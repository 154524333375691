import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";

import { TextWithPlaceholder } from "feature/panel/_shared/Messaging/Components/TextWithPlaceholder";

const Separator = styled.hr`
  display: block;
  height: ${({ theme }) => theme.setSpacing(5)}px;
  border: 0;
  padding: 0;
  border-top: none;
`;

const InfoBarGridElement = ({ data }) => {
  const { topPlaceholder, topValue, bottomPlaceholder, bottomValue } = data;

  return (
    <Grid container item xs={4} spacing={3}>
      <TextWithPlaceholder placeholder={topPlaceholder} value={topValue} />
      <Separator />
      <TextWithPlaceholder placeholder={bottomPlaceholder} value={bottomValue} />
    </Grid>
  );
};

InfoBarGridElement.propTypes = {
  data: PropTypes.shape().isRequired,
};

export { InfoBarGridElement };
