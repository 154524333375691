import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import PropTypes from "prop-types";

import { CUSTOM_APPS } from "constants/content";
import { setValueForMenuDocumentContent } from "store/customAppForm/actions";
import { usePermissionsService } from "hooks/usePermissionsService";
import { PERMISSIONS } from "constants/permissions";
import { useQuery } from "@tanstack/react-query";

import { CardSection, CardSectionsContainer, CustomCard, InputContentWrapper, PreviewCardSection, SectionTitle } from "../sharedComponents";
import { CustomAppEditor } from "./CustomAppEditor";
import { TermsPreview } from "../previews/TermsPreview";
import SectionTitleEditable from "./components/SectionTitle";

const NonEditableTCEditorInfo = styled.div`
  width: 100%;
  padding: ${({ theme }) => `${theme.setSpacing(3.5)}px ${theme.setSpacing(5.5)}px ${theme.setSpacing(2.5)}px  ${theme.setSpacing(3)}px`};
  background-color: ${({ theme }) => theme.colors.grey10};
  font-size: ${({ theme }) => theme.setSpacing(3)}px;
`;
const AppUseInfoBoxMsg = styled.div`
  width: 100%;
  padding: 14px 22px 10px 12px;
  color: ${({ theme }) => theme.colors.grey40};
  border-radius: 0 0 ${({ theme }) => `${theme.setSpacing(2)}px ${theme.setSpacing(2)}px`};
  max-height: 180px;
  overflow-y: auto;

  p {
    font-size: ${({ theme }) => theme.setSpacing(3)}px;
  }
  h3 {
    font-size: ${({ theme }) => theme.setSpacing(3.5)}px;
    font-weight: ${({ theme }) => theme.setSpacing(100)};
  }
`;

const TermsAndConditions = ({ isHistoryVersion, language, defaultName }) => {
  const permissionsService = usePermissionsService();
  const dispatch = useDispatch();

  const caStatus = useSelector(state => state.customAppForm?.customAppForm?.status);

  const termsValue = useSelector(
    state => state.customAppForm.customAppForm?.menu?.documents?.terms?.languages?.[language.current]?.html_content,
  );
  const termsName = useSelector(state => state.customAppForm.customAppForm?.menu?.documents?.terms?.languages?.[language.current]?.title);

  const errors = useSelector(state => state.customAppForm.errors?.menu);

  const canEdit = permissionsService.can(PERMISSIONS.actions.update, PERMISSIONS.sections.operator, PERMISSIONS.resources.operators);
  const canCreate = permissionsService.can(PERMISSIONS.actions.create, PERMISSIONS.sections.operator, PERMISSIONS.resources.operators);

  const editDisabled = !((caStatus === null && canCreate) || (caStatus === "draft" && canEdit));

  const onEditorContentChange = value => {
    dispatch(
      setValueForMenuDocumentContent({ content: value, type: "terms", language: language.current, defaultName: defaultName.current }),
    );
  };

  const onTitleChange = value => {
    dispatch(
      setValueForMenuDocumentContent({
        title: value || defaultName.current,
        type: "terms",
        language: language.current,
      }),
    );
  };

  const adornment = (
    <>
      <NonEditableTCEditorInfo>{CUSTOM_APPS.termsAndCondition.nonEditableInfo}</NonEditableTCEditorInfo>
      <AppUseInfoBoxMsg>
        <h3>{CUSTOM_APPS.termsAndCondition.appUseHeadline}</h3>
        <p>{CUSTOM_APPS.termsAndCondition.appUsePreviewContentP1}</p>
        <p>{CUSTOM_APPS.termsAndCondition.appUsePreviewContentP2}</p>
        <h3>{CUSTOM_APPS.termsAndCondition.scopeOfServiceHeadline}</h3>
        <p>{CUSTOM_APPS.termsAndCondition.scoupOfServiceContent}</p>
        <h3>{CUSTOM_APPS.termsAndCondition.disclaimerHeadline}</h3>
        <p>{CUSTOM_APPS.termsAndCondition.disclaimerContent}</p>
        <h3>{CUSTOM_APPS.termsAndCondition.intelectualRightsHeadline}</h3>
        <p>{CUSTOM_APPS.termsAndCondition.intelectualRightsContent}</p>
      </AppUseInfoBoxMsg>
    </>
  );

  return (
    <CustomCard>
      <CardSectionsContainer>
        <CardSection>
          <InputContentWrapper>
            <SectionTitleEditable
              title={termsName || defaultName.current}
              onChange={onTitleChange}
              disabled={isHistoryVersion || editDisabled}
            />
            <CustomAppEditor
              value={termsValue || ""}
              onEditorContentChange={onEditorContentChange}
              bottomAdornment={adornment}
              error={!!errors}
              helperText={errors?.html_content}
              disabled={isHistoryVersion || editDisabled}
              forceChange
            />
          </InputContentWrapper>
        </CardSection>
        <PreviewCardSection>
          <TermsPreview language={language} title={termsName || defaultName.current} />
        </PreviewCardSection>
      </CardSectionsContainer>
    </CustomCard>
  );
};

TermsAndConditions.defaultProps = {
  isHistoryVersion: false,
};

TermsAndConditions.propTypes = {
  isHistoryVersion: PropTypes.bool,
};

export { TermsAndConditions };
