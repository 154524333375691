import React from "react";
import { Grid } from "@material-ui/core";
import { useSelector } from "react-redux";

import { setUrl } from "utils/url";

import { useTableHandlers } from "hooks/useTableHandlers";
import { getCustomAppVersionsListStart } from "store/customAppForm/actions";
import { BUNDLE_VERSIONS_LIST_URL } from "constants/api";

import { VersionsTable } from "./VersionsTable";

const VersionsHistory = () => {
  const bundleId = useSelector(state => state.customAppForm.customAppFrom?.bundleId);
  const count = useSelector(state => state.customAppForm.count);
  const versionsList = useSelector(state => state.customAppForm.caVersionsList);
  const url = setUrl(BUNDLE_VERSIONS_LIST_URL, { bundleId: bundleId || 0 });
  const tableHandlers = useTableHandlers(url, getCustomAppVersionsListStart, {
    defaultOrderBy: "id",
    defaultOrder: "desc",
  });
  return (
    <Grid>
      <VersionsTable list={versionsList} count={count} tableHandlers={tableHandlers} />
    </Grid>
  );
};

export { VersionsHistory };
