import { Grid } from "@material-ui/core";
import { Content } from "components/ui/Content";
import { Tab } from "components/ui/Tabs/Tab";
import { Tabs } from "components/ui/Tabs/Tabs";
import { LOCATIONS_LABELS } from "constants/content";
import React, { useState } from "react";
import { setLocationsFilter } from "utils/dataConverters";
import { ManageTripContext, useManageTripContext } from "../../ManageTripContext";
import { LocationsTab } from "../LocationsTab/LocationsTab";
import { PoisTab } from "../PoisTab/PoisTab";

export const LocationsTabs = () => {
  const [currentTab, setCurrentTab] = useState(0);

  const { locations } = useManageTripContext();

  const noLocationsWithValidCoordinates = !setLocationsFilter(locations);

  return (
    <>
      <Grid item xs={12} md={10}>
        <Content margin={10}>
          <Tabs value={currentTab} onChange={(event, newValue) => setCurrentTab(newValue)} aria-label={LOCATIONS_LABELS.locationTabs}>
            <Tab label={LOCATIONS_LABELS.locations} />
            <Tab label={LOCATIONS_LABELS.pois} disabled={noLocationsWithValidCoordinates} />
          </Tabs>
        </Content>
      </Grid>
      <Grid item xs={12} md={10}>
        {currentTab === 0 && <LocationsTab />}
        {currentTab === 1 && <PoisTab context={ManageTripContext} />}
      </Grid>
    </>
  );
}