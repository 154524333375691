import React, { useContext } from "react";
import PropTypes from "prop-types";

import { DeleteAction, EditAction, TableActionsContainer } from "components/ui/Tables/TableRowActions";
import { ActionsContext } from "feature/panel/Notifications/ActionsContext";
import { TABLE_ACTIONS_TITLES } from "constants/content";
import { usePermissionsService } from "hooks/usePermissionsService";
import { PERMISSIONS } from "constants/permissions";

const NotificationsRowActions = ({ item }) => {
  const permissionsService = usePermissionsService();
  const { onEditClick, handleDeleteOpen } = useContext(ActionsContext);
  const handleEdit = () => onEditClick(item);
  const handleDelete = () => handleDeleteOpen(item);

  return (
    <TableActionsContainer>
      {permissionsService.can(PERMISSIONS.actions.update, PERMISSIONS.sections.operator, PERMISSIONS.resources.notifications) && (
        <EditAction 
          clickAction={handleEdit} 
          tooltip={TABLE_ACTIONS_TITLES.editResource(TABLE_ACTIONS_TITLES.resourceTypes.template)} 
        />
      )}
      {permissionsService.can(PERMISSIONS.actions.delete, PERMISSIONS.sections.operator, PERMISSIONS.resources.notifications) && (
        <DeleteAction 
          clickAction={handleDelete} 
          tooltip={TABLE_ACTIONS_TITLES.deleteResource(TABLE_ACTIONS_TITLES.resourceTypes.template)} 
        />
      )}
    </TableActionsContainer>
  );
};
NotificationsRowActions.propTypes = {
  item: PropTypes.shape().isRequired,
};

export { NotificationsRowActions };
