import { put, takeEvery } from "redux-saga/effects";

import * as actionTypes from "store/customApps/actionTypes";
import { handleErrorMessages } from "utils/store";
import { getCustomAppsFail, getCustomAppsSuccess } from "store/customApps/actions";
import { HttpClient } from "services/application/httpClient/httpClient";
import { CustomAppMapper } from "mappers/CustomAppMapper";

function* getCustomAppsStart({ payload }) {
  const { url } = payload;
  const customAppMapper = new CustomAppMapper();
  try {
    const { data } = yield HttpClient.get(url);

    if (data) {
      const { entries, count } = data;

      const items = entries.map(entry => customAppMapper.fromDtoToDomain({ ...entry.latest_definition, created_at: entry.created_at }));

      yield put(getCustomAppsSuccess({ items, total_matches: count }));
    }
  } catch (e) {
    yield put(getCustomAppsFail({ errors: handleErrorMessages(e) }));
  }
}

export function* customAppsSaga() {
  yield takeEvery(actionTypes.GET_CUSTOM_APPS_START, getCustomAppsStart);
}
