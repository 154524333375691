import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { CustomPicker } from "react-color";
import { Saturation, Hue, Alpha } from "react-color/lib/components/common";

import { GhostButton } from "components/ui/Buttons";

import { COLOR_PICKER, GLOBAL_CONTENT } from "constants/content";

import { firstLetterUpperCase } from "utils/string";

import {
  ColorPickerWrapper,
  CustomPointer,
  CustomSlider,
  CustomInputColor,
  ColorPreview,
  HueContainer,
  SaturationContainer,
  Container,
  Actions,
  Label,
  Indicator,
  AlphaSlider,
} from "./ColorPickerStyledComponents";
import { colorSetup } from "../colorConverters";

const ColorPicker = ({ title, inputColor, onColorSelect, onColorCancel, position, alphaChannel }) => {
  const pickerRef = useRef(null);
  const [color, setColor] = useState({
    hsl: {
      h: 0,
      s: 0,
      l: 0,
      a: 1,
    },
    hsv: {
      h: 0,
      s: 0,
      v: 0,
      a: 1,
    },
    rgb: {
      r: 0,
      g: 0,
      b: 0,
      a: 1,
    },
    hex: "#AAAAAA",
    mobileHex: "#AAAAAA",
  });

  const init = () => {
    if (inputColor) {
      const newColor = colorSetup(inputColor);
      setColor(newColor);
    }
  };

  const handleHueChange = hue => {
    const newColor = colorSetup(hue);

    setColor({ ...newColor });
  };

  const saturationChange = saturation => {
    const newColor = colorSetup(saturation);

    setColor({ ...newColor });
  };

  const onAlphaChannelChange = alpha => {
    const newColor = colorSetup(alpha);

    setColor({
      ...newColor,
    });
  };
  const handleColorSelect = () => {
    onColorSelect(color.mobileHex);
  };

  const handleClickOutside = event => {
    if (pickerRef.current && !pickerRef.current.contains(event.target)) {
      onColorCancel();
    }
  };

  const handleClickOutsideListener = () => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  };

  useEffect(init, [inputColor]);

  useEffect(handleClickOutsideListener, [handleClickOutside]);

  return (
    <>
      <Indicator position={position} />
      <ColorPickerWrapper ref={pickerRef} position={position} alphaChannel={alphaChannel}>
        <Label>{title}</Label>
        <Container>
          <SaturationContainer>
            <Saturation hsl={color.hsl} hsv={color.hsv} pointer={CustomPointer} onChange={saturationChange} />
          </SaturationContainer>
          <HueContainer>
            <Hue hsl={color.hsl} pointer={CustomSlider} direction="vertical" onChange={handleHueChange} />
          </HueContainer>
          {alphaChannel && (
            <HueContainer>
              <Alpha direction="vertical" pointer={AlphaSlider} {...color} onChange={onAlphaChannelChange} />
            </HueContainer>
          )}
        </Container>
        {alphaChannel && (
          <Container>
            {COLOR_PICKER.transparency}
            :
            <CustomInputColor>{`${100 - Math.round(color.hsv.a * 100)}%`}</CustomInputColor>
          </Container>
        )}
        <Container>
          <ColorPreview color={color.mobileHex} />
          {COLOR_PICKER.hex}
          :
          <CustomInputColor>{color.mobileHex}</CustomInputColor>
        </Container>
        <Actions>
          <GhostButton onClick={onColorCancel} cv="grey50" cvh="grey60">
            {firstLetterUpperCase(GLOBAL_CONTENT.cancel)}
          </GhostButton>
          <GhostButton onClick={handleColorSelect}>{GLOBAL_CONTENT.select}</GhostButton>
        </Actions>
      </ColorPickerWrapper>
    </>
  );
};

const ColorPickerPopup = CustomPicker(ColorPicker);

ColorPicker.propTypes = {
  title: PropTypes.string.isRequired,
  inputColor: PropTypes.string.isRequired,
  onColorCancel: PropTypes.func.isRequired,
  onColorSelect: PropTypes.func.isRequired,
  position: PropTypes.string.isRequired,
  alphaChannel: PropTypes.bool.isRequired,
};

export { ColorPickerPopup };
