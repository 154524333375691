// @ts-check

export class User {
  /**
   * @type {number}
   */
  id;

  /**
   * @type {string}
   */
  username;

  /**
   * @type {string}
   */
  email;

  /**
   * @type {string}
   */
  firstname;

  /**
   * @type {String}
   */
  lastname;

  /**
   * @type {string}
   */
  confirmedAt;

  /**
   * @type {string}
   */
  lastLoginAt;

  /**
   * @type {boolean}
   */
  userIsActive;

  /**
   * @type {boolean}
   */
  isSkeletal;

  /**
   * @type {any[]}
   */
  operators;

  /**
   * @type {string}
   */
  passwordExpiresAt;

  /**
   * @type {string}
   */
  passwordChangedAt;

  /**
   * @type {Object}
   */
  meta;

  /**
   * @type {string}
   */
  createdAt;

  /**
   * @type {string}
   */
  updatedAt;

  /**
   * @type {number}
   */
  operatorId;

  /**
   * @type {Object}
   */
  role;

  /**
   * @type {any[]}
   */
  additionalVamoosAccess;
}
