import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

import { EditAction, KeyAction, TableActionsContainer } from "components/ui/Tables/TableRowActions";
import { ConfirmationModal } from "components/ui/Modals/ConfirmationModal";

import { ADMIN_USERS_INDEX, GLOBAL_CONTENT, TABLE_ACTIONS_TITLES } from "constants/content";
import { ADMIN_USER_DETAILS_PATH } from "constants/routes";
import { setUrl } from "utils/url";

import { resetUserPasswordStart } from "store/adminUsers/actions";
import { PERMISSIONS } from "constants/permissions";
import { usePermissionsService } from "hooks/usePermissionsService";

const isResetInProgress = state => id => state.adminUsers.resetIdsInProgress.includes(id);

/**
 * @param {object} props
 * @param {User} props.item
 */
const TableRowActions = ({ item }) => {
  const permissionsService = usePermissionsService();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isInProgress = useSelector(state => isResetInProgress(state)(item.id));
  const [isModalOpened, setIsModalOpened] = useState(false);

  const { id, email, username } = item;
  const handleClickEditAction = () => {
    navigate(setUrl(ADMIN_USER_DETAILS_PATH, { id }));
  };

  const handleConfirm = () => {
    setIsModalOpened(false);
    dispatch(
      resetUserPasswordStart({
        id,
        email,
      }),
    );
  };

  const handleCancel = () => {
    setIsModalOpened(false);
  };

  const isResetPasswordDisabled = !permissionsService.can(
    PERMISSIONS.actions.update,
    PERMISSIONS.sections.admin,
    PERMISSIONS.resources.users,
  );

  const isReadDisabled = !permissionsService.can(
    PERMISSIONS.actions.read,
    PERMISSIONS.sections.admin,
    PERMISSIONS.resources.users,
    id || null,
  );

  return (
    <TableActionsContainer>
      <KeyAction
        clickAction={() => setIsModalOpened(true)}
        disabled={isInProgress || isResetPasswordDisabled}
        tooltip={ADMIN_USERS_INDEX.resetPassword}
      />
      <EditAction
        clickAction={handleClickEditAction}
        tooltip={TABLE_ACTIONS_TITLES.editResource(TABLE_ACTIONS_TITLES.resourceTypes.user)}
        disabled={isReadDisabled}
      />
      {isModalOpened && (
        <ConfirmationModal
          open={isModalOpened}
          confirmLabel={GLOBAL_CONTENT.proceed}
          title={ADMIN_USERS_INDEX.confirmReset}
          description={(
            <>
              {ADMIN_USERS_INDEX.doYouConfirmReset}
              <strong>{username}</strong>
              ?
            </>
          )}
          onConfirm={handleConfirm}
          onCancel={handleCancel}
        />
      )}
    </TableActionsContainer>
  );
};

TableRowActions.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number.isRequired,
    email: PropTypes.string.isRequired,
    username: PropTypes.string.isRequired,
  }).isRequired,
};

export { TableRowActions };
