import { ERRORS } from "constants/content";
import { emailValidate } from "utils/validation";

export function validateUserCreate(form) {
  const errors = {};
  if (!emailValidate(form.email)) errors.email = ERRORS.invalidEmail;
  if (!form.email) errors.email = ERRORS.isRequired("Email");
  if (!form.role_id) errors.role_id = ERRORS.isRequired("Role");
  return errors
}
