import { convertToFileObject } from "utils/dataConverters";
import linkifyHtml from "linkify-html";

export const StoryboardEntry = (dto, showDays) => {
  const domain = {};

  domain.headline = dto.headline;
  domain.id = dto.id;
  domain.position = dto.position;
  domain.tag = dto.tag;

  const prefix = '<span style="font-size: 1rem;">';
  let content =
    dto.content_type === "text/plain"
      ? linkifyHtml(dto.content?.replace(/\n/g, "<br>") || "")
      : dto.content
          ?.replace(/&amp;/g, "&")
          .replace(/&lt;/g, "<")
          .replace(/&gt;/g, ">")
          .replace(/&quot;/g, '"');

  if (content?.substring && content.substring(0, prefix.length) !== prefix) {
    content = prefix + content + "</span>";
  }
  domain.content = content;

  domain.details = dto.details;
  domain.documents = dto.documents.map(({ icon_id, ...document }) => ({
    ...convertToFileObject(document, true),
    icon_id,
  }));
  domain.tripId = dto.itinerary_id;

  if (dto.image) {
    domain.image = convertToFileObject(dto.image, true);
  }

  if (!dto?.meta?.day_number && showDays) {
    domain.meta = {
      day_number: dto.position,
      ...dto.meta,
    };
  } else {
    domain.meta = dto.meta;
  }
  if (dto.location) {
    domain.location = dto.location;
  }

  return domain;
};
