import FormControlLabel from "@material-ui/core/FormControlLabel";
import styled from "styled-components";

import { font } from "config/theme/fonts";

const CPFieldWrapper = styled(FormControlLabel)`
  && {
    .MuiFormControlLabel-label {
      font-size: ${({ theme }) => theme.fonts.fontSize.body}px;
      font-family: ${font}
    }
  }
`;

export {
  CPFieldWrapper
}