import React from "react";
import PropTypes from "prop-types";
import uuidv4 from "uuid";
import { FlightSearchElement } from "feature/panel/Trips/_shared/Flights/FlightSearchElement";

const FlightsSearchResults = ({ flights, onFlightSelect, selectedFlights }) => {
  return flights.map(flight => (
    <FlightSearchElement
      key={uuidv4()}
      isSelected={selectedFlights.some(selectedFlight => selectedFlight.id === flight.id)}
      flight={flight}
      onSelect={onFlightSelect}
    />
  ));
};

FlightsSearchResults.propTypes = {
  flights: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedFlights: PropTypes.arrayOf(PropTypes.object).isRequired,
  onFlightSelect: PropTypes.func.isRequired,
};

export { FlightsSearchResults };
