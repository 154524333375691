import React from "react";
import Grid from "@material-ui/core/Grid";
import uuidv4 from "uuid";
import { StickyHeaderFormSection } from "components/ui/Forms";
import AddIcon from "@material-ui/icons/Add";

import { SecondaryButton } from "components/ui/Buttons";
import { fullNameValidate } from "utils/validation";
import { useManageTripContext } from "feature/panel/Trips/_shared/ManageTripContext";
import { PeopleList } from "feature/panel/Trips/_shared/People/PeopleList";
import { SortableList } from "components/ui/Lists";
import { setNewOrder } from "utils/sortable";
import { CREATE_CONTENT_LABELS, EMPTY_LIST_MESSAGES_BASE, NAMES_OF_RESOURCES_LISTS } from "constants/content";
import { NoResultsMessage } from "components/ui/Messages/NoResultsMessage";
import { usePermissionsService } from "hooks/usePermissionsService";
import { PERMISSIONS } from "constants/permissions";

const People = () => {
  const { setValueFor, travelPeople, errors, vamoos_id, editMode, is_wiped } = useManageTripContext();
  const permissionsService = usePermissionsService();

  const canEdit = permissionsService.can(PERMISSIONS.actions.update, PERMISSIONS.sections.vamoosList, vamoos_id);

  const handlePersonEdit = newPerson => {
    const isMultiple = newPerson.name.includes("\n");

    if (fullNameValidate(newPerson.name)) {
      const people = newPerson.name.split("\n").map(name => ({ name, id: uuidv4() }));
      const newTravelPeople = travelPeople.map(person => (person.id === newPerson.id ? (isMultiple ? people[0] : newPerson) : person));
      people.shift();
      setValueFor("travelPeople", isMultiple ? [...newTravelPeople, ...people] : newTravelPeople);
    }
  };

  const handleDeletePerson = personIdToDelete => {
    setValueFor(
      "travelPeople",
      travelPeople.filter(person => person.id !== personIdToDelete),
    );
  };

  const handleAddPersonConfirm = () => {
    setValueFor("travelPeople", [...travelPeople, { id: uuidv4(), name: "" }]);
  };

  const handleChangeOrder = ({ newIndex, oldIndex }) => {
    const newList = setNewOrder(newIndex, oldIndex, travelPeople);
    setValueFor("travelPeople", newList);
  };

  const AddPersonButton = () =>
    (editMode ? canEdit : true) && (
      <SecondaryButton disabled={is_wiped} onClick={handleAddPersonConfirm}>
        <AddIcon />
        ADD
      </SecondaryButton>
    );

  const content = travelPeople.length ? (
    <SortableList lockAxis="y" onSortEnd={handleChangeOrder} lockToContainerEdges useDragHandle>
      <PeopleList
        people={travelPeople}
        onPersonChange={handlePersonEdit}
        onPersonDelete={handleDeletePerson}
        errors={errors.travellers ? errors.travellers : {}}
        disabled={is_wiped || (editMode && !canEdit)}
      />
    </SortableList>
  ) : (
    !(editMode && !canEdit) && (
      <NoResultsMessage>{EMPTY_LIST_MESSAGES_BASE(CREATE_CONTENT_LABELS.add, NAMES_OF_RESOURCES_LISTS.people)}</NoResultsMessage>
    )
  );

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} md={10}>
        <StickyHeaderFormSection title="List of people" headerActions={AddPersonButton}>
          {content}
        </StickyHeaderFormSection>
      </Grid>
    </Grid>
  );
};

export { People };
