import * as actionTypes from "store/customAppForm/actionTypes";
import { createAction } from "utils/store";

export const getCustomAppByIdStart = createAction(actionTypes.GET_CUSTOM_APP_BY_ID_START);
export const getCustomAppByIdSuccess = createAction(actionTypes.GET_CUSTOM_APP_BY_ID_SUCCESS);
export const getCustomAppByIdFail = createAction(actionTypes.GET_CUSTOM_APP_BY_ID_FAIL);

export const setValueForField = createAction(actionTypes.SET_VALUE_FOR_FIELD);
export const setBundleIdUniquenesFlag = createAction(actionTypes.SET_BUNDLE_ID_UNQUENESS_FLAG);
export const setValueForAll = createAction(actionTypes.SET_VALUE_FOR_ALL);
export const setIsFormTouched = createAction(actionTypes.SET_IS_FORM_TOUCHED);

export const clearCustomAppForm = createAction(actionTypes.CLEAR_CUSTOM_APP_FORM);

export const checkUserIdAvailabilityForCAStart = createAction(actionTypes.CHECK_USER_ID_FOR_CUSTOM_APP_AVAILABILITY_START);
export const checkUserIdAvailabilityForCAFail = createAction(actionTypes.CHECK_USER_ID_FOR_CUSTOM_APP_AVAILABILITY_FAIL);

export const saveNewCustomAppStart = createAction(actionTypes.SAVE_NEW_CUSTOM_APP_START);
export const saveNewCustomAppSuccess = createAction(actionTypes.SAVE_NEW_CUSTOM_APP_SUCCESS);
export const saveNewCustomAppFail = createAction(actionTypes.SAVE_NEW_CUSTOM_APP_FAIL);

export const reviseCustomAppStart = createAction(actionTypes.REVISE_CUSTOM_APP_START);
export const reviseCustomAppSuccess = createAction(actionTypes.REVISE_CUSTOM_APP_SUCCESS);
export const reviseCustomAppFail = createAction(actionTypes.REVISE_CUSTOM_APP_FAIL);

export const submitCustomAppDefinitionForReviewStart = createAction(actionTypes.SUBMIT_CUSTOM_APP_DEFINITION_FOR_REVIEW_START);
export const submitCustomAppDefinitionForReviewSuccess = createAction(actionTypes.SUBMIT_CUSTOM_APP_DEFINITION_FOR_REVIEW_SUCCESS);
export const submitCustomAppDefinitionForReviewFail = createAction(actionTypes.SUBMIT_CUSTOM_APP_DEFINITION_FOR_REVIEW_FAIL);

export const updateCustomAppDefinitionStart = createAction(actionTypes.UPDATE_CUSTOM_APP_DEFINITION_START);
export const updateCustomAppDefinitionSuccess = createAction(actionTypes.UPDATE_CUSTOM_APP_DEFINITION_SUCCESS);
export const updateCustomAppDefinitionFail = createAction(actionTypes.UPDATE_CUSTOM_APP_DEFINITION_FAIL);

export const dismissCustomAppStart = createAction(actionTypes.DISMISS_CUSTOM_APP_START);
export const dismissCustomAppSuccess = createAction(actionTypes.DISMISS_CUSTOM_APP_SUCCESS);
export const dismissCustomAppFail = createAction(actionTypes.DISMISS_CUSTOM_APP_FAIL);

export const getCustomAppVersionsListStart = createAction(actionTypes.GET_CUSTOM_APP_VERSIONS_LIST_START);
export const getCustomAppVersionsListSuccess = createAction(actionTypes.GET_CUSTOM_APP_VERSIONS_LIST_SUCCESS);
export const getCustomAppVersionsListFail = createAction(actionTypes.GET_CUSTOM_APP_VERSIONS_LIST_FAIL);

export const checkBundleIdAvailableStart = createAction(actionTypes.CHECK_BUNDLE_ID_AVAILABLE_START);
export const checkBundleIdAvailableSuccess = createAction(actionTypes.CHECK_BUNDLE_ID_AVAILABLE_SUCCESS);

export const setValueForLoginBackgroundColor = createAction(actionTypes.SET_VALUE_FOR_APP_LOGIN_BG_COLOR);
export const setValueForLoginBorderColor = createAction(actionTypes.SET_VALUE_FOR_APP_LOGIN_BORDER_COLOR);
export const setValueForLoginNavigationColor = createAction(actionTypes.SET_VALUE_FOR_APP_LOGIN_NAVIGATION_COLOR);
export const setValueForLoginTextColor = createAction(actionTypes.SET_VALUE_FOR_APP_LOGIN_TEXT_COLOR);
export const setValueForLoginText1String = createAction(actionTypes.SET_VALUE_FOR_APP_LOGIN_TEXT_1_STRING);
export const setValueForLoginText1Color = createAction(actionTypes.SET_VALUE_FOR_APP_LOGIN_TEXT_1_COLOR);
export const setValueForLoginText2String = createAction(actionTypes.SET_VALUE_FOR_APP_LOGIN_TEXT_2_STRING);
export const setValueForLoginText2Color = createAction(actionTypes.SET_VALUE_FOR_APP_LOGIN_TEXT_2_COLOR);
export const setValueForLoginText3String = createAction(actionTypes.SET_VALUE_FOR_APP_LOGIN_TEXT_3_STRING);
export const setValueForLoginText3Color = createAction(actionTypes.SET_VALUE_FOR_APP_LOGIN_TEXT_3_COLOR);
export const setValueForLoginText4String = createAction(actionTypes.SET_VALUE_FOR_APP_LOGIN_TEXT_4_STRING);
export const setValueForLoginText4Color = createAction(actionTypes.SET_VALUE_FOR_APP_LOGIN_TEXT_4_COLOR);
export const setValueForLoginText5String = createAction(actionTypes.SET_VALUE_FOR_APP_LOGIN_TEXT_5_STRING);
export const setValueForLoginText5Color = createAction(actionTypes.SET_VALUE_FOR_APP_LOGIN_TEXT_5_COLOR);

export const setValueForLoginUserIdHintString = createAction(actionTypes.SET_VALUE_FOR_APP_LOGIN_USER_ID_HINT_STRING);
export const setValueForLoginNextButtonString = createAction(actionTypes.SET_VALUE_FOR_APP_LOGIN_NEXT_STRING);
export const setValueForImageBackground = createAction(actionTypes.SET_VALUE_FOR_APP_BACKGROUND_IMAGE);
export const setValueForTitle = createAction(actionTypes.SET_VALUE_FOR_APP_TITLE);
export const setValueForBundleId = createAction(actionTypes.SET_VALUE_FOR_APP_BUNDLE_ID);
export const setValueForSequenceNumber = createAction(actionTypes.SET_VALUE_FOR_APP_SEQUENCE_NUMBER);
export const setValueForType = createAction(actionTypes.SET_VALUE_FOR_APP_TYPE);
export const setValueForAccentColor = createAction(actionTypes.SET_VALUE_FOR_APP_ACCENT_COLOR);
export const setValueForDefaultBgColor = createAction(actionTypes.SET_VALUE_FOR_APP_DEFAULT_BG_COLOR);
export const setValueForInputFieldBgColor = createAction(actionTypes.SET_VALUE_FOR_APP_INPUT_FIELD_BG_COLOR);
export const setValueForInputFieldFrameColor = createAction(actionTypes.SET_VALUE_FOR_APP_INPUT_FIELD_FRAME_COLOR);
export const setValueForInputFieldTextColor = createAction(actionTypes.SET_VALUE_FOR_APP_INPUT_TEXT_COLOR);
export const setValueForInputFieldHintColor = createAction(actionTypes.SET_VALUE_FOR_APP_INPUT_HINT_COLOR);
export const setValueForButtonBgActiveColor = createAction(actionTypes.SET_VALUE_FOR_APP_BUTTON_BG_ACTIVE_COLOR);
export const setValueForButtonBgInactiveColor = createAction(actionTypes.SET_VALUE_FOR_APP_BUTTON_BG_INACTIVE_COLOR);
export const setValueForButtonTextActiveColor = createAction(actionTypes.SET_VALUE_FOR_APP_BUTTON_TEXT_ACTIVE_COLOR);
export const setValueForButtonTextInactiveColor = createAction(actionTypes.SET_VALUE_FOR_APP_BUTTON_TEXT_INACTIVE_COLOR);
export const setValueForMenuBgColor = createAction(actionTypes.SET_VALUE_FOR_APP_MENU_BG_COLOR);
export const setValueForMenuIconColor = createAction(actionTypes.SET_VALUE_FOR_APP_MENU_ICON_COLOR);
export const setValueForMenuTextColor = createAction(actionTypes.SET_VALUE_FOR_APP_MENU_TEXT_COLOR);
export const setValueForMenuItinerartBgColor = createAction(actionTypes.SET_VALUE_FOR_APP_MENU_ITINERARY_BG_COLOR);
export const setValueForDividerColor = createAction(actionTypes.SET_VALUE_FOR_APP_MENU_DIVIDER_COLOR);
export const setValueForTextsBgColor = createAction(actionTypes.SET_VALUE_FOR_APP_TEXTS_BG_COLOR);
export const setValueForTextsContentColor = createAction(actionTypes.SET_VALUE_FOR_APP_TEXTS_CONTENT_COLOR);
export const setValueForTextsHeadlineColor = createAction(actionTypes.SET_VALUE_FOR_APP_TEXTS_HEADLINE_COLOR);
export const setValueForLoginPasscodeHeaderStayString = createAction(actionTypes.SET_VALUE_FOR_APP_LOGIN_PASSCODE_HEADER_STAY_STRING);
export const setValueForLoginPasscodeHeaderTripString = createAction(actionTypes.SET_VALUE_FOR_APP_LOGIN_PASSCODE_HEADER_TRIP_STRING);
export const setValueForLoginPasscodeHintString = createAction(actionTypes.SET_VALUE_FOR_APP_LOGIN_PASSCODE_HINT_STRING);
export const setValueForLoginPasscodeSubmitButtonString = createAction(actionTypes.SET_VALUE_FOR_APP_LOGIN_PASSCODE_SUBMIT_BUTTON_STRING);
export const setValueForLoginPasscodeSkipButtonString = createAction(actionTypes.SET_VALUE_FOR_APP_LOGIN_PASSCODE_SKIP_BUTTON_STRING);
export const setValueForMenuLoadNewString = createAction(actionTypes.SET_VALUE_FOR_APP_MENU_LOAD_NEW_STRING);
export const setValueForMenuViewAllString = createAction(actionTypes.SET_VALUE_FOR_APP_MENU_VIEW_ALL_STRING);
export const setValueForMenuRefreshString = createAction(actionTypes.SET_VALUE_FOR_APP_MENU_REFRESH_STRING);
export const setValueForMenuSettingsString = createAction(actionTypes.SET_VALUE_FOR_APP_MENU_SETTINGS_STRING);
export const setValueForMenuAboutString = createAction(actionTypes.SET_VALUE_FOR_APP_MENU_ABOUT_AS_STRING);
export const setValueForMenuFAQString = createAction(actionTypes.SET_VALUE_FOR_APP_MENU_FAQ_STRING);
export const setValueForMenuTermsString = createAction(actionTypes.SET_VALUE_FOR_APP_MENU_TERMS_STRING);
export const setValueForSettingsTitleString = createAction(actionTypes.SET_VALUE_FOR_APP_SETTING_TITLE_STRING);
export const setValueForMyItitneraryTitleString = createAction(actionTypes.SET_VALUE_FOR_APP_MY_ITINERART_TITLE_STRING);
export const setValueForAboutTitleString = createAction(actionTypes.SET_VALUE_FOR_APP_ABOUT_US_TITLE_STRING);
export const setValueForFAQTitleString = createAction(actionTypes.SET_VALUE_FOR_APP_FAQ_TITLE_STRING);
export const setValueForTermsTitleString = createAction(actionTypes.SET_VALUE_FOR_APP_TERMS_TITLE_STRING);
export const setValueForGalleryTitleString = createAction(actionTypes.SET_VALUE_FOR_APP_GALLERY_TITLE_STRING);
export const setValueForUppercaseMenuNamesSetting = createAction(actionTypes.SET_VALUE_FOR_APP_UPPERCASE_MENU_NAMES_SETTING);
export const setValueForLoginBordersSetting = createAction(actionTypes.SET_VALUE_FOR_APP_LOGIN_BORDERS_SETTING);
export const setValueForDefaultStatusbarStyleSetting = createAction(actionTypes.SET_VALUE_FOR_APP_DEFAULT_STATUSBAR_STYLE_SETTING);
export const setValueForDefaultUserIdSetting = createAction(actionTypes.SET_VALUE_FOR_APP_DEFAULT_USER_ID_SETTING);
export const setValueForButtonSquareCornersSetting = createAction(actionTypes.SET_VALUE_FOR_APP_BUTTON_SQUARE_CORNERS_SETTING);
export const setValueForInputSquareCornersSetting = createAction(actionTypes.SET_VALUE_FOR_APP_BUTTON_INPUT_FIELD_SQUARE_CORNERS_SETTING);
export const setValueForCustomFont = createAction(actionTypes.SET_VALUE_FOR_APP_CUSTOM_FONT);
export const setValueForIconImage = createAction(actionTypes.SET_VALUE_FOR_APP_ICON_IMAGE);
export const setValueForIconTransparentImage = createAction(actionTypes.SET_VALUE_FOR_APP_ICON_TRANSPARENT_IMAGE);
export const setValueForIconBackgroundColor = createAction(actionTypes.SET_VALUE_FOR_APP_ICON_BACKGROUND_COLOR);
export const setValueForLogoImage = createAction(actionTypes.SET_VALUE_FOR_APP_LOGO_IMAGE);
export const setValueForBackgroundImage = createAction(actionTypes.SET_VALUE_FOR_APP_BACKGROUND_IMAGE);
export const setValueForMenuDocumentName = createAction(actionTypes.SET_VALUE_FOR_APP_MENU_DOC_NAME);
export const setValueForMenuDocumentContent = createAction(actionTypes.SET_VALUE_FOR_APP_MENU_DOC_CONTENT);

export const setCustomAppCurrentLanguage = createAction(actionTypes.SET_CUSTOM_APP_CURRENT_LANGUAGE);
export const setValueForAppLoginText = createAction(actionTypes.SET_VALUE_FOR_APP_LOGIN_TEXT);
export const setValueForAppLoginTextColorCommon = createAction(actionTypes.SET_VALUE_FOR_APP_LOGIN_TEXT_COLOR_COMMON);
export const setColorForApp = createAction(actionTypes.SET_COLOR_FOR_APP);
export const setValueForAppDefaultLanguage = createAction(actionTypes.SET_VALUE_FOR_APP_DEFAULT_LANGUAGE);
export const setValueForAppLanguages = createAction(actionTypes.SET_VALUE_FOR_APP_LANGUAGES);
