import styled from "styled-components";
import { Select } from "components/ui/Forms";

export const NotificationWrapper = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.grey10};
  margin-bottom: ${({ theme }) => theme.setSpacing(6)}px;

  &:last-of-type {
    margin-bottom: 0;
  }

  &:first-of-type {
    border-top: 0;
  }
`;

export const DataDisplayWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${({ theme }) => theme.setSpacing(6)}px 0;
`;

export const CustomSelect = styled(Select)`
  &&& {
    .MuiSelect-root {
      color: ${({ theme }) => theme.colors.black};
    }
    .MuiSelect-root.Mui-disabled {
      color: ${({ theme }) => theme.colors.grey50};
    }
    svg {
      color: ${({ theme }) => theme.colors.grey100};
      margin-right: 0;
    }

    .Mui-disabled {
      color: ${({ theme }) => theme.colors.grey50};

      .MuiSelect-root {
        color: ${({ theme }) =>theme.colors.grey40};
      }
    }
  }
`;
export const DateAndTimeInputsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  &&& > * {
    margin-right: ${({ theme }) => theme.setSpacing(6)}px;

    &:last-child {
      margin-right: 0;
    }
  }
`;

export const NumberOfDaysWrapper = styled.div`
  && .MuiFormControl-root {
    width: 100px;
  }
`;
