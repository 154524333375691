// @ts-check

import {
  ADMIN_OPERATOR_DETAILS_URL,
  CURRENT_OPERATOR_URL,
  OPERATOR_ROLES_URL,
  OPERATOR_USERS_SUSPEND_URL,
  OPERATOR_USERS_URL,
  OPERATOR_USER_INVITE_URL,
  OPERATOR_USER_REVOKE_URL,
  OPERATOR_USER_UPDATE_URL,
} from "constants/api";
import { HttpClient } from "services/application/httpClient/httpClient";
import { setUrl } from "utils/url";

export class OperatorRepository {
  /**
   * @private
   * @type {HttpClient}
   */
  httpClient;

  constructor() {
    this.httpClient = HttpClient;
  }

  /**
   * @returns {Promise<any>}
   */
  async getOperatorUsers() {
    const { data } = await this.httpClient.get(OPERATOR_USERS_URL);

    return data;
  }

  /**
   *
   * @param {*} invitationData
   * @returns {Promise<any>}
   */
  inviteUser(invitationData) {
    return this.httpClient.post(OPERATOR_USER_INVITE_URL, invitationData);
  }

  /**
   *
   * @param {number} id
   * @returns {Promise<any>}
   */
  revokeInvitedUser(id) {
    return this.httpClient.delete(setUrl(OPERATOR_USER_REVOKE_URL, { id }));
  }

  /**
   *
   * @param {*} userData
   * @returns {Promise<any>}
   */
  updateUserRole(userData) {
    const { id, role_id } = userData;
    const url = setUrl(OPERATOR_USER_UPDATE_URL, { id });
    const body = { role_id };
    return this.httpClient.post(url, body);
  }

  suspendUser(userData) {
    const { userId, code } = userData;
    const url = setUrl(OPERATOR_USERS_SUSPEND_URL, { id: userId });

    const headers = code
      ? {
          "X-Operator-Code": code,
        }
      : {};

    return this.httpClient.post(url, null, { headers });
  }

  /**
   *
   * @returns {Promise<any>}
   */
  getOperatorRoles() {
    return this.httpClient.get(OPERATOR_ROLES_URL);
  }

  /**
   * @param {string} code
   * @returns {Promise<any>}
   */
  async getCurrentOperator(code = null) {
    const headers = code
      ? {
          "X-Operator-Code": code,
        }
      : {};

    return this.httpClient.get(CURRENT_OPERATOR_URL, { headers });
  }

  /**
   *
   * @param {object} body
   * @param {string} code
   * @returns {Promise<any>}
   */
  updateCurrentOperator(body, code = null) {
    const headers = code
      ? {
          "X-Operator-Code": code,
        }
      : {};

    return this.httpClient.post(CURRENT_OPERATOR_URL, body, { headers });
  }

  /**
   *
   * @param {string} url
   * @param {boolean} searchMode
   * @returns {Promise<any>}
   */
  getAdminOperatorList(url, searchMode = false) {
    if (searchMode) {
      this.httpClient.cancelRequest();
    }

    return this.httpClient.get(url);
  }

  /**
   *
   * @param {number} id
   * @returns {Promise<any>}
   */
  getAdminOperatorDetails(id) {
    const url = setUrl(ADMIN_OPERATOR_DETAILS_URL, { id });

    return this.httpClient.get(url);
  }

  /**
   *
   * @param {number} id
   * @param {object} data
   * @returns {Promise<any>}
   */
  updateAdminOperatorDetails(id, data) {
    const url = setUrl(ADMIN_OPERATOR_DETAILS_URL, { id });

    return this.httpClient.post(url, data);
  }
}
