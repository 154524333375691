import styled from "styled-components";
import { Link } from "components/ui/Links/Link";

const StyledLink = styled(Link)`
  height: ${({ theme }) => theme.setSpacing(6)};
  font-family: Montserrat;
  font-size: ${({ theme }) => theme.setSpacing(5)};
  white-space: nowrap;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: 1px;
  color: ${({ theme }) => theme.colors.grey100};
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
`;

export { StyledLink };
