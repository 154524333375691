export const TRIPS_GET_TRIPS_START = "TRIPS_GET_TRIPS_START";
export const TRIPS_GET_TRIPS_SUCCESS = "TRIPS_GET_TRIPS_SUCCESS";
export const TRIPS_GET_TRIPS_FAIL = "TRIPS_GET_TRIPS_FAIL";

export const TRIPS_GET_TRIP_CREATIONS_START = "TRIPS_GET_TRIP_CREATIONS_START";
export const TRIPS_GET_TRIP_CREATIONS_SUCCESS = "TRIPS_GET_TRIP_CREATIONS_SUCCESS";
export const TRIPS_GET_TRIP_CREATIONS_FAIL = "TRIPS_GET_TRIP_CREATIONS_FAIL";

export const CREATE_TRIP_CREATION_START = "CREATE_TRIP_CREATION_START";
export const CREATE_TRIP_CREATION_SUCCESS = "CREATE_TRIP_CREATION_SUCCESS";
export const CREATE_TRIP_CREATION_FAIL = "CREATE_TRIP_CREATION_FAIL";

export const DELETE_TRIP_CREATION_START = "DELETE_TRIP_CREATION_START";
export const DELETE_TRIP_CREATION_SUCCESS = "DELETE_TRIP_CREATION_SUCCESS";
export const DELETE_TRIP_CREATION_FAIL = "DELETE_TRIP_CREATION_FAIL";

export const REFRESH_TRIP_CREATION_START = "REFRESH_TRIP_CREATION_START";
export const REFRESH_TRIP_CREATION_SUCCESS = "REFRESH_TRIP_CREATION_SUCCESS";
export const REFRESH_TRIP_CREATION_FAIL = "REFRESH_TRIP_CREATION_FAIL";

export const CLEAR_TRIP_CREATION_JOB = "CLEAR_TRIP_CREATION_JOB";

export const TRIPS_GET_AIRLINE_CODES_START = "TRIPS_GET_AIRLINE_CODES_START";
export const TRIPS_GET_AIRLINE_CODES_SUCCESS = "TRIPS_GET_AIRLINE_CODES_SUCCESS";
export const TRIPS_GET_AIRLINE_CODES_FAIL = "TRIPS_GET_AIRLINE_CODES_FAIL";

export const TRIPS_SEARCH_FLIGHTS_START = "TRIPS_SEARCH_FLIGHTS_START";
export const TRIPS_SEARCH_FLIGHTS_SUCCESS = "TRIPS_SEARCH_FLIGHTS_SUCCESS";
export const TRIPS_SEARCH_FLIGHTS_FAIL = "TRIPS_SEARCH_FLIGHTS_FAIL";

export const TRIPS_ADD_SELECTED_FLIGHTS_START = "TRIPS_ADD_SELECTED_FLIGHTS_START";
export const TRIPS_ADD_SELECTED_FLIGHTS_SUCCESS = "TRIPS_ADD_SELECTED_FLIGHTS_SUCCESS";
export const TRIPS_ADD_SELECTED_FLIGHTS_FAIL = "TRIPS_ADD_SELECTED_FLIGHTS_FAIL";

export const CLEAR_LIST_OF_SELECTED_FLIGHTS = "CLEAR_LIST_OF_SELECTED_FLIGHTS";

export const CREATE_OR_UPDATE_TRIP_START = "CREATE_OR_UPDATE_TRIP_START";
export const CREATE_OR_UPDATE_TRIP_SUCCESS = "CREATE_OR_UPDATE_TRIP_SUCCESS";
export const CREATE_OR_UPDATE_TRIP_FAIL = "CREATE_OR_UPDATE_TRIP_FAIL";
export const CREATE_OR_UPDATE_TRIP_VALIDATION_FAIL = "CREATE_OR_UPDATE_TRIP_VALIDATION_FAIL";

export const TRIPS_CLEAR_FOUND_FLIGHTS = "TRIPS_CLEAR_FOUND_FLIGHTS";

export const COPY_TRIP_START = "COPY_TRIP_START";
export const COPY_TRIP_SUCCESS = "COPY_TRIP_SUCCESS";
export const COPY_TRIP_FAIL = "COPY_TRIP_FAIL";

export const TOGGLE_TRIP_ACTIVE_STATUS_START = "TOGGLE_TRIP_ACTIVE_STATUS_START";

export const CHECK_PASSCODE_AVAILABILITY_START = "CHECK_PASSCODE_AVAILABILITY_START";
export const CHECK_PASSCODE_AVAILABILITY_SUCCESS = "CHECK_PASSCODE_AVAILABILITY_SUCCESS";

export const GET_RECENT_LOCATION_START = "GET_RECENT_LOCATION_START";
export const GET_RECENT_LOCATION_SUCCESS = "GET_RECENT_LOCATION_SUCCESS";
export const GET_RECENT_LOCATION_FAIL = "GET_RECENT_LOCATION_FAIL";

export const GET_PUBLIC_STAYS_START = "GET_PUBLIC_STAYS_START";
export const GET_PUBLIC_STAYS_SUCCESS = "GET_PUBLIC_STAYS_SUCCESS";
export const GET_PUBLIC_STAYS_FAIL = "GET_PUBLIC_STAYS_FAIL";

export const SET_TRIPS_FORM_TOUCHED = "SET_TRIPS_FORM_TOUCHED";
export const SET_TRIPS_FINISHED_STATUS = "SET_TRIPS_FINISHED_STATUS";
export const SET_TRIPS_ACTION_STATUS = "SET_TRIPS_FINISHED_STATUS";

export const OPEN_CREATION_MODAL = "OPEN_CREATION_MODAL";
export const CLOSE_CREATION_MODAL = "CLOSE_CREATION_MODAL";
