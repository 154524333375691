import { InspirationRequestDto } from "dto/InspirationRequestDto";
import { InspirationPasscodeAlreadyUsedException } from "exceptions/InspirationPasscodeAlreadyUsedException";
import { InspirationRepository } from "repositories/InspirationRepository";

export class InspirationService {

  constructor(inspirationRepository = new InspirationRepository()) {
    this.inspirationRepository = inspirationRepository;
  }
  
  async getList(page, rowsPerPage, sortingOrder, sortingBy) {
    return this.inspirationRepository.getList(page, rowsPerPage, sortingOrder, sortingBy);
  }

  async saveInspiration(inspiration) {
    const newInspiration = InspirationRequestDto(inspiration, !inspiration.vamoos_id);

    try {
      const result = await this.inspirationRepository.saveInspiration(newInspiration);

      return result;
    } catch(e) {
      if(e.response?.status === 403) {
        if(e.response.data.error && e.response.data.error.includes("passcode has already been taken")) {
          throw new InspirationPasscodeAlreadyUsedException()
        }

        if(e.response.data.error && e.response.data.error.match(/^Cannot update inspiration (.+?) without its vamoos_id$/)) {
          throw new InspirationPasscodeAlreadyUsedException()
        }
      }
      return null;
    }
  }
}