import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const ButtonText = styled.p`
  line-height: 1;
  white-space: nowrap;
`;

const WordBreakLabel = ({ preBreakText, postBreakText }) => (
  <ButtonText>
    {preBreakText}
    {/* <span>{postBreakText}</span> */}
  </ButtonText>
);

WordBreakLabel.propTypes = {
  preBreakText: PropTypes.string.isRequired,
  postBreakText: PropTypes.string.isRequired,
};

export { WordBreakLabel };
