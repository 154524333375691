import React, { memo } from "react";
import styled from "styled-components";
import propTypes from "prop-types";

import { Logo } from "components/ui/Logo/Logo";
import { Link } from "components/ui/Links/Link";

import { PANEL_USER_SETTINGS, ROOT_PATH } from "constants/routes";
import { useSelector } from "react-redux";

const LogoArea = styled.div`
  position: absolute;
  left: ${({ theme }) => theme.setSpacing(4)}px;
  top: 0;
  height: inherit;
  width: auto;
  display: flex;
  align-items: center;
`;

const Logotype = memo(({ redirectUrl }) => {
  const { operators } = useSelector(state => state.auth);
  const newRedirectUrl = operators.length === 0 ? PANEL_USER_SETTINGS : redirectUrl;

  return (
      <LogoArea>
        <Link to={newRedirectUrl}>
          <Logo />
        </Link>
      </LogoArea>
  );
});

Logotype.defaultProps = {
  redirectUrl: ROOT_PATH,
};

Logotype.propTypes = {
  redirectUrl: propTypes.string,
};

export { Logotype, LogoArea };
