export class BrandSection {
  /** @type {number} */
  id;

  /** @type {number} */
  tag;

  /** @type {number} */
  operatorId;

  /** @type {string} */
  name;

  /** @type {string} */
  description;

  /** @type {object} */
  file;

  /** @type {number} */
  position;

  /** @type {string} */
  remoteUrl;

  /** @type {number} */
  iconId;

  /** @type {boolean} */
  isFolder;

  /** @type {string} */
  path;

  /** @type {string} */
  aliasForId;

  /** @type {number} */
  createdBy;

  /** @type {string} */
  createdAt;

  /** @type {string} */
  updatedAt;

  /** @type {object} */
  meta;

  /** @type {string} */
  mimeType;
}
