import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import { Radio } from "components/ui/Forms";

const FormControlWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const ImageSizePicker = ({ imageSize, onImageSizeChange, firstOptionLabel, secondOptionLabel, firstOptionValue, secondOptionValue }) => {
  const handleOptionChange = ({ target }) => onImageSizeChange(target.name);

  return (
    <FormControlWrapper>
      <FormControlLabel
        label={firstOptionLabel}
        control={<Radio name={firstOptionValue} color="primary" checked={imageSize === firstOptionValue} onChange={handleOptionChange} />}
      />
      <FormControlLabel
        label={secondOptionLabel}
        control={<Radio name={secondOptionValue} color="primary" checked={imageSize === secondOptionValue} onChange={handleOptionChange} />}
      />
    </FormControlWrapper>
  );
};

ImageSizePicker.propTypes = {
  imageSize: PropTypes.string.isRequired,
  onImageSizeChange: PropTypes.func.isRequired,
  firstOptionLabel: PropTypes.string.isRequired,
  secondOptionLabel: PropTypes.string.isRequired,
  firstOptionValue: PropTypes.string.isRequired,
  secondOptionValue: PropTypes.string.isRequired,
};

export { ImageSizePicker };
