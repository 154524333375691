import moment from "moment";
import config from "config/app";

import { LOGO_FIELD } from "feature/panel/_shared/defaultFieldNames";
import { UrlRefreshService } from "services/application/UrlRefreshService";

export const defaultStartTime = "12:00";
const date = moment(new Date());
const extractedDateWithoutTime = date
  .toISOString()
  .split("T")
  .shift();
const today = moment(`${extractedDateWithoutTime} ${defaultStartTime}`);
const todayDate = today.format(config.apiDateFormat);
const tomorrowDate = date.add(1, "d").format(config.apiDateFormat);
export const defaultNotificationTime = "13:00";

export const tripInitialState = {
  vamoos_id: null,
  field1: "",
  field2: "",
  field3: "",
  field4: "",
  type: "trip",
  departure_date: todayDate,
  return_date: tomorrowDate,
  start_time: today,
  userId: "",
  passcode: "",
  timezone: null,
  client_reference: "",
  background: null,
  destinationDocuments: [],
  travelDocuments: [],
  locations: [],
  travelPeople: [],
  listOfFlights: [],
  notifications: [],
  pois: [],
  is_wiped: false,
  meta: {
    show_messaging: "off",
    show_days: true,
    messaging_emails_list: [],
    messaging_email_during_days_before: "0",
    messaging_email_during_days_after: "0",
  },
  storyboard: [],
  show_journal: true,
  show_poi_list: undefined,
  is_active: true,
  currentPasscode: "",
  [LOGO_FIELD]: null,
  // Has to be here until we not refactor application to use domain class objects
  async refreshLinks() {
    this.storyboard = await Promise.all(
      this.storyboard.map(async entry => {
        if (entry.meta.type === "stay") {
          if (entry.image?.file_url || entry.image?.fileUrl) {
            return {
              ...entry,
              image: {
                ...entry.image,
                file_url: await UrlRefreshService.refresh(entry.image?.file_url || entry.image?.fileUrl),
              },
            };
          }
        }
        return entry;
      }),
    );
  },
};
