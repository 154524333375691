import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import MenuItem from "@material-ui/core/MenuItem";

import { Select } from "components/ui/Forms";
import { ToolbarSection } from "components/templates/_shared/styledComponents";

import { PANEL_ROOT_PATH, ADMIN_OPERATORS_PATH } from "constants/routes";
import { OPERATOR_TYPE } from "constants/content";

import { changeOperatorInitToggle, changeOperatorStart, clearChangeOperatorFlags } from "store/app/actions";

import { setTripsFormTouched } from "store/trips/actions";
import { toggleIconFormTouched } from "store/icons/actions";
import { setOperatorDetailsFormTouched } from "store/adminOperatorDetails/actions";
import { setUserDetailsFormTouched } from "store/adminUserDetails/actions";
import { setStaysFeaturesFormTouched } from "store/adminFeatures/actions";

import { TabSessionService } from "services/TabSessionService";
import { StorageService } from "services/StorageService";
import { useQueryClient } from "@tanstack/react-query";

import { ChangeOperatorConfirm } from "./ChangeOperatorConfirm";

const OperatorSelector = () => {
  const queryClient = useQueryClient();

  const tabSessionService = new TabSessionService(new StorageService());
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [operatorToChange, setOperatorToChange] = useState(null);
  const [currentOperator, setCurrentOperator] = useState("");
  const [isRedirectRequired, setIsRedirectRequired] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const operators = useSelector(state => state.auth?.operators);
  const currentOperatorCode = useSelector(state => state.auth?.currentOperatorCode);
  const isTripFormTouched = useSelector(state => state.trips.isTripFormTouched);
  const isIconFormTouched = useSelector(state => state.icons.isIconFormTouched);
  const operatorChanged = useSelector(state => state.app?.operatorChanged);
  const isOperatorFormTouched = useSelector(state => state.adminOperatorDetails.isFormTouched);
  const isDetailsFormTouched = useSelector(state => state.adminUserDetails.isFormTouched);
  const isFeaturesStaysFormTouched = useSelector(state => state.adminFeatureStays.isFormTouched);
  const currentOperatorType = operators?.find(({ code }) => code === currentOperatorCode)?.type;

  const changeOperator = target => {
    const { value } = target;
    const isSelectedOperatorActive = !!operators && operators.find(({ code }) => code === value)?.isActive;

    if (value !== currentOperator) {
      setCurrentOperator(value);
      dispatch(changeOperatorStart({ code: value, isSelectedOperatorActive }));
    }
  };

  const handleChangeOperatorConfirm = () => {
    changeOperator({ value: operatorToChange });
    setOpenConfirmation(false);
    dispatch(setTripsFormTouched(false));
  };
  const handleChangeOperatorCancel = () => {
    setOpenConfirmation(false);
    dispatch(changeOperatorInitToggle(false));
  };

  const handleChangeOperator = async ({ target }) => {
    setIsRedirectRequired(true);

    if (isTripFormTouched || isIconFormTouched || isOperatorFormTouched || isDetailsFormTouched || isFeaturesStaysFormTouched) {
      dispatch(changeOperatorInitToggle(true));
      setOpenConfirmation(true);
      setOperatorToChange(target.value);
    } else {
      changeOperator({ value: target.value });
    }
  };

  const init = () => {
    tabSessionService
      .getOperatorCode()
      .then(code => {
        if (code) {
          setCurrentOperator(code);
        }
      })
      .catch(() => setCurrentOperator(null));
  };

  const clearOperatorFlags = () => {
    if (operatorChanged) {
      if (isRedirectRequired) {
        const path = currentOperatorType === OPERATOR_TYPE.admin ? ADMIN_OPERATORS_PATH : PANEL_ROOT_PATH;
        queryClient.invalidateQueries({ queryKey: ["pois"] });

        navigate(path);
        setIsRedirectRequired(false);
      }

      if (isTripFormTouched) dispatch(setTripsFormTouched(false));
      if (isIconFormTouched) dispatch(toggleIconFormTouched(false));
      if (isOperatorFormTouched) dispatch(setOperatorDetailsFormTouched(false));
      if (isDetailsFormTouched) dispatch(setUserDetailsFormTouched(false));
      if (isFeaturesStaysFormTouched) dispatch(setStaysFeaturesFormTouched(false));

      dispatch(clearChangeOperatorFlags());
      dispatch(changeOperatorInitToggle(false));
    }
  };

  useEffect(init, [operators]);
  useEffect(() => {
    if (currentOperator !== currentOperatorCode && operatorChanged) {
      setCurrentOperator(currentOperatorCode);
    }
  }, [currentOperatorCode]);
  useEffect(clearOperatorFlags, [currentOperatorCode]);

  return (
    <>
      {openConfirmation && (
        <ChangeOperatorConfirm
          open={openConfirmation}
          onConfirmChange={handleChangeOperatorConfirm}
          onCancelChange={handleChangeOperatorCancel}
        />
      )}
      <ToolbarSection width={200} marginRight="3">
        {operators.length > 0 && (
          <Select name="operators" value={currentOperator} margin="dense" onChange={handleChangeOperator} cv="grey40">
            {operators.map(({ code, name }) => (
              <MenuItem key={code} value={code}>
                {code !== "ADMIN" ? `${name} (${code})` : name}
              </MenuItem>
            ))}
          </Select>
        )}
      </ToolbarSection>
    </>
  );
};

export { OperatorSelector };
