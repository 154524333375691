import React from "react";
import { Controller } from "react-hook-form";
import { Checkbox as CheckboxC } from "components/ui/Forms/Controls";

const Checkbox = ({ control, name, rules, label, style, value, checked, onChange }) => {
  return control ? (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, value } }) => (
        <CheckboxC value={value} onChange={onChange} inputProps={{ "aria-label": { label } }} label={label} style={style} />
      )}
    />
  ) : (
    <CheckboxC checked={checked} onChange={onChange} inputProps={{ "aria-label": { label } }} label={label} style={style} />
  );
};

export default Checkbox;
