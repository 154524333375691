import React from "react";
import PropTypes from "prop-types";

import { ConfirmationModal } from "components/ui/Modals/ConfirmationModal";
import { GLOBAL_CONTENT, SITE_LEAVE_WARNING_MESSAGES } from "constants/content";

const ChangeOperatorConfirm = ({ open, onConfirmChange, onCancelChange }) => (
  <>
    {open && (
      <ConfirmationModal
        open={open}
        confirmLabel={GLOBAL_CONTENT.proceed}
        title={SITE_LEAVE_WARNING_MESSAGES.createTitle(SITE_LEAVE_WARNING_MESSAGES.contexts.vamoosEditor)}
        onConfirm={onConfirmChange}
        onCancel={onCancelChange}
      />
    )}
  </>
);

ChangeOperatorConfirm.propTypes = {
  open: PropTypes.bool.isRequired,
  onCancelChange: PropTypes.func.isRequired,
  onConfirmChange: PropTypes.func.isRequired,
};

export { ChangeOperatorConfirm };
