import React from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import PropTypes from "prop-types";

import { CUSTOM_APPS } from "constants/content";
import { setValueForDefaultBgColor } from "store/customAppForm/actions";
import { usePermissionsService } from "hooks/usePermissionsService";
import { PERMISSIONS } from "constants/permissions";

import { CardSection, CardSectionsContainer, CustomCard, PreviewCardSection, SectionTitle, InputContentWrapper } from "../sharedComponents";
import { CustomColorPicker } from "../ColorPicker/CustomColorPicker";
import { SettingsPreview } from "../previews/SettingsPreview";

const StyledPreviewCardSection = styled(PreviewCardSection)`
  align-items: center;
  min-height: 200px;
`;

const BgColorOtherPages = ({ isHistoryVersion }) => {
  const permissionsService = usePermissionsService();

  const dispatch = useDispatch();

  const caStatus = useSelector(state => state.customAppForm?.customAppForm?.status);

  const canEdit = permissionsService.can(PERMISSIONS.actions.update, PERMISSIONS.sections.operator, PERMISSIONS.resources.operators);
  const canCreate = permissionsService.can(PERMISSIONS.actions.create, PERMISSIONS.sections.operator, PERMISSIONS.resources.operators);

  const editDisabled = !((caStatus === null && canCreate) || (caStatus === "draft" && canEdit));

  const onColorUpdate = value => {
    dispatch(setValueForDefaultBgColor(value));
  };

  const defaultBackground = useSelector(state => state.customAppForm.customAppForm.colors.defaultBackground);

  return (
    <CustomCard>
      <CardSectionsContainer>
        <CardSection>
          <InputContentWrapper>
            <SectionTitle>{CUSTOM_APPS.elements.otherPage.label}</SectionTitle>
            <CustomColorPicker
              color={defaultBackground}
              label={CUSTOM_APPS.elements.bgColorLabel}
              onColorChange={onColorUpdate}
              disabled={isHistoryVersion || editDisabled}
            />
          </InputContentWrapper>
        </CardSection>
        <StyledPreviewCardSection>
          <SettingsPreview />
        </StyledPreviewCardSection>
      </CardSectionsContainer>
    </CustomCard>
  );
};

BgColorOtherPages.defaultProps = {
  isHistoryVersion: false,
};

BgColorOtherPages.propTypes = {
  isHistoryVersion: PropTypes.bool,
};

export { BgColorOtherPages };
