// @ts-check

import { isObject, isString } from "contracts/types";

/**
 * @typedef {Object} PermissionObject
 * @property {boolean} create
 * @property {boolean} read
 * @property {boolean} update
 * @property {boolean} delete
 */

/**
 * Service for permissions parsing and handling
 */
export class PermissionsService {
  /**
   * @private
   */
  permissions;

  /**
   *
   * @param {*} user
   * @param {string} currentOperatorCode
   */
  constructor(user, currentOperatorCode) {
    this.permissions = {
      user: user?.operators?.find(operator => operator.code === currentOperatorCode)?.permissions || {},
      role: user?.operators?.find(operator => operator.code === currentOperatorCode)?.role?.permissions || {},
    };
  }

  /**
   * @param {string} category
   * @param {string|number} resource
   * @returns {PermissionObject}
   */
  getPermissions(permissionsSet, category, resource, id) {
    if (permissionsSet[category]) {
      if (permissionsSet[category][resource]) {
        if (isObject(permissionsSet[category][resource])) {
          if (permissionsSet[category][resource][id]) {
            return this.parsePermissions(permissionsSet[category][resource][id]);
          }

          return this.parsePermissions(permissionsSet[category][resource].default);
        }
        if (isString(permissionsSet[category][resource])) {
          return this.parsePermissions(permissionsSet[category][resource]);
        }
      }

      return this.parsePermissions(permissionsSet[category].default);
    }

    return {
      create: false,
      read: false,
      update: false,
      delete: false,
    };
  }

  /**
   * @param {string} operation
   * @param {string} section
   * @param {string|number} resource
   * @returns {boolean}
   */
  can(operation, section, resource, id = null) {
    return (
      (this.permissions.role ? this.getPermissions(this.permissions.role, section, resource, id)[operation] : false) ||
      (this.permissions.user ? this.getPermissions(this.permissions.user, section, resource, id)[operation] : false)
    );
  }

  hasUserGrantedPermission(operator, operation, section, resource, id = null) {
    const operatorPermissions = {
      user: operator?.permissions,
      role: operator?.role?.permissions,
    };

    return (
      (operatorPermissions?.role ? this.getPermissions(operatorPermissions.role, section, resource, id)[operation] : false) ||
      (operatorPermissions?.user ? this.getPermissions(operatorPermissions.user, section, resource, id)[operation] : false)
    );
  }

  /**
   * @private
   * @param {string} permissionString
   */
  // eslint-disable-next-line class-methods-use-this
  parsePermissions(permissionString) {
    return {
      create: !!permissionString?.includes("c"),
      read: !!permissionString?.includes("r"),
      update: !!permissionString?.includes("u"),
      delete: !!permissionString?.includes("d"),
    };
  }
}
