import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Grid from "@material-ui/core/Grid";
import { useNavigate } from "react-router-dom";

import { PanelTemplate } from "components/templates/Panel/PanelTemplate";
import { Switch } from "components/ui/Forms";
import { LoadingScreen } from "components/ui/LoadingScreen/LoadingScreen";
import { NoResultsMessage } from "components/ui/Messages/NoResultsMessage";

import { SearchField } from "feature/panel/_shared/SearchField/SearchField";
import { VamoosListNavigation } from "feature/panel/_shared/Navigations/VamoosListNavigation";
import { Actions } from "feature/panel/Trips/Index/Actions";
import { TableOfTrips } from "feature/panel/Trips/Index/TableOfTrips";

import { useTableHandlers } from "hooks/useTableHandlers";

import { getTripsStart } from "store/trips/actions";
import { CREATE_OR_UPDATE_TRIP_START, TRIPS_GET_TRIPS_START, TRIPS_GET_TRIPS_SUCCESS } from "store/trips/actionTypes";
import { pushErrorNotification, toggleTripRedirectChecked } from "store/app/actions";

import { GET_ITINERARIES_URL } from "constants/api";
import {
  CREATE_CONTENT_LABELS,
  EMPTY_LIST_MESSAGES_BASE,
  NAMES_OF_RESOURCES_LISTS,
  NO_RESULTS_FOUND_MESSAGE,
  TABLE_NAMES,
} from "constants/content";
import { DEFAULT_DEBOUNCE_DELAY } from "constants/defaults";
import { debounce } from "lodash";

import { PANEL_STAYS_PATH } from "constants/routes";
import { checkQueryParamExistence } from "utils/url";
import { usePermissionsService } from "hooks/usePermissionsService";
import { PERMISSIONS } from "constants/permissions";
import { getCurrentUserStart } from "store/auth/actions";
import { StayService } from "services/domain/StayService";
import notifications from "constants/notifications";
import { ShowArchivedContainer } from "./ShowArchivedContainer";
import { fetchStays } from "../../Stays/Index/helpers";
import { useService } from "../../../../hooks/useService";
import { Logger } from "../../../../services/application/Logger";

const TripsIndex = () => {
  const [searchValue, setSearchValue] = useState("");
  const [showArchived, setShowArchived] = useState(false);
  const [staysCount, setStaysCount] = useState(0);
  const [staysLoading, setStaysLoading] = useState(false);

  const permissionsService = usePermissionsService();

  const { listOfTrips, inProgress, finished, tripsCount, actionType } = useSelector(state => state.trips);
  const { currentOperatorCode } = useSelector(state => state.auth);
  const { tripRedirectChecked } = useSelector(state => state.app);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const staysService = useService(StayService);

  const tableHandlers = useTableHandlers(GET_ITINERARIES_URL, getTripsStart, {
    params: { type: TABLE_NAMES.trip },
    customSearchParam: "search",
    defaultOrderBy: "updated_at",
    defaultOrder: "desc",
  });

  const { handleSearch, handleChangeArchived, forceQuery, url } = tableHandlers;

  const staysTableHandlers = useTableHandlers();

  const canCreate = permissionsService.can(PERMISSIONS.actions.create, PERMISSIONS.sections.vamoosList, PERMISSIONS.resources.default);

  const handleToggleShowArchived = () => {
    setShowArchived(!showArchived);
    handleChangeArchived(!showArchived ? "on" : "off");
  };

  const refreshTripsListAfterCopyTrip = () => {
    if (finished && actionType === CREATE_OR_UPDATE_TRIP_START) {
      forceQuery();
    }
  };

  const tryRedirectToStaysList = () => {
    if (actionType === TRIPS_GET_TRIPS_SUCCESS && !tripRedirectChecked && !staysLoading) {
      dispatch(toggleTripRedirectChecked());
      if (!listOfTrips.length && staysCount > 0) {
        navigate(PANEL_STAYS_PATH);
      }
    }
  };

  const content = (() => {
    if (inProgress && !listOfTrips.length) return <LoadingScreen />;
    if (!listOfTrips.length && !canCreate) return null;
    if (!listOfTrips.length && canCreate)
      return (
        <NoResultsMessage>
          {checkQueryParamExistence(url, "search")
            ? NO_RESULTS_FOUND_MESSAGE
            : EMPTY_LIST_MESSAGES_BASE(CREATE_CONTENT_LABELS.trip, NAMES_OF_RESOURCES_LISTS.trip)}
        </NoResultsMessage>
      );
    return (
      <TableOfTrips
        trips={listOfTrips}
        tableHandlers={tableHandlers}
        count={tripsCount}
        isLoadingInProgress={inProgress && actionType === TRIPS_GET_TRIPS_START}
      />
    );
  })();

  const handleEncodedSearch = query => {
    setSearchValue(query);
    handleSearch(query, true);
  };

  const debounceSearchQuery = useRef(debounce(query => handleEncodedSearch(query), DEFAULT_DEBOUNCE_DELAY));

  const onSearch = query => {
    setSearchValue(query);
    if (query.length < 3 && query !== "") return;
    return debounceSearchQuery.current(query);
  };

  /**
   * To avoid wrong permissions when user has been invited to specific itinerary in meantime
   * @todo: Solution should be more generic
   */
  const refreshCurrentUserDetails = () => {
    dispatch(getCurrentUserStart());
  };

  const contextBar = {
    left: <SearchField onSearchChange={onSearch} value={searchValue} />,
    middle: VamoosListNavigation,
    right: Actions,
  };

  useEffect(refreshTripsListAfterCopyTrip, [actionType, finished]);
  useEffect(forceQuery, [currentOperatorCode]);
  useEffect(tryRedirectToStaysList, [actionType, staysLoading]);

  useEffect(refreshCurrentUserDetails, []);

  useEffect(() => {
    fetchStays({
      data: staysTableHandlers,
      setIsLoading: setStaysLoading,
      staysService,
    })
      .then(({ items }) => {
        setStaysCount(items.length);
      })
      .catch(e => {
        dispatch(pushErrorNotification(notifications.general[400]));
        Logger.debug(e);
      });
  }, []);

  return (
    <PanelTemplate contextBar={contextBar}>
      <Grid container justifyContent="center">
        <Grid item md={12} lg={11} xl={10}>
          <ShowArchivedContainer>
            <Switch
              name="show-archived"
              label="Show deleted"
              labelPlacement="start"
              checked={showArchived}
              onChange={handleToggleShowArchived}
            />
          </ShowArchivedContainer>

          {content}
        </Grid>
      </Grid>
    </PanelTemplate>
  );
};

export { TripsIndex };
