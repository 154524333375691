import React from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

import { EditAction, ViewAction } from "components/ui/Tables/TableRowActions";
import { Table, TableHeader, TableBody, TablePagination } from "components/ui/Tables";

import { PANEL_CUSTOM_APPS_EDIT_PATH_GENERAL } from "constants/routes";
import { CUSTOM_APPS, TABLE_ACTIONS_TITLES } from "constants/content";
import { setUrl } from "utils/url";

import { CustomAppsTableRow } from "./CustomAppsTableRow";

export const customAppsTableColumns = [
  { label: CUSTOM_APPS.tableHeaders.title, key: "name", show: true, sortable: true },
  { label: CUSTOM_APPS.tableHeaders.status, key: "status", show: true, sortable: true },
  { label: CUSTOM_APPS.tableHeaders.type, key: "type", show: true, sortable: true },
  { label: CUSTOM_APPS.tableHeaders.created, key: "created_at", show: true, sortable: true },
  { label: CUSTOM_APPS.tableHeaders.lastUpdated, key: "updated_at", show: true, sortable: true },
];

export const CustomAppsTable = ({ customApps, count, tableHandlers, canEdit }) => {
  const navigate = useNavigate();

  const { handleChangeOrder, handleChangePage, handleChangeRowsPerPage, rowsPerPage, page } = tableHandlers;

  const customAppActions = ({ item }) =>
    canEdit && (
      <>
        {item.status === "submitted" ? (
          <ViewAction clickAction={() => navigate(setUrl(PANEL_CUSTOM_APPS_EDIT_PATH_GENERAL, { id: item.id }))} />
        ) : (
          <EditAction
            clickAction={() => navigate(setUrl(PANEL_CUSTOM_APPS_EDIT_PATH_GENERAL, { id: item.id }))}
            tooltip={TABLE_ACTIONS_TITLES.editResource(TABLE_ACTIONS_TITLES.resourceTypes.customApp)}
            disabled={item.status === "submitted"}
          />
        )}
      </>
    );

  return (
    <Table
      url={PANEL_CUSTOM_APPS_EDIT_PATH_GENERAL}
      urlParams={["id"]}
      headers={customAppsTableColumns}
      list={customApps}
      actions={customAppActions}
      onChangeOrder={handleChangeOrder}
      onChangePage={handleChangePage}
      onChangeRowsPerPage={handleChangeRowsPerPage}
    >
      <TableHeader withActions />
      <TableBody rowComponent={CustomAppsTableRow} />
      <TablePagination count={count} page={page - 1} rowsPerPage={rowsPerPage} />
    </Table>
  );
};

CustomAppsTable.propTypes = {
  customApps: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  count: PropTypes.number.isRequired,
  canEdit: PropTypes.bool.isRequired,
  tableHandlers: PropTypes.shape({
    handleChangeOrder: PropTypes.func,
    handleChangeRowsPerPage: PropTypes.func,
    handleChangePage: PropTypes.func,
    rowsPerPage: PropTypes.number,
    page: PropTypes.number,
  }).isRequired,
};
