import React, { useContext } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import TableCell from "@material-ui/core/TableCell";
import { TableRow } from "components/ui/Tables";
import { TextLimiter } from "components/ui/TextLimiter";
import { LanguageContext } from "components/_shared/LanguageSelector/LanguageContext";
import { LocalisationService } from "services/LocalisationService";

const LocalisedTableCell = styled(TableCell)`
  && {
    ${({ $localised }) =>
      !$localised &&
      `
      opacity: 0.4;
    `}
  }
`;

const NotificationRowTemplate = ({ item }) => {
  const { currentLanguage } = useContext(LanguageContext);
  const localisationService = new LocalisationService(currentLanguage);

  const { type, id } = item;
  const content = localisationService.isPropertyLocalised(item, "content") ? localisationService.localise(item, "content") : item.content;
  const url = localisationService.isPropertyLocalised(item, "url") ? localisationService.localise(item, "url") : item.url;

  const isContentLocalised = localisationService.isPropertyLocalised(item, "content");
  const isUrlLocalised = localisationService.isPropertyLocalised(item, "url");

  const urlCell = type !== "forced" && (
    <LocalisedTableCell $localised={isUrlLocalised}>
      <TextLimiter text={url} maxChar={40} />
    </LocalisedTableCell>
  );

  return (
    <TableRow item={item}>
      <TableCell width="80px">{id}</TableCell>
      <LocalisedTableCell $localised={isContentLocalised}>
        <TextLimiter text={content} maxChar={50} />
      </LocalisedTableCell>
      {urlCell}
    </TableRow>
  );
};

NotificationRowTemplate.propTypes = {
  item: PropTypes.shape().isRequired,
};

export { NotificationRowTemplate };
