import React, { useContext } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { MENU_LABELS_ICONS_CAPTIONS } from "constants/content";
import { InformationField } from "feature/panel/Settings/_shared/InformationField";
import { BodyText } from "components/ui/Typography/Typography";
import { FieldValueWrapper } from "feature/panel/Settings/_shared/styledComponents";
import { IconImage } from "components/ui/Icons";
import { LocalisationService } from "services/LocalisationService";
import { LanguageContext } from "components/_shared/LanguageSelector/LanguageContext";

const FieldContentWrapper = styled.div`
  display: flex;
  max-width: 576px;
`;

const FieldContentName = styled(BodyText)`
  display: block;
  width: 320px;
  ${({ $localised }) =>
    !$localised &&
    `
    opacity: 0.4;
  `}
`;

const IconWrapper = styled.span`
  display: flex;
  align-items: center;
`;

const ButtonListElement = ({ button, onEditClick, isEditable }) => {
  const { currentLanguage } = useContext(LanguageContext);

  const { availableIcons } = useSelector(state => state.operator);
  const assignedIcon = availableIcons.find(icon => icon.id === button.icon_id) || {};

  const localisationService = new LocalisationService(currentLanguage);

  const isLabelLocalised = localisationService.isPropertyLocalised(button, "label");

  const label = isLabelLocalised ? localisationService.localise(button, "label") : button.label;

  return (
    <InformationField
      key={button.screen}
      title={MENU_LABELS_ICONS_CAPTIONS[button.screen]}
      onEditClick={isEditable ? () => onEditClick(button) : null}
    >
      <FieldContentWrapper>
        <FieldContentName $localised={isLabelLocalised}>{label}</FieldContentName>
        <FieldValueWrapper>
          <IconWrapper>
            <IconImage src={assignedIcon.url || ""} alt={button.screen} darkened />
            <span>{assignedIcon.name || ""}</span>
          </IconWrapper>
        </FieldValueWrapper>
      </FieldContentWrapper>
    </InformationField>
  );
};

ButtonListElement.defaultProps = {
  isEditable: true,
};

ButtonListElement.propTypes = {
  button: PropTypes.shape({
    screen: PropTypes.string,
    label: PropTypes.string,
    icon_id: PropTypes.number,
  }).isRequired,
  onEditClick: PropTypes.func.isRequired,
  isEditable: PropTypes.bool,
};

export { ButtonListElement, FieldContentWrapper, FieldContentName, IconWrapper };
