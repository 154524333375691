import React, { useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import { SortableHandler, SortableList, SortableListItem } from "components/ui/Lists";

import {
  ContentDivider,
  DirectoryWrapper,
  ReorderButton,
  StyledReorderIcon,
} from "feature/panel/Stays/_shared/DailyDirectoryVoucherShared/styledComponents";
import { TableActionsContainer } from "../Tables/TableRowActions";

const SortableTableHeader = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 56px;
  padding: 0 0 0 72px;
  border: 1px solid ${({ theme }) => theme.colors.grey10};
  border-radius: ${({ theme }) => `${theme.borderRadius}px ${theme.borderRadius}px 0 0`};
`;

const FlexContainer = styled.div`
  display: flex;
  width: 100%;
`;

const SortableTable = ({
  onSortEnd,
  list,
  disabled,
  rowListComponent: RowListComponent,
  actions: RowActions,
  headerComponent: HeaderComponent
}) => {

  const [sortedElementIndex, setSortedElementIndex] = useState(null);

  const onSortStart = ({ index }) => setSortedElementIndex(index);

  const handleSortEnd = ({ newIndex, oldIndex }) => {
    onSortEnd({ newIndex, oldIndex })
    setSortedElementIndex(null);
  }

  return (
    <div>
      <SortableTableHeader>
        {HeaderComponent && <HeaderComponent />}
      </SortableTableHeader>
      <SortableList lockAxis="y" onSortEnd={handleSortEnd} lockToContainerEdges useDragHandle updateBeforeSortStart={onSortStart}>
        {list.map((item, index) => (
          <SortableListItem index={index}>
            <DirectoryWrapper isSorted={index === sortedElementIndex}>
              <FlexContainer>
                {!disabled && (
                  <SortableHandler>
                    <ReorderButton>
                      <StyledReorderIcon />
                    </ReorderButton>
                  </SortableHandler>
                )}
                <RowListComponent item={item} index={index} />
              </FlexContainer>
              {
                RowActions && (
                  <ContentDivider>
                    <TableActionsContainer>
                      <RowActions item={item} />
                    </TableActionsContainer>
                  </ContentDivider>
                )
              }
            </DirectoryWrapper>
          </SortableListItem>
        ))}
      </SortableList>
    </div>
  )
}

SortableTable.defaultProps = {
  headerComponent: null,
  disabled: false,
}

SortableTable.propTypes = {
  onSortEnd: PropTypes.func.isRequired,
  list: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  disabled: PropTypes.bool,
  headerComponent: PropTypes.oneOfType([PropTypes.element, PropTypes.func, PropTypes.node]),
  rowListComponent: PropTypes.oneOfType([PropTypes.element, PropTypes.func, PropTypes.node]).isRequired,
  actions: PropTypes.oneOfType([PropTypes.element, PropTypes.func, PropTypes.node]).isRequired,
}

export {
  SortableTable
}