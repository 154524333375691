import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { ContentWrapper, Paper } from "feature/panel/Help/styledComponents";
import { BodyText2, H6 } from "components/ui/Typography/Typography";
import { FAQ } from "constants/content";

const FieldWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.setSpacing(10)}px;
  
  :last-of-type {
    margin-bottom: 0;
  }
`;

const Question = styled(H6)`
  && {
    margin-bottom: ${({ theme }) => theme.setSpacing(5)}px;
  }
`;

const Field = ({ question, answer }) => (
  <FieldWrapper>
    <Question>{question}</Question>
    <BodyText2 dangerouslySetInnerHTML={{ __html: answer }} />
  </FieldWrapper>
);

const Faq = ({ component: Component }) => (
  <Component>
    <ContentWrapper>
      {FAQ.map(element => (
        <Field
          key={element.question}
          question={element.question}
          answer={element.answer}
        />
        ))}
    </ContentWrapper>
  </Component>
  );

Faq.defaultProps = {
  component: Paper
};

Faq.propTypes = {
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.object])
};

Field.propTypes = {
  question: PropTypes.string.isRequired,
  answer: PropTypes.string.isRequired,
};

export { Faq, Field, FieldWrapper, Question };
