import React from "react";
import { useSelector } from "react-redux";

import { Paper, Grid } from "@material-ui/core";

import { StatisticsTable } from "./StatisticsTable";

const Statistics = () => {
  const operatorCodes = useSelector(state => state.adminOperatorDetails?.details?.operatorCodes);

  return (
    <Grid container justifyContent="center">
      <Grid item sm={10}>
        <Paper>{operatorCodes && <StatisticsTable operatorCodes={operatorCodes || []} />}</Paper>
      </Grid>
    </Grid>
  );
};

export { Statistics };
