import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { BrowserRouter } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { SITE_LEAVE_WARNING_MESSAGES } from "constants/content";
import { ConfirmationModal } from "components/ui/Modals/ConfirmationModal";
import { tryRouteChangeFinish } from "store/app/actions";
import {
  isInternalAdminOperatorRedirect,
  isInternalCustomAppRedirect,
  isInternalStayRedirect,
  isInternalTripRedirect,
} from "router/conditionalRedirectLogic";

const redirectModalDefaultState = {
  open: false,
  message: "",
  callback: null,
};

const Router = ({ children }) => {
  const redirectPath = useSelector(state => state.app.redirectPath);

  const dispatch = useDispatch();
  const [redirectModalConfig, setRedirectModalConfig] = useState(redirectModalDefaultState);

  const getUserConfirmation = (message, callback) => {
    setRedirectModalConfig({ open: true, message, callback });
  };

  const handleModalConfirmation = answer => {
    setRedirectModalConfig(redirectModalDefaultState);
    dispatch(tryRouteChangeFinish());
    redirectModalConfig.callback(answer);
  };

  const checkConfirmationNeeded = () => {
    const { pathname } = window.location;
    if (
      redirectPath &&
      redirectModalConfig.callback &&
      (isInternalTripRedirect(pathname, redirectPath) ||
        isInternalStayRedirect(pathname, redirectPath) ||
        isInternalAdminOperatorRedirect(pathname, redirectPath) ||
        isInternalCustomAppRedirect(pathname, redirectPath))
    ) {
      handleModalConfirmation(true);
    }
  };

  useEffect(checkConfirmationNeeded, [redirectPath]);

  return (
    <>
      <BrowserRouter getUserConfirmation={getUserConfirmation}>
        {children}
      </BrowserRouter>
      {redirectModalConfig.open && !!redirectPath && (
        <ConfirmationModal
          {...SITE_LEAVE_WARNING_MESSAGES.buttons}
          title={redirectModalConfig.message}
          open={redirectModalConfig.open && !!redirectPath}
          onCancel={() => handleModalConfirmation(false)}
          onConfirm={() => handleModalConfirmation(true)}
        />
      )}
    </>
  );
};

Router.propTypes = {
  children: PropTypes.node.isRequired,
};

export { Router };
