import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";

import { TimedNotificationForm } from "feature/panel/_shared/Notifications/TimedNotificationForm/TimedNotificationForm";
import { GpsNotificationForm } from "feature/panel/_shared/Notifications/GpsNotificationForm/GpsNotificationForm";

import { Tabs } from "components/ui/Tabs/Tabs";
import { Tab } from "components/ui/Tabs/Tab";
import { Content } from "components/ui/Content";
import { NAMES_OF_RESOURCES_LISTS } from "constants/content";

const NotificationsTemplate = ({ context, disabled }) => {
  const [currentTab, setCurrentTab] = useState(0);

  const renderSection =
    currentTab === 0 ? (
      <TimedNotificationForm disabled={disabled} context={context} />
    ) : (
      <GpsNotificationForm disabled={disabled} context={context} />
    );

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} md={10}>
        <Content margin={10}>
          <Tabs value={currentTab} onChange={(event, newValue) => setCurrentTab(newValue)} aria-label="notifications choice bar">
            <Tab label={NAMES_OF_RESOURCES_LISTS.notifications.timed} />
            <Tab label={NAMES_OF_RESOURCES_LISTS.notifications.gps} />
          </Tabs>
        </Content>
        <Grid container justifyContent="center">
          <Grid item xs={12}>
            {renderSection}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

NotificationsTemplate.propTypes = {
  context: PropTypes.shape({}).isRequired,
};

export { NotificationsTemplate };
