import { useState } from "react";
import { useDispatch } from "react-redux";

import { PANEL_TRIPS_ACTION_MESSAGES } from "constants/content";
import { CreationsService } from "services/CreationsService";

export const handleDownload = async (item, versionType) => {
  const { outputs } = item || {};
  if (outputs) {
    const { file } = outputs[versionType] ? outputs[versionType] : {};
    if (file) {
      CreationsService.invokeFileDownloading(file, item.name);
    }
    return true;
  }
  return false;
};

export const useDeleteHandler = (userId, passcode, dispatcher) => {
  const defaultDeleteModalData = { open: false, body: {} };

  const [deleteModal, setDeleteModal] = useState(defaultDeleteModalData);

  const dispatch = useDispatch();

  const handleDeleteToggle = creation => {
    const { open } = deleteModal;
    const body = open ? deleteModal.body : creation;
    setDeleteModal({ body, open: !open });
  };

  const handleDeleteConfirm = () => {
    dispatch(dispatcher({ reference_code: passcode, operator_code: userId, id: deleteModal.body.id }));
    setDeleteModal(defaultDeleteModalData);
  };

  const deleteConfirmationText = (() => {
    const { open, body } = deleteModal;
    const { deleteConfirmationBase } = PANEL_TRIPS_ACTION_MESSAGES;
    return open ? `${deleteConfirmationBase} document ${body.name}?` : "";
  })();

  return {
    modal: deleteModal,
    modalToggle: handleDeleteToggle,
    confirm: handleDeleteConfirm,
    confirmText: deleteConfirmationText,
  };
};
