import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import MenuItem from "@material-ui/core/MenuItem";

import { Select } from "components/ui/Forms";
import { SpacedTitle } from "feature/panel/_shared/Messaging/Components/SettingsWindow";
import { PageHeader } from "components/ui/PageHeader/PageHeader";
import { STAY_WIZARD_CONTENT } from "constants/content";
import { useDispatch, useSelector } from "react-redux";
import { 
  stayWizardSetDailyActivitiesRestriction, 
  stayWizardSetDirectoriesRestriction, 
  stayWizardSetVouchersRestriction 
} from "store/stayWizard/actions";
import { usePermissionsService } from "hooks/usePermissionsService";
import { PERMISSIONS } from "constants/permissions";

const SelectSpaceLimiter = styled.div`
  max-width: 800px;
`;

const RESTRICTION_TYPES = [
  { label: STAY_WIZARD_CONTENT.restrictions.alwaysShow, value: "always" },
  { label: STAY_WIZARD_CONTENT.restrictions.showDuringStay, value: "during" },
  { label: STAY_WIZARD_CONTENT.restrictions.passcodesOnly, value: "passcodes" },
  { label: STAY_WIZARD_CONTENT.restrictions.off, value: "off" },
];

const AccessRestrictionSection = ({ resource }) => {
  const dispatch = useDispatch();
  const permissionsService = usePermissionsService();

  const restrictionValue = useSelector(({ stayWizard }) => stayWizard.form?.[resource].show);

  const isWiped = useSelector(({ stayWizard }) => stayWizard.form.isWiped);
  const vamoosId = useSelector(({ stayWizard }) => stayWizard.form.vamoosId);
  const editMode = useSelector(({ stayWizard }) => stayWizard.form.vamoosId !== null)

  const canEdit = permissionsService.can(PERMISSIONS.actions.update, PERMISSIONS.sections.vamoosList, vamoosId);

  const handleRestrictionTypeChange = ({ target }) => {
    switch(resource) {
      case "directories":
        dispatch(stayWizardSetDirectoriesRestriction({ restrictionValue: target.value }));
        break;
      case "dailyActivities":
        dispatch(stayWizardSetDailyActivitiesRestriction({ restrictionValue: target.value }));
        break;
      case "vouchers":
        dispatch(stayWizardSetVouchersRestriction({ restrictionValue: target.value }));
        break;
      default:
        break;
    }
  };

  return (
    <PageHeader>
      <SpacedTitle>{STAY_WIZARD_CONTENT.restrictions.accessSettings}</SpacedTitle>
      <SelectSpaceLimiter>
        <Select 
          name="restriction" 
          label={STAY_WIZARD_CONTENT.restrictions.whenToDisplay} 
          value={restrictionValue} 
          onChange={handleRestrictionTypeChange}
          disabled={isWiped || (editMode && !canEdit)}
        >
          {RESTRICTION_TYPES.map(restrictionType => (
            <MenuItem key={restrictionType.value} value={restrictionType.value}>
              {restrictionType.label}
            </MenuItem>
          ))}
        </Select>
      </SelectSpaceLimiter>
    </PageHeader>
  );
};

AccessRestrictionSection.propTypes = {
  resource: PropTypes.oneOf([
    "directories",
    "dailyActivities",
    "vouchers"
  ]).isRequired,
};

export { AccessRestrictionSection, SelectSpaceLimiter, RESTRICTION_TYPES };
