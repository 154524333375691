import React from "react";
import { FLIGHT_LABELS } from "constants/content";
import { BodyText2 } from "components/ui/Typography/Typography";
import moment from "moment";
import { HttpClient } from "services/application/httpClient/httpClient";
import config from "config/app";

export const NotFoundText = ({ searchedFlightNumber }) => (
  <div>
    <BodyText2>
      <span>{FLIGHT_LABELS.notFoundInfo.line1}</span>
      <strong>{` ${searchedFlightNumber} `}</strong>
      <span>{FLIGHT_LABELS.notFoundInfo.line2}</span>
      <br />
      <span>{FLIGHT_LABELS.notFoundInfo.reasons.headline}</span>
    </BodyText2>
    <ul>
      <li>{FLIGHT_LABELS.notFoundInfo.reasons.reason1}</li>
      <li>{FLIGHT_LABELS.notFoundInfo.reasons.reason2}</li>
      <li>{FLIGHT_LABELS.notFoundInfo.reasons.reason3}</li>
      <li>{FLIGHT_LABELS.notFoundInfo.reasons.reason4}</li>
      <li>{FLIGHT_LABELS.notFoundInfo.reasons.reason5}</li>
    </ul>
  </div>
);

export const CONTENT_MARGIN_VALUES = {
  withFlights: 10,
  withoutFlights: 5,
};

export const onFlightAdd = async values => {
  const {
    carrier_code: { value },
    arrival_at,
    departure_time,
    date,
  } = values;

  const departure_at = moment(date)
    .add(departure_time.hours(), "hours")
    .add(departure_time.minutes(), "minutes")
    .add(departure_time.seconds(), "seconds");

  let arrivalAt = moment(date)
    .add(arrival_at.hours(), "hours")
    .add(arrival_at.minutes(), "minutes")
    .add(arrival_at.hours(), "seconds");

  if (moment(arrivalAt).unix() < moment(departure_at).unix()) {
    arrivalAt = moment(arrivalAt).add(1, "days");
  }

  const body = {
    ...values,
    arrival_at: moment(arrivalAt).format("YYYY-MM-DDTHH:mm:ss[Z]"),
    carrier_code: value,
    departure_at: moment(departure_at).format("YYYY-MM-DDTHH:mm:ss[Z]"),
    adjust_airport_local_times: true,
  };

  delete body.date;
  delete body.departure_time;

  const res = await HttpClient.post("/flight/manual", { ...body });
  const {
    carrier: { fs_code },
    carrier_flight_number,
    departure_date_local,
  } = res.data;

  const flightToListData = {
    fs: fs_code,
    number: carrier_flight_number,
    date: moment(departure_date_local).format(config.apiDateFormat),
    selectedFlights: [res.data],
  };

  return flightToListData;
};
