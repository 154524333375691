import React, { useContext, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import styled from "styled-components"

import {
  ListAltOutlined,
  TouchAppOutlined,
  PermMediaOutlined,
  VpnKeyOutlined,
  SortByAlphaOutlined,
  ScheduleOutlined,
  CardGiftcardOutlined,
  NotificationsNoneOutlined,
  LocationOnOutlined,
  LoyaltyOutlined,
  DeleteOutlined,
  PublicOutlined,
  GetAppOutlined,
  MailOutlined,
  LockOutlined,
} from "@material-ui/icons";

import {
  PANEL_STAYS_ACTIONS_PATH,
  PANEL_STAYS_EDIT_CONTENT_PATH,
  PANEL_STAYS_GALLERY_PARAM,
  PANEL_STAYS_PASSCODES_PARAM,
  PANEL_STAYS_DIRECTORIES_PARAM,
  PANEL_STAYS_DAILY_PARAM,
  PANEL_STAYS_VOUCHERS_PARAM,
  PANEL_STAYS_NOTIFICATIONS_PARAM,
  PANEL_STAYS_POIS_PARAM,
  PANEL_STAYS_MESSAGING_PARAM,
  PANEL_STAYS_DO_NOT_DISTURB_PARAM,
  PANEL_STAYS_INSPIRATIONS_PARAM,
  PANEL_STAYS_PUBLISHER_PARAM,
  PANEL_STAYS_CREATE_PATH,
  PANEL_STAYS_CREATE_POIS,
  PANEL_STAYS_CREATE_ACTIONS,
  PANEL_STAYS_CREATE_GALLERY,
  PANEL_STAYS_CREATE_DIRECTORIES,
  PANEL_STAYS_CREATE_DAILY,
  PANEL_STAYS_CREATE_VOUCHERS,
  PANEL_STAYS_CREATE_PASSCODES,
  PANEL_STAYS_CREATE_NOTIFICATIONS,
  PANEL_STAYS_CREATE_INSPIRATIONS,
  PANEL_STAYS_CREATE_DND,
  PANEL_STAYS_CREATE_PUBLISHER,
  PANEL_STAYS_CREATE_MESSAGING,
  PANEL_STAYS_EDIT_PATH,
  PANEL_STAYS_SHARE_ACCESS_PARAM,
  PANEL_STAYS_CREATE_SHARE_ACCESS,
} from "constants/routes";
import { setUrl } from "utils/url";
import { ContextNavigationList } from "components/ui/Lists/ContextNavigationList";
import { ERASE_DATA_DIALOG, GLOBAL_CONTENT, STAY_WIZARD_CONTENT } from "constants/content";
import { ConfirmationModal } from "components/ui/Modals/ConfirmationModal";

import { GENERAL_SECTION_ERROR_NAMES } from "feature/panel/Stays/_shared/helpers";
import { checkSectionHasError } from "feature/panel/_shared/helpers";
import { usePermissionsService } from "hooks/usePermissionsService";
import { PERMISSIONS } from "constants/permissions";
import { LanguageContext } from "components/_shared/LanguageSelector/LanguageContext";

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  justify-content: space-between;
`;

const StaysWizardNavigation = ({ isEditMode, onEraseConfirm, isStayActive, isStayDeleted, isStayMaster }) => {
  const { currentLanguage } = useContext(LanguageContext);

  const permissionsService = usePermissionsService();
  const [eraseModalOpen, setEraseModalOpen] = useState(false);
  const vamoosId = useSelector(({ stayWizard }) => stayWizard.form.vamoosId);
  const wizardErrors = useSelector(({ stayWizard }) => ({
    ...stayWizard.errors,
    ...(stayWizard.errors?.variants?.[currentLanguage.code] || {})
  }));
  const { pathname } = useLocation();

  const normalizedPathname = pathname.replace(/\/$/, "")

  const { operator_code } = useParams();

  const canEditVamoos = permissionsService.can(
    PERMISSIONS.actions.update, 
    PERMISSIONS.sections.vamoosList, 
    vamoosId
  );

  const canReadPois = permissionsService.can(
    PERMISSIONS.actions.read, 
    PERMISSIONS.sections.operator, 
    PERMISSIONS.resources.pois
  );

  const canReadNotifications = permissionsService.can(
    PERMISSIONS.actions.read, 
    PERMISSIONS.sections.operator, 
    PERMISSIONS.resources.notifications
  );

  const canReadInpirations = permissionsService.can(
    PERMISSIONS.actions.read, 
    PERMISSIONS.sections.operator, 
    PERMISSIONS.resources.inspirations
  );

  const list = [
    {
      url: isEditMode ? setUrl(PANEL_STAYS_EDIT_CONTENT_PATH, { operator_code, content: "" }, true) 
        : PANEL_STAYS_CREATE_PATH,
      label: STAY_WIZARD_CONTENT.navigation.general,
      icon: <ListAltOutlined />,
      isActive: normalizedPathname === (isEditMode 
        ? setUrl(PANEL_STAYS_EDIT_PATH, { operator_code, content: "" }, true)
        : PANEL_STAYS_CREATE_PATH).replace(/\/$/, ""),
      hasError: !!wizardErrors?.general || checkSectionHasError(GENERAL_SECTION_ERROR_NAMES, wizardErrors.general),
    },
    {
      url: isEditMode ? setUrl(PANEL_STAYS_EDIT_CONTENT_PATH, { operator_code, content: PANEL_STAYS_ACTIONS_PATH }, true) 
        : PANEL_STAYS_CREATE_ACTIONS,
      label: STAY_WIZARD_CONTENT.navigation.actions,
      icon: <TouchAppOutlined />,
      isActive: normalizedPathname === (isEditMode 
        ? setUrl(PANEL_STAYS_EDIT_CONTENT_PATH, { operator_code, content: PANEL_STAYS_ACTIONS_PATH }, true) 
        : PANEL_STAYS_CREATE_ACTIONS).replace(/\/$/, ""),
      hasError: !!wizardErrors?.actions,
      disabled: isStayDeleted,
    },
    ...(canReadPois ? [{
      url: isEditMode ? setUrl(PANEL_STAYS_EDIT_CONTENT_PATH, { operator_code, content: PANEL_STAYS_POIS_PARAM }, true) 
        : PANEL_STAYS_CREATE_POIS,
      label: STAY_WIZARD_CONTENT.navigation.pois,
      icon: <LocationOnOutlined />,
      isActive: normalizedPathname === (isEditMode 
        ? setUrl(PANEL_STAYS_EDIT_CONTENT_PATH, { operator_code, content: PANEL_STAYS_POIS_PARAM }, true) 
        : PANEL_STAYS_CREATE_POIS).replace(/\/$/, ""),
      disabled: isStayDeleted,
    }] : []),
    {
      url: isEditMode ? setUrl(PANEL_STAYS_EDIT_CONTENT_PATH, { operator_code, content: PANEL_STAYS_GALLERY_PARAM }, true) 
        : PANEL_STAYS_CREATE_GALLERY,
      label: STAY_WIZARD_CONTENT.navigation.gallery,
      icon: <PermMediaOutlined />,
      isActive: normalizedPathname === (isEditMode 
        ? setUrl(PANEL_STAYS_EDIT_CONTENT_PATH, { operator_code, content: PANEL_STAYS_GALLERY_PARAM }, true) 
        : PANEL_STAYS_CREATE_GALLERY).replace(/\/$/, ""),
      hasError: !!wizardErrors?.gallery,
      disabled: isStayDeleted,
    },
    {
      url: isEditMode 
        ? setUrl(PANEL_STAYS_EDIT_CONTENT_PATH, { operator_code, content: PANEL_STAYS_DIRECTORIES_PARAM }, true) 
        : PANEL_STAYS_CREATE_DIRECTORIES,
      label: STAY_WIZARD_CONTENT.navigation.directory,
      icon: <SortByAlphaOutlined />,
      isActive: normalizedPathname === (isEditMode 
        ? setUrl(PANEL_STAYS_EDIT_CONTENT_PATH, { operator_code, content: PANEL_STAYS_DIRECTORIES_PARAM }, true) 
        : PANEL_STAYS_CREATE_DIRECTORIES).replace(/\/$/, ""),
      disabled: isStayDeleted,
    },
    {
      url: isEditMode ? setUrl(PANEL_STAYS_EDIT_CONTENT_PATH, { operator_code, content: PANEL_STAYS_DAILY_PARAM }, true) 
        : PANEL_STAYS_CREATE_DAILY,
      label: STAY_WIZARD_CONTENT.navigation.dailyActivities,
      icon: <ScheduleOutlined />,
      isActive: normalizedPathname === (isEditMode 
        ? setUrl(PANEL_STAYS_EDIT_CONTENT_PATH, { operator_code, content: PANEL_STAYS_DAILY_PARAM }, true) 
        : PANEL_STAYS_CREATE_DAILY).replace(/\/$/, ""),
      disabled: isStayDeleted,
    },
    {
      url: isEditMode ? setUrl(PANEL_STAYS_EDIT_CONTENT_PATH, { operator_code, content: PANEL_STAYS_VOUCHERS_PARAM }, true) 
        : PANEL_STAYS_CREATE_VOUCHERS,
      label: STAY_WIZARD_CONTENT.navigation.vouchers,
      icon: <CardGiftcardOutlined />,
      isActive: normalizedPathname === (isEditMode 
        ? setUrl(PANEL_STAYS_EDIT_CONTENT_PATH, { operator_code, content: PANEL_STAYS_VOUCHERS_PARAM }, true) 
        : PANEL_STAYS_CREATE_VOUCHERS).replace(/\/$/, ""),
      disabled: isStayDeleted,
    },
    ...(isStayMaster ? [{
      url: isEditMode ? setUrl(PANEL_STAYS_EDIT_CONTENT_PATH, { operator_code, content: PANEL_STAYS_PASSCODES_PARAM }, true) 
        : PANEL_STAYS_CREATE_PASSCODES,
      label: STAY_WIZARD_CONTENT.navigation.passcodes,
      icon: <VpnKeyOutlined />,
      isActive: normalizedPathname === (isEditMode 
        ? setUrl(PANEL_STAYS_EDIT_CONTENT_PATH, { operator_code, content: PANEL_STAYS_PASSCODES_PARAM }, true) 
        : PANEL_STAYS_CREATE_PASSCODES).replace(/\/$/, ""),
      hasError: !!wizardErrors?.passcodes,
      disabled: isStayDeleted,
    }] : []),
    ...(canReadNotifications ? [{
      url: isEditMode 
        ? setUrl(PANEL_STAYS_EDIT_CONTENT_PATH, { operator_code, content: PANEL_STAYS_NOTIFICATIONS_PARAM }, true) 
        : PANEL_STAYS_CREATE_NOTIFICATIONS,
      label: STAY_WIZARD_CONTENT.navigation.notifications,
      icon: <NotificationsNoneOutlined />,
      isActive: normalizedPathname === (isEditMode 
        ? setUrl(PANEL_STAYS_EDIT_CONTENT_PATH, { operator_code, content: PANEL_STAYS_NOTIFICATIONS_PARAM }, true) 
        : PANEL_STAYS_CREATE_NOTIFICATIONS).replace(/\/$/, ""),
      hasError: !!wizardErrors?.timedNotifications || !!wizardErrors?.gpsNotifications,
      disabled: isStayDeleted,
    }] : []),
    ...(canReadInpirations ? [{
      url: isEditMode 
        ? setUrl(PANEL_STAYS_EDIT_CONTENT_PATH, { operator_code, content: PANEL_STAYS_INSPIRATIONS_PARAM }, true) 
        : PANEL_STAYS_CREATE_INSPIRATIONS,
      label: STAY_WIZARD_CONTENT.navigation.inspirations,
      icon: <PublicOutlined />,
      isActive: normalizedPathname === (isEditMode 
        ? setUrl(PANEL_STAYS_EDIT_CONTENT_PATH, { operator_code, content: PANEL_STAYS_INSPIRATIONS_PARAM }, true) 
        : PANEL_STAYS_CREATE_INSPIRATIONS).replace(/\/$/, ""),
      hasError: !!wizardErrors?.inspiration,
      disabled: isStayDeleted
    }] : []),
    ...(isStayMaster ? [{
      url: isEditMode 
        ? setUrl(PANEL_STAYS_EDIT_CONTENT_PATH, { operator_code, content: PANEL_STAYS_DO_NOT_DISTURB_PARAM }, true) 
        : PANEL_STAYS_CREATE_DND,
      label: STAY_WIZARD_CONTENT.navigation.dnd,
      icon: <LoyaltyOutlined />,
      isActive: normalizedPathname === (isEditMode 
        ? setUrl(PANEL_STAYS_EDIT_CONTENT_PATH, { operator_code, content: PANEL_STAYS_DO_NOT_DISTURB_PARAM }, true) 
        : PANEL_STAYS_CREATE_DND).replace(/\/$/, ""),
      hasError: !!wizardErrors?.dnd,
      disabled: isStayDeleted,
    }] : []),
    ...(isEditMode && isStayMaster ? [{
      url: isEditMode ? setUrl(PANEL_STAYS_EDIT_CONTENT_PATH, { operator_code, content: PANEL_STAYS_PUBLISHER_PARAM }, true) 
        : PANEL_STAYS_CREATE_PUBLISHER,
      label: STAY_WIZARD_CONTENT.navigation.publisher,
      icon: <GetAppOutlined />,
      isActive: normalizedPathname === (isEditMode 
        ? setUrl(PANEL_STAYS_EDIT_CONTENT_PATH, { operator_code, content: PANEL_STAYS_PUBLISHER_PARAM }, true) 
        : PANEL_STAYS_CREATE_PUBLISHER).replace(/\/$/, ""),
    }] : []),
    ...(isStayMaster ? [{
      url: isEditMode ? setUrl(PANEL_STAYS_EDIT_CONTENT_PATH, { operator_code, content: PANEL_STAYS_MESSAGING_PARAM }, true) 
        : PANEL_STAYS_CREATE_MESSAGING,
      label: STAY_WIZARD_CONTENT.navigation.messaging,
      icon: <MailOutlined />,
      isActive: normalizedPathname === (isEditMode 
        ? setUrl(PANEL_STAYS_EDIT_CONTENT_PATH, { operator_code, content: PANEL_STAYS_MESSAGING_PARAM }, true) 
        : PANEL_STAYS_CREATE_MESSAGING).replace(/\/$/, ""),
      hasError: !!wizardErrors?.messaging,
      disabled: isStayDeleted,
    }] : []),
    ...(isStayMaster ? [{
      url: isEditMode 
        ? setUrl(PANEL_STAYS_EDIT_CONTENT_PATH, { operator_code, content: PANEL_STAYS_SHARE_ACCESS_PARAM }, true) 
        : PANEL_STAYS_CREATE_SHARE_ACCESS,
      label: STAY_WIZARD_CONTENT.navigation.shareAccess,
      icon: <LockOutlined />,
      isActive: normalizedPathname === (isEditMode 
        ? setUrl(PANEL_STAYS_EDIT_CONTENT_PATH, { operator_code, content: PANEL_STAYS_SHARE_ACCESS_PARAM }, true) 
        : PANEL_STAYS_CREATE_SHARE_ACCESS).replace(/\/$/, ""),
      hasError: !!wizardErrors?.dnd,
      disabled: isStayDeleted,
    }] : []),
  ];

  const handleEraseDialogToggle = () => setEraseModalOpen(!eraseModalOpen);
  const handleDeleteToggle = () => {
    onEraseConfirm();
    handleEraseDialogToggle();
  };

  const bottomList = [
    { divider: true },
    {
      action: handleEraseDialogToggle,
      icon: <DeleteOutlined />,
      label: STAY_WIZARD_CONTENT.navigation.eraseData,
    },
  ];

  const eraseDataButton = isStayActive && <ContextNavigationList list={bottomList} />;

  return (
    <StyledWrapper>
      <ContextNavigationList list={list} />
      {canEditVamoos && eraseDataButton}
      {eraseModalOpen && (
        <ConfirmationModal
          open={eraseModalOpen}
          title={ERASE_DATA_DIALOG.title("stay")}
          description={ERASE_DATA_DIALOG.description("stay")}
          confirmLabel={GLOBAL_CONTENT.delete}
          onCancel={handleEraseDialogToggle}
          onConfirm={handleDeleteToggle}
        />
      )}
    </StyledWrapper>
  );
};

StaysWizardNavigation.defaultProps = {
  isStayMaster: true,
}

StaysWizardNavigation.propTypes = {
  // match: PropTypes.shape().isRequired,
  onEraseConfirm: PropTypes.func.isRequired,
  isStayActive: PropTypes.bool.isRequired,
  isStayDeleted: PropTypes.bool.isRequired,
  isEditMode: PropTypes.bool.isRequired,
  isStayMaster: PropTypes.bool
};

export { StaysWizardNavigation };
