import React from "react";
import styled from "styled-components";
import { ImageOutlined } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

import { CUSTOM_APPS, GLOBAL_CONTENT } from "constants/content";

import { AttachFilesButton } from "components/ui/Buttons";
import { IMAGES_MIME_TYPES } from "constants/defaults";
import { DEFAULT_LIBRARY_TABS } from "components/ui/LibraryPopup/LibraryPopup";
import { ChipWithRedirect } from "components/ui/Chips/ChipWithRedirect";

import { setValueForLogoImage } from "store/customAppForm/actions";
import { useRefreshedFileUrl } from "hooks/useRefreshedFileUrl";
import { usePermissionsService } from "hooks/usePermissionsService";
import { PERMISSIONS } from "constants/permissions";

import { isObjectEmpty } from "utils/object";
import { VamoosImage } from "domain/VamoosImage";

import {
  CustomCard,
  SectionTitle,
  CardSectionsContainer,
  CardSection,
  PreviewCardSection,
  HintWrapper,
  InputContentWrapper,
} from "../sharedComponents";

const LogoPreview = styled.img`
  max-width: 300px;
  max-height: 300px;
`;

const AppLogoSection = ({ isHistoryVersion }) => {
  const permissionsService = usePermissionsService();
  const dispatch = useDispatch();

  const caStatus = useSelector(state => state.customAppForm?.customAppForm?.status);
  const logoImage = useSelector(state => state.customAppForm.customAppForm.images.logo);
  const errors = useSelector(state => state.customAppForm.errors);
  const logoUrl = useRefreshedFileUrl(logoImage?.previewUrl);

  const canEdit = permissionsService.can(PERMISSIONS.actions.update, PERMISSIONS.sections.operator, PERMISSIONS.resources.operators);
  const canCreate = permissionsService.can(PERMISSIONS.actions.create, PERMISSIONS.sections.operator, PERMISSIONS.resources.operators);

  const editDisabled = !((caStatus === null && canCreate) || (caStatus === "draft" && canEdit));

  const handleChangeAttachment = event => {
    const image = VamoosImage(event[0]);

    dispatch(setValueForLogoImage(image));
  };
  const handleRemoveAttachment = () => {
    if (!(isHistoryVersion || editDisabled)) {
      dispatch(setValueForLogoImage(null));
    }
  };

  const renderAttachmentDeleter = () => (
    <ChipWithRedirect
      onDelete={handleRemoveAttachment}
      item={logoImage}
      maxChar={15}
      icon={<ImageOutlined />}
      editable={(caStatus === null && canCreate) || (caStatus === "draft" && canEdit)}
    />
  );

  const isLogoPresent = logoImage && !isObjectEmpty(logoImage);

  const renderAttachmentUploader = () => (
    <AttachFilesButton
      label={`${GLOBAL_CONTENT.attach}/${GLOBAL_CONTENT.link}`}
      size="small"
      onSelect={handleChangeAttachment}
      allowedFileTypes={IMAGES_MIME_TYPES}
      tabs={[DEFAULT_LIBRARY_TABS.library, DEFAULT_LIBRARY_TABS.files]}
      error={errors?.images?.logo || null}
      disabled={isHistoryVersion || editDisabled}
      returnEncoded={false}
      minResolution="900x300"
    />
  );

  return (
    <CustomCard>
      <CardSectionsContainer>
        <CardSection>
          <InputContentWrapper>
            <SectionTitle>{CUSTOM_APPS.general.logoSection.title}</SectionTitle>
            {isLogoPresent ? renderAttachmentDeleter() : renderAttachmentUploader()}
            <HintWrapper>{CUSTOM_APPS.general.logoSection.attachLogoHint}</HintWrapper>
          </InputContentWrapper>
        </CardSection>
        <PreviewCardSection>{isLogoPresent && <LogoPreview src={logoUrl} />}</PreviewCardSection>
      </CardSectionsContainer>
    </CustomCard>
  );
};

AppLogoSection.defaultProps = {
  isHistoryVersion: false,
};

AppLogoSection.propTypes = {
  isHistoryVersion: PropTypes.bool,
};

export { AppLogoSection };
