import React from "react";
import PropTypes from "prop-types";

import { ConfirmationModal } from "components/ui/Modals/ConfirmationModal";
import { CUSTOM_APPS, GLOBAL_CONTENT } from "constants/content";

const CustomAppsDeleteConfirmModal = ({ open, onCancel, onConfirm }) => {
  return (
    <ConfirmationModal
      title={CUSTOM_APPS.modals.deleteModalDescription}
      open={open}
      onCancel={onCancel}
      onConfirm={onConfirm}
      confirmLabel={GLOBAL_CONTENT.dismiss}
      fullWidth
      maxWidth="sm" 
    />
  )
}

CustomAppsDeleteConfirmModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired
}

export {
  CustomAppsDeleteConfirmModal
}