import React from "react";
import PropTypes from "prop-types";

import { EditAction, DeleteAction, SwitchAction } from "components/ui/Tables";
import { PANEL_TRIPS_ACTION_MESSAGES, TABLE_ACTIONS_TITLES } from "constants/content";
import { TableActionsContainer } from "components/ui/Tables/TableRowActions";
import { usePermissionsService } from "hooks/usePermissionsService";
import { PERMISSIONS } from "constants/permissions";
import { useService } from "hooks/useService";
import { PoiService } from "services/PoiService";
import { EventBus } from "services/application/EventBus";
import { PoiHasBeenUpdated } from "events/PoiHasBeenUpdated";
import { ConfirmationModal } from "components/ui/Modals/ConfirmationModal";
import { usePopupHandlers } from "hooks/usePopupHandlers";
import { PoiHasBeenDeleted } from "events/PoiHasBeenDeleted";
import { PoiHasBeenDisabledOrEnabled } from "events/PoiHasBeenDisabledOrEnabled";

const TableOfPoisRowActions = ({ onClickAction, item }) => {
  const permissionsService = usePermissionsService();

  const deleteModal = usePopupHandlers();

  const poiService = useService(PoiService);

  const handleClickDelete = () => {
    deleteModal.open();
  };

  const handleDeletePoi = async () => {
    await poiService.deletePoi(item.id);
    deleteModal.close();
    EventBus.dispatch(new PoiHasBeenDeleted());
  };

  const handleClickEdit = () => {
    deleteModal.open();
    onClickAction("edit", item);
  };

  const handleToggleDefaultActiveStatus = async () => {
    const { file, ...poiWithoutFile } = item;
    await poiService.updatePoi({
      ...(file === null ? poiWithoutFile : item),
      isDefaultOn: !item.isDefaultOn,
    });
    EventBus.dispatch(new PoiHasBeenDisabledOrEnabled());
  };

  return (
    <TableActionsContainer>
      {permissionsService.can(PERMISSIONS.actions.update, PERMISSIONS.sections.operator, PERMISSIONS.resources.pois) && (
        <>
          <SwitchAction clickAction={handleToggleDefaultActiveStatus} checked={item.isDefaultOn} />
          <EditAction clickAction={handleClickEdit} tooltip={TABLE_ACTIONS_TITLES.editResource(TABLE_ACTIONS_TITLES.resourceTypes.poi)} />
        </>
      )}
      {permissionsService.can(PERMISSIONS.actions.delete, PERMISSIONS.sections.operator, PERMISSIONS.resources.pois) && (
        <DeleteAction
          clickAction={handleClickDelete}
          tooltip={TABLE_ACTIONS_TITLES.deleteResource(TABLE_ACTIONS_TITLES.resourceTypes.poi)}
        />
      )}
      {deleteModal.isOpen && (
        <ConfirmationModal
          open={deleteModal.isOpen}
          title="Delete confirmation"
          confirmLabel="Delete"
          description={`${PANEL_TRIPS_ACTION_MESSAGES.deleteConfirmationBase} ${item ? item.name : null} POI?`}
          onCancel={deleteModal.close}
          onConfirm={handleDeletePoi}
        />
      )}
    </TableActionsContainer>
  );
};

TableOfPoisRowActions.propTypes = {
  onClickAction: PropTypes.func.isRequired,
  item: PropTypes.shape().isRequired,
};

export { TableOfPoisRowActions };
