import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Collapse } from "@material-ui/core";

import { Input, Checkbox, Switch } from "components/ui/Forms";
import { Content } from "components/ui/Content/Content";
import { PageEditorField } from "feature/panel/Trips/_shared/Creations/PageEditorField";
import { ImageSizePicker } from "feature/panel/Trips/_shared/Creations/ImageSizePicker";
import { ConfirmationModal } from "components/ui/Modals/ConfirmationModal";
import { BodyText, BodyText2 } from "components/ui/Typography/Typography";
import { MAX_INPUT_CHARACTERS } from "utils/validation";
import { CPFieldWrapper } from "feature/panel/_shared/CreationsAndPublishers/CPFieldWrapper";
import { GreySection } from "feature/panel/Trips/_shared/Creations/GreySection";

import { CREATIONS_CONTENT, GLOBAL_CONTENT } from "constants/content";
import { Message } from "components/ui/Messages";
import { UNSAVED_CHANGES_MESSAGE } from "constants/notifications";
import { useManageTripContext } from "feature/panel/Trips/_shared/ManageTripContext";
import { useDispatch, useSelector } from "react-redux";
import { usePayloadValidator } from "hooks/usePayloadValidator";
import { preUpdateTripPayload } from "utils/dataConverters";
import { createTripHandler } from "feature/panel/Trips/_shared/helpers";

const CreationCreatorSwitchWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.setSpacing(10)}px;
`;

const UnsavedChangessMessageWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  button {
    margin-top: 16px;
  }
`;

const CreationCreator = ({ open, onCancel, onConfirm, creation, setCreationValue }) => {
  const { id, fileName, showDays, storyboard, locations, frontPage, lastPage, tableOfContents } = creation;

  const dispatch = useDispatch();
  const { checkValidation } = usePayloadValidator("trips");
  const tripsForm = useManageTripContext();
  const isTripFormTouched = useSelector(state => state.trips.isTripFormTouched === true);
  const { timedNotificationsTemplates, gpsNotificationsTemplates } = useSelector(state => state.notifications);

  const onFileNameChange = ({ target }) => {
    if (target.value.length <= MAX_INPUT_CHARACTERS) {
      setCreationValue("fileName", target.value);
    }
  };
  const handleFirstPageFieldUpdate = (field, value) => {
    setCreationValue("frontPage", { ...frontPage, [field]: value });
  };
  const handleLastPageFieldUpdate = (field, value) => {
    setCreationValue("lastPage", { ...lastPage, [field]: value });
  };
  const handleShowDaysChange = () => setCreationValue("showDays", !showDays);
  const handleStoryboardShowChange = () =>
    setCreationValue("storyboard", {
      ...storyboard,
      show: !storyboard.show,
    });

  const handleUpdateTrip = async () => {
    const formData = { ...tripsForm, passcode: tripsForm.passcode.trim(), userId: tripsForm.userId.trim() };
    const payload = await preUpdateTripPayload(formData, [...timedNotificationsTemplates, ...gpsNotificationsTemplates]);
    createTripHandler(payload, checkValidation, dispatch);
  };

  const handleStoryboardSizeChange = value => setCreationValue("storyboard", { ...storyboard, imageSize: value });
  const handleLocationsChange = () => setCreationValue("locations", !locations);
  const handleTableOfContentsChange = () => setCreationValue("tableOfContents", !tableOfContents);

  const handleSaveTrip = () => {
    handleUpdateTrip();
  };

  const handleCancel = () => {
    onCancel();
  };

  const handleConfirm = () => {
    if (isTripFormTouched) {
      handleSaveTrip();
    } else {
      onConfirm();
    }
  };

  const shouldShowSpinnerWhenUpdate = false;

  const title = CREATIONS_CONTENT.title(id ? CREATIONS_CONTENT.editExisting : CREATIONS_CONTENT.generateNew);
  const confirmButtonText = id || isTripFormTouched ? GLOBAL_CONTENT.save : GLOBAL_CONTENT.generate;

  const unsavedChangesInfo = (
    <UnsavedChangessMessageWrapper>
      <Message text={UNSAVED_CHANGES_MESSAGE} type="info" />
    </UnsavedChangessMessageWrapper>
  );

  const creationForm = (
    <>
      <Content margin={10}>
        <Input
          label={CREATIONS_CONTENT.sections.fileName}
          type="text"
          value={fileName}
          onChange={onFileNameChange}
          name="creationName"
          isRequired
        />
      </Content>
      <CreationCreatorSwitchWrapper>
        <BodyText>{CREATIONS_CONTENT.sections.showDaysInDocument}</BodyText>
        <Switch checked={showDays} onChange={handleShowDaysChange} />
      </CreationCreatorSwitchWrapper>
      <Content>
        <BodyText2>{CREATIONS_CONTENT.sections.includeFollowingSections}</BodyText2>
      </Content>
      <Content>
        <CPFieldWrapper
          label={CREATIONS_CONTENT.sections.tableOfContents}
          control={<Checkbox checked={tableOfContents} onChange={handleTableOfContentsChange} />}
        />
      </Content>
      <Content margin={10}>
        <CPFieldWrapper
          label={CREATIONS_CONTENT.sections.storyboard}
          control={<Checkbox checked={storyboard.show} onChange={handleStoryboardShowChange} />}
        />
        <Collapse in={storyboard.show}>
          <GreySection>
            <ImageSizePicker
              imageSize={storyboard.imageSize}
              onImageSizeChange={handleStoryboardSizeChange}
              firstOptionLabel={CREATIONS_CONTENT.storyboardImageSize.firstOptionLabel}
              firstOptionValue={CREATIONS_CONTENT.storyboardImageSize.firstOptionValue}
              secondOptionLabel={CREATIONS_CONTENT.storyboardImageSize.secondOptionLabel}
              secondOptionValue={CREATIONS_CONTENT.storyboardImageSize.secondOptionValue}
            />
          </GreySection>
        </Collapse>
      </Content>
      <Content>
        <CPFieldWrapper
          label={CREATIONS_CONTENT.sections.locationsMap}
          control={<Checkbox checked={locations} onChange={handleLocationsChange} />}
        />
      </Content>
      <Content>
        <CPFieldWrapper
          label={CREATIONS_CONTENT.sections.frontPageNotes}
          control={<Checkbox checked={frontPage.show} onChange={() => handleFirstPageFieldUpdate("show", !frontPage.show)} />}
        />
        <PageEditorField open={frontPage.show} onChange={handleFirstPageFieldUpdate} text={frontPage.body} headline={frontPage.headline} />
      </Content>
      <CPFieldWrapper
        label={CREATIONS_CONTENT.sections.lastPageNotes}
        control={<Checkbox checked={lastPage.show} onChange={() => handleLastPageFieldUpdate("show", !lastPage.show)} />}
      />
      <PageEditorField open={lastPage.show} onChange={handleLastPageFieldUpdate} text={lastPage.body} headline={lastPage.headline} />
    </>
  );

  return open ? (
    <ConfirmationModal
      open={open}
      title={title}
      confirmLabel={confirmButtonText}
      confirmDisabled={!isTripFormTouched && !fileName.length}
      onCancel={handleCancel}
      onConfirm={handleConfirm}
      maxWidth="md"
      fullWidth
      disableEnforceFocus
      showSpinner={shouldShowSpinnerWhenUpdate}
    >
      {isTripFormTouched ? unsavedChangesInfo : creationForm}
    </ConfirmationModal>
  ) : null;
};

CreationCreator.propTypes = {
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  creation: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    fileName: PropTypes.string.isRequired,
    showDays: PropTypes.bool.isRequired,
    storyboard: PropTypes.shape().isRequired,
    locations: PropTypes.bool.isRequired,
    poisList: PropTypes.bool,
    frontPage: PropTypes.shape().isRequired,
    lastPage: PropTypes.shape().isRequired,
    tableOfContents: PropTypes.bool.isRequired,
  }).isRequired,
  setCreationValue: PropTypes.func.isRequired,
};

export { CreationCreator };
