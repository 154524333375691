import React, { useContext, useEffect } from "react";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Content } from "components/ui/Content/Content";
import { Radio } from "components/ui/Forms";
import { SignUpContext } from "feature/views/SignUp/SignUpContext";

const SignUpFormStep2 = () => {
  const {
    operator: {
      meta: { company_type: companyType },
    },
    handleChange,
    canGoNext
  } = useContext(SignUpContext);

  useEffect(() => {
    if(companyType) {
      canGoNext(true);
    } else {
      canGoNext(false)
    }
  }, [companyType])

  return (
    <Content>
      <RadioGroup aria-label="type" name="company_type" value={companyType} onChange={handleChange}>
        <FormControlLabel label="Travel company" control={<Radio checked={companyType === "travel"} value="travel" />} />
        <FormControlLabel label="Accommodation owner / manager" control={<Radio checked={companyType === "hotel"} value="hotel" />} />
        <FormControlLabel label="Event organiser" control={<Radio checked={companyType === "event"} value="event" />} />
        <FormControlLabel label="Relocation company" control={<Radio checked={companyType === "relocation"} value="relocation" />} />
        <FormControlLabel label="New user for existing organisation" control={<Radio checked={companyType === "user"} value="user" />} />
      </RadioGroup>
    </Content>
  );
};

export { SignUpFormStep2 };
