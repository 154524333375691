import React from "react";
import styled from "styled-components";
import Typography from "@material-ui/core/Typography";

import * as fonts from "config/theme/fonts";
import { setColorVariant } from "utils/styles";
import { colors } from "config/theme/colors";

const BaseTypography = styled(Typography)`
  color: ${({ cv }) => setColorVariant(cv)};
`;

const typography = element => styled(props => <BaseTypography component={element} {...props} />);

const { font, fontWeight, fontSize, lineHeight } = fonts;

const sharedStyles = `
  font-family: ${font};
`;

const headerSharedStyles = `
  ${sharedStyles}
  font-weight: ${fontWeight.semiBold};
`;

const textSharedStyles = `
  ${sharedStyles}
  font-weight: ${fontWeight.default};
`;

const H1 = typography("h1")`
  && {
    ${headerSharedStyles}
    font-size: ${fontSize.h1}px;
    line-height: ${lineHeight.h1}px;
  }
`;

const H2 = typography("h2")`
  && {
    ${headerSharedStyles}
    font-size: ${fontSize.h2}px;
    line-height: ${lineHeight.h2}px;
  }
`;

const H3 = typography("h3")`
  && {
    ${headerSharedStyles}
    font-size: ${fontSize.h3}px;
    line-height: ${lineHeight.h3}px;
  }
`;

const H4 = typography("h4")`
  && {
    ${headerSharedStyles}
    font-size: ${fontSize.h4}px;
    line-height: ${lineHeight.h4}px;
  }
`;

const H5 = typography("h5")`
  && {
    ${headerSharedStyles}
    font-size: ${fontSize.h5}px;
    line-height: ${lineHeight.h5}px;
  }
`;

const H6 = typography("h6")`
  && {
    ${headerSharedStyles}
    font-size: ${fontSize.h6}px;
    line-height: ${lineHeight.h6}px;
  }
`;

const TitleText = typography("div")`
  && {
    ${textSharedStyles}
    font-size: ${fontSize.title}px;
    line-height: ${lineHeight.title}px;
  }
`;

const TitleText2 = typography("div")`
  && {
    ${textSharedStyles}
    font-size: ${fontSize.title2}px;
    line-height: ${lineHeight.title2}px;
  }
`;

const TitleText3 = typography("div")`
  && {
    ${textSharedStyles}
    font-size: ${fontSize.title3}px;
    line-height: ${lineHeight.title3}px;
  }
`;

const TitleText4 = typography("div")`
  && {
    ${textSharedStyles}
    font-size: ${fontSize.title4}px;
    line-height: ${lineHeight.title4}px;
  }
`;

const LeadText = typography("div")`
  && {
    ${textSharedStyles}
    font-size: ${fontSize.lead}px;
    line-height: ${lineHeight.lead}px;
  }
`;

const BodyText = typography("div")`
  && {
    ${textSharedStyles}
    font-size: ${fontSize.body}px;
    line-height: ${lineHeight.body}px;
    letter-spacing: 0;
  }
`;

const BodyText2 = typography("div")`
  && {
    ${textSharedStyles}
    font-size: ${fontSize.body2}px;
    line-height: ${lineHeight.body2}px;
  }
`;

const BodyText3 = typography("div")`
  && {
    ${textSharedStyles}
    font-size: ${fontSize.body3}px;
    line-height: ${lineHeight.body3}px;
  }
`;

const CaptionText = typography("div")`
  && {
    ${textSharedStyles}
    font-size: ${fontSize.caption}px;
    line-height: ${lineHeight.caption}px;
  }
`;

const ErrorText = typography("div")`
  && {
    ${textSharedStyles}
    font-size: ${fontSize.body3}px;
    line-height: ${lineHeight.body3}px;
    color: ${colors.rootMuiError};
  }
`;

export {
  H1,
  H2,
  H3,
  H4,
  H5,
  H6,
  TitleText,
  TitleText2,
  TitleText3,
  TitleText4,
  LeadText,
  BodyText,
  BodyText2,
  BodyText3,
  CaptionText,
  ErrorText,
};
