import { createContext, useContext } from "react";

const SavingCoverContext = createContext({
  isVisible: false,
  show: () => {},
  hide: () => {}
});

const useSavingCoverContext = () => useContext(SavingCoverContext);

export { SavingCoverContext, useSavingCoverContext };
