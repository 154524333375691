import React from "react";
import styled from "styled-components";

import { ReactComponent as AddFromLibrary } from "assets/images/add_from_library_icon.svg";

const AddToLibraryWrapper = styled.div`

  height: 24px;

  svg {
    height: 24px;

    path {
      stroke: currentColor;
    }
    rect {
      fill: currentColor;
    } 
  }
`;

const AddFromLibraryIcon = () => {
  return (
    <AddToLibraryWrapper>
      <AddFromLibrary />
    </AddToLibraryWrapper>
  );
};

export {
  AddFromLibraryIcon
}