import React, { useContext, useReducer, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, FormHelperText } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { WatchLater } from "@material-ui/icons";
import styled from "styled-components";
import uuidv4 from "uuid";

import { SortableTable } from "components/ui/SortableTable/SortableTable";
import {
  stayWizardAddDailyActivitiesItem,
  stayWizardEditDailyActivitiesItem,
  stayWizardRemoveDailyActivitiesItem,
  stayWizardSortDailyActivities,
} from "store/stayWizard/actions";
import { BodyText2 } from "components/ui/Typography/Typography";
import { ConfirmationModal } from "components/ui/Modals/ConfirmationModal";
import { DeleteAction, EditAction } from "components/ui/Tables";
import { StickyWrapper } from "components/ui/Forms/FormSection";
import { NoResultsMessage } from "components/ui/Messages";
import { SecondaryButton } from "components/ui/Buttons";

import { initDirectory, WEEKDAY_VALUES_ARRAY } from "utils/directories";
import {
  CREATE_CONTENT_LABELS,
  EMPTY_LIST_MESSAGES_BASE,
  EMPTY_VARIANT_SECTION,
  GLOBAL_CONTENT,
  NAMES_OF_RESOURCES_LISTS,
  PANEL_TRIPS_ACTION_MESSAGES,
  STAY_WIZARD_CONTENT,
  TABLE_ACTIONS_TITLES,
  WEEKDAYS_ABBR,
} from "constants/content";
import { useSticky } from "hooks/useSticky";
import { useListOfLanguages } from "hooks/useListOfLanguages";

import { colors } from "config/theme/colors";

import { usePermissionsService } from "hooks/usePermissionsService";
import { PERMISSIONS } from "constants/permissions";
import { LanguageContext } from "components/_shared/LanguageSelector/LanguageContext";

import { AnchorLikeButton, ListRowSquareField } from "../styledComponents";
import { ContentDivider } from "../DailyDirectoryVoucherShared/styledComponents";

import { AccessRestrictionSection } from "../DailyDirectoryVoucherShared/AccessRestrictionSection";
import { dailyActivityReducer } from "./DailyActivityReducer";
import { DailyActivityDialog } from "./DailyActivityDialog";
import { WeekdayChip } from "./DailyListRow";

export const HeaderContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: auto;
`;

export const ActionsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.setSpacing(10)}px;
`;

export const DailyActivitiesTitle = styled.div`
  font-size: ${({ theme }) => theme.fonts.fontSize.h5}px;
  color: ${({ theme }) => theme.colors.black};
`;

export const DailyActivitiesHeaderContainer = styled(Grid)`
  ${({ disabled }) =>
    disabled
      ? `
    padding: 0 72px 0 4px;
  `
      : `
    padding: 0 150px 0 76px;
  `};
`;

const ActivityAnchor = styled.a`
  color: ${({ theme }) => theme.colors.grey100};
  ${({ isStoryboardEntry }) => (isStoryboardEntry ? "" : "cursor: pointer;")};
  :focus,
  :visited,
  :hover {
    color: ${({ theme }) => theme.colors.grey100};
  }
  :hover {
    text-decoration: underline;
  }
`;

const DailyActivities = () => {
  const headerRef = useRef();
  const dispatch = useDispatch();
  const permissionsService = usePermissionsService();
  const allLanguages = useListOfLanguages();

  const stickyPosition = "top";
  const stickyOffset = 128;

  const { currentLanguage } = useContext(LanguageContext);

  const defaultLanguageName = useSelector(({ stayWizard, operator }) => {
    if (stayWizard.defaultVariant.language) {
      return allLanguages.find(lang => lang.code === stayWizard.defaultVariant.language)?.name;
    }

    return allLanguages.find(lang => lang.code === operator.currentOperator.defaultLanguageCode)?.name;
  });

  const isSticky = useSticky(headerRef, stickyPosition, stickyOffset);

  const [creationModalOpen, setCreationModalOpen] = useState(false);
  const [activityType, setActivityType] = useState(null);
  const [deleteModal, setDeleteModal] = useState({ open: false, body: {} });
  const [currentDirectoryId, setCurrentDirectoryId] = useState(null);
  const [dailyActivityForm, dispatchLocal] = useReducer(dailyActivityReducer, initDirectory("daily", true));

  const dailyActivities = useSelector(({ stayWizard }) => stayWizard.form.dailyActivities.items || []);

  const isWiped = useSelector(({ stayWizard }) => stayWizard.form.isWiped);
  const vamoosId = useSelector(({ stayWizard }) => stayWizard.form.vamoosId);
  const editMode = useSelector(({ stayWizard }) => stayWizard.form.vamoosId !== null);

  const canRead = permissionsService.can(PERMISSIONS.actions.read, PERMISSIONS.sections.vamoosList, vamoosId);
  const canEdit = permissionsService.can(PERMISSIONS.actions.update, PERMISSIONS.sections.vamoosList, vamoosId);
  const canCreate = permissionsService.can(PERMISSIONS.actions.create, PERMISSIONS.sections.vamoosList, vamoosId);

  const isStayDefaultLanguage = useSelector(({ stayWizard }) =>
    stayWizard.defaultVariant.language ? stayWizard.defaultVariant.language === currentLanguage.code : true,
  );

  const openEditModal = (dailyActivity = null) => {
    setCreationModalOpen(true);
    setActivityType(dailyActivity === null ? "CREATE" : "EDIT");

    dispatchLocal({
      type: "setAllValues",
      payload: dailyActivity || initDirectory("daily", false),
    });
  };

  const handleChangeOrder = ({ newIndex, oldIndex }) => {
    dispatch(stayWizardSortDailyActivities({ newIndex, oldIndex }));
  };

  const handleModalConfirm = () => {
    if (dailyActivityForm.id) {
      dispatch(stayWizardEditDailyActivitiesItem({ editedActivity: dailyActivityForm }));
    } else if (currentDirectoryId) {
      dispatch(
        stayWizardAddDailyActivitiesItem({
          newDailyActivity: {
            ...dailyActivityForm,
            id: uuidv4(),
            parent_id: currentDirectoryId,
          },
        }),
      );
    } else {
      dispatch(
        stayWizardAddDailyActivitiesItem({
          newDailyActivity: {
            ...dailyActivityForm,
            id: uuidv4(),
          },
        }),
      );
    }
    setCreationModalOpen(false);
    setActivityType(null);
  };

  const handleModalCancel = () => {
    setCreationModalOpen(false);
    setActivityType(null);
  };

  const openDeleteModal = directory => {
    setDeleteModal({ body: directory, open: true });
  };

  const handleDeleteConfirm = () => {
    dispatch(stayWizardRemoveDailyActivitiesItem({ id: deleteModal.body.id }));
    setDeleteModal({ body: null, open: false });
  };

  const handleDeleteCancel = () => {
    setDeleteModal({ body: null, open: false });
  };

  const onDirectoryClick = directory => setCurrentDirectoryId(directory.id);

  const deleteTitle = deleteModal?.body?.name
    ? `${PANEL_TRIPS_ACTION_MESSAGES.deleteConfirmationBase} ${deleteModal?.body?.name} directory?`
    : "";

  const handleDailyActivitySet = (fieldName, value) => {
    dispatchLocal({
      type: "setValueFor",
      fieldName,
      value,
    });
  };

  const DailyActivityListRow = ({ item }) => {
    return (
      <>
        <ListRowSquareField>
          <WatchLater />
        </ListRowSquareField>
        <Grid alignItems="center" container>
          <Grid xs="4" item>
            <AnchorLikeButton onClick={() => onDirectoryClick(item)} disabled={!item.is_list}>
              {canRead ? (
                <ActivityAnchor onClick={() => openEditModal(item)}>{item.name}</ActivityAnchor>
              ) : (
                <BodyText2 cv={colors.grey100}>{item.name}</BodyText2>
              )}
            </AnchorLikeButton>
          </Grid>
          <Grid xs="4" alignItems="center" container item>
            <ContentDivider>
              {WEEKDAY_VALUES_ARRAY.map(weekday => (
                <WeekdayChip key={weekday} active={item.weekdays[weekday]}>
                  {WEEKDAYS_ABBR[weekday]}
                </WeekdayChip>
              ))}
            </ContentDivider>
          </Grid>
        </Grid>
      </>
    );
  };

  const DailyActivitiesTableHeader = ({ disabled }) => {
    return (
      <DailyActivitiesHeaderContainer disabled={disabled} alignItems="center" container>
        <Grid xs="4" item>
          <FormHelperText>{STAY_WIZARD_CONTENT.dailyActivities.title}</FormHelperText>
        </Grid>
        <Grid xs="4" alignItems="center" container item>
          <FormHelperText>{STAY_WIZARD_CONTENT.dailyActivities.days}</FormHelperText>
        </Grid>
      </DailyActivitiesHeaderContainer>
    );
  };

  const renderHeader = () => (
    <HeaderContentWrapper>
      <DailyActivitiesTitle>{STAY_WIZARD_CONTENT.dailyActivities.dailyActivities}</DailyActivitiesTitle>
      {!isWiped && (!editMode || canEdit) && (
        <SecondaryButton onClick={() => openEditModal(null)} id="add-directory-button">
          <AddIcon />
          {GLOBAL_CONTENT.add}
        </SecondaryButton>
      )}
    </HeaderContentWrapper>
  );

  const actions = ({ item }) => (
    <>
      {!isWiped && (!editMode || canEdit) && (
        <EditAction
          clickAction={() => openEditModal(item)}
          tooltip={TABLE_ACTIONS_TITLES.editResource(TABLE_ACTIONS_TITLES.resourceTypes.activity)}
        />
      )}
      {!isWiped && (!editMode || canEdit) && (
        <DeleteAction
          clickAction={() => openDeleteModal(item)}
          tooltip={TABLE_ACTIONS_TITLES.deleteResource(TABLE_ACTIONS_TITLES.resourceTypes.activity)}
        />
      )}
    </>
  );

  return (
    <>
      <AccessRestrictionSection resource="dailyActivities" />
      <Grid container justifyContent="center">
        <Grid item xs={12} md={10}>
          <ActionsHeader ref={headerRef}>
            {isSticky && (
              <StickyWrapper stickyVariant="normal" sticky={isSticky} offset={stickyOffset} position={stickyPosition}>
                <Grid container justifyContent="center">
                  <Grid xs={12} md={10} item>
                    {renderHeader()}
                  </Grid>
                </Grid>
              </StickyWrapper>
            )}
            {renderHeader()}
          </ActionsHeader>
          {dailyActivities.length > 0 ? (
            <SortableTable
              onSortEnd={handleChangeOrder}
              rowListComponent={DailyActivityListRow}
              list={dailyActivities}
              actions={actions}
              headerComponent={(...props) => <DailyActivitiesTableHeader disabled={!canEdit} {...props} />}
              disabled={!canEdit}
            />
          ) : (
            !(editMode && !canEdit) && (
              <>
                {!isStayDefaultLanguage && (
                  <NoResultsMessage height="100px">{EMPTY_VARIANT_SECTION(currentLanguage.name, defaultLanguageName)}</NoResultsMessage>
                )}
                <NoResultsMessage height="100px">
                  {EMPTY_LIST_MESSAGES_BASE(CREATE_CONTENT_LABELS.add, NAMES_OF_RESOURCES_LISTS.daily)}
                </NoResultsMessage>
              </>
            )
          )}
          <DailyActivityDialog
            open={creationModalOpen}
            directory={dailyActivityForm}
            onCancel={handleModalCancel}
            onConfirm={handleModalConfirm}
            setDirectoryValue={handleDailyActivitySet}
            currentDirectoriesList={dailyActivities}
            dailyActivitiesList={dailyActivities}
            currentDirectoryId={currentDirectoryId}
            canEdit={(canCreate && activityType === "CREATE") || (canEdit && activityType === "EDIT")}
          />
          {deleteModal.open && (
            <ConfirmationModal onCancel={handleDeleteCancel} open={deleteModal.open} onConfirm={handleDeleteConfirm} title={deleteTitle} />
          )}
        </Grid>
      </Grid>
    </>
  );
};

export { DailyActivities };
