import React from "react";

import { useManageTripContext } from "feature/panel/Trips/_shared/ManageTripContext";

import { ItineraryInspirations } from "feature/panel/_shared/ItineraryInspirations/ItineraryInspirations";
import { usePermissionsService } from "hooks/usePermissionsService";
import { PERMISSIONS } from "constants/permissions";
import { AccessDenied } from "components/templates/_shared/AccessDenied/AccessDenied";

export const Inspirations = () => {
  const { vamoos_id, editMode, is_wiped } = useManageTripContext();
  const permissionsService = usePermissionsService();

  const canEdit = permissionsService.can(PERMISSIONS.actions.update, PERMISSIONS.sections.vamoosList, vamoos_id);
  const canReadInspirations = permissionsService.can(
    PERMISSIONS.actions.read,
    PERMISSIONS.sections.operator,
    PERMISSIONS.resources.inspirations,
  );

  return canReadInspirations ? (
    <ItineraryInspirations disabled={is_wiped || (editMode && !canEdit)} contextObject={useManageTripContext} context="trips" />
  ) : (
    <AccessDenied />
  );
};
