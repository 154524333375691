import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { DialogTitle, DialogActions, DialogContent, Dialog } from "@material-ui/core";

import { Spinner } from "components/ui/Spinner/Spinner";
import { DialogTransition } from "components/ui/Dialogs";

import { LoadingArea } from "feature/panel/_shared/CreationsAndPublishers/styledComponents";

import { GhostButton } from "../Buttons/GhostButton";
import { BodyText, BodyText2 } from "../Typography/Typography";
import { Content } from "../Content/Content";
import { LoadingScreen } from "../LoadingScreen/LoadingScreen";

const Title = styled(BodyText)`
  &&& {
    font-weight: bold;
  }
`;
const Description = styled(BodyText2)`
  &&& {
    color: ${({ theme }) => theme.colors.grey50};
  }
`;

const StyledDialog = styled(Dialog)`
  && {
    .MuiPaper-root {
      padding: ${({ theme }) => theme.setSpacing(2)}px 0;
    }
  }
`;

/**
 *
 * @param {*} props
 * @returns
 */
const ConfirmationModal = ({
  title,
  description,
  onCancel,
  onConfirm,
  confirmLabel,
  cancelLabel,
  children,
  confirmDisabled,
  showSpinner,
  open,
  isLoading,
  ...rest
}) => {
  const descriptionBox = description && (
    <Content margin={5}>
      <Description>{description}</Description>
    </Content>
  );

  const dialogContent = (description || children) && (
    <DialogContent>
      {descriptionBox}
      {children}
    </DialogContent>
  );

  const onClose = (event, reason) => {
    if (reason === "escapeKeyDown" || reason === "closeButtonClick") {
      onCancel();
    }
  };

  return (
    <StyledDialog TransitionComponent={DialogTransition} onClose={onClose} open={open} {...rest}>
      {isLoading ? (
        <LoadingArea>
          <LoadingScreen />
        </LoadingArea>
      ) : (
        <>
          <DialogTitle>
            <Title>{title}</Title>
          </DialogTitle>
          {dialogContent}
          <DialogActions>
            {onCancel && (
              <GhostButton onClick={onCancel} cv="grey50" cvh="grey60">
                {cancelLabel}
              </GhostButton>
            )}
            <GhostButton onClick={onConfirm} disabled={confirmDisabled}>
              {showSpinner ? <Spinner size={28} cv="brand" /> : confirmLabel}
            </GhostButton>
          </DialogActions>
        </>
      )}
    </StyledDialog>
  );
};

ConfirmationModal.defaultProps = {
  confirmLabel: "Ok",
  cancelLabel: "Cancel",
  description: "",
  children: null,
  confirmDisabled: false,
  showSpinner: false,
  onCancel: null,
  isLoading: false,
};

ConfirmationModal.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  confirmLabel: PropTypes.string,
  cancelLabel: PropTypes.string,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func.isRequired,
  children: PropTypes.node,
  confirmDisabled: PropTypes.bool,
  showSpinner: PropTypes.bool,
  isLoading: PropTypes.bool,
};

export { ConfirmationModal, StyledDialog, Description, Title };
