import React, { useState, useEffect, useRef } from "react";
import { Grid } from "@material-ui/core";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setCustomAppCurrentLanguage } from "store/customAppForm/actions";
import { useQuery } from "@tanstack/react-query";

import { TermsAndConditions } from "./TermsAndCondition";
import { ColorBox } from "./ColorsBox";
import { FAQBox } from "./FaqBox";
import { AboutBox } from "./AboutBox";
import { CustomCard } from "../sharedComponents";
import LanguageSelectorCutsomApps from "../components/LanguageSelector";

const TextsPage = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  const { data: languages } = useQuery({
    queryKey: ["/app_languages"],
  });

  const queryParams = new URLSearchParams(location.search);
  const isHistoryVersion = queryParams.get("versionHistory");
  const [currentLanguage, setCurrentLanguage] = useState(null);
  const defaultLanguage = useSelector(state => state.customAppForm.customAppForm.default_language);

  const language = useRef();
  language.current = currentLanguage;

  const termsDefaultName = useRef();
  const aboutDefaultName = useRef();
  const faqDefaultName = useRef();
  const tranlsations = languages?.find(item => item.value === (language.current || language))?.translations;

  termsDefaultName.current = tranlsations?.terms;
  aboutDefaultName.current = tranlsations?.about;
  faqDefaultName.current = tranlsations?.faq;

  useEffect(() => {
    setCurrentLanguage(defaultLanguage);
    dispatch(setCustomAppCurrentLanguage(defaultLanguage));
  }, [defaultLanguage]);

  return (
    <Grid>
      <Grid>
        <ColorBox isHistoryVersion={isHistoryVersion} />
      </Grid>
      <div style={{ width: 450, padding: "0 40px" }}>
        <LanguageSelectorCutsomApps currentLanguage={currentLanguage} onChange={lang => setCurrentLanguage(lang)} />
      </div>
      <Grid>
        <TermsAndConditions isHistoryVersion={isHistoryVersion} language={language} defaultName={termsDefaultName} />
      </Grid>
      <Grid>
        <AboutBox isHistoryVersion={isHistoryVersion} language={language} defaultName={aboutDefaultName} />
      </Grid>
      <Grid>
        <FAQBox isHistoryVersion={isHistoryVersion} language={language} defaultName={faqDefaultName} />
      </Grid>
    </Grid>
  );
};

export { TextsPage };
