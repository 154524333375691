// @ts-check

import { isString } from "contracts/types";
import { ImageRequestDto } from "./ImageRequestDto";

/**
 * @param {Icon} icon
 * @returns {IconRequestDto} IconRequestDto object
 */
export function IconRequestDto(
  icon
) {
  /**
   * @type {IconRequestDto}
   */
  const dto = {};

  dto.name = icon.name;
  dto.image = isString(icon.image) ? icon.image.toString() : ImageRequestDto(icon.image, icon.id);

  return dto;
}