export const fetchStays = async ({ data, staysService, setIsLoading }) => {
  const { page, rowsPerPage, sortingOrder, sortingBy, showArchived, searchQuery } = data;

  setIsLoading(true);

  const res = await staysService.getStays(page, rowsPerPage, sortingOrder || "desc", sortingBy || "updated_at", showArchived, searchQuery);

  setIsLoading(false);

  return res;
};
