import styled from "styled-components";

export const DefaultIconWrapper = styled.div`
  width: 100%;
  height: 72px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ theme, error }) => theme.colors.withOpacity(error ? theme.colors.error : theme.colors.grey10, 0.3)};
  border-radius: ${({ theme }) => theme.borderRadius}px;
  padding: 0 24px;
  margin: 0 0 8px 0;
`;