import React, { useContext } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { FormControlLabel } from "@material-ui/core";

import { SortableListItem } from "components/ui/Lists";
import { Checkbox } from "components/ui/Forms/Controls";

import { TopFourContext } from "./TopFourContext";

const StyledFormControlLabel = styled(FormControlLabel)`
  &&& {
    && {
      .MuiTypography-body1 {
        font-family: Montserrat !important;
        letter-spacing: 0;
      }
    }
  }
`;

const FeaturesListItem = ({ feature, index, disabled }) => {
  const { toggleCheck, isFeatureDisabled, isFeatureChecked } = useContext(TopFourContext);

  const { id, name } = feature;

  return (
    <SortableListItem key={id} index={index} destination="popup" disabled={disabled}>
      <StyledFormControlLabel
        label={name}
        control={(
          <Checkbox
            checked={isFeatureChecked(id)}
            onChange={e => toggleCheck(id, e.target.checked)}
            disabled={isFeatureDisabled(id) || disabled}
          />
        )}
      />
    </SortableListItem>
  );
};

FeaturesListItem.defaultProps = {
  disabled: false,
};

FeaturesListItem.propTypes = {
  index: PropTypes.number.isRequired,
  feature: PropTypes.shape().isRequired,
  disabled: PropTypes.bool,
};

export { FeaturesListItem };
