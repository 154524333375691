import React from "react";
import { ArrowBackIos } from "@material-ui/icons";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { CUSTOM_APPS, GLOBAL_CONTENT } from "constants/content";
import { colors } from "config/theme/colors";

import { PhonePresentation, ContentWrapper, PhoneHeader, LeftSlot, MiddleSlot, RightSlot } from "../PhonePresentation";
import { HomeButton } from "../sharedComponents";
import { LoginPagePreviewButton } from "./LoginScreenPreview";

const PhoneContent = styled.div`
  background-color: white;
  padding: 10px;
  margin: 15px 10px;
  content: "";
  text-align: left;
`;

const SettingsPreview = () => {
  const defaultBackground = useSelector(state => state.customAppForm.customAppForm.colors.defaultBackground);
  const accentColor = useSelector(state => state.customAppForm.customAppForm.colors.accentColor);
  const user = useSelector(state => state.auth.user.username);

  const headerConfig = {
    visible: true,
    bgColor: "#ffffff",
    label: CUSTOM_APPS.elements.otherPage.previewHeaderLabel,
  };

  const homeButton = (
    <HomeButton color={accentColor}>
      <ArrowBackIos />
      {GLOBAL_CONTENT.home}
    </HomeButton>
  );

  return (
    <PhonePresentation>
      <>
        <PhoneHeader bgColor={headerConfig.bgColor} fontColor={headerConfig.fontColor}>
          <LeftSlot>{homeButton}</LeftSlot>
          <MiddleSlot>{headerConfig.label}</MiddleSlot>
          <RightSlot />
        </PhoneHeader>
        <ContentWrapper bgColor={defaultBackground}>
          <PhoneContent>
            <div style={{ fontSize: 16 }}>Your name</div>
            <div style={{ fontSize: 12, marginTop: 5 }}>
              Your name will appear whenever you will add notes and photos. It will be shared only with your traveling companions. You can
              always change it in the Settings menu.
              <LoginPagePreviewButton
                style={{
                  border: "1px solid #bbb",
                  borderRadius: 5,
                  margin: "10px auto 0 auto",
                  fontWeight: 600,
                  fontSize: 12,
                  height: 25,
                  padding: 6,
                }}
                color="#000"
                bgColor="#fff"
              >
                {user}
              </LoginPagePreviewButton>
            </div>
          </PhoneContent>
          <PhoneContent>
            <div style={{ fontSize: 16 }}>Notification</div>
            <div style={{ fontSize: 12, marginTop: 5 }}>
              Enable notifications in your device notification area.
              <LoginPagePreviewButton
                color="#fff"
                bgColor={accentColor}
                style={{ margin: "10px auto 0 auto", fontSize: 12, height: 25, padding: 6 }}
              >
                Open settings
              </LoginPagePreviewButton>
            </div>
          </PhoneContent>
        </ContentWrapper>
      </>
    </PhonePresentation>
  );
};

export { SettingsPreview };
