import React, { useState } from "react";
import PropTypes from "prop-types";
import { Menu, MenuItem } from "@material-ui/core";

import { BodyText2 } from "components/ui/Typography/Typography";

import { Spinner } from "components/ui/Spinner/Spinner";

import { EditAction, DeleteAction, RefreshAction, AddAction, TableActionsContainer } from "components/ui/Tables/TableRowActions";

import { useListActionsContext } from "feature/panel/_shared/CreationsAndPublishers/ListActionsContext";
import { WaitingWrapper, WaitingTextWrapper, SpinnerWrapper } from "feature/panel/_shared/CreationsAndPublishers/styledComponents";
import { TableRowDownloadAction } from "feature/panel/_shared/CreationsAndPublishers/TableRowDownloadAction";

import { TABLE_ACTIONS_TITLES } from "constants/content";

import { isObject } from "contracts/types";

const TableRowActions = ({ item, context, canEdit, canDelete, hasUnsavedChanges, onSelectRefreshItem }) => {
  const [openMenu, setOpenMenu] = useState(null);
  const { handleDelete, handleEdit, handleDownload, handleRefresh, handleAdd } = useListActionsContext();
  const handleRefreshClick = () => {
    if (hasUnsavedChanges) {
      onSelectRefreshItem({ open: true, body: item });
    } else {
      handleRefresh(item);
    }
  };
  const handleEditClick = () => handleEdit(item);
  const handleDeleteClick = () => handleDelete(item);

  const isCreationContext = context === "creation";

  const { job } = item;

  const handleMenuOpen = ({ currentTarget }) => setOpenMenu(currentTarget);

  const handleMenuClose = () => setOpenMenu(null);

  const checkJobStatus = status => job && isObject(job) && job.status === status;

  const handleAddClick = documentType => {
    if (isCreationContext) {
      handleAdd(item, documentType);
      handleMenuClose();
    }
  };

  return (
    <TableActionsContainer>
      {checkJobStatus("completed") && (
        <>
          {canEdit && (
            <RefreshAction
              clickAction={handleRefreshClick}
              tooltip={TABLE_ACTIONS_TITLES.refreshResource(TABLE_ACTIONS_TITLES.resourceTypes[context])}
            />
          )}
          <TableRowDownloadAction item={item} context={context} onDownload={handleDownload} />

          {isCreationContext && canEdit && (
            <>
              <Menu id="addMenu" anchorEl={openMenu} open={!!openMenu && openMenu.id === "add-button"} onClose={handleMenuClose}>
                <MenuItem onClick={() => handleAddClick("travel")}>Add to Travel documents</MenuItem>
                <MenuItem onClick={() => handleAddClick("destination")}>Add to Destination documents</MenuItem>
              </Menu>
              <AddAction clickAction={handleMenuOpen} id="add-button" tooltip={TABLE_ACTIONS_TITLES.addToDocuments} />
            </>
          )}
          {canEdit && (
            <EditAction
              clickAction={handleEditClick}
              tooltip={TABLE_ACTIONS_TITLES.editResource(TABLE_ACTIONS_TITLES.resourceTypes[context])}
            />
          )}
          {canDelete && (
            <DeleteAction
              clickAction={handleDeleteClick}
              tooltip={TABLE_ACTIONS_TITLES.deleteResource(TABLE_ACTIONS_TITLES.resourceTypes[context])}
            />
          )}
        </>
      )}
      {job && !checkJobStatus("completed") && !checkJobStatus("failed") && (
        <WaitingWrapper withBorder>
          <SpinnerWrapper>
            <Spinner size={20} />
          </SpinnerWrapper>
          <WaitingTextWrapper>
            <span>PDF generation: </span>
            <BodyText2 cv="grey50">
              <strong>in progress</strong>
            </BodyText2>
          </WaitingTextWrapper>
        </WaitingWrapper>
      )}
      {checkJobStatus("failed") && (
        <WaitingWrapper>
          <WaitingTextWrapper>
            <span>PDF generation: </span>
            <span>
              <BodyText2 cv="error">
                <strong>failed</strong>
              </BodyText2>
            </span>
          </WaitingTextWrapper>
          <SpinnerWrapper>
            <RefreshAction clickAction={handleRefreshClick} />
          </SpinnerWrapper>
          <DeleteAction
            clickAction={handleDeleteClick}
            tooltip={TABLE_ACTIONS_TITLES.deleteResource(TABLE_ACTIONS_TITLES.resourceTypes[context])}
          />
        </WaitingWrapper>
      )}

      {!job && (
        <>
          <TableRowDownloadAction item={item} context={context} onDownload={handleDownload} />
          {canDelete && (
            <DeleteAction
              clickAction={handleDeleteClick}
              tooltip={TABLE_ACTIONS_TITLES.deleteResource(TABLE_ACTIONS_TITLES.resourceTypes[context])}
            />
          )}
        </>
      )}
    </TableActionsContainer>
  );
};

TableRowActions.defaultProps = {
  canEdit: true,
  canDelete: true,
  hasUnsavedChanges: false,
  onSelectRefreshItem: () => {},
};

TableRowActions.propTypes = {
  item: PropTypes.shape().isRequired,
  context: PropTypes.oneOf(["creation", "publisher"]).isRequired,
  canEdit: PropTypes.bool,
  canDelete: PropTypes.bool,
  hasUnsavedChanges: PropTypes.bool,
  onSelectRefreshItem: PropTypes.func,
};

export { TableRowActions };
