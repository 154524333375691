import { deviceType } from "../../../utils/deviceDetect";

export const getTaleVarFromLS = (varName, tableId) => {
  const device = deviceType();

  const tables = JSON.parse(localStorage.getItem("v_tables"));

  const result = tables?.[device]?.[tableId]?.[varName];

  return result;
};
