import styled from "styled-components";
import {Paper as PaperMui} from "@material-ui/core";
import {BodyText2, H6} from "components/ui/Typography/Typography";

export const ContentWrapper = styled.div`
  max-width: 960px;
  margin: auto;
`;

export const Paper = styled(PaperMui)`
  && {
    max-width: 1600px;
    margin: auto;
    padding: ${({theme}) => theme.setSpacing(10)}px 0;
  }
`;

export const Header = styled (H6)`
  && {
    display: block;
    margin-bottom: ${({theme}) => theme.setSpacing(5)}px;
  }
`;

export const AnswerText = styled(BodyText2)`
  text-align: center;
`;

export const PageWrapper = styled(Paper)`
  && {
    min-height: 75vh;
  }
`;

export const Page = styled(ContentWrapper)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
