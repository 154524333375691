import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import MenuIcon from "@material-ui/icons/Menu";
import CachedOutlinedIcon from "@material-ui/icons/CachedOutlined";
import PlayForWorkOutlinedIcon from "@material-ui/icons/PlayForWorkOutlined";
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";

import { CUSTOM_APP_MENU_SAMPLE_STAYS, CUSTOM_APP_MENU_STRINGS } from "constants/content";

import presentationBg from "assets/images/ca_menu_bg.jpg";

import { PhonePresentation, bgDimmed } from "../PhonePresentation";

const MenuContainer = styled.div`
  background-color: ${({ bgColor }) => bgColor};
  color: ${({ textIconColor }) => textIconColor};
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 30%;
`;

const StyledMenuIcon = styled(MenuIcon)`
  && {
    color: ${({ theme }) => theme.colors.white};
    position: absolute;
    right: 40px;
    top: 20px;
    z-index: 2;
  }
`;

const StaysList = styled.ul`
  list-style: none;
  padding: 0px;
  font-size: ${({ theme }) => theme.setSpacing(2.5)}px;
  margin: ${({ theme }) => theme.setSpacing(3)}px 0;

  li {
    color: ${({ theme }) => theme.colors.white};
    height: ${({ theme }) => theme.setSpacing(7.5)}px;
    line-height: ${({ theme }) => theme.setSpacing(7.5)}px;
    vertical-align: middle;
    margin: 5px 0;
    padding: 0 ${({ theme }) => theme.setSpacing(4)}px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    text-align: left;

    &:first-of-type {
      background-color: ${({ color }) => color}20;
    }

    &:last-of-type {
      background-color: ${({ color }) => color}10;
    }
  }
`;

const MenuItemList = styled.ul`
  list-style: none;
  padding: 0px;
  font-size: ${({ theme }) => theme.setSpacing(2.5)}px;
  margin: ${({ theme }) => theme.setSpacing(3)}px 0;

  li {
    color: ${({ textIconColor }) => textIconColor};
    height: ${({ theme }) => theme.setSpacing(7.5)}px;
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: left;
    margin: ${({ theme }) => theme.setSpacing(1)}px 0;
    padding: 0 ${({ theme }) => theme.setSpacing(4)}px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    .MuiSvgIcon-root {
      font-size: ${({ theme }) => theme.setSpacing(4)}px !important;
      margin-left: -${({ theme }) => theme.setSpacing(1)}px;
      margin-right: ${({ theme }) => theme.setSpacing(1)}px;
    }
  }
`;

const Divider = styled.div`
  width: calc(100% - ${({ theme }) => theme.setSpacing(4)}px);
  margin-left: ${({ theme }) => theme.setSpacing(4)}px;
  height: 1px;
  background-color: ${({ color }) => color}20;
`;

const StyledPlayForWorkIcon = styled(PlayForWorkOutlinedIcon)`
  transform: rotate(180deg);
`;

const StyledCachedOutlinedIcon = styled(CachedOutlinedIcon)`
  transform: rotate(90deg);
`;

const BgContainer = styled.div`
  ${({ theme }) => bgDimmed(theme, presentationBg)}

  position: absolute;
  left: 70%;
  right: 0;
  top: 0;
  bottom: 0;
`;

const MenuOpenedPreview = () => {
  const [bgLoaded, setBgLoaded] = useState(false);

  const separationLineColor = useSelector(state => state.customAppForm.customAppForm.colors.menuDividerColour);
  const menuItineraryBackgroundColour = useSelector(state => state.customAppForm.customAppForm.colors.menuItineraryBackgroundColour);
  const menuTextColour = useSelector(state => state.customAppForm.customAppForm.colors.menuTextColour);
  const menuIconColour = useSelector(state => state.customAppForm.customAppForm.colors.menuIconColour);
  const menuBackgroundColour = useSelector(state => state.customAppForm.customAppForm.colors.menuBackgroundColour);
 
  const backgroundImage = new Image();

  backgroundImage.src = presentationBg;

  useEffect(() => {
    const handleBgLoad = () => {
      setBgLoaded(true);
    };

    backgroundImage.addEventListener("load", handleBgLoad);

    return () => {
      backgroundImage.removeEventListener("load", handleBgLoad);
    };
  }, [presentationBg]);

  const previewLoading = !!presentationBg && !bgLoaded;

  const menuContent = (
    <>
      <MenuContainer bgColor={menuBackgroundColour} textIconColor={menuTextColour}>
        <MenuItemList>
          <li>
            <StyledCachedOutlinedIcon />
            {CUSTOM_APP_MENU_STRINGS.menuRefresh}
          </li>
          <li>
            <SettingsOutlinedIcon />
            {CUSTOM_APP_MENU_STRINGS.menuSettings}
          </li>
        </MenuItemList>
        <Divider color={separationLineColor} />
        <MenuItemList>
          <li>
            <StyledPlayForWorkIcon />
            {CUSTOM_APP_MENU_STRINGS.menuLoadNew}
          </li>
        </MenuItemList>
        <StaysList color={menuItineraryBackgroundColour}>
          <li>{CUSTOM_APP_MENU_SAMPLE_STAYS.trip}</li>
          <li>{CUSTOM_APP_MENU_SAMPLE_STAYS.nested}</li>
        </StaysList>
        <MenuItemList>
          <li>{CUSTOM_APP_MENU_STRINGS.menuViewAll}</li>
        </MenuItemList>
        <Divider color={separationLineColor} />
        <MenuItemList>
          <li>{CUSTOM_APP_MENU_STRINGS.menuFaq}</li>
          <li>{CUSTOM_APP_MENU_STRINGS.menuTerms}</li>
          <li>{CUSTOM_APP_MENU_STRINGS.menuAbout}</li>
        </MenuItemList>
      </MenuContainer>
      <BgContainer />
      <StyledMenuIcon textIconColor={menuIconColour} />
    </>
  );

  return <PhonePresentation loading={previewLoading}>{menuContent}</PhonePresentation>;
};

export { MenuOpenedPreview };
