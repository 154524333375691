import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { ImageOutlined } from "@material-ui/icons";

import { ImagePreview, ImagePlaceholder } from "components/ui/Images";
import { BodyText2 } from "components/ui/Typography/Typography";
import { AttachFilesButton } from "components/ui/Buttons/AttachFilesButton";
import { ChipWithRedirect } from "components/ui/Chips/ChipWithRedirect";
import { DEFAULT_LIBRARY_TABS } from "components/ui/LibraryPopup/LibraryPopup";

import { getFilePreviewUrl } from "utils/library";

import { IMAGES_MIME_TYPES } from "constants/defaults";
import { ERRORS } from "constants/content";

const [width, height] = ["180px", "180px"];
const StyledImagePlaceholder = styled(ImagePlaceholder)`
  && {
    width: ${width};
    height: ${height};
  }
`;

const ContentContainer = styled.div`
  display: flex;
`;

const RightmostInnerContainer = styled.div`
  padding-left: ${({ theme }) => theme.setSpacing(6)}px;
`;

const TextContainer = styled.div`
  margin-top: ${({ theme }) => theme.setSpacing(4.5)}px;
`;

const UploadImageForm = ({ item, handleAddPicture, handleDeletePicture, hasError, disabled }) => {
  const { image } = item;
  const renderFileUploader = () => (
    <AttachFilesButton
      onSelect={files => handleAddPicture(files, item)}
      name="files"
      size="small"
      allowedFileTypes={IMAGES_MIME_TYPES}
      tabs={Object.values(DEFAULT_LIBRARY_TABS).splice(0, 2)}
      multiUpload
      error={hasError ? ERRORS.missingAttachment : null}
      disabled={disabled}
    />
  );

  const renderFileDeleter = () => (
    <ChipWithRedirect disabled={disabled} icon={<ImageOutlined />} onDelete={() => handleDeletePicture(item)} item={image} />
  );

  return (
    <ContentContainer>
      {image ? (
        <ImagePreview image={getFilePreviewUrl(image)} width={width} height={height} />
      ) : (
        <StyledImagePlaceholder hasError={hasError} />
      )}
      <RightmostInnerContainer>
        {image ? renderFileDeleter() : renderFileUploader()}
        <TextContainer>
          <BodyText2>Allowed extensions: jpg, jpeg, gif, png</BodyText2>
          <BodyText2>Maximum file size: 12 MB</BodyText2>
          <BodyText2>Recommended dimensions: 2048 x 2048 px</BodyText2>
        </TextContainer>
      </RightmostInnerContainer>
    </ContentContainer>
  );
};

UploadImageForm.propTypes = {
  item: PropTypes.shape(),
  handleAddPicture: PropTypes.func.isRequired,
  handleDeletePicture: PropTypes.func.isRequired,
  hasError: PropTypes.bool,
  disabled: PropTypes.bool,
};

UploadImageForm.defaultProps = {
  item: {},
  hasError: false,
  disabled: false,
};

export { UploadImageForm, ContentContainer, StyledImagePlaceholder, TextContainer };
