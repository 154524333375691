import styled from "styled-components";

const PresentationContent = styled.div`
  color: ${({ bodyTextColor }) => bodyTextColor};
  word-break: break-all;
  font-family: Montserrat !important;
  font-size: 10px;
  letter-spacing: 0;
  text-align: left;
  margin: 5px;

  h3 {
    color: ${({ headerTextColor }) => headerTextColor} !important;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.54;
    letter-spacing: normal;
  }
  a {
    /* color: ${({ theme, linkColor }) => linkColor || theme.colors.brand}; */
    color: inherit;
    text-decoration: underline;
  }
`;

const WysywigContent = styled.span``;

export { PresentationContent, WysywigContent };
