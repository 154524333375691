import React from "react";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import { Select, FormSection } from "components/ui/Forms";
import { useManageTripContext } from "feature/panel/Trips/_shared/ManageTripContext";
import { STORYBOARD_SHOW_ICONS_DEFAULT_ACCOUNT_VALUE, STORYBOARD_SHOW_ICONS_DISPLAYABLE_VALUES } from "constants/storyboard";
import { useSelector } from "react-redux";
import { transformStoryBoardDisplayableValue, transformStoryBoardIconShowValues } from "feature/panel/Trips/_shared/helpers";
import { usePermissionsService } from "hooks/usePermissionsService";
import { PERMISSIONS } from "constants/permissions";

const HomeScreenIcons = () => {
  const { setValueFor, meta, is_wiped, editMode, vamoos_id } = useManageTripContext();
  const currentOperator = useSelector(state => state.operator.currentOperator);

  const permissionsService = usePermissionsService();

  const canEdit = permissionsService.can(PERMISSIONS.actions.update, PERMISSIONS.sections.vamoosList, vamoos_id);

  const handleHomeScreenIconsChange = ({ target: { value } }) => {
    const newStoryboardIconShowValues = transformStoryBoardIconShowValues(value);
    setValueFor("meta", { ...meta, ...newStoryboardIconShowValues });
  };

  return (
    <Grid container justifyContent="center" data-testid="storyboard-homescreen-icons">
      <Grid item xs={12} md={10}>
        <FormSection title="Homescreen icons to show">
          <Grid item xs={12} md={6}>
            <Select
              name="homeScreenIcons"
              value={transformStoryBoardDisplayableValue(meta.show_story_board_summary, meta.show_story_board_daily)}
              displayEmpty
              onChange={handleHomeScreenIconsChange}
              disabled={is_wiped || (editMode && !canEdit)}
              data-testid="storyboard-homescreen-icons-dropdown"
            >
              <MenuItem value={STORYBOARD_SHOW_ICONS_DEFAULT_ACCOUNT_VALUE}>
                {`Account default (${transformStoryBoardDisplayableValue(
                  currentOperator.storyBoardView?.summary,
                  currentOperator.storyBoardView?.details,
                )})`}
              </MenuItem>
              {STORYBOARD_SHOW_ICONS_DISPLAYABLE_VALUES.map(({ value, label }) => (
                <MenuItem value={value} key={value}>
                  {label}
                </MenuItem>
              ))}
            </Select>
          </Grid>
        </FormSection>
      </Grid>
    </Grid>
  );
};

export { HomeScreenIcons };
