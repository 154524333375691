import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const StyledWrapper = styled.nav`
  min-width: 240px;
  max-width: 240px;
  z-index: 1;
  background-color: ${({ theme }) => theme.colors.withOpacity(theme.colors.grey10, 0.3)};
  border-right: 1px solid ${({ theme }) => theme.colors.grey10};
  box-sizing: border-box;
  overflow-y: auto;
`;

const Navigation = ({ content }) => (
  <StyledWrapper>{content}</StyledWrapper>
);

Navigation.propTypes = {
  content: PropTypes.oneOfType([PropTypes.element, PropTypes.object]).isRequired,
};

export { Navigation, StyledWrapper };
