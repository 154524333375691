import { MAX_WAYPOINTS_COUNT } from "constants/defaults";
import { FileReaderService } from "services/FileReaderService";

class GpxFileValidator {

  constructor(fileReaderService = new FileReaderService()) {
    this.fileReaderService = fileReaderService;
  }

  async validate(file) {
    const fileContent = await this.fileReaderService.read(file);

    const parser = new DOMParser();
    const doc = parser.parseFromString(fileContent, "text/xml");

    return !!doc.querySelector("gpx")
  }

  async validateWaypointsCount(file) {
    const fileContent = await this.fileReaderService.read(file);

    const parser = new DOMParser();
    const doc = parser.parseFromString(fileContent, "text/xml");

    return doc.querySelectorAll("trkpt, rtept")?.length <= MAX_WAYPOINTS_COUNT
  }
}

export {
  GpxFileValidator
}