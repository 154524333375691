import uuidv4 from "uuid";

import * as actionTypes from "store/stayWizard/actionTypes";
import { reducer, setState, globalReducerActions } from "utils/store";
import { setNewOrder } from "utils/sortable";
import { Stay } from "domain/Stay";

const createInitialStayWizardState = () => ({
  touched: false,
  isLoading: false,
  errors: {},
  form: new Stay(),
  defaultVariant: new Stay(),
});

const ReducerActions = globalReducerActions("stayWizard", createInitialStayWizardState(), {
  [actionTypes.STAY_WIZARD_SET_ERRORS](state, errors) {
    return setState(state, {
      ...state,
      errors,
    });
  },
  [actionTypes.FILL_STAY_WIZARD](state, stay) {
    return setState(state, {
      ...state,
      touched: false,
      isLoading: false,
      form: stay,
    });
  },
  [actionTypes.FILL_DEFAULT_VARIANT_WIZARD](state, defaultVariant) {
    return setState(state, {
      ...state,
      touched: false,
      isLoading: false,
      defaultVariant,
    });
  },
  [actionTypes.ENABLE_STAY_VARIANTS](state, languages) {
    return setState(state, {
      ...state,
      form: {
        ...state.form,
        variants: [
          ...state.form.variants.map(variant => ({
            ...variant,
            isActive: languages.some(lang => lang.code === variant.language)
          })),
          ...languages.filter(lang => !state.form.variants.some(variant => variant.language === lang.code)).map(lang => ({
            ...createInitialStayWizardState().form,
            background: state.form.background,
            vamoosId: state.form.vamoosId,
            language: lang.code,
            isActive: true
          }))
        ]
      }
    })
  },
  [actionTypes.SET_DEFAULT_STAY_VARIANT](state, defaultLanguage) {
    return setState(state, {
      ...state,
      touched: true,
      form: {
        ...state.form,
        variants: state.form.variants.map(variant => {
          return {
            ...variant,
            isDefault: variant.language === defaultLanguage.code
          }
        })
      },
    });
  },
  [actionTypes.SET_STAY_WIZARD_PROPERTY_NAME](state, { propertyName }) {
    return setState(state, {
      ...state,
      touched: true,
      form: {
        ...state.form,
        propertyName
      },
    });
  },
  [actionTypes.SET_STAY_WIZARD_USER_ID](state, userId) {
    return setState(state, {
      ...state,
      touched: true,
      form: {
        ...state.form,
        userId,
      },
    });
  },
  [actionTypes.INIT_STAY_WIZARD_USER_ID](state, userId) {
    return setState(state, {
      ...state,
      touched: false,
      form: {
        ...state.form,
        userId,
      },
    });
  },
  [actionTypes.SET_STAY_WIZARD_BRANDING_PROFILE_ID](state, brandingProfileId) {
    return setState(state, {
      touched: true,
      form: {
        ...state.form,
        brandingProfileId,
      },
    });
  },
  [actionTypes.SET_STAY_WIZARD_HOME_DESCRIPTION](state, { homeDescription }) {
    return setState(state, {
      ...state,
      touched: true,
      form: {
        ...state.form,
        homeDescription
      },
    });
  },
  [actionTypes.SET_STAY_WIZARD_PHONE](state, { phone }) {
    return setState(state, {
      ...state,
      touched: true,
      form: {
        ...state.form,
        phone
      },
    });
  },
  [actionTypes.SET_STAY_WIZARD_EMAIL](state, { email }) {
    return setState(state, {
      ...state,
      touched: true,
      form: {
        ...state.form,
        email
      },
    });
  },
  [actionTypes.SET_STAY_WIZARD_WEBSITE](state, { website }) {
    return setState(state, {
      ...state,
      touched: true,
      form: {
        ...state.form,
        website
      },
    });
  },
  [actionTypes.SET_STAY_WIZARD_TWITTER](state, { twitter }) {
    return setState(state, {
      ...state,
      touched: true,
      form: {
        ...state.form,
        twitter
      },
    });
  },
  [actionTypes.SET_STAY_WIZARD_FACEBOOK](state, { facebook }) {
    return setState(state, {
      ...state,
      touched: true,
      form: {
        ...state.form,
        facebook
      },
    });
  },
  [actionTypes.SET_STAY_WIZARD_INSTAGRAM](state, { instagram }) {
    return setState(state, {
      ...state,
      touched: true,
      form: {
        ...state.form,
        instagram
      },
    });
  },
  [actionTypes.STAY_WIZARD_SET_CHECK_IN_TIME](state, checkInTime) {
    return setState(state, {
      ...state,
      touched: true,
      form: {
        ...state.form,
        checkInTime,
      },
    });
  },
  [actionTypes.STAY_WIZARD_ALERT_EMAILS_SET_EMAILS](state, emails) {
    return setState(state, {
      ...state,
      touched: true,
      form: {
        ...state.form,
        alertEmails: {
          ...state.form.alertEmails,
          emails,
        },
      },
    });
  },
  [actionTypes.STAY_WIZARD_ALERT_EMAILS_TOGGLE_INCLUDE](state) {
    return setState(state, {
      ...state,
      touched: true,
      form: {
        ...state.form,
        alertEmails: {
          ...state.form.alertEmails,
          include: !state.form.alertEmails.include,
        },
      },
    });
  },
  [actionTypes.TOGGLE_STAY_WIZARD_REQUIRED_PERSONAL_DETAILS](state) {
    return setState(state, {
      ...state,
      touched: true,
      form: {
        ...state.form,
        requirePersonalDetails: !state.form.requirePersonalDetails,
      },
    });
  },
  [actionTypes.SET_STAY_WIZARD_ADDRESS](state, address) {
    return setState(state, {
      ...state,
      touched: true,
      form: {
        ...state.form,
        address,
      },
    });
  },
  [actionTypes.SET_STAY_WIZARD_COORDINATES](state, coords) {
    const { latitude, longitude } = coords;
    return setState(state, {
      ...state,
      touched: true,
      form: {
        ...state.form,
        coordinates: {
          latitude,
          longitude,
        },
      },
    });
  },
  [actionTypes.SET_STAY_WIZARD_INFO_BUTTON_LABEL](state, payload) {
    const { label, touch } = payload;
    return setState(state, {
      ...state,
      touched: touch,
      form: {
        ...state.form,
        hotelInfo: {
          ...state.form.hotelInfo,
          label,
        },
      },
    });
  },
  [actionTypes.SET_STAY_WIZARD_INFO_BUTTON_ICON](state, payload) {
    const { icon, touch } = payload;
    return setState(state, {
      ...state,
      touched: touch,
      form: {
        ...state.form,
        hotelInfo: {
          ...state.form.hotelInfo,
          icon,
        },
      },
    });
  },
  [actionTypes.SET_STAY_WIZARD_SHORT_DESCRIPTION](state, { shortDescription }) {
    return setState(state, {
      ...state,
      touched: true,
      form: {
        ...state.form,
        shortDescription
      },
    });
  },
  [actionTypes.SET_STAY_WIZARD_LONG_DESCRIPTION](state, { longDescription }) {
    return setState(state, {
      ...state,
      touched: true,
      form: {
        ...state.form,
        longDescription
      },
    });
  },
  [actionTypes.SET_STAY_WIZARD_NUMBER_OF_ROOMS](state, numberOfRooms) {
    return setState(state, {
      ...state,
      touched: true,
      form: {
        ...state.form,
        numberOfRooms,
      },
    });
  },
  [actionTypes.SET_STAY_WIZARD_LOGO](state, logo) {
    return setState(state, {
      ...state,
      touched: true,
      form: {
        ...state.form,
        logo,
      },
    });
  },
  [actionTypes.SET_STAY_WIZARD_BACKGROUND](state, background) {
    return setState(state, {
      ...state,
      touched: true,
      form: {
        ...state.form,
        background,
      },
    });
  },
  [actionTypes.SET_STAY_WIZARD_FEATURES](state, features) {
    return setState(state, {
      ...state,
      touched: true,
      form: {
        ...state.form,
        features,
      },
    });
  },
  [actionTypes.SET_STAY_WIZARD_ACTION_TITLE](state, { label }) {
    return setState(state, {
      ...state,
      touched: true,
      form: {
        ...state.form,
        actions: {
          ...state.form.actions,
          action: {
            ...state.form.actions.action,
            label,
          },
        },
      },
    });
  },
  [actionTypes.SET_STAY_WIZARD_BOOK_NOW_TITLE](state, { label }) {
    return setState(state, {
      ...state,
      touched: true,
      form: {
        ...state.form,
        actions: {
          ...state.form.actions,
          bookNow: {
            ...state.form.actions.bookNow,
            label,
          },
        },
      },
    });
  },
  [actionTypes.SET_STAY_WIZARD_ACTION_FILE](state, { file }) {
    return setState(state, {
      ...state,
      touched: true,
      form: {
        ...state.form,
        actions: {
          ...state.form.actions,
          action: {
            ...state.form.actions.action,
            label: state.form.actions.action.label || file?.file_name || "",
            file,
          },
        },
      },
    });
  },
  [actionTypes.SET_STAY_WIZARD_BOOK_NOW_FILE](state, { file }) {
    return setState(state, {
      ...state,
      touched: true,
      form: {
        ...state.form,
        actions: {
          ...state.form.actions,
          bookNow: {
            ...state.form.actions.bookNow,
            label: state.form.actions.bookNow.label || file?.file_name || "",
            file,
          },
        },
      },
    });
  },
  [actionTypes.SET_STAY_WIZARD_DEFAULT_ACTION_ICON](state, icon) {
    return setState(state, {
      ...state,
      form: {
        ...state.form,
        actions: {
          ...state.form.actions,
          action: {
            ...state.form.actions.action,
            icon,
          },
        },
      },
    });
  },
  [actionTypes.SET_STAY_WIZARD_ACTION_ICON](state, icon) {
    return setState(state, {
      ...state,
      touched: true,
      form: {
        ...state.form,
        actions: {
          ...state.form.actions,
          action: {
            ...state.form.actions.action,
            icon,
          },
        },
      },
    });
  },
  [actionTypes.SET_STAY_WIZARD_DEFAULT_BOOK_NOW_ICON](state, icon) {
    return setState(state, {
      ...state,
      form: {
        ...state.form,
        actions: {
          ...state.form.actions,
          bookNow: {
            ...state.form.actions.bookNow,
            icon,
          },
        },
      },
    });
  },
  [actionTypes.SET_STAY_WIZARD_BOOK_NOW_ICON](state, icon) {
    return setState(state, {
      ...state,
      touched: true,
      form: {
        ...state.form,
        actions: {
          ...state.form.actions,
          bookNow: {
            ...state.form.actions.bookNow,
            icon,
          },
        },
      },
    });
  },
  [actionTypes.STAY_WIZARD_ADD_GALLERY_ITEM](state, { id }) {
    return setState(state, {
      ...state,
      touched: true,
      form: {
        ...state.form,
        gallery: [
          ...state.form.gallery,
          {
            id,
            headline: "",
            isNewItem: true,
          },
        ],
      },
    });
  },
  [actionTypes.STAY_WIZARD_REMOVE_GALLERY_ITEM](state, { id }) {
    return setState(state, {
      ...state,
      touched: true,
      form: {
        ...state.form,
        gallery: [...state.form.gallery.filter(galleryItem => galleryItem.id !== id)],
      },
    });
  },
  [actionTypes.STAY_WIZARD_UPDATE_GALLERY_ITEM_TITLE](state, { headline, id }) {
    return setState(state, {
      ...state,
      touched: true,
      form: {
        ...state.form,
        gallery: [...state.form.gallery.map(galleryItem => (galleryItem.id === id ? { ...galleryItem, headline } : galleryItem))],
      },
    });
  },
  [actionTypes.STAY_WIZARD_UPDATE_GALLERY_PICTURES](state, { files, id }) {
    const [firstFile, ...restFiles] = files;

    return setState(state, {
      ...state,
      touched: true,
      form: {
        ...state.form,
        gallery: [
          ...state.form.gallery.map(galleryItem => (galleryItem.id === id ? { ...galleryItem, image: firstFile } : galleryItem)),
          ...restFiles.map(file => ({
            id: uuidv4(),
            headline: "",
            isNewItem: true,
            image: file,
          })),
        ],
      },
    });
  },
  [actionTypes.STAY_WIZARD_SORT_GALLERY](state, { oldIndex, newIndex }) {
    return setState(state, {
      ...state,
      touched: true,
      form: {
        ...state.form,
        gallery: [...setNewOrder(newIndex, oldIndex, state.form.gallery).map((image, index) => ({ ...image, position: index }))],
      },
    });
  },
  [actionTypes.STAY_WIZARD_REMOVE_GALLERY_ITEM_PICTURE](state, { id }) {
    return setState(state, {
      ...state,
      touched: true,
      form: {
        ...state.form,
        gallery: [...state.form.gallery.map(galleryItem => (galleryItem.id === id ? { ...galleryItem, image: null } : galleryItem))]
      },
    });
  },
  [actionTypes.STAY_WIZARD_SET_DIRECTORIES_RESTRICTION](state, { restrictionValue }) {
    return setState(state, {
      ...state,
      touched: true,
      form: {
        ...state.form,
        directories: {
          ...state.form.directories,
          show: restrictionValue,
        },
      },
    });
  },
  [actionTypes.STAY_WIZARD_ADD_DIRECTORIES_ITEM](state, { newDirectory }) {
    return setState(state, {
      ...state,
      touched: true,
      form: {
        ...state.form,
        directories: {
          ...state.form.directories,
          items: [...state.form.directories.items, newDirectory],
        },
      },
    });
  },
  [actionTypes.STAY_WIZARD_REMOVE_DIRECTORIES_ITEM](state, { id }) {
    const collectDirectoriesToRemove = (directoryId, directories) => {
      return [
        directoryId,
        ...directories.filter(dir => dir.parent_id === directoryId).map(dir => collectDirectoriesToRemove(dir.id, directories)),
      ].flat();
    };

    const directoriesToRemove = collectDirectoriesToRemove(id, state.form.directories.items);

    return setState(state, {
      ...state,
      touched: true,
      form: {
        ...state.form,
        directories: {
          ...state.form.directories,
          items: state.form.directories.items.filter(dir => !directoriesToRemove.some(dirId => dir.id === dirId)),
        },
      },
    });
  },
  [actionTypes.STAY_WIZARD_EDIT_DIRECTORIES_ITEM](state, { editedDirectory }) {
    const indexOfDir = state.form.directories.items.findIndex(dir => dir.tag === editedDirectory.tag);

    return setState(state, {
      ...state,
      touched: true,
      form: {
        ...state.form,
        directories: {
          ...state.form.directories,
          items: [
            ...state.form.directories.items.slice(0, indexOfDir),
            editedDirectory,
            ...state.form.directories.items.slice(indexOfDir + 1),
          ],
        },
      },
    });
  },
  [actionTypes.STAY_WIZARD_SORT_DIRECTORIES](state, { oldIndex, newIndex }) {
    return setState(state, {
      ...state,
      touched: true,
      form: {
        ...state.form,
        directories: {
          ...state.form.directories,  
          items: setNewOrder(newIndex, oldIndex, state.form.directories.items),
        },
      },
    });
  },
  [actionTypes.STAY_WIZARD_SET_DAILY_ACTIVITIES_RESTRICTION](state, { restrictionValue }) {
    return setState(state, {
      ...state,
      touched: true,
      form: {
        ...state.form,
        dailyActivities: {
          ...state.form.dailyActivities,
          show: restrictionValue,
        },
      },
    });
  },
  [actionTypes.STAY_WIZARD_ADD_DAILY_ACTIVITIES_ITEM](state, { newDailyActivity }) {
    return setState(state, {
      ...state,
      touched: true,
      form: {
        ...state.form,
        dailyActivities: {
          ...state.form.dailyActivities,
          items: [...state.form.dailyActivities.items, newDailyActivity],
        },
      },
    });
  },
  [actionTypes.STAY_WIZARD_REMOVE_DAILY_ACTIVITIES_ITEM](state, { id }) {
    const collectDailyActivitiesToRemove = (dailyActivityId, dailyActivities) => {
      return [
        dailyActivityId,
        ...dailyActivities
          .filter(dir => dir.parent_id === dailyActivityId)
          .map(dir => collectDailyActivitiesToRemove(dir.id, dailyActivities)),
      ].flat();
    };

    const dailyActivitiesToRemove = collectDailyActivitiesToRemove(
      id, 
      state.form.dailyActivities.items
    );

    return setState(state, {
      ...state,
      touched: true,
      form: {
        ...state.form,
        dailyActivities: {
          ...state.form.dailyActivities,
          items: state.form.dailyActivities.items.filter(dir => !dailyActivitiesToRemove.some(dirId => dir.id === dirId)),
        },
      },
    });
  },
  [actionTypes.STAY_WIZARD_EDIT_DAILY_ACTIVITIES_ITEM](state, { editedActivity }) {
    const indexOfActivity = state.form.dailyActivities.items.findIndex(dir => dir.tag === editedActivity.tag);

    return setState(state, {
      ...state,
      touched: true,
      form: {
        ...state.form,
        dailyActivities: {
          ...state.form.dailyActivities,
          items: [
            ...state.form.dailyActivities.items.slice(0, indexOfActivity),
            editedActivity,
            ...state.form.dailyActivities.items.slice(indexOfActivity + 1),
          ],
        },
      },
    });
  },
  [actionTypes.STAY_WIZARD_SORT_DAILY_ACTIVITIES](state, { oldIndex, newIndex }) {
    return setState(state, {
      ...state,
      touched: true,
      form: {
        ...state.form,
        dailyActivities: {
          ...state.form.dailyActivities,  
          items: setNewOrder(newIndex, oldIndex, state.form.dailyActivities.items),
        },
      },
    });
  },
  [actionTypes.STAY_WIZARD_SET_VOUCHERS_RESTRICTION](state, { restrictionValue }) {
    return setState(state, {
      ...state,
      touched: true,
      form: {
        ...state.form,
        vouchers: {
          ...state.form.vouchers,
          show: restrictionValue,
        },
      },
    });
  },
  [actionTypes.STAY_WIZARD_ADD_VOUCHERS_ITEM](state, { newVoucher }) {
    return setState(state, {
      ...state,
      touched: true,
      form: {
        ...state.form,
        vouchers: {
          ...state.form.vouchers,
          items: [...state.form.vouchers.items, newVoucher],
        },
      },
    });
  },
  [actionTypes.STAY_WIZARD_REMOVE_VOUCHERS_ITEM](state, { id }) {
    const collectVouchersToRemove = (voucherId, vouchers) => {
      return [
        voucherId,
        ...vouchers.filter(dir => dir.parent_id === voucherId).map(dir => collectVouchersToRemove(dir.id, vouchers)),
      ].flat();
    };
    const vouchersToRemove = collectVouchersToRemove(
      id,
      state.form.vouchers.items
    );

    return setState(state, {
      ...state,
      touched: true,
      form: {
        ...state.form,
        vouchers: {
          ...state.form.vouchers,
          items: state.form.vouchers.items.filter(dir => !vouchersToRemove.some(dirId => dir.id === dirId)),
        },
      },
    });
  },
  [actionTypes.STAY_WIZARD_EDIT_VOUCHERS_ITEM](state, { editedVoucher }) {
    const indexOfDir = state.form.vouchers.items.findIndex(dir => dir.tag === editedVoucher.tag);

    return setState(state, {
      ...state,
      touched: true,
      form: {
        ...state.form,
        vouchers: {
          ...state.form.vouchers,
          items: [
            ...state.form.vouchers.items.slice(0, indexOfDir),
            editedVoucher,
            ...state.form.vouchers.items.slice(indexOfDir + 1)
          ],
        },
      },
    });
  },
  [actionTypes.STAY_WIZARD_TOGGLE_STATUS_VOUCHERS_ITEM](state, { id }) {
    return setState(state, {
      ...state,
      touched: true,
      form: {
        ...state.form,
        vouchers: {
          ...state.form.vouchers,
          items: state.form.vouchers.items.map(dir => (dir.id === id ? { ...dir, is_enabled: !dir.is_enabled } : dir)),
        },
      },
    });
  },
  [actionTypes.STAY_WIZARD_SORT_VOUCHERS](state, { oldIndex, newIndex }) {
    return setState(state, {
      ...state,
      touched: true,
      form: {
        ...state.form,
        vouchers: {
          ...state.form.vouchers,
          items: setNewOrder(newIndex, oldIndex, state.form.vouchers.items),
        },
      },
    });
  },
  [actionTypes.STAY_WIZARD_ADD_PASSCODES_ITEM](state, newPasscode) {
    return setState(state, {
      ...state,
      touched: true,
      form: {
        ...state.form,
        passcodes: [...state.form.passcodes, newPasscode],
      },
    });
  },
  [actionTypes.STAY_WIZARD_REMOVE_PASSCODES_ITEM](state, id) {
    return setState(state, {
      ...state,
      touched: true,
      form: {
        ...state.form,
        passcodes: state.form.passcodes.filter(passcode => passcode.id !== id),
      },
    });
  },
  [actionTypes.STAY_WIZARD_EDIT_PASSCODES_ITEM](state, editedPasscode) {
    const indexOfPasscode = state.form.passcodes.findIndex(dir => dir.id === editedPasscode.id);

    return setState(state, {
      ...state,
      touched: true,
      form: {
        ...state.form,
        passcodes: [...state.form.passcodes.slice(0, indexOfPasscode), editedPasscode, ...state.form.passcodes.slice(indexOfPasscode + 1)],
      },
    });
  },
  [actionTypes.STAY_WIZARD_SORT_PASSCODES](state, payload) {
    const { oldIndex, newIndex } = payload;

    return setState(state, {
      ...state,
      touched: true,
      form: {
        ...state.form,
        passcodes: setNewOrder(newIndex, oldIndex, state.form.passcodes),
      },
    });
  },
  [actionTypes.STAY_WIZARD_SET_INSPIRATION](state, inspirationId) {
    return setState(state, {
      ...state,
      touched: true,
      form: {
        ...state.form,
        inspiration: {
          ...state.form.inspiration,
          vamoosId: inspirationId,
          settings: {
            ...state.form.inspiration.settings,
            period: {
              ...state.form.inspiration.settings.period,
              deliveryAtDays: inspirationId ? state.form.inspiration.settings.period.deliveryAtDays : null,
              deliveryAtRelativeTo: inspirationId ? state.form.inspiration.settings.period.deliveryAtRelativeTo : null,
            },
          },
        },
      },
    });
  },
  [actionTypes.STAY_WIZARD_SET_INSPIRATION_NOTIFICATION_CONTENT](state, notificationContent) {
    return setState(state, {
      ...state,
      touched: true,
      form: {
        ...state.form,
        inspiration: {
          ...state.form.inspiration,
          settings: {
            ...state.form.inspiration.settings,
            content: notificationContent,
          },
        },
      },
    });
  },
  [actionTypes.STAY_WIZARD_SET_INSPIRATION_NOTIFICATION_PERIOD](state, notificationPeriod) {
    return setState(state, {
      ...state,
      touched: true,
      form: {
        ...state.form,
        inspiration: {
          ...state.form.inspiration,
          settings: {
            ...state.form.inspiration.settings,
            period: notificationPeriod,
          },
        },
      },
    });
  },
  [actionTypes.STAY_WIZARD_ENABLE_POI](state, poiId) {
    return setState(state, {
      ...state,
      touched: true,
      form: {
        ...state.form,
        pois: state.form.pois.some(poi => poi.id === poiId)
          ? state.form.pois.map(poi => (poi.id === poiId ? { id: poiId, is_on: true } : poi))
          : [...state.form.pois, { id: poiId, is_on: true }],
      },
    });
  },
  [actionTypes.STAY_WIZARD_DISABLE_POI](state, poiId) {
    return setState(state, {
      ...state,
      touched: true,
      form: {
        ...state.form,
        pois: state.form.pois.some(poi => poi.id === poiId)
          ? state.form.pois.map(poi => (poi.id === poiId ? { id: poiId, is_on: false } : poi))
          : [...state.form.pois, { id: poiId, is_on: false }],
      },
    });
  },
  [actionTypes.STAY_WIZARD_REQUEST_CONNECT_ON](state) {
    return setState(state, {
      ...state,
      touched: true,
      form: {
        ...state.form,
        connect: {
          ...state.form.connect,
          requestedStatus: true,
        },
      },
    });
  },
  [actionTypes.STAY_WIZARD_REQUEST_CONNECT_OFF](state) {
    return setState(state, {
      ...state,
      touched: true,
      form: {
        ...state.form,
        connect: {
          ...state.form.connect,
          requestedStatus: false,
        },
      },
    });
  },
  [actionTypes.STAY_WIZARD_SET_NOTIFICATIONS](state, { notifications }) {
    return setState(state, {
      ...state,
      touched: true,
      form: {
        ...state.form,
        notifications
      },
    });
  },
  [actionTypes.STAY_WIZARD_INIT_NOTIFICATIONS](state, notifications) {
    return setState(state, {
      ...state,
      form: {
        ...state.form,
        notifications,
      },
    });
  },
  [actionTypes.STAY_WIZARD_MESSAGING_SET_EMAILS](state, emails) {
    return setState(state, {
      ...state,
      touched: true,
      form: {
        ...state.form,
        messaging: {
          ...state.form.messaging,
          emails,
        },
      },
    });
  },
  [actionTypes.STAY_WIZARD_TOGGLE_DND](state) {
    return setState(state, {
      ...state,
      touched: true,
      form: {
        ...state.form,
        dnd: {
          ...state.form.dnd,
          show: state.form.dnd.show === "off" ? "during" : "off",
        },
      },
    });
  },
  [actionTypes.STAY_WIZARD_MESSAGING_SET_DATES_BEFORE](state, before) {
    return setState(state, {
      ...state,
      touched: true,
      form: {
        ...state.form,
        messaging: {
          ...state.form.messaging,
          dates: {
            ...state.form.messaging.dates,
            before,
          },
        },
      },
    });
  },
  [actionTypes.STAY_WIZARD_CHANGE_DND_EMAIL](state, email) {
    return setState(state, {
      ...state,
      touched: true,
      form: {
        ...state.form,
        dnd: {
          ...state.form.dnd,
          email,
        },
      },
    });
  },
  [actionTypes.STAY_WIZARD_MESSAGING_SET_DATES_AFTER](state, after) {
    return setState(state, {
      ...state,
      touched: true,
      form: {
        ...state.form,
        messaging: {
          ...state.form.messaging,
          dates: {
            ...state.form.messaging.dates,
            after,
          },
        },
      },
    });
  },
  [actionTypes.STAY_WIZARD_CHANGE_DND_TIME](state, time) {
    return setState(state, {
      ...state,
      touched: true,
      form: {
        ...state.form,
        dnd: {
          ...state.form.dnd,
          time,
        },
      },
    });
  },
  [actionTypes.SET_STAY_WIZARD_TOUCHED](state, touched) {
    return setState(state, {
      ...state,
      touched
    });
  },
  [actionTypes.CLEAR_STAY_WIZARD](state) {
    return setState(state, createInitialStayWizardState());
  },
});

const stayWizardReducer = (state = createInitialStayWizardState(), action) => reducer(state, action, ReducerActions);

export { stayWizardReducer };
