import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { FormControlLabel, MenuItem } from "@material-ui/core";

import { Content } from "components/ui/Content";
import { Checkbox, Input, Select } from "components/ui/Forms";
import { BodyText2, ErrorText } from "components/ui/Typography/Typography";
import { AttachFilesButton } from "components/ui/Buttons/AttachFilesButton";
import { IconImage } from "components/ui/Icons";
import { FieldValueWrapper } from "feature/panel/Settings/_shared/styledComponents";
import { DOCUMENTS_FILE_TYPES } from "constants/defaults";
import { ChipWithRedirect } from "components/ui/Chips/ChipWithRedirect";
import { formatDisplayableLibraryPopupValues } from "utils/library";
import { setFileNameAfterUpload } from "utils/validation";
import { useActiveIcon } from "hooks/useActiveIcon";

const InputsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: ${({ theme }) => theme.setSpacing(8)}px;
`;

const InputWrapper = styled.div`
  flex-grow: 1;
`;

const IconSelectWrapper = styled.div`
  width: 110px;
  margin-left: ${({ theme }) => theme.setSpacing(10)}px;
`;

const IconSelect = styled(Select)`
  && {
    .MuiSelect-selectMenu {
      span {
        display: none;
      }
    }
  }
`;

const FormControlLabelStyled = styled(FormControlLabel)`
  && {
    .MuiTypography-body1 {
      font-size: 1rem;
      font-family: Montserrat;
      letter-spacing: 0;
    }
  }
`;

const ActionManageElement = ({ action, toggleActionExistence, handleActionChange, label, errors, disabled }) => {
  const { availableIcons } = useSelector(state => state.operator);
  const navigationIcons = availableIcons.filter(icon => icon.section === "navigation");
  const FileIcon = useActiveIcon(action);

  const handleChange = ({ target }) => {
    const newAction = { ...action, [target.name]: target.value };
    handleActionChange(newAction);
  };
  const handleUploadChange = files => {
    const [newFile] = formatDisplayableLibraryPopupValues(files);
    const { orderNumber, icon_id, name } = action;
    const newAction = { orderNumber, name: setFileNameAfterUpload(newFile, name), icon_id, ...newFile };
    handleActionChange(newAction);
  };
  const errorsForCurrentAction = !!action && !!errors && errors.find(e => e.orderNumber === action.orderNumber);
  const uploadErrorMessage = !!errorsForCurrentAction && !!errorsForCurrentAction.library_node_id && (
    <ErrorText>{errorsForCurrentAction.library_node_id}</ErrorText>
  );
  const renderFileUploader = () => (
    <AttachFilesButton
      onSelect={handleUploadChange}
      name={label}
      allowedFileTypes={DOCUMENTS_FILE_TYPES}
      size="small"
      disabled={disabled}
    />
  );
  const renderFileDeleter = () => (
    <ChipWithRedirect
      onDelete={() => handleChange({ target: { name: "file_name", value: null } })}
      item={action}
      icon={FileIcon}
      editable={!disabled}
    />
  );
  const handleTitleChange = event => handleChange({ target: { ...event.target, name: "name" } });

  const actionContent = !!action && (
    <>
      <InputsWrapper>
        <InputWrapper>
          <Input
            label={label}
            value={action.name}
            name={label}
            onChange={handleTitleChange}
            fullWidth
            error={!!errorsForCurrentAction && !!errorsForCurrentAction.name}
            helperText={(!!errorsForCurrentAction && errorsForCurrentAction.name) || ""}
            disabled={disabled}
          />
        </InputWrapper>
        <IconSelectWrapper>
          <IconSelect name="icon_id" value={action.icon_id} onChange={handleChange} disabled={disabled}>
            {navigationIcons.map(icon => (
              <MenuItem key={icon.id} value={icon.id}>
                <FieldValueWrapper>
                  <IconImage src={icon.url} darkened />
                  <span>{icon.name}</span>
                </FieldValueWrapper>
              </MenuItem>
            ))}
          </IconSelect>
        </IconSelectWrapper>
      </InputsWrapper>
      <InputsWrapper>
        <BodyText2>PDF document or URL to show for button:</BodyText2>
        {action.file_name ? renderFileDeleter() : renderFileUploader()}
      </InputsWrapper>
      {uploadErrorMessage}
    </>
  );

  return (
    <Content margin={10}>
      <FormControlLabelStyled
        label={label}
        control={<Checkbox checked={!!action} onChange={toggleActionExistence} disabled={disabled} />}
      />
      {actionContent}
    </Content>
  );
};

ActionManageElement.defaultProps = {
  action: null,
  disabled: false,
  errors: null,
};

ActionManageElement.propTypes = {
  action: PropTypes.shape({
    orderNumber: PropTypes.number,
    name: PropTypes.string,
    file_name: PropTypes.string,
    icon_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
  toggleActionExistence: PropTypes.func.isRequired,
  handleActionChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  errors: PropTypes.arrayOf(PropTypes.shape({})),
};

export default ActionManageElement;
