import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { useLocation } from "react-router-dom";

import TableCell from "@material-ui/core/TableCell";
import { Tooltip } from "@material-ui/core";

import { Chip } from "components/ui/Chips";
import { TableRow, useTableContext } from "components/ui/Tables";
import { LinkedTableRowCell } from "components/ui/Tables/LinkedTableRowCell";

import { StyledLink } from "feature/admin/_shared/StyledComponents";

import { usePermissionsService } from "hooks/usePermissionsService";
import { ADMIN_OPERATOR_DETAILS_GENERAL_PATH, ADMIN_USER_DETAILS_PATH } from "constants/routes";
import { setUrl } from "utils/url";

import config from "config/app";
import { PERMISSIONS } from "constants/permissions";
import { deviceType } from "utils/deviceDetect";

const OperatorsTableRow = ({ item }) => {
  const permissionsService = usePermissionsService();
  const location = useLocation();
  const getUrl = id => setUrl(ADMIN_USER_DETAILS_PATH, { id });
  const device = deviceType();

  const { headers } = useTableContext();
  const { admins, downloads, created, meta, id, name, code } = item;
  const { total } = downloads;
  const { last30days } = created;
  const data = headers[device] || headers[headers.defaultDevice] || headers.all;
  const columnsToRedner = data?.filter(header => header.show);
  const allOperatorNames = admins.map(({ username }) => username).join(", ");
  const adminUserNames = admins?.slice(0, 2).map((adminItem, index) => (
    <span key={`admin-operator-item-${adminItem.id}`}>
      <StyledLink to={getUrl(adminItem.id)}>{adminItem.username}</StyledLink>
      {admins.length > 1 && index === 0 && <span>, </span>}
      {admins.length > 2 && index === 1 && (
        <Tooltip title={allOperatorNames}>
          <span> ...</span>
        </Tooltip>
      )}
    </span>
  ));
  const itemRedirectUrl = setUrl(ADMIN_OPERATOR_DETAILS_GENERAL_PATH, { id });

  const isReadDetailsOperatorPermitted = permissionsService.can(
    PERMISSIONS.actions.read,
    PERMISSIONS.sections.admin,
    PERMISSIONS.resources.operators,
    id || null,
  );

  const renderCellValue = key => {
    if (key === "name") return code !== "ADMIN" ? `${name} (${code})` : name;
    if (key === "contact_person") return meta?.contact_person;
    if (key === "telephone") return meta?.telephone;
    if (key === "website") return meta?.website;
    if (key === "number_of_bookings") return <Chip label={meta?.number_of_bookings || 0} />;
    if (key === "number_of_passengers") return <Chip label={meta?.number_of_passengers || 0} />;
    if (key === "last30days") return <Chip label={last30days || 0} />;
    if (key === "total") return <Chip label={total || 0} />;
    if (key === "admins") return adminUserNames;
    if (["createdAt", "updatedAt"].includes(key)) return moment(item[key]).format(config.dateTimeFormat);
    return item[key];
  };

  const renderCell = key => {
    return isReadDetailsOperatorPermitted &&
      !["admins", "total", "last30days", "number_of_bookings", "number_of_passengers"].includes(key) ? (
      <LinkedTableRowCell
        url={{
          pathname: itemRedirectUrl,
          state: {
            previousUrl: location.pathname,
          },
        }}
        key={`${item.id}-${key}-${renderCellValue(key)}`}
      >
        {renderCellValue(key)}
      </LinkedTableRowCell>
    ) : (
      <TableCell key={`${item.id}-${key}-${renderCellValue(key)}`}>{renderCellValue(key)}</TableCell>
    );
  };

  return <TableRow item={item}>{columnsToRedner?.map(({ key }) => renderCell(key))}</TableRow>;
};

OperatorsTableRow.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number.isRequired,
    code: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    admins: PropTypes.arrayOf(PropTypes.shape()),
    createdAt: PropTypes.string.isRequired,
    meta: PropTypes.shape({
      contact_person: PropTypes.string,
      website: PropTypes.string,
      telephone: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      number_of_bookings: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      number_of_passengers: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }).isRequired,
    downloads: PropTypes.shape({
      total: PropTypes.number,
    }),
    created: PropTypes.shape({
      last30days: PropTypes.number,
    }),
  }).isRequired,
};

export { OperatorsTableRow };
