import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Collapse } from "@material-ui/core";

import {
  ChildrenContentArea,
  CloseButtonArea,
  ContentArea,
  HeaderContentArea,
  ReorderButton,
  RotatedArrowIcon,
  StyledReorderIcon,
  StyledWrapper,
} from "feature/panel/_shared/Sortable/StyledComponents/sortableBarElement";
import { RemoveButton } from "feature/panel/_shared/Sortable/StyledComponents/shared";

import { SortableHandler, SortableListItem } from "components/ui/Lists";
import { FlexGrow } from "components/ui/Content";
import { Input } from "components/ui/Forms";

import { checkErrorExistFor } from "utils/validation";
import { isObjectEmpty } from "utils/object";

import { useItemOriginalIndex } from "hooks/useItemOriginalIndex";

const SortableBarElement = ({
  index,
  title,
  titleLabel,
  isActive,
  onTitleChange,
  onExpandToggle,
  onItemDelete,
  children,
  headerChildren,
  errors,
  context,
  errorFieldName,
  item,
  disabled,
  childrenErrors,
}) => {
  const originalIndex = useItemOriginalIndex(item.id, context, errors || {});
  const error = errors[originalIndex] || errors[item.id] || {};
  const hasError = !isObjectEmpty(error);
  const titleRef = useRef(null);

  const hasChildrenError = !isObjectEmpty(childrenErrors);

  const scrollToNewItem = () => {
    if (item?.isNewItem) {
      titleRef.current.focus();
      titleRef.current.scrollIntoView({ behaviour: "smooth" });
    }
  };

  useEffect(scrollToNewItem, []);

  return (
    <SortableListItem index={index} disabled={disabled}>
      <StyledWrapper>
        {!disabled && (
          <SortableHandler>
            <ReorderButton isActive={isActive} hasError={hasError}>
              <StyledReorderIcon hasError={hasError} />
            </ReorderButton>
          </SortableHandler>
        )}
        <ContentArea disabled={disabled}>
          <HeaderContentArea isActive={isActive} hasError={hasError} disabled={disabled}>
            {headerChildren}
            <FlexGrow grow={1}>
              <Input
                label={titleLabel}
                value={title}
                onChange={onTitleChange}
                name={`section-title-nr${index}`}
                inputRef={titleRef}
                error={checkErrorExistFor(errorFieldName, error)}
                helperText={error[errorFieldName]}
                disabled={disabled}
              />
            </FlexGrow>
            <RotatedArrowIcon shouldRotate={isActive} onClick={onExpandToggle} />
          </HeaderContentArea>
          {!disabled && (
            <CloseButtonArea isActive={isActive} hasError={hasError}>
              <RemoveButton onClick={!disabled && onItemDelete} hasError={hasError} />
            </CloseButtonArea>
          )}
        </ContentArea>
      </StyledWrapper>
      <Collapse in={isActive}>
        <ChildrenContentArea hasError={hasChildrenError} disabled={disabled}>{children}</ChildrenContentArea>
      </Collapse>
    </SortableListItem>
  );
};

SortableBarElement.defaultProps = {
  headerChildren: null,
  errors: {},
  childrenErrors: {},
  errorFieldName: "",
  item: {
    id: 0,
    isNewItem: false,
  },
  context: null,
  disabled: false,
};

SortableBarElement.propTypes = {
  index: PropTypes.number.isRequired,
  isActive: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  titleLabel: PropTypes.string.isRequired,
  onExpandToggle: PropTypes.func.isRequired,
  onTitleChange: PropTypes.func.isRequired,
  onItemDelete: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  headerChildren: PropTypes.node,
  errors: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.object), PropTypes.object]),
  childrenErrors: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.object), PropTypes.object]),
  errorFieldName: PropTypes.string,
  item: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    isNewItem: PropTypes.bool,
  }),
  context: PropTypes.string,
  disabled: PropTypes.bool,
};

export { SortableBarElement };
