import { useListOfLanguages } from "hooks/useListOfLanguages";
import React, { createContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Logger } from "services/application/Logger";

const LanguageContext = createContext({
  currentLanguage: {
    code: null,
  },
  setLanguage: (lang) => { Logger.debug(lang) },
  additionalLanguages: []
});

const LanguageProvider = ({ children }) => {
  const languages = useListOfLanguages();
  const defaultLanguageCode = useSelector(({ operator }) => operator.currentOperator.defaultLanguageCode);
  const additionalLanguages = useSelector(({ operator }) => operator.currentOperator.additionalLanguageCodes) || [];

  const [currentLanguage, setCurrentLanguage] = useState(null);

  const setLanguage = (lang) => {
    setCurrentLanguage(lang);
  }

  const context = {
    currentLanguage: {
      ...currentLanguage,
      isDefault: currentLanguage?.code === defaultLanguageCode
    },
    setLanguage,
    additionalLanguages,
  }

  useEffect(() => {
    setCurrentLanguage({ ...languages.find(lang => defaultLanguageCode === lang.code) });
  }, [defaultLanguageCode]);

  return (
    <LanguageContext.Provider value={context}>
      {children}
    </LanguageContext.Provider>
  )
}

LanguageProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export { LanguageContext, LanguageProvider };
