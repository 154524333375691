import { reject } from "lodash";

class FileReaderService {
  // eslint-disable-next-line class-methods-use-this
  async read(file) {
    return new Promise((resolve) => {
      const reader = new FileReader();

      reader.addEventListener("load", (event) => {
        fetch(event.target.result)
          .then(r => r.text())
          .then(text => {
            resolve(text)
          })
          .catch(err => reject(err))
      });
      reader.readAsDataURL(file);
    })
  }
}

export {
  FileReaderService
}