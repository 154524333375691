import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

import { ToggleControl } from "components/ui/ToggleControl/ToggleControl";
import { InformationField } from "feature/panel/Settings/_shared/InformationField";

import { OPERATOR_CONFIG } from "feature/admin/Operators/Details/constants";

import { setValueForOperatorAction } from "store/adminOperatorDetails/actions";
import { usePermissionsService } from "hooks/usePermissionsService";
import { PERMISSIONS } from "constants/permissions";

const GeneralInfoForm = () => {
  const permissionsService = usePermissionsService();
  const details = useSelector(state => state.adminOperatorDetails.details);
  const errors = useSelector(state => state.adminOperatorDetails.errors);
  const { operatorStatus, operatorType } = OPERATOR_CONFIG;
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const isPageReadOnly = queryParams.get("readOnly") !== null;
  
  const isEditActionDisabled = !permissionsService.can(
    PERMISSIONS.actions.update,
    PERMISSIONS.sections.admin,
    PERMISSIONS.resources.operators,
    details.id,
  );
  const dispatch = useDispatch();

  const handleSwitchChange = () => {
    dispatch(setValueForOperatorAction({ fieldName: "isActive", value: !details?.isActive }));
  };

  const isActiveToggler = (
    <ToggleControl
      value={details?.isActive}
      onChange={handleSwitchChange}
      falseValueLabel={operatorStatus.inactiveLabel}
      trueValueLabel={operatorStatus.activeLabel}
      error={errors?.isActive}
      disabled={isPageReadOnly || isEditActionDisabled}
    />
  );

  return (
    <>
      <InformationField title={operatorStatus.label}>{isActiveToggler}</InformationField>
      <InformationField title={operatorType.label} content={details?.meta?.company_type || "-"} />
    </>
  );
};

export { GeneralInfoForm };
