import React, { useContext } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { Switch } from "components/ui/Forms";
import { requestStayConnectStatusStart } from "store/stays/actions";
import { VAMOOS_CONNECT_STATUS_LABEL, VAMOOS_CONNECT_STATUS_PENDING } from "constants/content";
import { stayWizardRequestConnectOff, stayWizardRequestConnectOn } from "store/stayWizard/actions";
import { LanguageContext } from "components/_shared/LanguageSelector/LanguageContext";

import { getConnectStatus, STAY_CONNECT_STATUS } from "../helpers";
import { LanguageLock } from "../LanguageLock";


const SwitchConnectWrapper = styled.div`
  display: flex;
  align-items: center;

  ${({ smallMargins, theme }) => smallMargins ? `
    margin: -${theme.setSpacing(6)}px 0 ${theme.setSpacing(6)}px auto;
  ` : `
    margin: -${theme.setSpacing(8)}px 0 ${theme.setSpacing(10)}px auto;
  `}
`;

const SwitchConnectContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
`;

const SwitchConnectControl = styled.div`
  display: flex;
  align-items: center;
  padding-right: ${({ theme }) => theme.setSpacing(0.5)}px;
`;

const SwitchConnectRequestedLabel = styled.div`
  display: flex;
  align-items: center;
  padding-right: ${({ theme }) => theme.setSpacing(4.5)}px;
  padding-bottom: ${({ theme }) => theme.setSpacing(2)}px;
`;

const SwitchConnectLockContainer = styled.div`
  margin: 0 16px 0 0;
`;

export const SwitchConnect = ({ disabled }) => {
  const dispatch = useDispatch();
  const { operator_code } = useParams();

  const { currentLanguage } = useContext(LanguageContext);

  const isStayDefaultLanguage = useSelector(
    ({ stayWizard }) => stayWizard.defaultVariant.language ? stayWizard.defaultVariant.language === currentLanguage.code : true
  )

  const referenceCode = useSelector(({ stayWizard }) => stayWizard.form.referenceCode);
  const isConnectEnabled = useSelector(({ stayWizard }) => stayWizard.form.connect.isEnabled);
  const requestedConnectStatus = useSelector(({ stayWizard }) => stayWizard.form.connect.requestedStatus);

  const connectStatus = getConnectStatus(isConnectEnabled, requestedConnectStatus);
  const isWaitingForApproval = [STAY_CONNECT_STATUS.OFF_REQUESTED, STAY_CONNECT_STATUS.ON_REQUESTED].includes(connectStatus);

  const requestChangeStatus = (currentConnectStatus) => {
    if(currentConnectStatus === STAY_CONNECT_STATUS.OFF) {
      dispatch(stayWizardRequestConnectOn())
      dispatch(
        requestStayConnectStatusStart({
          operatorCode: operator_code,
          passcode: referenceCode,
          requestedStatus: true
        }),
      );
    }
    if(currentConnectStatus === STAY_CONNECT_STATUS.ON) {
      dispatch(stayWizardRequestConnectOff())
      dispatch(
        requestStayConnectStatusStart({
          operatorCode: operator_code,
          passcode: referenceCode,
          requestedStatus: false
        }),
      );
    }
  }

  return (
    <SwitchConnectWrapper smallMargins={!isStayDefaultLanguage || isWaitingForApproval}>
      <SwitchConnectContent>
        <SwitchConnectControl>
          {VAMOOS_CONNECT_STATUS_LABEL}
          <Switch 
            disableRipple 
            checked={isConnectEnabled} 
            onChange={() => requestChangeStatus(connectStatus)} 
            disabled={isWaitingForApproval || disabled || !isStayDefaultLanguage} 
          />
        </SwitchConnectControl>
        {isWaitingForApproval && (
          <SwitchConnectRequestedLabel>
            {VAMOOS_CONNECT_STATUS_PENDING(connectStatus)}
          </SwitchConnectRequestedLabel>
        )}
      </SwitchConnectContent>
      {!isStayDefaultLanguage && (
        <SwitchConnectLockContainer>
          <LanguageLock defaultLanguage={{}} />
        </SwitchConnectLockContainer>
      )}
    </SwitchConnectWrapper>
  )

}