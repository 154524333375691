import React, { useEffect, useState, useRef, useLayoutEffect } from "react";
import { useDispatch } from "react-redux";
import Grid from "@material-ui/core/Grid";

import { SharedNavigation } from "feature/panel/_shared/Navigations/SharedNavigation";
import { Actions } from "feature/panel/Pois/Index/Actions/Actions";
import { TableOfPois } from "feature/panel/Pois/Index/TableOfPois/TableOfPois";
import { PoiModal } from "feature/panel/Pois/Index/PoiModal/PoiModal";
import { PoisMap } from "feature/panel/Pois/Index/PoisMap/PoisMap";

import { PanelTemplate } from "components/templates/Panel/PanelTemplate";
import { Content } from "components/ui/Content";

import { Logger } from "services/application/Logger";
import { EventBus } from "services/application/EventBus";
import { PoiService } from "services/PoiService";

import { PoiHasBeenCreated } from "events/PoiHasBeenCreated";
import { PoiHasBeenUpdated } from "events/PoiHasBeenUpdated";
import { PoiHasBeenDeleted } from "events/PoiHasBeenDeleted";

import { usePopupHandlers } from "hooks/usePopupHandlers";
import { useService } from "hooks/useService";

import { getIconsListStart } from "store/operator/actions";
import { pushErrorNotification, pushSuccessNotification } from "store/app/actions";

import { ERRORS } from "constants/content";
import { callFn } from "utils";
import notifications from "constants/notifications";
import { useQuery } from "@tanstack/react-query";
import { LoadingScreenWrapper } from "components/templates/_shared/styledComponents";
import { LoadingScreen } from "components/ui/LoadingScreen/LoadingScreen";
import { PoiHasBeenDisabledOrEnabled } from "events/PoiHasBeenDisabledOrEnabled";

const Pois = () => {
  const ref = useRef();
  const dispatch = useDispatch();

  const poiModal = usePopupHandlers();

  const poiService = useService(PoiService);

  const [poi, setPoi] = useState(null);
  const [canShowMap, setCanShowMap] = useState(false);
  const [isSearchEnabled, setIsSearchEnabled] = useState(false);

  const [searchedPois, setSearchedPois] = useState([]);
  const [allPois, setAllPois] = useState([]);
  const { data: pois, isLoading, refetch } = useQuery({
    queryKey: ["pois"],
    queryFn: () => {
      return poiService.getAllPois();
    },
  });
  const getIconsList = () => {
    dispatch(getIconsListStart());
  };

  const closePoiModal = () => {
    poiModal.close();
    setPoi(null);
  };

  const handleAddPoiFromMap = newPoi => {
    setPoi(newPoi);
    poiModal.open();
  };

  const handleClickCreatePoiButton = () => {
    poiModal.open();
  };

  const handleEditPoi = editedPoi => {
    setPoi(editedPoi);

    poiModal.open();
  };

  const handleClickRowAction = callFn({
    edit: handleEditPoi,
  });

  const resolvePoisToDisplay = (value, list) => {
    setIsSearchEnabled(value > 0);
    setSearchedPois(list);
  };

  // const fetchAllPois = async () => {
  //   try {
  //     const dataPayload = await poiService.getAllPois();
  //     setAllPois(dataPayload.items);
  //   } catch (e) {
  //     Logger.debug(e);
  //   }
  // };

  useEffect(() => {
    const onPoiSave = async noPopup => {
      closePoiModal();

      try {
        refetch();

        if (!noPopup) dispatch(pushSuccessNotification(notifications.resource("poi").create.success));
      } catch (e) {
        dispatch(pushErrorNotification(ERRORS.unknownError));
      }
    };

    const onPoiDelete = async () => {
      await dispatch(pushSuccessNotification(notifications.resource("poi").delete.success));

      setPoi(null);

      refetch();
    };

    EventBus.on(PoiHasBeenCreated, onPoiSave);
    EventBus.on(PoiHasBeenUpdated, onPoiSave);
    EventBus.on(PoiHasBeenDisabledOrEnabled, onPoiSave);
    EventBus.on(PoiHasBeenDeleted, onPoiDelete);

    return () => {
      EventBus.remove(PoiHasBeenCreated, onPoiSave);
      EventBus.remove(PoiHasBeenUpdated, onPoiSave);
      EventBus.remove(PoiHasBeenDeleted, onPoiDelete);
      EventBus.on(PoiHasBeenDisabledOrEnabled, onPoiSave);
    };
  }, []);

  // useEffect(resolvePoisToDisplay, [allPois]);
  useEffect(getIconsList, []);

  const contextBar = {
    left: null,
    middle: SharedNavigation,
    right: () => <Actions onClickCreate={handleClickCreatePoiButton} />,
  };
  return (
    <PanelTemplate languageSelector contextBar={contextBar}>
      <div ref={ref} />
      <Content>
        <Grid container justifyContent="center">
          <Grid item xs={10}>
            <PoisMap pois={isSearchEnabled ? searchedPois : pois?.items} onAddMarker={handleAddPoiFromMap} loading={isLoading} />
          </Grid>
        </Grid>
      </Content>
      <Grid container justifyContent="center">
        <Grid item xs={10}>
          <TableOfPois onClickRowAction={handleClickRowAction} onSearch={resolvePoisToDisplay} />
        </Grid>
      </Grid>
      <PoiModal isOpen={poiModal.isOpen} onClose={closePoiModal} poi={poi} />
    </PanelTemplate>
  );
};

export { Pois };
