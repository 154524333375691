import { ConfirmationModal } from "components/ui/Modals/ConfirmationModal";
import { useCallback, useState } from "react";

const defaults = {
  onConfirm: () => {},
  onCancel: () => {},
  fullWidth: true,
  size: "sm",
  title: ""
}

export const useConfirmationModal = (options) => {
  const [isOpen, setIsOpen ] = useState(false);
  const [props, setProps] = useState({
    ...defaults,
    ...options
  })

  const { onConfirm, onCancel, ...otherProps } = props;

  const openModal = useCallback((callProps = {}) => {
    setProps({
      ...defaults,
      ...options,
      ...callProps
    });
    setIsOpen(true);
  }, []);

  const closeModal = useCallback(() => {
    setProps({
      ...defaults,
      ...options
    });
    setIsOpen(false);
  }, []);

  const modalProps = {
    ...otherProps,
    open: isOpen,
    onCancel: () => {
      onCancel();
      closeModal();
    },
    onConfirm: () => {
      onConfirm();
      closeModal();
    },
  }

  return {
    openModal,
    closeModal,
    modalProps,
    ConfirmationModal
  }
}