import React from "react";
import styled from "styled-components";
import TableCell from "@material-ui/core/TableCell";

import { TableRow, useTableContext } from "components/ui/Tables";
import { Switch } from "components/ui/Forms";

const StyledSwitch = styled(Switch)`
  margin-left: -12px;
`

const TripShareAccessTableRow = ({ item }) => {
  const { headers } = useTableContext();

  const columnsToRedner = headers.filter(header => header.show);

  const renderCellValue = key => {
    if(key === "email") {
      return <TableCell key={`${item.id}-${key}`}>{item[key]}</TableCell>
    }
    if(key === "read") {
      return (
        <TableCell key={`${item.id}-${key}`}>
          <StyledSwitch
            name="read"
            label=""
            labelPlacement="start"
            checked
            disabled
          />
        </TableCell>
      )
    }
    if(key === "write") {
      return (
        <TableCell key={`${item.id}-${key}`}>
          <StyledSwitch
            name="write"
            label=""
            labelPlacement="start"
            checked={item[key]}
            disabled
          />
        </TableCell>
      )
    }
    return null;
  };

  return (
    <TableRow item={item}>
      {columnsToRedner.map(({ key }) => renderCellValue(key))}
    </TableRow>
  )
}

export {
  TripShareAccessTableRow
}