/* eslint-disable max-len */

import { firstLetterUpperCase } from "utils/string";
import { MAX_WAYPOINTS_COUNT } from "./defaults";

export const GLOBAL_CONTENT = {
  save: "SAVE",
  copy: "Copy",
  edit: "Edit",
  attach: "Attach",
  addImage: "Add image",
  attachLink: "Attach / Link",
  link: "Link",
  proceed: "Proceed",
  include: "Include",
  forceUpdate: "Force update",
  update: "Update",
  create: "Create",
  userId: "User ID",
  passcode: "Passcode",
  operator: "Operator",
  search: "Search",
  delete: "Delete",
  confirm: "Confirm",
  restore: "restore",
  cancel: "cancel",
  back: "back",
  home: "Home",
  submit: "submit",
  revise: "Revise",
  dismiss: "dismiss",
  none: "None",
  at: "at",
  select: "Select",
  exit: "Exit",
  next: "Next",
  generate: "Generate",
  name: "Name",
  add: "Add",
  new: "New",
  send: "Send",
  clear: "Clear",
  addStay: "Add stay",
  refresh: "Refresh",
  tryAgain: "Try again"
};

export const WEEKDAYS_ABBR = {
  monday: "M",
  tuesday: "T",
  wednesday: "W",
  thursday: "T",
  friday: "F",
  saturday: "S",
  sunday: "S",
};

export const VAMOOS_PHONES = {
  uk: "(UK) 0203 474 0512",
  us: "(US) 213 808 6027",
};

export const VAMOOS_OFFICIAL_WEBSITE = {
  url: "https://www.vamoos.com",
  label: "www.vamoos.com",
};

export const SITE_LEAVE_WARNING_MESSAGES = {
  createTitle: context =>
    `You have chosen to navigate away from the ${context}. If you do so without saving first, you will lose all current changes.`,
  buttons: {
    confirmLabel: "proceed",
    cancelLabel: "cancel",
  },
  contexts: {
    vamoosEditor: "Vamoos editor",
    inspirationEditor: "inspiration editor",
    iconsManager: "icons manager",
  },
  switchLanguage: name => `You have to create and save the ${name} version before you can start localising`,
};

export const OPERATOR_TYPE = {
  customer: "customer",
  admin: "admin",
};

export const DND_REQUEST_MSG = {
  success: "Do not disturb request accepted",
  fail: "Do not disturb request failed",
};

export const VAMOOS_CONNECT_REQUEST_MSG = {
  success: "Vamoos Connect status change has been requested successfuly",
  fail: "Vamoos Connect status change failed",
};

export const VAMOOS_CONNECT_STATUS = {
  OFF: "Off",
  ON: "On",
  OFF_REQUESTED: "Off requested",
  ON_REQUESTED: "On requested",
};

export const VAMOOS_CONNECT_STATUS_LABEL = "Vamoos Connect";
export const VAMOOS_CONNECT_STATUS_PENDING = status => `${VAMOOS_CONNECT_STATUS[status]}. Under review.`;

export const ACCOUNT_SETTINGS = {
  title: "Account settings",
  generateApiKey: "GENERATE API KEY",
  username: {
    label: "Username",
    key: "username",
  },
  email: {
    label: "Contact email",
    key: "email",
  },
  password: {
    label: "Password",
    key: "password",
  },
  oldPassword: {
    label: "Old password",
    key: "old_password",
  },
  newPasswordLabel: "New password",
  APIToken: {
    label: "API authentication token",
  },
};

export const COMPANY_SETTINGS = {
  companyInformation: "Company information",
  fields: {
    companyCode: "Company identifier",
    companyName: "Company name",
    contactPerson: "Contact person",
    contactPhoneNumber: "Contact phone number",
    numberOfBookings: "Number of bookings",
    numberOfPassengers: "Number of passengers",
    generalDescription: "General description of business",
    companyWebsite: "Company website",
  },
};

export const LANGUAGES_SETTINGS = {
  languageSettings: "Language settings",
};

export const SETTINGS_NAVIGATION = {
  general: "GENERAL",
  branding: "BRAND",
  users: "USERS",
  connect: "CONNECT",
  customApps: "CUSTOM APPS",
  requestTracker: "REQUEST TRACKER",
};

export const SHARED_NAVIGATION = {
  notifications: "Notifications",
  poi: "POI",
  inspirations: "Inspirations",
  menuLabels: "MENU & LABELS",
};

export const VAMOOS_USER_GUIDE = {
  url: "https://www.vamoos.com/user-guides",
  label: "USER GUIDE",
};

export const VAMOOS_INFO_EMAIL = "support@vamoos.com ?subject=New%20accommodation%20feature%20suggestion";

export const PASSWORD_RESET = {
  title: "Reset password",
  description: "Enter the email associated with your account and we will send an email with instructions to reset the password.",
  sendSuccess: "Email sent successfully. Follow the steps sent to your email box.",
  noAssociationError: "No account is associated with the entered email",
};

export const PANEL_MAIN_NAVIGATION_LABELS = {
  vamoosList: "vamoos list",
  library: "library",
  shared: "shared",
  settings: "settings",
  help: "help",
};

export const ADMIN_MAIN_NAVIGATION_LABELS = {
  accounts: "manage accounts",
  settings: "settings",
};

export const ADMIN_MAIN_SUB_NAVIGATION_LABELS = {
  operators: "operators",
  users: "users",
  connect: "connect",
  features: "features",
  icons: "icons",
};

export const BUTTON_LABEL = {
  save: "save",
  resetPassword: "reset password",
  confirmReset: "Confirm reset",
  proceed: "proceed",
};

export const PAGE_TITLE_LABEL = {
  editUser: "Edit user: ",
  editOperator: "Edit operator: ",
  createStay: "Create Vamoos Stay",
  editStay: operatorCode => `Edit Vamoos Stay ${operatorCode ? `- ${operatorCode}` : ""}`,
  createTrip: "Create Vamoos Trip",
  editTrip: ({ operatorCode, referenceCode }) => `Edit Vamoos Trip - ${operatorCode}${referenceCode ? `-${referenceCode}` : ""}`,
};

export const TOGGLE_CONTROL_LABEL = {
  on: "On",
  off: "Off",
};

export const LIBRARY_SEARCH_LABEL = "Search documents and folders";

export const ERASE_DATA_DIALOG = {
  title: resourceName => `Warning! Are you sure want to delete current ${resourceName}?`,
  description: resourceName => `By pressing the button below, you will delete all data for this ${resourceName}.
  This change will be non-reversible after a restore period of 5 days.
  This will include all data entered by operators or end users.
  The ${resourceName} will immediately become unavailable from apps,
  and any existing installations on devices will be wiped the next time they synchronise with our servers.`,
};

export const PANEL_TRIPS_CREATE_TEXTS = {
  preview: {
    line1: "Allowed extensions: jpg, jpeg, gif, png",
    line2: "Maximum file size: 12 MB ",
    line3: "Recommended dimensions: 2048 x 2048 px",
  },
  inspirationsForm: {
    text1:
      "To enable auto-opening, set the date and time you wish the inspiration to appear as the opening view. If left disabled it will be accessed only via the icon on the home screen:",
    text2:
      'Select a number of days and then chose whether it is before or after the start. Pick "0" if you wish to select the start day itself.',
  },
};

export const PANEL_TRIPS_ACTION_MESSAGES = {
  deleteConfirmationBase: "Are you sure you want to delete",
  archivedInformation: "This information will be permanently deleted after 5 days. Click the “Restore” button now to restore your data.",
};

export const USER_OPERATOR_MESSAGE = {
  deleteConfirmation: companyName => `You would like to be removed from ${companyName} account`,
};

export const NO_HOTEL_LOCATION = "In order to add points of interest you need to define hotel location";
export const NO_POIS_FOUND_FOR_LOCATIONS = "No POIs were found in the range of existing locations";
export const CREATE_ONE_LABEL = "Create one";
export const DEFINE_LOCATION_LABEL = "Define location";
export const NO_INSPIRATIONS_MESSAGE =
  "You have no Inspirations. Create one inspiration on Inspirations list in Shared to add this section.";

export const NO_TEMPLATE_TEXT = notificationType => `There are no ${notificationType} notification templates for this operator`;

export const NOTIFICATION_TYPES = {
  timed: "timed",
  gps: "gps",
  forced: "forced",
  inspiration: "inspiration",
};

export const CREATION_FILE_REQUIREMENTS = {
  extensions: "Allowed extensions: jpg, jpeg, gif, png",
  size: "Maximum file size: 12 MB",
  dimensions: (width = 2048, height = 454) => `Recommended dimensions: ${width} x ${height} px`,
  warning: (width = 2048, height = 454) =>
    `Please upload an image that is ${width} pixels wide and up to ${height} pixels of height for use in your Creations.
    Though not recommended, you can also upload a lower resolution image, which will then have height restrictions scaled down accordingly.`,
};

export const CREATION_NOTIFICATIONS = {
  travel: name => `Creation "${name}" has been successfully copied to Travel Documents`,
  destination: name => `Creation "${name}" has been successfully copied to Destination Documents`,
};

export const NOTIFICATIONS_LABELS = {
  notificationText: "Notification text",
  notificationUrl: "Notification URL",
  activeFrom: "Active from",
  activeTo: "Active to",
  lngLat: "Latitude, Longitude",
  coords: "Coordinates",
  location: "Location",
  period: "Period",
};

export const EMPTY_LIST_MESSAGES_BASE = (actionButtonText, resourceName, optionalActionText = "") =>
  `Please click "${actionButtonText}"${optionalActionText} to start adding ${resourceName}`;

export const EMPTY_VARIANT_SECTION = (currentLanguage, defaultLanguage) =>
  `This section has not been configured for ${currentLanguage} yet. The main (${defaultLanguage}) version is currently being served.`;

export const NO_RESULTS_FOUND_MESSAGE = "No results";
export const CHANGES_SAVED_MESSAGE = "Changes saved";
export const NODE_DELETED_MESSAGE = nodeType => `${nodeType} has been removed successfully`;
export const FILE_CREATED_MESSAGE = "File has been successfully saved";
export const FILES_CREATED_MESSAGE = "Files have been successfully saved";
export const CREATE_CONTENT_LABELS = {
  add: "ADD",
  edit: "EDIT",
  trip: "CREATE TRIP",
  stay: "CREATE STAY",
  flight: "NEW FLIGHT",
  creation: "NEW CREATION",
  publisher: "NEW",
  notificationTemplate: "CREATE TEMPLATE",
  poi: "CREATE POI",
  inspiration: "CREATE INSPIRATION",
  library: "LIBRARY",
};

export const NAMES_OF_RESOURCES_LISTS = {
  directory: "directories",
  daily: "activities",
  voucher: "vouchers or menus",
  passcodeGroup: "passcode groups",
  publisher: "documents",
  notifications: {
    timed: "Timed notifications",
    gps: "GPS notifications",
    forced: "Force update notifications",
  },
  poi: "POIs",
  inspiration: "inspirations",
  gallery: "images",
  trip: "trips",
  stay: "stays",
  flight: "flights",
  document: "documents",
  creation: "creations",
  location: "locations",
  people: "people",
  day: "days",
  storyboard: "storyboards",
};

export const HOMESCREEN_LABELS = {
  daysAmount: "30",
  hoursAmount: "00",
  minAmount: "02",
  secAmount: "05",
  days: "days",
  hours: "hours",
  minutes: "minutes",
  seconds: "seconds",
  line1: "test test",
  logoCaption: "Sample logo",
  summary: "Summary",
  travelDoc: "Travel documents",
  posts: "Posts",
  maps: "Maps",
};

export const STAY_PAGE_LABELS = {
  createPageTitle: "Create Vamoos Stay",
};

export const TABLE_ACTIONS_TITLES = {
  editColumns: "Edit columns",
  duplicateResource: resourceName => `Duplicate ${resourceName}`,
  restoreResource: resourceName => `Restore ${resourceName}`,
  editResource: resourceName => `Edit ${resourceName}`,
  deleteResource: resourceName => `Delete ${resourceName}`,
  downloadResource: resourceName => `Download ${resourceName}`,
  refreshResource: resourceName => `Refresh ${resourceName}`,
  activateResource: resourceName => `Activate ${resourceName}`,
  deactivateResource: resourceName => `Deactivate ${resourceName}`,
  addToDocuments: "Add to documents",
  openMessages: "Open messages",
  hideDates: "Hide dates",
  showDates: "Show dates",
  reorder: "Reorder",
  resourceTypes: {
    trip: "trip",
    stay: "stay",
    file: "file",
    folder: "folder",
    link: "link",
    template: "template",
    poi: "POI",
    inspiration: "inspiration",
    operator: "operator",
    user: "user",
    flight: "flight",
    creation: "Creation",
    publisher: "Publisher document",
    directory: "directory",
    activity: "activity",
    voucher: "voucher",
    customApp: "custom app",
  },
};

export const MODAL_TITLE_GENERATOR = {
  travelDocuments: " Edit Travel documents",
  destinationDocuments: "Edit Destination documents",
};

export const MIME_FILE_TYPES = {
  "application/pdf": "PDF",
  "image/jpg": "JPG",
  "image/jpeg": "JPEG",
  "image/png": "PNG",
  "image/gif": "GIF",
};

export const ERRORS = {
  isRequired: (field, multiple = false) => `${firstLetterUpperCase(field)} ${multiple ? "are" : "is"} required`,
  isUnique: field => `${firstLetterUpperCase(field)} has to be unique`,
  reservedPasscode: value => `Cannot use reserved passcode "${value}"`,
  unknownError: "Something went wrong",
  invalidEmail: "Invalid e-mail format",
  someInvalidEmail: "Some of provided emails are invalid",
  invalidURL: "Invalid URL format. Please provide valid web link, eg. https://vamoos.com",
  invalidPhone: `Does not look like a phone number. Only digits
    separated by space or "-" and optional International Dialing Codes with "+" sign are allowed`,
  folderName:
    "Folder name can only contain English letters, numbers, space characters and special characters specified inside the brackets: [ - _ . & ]",
  appName: "App title can only contain English letters, numbers and space characters",
  latitude: "Latitude range must be between -90 and 90",
  longitude: "Longitude range must be between -180 and 180",
  noRoleDescription: "There is no description provided for this role",
  invalidFolderPath: "You can't move the folder neither to its descendant nor to itself. Please, select valid path",
  resourceTaken: resourceName => `This ${resourceName} is already taken`,
  userIdTaken: "This User ID is already taken. Please, come up with a different one",
  nodeDeleteFailed: (name, is_folder) => `The ${name} ${is_folder ? "folder" : "file"} could not be deleted`,
  failedUpdate: resourceName => `Failed to update the ${resourceName}`,
  failedGet: resourceName => `Failed to receive the data of ${resourceName}`,
  invalidPassword: "Password needs to contain uppercase, lowercase characters, numbers, and be at least 8 characters long",
  invalidOldPassword: "You entered incorrect old password",
  invalidPassowrdConfirmation: "Password confirmation must be the same as password",
  invalidDate: "Invalid date",
  invalidTime: "Invalid time",
  createAccountFail: "Could not create account. Please go back and check fields for error messages",
  missingAttachment: "Missing attachment",
  isTooLong: length => `Should NOT be longer than ${length} characters`,
  isTooShort: length => `Should be at least ${length} characters long`,
  filesTooHeavy: (filesCount, maxSizeMB) =>
    `${filesCount} file${filesCount > 1 ? "s" : ""} exceed${filesCount > 1 ? "" : "s"} the maximum size of ${maxSizeMB} MB`,
  wrongFileType: (filesCount, allowedFileTypes) =>
    `${filesCount} file${filesCount > 1 ? "s" : ""} ${filesCount > 1 ? "have" : "has"} wrong type. Only ${allowedFileTypes.join(
      ", ",
    )} files are allowed in this context.`,
  fileContainsToManyWaypoints: filesCount =>
    `${filesCount} file${filesCount > 1 ? "s" : ""} exceed${
      filesCount > 1 ? "" : "s"
    } the maximum waypoints count of ${MAX_WAYPOINTS_COUNT}`,
  imageInvalidResolution: (filesCount, maxResoltion) =>
    `${filesCount} file${filesCount > 1 ? "s" : ""} exceed${filesCount > 1 ? "" : "s"} the max resolution of ${maxResoltion}px`,
  imageInvalidMinResolution: (filesCount, minResoltion) =>
    `${filesCount} file${filesCount > 1 ? "s" : ""} exceed${filesCount > 1 ? "" : "s"} the min resolution of ${minResoltion}px`,
  imageInvalidOnlyResolution: (filesCount, minResoltion) =>
    `${
      filesCount > 1 ? "Some files exceed or do not meet the expected dimensions" : "1 file does not meet the expected dimensions"
    } (${minResoltion}px)`,
  filesDuplicated: count => `${count} file${count > 1 ? "s" : ""} already exist${count === 1 ? "s" : ""}`,
  fileNameAlreadyExists: "File name already exists",
  invalidDocumentFile: "Invalid document. Please check for an invalid URL or file reference.",
  invalidData: "Invalid data. Please review/refresh content and resubmit.",
  copyVamoos: resource =>
    `Cannot copy this ${resource} as it currently has errors in it. Please try saving it and correct any highlighted issues, then try again.`,
  directoryAlreadyExists: "Directory entry already exists",
  messagingWithoutDuringOptionSelected: "At least one email address must be activated for messaging during the visit",
  resourceAlreadyExists: "Folder/file name already exists",
  saveActionError: (resources, plural) => `Attempting to update Vamoos failed. Check ${resources} section${plural ? "s" : ""}.`,
  updateOperatorDetailsError: (resources, plural) =>
    `Attempting to update Operator details failed. Check ${resources} field${plural ? "s" : ""}.`,
  onlyDigitsAllowed: "Only digits allowed",
  cantBeBlank: name => `${name} can't be blank`,
  latitudeMaxRange: "Latitude must be less than or equal to 90",
  latitudeMinRange: "Latitude must be greater than -90",
  longitudeMaxRange: "Longitude must be less than or equal to 180",
  longitudeMinRange: " Longitude must be greater than -180",
  coordinatesNotNumbers: "Coordinates are not a number",
  coordinatesRequired: "Coordinates can't be blank",
  uniquePasscode: "Passcode must be unique",
  invalidHexColorFormat: "Invalid colour format! Should starts with # char and contains six chars 0-9 and A-F",
  caInvalidUserId: "Only a User ID already assigned to an existing stay for the current operator allowed",
  coordinatesOrLocationRequired: "Location or coordinates are required",
  gpsNotificationError: "Invalid notification. Location or coordinates are required",
  invalidBundleIdFormat: "Invalid Bundle ID format",
  alphanumericCharacters: title => `${title} should contain alphanumeric characters`,
};

export const OPERATOR_USER = {
  noOwnAccountManipulation: "You can't manipulate your own account",
  invitedLabel: "Invited",
};

export const CREATE_COPY_OF = context => `Create a copy of "${context}"`;
export const COPY_TRIP = context => `Create trip "${context}"`;
export const CREATE_A_COPY = "create a copy";

export const COPYING_TRIP_MODAL = {
  travelDocuments: "Travel documents",
  destinationDocuments: "Destination documents",
  locations: "Locations",
  storyboard: "Storyboard",
  people: "People",
  notifications: "Notifications",
  flights: "Flights",
  inspirations: "Inspirations",
};

export const COPYING_STAY_MODAL = {
  actions: "Actions",
  pois: "POIs",
  gallery: "Gallery",
  directory: "Directory",
  daily: "Daily",
  vouchers: "Vouchers",
  passcodes: "Passcodes",
  notifications: "Notifications",
  translations: "Translations",
};

export const USER_ID_EXPLANATION_MESSAGE =
  "This is the login that will be used by your customers and business partners to access your Vamoos. Please keep this to between 3-12 letters, we recommend to make it as short as possible.";

export const FAQ = [
  {
    question: "What is Vamoos for Travel Companies?",
    answer:
      "Vamoos is an application that displays your clients' information on their iPhone, iPad or Android device. It is used by holiday companies, hotels, hospitality and conference companies planning trips and events.",
  },
  {
    question: "What is Vamoos for Hotels and Villas?",
    answer:
      "Vamoos is an application that shows your property’s information for your guests on their iPhone, iPad or Android device.  It’s designed so that they can easily find out more about your amenities and contact you quickly if they need to.",
  },
  {
    question: "What is Vamoos Connect?",
    answer:
      "Vamoos Connect brings our travel companies and hotels together in a network. Vamoos travel companies can drag and drop hotel information into their itineraries, and Vamoos hotels can promote their properties to the network to generate more enquiries and bookings. ",
  },
  {
    question: "How much does Vamoos Cost?",
    answer:
      "It depends on how many people you enable Vamoos for each year and the type of application. For our average travel customers it is around £1 per passenger per year, and for event companies around £10 per delegate.<br><br>If you are a hotel or Villa, the cost depends on the number of bedrooms you have at your property. For our average hotel customers it is around £2 per room per month.",
  },
  {
    question: "Can I enable Vamoos for only some of my clients or rooms?",
    answer: "No, it's all or nothing.",
  },
  {
    question: "How do I stop my Vamoos Plan?",
    answer:
      "You can give us 3 months' notice to stop your plan, and we'll refund the difference between that month and the end of your yearly plan.",
  },
  {
    question: "I have several brands - can I group them all together?",
    answer:
      "Yes, you can have several brands on one Vamoos account if you wish. Although it is just as easy to have separate accounts with the ability to switch between them in order to manage them. A member from the Vamoos team will be able to work with you to decide on the best approach to take and help with the set up.",
  },
  {
    question: "I have several hotels in my group - can I list them all together?",
    answer: "Yes, you can list all your hotels under the same account. We can also build you a Hotel Group app, branded fully for you.  ",
  },
  {
    question: "How do I upload my client documents or hotel information?",
    answer:
      'Click on "sign up" and set up an account. It\'s all self-explanatory from there. Our target is that once you are registered a typical "Vamoos" should take no more than a few minutes to set up.',
  },
  {
    question: "How do my users get the right information in the Vamoos Apps?",
    answer:
      "For travel companies, we use a unique reference for each Vamoos, which is a combination of your company User ID and a passcode you assign. They are asked to input that code when first opening the application.<br><br>If you are a hotel or villa, you will only require the User ID.",
  },
  {
    question: "Do my users have to be connected to a mobile network or WIFI to view their information?",
    answer:
      "No. We've worked extremely hard to ensure that once they have initially downloaded their information that it is available in the application even when they are not able to access a local mobile network or WIFI. (They must be online to use the live messaging feature though)",
  },
  {
    question: "What languages does Vamoos support?",
    answer: "Only English for now, but we have plans to support other languages soon.",
  },
  {
    question: "How do I know what's coming next?",
    answer:
      "Once you become a Vamoos Customer, we'll let you know what our plans are for the future and of course we greatly welcome ideas and feedback as to what features you would like to prioritise.",
  },
  {
    question: "What if I need more help?",
    answer: "We have a full support team on hand for you; please contact support@vamoos.com",
  },
];

export const LANGUAGE_SELECTOR = {
  languages: "Languages",
  defaultLanguage: "Default language",
  additionalLanguages: "Additional languages",
  toAddAdditionalLanguages: "To add additional languages, please go to ",
  languageSettings: "language settings",
};

export const MENU_LABELS_ICONS_CAPTIONS = {
  storyboardView: "Default storyboard view",
  temperatureScale: "Temperature scale",
  poiListOption: "Show the POI list option by default",
  accommodation: "Accommodation",
  actionButton: "Action",
  bookNow: "Book now",
  daily: "Details",
  dailyActivities: "Daily activities",
  destinationDocuments: "Destination documents",
  directory: "Directory",
  doNotDisturb: "Do not disturb",
  flights: "Flights",
  gallery: "Gallery",
  hotelInfo: "Property info",
  inspirations: "Inspirations",
  maps: "Maps",
  messaging: "Messaging",
  poiList: "POI list",
  posts: "Posts",
  summary: "Summary",
  travelDocuments: "Travel documents",
  vouchers: "Vouchers",
  weather: "Weather",
};

export const TERMS = {
  contactInfo: "Questions about the Terms of Service should be sent to info@appexmobile.com",
  categories: [
    {
      header: "FOR TRAVEL COMPANIES",
      explanations: [
        'By using Vamoos ("Service"), you are agreeing to be bound by the following terms and conditions ("Terms of Service").',
        'Vamoos Ltd ("Company") reserves the right to update and change these Terms of Service without notice.',
        "Violation of any of the terms below may result in the termination of your account.",
      ],
      terms: [
        {
          header: "Account Terms",
          enumerable: true,
          texts: [
            "Access to the Vamoos service is based on full disclosure of number of passengers carried annually, measured immediately prior to the service commencing or being renewed.  We reserve the right to request an independent audit of passenger figures at the operator’s expense at any time.   Any variation from the disclosed figures will require a variance fee applied retrospectively together with a 50% administration fee.",
            "You are responsible for maintaining the security of your account and password. The Company cannot and will not be liable for any loss or damage from your failure to comply with this security obligation.",
            "You are responsible for all content posted and activity that occurs under your account (even when content is posted by others who have their own logins under your account).",
            "You may not use the Service for any illegal purpose or to violate any laws in your jurisdiction (including but not limited to copyright laws).",
            "You must provide your legal full name, a valid email address, and any other information requested in order to complete the signup process",
            "You must be a human. Accounts registered by “bots” or other automated methods are not permitted.",
          ],
        },
      ],
    },
    {
      header: "FOR HOTELS & VILLAS",
      explanations: [
        "By using Vamoos for Accommodation (“Service”), you are agreeing to be bound by the following terms and conditions (“Terms of Service”).",
        "Vamoos Ltd (“Company”) reserves the right to update and change these Terms of Service without notice.",
        "Violation of any of the terms below may result in the termination of your account",
      ],
      terms: [
        {
          header: "Account Terms",
          enumerable: true,
          texts: [
            "Access to the Vamoos service is based on full disclosure of number of habitable rooms, measured immediately prior to the service commencing or being renewed.",
            "You are responsible for maintaining the security of your account and password. The Company cannot and will not be liable for any loss or damage from your failure to comply with this security obligation.",
            "You are responsible for all content posted and activity that occurs under your account (even when content is posted by others who have their own logins under your account).",
            "In consideration of your use of the Vamoos Services, you agree to maintain and promptly update the Registration Data to keep it true, accurate, current and complete. If you provide any information that is untrue, inaccurate, not current or incomplete, or Vamoos has reasonable grounds to suspect that such information is untrue, inaccurate, not current or incomplete, Vamoos has the right to suspend or terminate your account and refuse any and all current or future use of the Vamoos Services (or any portion thereof).",
            "Vamoos does not claim ownership of Content you submit or make available for inclusion on the Vamoos Services. However, with respect to Content you submit or make available for inclusion on publicly accessible areas of the Vamoos Services, you grant Vamoos the following worldwide, royalty-free and non-exclusive license(s), as applicable.",
            "With respect to Content including photos, graphics, audio or video you submit or make available for inclusion on publicly accessible areas of the Vamoos Services, the perpetual, irrevocable and fully sub licensable license to use, distribute, reproduce, modify, adapt, publish, translate, publicly perform and publicly display such Content (in whole or in part) and to incorporate such Content into other works in any format or medium now known or later developed",
            'You agree to not use the Vamoos Services to upload, post, transmit or otherwise make available any Content that infringes any patent, trademark, trade secret, copyright or other proprietary rights ("Rights") of any party',
            "You may not use the Service for any illegal purpose or to violate any laws in your jurisdiction (including but not limited to copyright laws).",
            "You must provide your legal full name, a valid email address, and any other information requested in order to complete the signup process.",
            "You must be a human. Accounts registered by “bots” or other automated methods are not permitted.",
          ],
        },
      ],
    },
    {
      header: "FOR ALL CUSTOMERS",
      explanations: [],
      terms: [
        {
          header: "Payment, Refunds, Upgrading and Downgrading Terms",
          enumerable: true,
          texts: [
            "For any upgrade or downgrade in plan level, will result in the new rate being charged at the next billing cycle. There will be no prorating for downgrades in between billing cycles.",
            "Downgrading your Service may cause the loss of features or capacity of your account. The Company does not accept any liability for such loss.",
            "All fees are exclusive of all taxes, levies, or duties imposed by taxing authorities, and you shall be responsible for payment of all such taxes, levies, or duties.",
          ],
        },
        {
          header: "Cancellation and Termination",
          enumerable: true,
          texts: [
            "You are solely responsible for properly cancelling your account. ",
            "We require 3 months’ notice of cancellation. ",
            "All of your content will be inaccessible from the Service 3 months following cancellation. ",
            "The Company, in its sole discretion, has the right to suspend or terminate your account and refuse any and all current or future use of the Service for any reason at any time. Such termination of the Service will result in the deactivation or deletion of your Account or your access to your Account, and the forfeiture and relinquishment of all content in your account. The Company reserves the right to refuse service to anyone for any reason at any time.",
          ],
        },
        {
          header: "Modifications to the Service and Prices",
          enumerable: true,
          texts: [
            "The Company reserves the right at any time and from time to time to modify or discontinue, temporarily or permanently, any part of the Service with or without notice.",
            "Prices of all Services are subject to change upon 30 days’ notice from us. Such notice may be provided at any time by posting the changes to the Vamoos site or the Service itself.",
            "The Company shall not be liable to you or to any third party for any modification, price change, suspension or discontinuance of the Service.",
          ],
        },
        {
          header: "Copyright and Content Ownership",
          enumerable: true,
          texts: [
            "All content posted on the Service must comply with U.K copyright law.",
            "We claim no intellectual property rights over the material you provide to the Service. All materials uploaded remain yours.",
            "The Company does not pre-screen content but reserves the right (but not the obligation) in their sole discretion to refuse or remove any content that is available via the Service.",
            "The look and feel of the Service is copyright© Vamoos Limited. All rights reserved. You may not duplicate, copy, or reuse any portion of the HTML, CSS, JavaScript, or visual design elements without express written permission from the Company",
          ],
        },
        {
          header: "Data Protection",
          enumerable: true,
          texts: [
            "Vamoos is fully GDPR compliant and acts as a data processor for your clients’ data.",
            'Vamoos uses one supplier that acts as a data sub processor (Amazon Web Services) which is also GDPR compliant. (<a href="https://aws.amazon.com/blogs/security/all-aws-services-gdpr-ready/">https://aws.amazon.com/blogs/security/all-aws-services-gdpr-ready/</a>)',
            `Vamoos does not control the content or information added to its service – that is controlled by you the data controller. As such, it is important to note our recommendations for the types of data uploaded. Do not upload:
            <ol>
              <li>Client addresses</li>
              <li>Credit/Debit card information</li>
              <li>Passwords</li>
              <li>Passport information or scans</li>
            </ol>
            `,
          ],
        },
        {
          header: "General Conditions",
          enumerable: true,
          texts: [
            "Your use of the Service is at your sole risk. The service is provided on an “as is” and “as available” basis.",
            "Technical support is only provided via email.",
            "You understand that the Company uses third party vendors and hosting partners to provide the necessary hardware, software, networking, storage, and related technology required to run the Service.",
            "You must not modify, adapt or hack the Service.",
            "You must not modify another website so as to falsely imply that it is associated with the Service or the Company.",
            "You agree not to reproduce, duplicate, copy, sell, resell or exploit any portion of the Service, use of the Service, or access to the Service without the express written permission by the Company.",
            "We may, but have no obligation to, remove content and accounts that we determine in our sole discretion are unlawful or violates any party’s intellectual property or these Terms of Service.",
            "Verbal, physical, written or other abuse (including threats of abuse or retribution) of any Service customer, Company employee or officer will result in immediate account termination.",
            "You understand that the technical processing and transmission of the Service, including your content, may be transferred unencrypted and involve (a) transmissions over various networks; and (b) changes to conform and adapt to technical requirements of connecting networks or devices.",
            "We reserve the right to temporarily disable your account if your usage significantly exceeds the average usage of other Service customers. Of course, we'll reach out to the account owner before taking any action except in rare cases where the level of use may negatively impact the performance of the Service for other customers.",
            "The Company does not warrant that (i) the service will meet your specific requirements, (ii) the service will be uninterrupted, timely, secure, or error-free, (iii) the results that may be obtained from the use of the service will be accurate or reliable, (iv) the quality of any products, services, information, or other material purchased or obtained by you through the service will meet your expectations, and (v) any errors in the Service will be corrected.",
            "You expressly understand and agree that the Company shall not be liable for any direct, indirect, incidental, special, consequential or exemplary damages, including but not limited to, damages for loss of profits, goodwill, use, data or other intangible losses (even if the Company has been advised of the possibility of such damages), resulting from: (i) the use or the inability to use the service; (ii) the cost of procurement of substitute goods and services resulting from any goods, data, information or services purchased or obtained or messages received or transactions entered into through or from the service; (iii) unauthorized access to or alteration of your transmissions or data; (iv) statements or conduct of any third party on the service; (v) or any other matter relating to the service.",
            "The failure of the Company to exercise or enforce any right or provision of the Terms of Service shall not constitute a waiver of such right or provision. The Terms of Service constitutes the entire agreement between you and the Company and govern your use of the Service, superceding any prior agreements between you and the Company (including, but not limited to, any prior versions of the Terms of Service).",
          ],
        },
        {
          header: "Publicity",
          enumerable: false,
          texts: [
            "We're proud to have you as a customer. During the term of this agreement, you hereby grant us a worldwide, non-exclusive, royalty-free, non-transferable license to use your trademarks, service marks, and logos for the purpose of identifying you as a Vamoos customer to promote and market Vamoos services. Of course, if you prefer we not use your logo or name in a particular way, just let us know, and we will respect that.",
          ],
        },
      ],
    },
  ],
};

export const USER_WITHOUT_OPERATOR_MESSAGE = "You haven’t been added to any operator yet. Please contact your administrator.";

export const PAGE_NOT_FOUND_TEXT = "The page you are looking for was not found.";

export const NO_API_KEYS_MESSAGE = "No API keys added";

export const VOUCHERS_INFO = "To choose a different icon, go to Shared and select Menus and labels.";
export const INSPIRATIONS = {
  title: "Inspirations",
  numberOfDays: "Number of days",
  messageContent: "Message content",
  period: "Period",
  deviceLocalTime: "Device local time",
  chooseInspirations: "Choose inspiration",
  createNew: "Create new inspiration",
  editInspiration: "Edit inspiration:",
  inspirationNotFound: "Inspiration not found",
};

export const ADMIN_ICONS_CONTENT = {
  listOfPoiIcons: "List of POI icons",
  editListOfPoiIcons: "Edit list of POI icons",
  noIconsDefined: "No icons are defined",
  defaultOfPoiIcon: "Default of POI icon",
  title: "Title",
  default: "Default",
  general: "General",
  image: "Image",
  success: "Icons has been updated successfully",
  failure: "Something went wrong",
};

export const ADMIN_FEATURES_CONTENT = {
  features: "Features",
  name: "Name",
  image: "Image",
  listOfFeatures: "List of features",
  noFeaturesDefined: "No features defined",
  editFeatureList: "Edit list of features",
  listOfFeaturesCategories: "List of features categories",
  featuresSectionTitle: "Features:",
  categoryName: "Category name",
  title: "Title",
  notifications: {
    updateError: "Stay features update failed",
    updateSuccess: "Stay features have been updated successfully",
    toggleStatusSuccess: "Feature status changed",
    toggleStatusFailed: "Something went wrong",
  },
  deleteConfitmationTitle: "Delete confirmation",
  deleteConfirmation: count => `This feature is used in ${count} itineraries. Deleting it will remove it from all of these. Proceed?`,
};

export const ADMIN_OPERATOR_DETAILS_CONTENT = {
  operatorInfo: "Operator information",
  adminInfo: "Admins information",
  generalInfo: "General information",
  adminsList: "List of admin users",
};

export const ADMIN_USERS_INDEX = {
  searchUsers: "Search users",
  allOperators: "All operators",
  filterByOperator: "Filter by operator",
  filterByRole: "Filter by role",
  all: "All roles",
  resetPassword: "Reset password",
  confirmReset: "Confirm reset",
  doYouConfirmReset: "Do you confirm the password reset for ",
};

export const ADMIN_USERS_DETAILS = {
  confirmReset: "Confirm reset",
  doYouConfirmReset: "Do you confirm the password reset for ",
};

export const USERNAME_DETAILS_TAKEN_MESSAGE = "This username is already taken";
export const EMAIL_DETAILS_TAKEN_MESSAGE = "This email is already taken";

export const ADMIN_USER_DETAILS = {
  generalInfo: "General information",
  operatorInfo: "Operators information",
  userInfo: "Users information",
};

export const USER_DETAILS_IS_ACTIVE_LABEL = "Account status";
export const USER_DETAILS_ACTIVE_LABEL = "Active";
export const USER_DETAILS_INACTIVE_LABEL = "Inactive";
export const USER_DETAILS_OPERATORS_LABEL = "List of operators";
export const USER_DETAILS_USERNAME_LABEL = "Username";
export const USER_DETAILS_EMAIL_LABEL = "Email";
export const USER_DETAILS_NAME_LABEL = "Name";
export const USER_DETAILS_LAST_LOGIN_LABEL = "Last login";
export const USER_DETAILS_PASSWORD_EXPIRATION_LABEL = "Password expiry";
export const USER_DETAILS_PHONENUMBER_LABEL = "Phone number";

export const LIBRARY_UPLOAD = {
  clickHere: "Click here or drag files from your computer to upload new document",
  noFolders: "No folders to display",
  pasteUrl: "Paste the URL link of web page here...",
};

export const LIBRARY_CONTENT = {
  folder: "Folder",
  newFolder: "New folder",
  file: "File",
  name: "Name",
  updateFile: "Update file",
  libraryTitle: "Library",
  librarySearchTitle: "Search library results",
  noResultsFound: "No results found for searched phrase.",
  libraryEmpty: "The library is empty at the moment.",
};

export const ADMIN_OPERATOR_INDEX = {
  searchLabel: "Search operators",
  showDeactivated: "Show deactivated",
  operatorActivated: "Operator activated",
  operatorDeactivated: "Operator deactivated",
};

export const ERROR_403_ERROR_MESSAGE =
  "This page requires special privileges.\r\n If you believe you should have access to this page,\r\n please contact your Vamoos administrator.";
export const ACCESS_DENIED_TEXT = "Access denied.";
export const SUSPENDED_ACCOUNT_MESSAGE = "Account suspended please contact your administrator or the Vamoos partnership team.";

export const LOCATION_LABELS = {
  showPoiOnMap: "POI",
  showStays: "Connect",
};

export const CUSTOMISED_NESTED_STAY_LABELS = {
  title: name => `Customise "${name}" stay`,
  save: "save",
  longDescriptionLabel: "Long description",
  shortDescriptionLabel: "Short description",
  shortDescriptionPlaceholder: "Enter your own text to replace the default Short description",
  longDescriptionPlaceholder: "Enter your own text to replace the default Long description",
  default: "Default",
  longDescriptionCopied: "Long description copied",
  shortDescriptionCopied: "Short description copied",
  stayUpdateSuccess: "Nested stay updated successfully",
  stayUpdateFail: "Nested stay update fail",
  getVamoosIdOverridedStay: "Get overrided stay failed",
  info: "Changes made here will affect all your Vamoos itineraries using this property",
  searchMapLabel: "Search all Vamoos properties",
  searchStayToAdd: "Search stay to add",
};

export const LOCATIONS_LABELS = {
  userID: "User ID",
  location: "Location",
  name: "Name",
  coordinates: "Coordinates",
  weather: "Weather",
  maps: "Maps",
  edit: "edit",
  searchLocation: "Search location...",
  addLocation: "add Location",
  add: "add",
  poi: "POI",
  pois: "POIs",
  locations: "Locations",
  locationTabs: "location tabs",
  stays: "Stays",
  addStay: "add stay",
  copyCoords: "copy coordinates",
  preview: "Preview",
  label: "Label",
};

export const STAYS_FEATURES_LABEL = {
  title: "Features",
  requestNewFeature: "request new feature",
  setTop4: "set top 4",
  topFourTitle: "Set TOP 4 features",
};

export const SETTINGS_STAYS = {
  table: {
    userId: "User ID",
    propertyName: "Property name",
    shortDescription: "Short description",
    lognDescription: "Long description",
    custom: "(Custom)",
  },
  pleaseSelectStay: "Select a Stay to customize the descriptions",
  searchField: "Search previously used Vamoos properties",
  countryField: "Show stay by country",
  editStaysDescription: "Edit stay description",
  showBookingNowButton: "Show Booking button",
};

export const SETTINGS_LANGUAGES = {
  defaultLanguage: "Default language",
  additionalLanguages: "Additional languages",
  changeDefaultLanguage: "Change default language",
  selectDefaultLanguage: "Select default language",
  addLanguages: "Add languages",
  selectLanguage: "Select language",
};

export const REQUIRED_FIELD = label => `${label}*`;

export const STAYS_FORM_LABELS = {
  generalInformation: "General information",
  addressAndCoordinates: "Address and coordinates",
  propertyInfoButton: "Property info button",
  overlayLogo: "Overlay Logo",
  vamoosListingInformation: "Vamoos listing information",
  explanations: {
    loginEmails: "Send an email for each new login to the following addresses (multiple email addresses should be separated by a comma)",
  },
  fields: {
    userId: "User ID",
    propertyName: "Property name",
    homeDescription: "Home description",
    contactPhoneNumber: "Contact phone number",
    contactEmail: "Contact email",
    website: "Website",
    twitter: "Twitter",
    facebook: "Facebook",
    instagram: "Instagram",
    mandateEmailLogin: "Mandate email login",
    includeAdmins: "Automatically include account administrators",
    checkInTime24: "Check-in time (24h)",
    checkInTime: "Check-in time",
  },
  shortDescription: "Short description",
  longDescription: "Long description",
  numberOfRooms: "Number of rooms",
  maximumNumberOfCharacters: limit => `Maximum ${limit} characters`,
};

export const MAP_CONTENT = {
  coordinates: "Coordinates",
  copyToField: "copy to field",
  location: "Location",
};
export const MESSAGING = {
  fullName: "Full name",
  checkIn: "Check in date",
  checkOut: "Check out date",
  passcode: "Passcode",
  email: "Email address",
  provider: "Provider",
  deviceType: "Device type",
};

export const MINIATURE_CONTENT = {
  defaultLinkCaption: "Default preview of link",
  imageCaption: name => `Miniature representing ${name} image file.`,
  documentFileCaption: "Default preview of generic pdf document",
  videoFileCaption: "Default preview of generic video file",
  otherFileCaption: "Default preview of other file document",
  storyboardFileCaption: "Default preview of storyboard entry configuration file",
};

export const ADMIN_CONNECT_LABELS = {
  searchLabel: "Search stays",
  authorisedForPublicListing: "Stay is authorised for public listing",
  authorisedForPublicListingFailed: "Stay authorising for public listing failed",
  enqueuedForPublicListing: status => `Stay is ${status} to authorise for public listing`,
  unauthorisedForPublicListing: "Stay is unauthorised for public listing",
};

export const PASSCODES_LABELS = {
  listTitle: "List of passcode categories",
  itemTitle: "Category name*",
};

export const INSPIRATIONS_LABEL = {
  generalInfo: "General information",
  inspirationSection: "Inspiration sections",
  labelRequired: "Label*",
  userID: "User ID*",
  passcode: "Passcode*",
  contactEmail: "Contact email*",
  phone: "Contact phone number*",
  notification: "Notification text",
  notificationHelperMsg: 'Use this box if you plan to modify the inspiration and press "FORCE UPDATE"',
  sectionTitle: "Section title",
};

export const ADMIN_CONNECT_TABLE_LABELS = {
  staysId: "Stay's ID",
  propertyName: "Property name",
  companyName: "Company name",
  created: "Created",
  last30Days: "Last 30 days",
  totalDownloads: "Total downloads",
};

export const DIRECTORY_LABELS = {
  title: "Title",
  buttonOne: "Button 1",
  buttonTwo: "Button 2",
  buttonTitle: "Button title",
  bgImage: "Background image",
  video: "Video",
  file: "File",
  pdfDocLabel: "PDF document or URL to show for button",
  description: "Description",
  popupTitle: (id = null, displayableDirectoryType, is_list) =>
    `${id ? "Edit existing" : "Create new"} ${displayableDirectoryType} ${is_list ? "list " : ""}item`,
  days: "Days",
};

export const CUSTOM_APPS = {
  tableHeaders: {
    title: "App title",
    type: "Type",
    status: "Status",
    created: "Creation date",
    lastUpdated: "Last updated",
    bundleId: "Bundle ID",
    sequence: "Version",
  },
  createPageHeaders: {
    title: "Create Custom App",
    editPageTitle: (name, version) => `Edit ${name} Custom App - version ${version}`,
  },
  general: {
    appInfo: "App info",
    titleLabel: "App title",
    titleHint: "30 characters - maximal app title lenght",
    type: "Type",
    typeModal: {
      popupTitle: "Create custom version of Vamoos",
      popupBodyText:
        // eslint-disable-next-line no-multi-str
        "You can choose colours and styles for your custom app and once complete it will appear in the \
        Appstore/Playstore under your own logo and icon.  Please only continue with this if you have already ordered and paid for a custom app. \
        Email support@vamoos.com  for more information about the prices and process for creating and maintaining a custom version of Vamoos",
      infoText: "To proceed first choose the type of custom app you require",
      cancelLabel: "cancel",
      confirmLabel: "continue",
    },
    typeOptions: {
      company: "Custom app for a travel organising company",
      hotel: "Custom app for a hotel or other property",
    },
    iconSection: {
      title: "App icon",
      attachIconHint: "Image can't be smaller than 1024px x 1024px",
      ios: "iOS",
      android: "Android",
    },
    logoSection: {
      title: "Logo",
      attachLogoHint: "Image shouldn't be smaller than 900px x 300px",
    },
    userIdProp: "Property User ID",
  },
  loginPage: {
    stay: {
      label: "Enter the USER ID for your trip or accomodation:",
      fullName: "Full name",
      emailAddress: "Email address",
      next: "NEXT",
      skip: "SKIP",
      alreadyHave: "If you already have a reservation, please specify the name the booking was made for",
    },
    label: "Login page",
    labelBg: "Login page background",
    backgroundColor: "Background colour",
    textColour: "Text colour",
    navigationColour: "Navigation colour",
    borderColor: "Border colour",
    text1: "Text 1",
    text2: "Text 2",
    text3: "Text 3",
    text4: "Text 4",
    text5: "Text 5",
    text1Color: "Text 1 colour",
    text2Color: "Text 2 colour",
    text3Color: "Text 3 colour",
    text4Color: "Text 4 colour",
    text5Color: "Text 5 colour",
    placeholderText: "Placeholder text",
    actionButton: "Action button",
    actionButtonText: "Action button text",
  },
  elements: {
    bgColorLabel: "Background colour",
    otherPage: {
      label: "Other pages",
      previewHeaderLabel: "Settings",
    },
    accentColor: {
      label: "Accent colour",
      previewHeaderLabel: "FAQ",
      km: "KM",
      miles: "MILES",
      userInfoHeader: "Rebali Riads, Morocco",
      userInfoMessage: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do...",
      inputLabel: "Write your message here",
      sampleChatMsg: "Nothing for now thanks",
      sampleChatTime: "8:00",
    },
    buttons: {
      title: "Buttons",
      activeBgLabel: "Active background colour",
      useAccentColorLabel: "Use the Accent colour",
      activeBgCheckboxLabel: "Use the Accent colour",
      activeTextLabel: "Active text colour",
      inactiveBgLabel: "Inactive background colour",
      inactiveTextLabel: "Inactive text colour",
      menuButtonColorLabel: "Menu button colour",
      active: "Active",
      inactive: "Inactive",
    },
    textFields: {
      title: "Text fields",
      borderColorLabel: "Border colour",
      bgColorLabel: "Background colour",
      textColorLabel: "Text colour",
      inputPresentationLabel: "TXT",
    },
  },
  termsAndCondition: {
    title: "Terms & Conditions",
    headerTextColorLabel: "Header text colour",
    bodyTextColorLabel: "Body text colour",
    nonEditableInfo: "The Vamoos App-use conditions are non-editable but you can add your own T&C's above this text",
    appUseHeadline: "App Use Terms and Conditions",
    appUsePreviewContentP1:
      "These terms and conditions, as may be amended from time to time, apply to all our services directly or indirectly made available online, or through any mobile device. " +
      "By accessing, browsing and using our website or any of our applications through whatever platform, you acknowledge and agree to have read, understood and agreed " +
      "to the terms and conditions set out below.",
    appUsePreviewContentP2:
      "These pages, the content, and infrastructure of these pages are owned, operated, and provided by Vamoos Ltd " +
      "and are provided for your personal, noncommercial use only, subject to the terms and conditions set out below.",
    scopeOfServiceHeadline: "Scope of our Service",
    scoupOfServiceContent:
      "Through the applications and website we, Vamoos Ltd, provide an online/mobile platform through which affiliated Partners can make information available for your use." +
      "When rendering our services, the information that we disclose is based on the information provided to us by Partners. As such, the Partners are given access to an extranet " +
      "through which they are fully responsible for updating all documents and other information which is displayed on our applications and website. Although we will use reasonable " +
      "skill and care in performing our services we will not verify if, and cannot guarantee that, all information is accurate, complete or correct, nor can we be held responsible " +
      "for any errors (including manifest and typographical errors), any interruptions (whether due to any temporary and/or partial breakdown, repair, upgrade or maintenance of our " +
      "website, applications or otherwise), inaccurate, misleading or untrue information or non-delivery of information. Each Partner remains responsible at all times for the accuracy, " +
      "completeness and correctness of the (descriptive) information displayed on our website and applications. Our services are made available for personal and non-commercial use only. " +
      "Therefore, you are not allowed to re-sell, deep-link, use, copy, monitor (e.g. spider, scrape), display, download or reproduce any content or information, software, products " +
      "or services available on our website for any commercial or competitive activity or purpose. Please note that any information you add through the Vamoos App is also available " +
      "to your organiser or accommodation provider. Any information you add will not be used or distributed without your explicit permission.",
    disclaimerHeadline: "Disclaimer",
    disclaimerContent:
      "To the extent permitted by law, neither we nor any of our officers, directors, employees, representatives, subsidiaries, affiliated companies, distributors, " +
      "affiliate partners, licensees, agents or others involved in creating, sponsoring, promoting, or otherwise making available the site and applications and their contents shall be " +
      "liable for  any punitive, special, indirect or consequential loss or damages, any loss of production, loss of profit, loss of revenue, loss of contract, loss of or damage to " +
      "goodwill or reputation, loss of claim, (ii) any inaccuracy relating to the descriptive information of the content as made available on our website and applications, (iii) " +
      "the services rendered or the products offered by the Partner, (iv) any direct, indirect, consequential or punitive damages, losses or costs suffered, incurred or paid " +
      "by you, pursuant to, arising out of or in connection with the use, inability to use or delay of our website or applications, or (v) for any personal injury, death, " +
      "property damage, or other (direct, indirect, special, consequential or punitive) damages, losses or costs suffered, incurred or paid by you, whether due to legal " +
      "acts, errors, breaches, gross negligence, wilful misconduct, omissions, non-performance, misrepresentations, tort or strict liability by or wholly or partly attributable " +
      "to the content (its employees, directors, officers, agents, representatives or affiliated companies), including any strike, force majeure or any other event beyond our control.",
    intelectualRightsHeadline: "Intellectual Property Rights",
    intelectualRightsContent:
      "Unless stated otherwise, the software required for our services or available at or used by our website and applications and the intellectual property " +
      "rights (including the copyrights) of the contents and information of and material on our website are owned by Vamoos Ltd, its suppliers or providers. Vamoos Ltd exclusively " +
      "retains ownership of all rights, title and interest in and to (all intellectual property rights of) (the look and feel (including infrastructure) of) the website and " +
      "applications on which the service is made available and you are not entitled to copy, scrape, (hyper-/deep-)link to, publish, promote, market, integrate, utilize, combine " +
      "or otherwise use the content (including any translations thereof) or our brand Vamoos.com without our express written permission. To the extent that you would (wholly or partly) " +
      "use or combine our (translated) content or would otherwise own any intellectual property rights in the website or applications or any content, you hereby assign, transfer " +
      "and set over all such intellectual property rights to Vamoos Ltd. Any unlawful use or any of the aforementioned actions or behaviour will constitute a material infringement " +
      "of our intellectual property rights (including copyright and database right).",
  },
  faq: {
    title: "FAQ",
  },
  about: {
    title: "About",
  },
  colors: {
    title: "Colours",
    bgColorLabel: "Background colour",
    headerTextLabel: "Header text colour",
    bodyTextLabel: "Body text colour",
  },
  modals: {
    deleteModalDescription: "Are you sure you wish to permanently dismiss this version?",
    createReviseModalDescription: (name, version) =>
      `Confirm you wish to create a new custom app draft based on ${name} - version ${version}`,
  },
  menu: {
    title: "Menu",
    bgLabel: "Background colour",
    textAndIconLabel: "Text and icon colour",
    divider: {
      light: "Light",
      dark: "Dark",
      separationLineLabel: "Separation line colour",
    },
  },
  previews: {
    messagingDate: "26 February",
  },
  notifications: {
    submit: "Custom app submitted successfully",
    dismiss: "Custom app dissmised successfully",
    dismissError: "Custom app dismiss failed!",
    submitFail: "Custom app submitted unsuccessfully",
    submitIncompleteDef: "Cannot submit incomplete definition",
    reviseSuccess: "Custom App revised successfully!",
    reviseFailed: "Error occured while attempt to revise custom app.",
    reviseDraftError: "Cannot add version while last revision is in state draft",
  },
};

export const CUSTOM_APP_NAVIGATION = {
  general: "General",
  logoAndIcons: "Logo and icons",
  elements: "Elements and colours",
  loginScreen: "Login screen",
  menu: "Menu",
  texts: "Texts",
  preview: "Preview",
  versionHistory: "Version history",
  information: "App stores",
};

export const VAMOOS_CONNECT_INFO_MESSAGE = {
  message: "Vamoos Connect now live. Click here for more details.",
  url: "https://www2.vamoos.com/vamooos-connect-for-travel-companies",
};
export const REQUEST_ABORTED_MESSAGE = "Previous request was aborted";

// TODO: when api ready we should be able to update options value
export const CUSTOM_APP_OPTIONS = [
  { value: "stay", label: CUSTOM_APPS.general.typeOptions.hotel },
  { value: "trip", label: CUSTOM_APPS.general.typeOptions.company },
];

export const CustomAppStatus = {
  none: "NONE",
  ok: "OK",
  error: "ERROR",
};

export const CUSTOM_APP_MENU_STRINGS = {
  menuLoadNew: "Load new",
  menuViewAll: "View All",
  menuRefresh: "Refresh",
  menuSettings: "Settings",
  menuAbout: "About Vamoos",
  menuFaq: "FAQ",
  menuTerms: "Terms & Conditions",
  menuNotification: "Notifications",
};
export const CUSTOM_APP_MENU_SAMPLE_STAYS = {
  trip: "Trip",
  nested: "Nested Stay",
};

export const SETTINGS_CONTENT = {
  label: "Settings",
};

export const FLIGHTS_PREVIEW_LABELS = {
  label: "Flights",
  source: "London",
  destination: "Belfast",
  departureDate: "11 Jan 2021",
  departureTime: "07:45",
  arrivalDate: "11 Jan 2021",
  arrivalTime: "09:05",
  flightNumber: "BA1414",
  lastUpdate: "Last update - Just now",
};

export const MESSAGING_CONTENT = {
  label: "Messaging",
  messagess: [
    { content: "Should I take an umbrella?", createdAt: "12:00:00", user: "User 1" },
    { content: "There is no need. The whole week is going to be hot and sunny :)", createdAt: "12:01:00", user: "me" },
  ],
};

export const CA_VERSIONS_HISTORY = {
  editPreviousVersion: "Edit previous version of custom app",
  draft: "Draft",
  submitted: "Submitted",
  rejected: "Rejected",
  archived: "Archived",
  live: "Live",
  tableLabels: {
    title: "App Title",
    version: "Version",
    status: "Status",
    lastChange: "Last status change",
    created: "Date created",
    parent: "Parent",
  },
};

export const CUSTOM_APP_ADMIN = {
  tooltips: {
    accept: "Accept",
    reject: "Reject",
    revert: "Revert to draft",
    live: "Mark as live",
  },
  table: {
    name: "App title",
    version: "Version",
    status: "Status",
    lastChange: "Last updated",
    created: "Creation date",
    parent: "Parent",
  },
  rejectionPopup: {
    title: "Rejection notice",
    text: "Please note that you need to separately reach out to the customer and explain the rejection. Are you sure you want to proceed?",
  },
  reversionPopup: {
    title: "Reversion notice",
    text: "Please note that you need to separately reach out to the customer and explain the reversion. Are you sure you want to proceed?",
  },
  notifications: {
    acceptSuccess: "Custom App approved successfully!",
    accessFail: "Error occured while attempt to approve custom app.",
    rejectSuccess: "Custom App rejected successfully!",
    rejectFail: "Error occured while attempt to reject custom app.",
    revertToDraftSuccess: "Custom App reverted to draft successfully!",
    revertToDraftFail: "Error occured while attempt to revert to draft custom app.",
    revertToLiveSuccess: "Custom App reverted to live successfully!",
    revertToLiveFail: "Error occured while attempt to revert to live custom app.",
    getCustomAppVersionFail: "Fetching custom app versions failed",
  },
};

export const OPERATOR_DETAILS_NAVIGATION = {
  general: "General",
  stats: "Statistics",
  customApps: "Custom apps",
};

export const COLOR_PICKER = {
  hex: "Hex",
  transparency: "Transparency",
};
export const PUBLISHER_CONTENT = {
  newPublisher: "+ new",
  listOfPublishers: "List of Publisher documents",
  showPois: "Show POIs",
  doesntHaveDirectories: "Your stay doesn&apos;t have any directories",
  directories: {
    directory: "Directory",
    newPage: "New page",
    showImage: "Show image",
    imageSize: "Image size",
    selectAll: "Select all",
  },
  modalTitle: isEdit => `${isEdit ? "Edit existing" : "Generate new"} Publisher document`,
  large: "Large",
  small: "Small",
  includeSections: "Include following sections in document:",
  sections: {
    coverPage: "Cover page",
    coverPageNotes: "Footer notes (optional)",
    fileName: "File name",
    frontPageNotes: "Front page notes",
    introductoryNotes: "Introductory notes",
    includeContactDetails: "Include contact details",
    directory: "Directory",
    youCanConfigureDirectoryInNextStep: "You can configure the directory in the next step",
    map1: "Map 1",
    map2: "Map 2",
    map3: "Map 3",
    poiList: "POI list",
    tableOfContents: "Table of Contents",
    orderPoiBy: "Order POI by:",
    type: "Type",
    distance: "Distance",
    lastPageNotes: "Last page notes",
  },
  converImageSize: {
    firstOptionLabel: "Reduced-size cover (default)",
    firstOptionValue: "reduced",
    secondOptionLabel: "Full-size cover",
    secondOptionValue: "full",
  },
  errors: {
    emptySectionList: "Document has to contain at least one section",
  },
  messages: {
    success: "Publisher has been successfuly generated",
    failed: "Publisher generation has failed",
  },
};

export const CREATIONS_CONTENT = {
  title: prefix => `${prefix} Creation`,
  editExisting: "Edit existing",
  generateNew: "Generate new",
  sections: {
    fileName: "File name",
    showDaysInDocument: "Show days in document",
    includeFollowingSections: "Include following sections in document:",
    storyboard: "Storyboard",
    locationsMap: "Locations map",
    tableOfContents: "Table of Contents",
    frontPageNotes: "Front page notes",
    lastPageNotes: "Last page notes",
  },
  storyboardImageSize: {
    firstOptionLabel: "Full-height images (default)",
    firstOptionValue: "adjusted",
    secondOptionLabel: "Fixed size images (square)",
    secondOptionValue: "fixed",
  },
};

export const STAY_POIS = {
  toAddMessage: "To add a new POI please go to the POI screen in the Shared menu",
  listOfPois: "List of POIs",
};

export const UPDATE_MODAL = {
  title: "Force update / Send notification",
  noTemplates: "No templates are available",
  notificationTemplate: "Notification template",
  bespokeNotification: "Bespoke notification",
  forceUpdate: "Force update",
  forceUpdateTip: "Checks App has latest information",
  sendOnlyToActive: "Send only to active",
  sendOnlyToActiveTip: "Sends message to users in their stay dates",
  selectTemplate: "Select template",
  notificationText: "Notification text",
  useLanguage: lang => `Only target users using ${lang} language`,
  useCurrentLanguage: "Use current language",
};

export const STAY_WIZARD_CONTENT = {
  notifications: {
    forceUpdate: "Force update sent successfully",
    forceUpdateError: "Force update has not been sent",
  },
  actions: {
    action: "Action",
    bookNow: "Book now",
  },
  dailyActivities: {
    dailyActivities: "Daily activities",
    name: "Name",
    title: "Title",
    days: "Days",
  },
  restrictions: {
    alwaysShow: "Always show",
    showDuringStay: "Show during stay",
    passcodesOnly: "Passcodes only",
    off: "Off",
    whenToDisplay: "When to display",
    accessSettings: "Access settings",
  },
  directories: {
    directory: "Directory",
    directories: "Directories",
    folder: "Folder",
    subDirectory: "Sub-directory",
    details: "Details",
    deleteModalTitle: name => `${PANEL_TRIPS_ACTION_MESSAGES.deleteConfirmationBase} ${name} directory?`,
    title: "Title",
  },
  addressAndCoordinates: {
    address: "Address",
    coords: "Coordinates",
    searchLocation: "Search location",
    copyToField: "Copy to field",
  },
  vouchers: {
    vouchers: "Vouchers",
    deleteModalTitle: name => `${PANEL_TRIPS_ACTION_MESSAGES.deleteConfirmationBase} ${name} voucher?`,
    title: "Title",
  },
  navigation: {
    general: "General",
    actions: "Actions",
    pois: "POIs",
    gallery: "Gallery",
    directory: "Directory",
    dailyActivities: "Daily activities",
    vouchers: "Vouchers/menus",
    passcodes: "Passcodes",
    notifications: "Notifications",
    inspirations: "Inspirations",
    dnd: "DND",
    publisher: "Publisher",
    messaging: "Messaging",
    shareAccess: "Share access",
    eraseData: "Erase data",
  },
  fetchingError: "Attempting to load stay failed",
  dnd: {
    title: "Do not disturb",
    contactEmail: "Contact email",
    cutOffTime: "Cut off time",
    listIsEmpty: "Do not disturb requests list is empty.",
    waiting: "Waiting",
    accepted: "Accepted",
    room: "Room",
    guestName: "Guest name",
    dateSent: "Date sent",
    timeSent: "Time sent",
    endTime: "DND end time",
  },
  passcodes: {
    categoryName: "Category name",
    passcode: "Passcode",
  },
  permissions: {
    permissions: "Share access",
    removeUser: "Remove user",
    email: "Email",
    read: "Read",
    write: "Write",
    invite: "Invite",
    inviteUser: "Invite user",
    invitedUsers: "Invited users",
    notifications: {
      fetchUsersError: "Failed fetch stay's user",
      invitedUser: "User has been invited successfully",
      inviteSelf: "You cannot edit your own permissions",
      inviteExisting: "This user already has permissions for this Vamoos. First delete existing.",
      inviteUserError: "User invitation has failed",
      removedUser: "User has been removed successfully",
      removeUserError: "User removing has failed",
    },
    message: "Share access section is only available for already existing itineraries",
  },
};

export const TRIP_WIZARD_CONTENT = {
  general: {
    instantViewLink: "instant view link",
  },
  documents: {
    title: "Title",
  },
  navigation: {
    general: "General",
    documents: "Documents",
    locations: "Locations",
    storyboard: "Storyboard",
    notifications: "Notifications",
    people: "People",
    flights: "Flights",
    inspirations: "Inspirations",
    creations: "Creations",
    messaging: "Messaging",
    shareAccess: "Share access",
    eraseData: "Erase data",
  },
  storyboard: {
    storyboard: "Storyboard",
    addToStoryboard: "Add to storyboard",
    addToLibrary: "Add to library",
    day: "Day",
    dayNumber: "Day number",
    dayTitle: "Title",
    locations: "Locations",
    additionalInformation: "Additional information",
    backgroundImage: "Background image",
    title: "Title",
    documents: "Documents",
    listOfDays: "List of days",
    reorder: "Reorder",
    youHaveToSaveTrip:
      "In order to add this entry to the library you first have to save your changes. Please save your changes and try again.",
    storyboardComponentSaved: "Storyboard component has been successfully saved",
    removeModalTitle: name =>
      `You’ve removed the location ${name} from your storyboard. Do you want to permanently remove it from the trip?`,
    removeFlightModalTitle: (flightNumber, departureCity, arrivalCity) =>
      `You’ve removed the flight ${flightNumber} from ${departureCity} to ${arrivalCity} from your storyboard. Do you want to permanently remove it from the trip?`,
  },
  permissions: {
    permissions: "Share access",
    removeUser: "Remove user",
    email: "Email",
    read: "Read",
    write: "Write",
    invite: "Invite",
    inviteUser: "Invite user",
    invitedUsers: "Invited users",
    notifications: {
      fetchUsersError: "Failed fetch trip's user",
      invitedUser: "User has been invited successfully",
      inviteSelf: "You cannot edit your own permissions",
      inviteExisting: "This user already has permissions for this Vamoos. First delete existing.",
      inviteUserError: "User invitation has failed",
      removedUser: "User has been removed successfully",
      removeUserError: "User removing has failed",
    },
    message: "Share access section is only available for already existing itineraries",
  },
  notifications: {
    forceUpdate: "Force update sent successfully",
    forceUpdateError: "Force update has not been sent",
  },
};

export const MESSAGING_LABELS = {
  fetchingMessages: "Fetching messages for current itinerary...",
  settings: "Settings",
  noMessagesPresent: "No messages to be displayed.",
  friendlyNameUpdateError: "Unable to update friendly name with the API",
  sendMessageError: "Unable to send new message to the API",
  updateLastReadConversationError: "Unable to update last read conversation with the API",
  paddedPlaceholder: "Click on the element from the list to display the message.",
  title: "conversation name",
};

export const LOGIN_SCREEN_EDITOR_LABELS = {
  save: "Save",
  insertWeblink: "Insert/Edit Link",
  insertPhone: "Insert/Edit Link",
  insertEmail: "Insert/Edit Link",
  addWeblink: "Add weblink",
  addPhone: "Add phone number",
  addEmail: "Add email",
  phone: "Phone number",
  email: "Email",
  weblink: "Weblink",
  url: "URL",
  textToDisplay: "Text to display",
  title: "Title",
};

export const FLIGHT_LABELS = {
  notFoundInfo: {
    line1: "Sorry, we could not find the flight",
    line2: "in our system.",
    reasons: {
      headline: "There are several possible reasons why we don't have a record matching this flight number including:",
      reason1: "The flight number or airline code is incorrect",
      reason2: "The flight number may have been changed by the airline",
      reason3: "The flight may not operate on the date requested",
      reason4: "The flight may actually be operated by another airline",
      reason5: "The flight may be a charter flight that isn't tracked in our system",
    },
  },
  entry: {
    headline: (departure, arrival) => `Flight from ${departure} to ${arrival}`,
    content: (departureAirport, terminal, departureTime, arrivalAirport, arrivalTime) =>
      `Flight departs from ${departureAirport}${
        terminal ? ` ${terminal}` : ""
      } at ${departureTime} and arrives at ${arrivalAirport} at ${arrivalTime}`,
    flightHeadline: name => `Stay at ${name}`,
  },
};

export const DEFAULT_TERMS_CONTENT = `
  <h3>App Use Terms and Conditions</h3>
  <p>
    These terms and conditions, as may be amended from time to time, apply to all our services directly or indirectly made available
    online, or through any mobile device. By accessing, browsing and using our website or any of our applications through whatever
    platform, you acknowledge and agree to have read, understood and agreed to the terms and conditions set out below.
  </p>
  <p>
    These pages, the content, and infrastructure of these pages are owned, operated, and provided by Vamoos Ltd and are provided for
    your personal, noncommercial use only, subject to the terms and conditions set out below.
  </p>
  <h3>Scope of our Service</h3>
  <p>
    Through the applications and website we, Vamoos Ltd, provide an online/mobile platform through which affiliated Partners can
    make information available for your use. When rendering our services, the information that we disclose is based on the
    information provided to us by Partners. As such, the Partners are given access to an extranet through which they are fully
    responsible for updating all documents and other information which is displayed on our applications and website. Although we
    will use reasonable skill and care in performing our services we will not verify if, and cannot guarantee that, all information
    is accurate, complete or correct, nor can we be held responsible for any errors (including manifest and typographical errors),
    any interruptions (whether due to any temporary and/or partial breakdown, repair, upgrade or maintenance of our website,
    applications or otherwise), inaccurate, misleading or untrue information or non-delivery of information. Each Partner remains
    responsible at all times for the accuracy, completeness and correctness of the (descriptive) information displayed on our
    website and applications. Our services are made available for personal and non-commercial use only. Therefore, you are not
    allowed to re-sell, deep-link, use, copy, monitor (e.g. spider, scrape), display, download or reproduce any content or
    information, software, products or services available on our website for any commercial or competitive activity or purpose.
    Please note that any information you add through the Vamoos App is also available to your organiser or accommodation provider.
    Any information you add will not be used or distributed without your explicit permission.
  </p>
  <h3>Disclaimer</h3>
  <p>
    To the extent permitted by law, neither we nor any of our officers, directors, employees, representatives, subsidiaries,
    affiliated companies, distributors, affiliate partners, licensees, agents or others involved in creating, sponsoring, promoting,
    or otherwise making available the site and applications and their contents shall be liable for any punitive, special, indirect
    or consequential loss or damages, any loss of production, loss of profit, loss of revenue, loss of contract, loss of or damage
    to goodwill or reputation, loss of claim, (ii) any inaccuracy relating to the descriptive information of the content as made
    available on our website and applications, (iii) the services rendered or the products offered by the Partner, (iv) any direct,
    indirect, consequential or punitive damages, losses or costs suffered, incurred or paid by you, pursuant to, arising out of or
    in connection with the use, inability to use or delay of our website or applications, or (v) for any personal injury, death,
    property damage, or other (direct, indirect, special, consequential or punitive) damages, losses or costs suffered, incurred or
    paid by you, whether due to legal acts, errors, breaches, gross negligence, wilful misconduct, omissions, non-performance,
    misrepresentations, tort or strict liability by or wholly or partly attributable to the content (its employees, directors,
    officers, agents, representatives or affiliated companies), including any strike, force majeure or any other event beyond our
    control.
  </p>
  <h3>Intellectual Property Rights</h3>
  <p>
    Unless stated otherwise, the software required for our services or available at or used by our website and applications and the
    intellectual property rights (including the copyrights) of the contents and information of and material on our website are owned
    by Vamoos Ltd, its suppliers or providers. Vamoos Ltd exclusively retains ownership of all rights, title and interest in and to
    (all intellectual property rights of) (the look and feel (including infrastructure) of) the website and applications on which
    the service is made available and you are not entitled to copy, scrape, (hyper-/deep-)link to, publish, promote, market,
    integrate, utilize, combine or otherwise use the content (including any translations thereof) or our brand Vamoos.com without
    our express written permission. To the extent that you would (wholly or partly) use or combine our (translated) content or would
    otherwise own any intellectual property rights in the website or applications or any content, you hereby assign, transfer and
    set over all such intellectual property rights to Vamoos Ltd. Any unlawful use or any of the aforementioned actions or behaviour
    will constitute a material infringement of our intellectual property rights (including copyright and database right).
  </p>
`;
export const CREATION_LINNKS_LABELS = {
  sectionTitle: "Creations links",
  instantView: "Creation instant view label",
  instantViewMaps: "Creation instant view maps label",
};

export const ACCOUNT_PAGE_LABELS = {
  editUser: "Edit user",
  assingNewUser: "Assign a new Vamoos user",
  assingUser: "Assign user",
  save: "Save",
};

export const BRANDING_LABELS = {
  createBrand: "CREATE BRAND",
  selectBrand: "Select branding",
  brandingProfile: "Branding profile",
  creationLinks: "Creation links",
  creationHeader: "Creation header",
  creationFooter: "Creation footer",
  logo: "Logo",
  createBrandLabel: "Create brand",
  default: "Default",
  notifications: {
    create: "Brand created successfuly",
    createFail: "Creating brand failed",
    load: "Selected brand loaded successfuly",
    update: "Brand updated successfuly",
    updateFail: "Updating brand failed",
  },
};

export const GENERAL_SETTINGS_LABELS = {
  generalSettings: "General settings",
  celsius: "Celsius",
  fahrenheit: "Fahrenheit",
  storyboardView: "Storyboard view",
  details: "Details",
  summary: "Summary",
  summaryAndDetails: "Summary + Details",
};

export const SIGN_UP = {
  hotelCodeHelperText: " If you have multiple properties, we recommend choosing a code that is suitable for your group.",
  companyCodeBaseText: `Choose an identifier that clients will use when logging in to your Vamoos entries.
    We recommend a short 3-12 character code based on your company name, e.g. CLTC for Caring Luxurious Travel Company.`,
};

export const POIS_LABELS = {
  newPoi: "New POI",
  editPoi: "Edit POI",
  newTrack: "New track",
  editTrack: "Edit track",
  newRoute: "New route",
  editRoute: "Edit route",
};

export const TABLE_NAMES = {
  trip: "trip",
  stay: "stay",
  library: "library",
  notifications: "notifications",
  poi: "poi",
};

export const AUTH = {
  appleWarningMessage: "You will soon be asked if you want to hide your email. Note that if you choose to do so this will stop us from properly identified you.",
  appleWarningTitle: "Caution: Hide Email Option",
  appleStateMismatch: "The authorization request is invalid or malformed.",
  unknownErrorReauth: "Something went wrong. Try to authenticate again",
}
