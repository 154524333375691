import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import MuiAutocomplete from "@material-ui/lab/Autocomplete";
import { FormControl, FormHelperText, TextField } from "@material-ui/core";
import { setInputAttr } from "utils/styles";

const AutocompleteTextField = styled(TextField)`
  &&& fieldset {
    border-color: ${({ theme }) => theme.colors.grey40};
  }

  &&& .Mui-error fieldset {
    border-color: ${({ theme }) => theme.colors.error};
  }

  &&&:hover fieldset {
    border-color: ${({ theme }) => theme.colors.brand};
  }

  &&& .MuiOutlinedInput-root {
    border-radius: ${({ theme }) => theme.borderRadius}px;

    &:hover {
      border-color: ${({ theme }) => theme.colors.brand};
    }
  }

  &&& .Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${({ theme }) => theme.colors.brand};
  }

  &&& .Mui-focused.MuiInputLabel-root {
    color: ${({ theme }) => theme.colors.brand};
  }

  &&& .MuiChip-root.MuiAutocomplete-tag {
    background: ${({ theme }) => theme.colors.brand};
    color: #ffffff;

    .MuiChip-deleteIcon {
      color: #ffffff;
    }
  }
`;

const AutocompleteStyled = styled(MuiAutocomplete)`
  &&& .MuiInputLabel-root {
    transform: translate(${({ size }) => setInputAttr("transform-label", size, "before")}) scale(1);
    ${p => p.$styleLabelCustom};
  }

  &&& .MuiInputLabel-shrink {
    transform: translate(${({ size }) => setInputAttr("transform-label", size, "after")}) scale(0.75);
    z-index: 2;
  }

  &&& .MuiInputBase-root {
    padding: 10px 9px;
  }

  &&& .MuiOutlinedInput-adornedEnd {
    padding-right: 14px;
  }

  &&&& .MuiInputBase-input {
    padding: 0 0 0 4px;
    height: 20px;
    /* margin: 1px 0; */
  }

  &&& .MuiChip-root {
    height: 24px;
  }

  &&& .MuiChip-deleteIcon {
    height: 16px;
    width: 16px;
  }

  /* &&& fieldset {
    height: 53px;
  } */
`;

const StyledFormHelperText = styled(FormHelperText)`
  && {
    font-size: ${({ theme }) => theme.fonts.fontSize.caption}px;
    line-height: ${({ theme }) => theme.fonts.fontSize.caption + theme.setSpacing(1)}px;
  }
`;

/**
 *
 * Extended Material UI Autocomplete
 * This implementation is easier to maintain and more appropriate
 * Might be wrapped with form controls
 *
 * @param {*} param0
 * @returns
 */
const Autocomplete = React.forwardRef(({ label, inputProps, helperText, isRequired, size, styleLabelCustom, ...props }, ref) => {
  return (
    <FormControl error={helperText} fullWidth>
      <AutocompleteStyled
        disableClearable
        $styleLabelCustom={styleLabelCustom}
        renderInput={params => (
          <AutocompleteTextField
            {...params}
            InputProps={{
              ...params.InputProps,
              ...inputProps,
            }}
            label={label}
            size={size}
            variant="outlined"
            error={helperText}
            required={isRequired}
          />
        )}
        {...props}
        ref={ref}
      />
      {helperText && <StyledFormHelperText component="div">{helperText}</StyledFormHelperText>}
    </FormControl>
  );
});

Autocomplete.defaultProps = {
  label: null,
  isRequired: false,
  helperText: null,
  inputProps: {},
  size: "medium",
};

Autocomplete.propTypes = {
  label: PropTypes.string,
  isRequired: PropTypes.bool,
  helperText: PropTypes.string,
  inputProps: PropTypes.shape({}),
  size: PropTypes.oneOf(["small", "medium"]),
};

export { Autocomplete };
