import { takeEvery, put, select } from "redux-saga/effects";

import * as actionTypes from "store/stays/actionTypes";
import { setNotification } from "store/app/actions";
import {
  getStaysFeaturesSuccess,
  getStaysFeaturesFail,
  getStaysDndSuccess,
  getStaysDndFail,
  setStaysDndApprovalSuccess,
  setStaysDndApprovalFail,
  checkIsUserIdAvailableSuccess,
  createStaysPublisherSuccess,
  createStaysPublisherFail,
  getStaysPublishersSuccess,
  getStaysPublishersFail,
  refreshStaysPublisherSuccess,
  refreshStaysPublisherFail,
  deleteStaysPublisherFail,
  requestStayConnectStatusSuccess,
  requestStayConnectStatusFail,
} from "store/stays/actions";

import { setUrl } from "utils/url";
import { handleErrorMessages } from "utils/store";
import { HttpClient } from "services/application/httpClient/httpClient";

import notifications from "constants/notifications";
import {
  STAYS_FEATURES_URL,
  DND_APPROVAL_URL,
  USER_ID_AVAILABILITY_CHECK_URL,
  CREATRIONS_URL,
  DELETE_CREATRION,
  REQUEST_STAY_CONNECT_STATUS,
} from "constants/api";
import { DEFAULT_PASSCODE } from "constants/defaults";
import { DND_REQUEST_MSG, ERRORS, VAMOOS_CONNECT_REQUEST_MSG } from "constants/content";

import { STAYS_SECTION_ERROR } from "feature/panel/Stays/_shared/helpers";
import { getSectionsWithErrorsNames } from "feature/panel/_shared/helpers";
import { FeatureStay } from "domain/FeatureStay";

function* getFeatures() {
  try {
    const response = yield HttpClient.get(STAYS_FEATURES_URL);
    const { items } = response.data;
    yield put(getStaysFeaturesSuccess(items.map(FeatureStay)));
  } catch (e) {
    yield put(getStaysFeaturesFail(handleErrorMessages(e)));
  }
}

function* getDnd({ payload }) {
  const { url } = payload;
  try {
    const response = yield HttpClient.get(url);
    const { items, total_matches } = response.data;
    yield put(getStaysDndSuccess({ items, count: total_matches }));
  } catch (e) {
    yield put(getStaysDndFail(handleErrorMessages(e)));
  }
}

function* setDndApproval({ payload }) {
  const { id, newList, userId, passcode } = payload;
  try {
    yield HttpClient.post(setUrl(setUrl(DND_APPROVAL_URL, { operator: userId, code: passcode }, true), { id }), { approved: true });
    yield put(setStaysDndApprovalSuccess(newList));
    yield put(setNotification({ type: "success", message: DND_REQUEST_MSG.success }));
  } catch (e) {
    yield put(setStaysDndApprovalFail(handleErrorMessages(e)));
    yield put(setNotification({ type: "error", message: DND_REQUEST_MSG.fail }));
  }
}

function* requestStayConnectStatus({ payload }) {
  const { operatorCode, passcode, requestedStatus } = payload;
  try {
    yield HttpClient.post(setUrl(REQUEST_STAY_CONNECT_STATUS, { operatorCode, passcode }, true), {
      status: requestedStatus,
    });
    yield put(requestStayConnectStatusSuccess());
    yield put(setNotification({ type: "success", message: VAMOOS_CONNECT_REQUEST_MSG.success }));
  } catch (e) {
    yield put(requestStayConnectStatusFail(handleErrorMessages(e)));
    yield put(setNotification({ type: "error", message: VAMOOS_CONNECT_REQUEST_MSG.fail }));
  }
}

export const checkUserIdTaken = async (operatorCode, isCurrentOperatorCode, currentOperator = null) => {
  const url = setUrl(USER_ID_AVAILABILITY_CHECK_URL, { operator_code: operatorCode }, true);
  const headers = currentOperator ? { "X-Operator-Code": currentOperator } : {};
  try {
    const { data } = await HttpClient.get(url, { headers });
    const { used, operator, stay } = data || {};
    if (currentOperator === operatorCode) {
      return stay && (used || operator || isCurrentOperatorCode);
    }
    return used || operator || isCurrentOperatorCode;
  } catch {
    return false;
  }
};

function* checkUserIdAvailability({ payload }) {
  const { operators, currentOperatorCode } = yield select(state => state.auth);
  const isCurrentOperatorCode = operators.some(({ usedCodes }) => usedCodes && usedCodes.includes(payload));

  const isIdTaken = yield checkUserIdTaken(payload, isCurrentOperatorCode, currentOperatorCode);
  const errorMessage = isIdTaken ? ERRORS.userIdTaken : "";

  yield put(checkIsUserIdAvailableSuccess(errorMessage));
}

function* createPublisher({ payload }) {
  const { data, operator_code, url } = payload;
  const directories = yield select(({ stayWizard }) => stayWizard.form.directories.items);
  try {
    const preparedData = {
      ...data,
      sections: data.sections
        .map(section => {
          if (section.type === "directory") {
            const filteredNodes = section.nodes.filter(node => {
              return directories.some(dir => dir.tag === node.section);
            });

            filteredNodes.sort((a, b) => {
              if (directories.findIndex(dir => dir.tag === a.section) < directories.findIndex(dir => dir.tag === b.section)) {
                return -1;
              }
              return 1;
            });

            if (filteredNodes.length > 0) {
              return {
                ...section,
                nodes: filteredNodes,
              };
            }

            return null;
          }
          return section;
        })
        .filter(section => !!section),
    };

    yield HttpClient.post(setUrl(CREATRIONS_URL, { operator_code, reference_code: DEFAULT_PASSCODE }, true), preparedData);
    const response = yield HttpClient.get(url);
    const { items, total_matches } = response.data;
    yield put(createStaysPublisherSuccess({ items, count: total_matches }));
    yield put(
      setNotification({
        type: "success",
        message: notifications.resource("publisher")[data.id ? "update" : "create"].success,
      }),
    );
  } catch (e) {
    yield put(createStaysPublisherFail(handleErrorMessages(e)));
  }
}

function* getPublishers({ payload }) {
  const { url } = payload;
  try {
    const response = yield HttpClient.get(url);
    const { items, total_matches } = response.data;
    yield put(getStaysPublishersSuccess({ items, count: total_matches }));
  } catch (e) {
    yield put(getStaysPublishersFail(handleErrorMessages(e)));
  }
}

function* refreshPublisher({ payload }) {
  const { data, url } = payload;
  try {
    const { publishers } = yield select(state => state.stays);
    const directories = yield select(({ stayWizard }) => stayWizard.form.directories.items);

    const preparedData = {
      ...data,
      sections: data.sections
        .map(section => {
          if (section.type === "directory") {
            const filteredNodes = section.nodes.filter(node => {
              return directories.some(dir => dir.tag === node.section);
            });

            filteredNodes.sort((a, b) => {
              if (directories.findIndex(dir => dir.tag === a.section) < directories.findIndex(dir => dir.tag === b.section)) {
                return -1;
              }
              return 1;
            });

            if (filteredNodes.length > 0) {
              return {
                ...section,
                nodes: filteredNodes,
              };
            }

            return null;
          }
          return section;
        })
        .filter(section => !!section),
    };

    const response = yield HttpClient.post(url, preparedData);
    const newPublishers = publishers.map(publisher =>
      publisher.id === data.id ? { ...publisher, job: { ...publisher.job, status: response.data.status } } : publisher,
    );
    yield put(refreshStaysPublisherSuccess(newPublishers));
    yield put(
      setNotification({
        type: "success",
        message: notifications.resource("publisher").refresh.success,
      }),
    );
  } catch (e) {
    put(refreshStaysPublisherFail(e));
  }
}

function* deletePublisher({ payload }) {
  const { reference_code, operator_code, id, url } = payload;

  try {
    yield HttpClient.delete(setUrl(DELETE_CREATRION, { reference_code, operator_code, id }, true));
    const response = yield HttpClient.get(url);
    const { items, total_matches } = response.data;

    yield put(createStaysPublisherSuccess({ items, count: total_matches }));
    yield put(
      setNotification({
        type: "success",
        message: notifications.resource("publisher").delete.success,
      }),
    );
  } catch (e) {
    put(deleteStaysPublisherFail(e));
  }
}

function* createOrUpdateFailValidationHandler() {
  const { errors } = yield select(state => state.stays);
  const wizardErrors = yield select(({ stayWizard }) => stayWizard.errors);
  const [errorSections, isPlural] = getSectionsWithErrorsNames({ ...errors, ...wizardErrors }, STAYS_SECTION_ERROR);
  yield put(setNotification({ type: "error", message: ERRORS.saveActionError(errorSections, isPlural) }));
}

export function* staysSaga() {
  yield takeEvery(actionTypes.CREATE_OR_UPDATE_STAY_VALIDATION_FAIL, createOrUpdateFailValidationHandler);
  yield takeEvery(actionTypes.GET_STAYS_FEATURES_START, getFeatures);
  yield takeEvery(actionTypes.GET_STAYS_DND_START, getDnd);
  yield takeEvery(actionTypes.SET_STAYS_DND_APPROVAL_START, setDndApproval);
  yield takeEvery(actionTypes.REQUEST_STAY_CONNECT_STATUS_START, requestStayConnectStatus);
  yield takeEvery(actionTypes.CHECK_USER_ID_AVAILABILITY_START, checkUserIdAvailability);
  yield takeEvery(actionTypes.CREATE_STAYS_PUBLISHER_START, createPublisher);
  yield takeEvery(actionTypes.GET_STAYS_PUBLISHERS_START, getPublishers);
  yield takeEvery(actionTypes.REFRESH_STAYS_PUBLISHER_START, refreshPublisher);
  yield takeEvery(actionTypes.DELETE_STAYS_PUBLISHER_START, deletePublisher);
  yield takeEvery(actionTypes.CLEAR_STAYS_PUBLISHER_JOB, getPublishers);
}
