// @ts-check
import { PasscodeAlreadyUsedException } from "exceptions/PasscodeAlreadyUsedException";
import { PaginatedMapper } from "mappers/PaginatedMapper";
import { TripPermissionMapper } from "mappers/TripPermissionMapper";
import { TripRepository } from "repositories/TripRepository";

class TripService {
  /**
   * @param {import("repositories/TripRepository").TripRepository=} tripRepository
   */
  constructor(tripRepository = new TripRepository()) {
    /** @type {import("repositories/TripRepository").TripRepository} */
    this.tripRepository = tripRepository;

    /** @type {import("mappers/PaginatedMapper").PaginatedMapper} */
    this.paginatedMapper = new PaginatedMapper(new TripPermissionMapper());
  }

  /**
   * @todo: Should use mapper
   *
   * @param {*} operator
   * @param {*} code
   * @returns
   */
  async getTrip(operator, code) {
    return this.tripRepository.getTrip(operator, code);
  }

  /**
   * @todo: Should use mapper
   *
   * @param {*} userId
   * @param {*} code
   * @returns
   */
  async saveTrip(userId, code, data, operator) {
    return this.tripRepository.saveTrip(userId, code, data, operator);
  }

  /**
   *
   * @param {string} operator
   * @param {string} code
   * @param {*} params
   * @returns {Promise<import("domain/Paginated").Paginated<import("domain/TripPermission").TripPermission>>}
   */
  async getTripUsers(operator, code, params = {}) {
    const paginatedUsersObj = await this.tripRepository.getTripUsers(operator, code, params);
    return this.paginatedMapper.fromDtoToDomain(paginatedUsersObj);
  }

  /**
   *
   * @param {string} email
   * @param {"read"|"write"} permission
   * @param {string} operator
   * @param {string} code
   * @returns
   */
  async addUserToTrip(email, permission, operator, code) {
    return this.tripRepository.addUserToTrip(email, permission, operator, code);
  }

  /**
   *
   * @param {number} userId
   * @param {string} operator
   * @param {string} code
   * @returns
   */
  async removeUserFromTrip(userId, operator, code) {
    return this.tripRepository.removeUserFromTrip(userId, operator, code);
  }

  async checkIfPasscodeIsTaken(userId, passcode, vamoosId) {
    try {
      const data = await this.tripRepository.checkIfPassocdeIsTaken(userId, passcode);

      if (data.vamoos_id === vamoosId) {
        return false;
      }

      throw new Error("passcode");
    } catch (e) {
      if (e.message === "passcode") throw new PasscodeAlreadyUsedException();
      throw new Error("Something went wrong");
    }
  }

  /**
   *
   * @param {*} userId
   * @param {*} passcode
   * @param {*} notificationText
   * @returns
   */
  async sendUpdate(userId, passcode, notificationText) {
    return this.tripRepository.sendUpdate(userId, passcode, notificationText);
  }
}

export { TripService };
