import React, { useState } from "react";
import styled from "styled-components";

import { Paper, ContentWrapper, Header } from "feature/panel/Settings/_shared/styledComponents";
import { InformationField } from "feature/panel/Settings/_shared/InformationField";
import { useSelector, useDispatch } from "react-redux";
import { FieldChangeModal } from "feature/panel/Settings/_shared/FieldChangeModal";
import {
  changeCompanyContactPerson,
  changeCompanyDescription,
  changeCompanyName,
  changeCompanyNumberOfBookings,
  changeCompanyNumberOfPassengers,
  changeCompanyTelephone,
  changeCompanyWebsite,
} from "store/operator/actions";
import { COMPANY_SETTINGS, ERRORS } from "constants/content";
import { usePermissionsService } from "hooks/usePermissionsService";
import { PERMISSIONS } from "constants/permissions";
import { AccessDenied } from "components/templates/_shared/AccessDenied/AccessDenied";
import { GeneralSettings } from "./GeneralSettings";
import { GeneralLanguages } from "./Languages/GeneralLanguages";

const CompanyLogo = styled.img`
  max-width: 576px;
`;

const General = ({ hasPermission }) => {
  const permissionsService = usePermissionsService();

  const [errorMessage, setErrorMessage] = useState("");
  const [editModal, setEditModal] = useState({ open: false, body: {} });
  const dispatch = useDispatch();
  const { currentOperator } = useSelector(state => state.operator);
  const canEdit = permissionsService.can(PERMISSIONS.actions.update, PERMISSIONS.sections.operator, PERMISSIONS.resources.operators);

  const handleEditionToggle = field => {
    const { open } = editModal;
    const body = open ? editModal.body : field;
    setEditModal({ body, open: !open });
    setErrorMessage("");
  };

  const handleEditionConfirm = () => {
    const { body } = editModal;
    if (!body.allowEmpty && body.value.length === 0) {
      setErrorMessage(ERRORS.isRequired(body.label));
    } else {
      if (body.fieldName === "name") {
        dispatch(changeCompanyName(body.value));
        handleEditionToggle();
        return;
      }

      if (body.fieldName === "contact_person") {
        dispatch(changeCompanyContactPerson(body.value));
        handleEditionToggle();
        return;
      }

      if (body.fieldName === "telephone") {
        dispatch(changeCompanyTelephone(body.value));
        handleEditionToggle();
        return;
      }

      if (body.fieldName === "number_of_bookings") {
        dispatch(changeCompanyNumberOfBookings(body.value));
        handleEditionToggle();
        return;
      }

      if (body.fieldName === "number_of_passengers") {
        dispatch(changeCompanyNumberOfPassengers(body.value));
        handleEditionToggle();
        return;
      }

      if (body.fieldName === "description") {
        dispatch(changeCompanyDescription(body.value));
        handleEditionToggle();
        return;
      }

      if (body.fieldName === "website") {
        dispatch(changeCompanyWebsite(body.value));
        handleEditionToggle();
      }
    }
  };

  const handleValueChange = ({ target }) => {
    setEditModal({
      ...editModal,
      body: {
        ...editModal.body,
        value: target.value,
      },
    });
  };

  const { code, name, contactPerson, telephone, numberOfBookings, numberOfPassengers, description, website } = currentOperator;

  const fieldsData = [
    {
      label: COMPANY_SETTINGS.fields.companyCode,
      value: code,
      formatted: code,
      fieldName: "code",
      nonMetaValue: true,
      nonEditable: true,
      allowEmpty: true,
    },
    {
      label: COMPANY_SETTINGS.fields.companyName,
      value: name,
      formatted: code !== "ADMIN" ? `${name} (${code})` : name,
      fieldName: "name",
      nonMetaValue: true,
      allowEmpty: false,
      nonEditable: !canEdit,
    },
    {
      label: COMPANY_SETTINGS.fields.contactPerson,
      value: contactPerson,
      formatted: contactPerson,
      fieldName: "contact_person",
      allowEmpty: false,
      nonEditable: !canEdit,
    },
    {
      label: COMPANY_SETTINGS.fields.contactPhoneNumber,
      value: telephone,
      formatted: telephone,
      fieldName: "telephone",
      allowEmpty: false,
      nonEditable: !canEdit,
    },
    {
      label: COMPANY_SETTINGS.fields.numberOfBookings,
      value: numberOfBookings,
      formatted: numberOfBookings,
      fieldName: "number_of_bookings",
      allowEmpty: true,
      nonEditable: !canEdit,
    },
    {
      label: COMPANY_SETTINGS.fields.numberOfPassengers,
      value: numberOfPassengers,
      formatted: numberOfPassengers,
      fieldName: "number_of_passengers",
      allowEmpty: true,
      nonEditable: !canEdit,
    },
    {
      label: COMPANY_SETTINGS.fields.generalDescription,
      value: description,
      formatted: description,
      fieldName: "description",
      allowEmpty: true,
      nonEditable: !canEdit,
    },
    {
      label: COMPANY_SETTINGS.fields.companyWebsite,
      value: website,
      formatted: website,
      fieldName: "website",
      allowEmpty: true,
      nonEditable: !canEdit,
    },
  ];

  const { open, body } = editModal;

  return hasPermission ? (
    <>
      <GeneralSettings />
      <GeneralLanguages />
      <Paper>
        <ContentWrapper>
          <Header>{COMPANY_SETTINGS.companyInformation}</Header>
          {fieldsData.map(field => {
            const editToggle = () => handleEditionToggle(field);
            return (
              <InformationField
                key={field.fieldName}
                title={field.label}
                content={field.formatted}
                onEditClick={!field.nonEditable ? editToggle : undefined}
              />
            );
          })}
        </ContentWrapper>
        <FieldChangeModal
          open={open}
          label={body.label || ""}
          value={body.value || ""}
          onValueEdit={handleValueChange}
          onConfirm={handleEditionConfirm}
          onCancel={handleEditionToggle}
          inputProps={{ error: !!errorMessage, helperText: errorMessage }}
        />
      </Paper>
    </>
  ) : (
    <AccessDenied />
  );
};

export { General, ContentWrapper, CompanyLogo, Paper };
