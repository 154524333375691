import { useState } from "react";
import { useDispatch } from "react-redux";

import { isAuthorizedToMakeRequest } from "utils/isAuthorizedToMakeRequest";
import { setNotification } from "store/app/actions";
import { fetchConversationMessagesHandler, getGroupedMessagesHandler } from "utils/messaging/getMessages";
import { messagesMapper, threadMessagesPayloadMapper } from "utils/messagesMapper";

const FETCH_MESSAGES_ERROR = "Unable to fetch messages for given itinerary";
const UNABLE_TO_DETERMINE_STATUS = "Unable to determine data access for current user";

export const setThreadLastReadTime = (threadsList, editedThreadId, readTimestamp) =>
  threadsList.map(thread => (thread.id === editedThreadId ? { ...thread, lastReadAt: readTimestamp } : thread));

const useMessagesList = ({ details, sendRequest }) => {
  const [conversations, setConversations] = useState([]);
  const [blockDispatch, setBlockDispatch] = useState(false);
  const [finishedFetchingMessages, setFinishedFetchingMessages] = useState(false);
  const dispatch = useDispatch();

  const blockHookRequests = () => {
    setBlockDispatch(true);
    setFinishedFetchingMessages(true);
  };

  const updateMessageLastRead = (id, timestamp) => {
    const newList = setThreadLastReadTime(conversations, id, timestamp);
    setConversations(newList);
  };

  const fetchConversationMessages = () => {
    if (details.reference_code && details.operator_code) {
      fetchConversationMessagesHandler(details)
        .then(result => {
          const mappedConversations = messagesMapper(result);

          setConversations(mappedConversations);
        })
        .catch(() => {
          blockHookRequests();
          dispatch(
            setNotification({
              type: "error",
              message: FETCH_MESSAGES_ERROR,
            }),
          );
        })
        .finally(() => setFinishedFetchingMessages(true));
    }
  };

  const reloadMessages = () => {
    isAuthorizedToMakeRequest()
      .then(allowRequest => {
        if (allowRequest && !blockDispatch && !sendRequest) {
          fetchConversationMessages();
        }
      })
      .catch(() => {
        blockHookRequests();
        dispatch(
          setNotification({
            type: "error",
            message: UNABLE_TO_DETERMINE_STATUS,
          }),
        );
      });
  };

  const updateThreadMessages = async conversationId => {
    const groupedMessages = await getGroupedMessagesHandler(details);
    const editedConversation = conversations.find(({ id }) => id === conversationId);
    const updatedEditedConversation = {
      ...editedConversation,
      messages: groupedMessages[conversationId]
        .map(item => ({ ...item, friendly_name: editedConversation.friendly_name }))
        .map(threadMessagesPayloadMapper),
    };

    const newListOfMessages = conversations.map(conversation =>
      conversation.id === conversationId ? updatedEditedConversation : conversation,
    );
    const listWithUpdatedReadTime = setThreadLastReadTime(newListOfMessages, conversationId, new Date().getTime());
    setConversations(listWithUpdatedReadTime);
  };

  const friendlyNameUpdate = (friendlyName, threadId) => {
    const msgUpdated = conversations.map(conversation => (conversation.id !== threadId ? conversation : { ...conversation, friendlyName }));
    setConversations(msgUpdated);
  };

  return [conversations, finishedFetchingMessages, reloadMessages, updateMessageLastRead, updateThreadMessages, friendlyNameUpdate];
};

export { useMessagesList };
