import React from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";

const DefaultPageLeftSection = ({ children }) => (
  <Grid item lg={7}>
    <Grid container justifyContent="center">
      <Grid item xs={7}>
        {children}
      </Grid>
    </Grid>
  </Grid>
);

DefaultPageLeftSection.defaultProps = {
  children: null
};

DefaultPageLeftSection.propTypes = {
  children: PropTypes.node,
};

export { DefaultPageLeftSection }
