export const TOGGLE_FEATURE_ACTIVE_STATUS_START = "TOGGLE_FEATURE_ACTIVE_STATUS_START";
export const TOGGLE_FEATURE_ACTIVE_STATUS_SUCCESS = "TOGGLE_FEATURE_ACTIVE_STATUS_SUCCESS";
export const TOGGLE_FEATURE_ACTIVE_STATUS_FAIL = "TOGGLE_FEATURE_ACTIVE_STATUS_FAIL";

export const GET_FEATURES_STAYS_START = "GET_FEATURES_STAYS_START";
export const GET_FEATURES_STAYS_SUCCESS = "GET_FEATURES_STAYS_SUCCESS";
export const GET_FEATURES_STAYS_FAIL = "GET_FEATURES_STAYS_FAIL";

export const SET_VALUE_FOR_STAY = "SET_VALUE_FOR_STAY";
export const SET_VALUE_FOR_STAY_FEATURE = "SET_VALUE_FOR_STAY_FEATURE";

export const ADD_NEW_STAY_FEATURE_START = "ADD_NEW_STAY_FEATURE_START";
export const ADD_NEW_FEATURE_ITEM_START = "ADD_NEW_FEATURE_ITEM_START";
export const DELETE_STAY_FEATURE_START = "DELETE_STAY_FEATURE_START";
export const DELETE_FEATURE_ITEM_START = "DELETE_FEATURE_ITEM_START";

export const SAVE_FEATURE_STAYS_START = "SAVE_FEATURE_STAYS_START";
export const SAVE_FEATURE_STAYS_SUCCESS = "SAVE_FEATURE_STAYS_SUCCESS";
export const SAVE_FEATURE_STAYS_FAIL = "SAVE_FEATURE_STAYS_FAIL";

export const SET_ORDER_FOR_STAY_FEATURES = "SET_ORDER_FOR_STAY_FEATURES";
export const SET_ORDER_FOR_FEATURES_ITEM = "SET_ORDER_FOR_FEATURES_ITEM";

export const SET_STAYS_FEATURES_FORM_TOUCHED = "SET_STAYS_FEATURES_FORM_TOUCHED";
