import { isObjectEmpty } from "utils/object";
import uuidv4 from "uuid";

export function StoryboardComponent(storyboardFile) {
  const domain = {};

  domain.id = storyboardFile?.id || uuidv4();
  domain.headline = storyboardFile?.title || "";
  domain.content = storyboardFile.content || "";
  domain.content = domain.content.replace(/\n/g, "<br>");
  domain.documents = storyboardFile.documents || [];
  if (storyboardFile?.location) {
    domain.location = storyboardFile.location;
  }

  if (!isObjectEmpty(storyboardFile?.image)) {
    domain.image = {
      ...storyboardFile.image,
    };
  }

  return domain;
}
