import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import uuidv4 from "uuid";
import { List, Divider } from "@material-ui/core";

import { ContextMenuButton } from "components/ui/Buttons/ContextMenuButton";

const StyledList = styled(List)`
  && {
    padding: 0;
  }
`;

const StyledDivider = styled(Divider)`
  && {
    width: calc(100% - ${({ theme }) => theme.setSpacing(8)}px);
    margin: ${({ theme }) => theme.setSpacing(2)}px auto;
  }
`;

const ContextNavigationList = ({ list }) => {
  return (
    <StyledList>
      {list.map(({ icon, label, url, action, isActive, divider, hasError, disabled, replace }) =>
        divider ? (
          <StyledDivider key={uuidv4()} />
        ) : (
          <ContextMenuButton
            key={label}
            icon={icon}
            label={label}
            url={url}
            action={action}
            error={hasError || undefined}
            isActive={isActive}
            disabled={disabled}
            replace={replace}
          />
        ),
      )}
    </StyledList>
  );
};
ContextNavigationList.propTypes = {
  list: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export { ContextNavigationList };
