import * as actionTypes from "store/nested/actionTypes";
import { reducer, setState, globalReducerActions } from "utils/store";

const nestedInitialState = {
  errors: {},
  actionType: null,
  inProgress: false,
  finished: false,
  searchMode: false,
  listOfNested: [],
  allNested: [],
  nestedCount: 0
};

const ReducerActions = globalReducerActions("nested", nestedInitialState, {

  [actionTypes.GET_NESTED_ITINERARIES_START](state) {
    return setState(state, {
      actionType: actionTypes.GET_NESTED_ITINERARIES_START,
      inProgress: true,
    });
  },

  [actionTypes.GET_NESTED_ITINERARIES_SUCCESS](state, payload) {
    const { items, total_matches, searchMode } = payload;
    return setState(state, {
      actionType: actionTypes.GET_NESTED_ITINERARIES_SUCCESS,
      inProgress: false,
      listOfNested: items,
      nestedCount: total_matches,
      errors: {},
      searchMode,
    });
  },

  [actionTypes.GET_NESTED_ITINERARIES_FAIL](state, payload) {
    return setState(state, {
      actionType: actionTypes.GET_NESTED_ITINERARIES_FAIL,
      inProgress: false,
      nestedCount: 0,
      listOfNested: [],
      errors: payload.errors,
      searchMode: payload.searchMode,
    });
  },

  [actionTypes.GET_ALL_NESTED_ITINERARIES_START](state) {
    return setState(state, {
      actionType: actionTypes.GET_NESTED_ITINERARIES_START,
      inProgress: true,
    });
  },

  [actionTypes.GET_ALL_NESTED_ITINERARIES_SUCCESS](state, payload) {
    const { items, searchMode } = payload;
    return setState(state, {
      actionType: actionTypes.GET_NESTED_ITINERARIES_SUCCESS,
      inProgress: false,
      allNested: items,
      errors: {},
      searchMode,
    });
  },

  [actionTypes.GET_ALL_NESTED_ITINERARIES_FAIL](state, payload) {
    return setState(state, {
      actionType: actionTypes.GET_NESTED_ITINERARIES_FAIL,
      inProgress: false,
      allNested: [],
      errors: payload.errors,
      searchMode: payload.searchMode,
    });
  },

});

const nestedReducer = (state = nestedInitialState, action) => reducer(state, action, ReducerActions);

export { nestedReducer };
