import { useLayoutEffect, useState } from "react";

const useWindowResize = fn => {
  const [size, setSize] = useState(fn());
  useLayoutEffect(() => {
    function updateSize() {
      setSize(fn());
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, [fn]);
  return size;
}

export { useWindowResize };
