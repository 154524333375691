import styled from "styled-components";
import { Textarea } from "components/ui/Forms";
import { SortableListElement } from "components/_shared/SortableListElement/SortableListElement";

const StyledSortableListElement = styled(SortableListElement)`
  && {
    height: auto;
    min-height: 80px;
  }
`;

const StyledTextarea = styled(Textarea)`
  && {
    padding-top: 0;
    margin-bottom: ${({ theme }) => theme.setSpacing(2)}px;
  }
`;

const ContentArea = styled.div`
  width: calc(100% - 72px);
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const DescArea = styled.div`
  height: 100%;
  max-height: 120px;
  display: flex;
  width: calc(100% - 148px);
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  margin: 24px 0;
`;

export { StyledSortableListElement, StyledTextarea, ContentArea, DescArea };
