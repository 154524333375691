import moment from "moment";
import { convertToFileObject } from "utils/dataConverters";

import { defaultStartTime } from "feature/panel/Trips/_shared/tripInitialState";
import { getFlightsMapUrl } from "utils/url";
import { isValidCoordinatePart } from "utils/validation";

import locationIcon from "assets/images/location.png";
import nestedLocationIcon from "assets/images/nestingLocation.png";
import { UrlRefreshService } from "services/application/UrlRefreshService";

const { StoryboardEntry } = require("./StoryboardEntry");

export const getBooleanValue = value => (value === null ? null : Boolean(Number(value)));

export const Trip = dto => {
  const {
    operator_id,
    operator_code,
    reference_code,
    version,
    is_current_version,
    directories,
    passcodes,
    start_time,
    created_at,
    updated_at,
    meta,
    documents,
    departure_date,
    locations,
    details,
    travellers,
    pois,
    logo,
    background,
    notifications,
    flights,
    inspiration,
    branding_profile_id,
    language,
    ...restOfDto
  } = dto;
  const {
    show_days,
    show_messaging,
    show_book_in_nested,
    require_personal_details,
    show_story_board_daily,
    show_story_board_summary,
    messaging_email_during_days_before,
    messaging_email_during_days_after,
    messaging_emails_list,
    show_journal,
    show_poi_list,
    icon_destination_documents,
    label_destination_documents,
    label_travel_documents,
    travel_documents_label,
    destination_documents_label,
    icon_travel_documents,
    copied_from,
  } = meta;

  const domain = {
    ...restOfDto,
    language,
    userId: operator_code,
    passcode: reference_code,
    destinationDocuments: documents.destination
      ? documents.destination.map(destinationDoc => convertToFileObject(destinationDoc, true))
      : [],
    // label_destination_documents is deprecated field but might occur in existing itineraries
    destinationDocumentsTitle: label_destination_documents || destination_documents_label,
    destinationDocumentsIcon: Number(icon_destination_documents) || 0,
    travelDocuments: documents.travel ? documents.travel.map(travelDoc => convertToFileObject(travelDoc, true)) : [],
    // label_travel_documents is deprecated field but might occur in existing itineraries
    travelDocumentsTitle: label_travel_documents || travel_documents_label,
    travelDocumentsIcon: Number(icon_travel_documents) || 0,
    departure_date,
    start_time: moment(`${departure_date} ${start_time || defaultStartTime}`),
    show_journal: Boolean(Number(show_journal)),
    show_poi_list: Boolean(Number(show_poi_list)),
    pois: pois.map(({ id, is_on }) => ({ id, is_on })),
    listOfFlights: flights.map(flight => {
      return {
        ...flight,
        map: getFlightsMapUrl(flight.departure_airport_fs_code, flight.arrival_airport_fs_code),
      };
    }),
    logo: logo ? convertToFileObject(logo, true) : logo,
    locations: locations.map(
      ({ loc_position, created_at: cat, updated_at: uat, operator_id: pi, itinerary_id, library_node, ...rest }) => ({
        ...rest,
        internal_id: rest.id,
        icon: {
          url: rest?.nested ? nestedLocationIcon : locationIcon,
        },
        coordinates:
          isValidCoordinatePart(rest.longitude) && isValidCoordinatePart(rest.latitude) ? `${rest.latitude}, ${rest.longitude}` : "",
        file: convertToFileObject(library_node, true),
      }),
    ),
    // storyboard: details.map((entry, index) => {
    //   console.log(index === 3 && entry.content?.replace(/<h1>/g, "").replace(/<\/h1>/g, ""));
    //   return StoryboardEntry({ ...entry, content: index === 3 ? entry.content?.replace(/<h1>/g, "") : "asd" }, !!Number(show_days));
    // }),
    storyboard: details.map(entry => StoryboardEntry(entry, !!Number(show_days))),
    notifications: notifications.map(({ location_id, ...notification }) => ({
      ...notification,
      location_internal_id: location_id,
    })),
    travelPeople: travellers.map(({ created_at: cat, updated_at: uat, operator_id: pi, itinerary_id, ...rest }) => rest),
    background: background ? convertToFileObject(background, true) : background,
    brandingProfileId: branding_profile_id || null,
    inspiration,
    meta: {
      ...dto.meta,
      show_messaging,
      messaging_emails_list,
      messaging_email_during_days_before: messaging_email_during_days_before || "0",
      messaging_email_during_days_after: messaging_email_during_days_after || "0",
      show_journal: getBooleanValue(show_journal),
      show_days: getBooleanValue(show_days),
      show_story_board_daily: getBooleanValue(show_story_board_daily),
      show_story_board_summary: getBooleanValue(show_story_board_summary),
      show_poi_list: getBooleanValue(show_poi_list),
      require_personal_details: getBooleanValue(require_personal_details),
      show_book_in_nested: getBooleanValue(show_book_in_nested),
    },
    updated_at,
    version,
    copiedFrom: copied_from,

    async refreshLinks() {
      this.storyboard = await Promise.all(
        this.storyboard.map(async entry => {
          if (entry.meta.type === "stay") {
            if (entry.image?.file_url || entry.image?.fileUrl) {
              return {
                ...entry,
                image: {
                  ...entry.image,
                  file_url: await UrlRefreshService.refresh(entry.image?.file_url || entry.image?.fileUrl),
                },
              };
            }
          }
          return entry;
        }),
      );
    },
  };
  if (inspiration && inspiration.vamoos_id) {
    domain.inspiration_vamoos_id = inspiration.vamoos_id;
  }
  return domain;
};
