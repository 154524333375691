import React, { useState } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { Table, TableHeader, TableBody, TablePagination } from "components/ui/Tables";
import { ShowAction, EditAction, MessageAction, TableActionsContainer } from "components/ui/Tables/TableRowActions";
import { ColumnSelector } from "components/_shared/ColumnSelector/ColumnSelector";

import { ItineraryTableRow } from "feature/panel/_shared/ItineraryTableRow/ItineraryTableRow";

import columns from "feature/panel/Stays/Index/TableOfStaysColumns.json";
import { CopyingStayModal } from "feature/panel/Stays/CopyingStayModal/CopyingStayModal";

import { usePermissionsService } from "hooks/usePermissionsService";
import { PERMISSIONS } from "constants/permissions";

import { LS_STAYS_SELECTED_COLUMNS } from "constants/localstorage";
import { TABLE_ACTIONS_TITLES, TABLE_NAMES } from "constants/content";
import { PANEL_STAYS_EDIT_CONTENT_PATH, PANEL_STAYS_EDIT_PATH, PANEL_TRIPS_CREATE_MESSAGING_PARAM } from "constants/routes";

import { setUrl } from "utils/url";

import { useService } from "hooks/useService";
import { StayService } from "services/domain/StayService";

import notifications from "constants/notifications";
import { pushSuccessNotification } from "store/app/actions";

import { useColumnSelectorHandlers } from "hooks/useColumnSelectorHandlers";
import { RevertActionButton } from "../ReventActionButton/RevertActionButton";

const tableId = "stays";

const TableOfStays = ({ stays, tableHandlers, page, count, fetchStays, isLoadingInProgress }) => {
  const [openColumnSelectorPopup, setOpenColumnSelectorPopup] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [activeItem, setActiveItem] = useState(null);
  const navigate = useNavigate();
  const permissionsService = usePermissionsService();

  const dispatch = useDispatch();

  const stayService = useService(StayService);

  const { headers, handleSave } = useColumnSelectorHandlers(LS_STAYS_SELECTED_COLUMNS, columns, setOpenColumnSelectorPopup);

  const canCreateVamoos = permissionsService.can(
    PERMISSIONS.actions.create,
    PERMISSIONS.sections.vamoosList,
    PERMISSIONS.resources.default,
  );

  const handleToggleModal = item => {
    setActiveItem(item);
    setOpenModal(!openModal);
  };

  const handleStayRestore = async ({ operator_code }) => {
    const { isActive } = await stayService.getStayVariant(operator_code);
    if (isActive) {
      await stayService.deactivateStay(operator_code);
    } else {
      await stayService.activateStay(operator_code);
    }
    fetchStays();
    dispatch(pushSuccessNotification(notifications.resource("stays").update.success));
  };

  const { handleChangeOrder, handleChangeRowsPerPage, handleChangePage } = tableHandlers;

  const renderActiveTripActions = item => {
    const messagingUrl = setUrl(
      PANEL_STAYS_EDIT_CONTENT_PATH,
      {
        operator_code: item.operator_code,
        content: PANEL_TRIPS_CREATE_MESSAGING_PARAM,
      },
      true,
    );
    return (
      <>
        <MessageAction clickAction={() => navigate(messagingUrl)} tooltip={TABLE_ACTIONS_TITLES.openMessages} />
        {canCreateVamoos && (
          <ShowAction
            clickAction={() => handleToggleModal(item)}
            tooltip={TABLE_ACTIONS_TITLES.duplicateResource(TABLE_ACTIONS_TITLES.resourceTypes.stay)}
          />
        )}
      </>
    );
  };

  const bodyCellAction = ({ item, archived }) => {
    const canUpdateVamoosItem = permissionsService.can(PERMISSIONS.actions.update, PERMISSIONS.sections.vamoosList, item.vamoos_id);
    const restoreButton = canUpdateVamoosItem && (
      <RevertActionButton
        clickAction={() => handleStayRestore(item)}
        tooltip={TABLE_ACTIONS_TITLES.restoreResource(TABLE_ACTIONS_TITLES.resourceTypes.stay)}
      />
    );
    if (item?.is_wiped) return null;
    return archived ? restoreButton : renderActiveTripActions(item);
  };
  const staysWithAddress = stays.map(stay => ({ ...stay, address: stay.meta?.address || "" }));

  const tableActions = (
    <TableActionsContainer>
      <EditAction
        noBackground
        clickAction={() => setOpenColumnSelectorPopup(true)}
        height={16}
        tooltip={TABLE_ACTIONS_TITLES.editColumns}
      />
    </TableActionsContainer>
  );

  return (
    <>
      <Table
        url={PANEL_STAYS_EDIT_PATH}
        urlParams={["operator_code"]}
        headers={headers}
        list={staysWithAddress}
        actions={bodyCellAction}
        onChangeOrder={handleChangeOrder}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        isDataLoading={isLoadingInProgress}
      >
        <TableHeader withActions actionsLabel={tableActions} tableId={TABLE_NAMES.stay} />
        <TableBody rowComponent={ItineraryTableRow} />
        <TablePagination page={page - 1} count={count} tableId={TABLE_NAMES.stay} />
        <ColumnSelector
          open={openColumnSelectorPopup}
          onClose={() => setOpenColumnSelectorPopup(false)}
          onSave={handleSave}
          list={headers}
          limit={6}
          tableId="stays"
        />
      </Table>
      <CopyingStayModal open={openModal} onCancel={handleToggleModal} item={activeItem || {}} />
    </>
  );
};
TableOfStays.defaultProps = {
  isLoadingInProgress: false,
};
TableOfStays.propTypes = {
  stays: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  tableHandlers: PropTypes.shape({
    handleChangeOrder: PropTypes.func,
    handleChangeRowsPerPage: PropTypes.func,
    handleChangePage: PropTypes.func,
    forceQuery: PropTypes.func,
  }).isRequired,
  count: PropTypes.number.isRequired,
  isLoadingInProgress: PropTypes.bool,
};

export { TableOfStays };
