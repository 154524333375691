import React from "react";
import styled from "styled-components";

const LoadingArea = styled.div`
  width: 100%;
  height: 50vh;
`;

const WaitingWrapper = styled(({ withBorder, ...rest }) => <div {...rest} />)`
  float: right;
  display: flex;
  justify-content: space-around;
  align-items: center;
  max-width: 360px;
  width: 100%;
  height: 72px;
  box-sizing: content-box;
  ${({ withBorder, theme }) => (withBorder ? `border-left: 1px solid ${theme.colors.grey10}` : null)}
`;

const WaitingTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: ${({ theme }) => theme.setSpacing(72)}px;
  flex-grow: 1;
  height: ${({ theme }) => theme.setSpacing(18)}px;
  color: ${({ theme }) => theme.colors.grey50};
  border-left: 1px solid ${({ theme }) => theme.colors.grey10};
  & > span {
    padding: 0 2px;
  }
`;

const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 72px;
  height: 72px;
`;

export { LoadingArea, WaitingWrapper, WaitingTextWrapper, SpinnerWrapper };
