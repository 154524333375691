import React from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  PANEL_ROOT_PATH,
  PANEL_LIBRARY_PATH,
  PANEL_TRIPS_ROOT_PATH,
  PANEL_STAYS_PATH,
  PANEL_NOTIFICATIONS_PATH,
  PANEL_INSPIRATIONS_PATH,
  PANEL_POIS_PATH,
  PANEL_SETTINGS_COMPANY,
  PANEL_SETTINGS_ROOT,
  PANEL_HELP_USER_GUIDE,
  PANEL_HELP_ROOT,
  PANEL_SETTINGS_CONNECT,
} from "constants/routes";
import { PANEL_MAIN_NAVIGATION_LABELS } from "constants/content";

import { MainNavigationButton } from "components/ui/Buttons";

import { checkIsActiveUrl } from "utils/url";
import { usePermissionsService } from "hooks/usePermissionsService";
import { PERMISSIONS } from "constants/permissions";

const { vamoosList, library, shared, settings, help } = PANEL_MAIN_NAVIGATION_LABELS;

const MainNavigation = () => {
  const { pathname } = useLocation();
  const permissionsService = usePermissionsService();

  const { currentOperatorCode } = useSelector(state => state.auth);

  const getSharedUrl = () => {
    if (permissionsService.can(PERMISSIONS.actions.read, PERMISSIONS.sections.operator, PERMISSIONS.resources.notifications)) {
      return PANEL_NOTIFICATIONS_PATH;
    }
    if (permissionsService.can(PERMISSIONS.actions.read, PERMISSIONS.sections.operator, PERMISSIONS.resources.pois)) {
      return PANEL_POIS_PATH;
    }
    return PANEL_INSPIRATIONS_PATH;
  };

  const settingsPageUrl = permissionsService.can(PERMISSIONS.actions.read, PERMISSIONS.sections.operator, PERMISSIONS.resources.operators)
    ? PANEL_SETTINGS_COMPANY
    : PANEL_SETTINGS_CONNECT;

  const canReadNotifications = permissionsService.can(
    PERMISSIONS.actions.read,
    PERMISSIONS.sections.operator,
    PERMISSIONS.resources.notifications,
  );
  const canReadPois = permissionsService.can(PERMISSIONS.actions.read, PERMISSIONS.sections.operator, PERMISSIONS.resources.pois);
  const canReadInspiration = permissionsService.can(
    PERMISSIONS.actions.read,
    PERMISSIONS.sections.operator,
    PERMISSIONS.resources.inspirations,
  );
  const canReadLibrary = permissionsService.can(PERMISSIONS.actions.read, PERMISSIONS.sections.operator, PERMISSIONS.resources.library);

  return currentOperatorCode ? (
    <>
      <MainNavigationButton
        to={PANEL_ROOT_PATH}
        isActive={
          checkIsActiveUrl(pathname, PANEL_ROOT_PATH, true) ||
          checkIsActiveUrl(pathname, PANEL_TRIPS_ROOT_PATH) ||
          checkIsActiveUrl(pathname, PANEL_STAYS_PATH)
        }
      >
        {vamoosList}
      </MainNavigationButton>
      {canReadLibrary && (
        <MainNavigationButton to={PANEL_LIBRARY_PATH} isActive={checkIsActiveUrl(pathname, PANEL_LIBRARY_PATH)}>
          {library}
        </MainNavigationButton>
      )}
      {(canReadNotifications || canReadPois || canReadInspiration) && (
        <MainNavigationButton
          to={getSharedUrl()}
          isActive={
            checkIsActiveUrl(pathname, PANEL_NOTIFICATIONS_PATH) ||
            checkIsActiveUrl(pathname, PANEL_INSPIRATIONS_PATH) ||
            checkIsActiveUrl(pathname, PANEL_POIS_PATH)
          }
        >
          {shared}
        </MainNavigationButton>
      )}
      <MainNavigationButton to={settingsPageUrl} isActive={checkIsActiveUrl(pathname, PANEL_SETTINGS_ROOT)}>
        {settings}
      </MainNavigationButton>
      <MainNavigationButton to={PANEL_HELP_USER_GUIDE} isActive={checkIsActiveUrl(pathname, PANEL_HELP_ROOT)}>
        {help}
      </MainNavigationButton>
    </>
  ) : null;
};

export { MainNavigation };
