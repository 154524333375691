// @ts-check

import { isNumber } from "contracts/types";

/**
 * @param {VamoosImage} image - The image object.
 * @param {number|string} iconId - icon id
 * @returns {ImageRequestDto} ImageRequestDto object
 */
export function ImageRequestDto(image, iconId = null) {
  /**
   * @type {ImageRequestDto}
   */
  const dto = {};

  dto.name = image.name;

  // snake case is used for backward compatibility

  // @ts-ignore
  if (isNumber(iconId || image.iconId || image.icon_id)) {
    // @ts-ignore
    dto.icon_id = iconId || image.iconId || image.icon_id;
  } else {
    dto.icon_id = 0; // If icon id is string then it's new icon
  }

  // @ts-ignore
  if (image.libraryNodeId || image.library_node_id) {
    // @ts-ignore
    dto.library_node_id = image.libraryNodeId || image.library_node_id;
    return dto;
  }

  // @ts-ignore
  if (image.fileId || image.file_id) {
    // @ts-ignore
    dto.file_id = image.fileId || image.file_id;
    return dto;
  }

  // @ts-ignore
  if (image.fileUrl || image.file_url) {
    // @ts-ignore
    dto.file_url = image.fileUrl || image.file_url;
    return dto;
  }

  // @ts-ignore
  if (image.webUrl || image.web_url) {
    // @ts-ignore
    dto.web_url = image.webUrl || image.web_url;
    return dto;
  }

  // If there is no previous properties, then probably image object has "image" property containing file url
  if (image.image) {
    dto.file_url = image.image;
    return dto;
  }

  return dto;
}
