import React, { forwardRef, useEffect, useRef } from "react";
import { Controller } from "react-hook-form";
import { InputStyled, ErrorText } from "./style";

const Input = forwardRef(
  ({
    control,
    name,
    rules,
    label,
    type = "text",
    style,
    onBlur,
    autoFocus,
    disabled,
    hint,
    modifyValue,
    modifyError,
    modifiedOnChange,
    currentLanguage,
  }) => {
    const ref = useRef();
    const handleBlur = () => onBlur && onBlur();

    const getErrorMessage = message => {
      if (typeof message === "object") {
        return message[currentLanguage];
      }
      return message;
    };

    const isError = error => {
      if (typeof error !== "object") {
        if (error) return true;
      }

      if (typeof error === "object") {
        if (error.message) return true;
      }

      return false;
    };

    return (
      <Controller
        control={control}
        name={name}
        rules={rules}
        defaultValue={null}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <div style={style}>
            <InputStyled
              label={label ? label + (rules?.required ? "*" : "") : null}
              variant="outlined"
              value={(modifyValue ? modifyValue(value) : value) || ""}
              onChange={value => {
                onChange(modifiedOnChange ? modifiedOnChange(value) : value);
              }}
              onBlur={handleBlur}
              error={modifyError ? modifyError(error) : error}
              type={type}
              autoFocus={autoFocus}
              disabled={disabled}
            />
            {!error && hint && <ErrorText style={{ color: "#bbb" }}>{hint}</ErrorText>}
            {isError(error) && <ErrorText>{getErrorMessage(error.message) || "This field is required"}</ErrorText>}
          </div>
        )}
      />
    );
  },
);

export default Input;
