import React from "react";
import { StickyHeaderFormSection } from "components/ui/Forms";
import { LocationsTabList } from "feature/panel/Trips/_shared/Locations/LocationsTab/LocationsTabList";
import { LocationsTabRightMenu } from "feature/panel/Trips/_shared/Locations/LocationsTab/LocationsTabRightMenu";

const LocationsTab = () => {
  return (
    <StickyHeaderFormSection title="List of locations" headerActions={LocationsTabRightMenu}>
      <LocationsTabList />
    </StickyHeaderFormSection>
  );
};

export { LocationsTab };
