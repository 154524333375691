import uuidv4 from "uuid";

import { firstLetterUpperCase } from "utils/string";

export const WEEKDAY_VALUES_ARRAY = [
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "sunday",
];

export const INIT_WEEKDAYS_VALUES = {
  monday: false,
  tuesday: false,
  wednesday: false,
  thursday: false,
  friday: false,
  saturday: false,
  sunday: false,
};

export const multipleDaysOptions = [
  { name: "all week", values: WEEKDAY_VALUES_ARRAY },
  { name: "only workdays", values: WEEKDAY_VALUES_ARRAY.slice(0, -2) },
  { name: "only weekend", values: WEEKDAY_VALUES_ARRAY.slice(-2) },
];

export function areStringArraysEqual(arr1, arr2) {
  const sortedArr1 = [...arr1].sort((a, b) => a.localeCompare(b));
  const sortedArr2 = [...arr2].sort((a, b) => a.localeCompare(b));

  return sortedArr1.join("") === sortedArr2.join("");
}

export function initDirectory(type, is_list, parentId = null) {
  const allDirectoriesCommonFields = {
    type,
    name: "",
    content: "",
    is_list,
    background: {},
    video: {},
    actions: [],
    children: [],
    tag: uuidv4()
  };
  if (type === "directory")
    return {
      ...allDirectoriesCommonFields,
      parent_id: parentId,
    };
  if (type === "daily")
    return {
      ...allDirectoriesCommonFields,
      weekdays: INIT_WEEKDAYS_VALUES,
    };
  if (type === "voucher")
    return {
      ...allDirectoriesCommonFields,
      is_enabled: true,
    };
  return allDirectoriesCommonFields;
}

export const getWeekdaysObjectFromActiveList = (selectedList, activeWeekdaysArray) => {
  const multipleDaysOptionSelected = multipleDaysOptions.find(option => selectedList.includes(option.name));
  const weekdaysList = multipleDaysOptionSelected ? multipleDaysOptionSelected.values : selectedList;
  const isArraySameAsBefore = areStringArraysEqual(weekdaysList, activeWeekdaysArray);

  return isArraySameAsBefore
    ? INIT_WEEKDAYS_VALUES
    : WEEKDAY_VALUES_ARRAY.reduce((acc, weekday) => ({ ...acc, [weekday]: weekdaysList.includes(weekday) }), {});
};

export const getDisplayableDaysText = selectedWeekdays => {
  if (!Array.isArray(selectedWeekdays)) return "";
  const periodsValues = Object.values(multipleDaysOptions);
  const fullPeriodSelected = periodsValues.find(
    period => period.values.length === selectedWeekdays.length && period.values.every(value => selectedWeekdays.includes(value)),
  );
  if (fullPeriodSelected) return fullPeriodSelected.name;

  return selectedWeekdays.map(weekday => firstLetterUpperCase(weekday)).join(", ");
};