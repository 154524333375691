import React, { useEffect, useState } from "react";
import styled from "styled-components";
import AddIcon from "@material-ui/icons/Add";
import { useDispatch, useSelector, shallowEqual } from "react-redux";

import { ActionButton } from "components/ui/Buttons";
import { Chip } from "components/ui/Chips";
import { GLOBAL_CONTENT, LANGUAGES_SETTINGS, SETTINGS_LANGUAGES } from "constants/content";

import { ConfirmationModal } from "components/ui/Modals/ConfirmationModal";
import { Autocomplete } from "components/ui/Forms/Autocomplete";

import { changeCompanyAdditionalLanguages, changeCompanyLanguage } from "store/operator/actions";

import { usePopupHandlers } from "hooks/usePopupHandlers";
import { useListOfLanguages } from "hooks/useListOfLanguages";
import { usePermissionsService } from "hooks/usePermissionsService";

import { PERMISSIONS } from "constants/permissions";

import { SettingsWrapper } from "../../styledComponents";
import { InformationField } from "../../_shared/InformationField";

/**
 * @todo: This requires refactoring - to generic names
 */
import { ContentWrapper, Header, Paper } from "../../_shared/styledComponents";

const LanguageChip = styled(Chip)`
  margin: 4px 8px 4px 0;

  && {
    border-color: ${({ theme }) => theme.colors.brand};
  }
`;

const DialogContentContainer = styled.div`
  min-width: calc(600px - 24px - 24px);
`;

const GeneralLanguages = () => {
  const dispatch = useDispatch();
  const languages = useListOfLanguages();
  const permissionsService = usePermissionsService();

  const changeModal = usePopupHandlers();
  const editModal = usePopupHandlers();

  const defaultLanguageCode = useSelector(({ operator }) => operator.currentOperator.defaultLanguageCode);
  const additionalLanguageCodes = useSelector(({ operator }) => operator.currentOperator.additionalLanguageCodes || [], shallowEqual);
  const canEdit = permissionsService.can(PERMISSIONS.actions.update, PERMISSIONS.sections.operator, PERMISSIONS.resources.operators);

  const [selectedLanguages, setSelectedLanguages] = useState([]);
  const [selectedDefaultLanguage, setSelectedDefaultLanguage] = useState(null);

  const [selectedLanguagesInModal, setSelectedLanguagesInModal] = useState([]);

  const handleChangeDefaultLanguage = () => {
    changeModal.open();
  };

  const handleAddLanguage = () => {
    editModal.open();
  };

  const handleAcceptDefaultLanguageChange = () => {
    dispatch(changeCompanyLanguage(selectedDefaultLanguage.code));
    changeModal.close();
  };

  const handleAcceptAddLanguage = () => {
    dispatch(changeCompanyAdditionalLanguages([...selectedLanguages, ...selectedLanguagesInModal].map(lang => lang.code)));
    setSelectedLanguagesInModal([]);
    editModal.close();
  };

  const handleCancelAddLanguage = () => {
    setSelectedLanguagesInModal([]);
    editModal.close();
  };

  const handleDeleteLanguage = langToDelete => {
    dispatch(
      changeCompanyAdditionalLanguages(selectedLanguages.filter(selected => selected.code !== langToDelete.code).map(lang => lang.code)),
    );
  };

  useEffect(() => {
    setSelectedDefaultLanguage(languages.find(lang => defaultLanguageCode === lang.code));
    setSelectedLanguages(additionalLanguageCodes.map(code => languages.find(lang => code === lang.code)));
  }, [defaultLanguageCode, additionalLanguageCodes.length]);

  return (
    <SettingsWrapper>
      <Paper>
        <ContentWrapper>
          <Header>{LANGUAGES_SETTINGS.languageSettings}</Header>
          <InformationField
            title={SETTINGS_LANGUAGES.defaultLanguage}
            onEditClick={handleChangeDefaultLanguage}
            content={languages.find(lang => lang.code === defaultLanguageCode)?.name}
            disabled={selectedLanguages.length > 0}
          />
          <InformationField
            title={SETTINGS_LANGUAGES.additionalLanguages}
            editSlot={
              canEdit && (
                <ActionButton onClick={handleAddLanguage} size="small">
                  <AddIcon />
                  {GLOBAL_CONTENT.add}
                </ActionButton>
              )
            }
          >
            <div>
              {selectedLanguages.map(lang => (
                <LanguageChip key={lang.code} label={lang.name} onDelete={() => handleDeleteLanguage(lang)} disabled={!canEdit} />
              ))}
            </div>
          </InformationField>
        </ContentWrapper>
      </Paper>
      <ConfirmationModal
        title={SETTINGS_LANGUAGES.changeDefaultLanguage}
        open={changeModal.isOpen}
        onCancel={changeModal.close}
        onConfirm={handleAcceptDefaultLanguageChange}
        confirmLabel={GLOBAL_CONTENT.save}
      >
        <DialogContentContainer>
          <Autocomplete
            value={selectedDefaultLanguage}
            options={languages.filter(({ code }) => defaultLanguageCode !== code && !selectedLanguages.some(lang => lang.code === code))}
            getOptionLabel={option => option.name}
            onChange={(event, value) => {
              setSelectedDefaultLanguage(value);
            }}
            filterSelectedOptions
            label={SETTINGS_LANGUAGES.selectDefaultLanguage}
          />
        </DialogContentContainer>
      </ConfirmationModal>

      <ConfirmationModal
        title={SETTINGS_LANGUAGES.addLanguages}
        open={editModal.isOpen}
        onConfirm={handleAcceptAddLanguage}
        onCancel={handleCancelAddLanguage}
        confirmLabel={GLOBAL_CONTENT.save}
      >
        <DialogContentContainer>
          <Autocomplete
            multiple
            value={selectedLanguagesInModal}
            options={languages.filter(
              lang => !selectedLanguages.some(selected => selected.code === lang.code) && lang.code !== defaultLanguageCode,
            )}
            getOptionLabel={option => option.name}
            getOptionSelected={(option, value) => option.code === value.code}
            onChange={(event, value) => {
              setSelectedLanguagesInModal(value);
            }}
            filterSelectedOptions
            label={SETTINGS_LANGUAGES.selectLanguage}
          />
        </DialogContentContainer>
      </ConfirmationModal>
    </SettingsWrapper>
  );
};

export { GeneralLanguages };
