import styled from "styled-components";
import SearchIcon from "@material-ui/icons/Search";
import theme from "config/theme";

const StyledWrapper = styled.div`
  border: 1px solid ${theme.colors.grey10};
  border-radius: ${theme.borderRadius}px;
  width: 100%;
  height: ${({ withMap, height }) => (withMap ? height : 90)}px;
  ${({ withShadow }) => (withShadow ? `box-shadow: ${theme.shadows.map}` : null)};
  transition: all 0.15s ease-in-out;
  ${({ noBottomMargin }) => (noBottomMargin ? "" : ` margin-bottom: ${theme.setSpacing(8)}px;`)};
`;

const ActionsPanel = styled.div`
  width: 100%;
  height: 88px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${theme.colors.white};
  border-radius: ${theme.borderRadius}px;
  border: 1px solid #eaeaea;
  border-bottom: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
`;

const MapWrapper = styled.div`
  width: 100%;
  height: ${({ height, disableSearchBar }) => (disableSearchBar ? height : height - 90)}px;
  position: relative;
  border-bottom-left-radius: ${theme.borderRadius}px;
  border-bottom-right-radius: ${theme.borderRadius}px;
  overflow: hidden;
  display: ${({ hidden }) => (hidden ? "none" : "block")};
`;

const SearchWrapper = styled.div`
  && {
    width: auto;
    flex-grow: 1;
    max-width: ${({ fullWidth }) => (fullWidth ? "100%" : "40%")};
    margin: 0 ${theme.setSpacing(6)}px;
  }
`;

const StyledSearchIcon = styled(SearchIcon)`
  && {
    color: ${theme.colors.grey50};
  }
`;

const ActionsWrapper = styled.div`
  margin: 0 ${({ fullWidth }) => (!fullWidth ? `${theme.setSpacing(6)}px;` : null)};
`;

const InfoWindowContent = styled.div`
  width: 100%;
  line-height: 16px;
  font-size: 14px;
`;

const InfoWindowActions = styled.div`
  min-width: ${theme.setSpacing(100)}px;
  height: ${theme.setSpacing(11)}px;
  margin-top: ${theme.setSpacing(2)}px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const AddStayActionWrapper = styled.div`
  margin: 15px 0 5px 20px;
  display: flex;
  justify-content: flex-end;
`;

const Button = styled.button`
  background: transparent;
  border: 1px solid ${({ primary }) => (primary ? theme.colors.brand : theme.colors.grey50)};
  border-radius: ${theme.setSpacing(2)}px;
  padding: 0 ${theme.setSpacing(2)}px;
  height: ${theme.setSpacing(8)}px;
  width: ${({ fullWidth }) => (fullWidth ? "100%" : "auto")};
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ primary }) => (primary ? theme.colors.brand : theme.colors.grey100)};
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.25 ease;

  :hover {
    border: 1px solid ${({ primary }) => (primary ? theme.colors.brandHover : theme.colors.grey70)};
    svg {
      color: ${({ primary }) => (primary ? theme.colors.brand : theme.colors.grey70)};
    }
  }

  svg {
    margin-right: ${theme.setSpacing(2)}px;
    color: ${({ primary }) => (primary ? theme.colors.brand : theme.colors.grey50)};
  }

  &:disabled {
    pointer-events: none;
    color: rgba(0, 0, 0, 0.26);
    border-color: rgba(0, 0, 0, 0.26);
    svg {
      color: rgba(0, 0, 0, 0.26);
    }
  }
`;

export {
  StyledWrapper,
  ActionsPanel,
  MapWrapper,
  SearchWrapper,
  ActionsWrapper,
  InfoWindowActions,
  InfoWindowContent,
  StyledSearchIcon,
  AddStayActionWrapper,
  Button,
};
