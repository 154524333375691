// @ts-check
import moment from "moment";
import uuidv4 from "uuid";

import config from "config/app";

import { NOTIFICATION_TYPES } from "constants/content";
import { Feature } from "domain/Feature";
import { Stay } from "domain/Stay";
import { StayGalleryItem } from "domain/StayGalleryItem";
import { StayGalleryItemRequestDto } from "dto/StayGalleryItemRequestDto";
import { StayRequestDto } from "dto/StayRequestDto";
import { documentPayloadConvert } from "utils/dataConverters";
import { formFilePayloadObject } from "utils/library";
import { BaseMapper } from "./BaseMapper";

class StayVariantMapper extends BaseMapper {
  /**
   * @param {import("dto/StayResponseDto").StayResponseDto} dto
   * @returns {Stay}
   */
  fromDtoToDomain(dto) {
    /** @type {Stay} */
    const domain = new Stay();

    domain.vamoosId = dto.vamoos_id;
    domain.updated_at = dto.updated_at;
    domain.id = dto.id;
    domain.language = dto.language;
    domain.isWiped = dto.is_wiped;
    domain.isActive = dto.is_active;
    domain.isListed = dto.is_listed;

    domain.referenceCode = dto.reference_code;

    domain.departureDate = dto.departure_date;
    domain.returnDate = dto.return_date;

    domain.userId = dto.operator_code;
    domain.propertyName = dto.field1;
    domain.homeDescription = dto.meta.home_screen_description;
    domain.phone = dto.meta.phone;
    domain.email = dto.meta.email;
    domain.website = dto.meta.website;
    domain.twitter = dto.meta.twitter;
    domain.facebook = dto.meta.facebook;
    domain.instagram = dto.meta.instagram;

    domain.brandingProfileId = dto.branding_profile_id || null;

    domain.checkInTime = dto.meta.check_in_time || "12:00";

    domain.requirePersonalDetails = dto.meta.require_personal_details;

    domain.address = dto.locations[0]?.location;
    domain.coordinates = {
      latitude: dto.locations[0]?.latitude,
      longitude: dto.locations[0]?.longitude,
    };

    domain.connect = {
      isEnabled: dto.is_listed,
      requestedStatus: dto.requested_listing_status,
    };

    domain.hotelInfo = {
      label: dto.meta.hotel_info_label,
      icon: dto.meta.hotel_info_icon_id,
    };

    domain.logo = dto.logo;
    domain.background = dto.background;

    domain.shortDescription = dto.meta.short_description;
    domain.longDescription = dto.meta.long_description;
    domain.numberOfRooms = dto.meta.number_of_rooms;

    domain.directories = {
      show: dto.meta.show_directory,
      items: this.flatDirectoriesTree(dto.directories.filter(dir => dir.type === "directory")).map(dir => ({
        ...dir,
        // @ts-ignore
        tag: dir.tag && dir.tag < 10000000000 ? dir.tag : uuidv4(), // temporal workaround for to high tag values
      })),
    };

    domain.vouchers = {
      show: dto.meta.show_vouchers,
      items: dto.directories
        .filter(dir => dir.type === "voucher")
        .map(dir => ({
          ...dir,
          // @ts-ignore
          tag: dir.tag && dir.tag < 10000000000 ? dir.tag : uuidv4(), // temporal workaround for to high tag values
        })),
    };

    domain.dailyActivities = {
      show: dto.meta.show_daily_activities,
      items: dto.directories
        .filter(dir => dir.type === "daily")
        .map(dir => ({
          ...dir,
          // @ts-ignore
          tag: dir.tag && dir.tag < 10000000000 ? dir.tag : uuidv4(), // temporal workaround for to high tag values
        })),
    };

    domain.features = dto.features.map(feature => Feature(feature));

    domain.notifications = {
      timed: {
        items: dto.notifications
          .filter(notification => notification.type === "timed")
          .map(notification => ({
            ...notification,
            delivery_at_days: notification.delivery_at_days || 0,
          })),
      },
      gps: {
        items: dto.notifications.filter(notification => notification.type === "gps"),
      },
    };

    domain.passcodes = dto.passcode_groups;

    domain.pois = dto.pois;

    domain.inspiration = {
      vamoosId: dto.inspiration_vamoos_id,
      settings: {
        content: dto.notifications.find(({ type }) => type === NOTIFICATION_TYPES.inspiration)?.content || "",
        period: {
          deliveryAtDays: dto.notifications.find(({ type }) => type === NOTIFICATION_TYPES.inspiration)?.delivery_at_days,
          startAt: dto.notifications.find(({ type }) => type === NOTIFICATION_TYPES.inspiration)?.start_at || "13:00",
          deliveryAtRelativeTo: dto.notifications.find(({ type }) => type === NOTIFICATION_TYPES.inspiration)?.delivery_at_relative_to,
        },
      },
    };

    domain.gallery = dto.details.map(item => StayGalleryItem(item));

    domain.actions = {
      action: {
        icon: parseInt(dto.meta.travel_documents_icon_id, 10),
        label: dto.meta.travel_documents_label,
        file: dto.documents.travel.length === 1 ? dto.documents.travel[0] : null,
      },
      bookNow: {
        icon: parseInt(dto.meta.destination_documents_icon_id, 10),
        label: dto.meta.destination_documents_label,
        file: dto.documents.destination.length === 1 ? dto.documents.destination[0] : null,
      },
    };

    domain.messaging = {
      emails: dto.meta.messaging_emails_list,
      dates: {
        before: dto.meta.messaging_email_during_days_before,
        after: dto.meta.messaging_email_during_days_after,
      },
    };

    domain.dnd = {
      show: dto.meta.show_dnd,
      email: dto.meta.dnd_contact_email,
      time: dto.meta.dnd_cut_off_time,
    };

    domain.alertEmails = {
      include: dto.meta.alert_email_admins !== false,
      emails: dto.meta.alert_emails?.length ? dto.meta.alert_emails?.join(", ") : "",
    };

    domain.variants = dto.routing.language
      ? Object.keys(dto.routing.language).map(code => {
          const masterCode = dto.routing.master.match(/[a-z]{1,3}$/)?.[0];
          const fallbackCode = dto.routing.fallback.match(/[a-z]{1,3}$/)?.[0];

          return {
            language: code,
            isMaster: masterCode === code,
            isDefault: fallbackCode === code,
            isActive: !!dto.variants?.find(variant => variant.language === code).is_active,
          };
        })
      : [];

    domain.copiedFrom = dto.meta.copied_from;

    return domain;
  }

  /**
   *
   * @param {import("domain/Stay").Stay} domain
   * @returns {Promise<import("dto/StayRequestDto").StayRequestDto>}
   */
  async fromDomainToDto(domain, copyToOtherOperator = false) {
    const dto = new StayRequestDto();

    dto.field1 = domain.propertyName || null;
    dto.vamoos_id = domain.vamoosId;
    dto.is_active = domain.isActive;
    dto.new_only = domain.newOnly;
    dto.background = formFilePayloadObject(domain.background, copyToOtherOperator);

    dto.documents = {
      travel: [
        ...(domain.actions.action.file
          ? [
              {
                ...formFilePayloadObject(domain.actions.action.file, copyToOtherOperator),
                icon_id: domain.actions.action.icon,
              },
            ]
          : []),
      ],
      destination: [
        ...(domain.actions.bookNow.file
          ? [
              {
                ...formFilePayloadObject(domain.actions.bookNow.file, copyToOtherOperator),
                icon_id: domain.actions.bookNow.icon,
              },
            ]
          : []),
      ],
    };

    dto.notifications = [
      ...(Array.isArray(domain.notifications)
        ? domain.notifications
        : [...domain.notifications.timed.items, ...domain.notifications.gps.items]),
      ...(domain.inspiration.vamoosId
        ? [
            {
              type: NOTIFICATION_TYPES.inspiration,
              is_active: true,
              inspirationVamoosId: domain.inspiration.id,
              content: domain.inspiration.settings.content,
              deliveryAtDays: domain.inspiration.settings.period.deliveryAtDays,
              startAt: domain.inspiration.settings.period.startAt,
              deliveryAtRelativeTo: domain.inspiration.settings.period.deliveryAtRelativeTo,
            },
          ]
        : []),
    ]
      .filter(notification => {
        if (notification.type === NOTIFICATION_TYPES.inspiration) {
          return domain.inspiration.vamoosId && notification.deliveryAtRelativeTo !== null;
        }
        return true;
      })
      .map(notification => {
        const notificationDto = {};

        notificationDto.content = notification.content;
        notificationDto.details = notification.details;
        notificationDto.is_active = notification.is_active;
        notificationDto.start_at = notification.start_at || notification.startAt;
        notificationDto.tag = notification.tag;
        notificationDto.type = notification.type;
        notificationDto.url = notification.url;

        if (!copyToOtherOperator) {
          notificationDto.template_id = notification.template_id;
        }

        if (notification.type === "timed") {
          notificationDto.delivery_at_days = notification.delivery_at_days || notification.deliveryAtDays || 0;
          notificationDto.delivery_at_relative_to = notification.delivery_at_relative_to || notification.deliveryAtRelativeTo;
        }

        if (notification.type === "gps") {
          notificationDto.end_at = notification.end_at;

          if (notification.latitude) {
            notificationDto.latitude = notification.latitude;
          }

          if (notification.longitude) {
            notificationDto.longitude = notification.longitude;
          }
        }

        if (notification.type === "inspiration") {
          notificationDto.delivery_at_days = notification.delivery_at_days || notification.deliveryAtDays || 0;
          notificationDto.delivery_at_relative_to = notification.delivery_at_relative_to || notification.deliveryAtRelativeTo;

          if (!copyToOtherOperator) {
            notificationDto.inspiration_vamoos_id = domain.inspiration.id;
          }
        }

        return notificationDto;
      });

    if (domain.inspiration.vamoosId && !copyToOtherOperator) {
      dto.inspiration_vamoos_id = domain.inspiration.vamoosId;
    }

    dto.directories = [
      ...domain.directories.items
        .filter(dir => !dir.parent_id)
        .map(dir => this.createDirectoriesTree(dir, domain.directories.items, copyToOtherOperator)),
      ...domain.vouchers.items.map(item => this.mapToDirectoryDto(item, copyToOtherOperator)),
      ...domain.dailyActivities.items.map(item => this.mapToDirectoryDto(item, copyToOtherOperator)),
    ];

    dto.details =
      domain.gallery.length > 0
        ? [...domain.gallery.map(galleryItem => StayGalleryItemRequestDto(galleryItem, copyToOtherOperator))]
        : undefined;

    dto.meta.phone = domain.phone || undefined;
    dto.meta.email = domain.email || undefined;
    dto.meta.website = domain.website || undefined;
    dto.meta.twitter = domain.twitter || undefined;
    dto.meta.facebook = domain.facebook || undefined;
    dto.meta.instagram = domain.instagram || undefined;
    dto.meta.short_description = domain.shortDescription || undefined;
    dto.meta.long_description = domain.longDescription || undefined;
    dto.meta.home_screen_description = domain.homeDescription || undefined;
    dto.meta.hotel_info_label = domain.hotelInfo.label || undefined;
    dto.meta.hotel_info_icon_id = domain.hotelInfo.icon || undefined;

    dto.meta.travel_documents_label = domain.actions.action.label || undefined;
    dto.meta.destination_documents_label = domain.actions.bookNow.label || undefined;

    dto.meta.show_directory = domain.directories.show;
    dto.meta.show_vouchers = domain.vouchers.show;
    dto.meta.show_daily_activities = domain.dailyActivities.show;

    dto.departure_date = moment(domain.departureDate).format(config.apiDateFormat);
    dto.return_date = moment(domain.returnDate).format(config.apiDateFormat);

    return dto;
  }

  /**
   * @protected
   * @param {any[]} dirs
   * @returns {any[]}
   */
  flatDirectoriesTree(dirs) {
    return [...dirs, ...dirs.map(dir => this.flatDirectoriesTree(dir.children))].flat();
  }

  /**
   * @protected
   * @param {any} directory
   * @returns {any}
   */
  // eslint-disable-next-line class-methods-use-this
  mapToDirectoryDto({ id, updated_at, created_at, itinerary_id, parent_id, tag, ...item }, copyToOtherOperator) {
    return {
      ...item,
      tag: typeof tag === "number" ? tag : null,
      background: item.background && item.background.file_name ? formFilePayloadObject(item.background, copyToOtherOperator) : undefined,
      video: item.video && item.video.file_name ? formFilePayloadObject(item.video, copyToOtherOperator, true) : undefined,
      actions: item.actions.map(action => ({ ...documentPayloadConvert(action, copyToOtherOperator), icon_id: action.icon_id })),
    };
  }

  /**
   * @protected
   * @param {any} directory
   * @param {any[]} directoriesList
   * @returns {any}
   */
  createDirectoriesTree({ id, updated_at, created_at, itinerary_id, parent_id, ...directory }, directoriesList, copyToOtherOperator) {
    return {
      ...this.mapToDirectoryDto(
        {
          ...directory,
          children: directoriesList.filter(dir => dir.parent_id === id).map(child => this.createDirectoriesTree(child, directoriesList)),
        },
        copyToOtherOperator,
      ),
    };
  }
}

export { StayVariantMapper };
