import React, { memo } from "react";
import PropTypes from "prop-types";
import { ImageOutlined } from "@material-ui/icons";
import { useSelector } from "react-redux";

import { FormSection, Input } from "components/ui/Forms";
import { ImagePreview, ImagePlaceholder } from "components/ui/Images";
import { AttachFilesButton } from "components/ui/Buttons";
import { DEFAULT_LIBRARY_TABS } from "components/ui/LibraryPopup/LibraryPopup";
import { ChipWithRedirect } from "components/ui/Chips/ChipWithRedirect";

import { IMAGES_MIME_TYPES } from "constants/defaults";
import { usePermissionsService } from "hooks/usePermissionsService";
import { ADMIN_ICONS_CONTENT } from "constants/content";
import { PERMISSIONS } from "constants/permissions";

import { DefaultIconWrapper } from "./DefaultIconWrapper";
import { DefaultIconNameInputWrapper } from "./DefaultIconNameInputWrapper";
import { useManageIconHandlers } from "./useManageIconHandlers";

const DefaultIconSection = memo(({ errors, onChange }) => {
  const permissionsService = usePermissionsService();
  const listOfIcons = useSelector(state => state.icons.listOfIcons);

  const { library, files } = DEFAULT_LIBRARY_TABS;

  const { handleChangeName, handleChangeAttachment, handleRemoveAttachment, name, attachment, icon, preview } = useManageIconHandlers(
    listOfIcons.find(iconObj => iconObj.isDefault),
    onChange,
  );

  const canUserEditDefaultIcon = permissionsService.can(
    PERMISSIONS.actions.update,
    PERMISSIONS.sections.admin,
    PERMISSIONS.resources.icons,
  );

  const renderAttachmentDeleter = () => (
    <ChipWithRedirect
      onDelete={handleRemoveAttachment}
      item={attachment}
      maxChar={15}
      icon={<ImageOutlined />}
      editable={canUserEditDefaultIcon}
    />
  );

  const renderAttachmentUploader = () => (
    <AttachFilesButton
      label="attach"
      size="small"
      onSelect={handleChangeAttachment}
      allowedFileTypes={IMAGES_MIME_TYPES}
      tabs={[library, files]}
      error={errors[icon?.id]?.image}
      disabled={canUserEditDefaultIcon}
    />
  );

  return (
    <FormSection title={ADMIN_ICONS_CONTENT.defaultOfPoiIcon}>
      <DefaultIconWrapper error={!!errors[icon?.id]}>
        {preview ? (
          <ImagePreview image={preview} bgType="contain" width="40px" height="40px" />
        ) : (
          <ImagePlaceholder width="40px" height="40px" />
        )}
        <DefaultIconNameInputWrapper>
          <Input
            error={errors[icon?.id]?.name}
            helperText={errors[icon?.id]?.name}
            label={ADMIN_ICONS_CONTENT.title}
            name="default-icon-name"
            size="small"
            value={name}
            onChange={handleChangeName}
            disabled={!canUserEditDefaultIcon}
          />
        </DefaultIconNameInputWrapper>
        {attachment ? renderAttachmentDeleter() : renderAttachmentUploader()}
      </DefaultIconWrapper>
    </FormSection>
  );
});

DefaultIconSection.propTypes = {
  errors: PropTypes.shape({}).isRequired,
  onChange: PropTypes.func.isRequired,
};

export { DefaultIconSection };
