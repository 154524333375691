import { isObjectEmpty } from "utils/object";

import { coordinatesValidator, validateDocuments, validateLocations } from "utils/validators/sharedValidators";

import { returnIfKeyExist } from "utils/validators/helpers";
import { itineraryValidator } from "utils/validators/itineraryValidator";

import { ERRORS, INSPIRATIONS } from "constants/content";

export const validateTravellers = travellers => {
  const result = {};
  if (Array.isArray(travellers) && travellers.length > 0) {
    travellers.forEach((traveller, index) => {
      if (!traveller.name) result[index] = { ...result[index], name: ERRORS.isRequired("Name") };
    });
  }
  return result;
};

export const tripsValidator = payload => {
  const { field1, field3, documents, departure_date, return_date, start_time, travellers, locations, notifications } = payload;
  const { travel, destination } = documents || {};
  let result = {};

  result = itineraryValidator(payload);

  if (!field1) result.field1 = ERRORS.isRequired("Destination / Event title");
  if (!field3) result.field3 = ERRORS.isRequired("First name / Location");
  if (!departure_date) result.departure_date = ERRORS.isRequired("Start date");
  if (!return_date) result.return_date = ERRORS.isRequired("End date");
  if (!start_time) result.start_time = ERRORS.isRequired("Start time");

  if (destination) {
    const documentResult = validateDocuments(destination);

    if (!isObjectEmpty(documentResult))
      result = { ...result, documents: { ...returnIfKeyExist(result, "documents"), destination: documentResult } };
  }

  if (locations) {
    const locationsResult = validateLocations(locations, false);
    if (!isObjectEmpty(locationsResult)) result = { ...result, locations: locationsResult };
  }

  if (travel) {
    const travelResult = validateDocuments(travel);
    if (!isObjectEmpty(travelResult)) result = { ...result, documents: { ...returnIfKeyExist(result, "documents"), travel: travelResult } };
  }

  if (travellers) {
    const travellersResult = validateTravellers(travellers);
    if (!isObjectEmpty(travellersResult)) result = { ...result, travellers: travellersResult };
  }

  if (notifications) {
    const gpsNotifications = {};

    notifications.forEach(notification => {
      if (notification.type === "inspiration") {
        if (notification.content === "" || !notification?.content) {
          result = {
            ...result,
            inspirations: {
              content: ERRORS.cantBeBlank(INSPIRATIONS.messageContent.replace("*", "")),
            },
          };
        }
      }
      if (notification.type === "gps") {
        if (
          notification.is_active &&
          !(
            notification.location_id !== undefined ||
            notification.location_internal_id !== undefined ||
            (notification.latitude !== undefined && notification.longitude !== undefined)
          )
        ) {
          gpsNotifications[notification.template_id] = {
            coordinates: ERRORS.coordinatesOrLocationRequired,
          };
        }

        if (notification?.latitude || notification?.longitude) {
          const coordinatesErrors = coordinatesValidator(notification);
          if (coordinatesErrors?.latitude || coordinatesErrors?.longitude) {
            gpsNotifications[notification.template_id] = {
              coordinates: coordinatesErrors.latitude || coordinatesValidator?.longitude,
            };
          }
        }
      }
    });

    if (!isObjectEmpty(gpsNotifications)) {
      result.gpsNotifications = gpsNotifications;
    }
  }

  return result;
};
