import React from "react";
import PropTypes from "prop-types";

import { SortableList } from "components/ui/Lists";
import { EntryDocumentsListItem } from "feature/panel/Trips/_shared/Storyboard/Entries/EntryForm/EntryDocumentsListItem";

import { setNewOrder } from "utils/sortable";
import { deepClone } from "utils/array";

const EntryDocumentsList = ({ list, onChange, errors, dayId, defaultIcon, disabled }) => {
  const handleChangeOrder = ({ newIndex, oldIndex }) => {
    const newList = setNewOrder(newIndex, oldIndex, list);
    onChange(newList);
  };

  const handleRemoveDocument = id => {
    const newList = list.filter(el => el.id !== id);
    onChange(newList);
  };

  const handleChangeDocumentData = (id, item) => {
    const newList = deepClone(list);
    onChange(newList.map(listItem => (listItem.id === id ? item : listItem)));
  };

  return (
    list && (
      <SortableList lockAxis="y" onSortEnd={handleChangeOrder} lockToContainerEdges useDragHandle>
        {list.map((element, index) => (
          <EntryDocumentsListItem
            key={element.id}
            dayId={dayId}
            file={element}
            index={index}
            defaultIcon={defaultIcon}
            onRemove={handleRemoveDocument}
            onChange={handleChangeDocumentData}
            errors={errors.documents || {}}
            disabled={disabled}
          />
        ))}
      </SortableList>
    )
  );
};

EntryDocumentsList.defaultProps = {
  defaultIcon: null,
};

EntryDocumentsList.propTypes = {
  list: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onChange: PropTypes.func.isRequired,
  errors: PropTypes.shape().isRequired,
  dayId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  defaultIcon: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  disabled: PropTypes.bool.isRequired,
};

export { EntryDocumentsList };
