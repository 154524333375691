import React, { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Grid from "@material-ui/core/Grid";

import { ActionListElement } from "feature/panel/Stays/_shared/Actions/ActionListElement";

import { STAY_WIZARD_CONTENT } from "constants/content";

import { getIconsListStart } from "store/operator/actions";

import {
  setStayWizardActionFile,
  setStayWizardActionIcon,
  setStayWizardActionTitle,
  setStayWizardBookNowFile,
  setStayWizardBookNowIcon,
  setStayWizardBookNowTitle,
  setStayWizardDefaultActionIcon,
  setStayWizardDefaultBookNowIcon,
} from "store/stayWizard/actions";
import { LanguageContext } from "components/_shared/LanguageSelector/LanguageContext";

const Actions = () => {
  const dispatch = useDispatch();

  const { currentLanguage } = useContext(LanguageContext);

  const currentOperator = useSelector(state => state.operator.currentOperator);

  const isStayDefaultLanguage = useSelector(({ stayWizard }) =>
    stayWizard.defaultVariant.language ? stayWizard.defaultVariant.language === currentLanguage.code : true,
  );

  const actionLabel = useSelector(({ stayWizard }) => stayWizard.form.actions.action.label);
  const actionFile = useSelector(({ stayWizard }) => stayWizard.form.actions.action.file);
  const actionIcon = useSelector(({ stayWizard }) =>
    isStayDefaultLanguage ? stayWizard.form.actions.action.icon : stayWizard.defaultVariant.actions.action.icon,
  );

  const bookNowLabel = useSelector(({ stayWizard }) => stayWizard.form.actions.bookNow.label);
  const bookNowFile = useSelector(({ stayWizard }) => stayWizard.form.actions.bookNow.file);
  const bookNowIcon = useSelector(({ stayWizard }) =>
    isStayDefaultLanguage ? stayWizard.form.actions.bookNow.icon : stayWizard.defaultVariant.actions.bookNow.icon,
  );

  const init = () => {
    dispatch(getIconsListStart());
    if (!actionIcon) {
      const actionDefaultLabel = currentOperator.labels?.find(label => label.screen === "actionButton");

      if (actionDefaultLabel !== undefined) {
        const { icon_id: defaultActionIcon } = actionDefaultLabel;
        dispatch(setStayWizardDefaultActionIcon(defaultActionIcon));
      }
    }

    if (!bookNowIcon) {
      const bookNowDefaultLabel = currentOperator.labels?.find(label => label.screen === "bookNow");

      if (bookNowDefaultLabel !== undefined) {
        const { icon_id: defaultBookNowIcon } = bookNowDefaultLabel;
        dispatch(setStayWizardDefaultBookNowIcon(defaultBookNowIcon));
      }
    }
  };

  const handleActionTitleChange = ({ target }) => {
    dispatch(setStayWizardActionTitle({ label: target.value, language: currentLanguage }));
  };

  const handleBookNowTitleChange = ({ target }) => {
    dispatch(setStayWizardBookNowTitle({ label: target.value, language: currentLanguage }));
  };

  const handleActionFileChange = files => {
    if (files !== null) {
      const [newFile] = files;
      dispatch(setStayWizardActionFile({ file: newFile, language: currentLanguage }));
    } else {
      dispatch(setStayWizardActionFile({ file: null, language: currentLanguage }));
    }
  };

  const handleBookNowFileChange = files => {
    if (files !== null) {
      const [newFile] = files;
      dispatch(setStayWizardBookNowFile({ file: newFile, language: currentLanguage }));
    } else {
      dispatch(setStayWizardBookNowFile({ file: null, language: currentLanguage }));
    }
  };

  const handleActionIconChange = newIconId => {
    dispatch(setStayWizardActionIcon(newIconId));
  };

  const handleBookNowIconChange = newIconId => {
    dispatch(setStayWizardBookNowIcon(newIconId));
  };

  useEffect(init, [currentOperator, isStayDefaultLanguage, bookNowIcon, actionIcon]);

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} md={10}>
        <ActionListElement
          onChange={handleActionTitleChange}
          onChangeIcon={handleActionIconChange}
          onUploadFile={files => handleActionFileChange(files)}
          onDeleteFile={() => handleActionFileChange(null)}
          label={STAY_WIZARD_CONTENT.actions.action}
          context="action"
          title={actionLabel}
          file={actionFile}
          iconId={actionIcon}
        />
        <ActionListElement
          onChange={handleBookNowTitleChange}
          onChangeIcon={handleBookNowIconChange}
          onUploadFile={files => handleBookNowFileChange(files)}
          onDeleteFile={() => handleBookNowFileChange(null)}
          label={STAY_WIZARD_CONTENT.actions.bookNow}
          context="bookNow"
          title={bookNowLabel}
          file={bookNowFile}
          iconId={bookNowIcon}
        />
      </Grid>
    </Grid>
  );
};

export { Actions };
