import { reducer, setState, globalReducerActions } from "utils/store";

import * as actionTypes from "./actionTypes";

const adminConnectInitialState = {
  errors: {},
  actionType: null,
  inProgress: false,
  finished: false,
  staysCount: 0,
  stays: [],
  searchMode: false,
};

const ReducerActions = globalReducerActions("adminFeatureStays", adminConnectInitialState, {
  [actionTypes.GET_CONNECT_STAYS_START](state) {
    return setState(state, {
      actionType: actionTypes.GET_CONNECT_STAYS_START,
      inProgress: true,
    });
  },
  [actionTypes.GET_CONNECT_STAYS_SUCCESS](state, payload) {
    return setState(state, {
      stays: payload.listOfStays,
      staysCount: payload.totalMatches,
      inProgress: false,
      finished: true,
      searchMode: payload.searchMode,
    });
  },
  [actionTypes.GET_CONNECT_STAYS_FAIL](state, payload) {
    return setState(state, {
      errors: payload.errors,
      staysCount: payload.totalMatches,
      inProgress: false,
      finished: false,
    });
  },
  [actionTypes.TOGGLE_CONNECT_STAY_START](state, payload) {
    return setState(state, {
      actionType: actionTypes.TOGGLE_CONNECT_STAY_START,
      inProgress: true,
      finished: false,
      errors: {},
    });
  },
  [actionTypes.TOGGLE_CONNECT_STAY_SUCCESS](state, payload) {
    const { id, isListed } = payload;
    const stays = state.stays.map(stay => (stay.id === id ? { ...stay, isListed } : stay));

    return setState(state, {
      actionType: actionTypes.TOGGLE_CONNECT_STAY_SUCCESS,
      inProgress: false,
      finished: false,
      errors: {},
      stays,
    });
  },
  [actionTypes.TOGGLE_CONNECT_STAY_FAIL](state, payload) {
    return setState(state, {
      inProgress: false,
      finished: false,
      errors: payload.errors,
    });
  },
});

const adminConnectReducer = (state = adminConnectInitialState, action) => reducer(state, action, ReducerActions);

export { adminConnectReducer };
