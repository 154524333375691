import React from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import { LeadText } from "components/ui/Typography/Typography";
import { IconField } from "feature/panel/Pois/IconManager/IconField";

const IconListSection = ({ list, onChange }) => (
  <Grid container justifyContent="center">
    <Grid item xs={12} md={10}>
      <LeadText>List of Icons</LeadText>
      {list
        .filter(icon => !icon.is_default)
        .map(item => (
          <IconField key={item.id} icon={item} onChange={onChange} />
        ))}
    </Grid>
  </Grid>
);

IconListSection.propTypes = {
  list: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onChange: PropTypes.func.isRequired,
};

export { IconListSection };
