import React from "react";
import PropTypes from "prop-types";
import { SortableList } from "components/ui/Lists/SortableList";
import { ColumnSelectorContext } from "components/_shared/ColumnSelector/ColumnSelectorContext";
import { ListOfColumnsItem } from "./ListOfColumnsItem";

const ListOfColumns = ({ columns }) => {
  const { handleChangeOrder } = React.useContext(ColumnSelectorContext);
  return (
    <SortableList lockAxis="y" onSortEnd={handleChangeOrder} lockToContainerEdges>
      {columns.all.map((column, index) => (
        <ListOfColumnsItem key={column.key} column={column} index={index} />
      ))}
    </SortableList>
  );
};

ListOfColumns.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export { ListOfColumns };
