import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { PickerButton, SizePicker } from "feature/panel/Stays/_shared/Publisher/styledComponents";
import { STAY_WIZARD_CONTENT } from "constants/content";

const ActionsWrapper = styled.div`
  float: right;
  background-color: ${({ theme }) => theme.colors.withOpacity(theme.colors.grey10, 0.3)};
  padding: 0 ${({ theme }) => theme.setSpacing(6)}px;
  height: ${({ theme }) => theme.setSpacing(18)}px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  max-width: 235px;
`;

const DndRequestsTableAction = ({ item, onAccept, canEdit }) => {
  const { id, approved } = item;

  return (
    <ActionsWrapper>
      <SizePicker disabled={approved || !canEdit} disableRipple>
        <PickerButton size="small" selected={!approved}>
          {STAY_WIZARD_CONTENT.dnd.waiting}
        </PickerButton>
        <PickerButton size="small" selected={approved} onClick={() => onAccept(id)}>
          {STAY_WIZARD_CONTENT.dnd.accepted}
        </PickerButton>
      </SizePicker>
    </ActionsWrapper>
  );
};

DndRequestsTableAction.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number.isRequired,
    approved: PropTypes.bool.isRequired,
  }).isRequired,
  onAccept: PropTypes.func.isRequired,
  canEdit: PropTypes.bool.isRequired,
};

export { DndRequestsTableAction, ActionsWrapper };
