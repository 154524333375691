import React, { useContext, useEffect, useRef, useState } from "react";
import { Content } from "components/ui/Content/Content";
import { Input } from "components/ui/Forms";
import { SignUpContext } from "feature/views/SignUp/SignUpContext";
import { debounce } from "lodash";
import { DEFAULT_DEBOUNCE_DELAY } from "constants/defaults";

import { SIGN_UP } from "constants/content";

const SignUpFormStep3 = () => {
  const { operator, handleChange, canGoNext, validationService } = useContext(SignUpContext);

  const {
    name,
    code,
    meta: { 
      company_type: companyType,
      contact_person: contactPerson, 
      telephone
    },
  } = operator;

  const [ errors, setErrors ] = useState({});

  const validate = async (form, step) => {
    canGoNext(false);

    const validationErrors = await validationService.validate(form, step);

    if(form.name && form.code && form.contactPerson && form.telephone && Object.keys(validationErrors).length === 0) {
      canGoNext(true)
    } else {
      canGoNext(false);
    }

    setErrors(validationErrors);
  }

  const debouncedValidate = useRef(debounce((form, step) => validate(form, step), DEFAULT_DEBOUNCE_DELAY));

  useEffect(() => {
    debouncedValidate.current({
      name,
      code,
      contactPerson,
      telephone      
    }, 3);
  }, [name, code, contactPerson, telephone])

  const companyCodeText = `${SIGN_UP.companyCodeBaseText}${companyType === "hotel" ? SIGN_UP.hotelCodeHelperText : ""}`;

  return (
    <>
      <Content>
        <Input
          label="Company name"
          size="large"
          name="name"
          value={name}
          helperText={errors.name || ""}
          isRequired
          onChange={handleChange}
          error={!!errors.name}
          autoComplete="off"
        />
      </Content>
      <Content margin={4}>
        <Input
          value={code}
          label="Identifier"
          size="large"
          name="code"
          isRequired
          onChange={handleChange}
          helperText={errors.code || companyCodeText}
          error={!!errors.code}
          autoComplete="off"
        />
      </Content>
      <Content>
        <Input
          label="Contact person"
          size="large"
          name="contact_person"
          isRequired
          value={contactPerson}
          onChange={handleChange}
          error={errors.contactPerson}
          autoComplete="off"
        />
      </Content>
      <Content>
        <Input
          label="Contact phone number"
          size="large"
          name="telephone"
          isRequired
          value={telephone}
          onChange={handleChange}
          error={errors.telephone}
          helperText={errors.telephone}
          autoComplete="off"
        />
      </Content>
    </>
  );
};

export { SignUpFormStep3 };
