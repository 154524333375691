import React, { useContext } from "react";
import styled from "styled-components";
import AddIcon from "@material-ui/icons/Add";
import PropTypes from "prop-types";

import { BodyText } from "components/ui/Typography/Typography";
import { SecondaryButton } from "components/ui/Buttons";
import { SortableList } from "components/ui/Lists";
import { ManageInspirationContext } from "feature/panel/Inspirations/_shared/ManageInspirationContext";
import { setNewOrder } from "utils/sortable";
import { createSectionInitValues } from "utils/createInitValues";
import { SortableListFormElement } from "feature/panel/_shared/Sortable/SortableListFormElement";
import { SortableBarElement } from "feature/panel/_shared/Sortable/SortableBarElement";
import useActiveItemId from "hooks/useActiveItemId";

import { ImagePlaceholder, ImagePreview } from "components/ui/Images";

import { getFilePreviewUrl } from "utils/library";
import { CREATE_CONTENT_LABELS, INSPIRATIONS_LABEL } from "constants/content";

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  margin-top: ${({ theme }) => theme.setSpacing(10)}px;
  margin-bottom: ${({ theme }) => theme.setSpacing(8)}px;
`;

const CONTENT_FIELD_NAMES = {
  data: "data",
};

const InspirationGallery = ({ canEdit }) => {
  const [expandedSectionId, setExpandedSectionId] = useActiveItemId();
  const {
    setValueFor,
    data: { rows: sections },
  } = useContext(ManageInspirationContext);

  const handleSectionAdd = () => {
    const newSection = createSectionInitValues();
    const value = { rows: [...sections, newSection] };
    setValueFor(CONTENT_FIELD_NAMES.data, value);
  };

  const handleUpdateTitle = (id, { target }) => {
    const newList = sections.map(section => (section.id === id ? { ...section, title: target.value } : section));
    setValueFor(CONTENT_FIELD_NAMES.data, { rows: newList });
  };

  const handleDeleteSection = id => {
    const newList = sections.filter(section => section.id !== id);
    setValueFor(CONTENT_FIELD_NAMES.data, { rows: newList });
  };

  const handleGalleryChange = (id, newGallery) => {
    const newList = sections.map(section => (section.id === id ? newGallery : section));
    setValueFor(CONTENT_FIELD_NAMES.data, { rows: newList });
  };

  const handleChangeOrder = ({ newIndex, oldIndex }) => {
    const newList = setNewOrder(newIndex, oldIndex, sections);
    setValueFor("data", { rows: newList });
  };

  const galleriesList = sections.map((gallery, index) => {
    const hasImages = !!gallery.images;
    const showImage = hasImages ? gallery.images.length > 0 && gallery.images[0].image : null;
    const imageElement = showImage ? <ImagePreview image={getFilePreviewUrl(showImage)} /> : <ImagePlaceholder />;

    return (
      <SortableBarElement
        key={gallery.id}
        titleLabel={INSPIRATIONS_LABEL.sectionTitle}
        onTitleChange={e => handleUpdateTitle(gallery.id, e)}
        onItemDelete={() => handleDeleteSection(gallery.id)}
        onExpandToggle={() => setExpandedSectionId(gallery.id)}
        title={gallery.title || ""}
        index={index}
        item={gallery}
        isActive={expandedSectionId === gallery.id}
        headerChildren={imageElement}
        disabled={!canEdit}
      >
        <SortableListFormElement
          item={hasImages ? gallery : { ...gallery, images: [] }}
          onGalleryChange={newList => handleGalleryChange(gallery.id, newList)}
          disabled={!canEdit}
        />
      </SortableBarElement>
    );
  });

  return (
    <>
      <Header>
        <BodyText>{INSPIRATIONS_LABEL.inspirationSection}</BodyText>
        {canEdit && (
          <SecondaryButton onClick={handleSectionAdd}>
            <AddIcon />
            {CREATE_CONTENT_LABELS.add}
          </SecondaryButton>
        )}
      </Header>
      <SortableList lockAxis="y" onSortEnd={handleChangeOrder} lockToContainerEdges useDragHandle>
        {galleriesList}
      </SortableList>
    </>
  );
};

InspirationGallery.propTypes = {
  canEdit: PropTypes.bool.isRequired,
};

export { InspirationGallery, Header };
