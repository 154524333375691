import { useState, useEffect, useCallback, useRef } from "react";
import { debounce } from "lodash";

import { DEFAULT_DEBOUNCE_DELAY } from "constants/defaults";

const useDebouncedFnExecution = (fn, initValue, props = [], delayTime = DEFAULT_DEBOUNCE_DELAY) => {
  const [value, setValue] = useState();

  const ref = useRef(debounce(fn, delayTime));

  const executeFn = useCallback(() => {
    if ((value || value === "") && value !== initValue) {
      ref.current(value, ...props);
    }
  }, [props, value, initValue]);

  const setInitValue = () => {
    setValue(initValue);
  };

  useEffect(executeFn, [value]);
  useEffect(setInitValue, []);

  return [value, setValue];
};

export { useDebouncedFnExecution };
