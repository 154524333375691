import { isEmpty, webUrlValidation } from "utils/validation";
import { ERRORS, DIRECTORY_LABELS } from "constants/content";

export const documentValidator = document => {
  const result = {};

  if (!document) {
    result.file = ERRORS.missingAttachment;
    result.name = ERRORS.cantBeBlank(DIRECTORY_LABELS.title);
    return result;
  }
  const { name, library_node_id, file_id, file_url, web_url } = document;
  if (isEmpty(name)) result.name = ERRORS.cantBeBlank(DIRECTORY_LABELS.title);
  if (web_url && !webUrlValidation(web_url)) result.web_url = ERRORS.invalidURL;
  if (!library_node_id && !file_url && !file_id && !web_url) result.file = ERRORS.missingAttachment;
  return result;
};
