import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { v4 as uuidv4 } from 'uuid';

import appleLogo from "assets/images/apple.svg";
import { AppleSignInButton, AppleSignInButtonIcon, AppleSignInButtonText } from "./style";

import { dispatch } from "../../../../../store/store";
import { setNotification } from "../../../../../store/app/actions";
import { ConfirmationModal } from "../../../../../components/ui/Modals/ConfirmationModal";
import { AUTH } from "../../../../../constants/content";

const AppleAuth = ({ handleSignIn, setSpinner }) => {
  const [openModal, setOpenModal] = useState(false);

  const { composerData } = useSelector(state => state.auth);

  let state = localStorage.getItem('state');
  if (!state) {
    state = uuidv4();
    localStorage.setItem('state', state);
  }

  let nonce = localStorage.getItem('nonce');
  if (!nonce) {
    nonce = uuidv4();
    localStorage.setItem('nonce', nonce);
  }

  useEffect(() => {
    if (!composerData) return;
    window.AppleID.auth.init({
      clientId: composerData?.auth?.apple?.systemClientId,
      scope: 'name email',
      redirectURI: `${window.location.origin}/auth/callback/${composerData?.auth?.apple?.name}`,
      state,
      nonce,
      usePopup: true
    });

  }, [nonce, state, composerData])

  useEffect(() => {
    const handleAppleSignInSuccess = async (event) => {
      setSpinner(true);
      if (event.detail.authorization.state !== state) {
        dispatch(setNotification({ type: "error", message: AUTH.appleStateMismatch }));
        setSpinner(false);
        return;
      }

      const signInUserBody = {
        method: composerData?.auth?.apple?.name,
        clientId: composerData?.auth?.apple?.systemClientId,
        idToken: event.detail.authorization.id_token,
        code: event.detail.authorization.code,
        nonce,
        ...(event.detail.user && { profile: event.detail.user }),
      }
      await handleSignIn('apple', signInUserBody);
    };

    const handleAppleSignInFailure = async (event) => {
      setSpinner(true);
      console.error("Sign in Apple error: ", event.detail.error);
      localStorage.removeItem("state");
      localStorage.removeItem("nonce");
      setSpinner(false);
    };

    document.addEventListener('AppleIDSignInOnSuccess', handleAppleSignInSuccess);
    document.addEventListener('AppleIDSignInOnFailure', handleAppleSignInFailure);

    return () => {
      document.removeEventListener('AppleIDSignInOnSuccess', handleAppleSignInSuccess);
      document.removeEventListener('AppleIDSignInOnFailure', handleAppleSignInFailure);
    };
  }, [handleSignIn, composerData?.auth?.apple?.name, nonce, state, composerData?.auth?.apple?.systemClientId]);

  const handleAppleSignIn = () => {
    setOpenModal(false);
    window.AppleID.auth.signIn();
  };

  return (
    <>
      <AppleSignInButton onClick={(e) => {
        e.preventDefault();
        setOpenModal(true);
      }}
      >
        <AppleSignInButtonIcon src={appleLogo} alt="Apple Logo" />
        <AppleSignInButtonText>Sign in with Apple</AppleSignInButtonText>
      </AppleSignInButton>

      {openModal && <ConfirmationModal
        open={openModal}
        title={AUTH.appleWarningTitle}
        description={AUTH.appleWarningMessage}
        onConfirm={() => (handleAppleSignIn())}
        confirmLabel='OK'
        onCancel={() => setOpenModal(false)}
      />}
    </>
  )
}

AppleAuth.propTypes = {
  handleSignIn: PropTypes.func.isRequired,
  setSpinner: PropTypes.func.isRequired,
};

export default AppleAuth;
