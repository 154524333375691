import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import moment from "moment";

import config from "config/app";

import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import AssignmentLateIcon from "@material-ui/icons/AssignmentLate";
import AssignmentReturnIcon from "@material-ui/icons/AssignmentReturn";
import { ExpandLess, ExpandMore } from "@material-ui/icons";

import { firstLetterUpperCase } from "utils/string";
import { CUSTOM_APP_ADMIN } from "constants/content";
import { BaseAction } from "components/ui/Tables";

import {
  acceptCustomAppStart,
  getCustomAppBundleIdVersionsStart,
  makeLiveCustomAppStart,
  rejectCustomAppStart,
  revertToDraftCustomAppStart,
} from "store/adminOperatorDetails/actions";
import {
  CUSTOM_APP_STATUS_ACCEPTED,
  CUSTOM_APP_STATUS_APPROVED,
  CUSTOM_APP_STATUS_SUBMITTED,
} from "feature/panel/Settings/CustomApps/_shared/constants/customAppStatus";
import { ViewAction } from "components/ui/Tables/TableRowActions";
import { setUrl } from "utils/url";
import { PANEL_CUSTOM_APPS_EDIT_PATH_GENERAL } from "constants/routes";
import { useNavigate } from "react-router-dom";

import { BundleListItem, InfoCell, ItemContent, ButtonArea, StyledVerifiedIcon, StyledCollapse } from "./styledComponents";

import { RejectConfirmation } from "../RejectConfirmation";
import { RevertToDraftConfirmation } from "../RevertToDraftConfirmation";
import { BundleVersionsTable } from "../BundleVersionsTable";

const CustomAppListItem = ({ item, disabled }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [openAccordion, setOpenAccordion] = useState(false);
  const [openRejectConfirm, setRejectConfirmWindow] = useState(false);
  const [openRevertToDraft, setRevertToDraftConfirm] = useState(false);

  const { versionsList } = item;

  const areAcceptedAndRejectButtonsDisabled = item.status !== CUSTOM_APP_STATUS_SUBMITTED;
  const isLiveButtonDisabled = !(item.status === CUSTOM_APP_STATUS_APPROVED || item.status === CUSTOM_APP_STATUS_ACCEPTED);
  const isRevertToDraftDisabled = !(
    item?.status === CUSTOM_APP_STATUS_APPROVED ||
    item?.status === CUSTOM_APP_STATUS_SUBMITTED ||
    item?.status === CUSTOM_APP_STATUS_ACCEPTED
  );

  useEffect(() => {
    if (openAccordion && !versionsList.length) {
      dispatch(getCustomAppBundleIdVersionsStart({ bundleId: item.bundleId }));
    }
  }, [openAccordion]);

  const setCustomAppActive = id => {
    dispatch(acceptCustomAppStart({ id }));
  };
  const rejectCustomAppHandler = () => {
    setRejectConfirmWindow(true);
  };
  const rejectCustomAppConfirmHandler = id => {
    dispatch(rejectCustomAppStart({ id }));
    setRejectConfirmWindow(false);
  };
  const rejectCustomAppCancelHandler = () => {
    setRejectConfirmWindow(false);
  };

  const revertToDraftCustomAppHandler = () => {
    setRevertToDraftConfirm(true);
  };
  const revertToDraftCustomAppConfirmHandler = id => {
    dispatch(revertToDraftCustomAppStart({ id }));
    setRevertToDraftConfirm(false);
  };
  const revertToDraftCustomAppCancelHandler = () => {
    setRevertToDraftConfirm(false);
  };

  const revertToLiveActionHandler = id => {
    dispatch(makeLiveCustomAppStart({ id }));
  };

  const acceptButtonAction = (
    <BaseAction
      noBackground={openAccordion}
      icon={<AssignmentTurnedInIcon />}
      clickAction={() => setCustomAppActive(item.id)}
      tooltip={CUSTOM_APP_ADMIN?.tooltips?.accept}
      disabled={disabled || areAcceptedAndRejectButtonsDisabled}
    />
  );
  const rejectButtonAction = (
    <BaseAction
      noBackground={openAccordion}
      icon={<AssignmentLateIcon />}
      clickAction={rejectCustomAppHandler}
      tooltip={CUSTOM_APP_ADMIN?.tooltips?.reject}
      disabled={disabled || areAcceptedAndRejectButtonsDisabled}
    />
  );
  const revertToDraftButtonAction = (
    <BaseAction
      noBackground={openAccordion}
      icon={<AssignmentReturnIcon />}
      clickAction={revertToDraftCustomAppHandler}
      tooltip={CUSTOM_APP_ADMIN?.tooltips?.revert}
      disabled={disabled || isRevertToDraftDisabled}
    />
  );
  const revertToLiveButtonAction = (
    <BaseAction
      noBackground={openAccordion}
      icon={<StyledVerifiedIcon disabled={isLiveButtonDisabled} />}
      clickAction={() => revertToLiveActionHandler(item.id)}
      tooltip={CUSTOM_APP_ADMIN?.tooltips?.live}
      disabled={disabled || isLiveButtonDisabled}
    />
  );

  const viewButtonAction = <ViewAction clickAction={() => navigate(setUrl(PANEL_CUSTOM_APPS_EDIT_PATH_GENERAL, { id: item.id }))} />;

  const toggleAccordion = () => {
    setOpenAccordion(!openAccordion);
  };

  return (
    <>
      <BundleListItem isOpened={openAccordion}>
        <ItemContent>
          <InfoCell>{item.name}</InfoCell>
          <InfoCell>{item.status === "approved" ? "Accepted" : firstLetterUpperCase(item.status)}</InfoCell>
          <InfoCell>{moment(item.createdAt).format(config.dateTimeFormat)}</InfoCell>
          <InfoCell>{moment(item.updatedAt).format(config.dateTimeFormat)}</InfoCell>
          <ButtonArea noBackground>
            <BaseAction
              noBackground={openAccordion}
              icon={openAccordion ? <ExpandLess /> : <ExpandMore />}
              clickAction={() => toggleAccordion(item.id)}
            />
          </ButtonArea>
          <ButtonArea>{acceptButtonAction}</ButtonArea>
          <ButtonArea>{rejectButtonAction}</ButtonArea>
          <ButtonArea>{revertToDraftButtonAction}</ButtonArea>
          <ButtonArea>{revertToLiveButtonAction}</ButtonArea>
          <ButtonArea>{viewButtonAction}</ButtonArea>
        </ItemContent>
        <StyledCollapse in={openAccordion}>
          <BundleVersionsTable versionsList={item.versionsList} />
        </StyledCollapse>
      </BundleListItem>
      {openRejectConfirm && (
        <RejectConfirmation
          open={openRejectConfirm}
          onConfirmModal={() => rejectCustomAppConfirmHandler(item.id)}
          onCancelModal={rejectCustomAppCancelHandler}
        />
      )}
      {openRevertToDraft && (
        <RevertToDraftConfirmation
          open={openRevertToDraft}
          onConfirmModal={() => revertToDraftCustomAppConfirmHandler(item.id)}
          onCancelModal={revertToDraftCustomAppCancelHandler}
        />
      )}
    </>
  );
};

CustomAppListItem.propTypes = {
  item: PropTypes.shape({
    name: PropTypes.string,
    status: PropTypes.string,
    createdAt: PropTypes.string,
    updatedAt: PropTypes.string,
    bundleId: PropTypes.string,
    versionsList: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
};
export { CustomAppListItem };
