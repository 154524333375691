export const UPDATE_ADMIN_USER_DETAILS_START = "UPDATE_ADMIN_USER_DETAILS_START";
export const UPDATE_ADMIN_USER_DETAILS_SUCCESS = "UPDATE_ADMIN_USER_DETAILS_SUCCESS";
export const UPDATE_ADMIN_USER_DETAILS_FAIL = "UPDATE_ADMIN_USER_DETAILS_FAIL";

export const GET_USER_DETAILS_START = "GET_USER_DETAILS_START";
export const GET_USER_DETAILS_SUCCESS = "GET_USER_DETAILS_SUCCESS";
export const GET_USER_DETAILS_FAIL = "GET_USER_DETAILS_FAIL";

export const SET_VALUE_FOR_USER_DETAILS = "SET_VALUE_FOR_USER_DETAILS";
export const SET_ERRORS_FOR_USER_DETAILS = "SET_ERRORS_FOR_USER_DETAILS";

export const RESET_ADMIN_USER_DETAILS_PASSWORD = "RESET_ADMIN_USER_DETAILS_PASSWORD";
export const CHECK_ADMIN_USERNAME_UNIQUENES_START = "CHECK_ADMIN_USERNAME_UNIQUENES_START";
export const CHECK_ADMIN_USERNAME_UNIQUENES_FAIL = "CHECK_ADMIN_USERNAME_UNIQUENES_FAIL";
export const CHECK_ADMIN_EMAIL_UNIQUENES_START = "CHECK_ADMIN_EMAIL_UNIQUENES_START";
export const CHECK_ADMIN_EMAIL_UNIQUENES_FAIL = "CHECK_ADMIN_EMAIL_UNIQUENES_FAIL";

export const CLEAR_USER_DETAILS_ERRORS = "CLEAR_USER_DETAILS_ERRORS";

export const SET_USER_DETAILS_FORM_TOUCHED = "SET_USER_DETAILS_FORM_TOUCHED";
