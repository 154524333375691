import styled from "styled-components";

const variants = {
  white: "255, 255, 255",
  black: "0, 0, 0",
};

const BaseComponent = styled.div`
  position: ${({ position }) => position || "static"};
`;

const BackgroundCover = styled(BaseComponent)`
  width: inherit;
  height: inherit;
  background: rgba(
    ${({ variant, opacity }) => {
      return `${variants[variant || "black"]}, ${opacity || "0.5"}`;
    }}
  );
`;

const BackgroundImage = styled(BaseComponent)`
  width: inherit;
  height: inherit;
  background-image: url('${({ background }) => background}');
  background-size: cover;
  background-position: bottom right;
  object-fit: contain;
`;

export { BackgroundCover, BackgroundImage };
