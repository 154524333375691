import React, { useContext } from "react";
import PropTypes from "prop-types";

import { NotificationItem } from "feature/panel/_shared/Notifications/TimedNotificationForm/NotificationItem";
import { NoTemplatesMessage } from "feature/panel/_shared/Notifications/NoTemplatesMessage";
import { NOTIFICATION_TYPES } from "constants/content";

const TimedNotificationForm = ({ context, disabled }) => {
  const { notifications } = useContext(context);
  const timedNotificationsList = notifications.filter(notification => notification.type === NOTIFICATION_TYPES.timed);

  return timedNotificationsList.length ? (
    timedNotificationsList.map(
      notification => <NotificationItem disabled={disabled} key={notification.id} item={notification} context={context} />
    )
  ) : (
    <NoTemplatesMessage notificationType="timed" />
  );
};

TimedNotificationForm.defaultProps = {
  disabled: false,
}

TimedNotificationForm.propTypes = {
  context: PropTypes.shape({}).isRequired,
  disabled: PropTypes.bool,
};

export { TimedNotificationForm };
