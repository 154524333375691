import { Card } from "@material-ui/core";
import styled from "styled-components";

const SectionTitle = styled.div`
  font-family: Montserrat;
  font-size: ${({ theme }) => theme.setSpacing(6)}px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  color: ${({ theme }) => theme.colors.grey90};
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  position: relative;
  svg {
    margin-left: 10px;
    cursor: ${p => (p.disabled ? "not-allowed" : "pointer")};
    color: #bbb;
  }
  &:after {
    content: "";
    position: absolute;
    height: 3px;
    width: 100px;
    background-color: ${({ theme }) => theme.colors.brand};
    bottom: -10px;
    border-radius: 10px;
    opacity: ${p => (p.underlined ? 1 : 0)};
  }
`;

const HintWrapper = styled.div`
  max-width: 350px;
  background-color: ${({ theme }) => theme.colors.descriptionBackground};
  margin: ${({ theme }) => theme.setSpacing(5)}px 0 0 0;
  padding: ${({ theme }) => theme.setSpacing(4.5)}px ${({ theme }) => theme.setSpacing(11)}px ${({ theme }) => theme.setSpacing(4.5)}px
    ${({ theme }) => theme.setSpacing(6.5)}px;
  border-radius: ${({ theme }) => theme.setSpacing(12.5)}px;
  font-family: Montserrat;
  font-size: ${({ theme }) => theme.setSpacing(3)}px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: ${({ theme }) => theme.colors.black};
`;

const CustomCard = styled(Card)`
  && {
    overflow: unset;
  }

  margin-top: ${({ theme }) => theme.setSpacing(12)}px;
  margin-bottom: ${({ theme }) => theme.setSpacing(12)}px;
  min-height: ${({ theme }) => theme.setSpacing(80)}px;

  ${({ theme, noColor }) =>
    !noColor ? `background: linear-gradient(to right, ${theme.colors.white} 50%, ${theme.colors.grey10} 50%);` : ""}
`;

const CardSection = styled.div`
  min-height: 320px;
  flex-basis: 100%;
  padding-top: 30px;
  padding-bottom: 30px;
`;

const PreviewCardSection = styled(CardSection)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const CardSectionsContainer = styled.div`
  flex-basis: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const CustomInputContainer = styled.div`
  margin: 0 0 40px 0;

  &:last-child {
    margin: 0;
  }

  && {
    .MuiInputLabel-root {
      pointer-events: none;
    }
  }
`;

const CustomInputsSection = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.grey10};
  padding: 40px 84px 0 84px;
  margin: 40px -84px 0 -84px;
`;

const ColorPickerWrapper = styled.div`
  margin: 40px auto;
`;

const SectionBorder = styled.div`
  background-color: ${({ theme }) => theme.colors.grey20};
  height: 2px;
  width: 100%;
`;

const InputContentWrapper = styled.div`
  max-width: 360px;
  margin: 0 auto;
`;

const HomeButton = styled.div`
  && {
    font-size: 10px;
    color: ${({ color }) => color};

    .MuiSvgIcon-root {
      font-size: 12px;
      margin-bottom: -2px;
      margin-right: -3px;
    }
  }
`;

export {
  HintWrapper,
  SectionTitle,
  CustomCard,
  CardSectionsContainer,
  CardSection,
  PreviewCardSection,
  CustomInputContainer,
  CustomInputsSection,
  ColorPickerWrapper,
  SectionBorder,
  InputContentWrapper,
  HomeButton,
};
