import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import MenuItem from "@material-ui/core/MenuItem";

import { Select } from "components/ui/Forms";
import { IconImage } from "components/ui/Icons";
import { FieldValueWrapper } from "feature/panel/Settings/_shared/styledComponents";

const StyledSelect = styled(({ short, ...props }) => <Select {...props} />)`
  && {
    cursor: pointer;
  }

  ${({ short, theme }) =>
    short
      ? `
        width: ${theme.setSpacing(15)}px;

        div {
          padding-left: ${theme.setSpacing(1)}px !important;
        }
        img {
          margin-right: 0px !important;
        }
        svg {
            right: ${theme.setSpacing(1)}px !important;
            margin-right: 0 !important;
          }
        `
      : null}
`;

const IconsSelect = ({ name, withNames, selected, onSelect, ...rest }) => {
  const { availableIcons } = useSelector(state => state.operator);
  const navigationIcons = availableIcons.filter(icon => icon.section === "navigation");
  const value = navigationIcons.some(icon => icon.id === selected) ? selected : "";

  return (
    <StyledSelect name={name} label="Icon" value={value} onChange={onSelect} short={!withNames} {...rest}>
      {navigationIcons.map(icon => (
        <MenuItem value={icon.id} key={icon.id}>
          <FieldValueWrapper>
            <IconImage src={icon.url} darkened />
            {withNames && <span>{icon.localised_name || icon.name}</span>}
          </FieldValueWrapper>
        </MenuItem>
      ))}
    </StyledSelect>
  );
};

IconsSelect.defaultProps = {
  withNames: false,
  selected: null,
};

IconsSelect.propTypes = {
  name: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
  selected: PropTypes.number,
  withNames: PropTypes.bool,
};

export { IconsSelect };
