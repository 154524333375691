import React from "react";
import PropTypes from "prop-types";
import TableCell from "@material-ui/core/TableCell";

import styled from "styled-components";

import { ChipWithRedirect } from "components/ui/Chips/ChipWithRedirect";
import { TableRow } from "components/ui/Tables";
import { IconImage } from "components/ui/Icons";

import { DEFAULT_CELL_MAX_CHARACTERS } from "constants/defaults";
import { TextLimiter } from "components/ui/TextLimiter";
import { useActiveIcon } from "hooks/useActiveIcon";

const IconCenterer = styled.div`
  display: flex;
  align-items: center;
`;

const PoisTableRow = ({ item }) => {
  const { icon, poiRange, latitude, longitude, country, name, description, file } = item;

  const chipFileIcon = useActiveIcon(file);

  const renderFileDeleter = () => (
    <ChipWithRedirect
      onDelete={() => {}}
      item={file}
      icon={chipFileIcon}
      editable={false}
      maxChar={15}
    />
  );

  return (
    <TableRow item={item}>
      <TableCell>
        <IconCenterer>
          <IconImage src={icon?.url} />
        </IconCenterer>
      </TableCell>
      <TableCell>{name}</TableCell>
      <TableCell>{country}</TableCell>
      <TableCell style={{ minWidth: 120 }}>{poiRange}</TableCell>
      <TableCell>{`${latitude}, ${longitude}`}</TableCell>
      <TableCell>
        <TextLimiter maxChar={DEFAULT_CELL_MAX_CHARACTERS} text={description} />
      </TableCell>
      <TableCell>
        {!file ? "-" : renderFileDeleter()}
      </TableCell>
    </TableRow>
  );
};

PoisTableRow.propTypes = {
  item: PropTypes.shape().isRequired,
};

export { PoisTableRow };
