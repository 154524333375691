import React, { forwardRef } from "react";
import styled from "styled-components";
import { ContentArea } from "./ContentArea";
import { ReorderButton } from "./ReorderButton";

const StyledWrapper = styled.div`
  display: flex;
  flex-basis: auto;
  margin-bottom: ${({ theme }) => theme.setSpacing(2)}px;
  align-items: start;

  border-radius: ${({ theme }) => theme.borderRadius}px;
  min-height: ${({ theme }) => theme.setSpacing(10)}px;

  background-color: ${({ theme, error, noBg, brandedBg }) => {
    const color = brandedBg ? theme.colors.brand : theme.colors.grey10;
    return noBg ? "transparent" : theme.colors.withOpacity(error ? theme.colors.error : color, 0.2);
  }};

  box-shadow: ${({ theme }) => theme.shadows.map};

  &:last-child {
    margin: 0;
  }
`;

export const ListItem = forwardRef(({ children, listeners, useDragHandle, ...props }, ref) => {
  return (
    <StyledWrapper ref={ref} {...props}>
      {useDragHandle ? (
        <>
          <ReorderButton listeners={listeners} />
          <ContentArea>
            {children}
          </ContentArea>
        </>
      ) : (
        <>
          <ContentArea {...listeners}>
            {children}
          </ContentArea>
        </>
      )}
    </StyledWrapper>
  );
})