import React from "react";
import PropTypes from "prop-types";
import { BodyText } from "components/ui/Typography/Typography";
import { Link } from "components/ui/Links/Links";
import { BreadcrumbsList } from "components/ui/Breadcrumbs/BreadcrumbsList";
import { BreadcrumbsMenu } from "components/ui/Breadcrumbs/BreadcrumbsMenu";
import { ArrowIcon } from "components/ui/Breadcrumbs/styledComponents";
import { PANEL_LIBRARY_PATH } from "constants/routes";

const getNestedFolderUrl = (value, nestLevel) => {
  return value
    .split(/\//)
    .filter(path => path.length > 0)
    .slice(0, nestLevel)
    .join("/");
};

const Breadcrumbs = ({ list, rootEl, currentEl, location, max }) => {
  const { name } = currentEl;
  const parentsCount = list.length;
  const pathFromLibraryRoot = location.pathname.substring(PANEL_LIBRARY_PATH.length);

  const BreadcrumbsMenuParents =
    parentsCount > max
      ? list
          .slice(0, parentsCount - max)
          .map((pathName, index) => {
            const nestLevel = index + 1;
            const url = `${PANEL_LIBRARY_PATH}/${getNestedFolderUrl(pathFromLibraryRoot, nestLevel)}`;
            return {
              label: pathName,
              url,
            };
          })
          .reverse()
      : [];

  const BreadcrumbsParents = list.slice(-max).map((pathName, index, array) => {
    const nestLevel = index + 1 + BreadcrumbsMenuParents.length;
    const url = `${PANEL_LIBRARY_PATH}/${getNestedFolderUrl(pathFromLibraryRoot, nestLevel)}`;
    return {
      label: pathName,
      url,
      isLast: array.length - 1 === index,
    };
  });

  return (
    <>
      <Link to={rootEl.url} cv="grey50" noDecoration>
        <BodyText cv={BreadcrumbsMenuParents.length || BreadcrumbsParents.length ? "grey50" : "black"}>{rootEl.title}</BodyText>
      </Link>
      {!!(BreadcrumbsMenuParents.length || BreadcrumbsParents.length) && <ArrowIcon />}
      <BreadcrumbsMenu list={BreadcrumbsMenuParents} />
      <BreadcrumbsList list={BreadcrumbsParents} />
      <BodyText>{name}</BodyText>
    </>
  );
};

Breadcrumbs.defaultProps = {
  max: 4,
};

Breadcrumbs.propTypes = {
  list: PropTypes.arrayOf(PropTypes.string).isRequired,
  rootEl: PropTypes.shape({
    title: PropTypes.string,
    url: PropTypes.string,
  }).isRequired,
  currentEl: PropTypes.shape({
    parent: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    name: PropTypes.string,
    url: PropTypes.string,
  }).isRequired,
  max: PropTypes.number,
};

export { Breadcrumbs };
