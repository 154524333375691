import styled from "styled-components";
import { setButtonAttr } from "utils/styles/buttons";
import { BaseButton } from "components/ui/Buttons/BaseButton";

const SecondaryButton = styled(BaseButton)`
  && {
    color: ${({ cv }) => setButtonAttr("color", cv, "brand")};
    background: transparent;
    border: 1px solid ${({ cv }) => setButtonAttr("color", cv, "brand")};

    ${({ disabled, theme }) => disabled && `
      background: transparent;
      border: 1px solid ${theme.colors.grey20};
      color: ${theme.colors.grey20};
    `}

    :hover {
      background: transparent;
      border: 1px solid ${({ theme }) => theme.colors.brandHover};
      color: ${({ theme }) => theme.colors.brandHover};
    }
  }
`;

export { SecondaryButton };
