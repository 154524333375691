import React, { useState, useContext, useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useSelector } from "react-redux";

import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import ImageOutlinedIcon from "@material-ui/icons/ImageOutlined";

import { Input, Textarea, Select } from "components/ui/Forms";
import { ChipWithRedirect } from "components/ui/Chips/ChipWithRedirect";
import { BodyText2 } from "components/ui/Typography/Typography";
import { AttachFilesButton } from "components/ui/Buttons/AttachFilesButton";
import { IMAGES_MIME_TYPES } from "constants/defaults";
import { DEFAULT_LIBRARY_TABS } from "components/ui/LibraryPopup/LibraryPopup";

import { EntriesContext } from "feature/panel/Trips/_shared/Storyboard/Entries/EntriesContext";

import { useItemOriginalIndex } from "hooks/useItemOriginalIndex";
import { useDebouncedFnExecution } from "hooks/useDebouncedFnExecution";

import { formatDisplayableLibraryPopupValues } from "utils/library";
import { checkErrorExistFor } from "utils/validation";
import { GLOBAL_CONTENT, TRIP_WIZARD_CONTENT } from "constants/content";
import { isObjectEmpty } from "utils/object";
import { useRefreshedFileUrl } from "hooks/useRefreshedFileUrl";
import { TextInputEditor } from "feature/panel/Settings/CustomApps/_shared/LoginScreen/TextInputEditor";

export const getHeadLine = item => (item.meta.hide_day_info && item.headline.charAt(0) !== "#" ? "#" + item.headline : item.headline);

const GRID_NUMBER_OF_COLUMNS = {
  small: 4,
  large: 6,
};

const StyledTextarea = styled(Textarea)`
  && {
    textarea {
      padding-top: ${({ theme }) => theme.setSpacing(1)}px;
      padding-bottom: ${({ theme }) => theme.setSpacing(2)}px;
    }
  }
`;

const removeLocation = ({ location_internal_id, location, ...rest }) => rest;

const EntryDetailsForm = ({ item, locations, index, disabled }) => {
  const refreshedUrl = useRefreshedFileUrl(item?.image?.fileUrl || null);
  const { updateDayData, handleChangeDayNumber, days, setDays, show_days, errors, getIfErrorExist } = useContext(EntriesContext);

  const [invalidDayNumber, setInvalidDayNumber] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [headlineValue, setHeadlineValue] = useState("");

  const { errors: backendErrors } = useSelector(state => state.trips);

  const numberOfGridColumns = show_days ? GRID_NUMBER_OF_COLUMNS.small : GRID_NUMBER_OF_COLUMNS.large;

  const { id, headline, location, location_internal_id, content, image, meta } = item;
  const [editorContent, setContent] = useState(content);

  const locationToCompare = (location && location.id) || location_internal_id || 0;

  const originalIndex = useItemOriginalIndex(id, "storyboard", backendErrors);

  const backendErrorsObject = backendErrors.details ? backendErrors.details[originalIndex] : {};

  const handleAttachFile = files => {
    const [newImage] = formatDisplayableLibraryPopupValues(files);

    const newItem = { ...item, image: newImage };
    updateDayData(newItem);
  };

  const handleSelectLocation = ({ target }) => {
    if (target.value !== 0) {
      updateDayData({
        ...item,
        headline: item.meta.hide_day_info ? "#" + item.headline : item.headline,
        location: locations.find(({ id: locationId }) => locationId === target.value),
      });
    } else {
      updateDayData(removeLocation({ ...item, headline: item.meta.hide_day_info ? "#" + item.headline : item.headline }));
    }
  };

  const handleChange = (key, value) => {
    if (key === "headline") setHeadlineValue(value);

    updateDayData({ ...item, [key]: value });
  };

  const updateExpiredFileUrl = () => {
    if (item?.image?.fileUrl && refreshedUrl && refreshedUrl !== item?.image?.fileUrl) {
      updateDayData({
        ...item,
        image: {
          ...item.image,
          fileUrl: refreshedUrl,
          previewUrl: refreshedUrl,
        },
      });
    }
  };

  const renderFileUploader = () => (
    <AttachFilesButton
      disabled={disabled}
      name="background-image"
      onSelect={handleAttachFile}
      allowedFileTypes={IMAGES_MIME_TYPES}
      tabs={Object.values(DEFAULT_LIBRARY_TABS).splice(0, 2)}
    />
  );

  const setupErrors = () => {
    const error = getIfErrorExist(item.id);
    if (error) {
      setInvalidDayNumber(true);
      setErrorMessage(error.message);
    } else {
      setInvalidDayNumber(false);
      setErrorMessage(null);
    }
  };

  useEffect(setupErrors, [errors]);
  useEffect(updateExpiredFileUrl, [refreshedUrl]);
  useEffect(() => {
    if (meta.hide_day_info) setHeadlineValue("#" + headline);
    else setHeadlineValue(headline);
  }, []);

  const renderFileDeleter = () => (
    <ChipWithRedirect disabled={disabled} icon={<ImageOutlinedIcon />} onDelete={() => handleChange("image", null)} item={image} />
  );
  const [contentValue, handleChangeContent] = useDebouncedFnExecution(
    (value, item, days) => {
      const newDays = days.map(day => (day.id === item.id ? { ...item, content: value } : { ...day, headline: getHeadLine(day) }));
      setDays(newDays);
    },
    content,
    [{ ...item, headline: headlineValue }, days],
    0,
  );
  return (
    <Grid container spacing={6}>
      <Grid item xs={12}>
        <Grid container spacing={6}>
          {show_days && (
            <Grid item lg={numberOfGridColumns} xs={12}>
              <Input
                name={`day-nr${index}`}
                label={TRIP_WIZARD_CONTENT.storyboard.dayNumber}
                value={meta?.day_number || ""}
                type="number"
                onChange={event => handleChangeDayNumber({ ...item, headline: headlineValue }, event)}
                error={invalidDayNumber || checkErrorExistFor("dayNumber", backendErrorsObject)}
                helperText={errorMessage || (backendErrorsObject && backendErrorsObject.dayNumber)}
                disabled={disabled}
              />
            </Grid>
          )}
          <Grid item lg={numberOfGridColumns} xs={12}>
            <Input
              name={`day-headline-nr${index}`}
              label={TRIP_WIZARD_CONTENT.storyboard.dayTitle}
              value={headlineValue}
              onChange={({ target }) => handleChange("headline", target.value)}
              error={checkErrorExistFor("headline", backendErrorsObject)}
              helperText={backendErrorsObject ? backendErrorsObject.headline : null}
              disabled={disabled}
            />
          </Grid>
          <Grid item lg={numberOfGridColumns} xs={12}>
            {locations.length === 0 && <Input name={`day-location-nr${index}`} label={TRIP_WIZARD_CONTENT.storyboard.locations} disabled />}
            {locations.length > 0 && (
              <Select
                name={`day-location-nr${index}`}
                label={TRIP_WIZARD_CONTENT.storyboard.locations}
                value={locationToCompare}
                onChange={handleSelectLocation}
                disabled={disabled}
              >
                <MenuItem value={0} selected={locationToCompare}>
                  <em>{GLOBAL_CONTENT.none}</em>
                </MenuItem>
                {locations.map(locationItem => (
                  <MenuItem value={locationItem.id} key={locationItem.id} selected={locationToCompare === locationItem.id}>
                    {locationItem.name}
                  </MenuItem>
                ))}
              </Select>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <TextInputEditor
          name={`day-content-nr${index}`}
          label={TRIP_WIZARD_CONTENT.storyboard.additionalInformation}
          value={contentValue}
          disabled={disabled}
          onChange={val => handleChangeContent(val)}
          toolbar="bold italic underline link fontsizeselect code"
          index={index}
          // height="160"
        />
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={6} alignItems="center">
          <Grid item xs={4}>
            <BodyText2>{TRIP_WIZARD_CONTENT.storyboard.backgroundImage}:</BodyText2>
          </Grid>
          <Grid item xs={8} align="right">
            {image && !isObjectEmpty(image) ? renderFileDeleter() : renderFileUploader()}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

EntryDetailsForm.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    position: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    image: PropTypes.shape(),
    headline: PropTypes.string,
    documents: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    content: PropTypes.string.isRequired,
    location: PropTypes.shape(),
    location_internal_id: PropTypes.number,
    meta: PropTypes.arrayOf(PropTypes.shape({})) | PropTypes.object,
  }).isRequired,
  index: PropTypes.number.isRequired,
  locations: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  disabled: PropTypes.bool.isRequired,
};

export { EntryDetailsForm, StyledTextarea };
