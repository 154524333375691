import React, { useState } from "react";
import PropTypes from "prop-types";
import { Menu, MenuItem } from "@material-ui/core";

import { TABLE_ACTIONS_TITLES } from "constants/content";

import { DownloadAction } from "components/ui/Tables/TableRowActions";

const TableRowDownloadAction = ({ item, context, onDownload }) => {
  const [openMenu, setOpenMenu] = useState(null);

  const handleMenuClose = () => setOpenMenu(null);

  const handleMenuOpen = ({ currentTarget }) => setOpenMenu(currentTarget);

  const handleDownloadVersion = (versionType, target) => {
    onDownload(item, versionType, target);
    handleMenuClose();
  };

  return (
    <>
      <DownloadAction
        clickAction={handleMenuOpen}
        id="download-button"
        tooltip={TABLE_ACTIONS_TITLES.downloadResource(TABLE_ACTIONS_TITLES.resourceTypes[context])}
      />

      <Menu id="downloadMenu" anchorEl={openMenu} open={!!openMenu && openMenu.id === "download-button"} onClose={handleMenuClose}>
        <MenuItem onClick={({ target }) => handleDownloadVersion("web", target)}>Download web version</MenuItem>
        <MenuItem onClick={({ target }) => handleDownloadVersion("print", target)}>Download print version</MenuItem>
      </Menu>
    </>
  );
};

TableRowDownloadAction.propTypes = {
  item: PropTypes.shape().isRequired,
  context: PropTypes.string.isRequired,
  onDownload: PropTypes.func.isRequired,
};

export { TableRowDownloadAction };
