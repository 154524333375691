import React from "react";
import styled from "styled-components";
import TreeView from "@material-ui/lab/TreeView";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import TreeItem from "@material-ui/lab/TreeItem";
import PropTypes from "prop-types";
import { firstLetterUpperCase } from "utils/string";
import { Accordion, AccordionSummary, AccordionDetails, Typography } from "@material-ui/core";
import { STAY_WIZARD_CONTENT } from "constants/content";

const CustomTreeView = styled(TreeView)`
  && {
    height: ${({ theme }) => theme.setSpacing(54)}px;
    flex-grow: 1;
    max-width: 400px;
  }
`;

const Heading = styled(Typography)`
  font-size: 14px;
  flex-basis: 33.33%;
  flex-shrink: 0;
`

const SecondaryHeading = styled(Typography)`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.withOpacity(theme.colors.black, 0.54)};
`

const StyledExpansionPanel = styled(AccordionDetails)`
  .MuiTreeView-root {
    height: auto;
  }

  && .MuiTypography-body1 {
    font-family: Montserrat !important;
    letter-spacing: 0;
  }
`;

const StyledExpansionHeader = styled(AccordionSummary)`
  && .MuiTypography-body1 {
    font-family: Montserrat !important;
    letter-spacing: 0;
  }
`;


const DirectoriesTree = ({ directories, handleItemClick, selectedDirectory }) => {
  const createDirectoriesTree = ({ name, ...directory }, directoriesList) => {
    return  {
      ...directory,
      name: firstLetterUpperCase(name),
      children: directoriesList
        .filter(dir => dir.parent_id === directory.id)
        .map(child => createDirectoriesTree(child, directoriesList))
    }
  }

  const renderTree = (node) => (
    <TreeItem onLabelClick={() => handleItemClick(node.id)} key={node.id} nodeId={node.id.toString()} label={node.name}>
      {Array.isArray(node.children) ? node.children.map((nodeChild) => renderTree(nodeChild)) : null}
    </TreeItem>
  );

  const tree = {
    id: "root",
    name: STAY_WIZARD_CONTENT.directories.directories,
    children: directories 
      .filter(dir => !dir.parent_id && dir.is_list === true)
      .map(dir => createDirectoriesTree(dir, directories)),
  }

  const getCurrentParentName = (directory) => {
    if(directory.parent_id) {
      return directories.find(dir => dir.id === directory.parent_id)?.name
    }
    return STAY_WIZARD_CONTENT.directories.directories;
  }

  return (
    <Accordion>
      <StyledExpansionHeader expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
        <Heading>
          {STAY_WIZARD_CONTENT.directories.folder}
          : 
        </Heading>
        <SecondaryHeading>{getCurrentParentName(selectedDirectory)}</SecondaryHeading>
      </StyledExpansionHeader>
      <StyledExpansionPanel>
        <CustomTreeView defaultCollapseIcon={<ExpandMoreIcon />} defaultExpandIcon={<ChevronRightIcon />}>
          {renderTree(tree)}
        </CustomTreeView>
      </StyledExpansionPanel>
    </Accordion>
  );
};

DirectoriesTree.propTypes = {
  directories: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  handleItemClick: PropTypes.func.isRequired,
  selectedDirectory: PropTypes.shape({}).isRequired,
};

export { DirectoriesTree, CustomTreeView };
