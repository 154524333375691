import React, { useState } from "react";
import styled from "styled-components";

import { FlightsSearchForm } from "feature/panel/Trips/_shared/Flights/FlightsSearchForm";

const InnerContainer = styled.div`
  width: 100%;
`;

const SearchContainer = styled.div`
  margin: 30px 25px 0;
  padding-bottom: 1px;
`;

const FlightsSection = ({ searchDate, onFlightAdd, form, showManualForm, setShowManualForm }) => {
  const handleFlightSelect = flightData => {
    if (flightData?.selectedFlights?.length > 0) {
      onFlightAdd(flightData);
    } else {
      onFlightAdd(null);
    }
  };

  return (
    <InnerContainer>
      <SearchContainer>
        <FlightsSearchForm
          defaultFlightDate={searchDate}
          onFlightsSelect={handleFlightSelect}
          onShowManualForm={() => setShowManualForm(true)}
          showManualForm={showManualForm}
          form={form}
        />
      </SearchContainer>
    </InnerContainer>
  );
};

export { FlightsSection };
