// @ts-check
import { VamoosImage } from "./VamoosImage";

/**
 * @param {FeatureResponseDto} featureDto  feature stay object
 * @returns {Feature} Feature stay item
 */

export function Feature(featureDto) {
  /**
   * @type {Feature}
   */
  const domain = {};

  if (featureDto.icon_https_url) {
    const splitedUrl = featureDto.icon_https_url.split("/");
    const file = {
      name: splitedUrl[splitedUrl.length - 1],
      file_url: featureDto.icon_https_url,
      preview_url: featureDto.icon_https_url,
    };
    domain.icon = VamoosImage(file, null);
  } else domain.icon = null;

  if (featureDto.hasOwnProperty("group_name")) {
    domain.groupName = featureDto.group_name;
  }
  domain.count = featureDto?.count;
  domain.isFeatured = featureDto?.is_featured;
  domain.id = featureDto.id;
  domain.featureGroupId = featureDto.feature_group_id;
  domain.isActive = featureDto.is_active;
  domain.name = featureDto.name;

  return domain;
}
