export const CREATE_OR_UPDATE_STAY_VALIDATION_FAIL = "CREATE_OR_UPDATE_STAY_VALIDATION_FAIL";

export const GET_STAYS_FEATURES_START = "GET_STAYS_FEATURES_START";
export const GET_STAYS_FEATURES_SUCCESS = "GET_STAYS_FEATURES_SUCCESS";
export const GET_STAYS_FEATURES_FAIL = "GET_STAYS_FEATURES_FAIL";

export const COPY_STAY_START = "COPY_STAY_START";
export const CHECK_USER_ID_AVAILABILITY_START = "CHECK_USER_ID_AVAILABILITY_START";
export const CHECK_USER_ID_AVAILABILITY_SUCCESS = "CHECK_USER_ID_AVAILABILITY_SUCCESS";

export const GET_STAYS_DND_START = "GET_STAYS_DND_START";
export const GET_STAYS_DND_SUCCESS = "GET_STAYS_DND_SUCCESS";
export const GET_STAYS_DND_FAIL = "GET_STAYS_DND_FAIL";

export const SET_STAYS_DND_APPROVAL_START = "SET_STAYS_DND_APPROVAL_START";
export const SET_STAYS_DND_APPROVAL_SUCCESS = "SET_STAYS_DND_APPROVAL_SUCCESS";
export const SET_STAYS_DND_APPROVAL_FAIL = "SET_STAYS_DND_APPROVAL_FAIL";

export const REQUEST_STAY_CONNECT_STATUS_START = "REQUEST_STAY_CONNECT_STATUS_START";
export const REQUEST_STAY_CONNECT_STATUS_SUCCESS = "REQUEST_STAY_CONNECT_STATUS_SUCCESS";
export const REQUEST_STAY_CONNECT_STATUS_FAIL = "REQUEST_STAY_CONNECT_STATUS_FAIL";

export const CREATE_STAYS_PUBLISHER_START = "CREATE_STAYS_PUBLISHER_START";
export const CREATE_STAYS_PUBLISHER_SUCCESS = "CREATE_STAYS_PUBLISHER_SUCCESS";
export const CREATE_STAYS_PUBLISHER_FAIL = "CREATE_STAYS_PUBLISHER_FAIL";

export const GET_STAYS_PUBLISHERS_START = "GET_STAYS_PUBLISHERS_START";
export const GET_STAYS_PUBLISHERS_SUCCESS = "GET_STAYS_PUBLISHERS_SUCCESS";
export const GET_STAYS_PUBLISHERS_FAIL = "GET_STAYS_PUBLISHERS_FAIL";

export const CLEAR_STAYS_PUBLISHER_JOB = "CLEAR_STAYS_PUBLISHER_JOB";

export const REFRESH_STAYS_PUBLISHER_START = "REFRESH_STAYS_PUBLISHER_START";
export const REFRESH_STAYS_PUBLISHER_SUCCESS = "REFRESH_STAYS_PUBLISHER_SUCCESS";
export const REFRESH_STAYS_PUBLISHER_FAIL = "REFRESH_STAYS_PUBLISHER_FAIL";

export const DELETE_STAYS_PUBLISHER_START = "DELETE_STAYS_PUBLISHER_START";
export const DELETE_STAYS_PUBLISHER_SUCCESS = "DELETE_STAYS_PUBLISHER_SUCCESS";
export const DELETE_STAYS_PUBLISHER_FAIL = "DELETE_STAYS_PUBLISHER_FAIL";

// Publisher modal

export const OPEN_PUBLISHER_MODAL = "OPEN_PUBLISHER_MODAL";
export const CLOSE_PUBLISHER_MODAL = "CLOSE_PUBLISHER_MODAL";

export const SET_PUBLISHER_MODAL_ALL_VALUES = "SET_PUBLISHER_MODAL_ALL_VALUES";
export const SET_PUBLISHER_MODAL_VALUE = "SET_PUBLISHER_MODAL_VALUE";
export const SET_PUBLISHER_MODAL_ERROR = "SET_PUBLISHER_MODAL_ERROR";