import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import styled from "styled-components";
import { EditOutlined } from "@material-ui/icons";

import { BodyText2, H5 } from "components/ui/Typography/Typography";
import { SecondaryButton } from "components/ui/Buttons";
import { TABLE_ACTIONS_TITLES } from "constants/content";
import { usePermissionsService } from "hooks/usePermissionsService";
import { PERMISSIONS } from "constants/permissions";

const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.setSpacing(10)}px;
`;
const DescriptionBox = styled.div`
  display: flex;
`;

const Title = styled(H5)`
  && {
    margin-right: ${({ theme }) => theme.setSpacing(6)}px;
  }
`;

const DescriptionLabel = styled(BodyText2)`
  && {
    color: ${({ theme }) => theme.colors.grey50};
    margin-right: ${({ theme }) => theme.setSpacing(10)}px;
  }
`;

export const CurrentDirectoryInfoWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.withOpacity(theme.colors.grey10, 0.3)};
  padding: ${({ theme, fullWidth }) => `${theme.setSpacing(10)}px ${theme.setSpacing(fullWidth ? 0 : 10)}px`};
  margin-bottom: ${({ theme }) => theme.setSpacing(10)}px;
  border-radius: ${({ theme, fullWidth }) => (!fullWidth ? theme.borderRadius : 0)}px;
`;

const CurrentDirectoryInfo = ({ onEditToggle, directory }) => {
  const permissionsService = usePermissionsService();

  const isWiped = useSelector(({ stayWizard }) => stayWizard.form.isWiped);
  const vamoosId = useSelector(({ stayWizard }) => stayWizard.form.vamoosId);
  const editMode = useSelector(({ stayWizard }) => stayWizard.form.vamoosId !== null)

  const canEdit = permissionsService.can(PERMISSIONS.actions.update, PERMISSIONS.sections.vamoosList, vamoosId);

  return (
    (
      <CurrentDirectoryInfoWrapper data-testid="current-directory-info">
        <Header>
          <Title>{directory.name}</Title>
          {!isWiped && (!editMode || canEdit) && (
            <SecondaryButton
              onClick={() => onEditToggle(directory)}
              cv="grey40"
              title={TABLE_ACTIONS_TITLES.editResource(TABLE_ACTIONS_TITLES.resourceTypes.directory)}
              iconButton
              data-testid="current-directory-edit"
            >
              <EditOutlined />
            </SecondaryButton>
          )}
        </Header>
        <DescriptionBox>
          <DescriptionLabel>Description:</DescriptionLabel>
          <BodyText2>{directory.content}</BodyText2>
        </DescriptionBox>
      </CurrentDirectoryInfoWrapper>
    )
  );
};

CurrentDirectoryInfo.propTypes = {
  onEditToggle: PropTypes.func.isRequired,
  directory: PropTypes.shape({
    name: PropTypes.string,
    content: PropTypes.string,
  }).isRequired,
};

export { CurrentDirectoryInfo, DescriptionBox, Title, DescriptionLabel };
