export default {
  operator_code: "",
  reference_code: "",
  name: "",
  contact_email: "",
  contact_phone: "",
  notification_text: "",
  data: {
    rows: [],
  },
};
