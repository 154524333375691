import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { useItemOriginalIndex } from "hooks/useItemOriginalIndex";
import { FlexGrow } from "components/ui/Content";
import { Input } from "components/ui/Forms";
import { AttachFilesButton } from "components/ui/Buttons/AttachFilesButton";
import { ChipWithRedirect } from "components/ui/Chips/ChipWithRedirect";

import { SortableListElement, RemoveButton } from "components/_shared/SortableListElement/SortableListElement";
import { 
  SortableListElementContentWithError 
} from "components/_shared/SortableListElementContentWithError/SortableListElementContentWithError";

import { DOCUMENTS_FILE_TYPES } from "constants/defaults";
import { ERRORS } from "constants/content";
import { useActiveIcon } from "hooks/useActiveIcon";

const Number = styled.span`
  color: ${({ theme }) => theme.colors.grey50};
`;

const DocumentsListItem = ({ index, item, name, onRemove, onChange, onFilesUpload, errors, disabled }) => {
  const documentRef = useRef(null);
  const { name: title, id, file_name } = item;

  const originalIndex = useItemOriginalIndex(id, name, errors);
  const nameError = errors && errors[originalIndex] ? errors[originalIndex].name : null;
  const fileError = errors && errors[originalIndex] ? errors[originalIndex].file : null;

  const hasError = errors && errors[originalIndex];

  const itemIndex = index + 1;
  const activeIcon = useActiveIcon(item);
  const handleChange = ({ target }) => {
    const { value } = target;
    onChange(id, { ...item, name: value });
  };

  const handleRemoveAttachedFile = () => {
    onChange(id, { id, name: title });
  };

  const renderFileUploader = () => (
    <AttachFilesButton
      size="small"
      name={name}
      onSelect={files => onFilesUpload(id, files)}
      allowedFileTypes={DOCUMENTS_FILE_TYPES}
      error={fileError}
      multiUpload
      disabled={disabled}
    />
  );

  const renderFileDeleter = () => (
    <ChipWithRedirect disabled={disabled} onDelete={handleRemoveAttachedFile} item={item} icon={activeIcon} />
  );

  useEffect(() => {
    if (!documentRef.current.value) {
      documentRef.current.focus();
      documentRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, []);
  
  return (
    <SortableListElement disabled={disabled} index={index} withError={!!hasError}>
      <SortableListElementContentWithError error={hasError ? ERRORS.invalidDocumentFile : undefined}>
        <FlexGrow grow={1}>
          <Input
            label="Title"
            value={title}
            onChange={handleChange}
            name={`${name}-document-nr${itemIndex}`}
            inputRef={documentRef}
            size="small"
            error={!!nameError}
            helperText={nameError}
            disabled={disabled}
          />
        </FlexGrow>
        {file_name ? renderFileDeleter() : renderFileUploader()}
        {!disabled && <RemoveButton onClick={() => onRemove(id)} />}
      </SortableListElementContentWithError>
    </SortableListElement>
  );
};

DocumentsListItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string,
    file_name: PropTypes.string,
    error: PropTypes.string,
  }).isRequired,
  index: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  onRemove: PropTypes.func.isRequired,
  onFilesUpload: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  errors: PropTypes.shape().isRequired,
};

export { DocumentsListItem, Number };
