import React, { useContext } from "react";
import styled from "styled-components";
import Grid from "@material-ui/core/Grid";
import ImageOutlinedIcon from "@material-ui/icons/ImageOutlined";
import PropTypes from "prop-types";

import { ChipWithRedirect } from "components/ui/Chips/ChipWithRedirect";
import { Content } from "components/ui/Content";
import { AttachFilesButton } from "components/ui/Buttons/AttachFilesButton";
import { BodyText2 } from "components/ui/Typography/Typography";
import { Message } from "components/ui/Messages/Message";

import { PANEL_TRIPS_CREATE_TEXTS, ERRORS } from "constants/content";
import { formatDisplayableLibraryPopupValues, getFilePreviewUrl } from "utils/library";
import { IMAGES_MIME_TYPES } from "constants/defaults";
import { DEFAULT_LIBRARY_TABS } from "components/ui/LibraryPopup/LibraryPopup";
import { useRefreshedFileUrl } from "hooks/useRefreshedFileUrl";

const StyledWrapper = styled.div`
  border-radius: ${({ theme }) => theme.borderRadius}px;
  border: 1px solid ${({ theme }) => theme.colors.grey10};
  width: 400px;
  height: 750px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 32px;
  position: relative;
  overflow: hidden;

  ${({ background, $localised }) =>
    background &&
    `
      &:before {
        content: '';
        position: absolute !important;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 0;
        background-image: url('${background}');
        background-position: center;
        background-size: cover;

        ${!$localised &&
          `
          opacity: 0.4;
        `}
      }
       
    `};

  & > * {
    position: relative;
    z-index: 1;
  }
`;

const MessageWrapper = styled.div`
  width: 400px;
  margin: 0 auto;
  margin-top: 24px;
`;

const MobilePreview = ({ context, disabled, background, defaultBackground, onBackgroundChange, children }) => {
  const { errors } = useContext(context) || {};
  const backgroundUrl = useRefreshedFileUrl(getFilePreviewUrl(background || defaultBackground));

  const handleAttachFile = files => {
    const [newImage] = formatDisplayableLibraryPopupValues(files);
    onBackgroundChange(newImage);
  };

  const handleDeleteBackground = () => {
    onBackgroundChange(null);
  };

  const renderFileUploader = () => (
    <AttachFilesButton
      name="trip-bg"
      onSelect={handleAttachFile}
      multiUpload={false}
      allowedFileTypes={IMAGES_MIME_TYPES}
      tabs={Object.values(DEFAULT_LIBRARY_TABS).splice(0, 2)}
      disabled={disabled}
    />
  );

  const renderFileDeleter = () => (
    <ChipWithRedirect disabled={disabled} icon={<ImageOutlinedIcon />} item={background} onDelete={handleDeleteBackground} />
  );

  return (
    <Grid container>
      <Grid item xs={12}>
        <Content>
          <Grid container justifyContent="center">
            <StyledWrapper background={backgroundUrl} $localised={!!background}>
              {children}
            </StyledWrapper>
            {errors?.background && (
              <MessageWrapper>
                <Message text={ERRORS.isRequired("Background")} type="error" />
              </MessageWrapper>
            )}
          </Grid>
        </Content>
      </Grid>
      <Grid item xs={12}>
        <Content margin={4}>
          <Grid container justifyContent="center">
            {background ? renderFileDeleter() : renderFileUploader()}
          </Grid>
        </Content>
        <Content noMargin>
          <BodyText2 align="center">
            {PANEL_TRIPS_CREATE_TEXTS.preview.line1}
            <br />
            {PANEL_TRIPS_CREATE_TEXTS.preview.line2}
            <br />
            {PANEL_TRIPS_CREATE_TEXTS.preview.line3}
          </BodyText2>
        </Content>
      </Grid>
    </Grid>
  );
};

MobilePreview.defaultProps = {
  disabled: false,
  background: null,
  defaultBackground: null,
};

MobilePreview.propTypes = {
  context: PropTypes.shape({}).isRequired,
  disabled: PropTypes.bool,
  background: PropTypes.shape({}),
  defaultBackground: PropTypes.shape({}),
  onBackgroundChange: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export { MobilePreview, StyledWrapper };
