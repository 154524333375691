import React from "react";
import Collapse from "@material-ui/core/Collapse";
import PropTypes from "prop-types";
import styled from "styled-components";
import { TextEditor } from "components/ui/Forms/TextEditor";
import { Input } from "components/ui/Forms";
import { Content } from "components/ui/Content/Content";
import { GreySection } from "./GreySection";

const HeadlineWrapper = styled(Content)`
  && {
    background: ${({ theme }) => theme.colors.white};
  }
`;

const PageEditorField = ({ open, onChange, text, headline }) => {
  const handleHeadlineChange = ({ target }) => onChange("headline", target.value);
  const handleEditorChange = value => {
    onChange("body", value);
  };

  const plugins = [
    "advlist autolink lists link charmap print preview anchor nonbreaking",
    "searchreplace visualblocks",
    "insertdatetime media table paste code help wordcount",
  ];

  return (
    <Collapse in={open}>
      <GreySection>
        <HeadlineWrapper>
          <Input label="Headline" type="text" value={headline} onChange={handleHeadlineChange} name="page-headline" size="small" />
        </HeadlineWrapper>
        <TextEditor
          onChange={handleEditorChange}
          value={text}
          plugins={plugins}
          fontFormats="Lato=lato, Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats"
          contentStyle="@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap'); body {font-family: Lato}"
        />
      </GreySection>
    </Collapse>
  );
};

PageEditorField.propTypes = {
  open: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  headline: PropTypes.string.isRequired,
};

export { PageEditorField };
