import { deviceType } from "./deviceDetect";

const lsVarName = "v_tables";

const getRequiredData = tableId => {
  const tables = JSON.parse(localStorage.getItem(lsVarName)) || {};
  const device = deviceType();
  const currentTable = tableId || {};

  return { tables, device, currentTable };
};

const setData = (tableId, data) => {
  const { tables, device, currentTable } = getRequiredData(tableId);

  tables[device] = {
    ...(tables[device] || {}),
    [currentTable]: {
      ...(tables[device]?.[currentTable] || {}),
      ...data,
    },
  };

  localStorage.setItem(lsVarName, JSON.stringify(tables));
};

export const saveColumnsToLS = (columns, tableId) => setData(tableId, { columns });

export const saveResultPerPage = (value, tableId) => setData(tableId, { resultsPerPage: value });

export const saveOrdering = ({ column, order }, tableId) => setData(tableId, { ordering: { column, order } });
