import { BRANDING_LABELS } from "constants/content";
import { takeEvery, put, call, select } from "redux-saga/effects";
import { BrandService } from "services/BrandService";
import { setNotification } from "store/app/actions";
import * as actionTypes from "store/branding/actionTypes";
import { formatDisplayableLibraryPopupValues } from "utils/library";
import { handleErrorMessages } from "utils/store";
import {
  createNewBrandFail,
  createNewBrandSuccess,
  getBrandingListFail,
  getBrandingListSuccess,
  getSelectedBrandDataFail,
  getSelectedBrandDataSuccess,
  updateBrandItemFail,
  updateBrandItemSuccess,
} from "./actions";

const prepareUploadedFile = files => {
  const [newImage] = formatDisplayableLibraryPopupValues(files);

  return {
    name: newImage.file_name,
    file: newImage,
    remote_url: newImage?.preview_url || newImage?.https_url,
    mime_type: newImage.type,
  };
};

function* getDefaultBrandData() {
  const brandService = new BrandService();

  try {
    const data = yield brandService.getDefaultBrandData();

    if (data) {
      yield put(getSelectedBrandDataSuccess(data));
    }
  } catch (e) {
    const error = handleErrorMessages(e);
    yield put(getSelectedBrandDataFail(error));
  }
}

function* getBrandById({ payload }) {
  const brandService = new BrandService();

  try {
    const data = yield brandService.getBrandById(payload);

    if (data) {
      yield put(getSelectedBrandDataSuccess(data));
    }
  } catch (e) {
    const error = handleErrorMessages(e);
    yield put(getSelectedBrandDataFail(error));
  }
}

function* getBrandingsList() {
  const brandService = new BrandService();

  const brandId = yield select(({ branding }) => branding.brandingForm.id);

  try {
    const data = yield brandService.getBrandsList();

    if (data) {
      yield put(getBrandingListSuccess(data));

      if (!brandId) {
        yield call(getDefaultBrandData);
      }
    }
  } catch (e) {
    const error = handleErrorMessages(e);
    
    yield put(
      setNotification({
        type: "error",
        message: error,
      }),
    );
    yield put(getBrandingListFail(error));
  }
}

function* createNewBrand({ payload }) {
  const brandService = new BrandService();

  try {
    const data = yield brandService.createNewBrand(payload.name);

    if (data?.id) {
      yield put(setNotification({ type: "success", message: BRANDING_LABELS.notifications.create }));
      yield put(createNewBrandSuccess());
      yield call(getBrandingsList);
      yield call(getBrandById, { payload: data.id });
    }
  } catch (e) {
    const error = handleErrorMessages(e);

    yield put(createNewBrandFail({ error }));
    yield put(setNotification({ type: "error", message: BRANDING_LABELS.notifications.createFail }));
  }
}

function* setValueForCreationHeader({ payload }) {
  const brandService = new BrandService();
  const form = yield select(state => state.branding.brandingForm);
  const creationsHeader = payload ? prepareUploadedFile(payload) : null;
  const formData = {
    ...form,
    creationsHeader,
  };

  try {
    const brand = yield brandService.updateBrand(formData);

    if (brand) {
      yield put(updateBrandItemSuccess());
      yield call(getBrandById, { payload: formData.id });
      yield put(setNotification({ type: "success", message: BRANDING_LABELS.notifications.update }));
    }
  } catch (e) {
    const error = handleErrorMessages(e);
    yield put(updateBrandItemFail(error));
    yield put(setNotification({ type: "error", message: BRANDING_LABELS.notifications.updateFail }));
  }
}

function* setValueForCreationFooter({ payload }) {
  const brandService = new BrandService();
  const form = yield select(state => state.branding.brandingForm);
  const creationsFooter = payload ? prepareUploadedFile(payload) : null;
  const formData = {
    ...form,
    creationsFooter,
  };

  try {
    const brand = yield brandService.updateBrand(formData);

    if (brand) {
      yield put(updateBrandItemSuccess());
      yield put(setNotification({ type: "success", message: BRANDING_LABELS.notifications.update }));
      yield call(getBrandById, { payload: formData.id });
    }
  } catch (e) {
    const error = handleErrorMessages(e);
    yield put(updateBrandItemFail(error));
    yield put(setNotification({ type: "error", message: BRANDING_LABELS.notifications.updateFail }));
  }
}
function* setValueForLogo({ payload }) {
  const brandService = new BrandService();
  const form = yield select(state => state.branding.brandingForm);
  const logo = payload ? prepareUploadedFile(payload) : null;
  const formData = {
    ...form,
    logo,
  };

  try {
    const brand = yield brandService.updateBrand(formData);

    if (brand) {
      yield put(updateBrandItemSuccess());
      yield put(setNotification({ type: "success", message: BRANDING_LABELS.notifications.update }));
      yield call(getBrandById, { payload: formData.id });
    }
  } catch (e) {
    const error = handleErrorMessages(e);
    yield put(updateBrandItemFail(error));
    yield put(setNotification({ type: "error", message: BRANDING_LABELS.notifications.updateFail }));
  }
}

function* setValueForDefaultLogo({ payload }) {
  const brandService = new BrandService();
  const form = yield select(state => state.branding.brandingForm);
  const logo = payload ? prepareUploadedFile(payload) : null;
  const formData = {
    ...form,
    logo,
  };

  try {
    const brand = yield brandService.updateDefaultBrand(formData);
    if (brand) {
      yield put(updateBrandItemSuccess());
      yield put(setNotification({ type: "success", message: BRANDING_LABELS.notifications.update }));
      yield call(getDefaultBrandData);
    }
  } catch (e) {
    const error = handleErrorMessages(e);
    yield put(updateBrandItemFail(error));
    yield put(setNotification({ type: "error", message: BRANDING_LABELS.notifications.updateFail }));
  }
}

function* setValueForDefaultCreationFooter({ payload }) {
  const brandService = new BrandService();
  const form = yield select(state => state.branding.brandingForm);
  const creationsFooter = payload ? prepareUploadedFile(payload) : null;
  const formData = {
    ...form,
    creationsFooter,
  };

  try {
    const brand = yield brandService.updateDefaultBrand(formData);
    if (brand) {
      yield put(updateBrandItemSuccess());
      yield put(setNotification({ type: "success", message: BRANDING_LABELS.notifications.update }));
      yield call(getDefaultBrandData);
    }
  } catch (e) {
    const error = handleErrorMessages(e);
    yield put(updateBrandItemFail(error));
    yield put(setNotification({ type: "error", message: BRANDING_LABELS.notifications.updateFail }));
  }
}

function* setValueForDefaultCreationHeader({ payload }) {
  const brandService = new BrandService();
  const form = yield select(state => state.branding.brandingForm);
  const creationsHeader = payload ? prepareUploadedFile(payload) : null;
  const formData = {
    ...form,
    creationsHeader,
  };

  try {
    const brand = yield brandService.updateDefaultBrand(formData);
    if (brand) {
      yield put(updateBrandItemSuccess());
      yield put(setNotification({ type: "success", message: BRANDING_LABELS.notifications.update }));
      yield call(getDefaultBrandData);
    }
  } catch (e) {
    const error = handleErrorMessages(e);
    yield put(updateBrandItemFail(error));
    yield put(setNotification({ type: "error", message: BRANDING_LABELS.notifications.updateFail }));
  }
}

function* setValueForDefaultCreationLink({ payload }) {
  const brandService = new BrandService();
  const form = yield select(state => state.branding.brandingForm);

  const formData = {
    ...form,
    [payload.linkType]: payload.value
  }

  try {
    const brand = yield brandService.updateDefaultBrand(formData);

    if (brand) {
      yield put(updateBrandItemSuccess());
      yield put(setNotification({ type: "success", message: BRANDING_LABELS.notifications.update }));
      yield call(getDefaultBrandData);
    }
  } catch (e) {
    const error = handleErrorMessages(e);
    yield put(updateBrandItemFail(error));
    yield put(setNotification({ type: "error", message: BRANDING_LABELS.notifications.updateFail }));
  }
}

function* setValueForCreationLink({ payload }) {
  const brandService = new BrandService();
  const form = yield select(state => state.branding.brandingForm);

  const formData = {
    ...form,
    [payload.linkType]: payload.value
  }

  try {
    const brand = yield brandService.updateBrand(formData);

    if (brand) {
      yield put(updateBrandItemSuccess());
      yield put(setNotification({ type: "success", message: BRANDING_LABELS.notifications.update }));
      yield call(getBrandById, { payload: formData.id });
    }
  } catch (e) {
    const error = handleErrorMessages(e);
    yield put(updateBrandItemFail(error));
    yield put(setNotification({ type: "error", message: BRANDING_LABELS.notifications.updateFail }));
  }
}

export function* brandingSaga() {
  yield takeEvery(actionTypes.GET_BRANDING_LIST_START, getBrandingsList);
  yield takeEvery(actionTypes.GET_SELECTED_BRANDING_DATA_START, getBrandById);
  yield takeEvery(actionTypes.GET_DEFAULT_BRANDING_DATA_START, getDefaultBrandData);
  yield takeEvery(actionTypes.CREATE_NEW_BRAND_START, createNewBrand);
  yield takeEvery(actionTypes.SET_VALUE_FOR_LOGO_START, setValueForLogo);
  yield takeEvery(actionTypes.SET_VALUE_FOR_CREATION_FOOTER_START, setValueForCreationFooter);
  yield takeEvery(actionTypes.SET_VALUE_FOR_CREATION_HEADER_START, setValueForCreationHeader);
  yield takeEvery(actionTypes.SET_VALUE_FOR_CREATION_LINK_START, setValueForCreationLink);
  yield takeEvery(actionTypes.SET_VALUE_FOR_DEFAULT_CREATION_LINK_START, setValueForDefaultCreationLink);

  yield takeEvery(actionTypes.SET_VALUE_FOR_DEFAULT_LOGO_START, setValueForDefaultLogo);
  yield takeEvery(actionTypes.SET_VALUE_FOR_DEFAULT_CREATION_FOOTER_START, setValueForDefaultCreationFooter);
  yield takeEvery(actionTypes.SET_VALUE_FOR_DEFAULT_CREATION_HEADER_START, setValueForDefaultCreationHeader);
}
