import React, { useState, useContext, useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Grid, DialogContent } from "@material-ui/core";

import { TableContext } from "components/ui/Tables";
import { CaptionText } from "components/ui/Typography/Typography";
import { setNewOrder } from "utils/sortable";
import { ListOfColumns } from "components/_shared/ColumnSelector/ListOfColumns";
import { ColumnSelectorContext } from "components/_shared/ColumnSelector/ColumnSelectorContext";
import { Message } from "components/ui/Messages/Message";
import { ConfirmationModal } from "components/ui/Modals/ConfirmationModal";
import { deviceType } from "utils/deviceDetect";
import { useDispatch } from "react-redux";
import { setNotification } from "store/app/actions";

const StyledDialogContent = styled(DialogContent)`
  && {
    padding: 0;
  }
`;

const SelectedCounter = styled(CaptionText)`
  && {
    position: absolute;
    top: ${({ theme }) => theme.setSpacing(6)}px;
    right: ${({ theme }) => theme.setSpacing(6)}px;
  }
`;

const MessageWrapper = styled.div`
  margin-top: ${({ theme }) => theme.setSpacing(6)}px;
`;

const ColumnSelector = ({ open, onClose, onSave, list, limit, tableId }) => {
  const dispatch = useDispatch();
  const { headers } = useContext(TableContext);
  const [columns, setColumns] = useState([]);
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [numberOfSelectedColumns, setNumberOfSelectedColumns] = useState(0);

  const device = deviceType();
  const data = headers[device] || headers[headers.defaultDevice] || headers.all;
  const setupNumberOfSelectedColumnsFromHeaders = () => {
    const selectedColumnsNumber = data?.filter(header => !!header.show).length;
    setNumberOfSelectedColumns(selectedColumnsNumber);
  };

  const setupNumberOfSelectedColumns = () => {
    setNumberOfSelectedColumns(selectedColumns.length);
  };

  const toggleCheck = (key, value) => {
    if (value === true) {
      setSelectedColumns([...selectedColumns, key]);
    } else {
      const index = selectedColumns.indexOf(key);
      if (index > -1) {
        selectedColumns.splice(index, 1);
        setSelectedColumns([...selectedColumns]);
      }
    }
  };

  const resolveDisabled = key => selectedColumns.length >= limit && !selectedColumns.includes(key);

  const init = () => {
    setupNumberOfSelectedColumnsFromHeaders();
    setColumns(list);
    const cols = data?.filter(header => header.show);
    const selectedCols = [];
    if (cols?.length > 0) {
      cols.forEach(col => selectedCols.push(col.key));
      setSelectedColumns([...selectedCols]);
    }
  };

  const toggleColumnSelection = key => selectedColumns.includes(key);

  const handleChangeOrder = ({ newIndex, oldIndex }) => {
    setColumns(setNewOrder(newIndex, oldIndex, columns));
  };

  useEffect(setupNumberOfSelectedColumns, [selectedColumns]);
  useEffect(init, [headers]);

  const context = {
    toggleColumnSelection,
    resolveDisabled,
    toggleCheck,
    handleChangeOrder,
  };

  const isMaxColumnsNumberReached = numberOfSelectedColumns >= limit;
  const maxColumnsReachedMessage = isMaxColumnsNumberReached && (
    <MessageWrapper>
      <Message type="info" text="Maximum number of columns selected. To choose another, first deselect one." />
    </MessageWrapper>
  );
  return open ? (
    <ConfirmationModal
      open={open}
      maxWidth="sm"
      fullWidth
      onCancel={onClose}
      title="Edit titles"
      onConfirm={() => {
        if (selectedColumns.length) {
          onSave(selectedColumns, columns);
        } else {
          dispatch(setNotification({ type: "error", message: "Select at least on column" }));
        }
      }}
    >
      <SelectedCounter>{`${numberOfSelectedColumns || 0}/ ${limit}`}</SelectedCounter>
      <StyledDialogContent>
        {columns && (
          <Grid container>
            <Grid item xs={12}>
              <ColumnSelectorContext.Provider value={context}>
                <ListOfColumns columns={columns} />
              </ColumnSelectorContext.Provider>
            </Grid>
          </Grid>
        )}
        {maxColumnsReachedMessage}
      </StyledDialogContent>
    </ConfirmationModal>
  ) : null;
};

ColumnSelector.defaultProps = {
  tableId: null,
};

ColumnSelector.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  tableId: PropTypes.string,
  list: PropTypes.object.isRequired,
  limit: PropTypes.number.isRequired,
};

export { ColumnSelector, StyledDialogContent, SelectedCounter };
