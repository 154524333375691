import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { Grid } from "@material-ui/core";
import { delay } from "lodash";

import { CancelHistoryButton } from "components/ui/Buttons";
import { ContextBarTitle } from "components/templates/Panel/ContextBarTitle";
import { AdminTemplate } from "components/templates/Admin/AdminTemplate";
import { Spinner } from "components/ui/Spinner/Spinner";
import { ADMIN_ICONS_PATH } from "constants/routes";
import { UPDATE_ICONS_START, GET_PUBLIC_ICONS_START } from "store/icons/actionTypes";
import { getPublicIconsStart, updateIconsStart, addNewIcon, deleteIcon, toggleIconFormTouched } from "store/icons/actions";
import { isString } from "contracts/types";
import { ADMIN_ICONS_CONTENT, ERRORS } from "constants/content";
import { SaveAdminButton } from "feature/admin/_shared/SaveAdminButton";
import { usePermissionsService } from "hooks/usePermissionsService";

import { PERMISSIONS } from "constants/permissions";
import { useExtendedNavigate } from "hooks/useExtendedNavigate";

import { DefaultIconSection } from "./DefaultIconSection";
import { OtherIconsSection } from "./OtherIconsSection";

const SpinnerWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: ${({ theme }) => theme.setSpacing(6)}px 0;
`;

/**
 * @param {object} icons
 * @param {Icon[]} icons.changedIcons
 * @param {Icon[]} icons.addedIcons
 * @param {Icon[]} icons.listOfIcons
 */
const validate = ({ changedIcons, addedIcons }) => {
  /**
   * @type {{ [key: string]: string[]; }}
   */
  const errors = {};

  [...changedIcons, ...addedIcons].forEach(icon => {
    if (!icon.name) {
      if (errors[icon.id]) {
        errors[icon.id].name = ERRORS.isRequired(ADMIN_ICONS_CONTENT.title);
      } else {
        errors[icon.id] = {
          name: ERRORS.isRequired(ADMIN_ICONS_CONTENT.title),
        };
      }
    } else if (icon.name.length > 20) {
      if (errors[icon.id]) {
        errors[icon.id].name = ERRORS.isTooLong(20);
      } else {
        errors[icon.id] = {
          name: ERRORS.isTooLong(20),
        };
      }
    }

    if (!icon.image) {
      if (errors[icon.id]) {
        errors[icon.id].image = ERRORS.isRequired(ADMIN_ICONS_CONTENT.image);
      } else {
        errors[icon.id] = {
          image: ERRORS.isRequired(ADMIN_ICONS_CONTENT.image),
        };
      }
    }
  });

  return errors;
};

const EditIcons = () => {
  const navigate = useExtendedNavigate();
  const permissionsService = usePermissionsService();
  const [changedIcons, setChangedIcons] = useState([]);
  const [deletedIcons, setDeletedIcons] = useState([]);
  const [addedIcons, setAddedIcons] = useState([]);
  const [errorsList, setErrorsList] = useState({});

  const inProgress = useSelector(state => state.icons.inProgress);
  const actionType = useSelector(state => state.icons.actionType);
  const isIconFormTouched = useSelector(state => state.icons.isIconFormTouched);

  const dispatch = useDispatch();

  const canUserDeleteIcon = permissionsService.can(PERMISSIONS.actions.delete, PERMISSIONS.sections.admin, PERMISSIONS.resources.icons);

  const canUserCreateIcon = permissionsService.can(PERMISSIONS.actions.create, PERMISSIONS.sections.admin, PERMISSIONS.resources.icons);

  const canUserUpdateIcon = permissionsService.can(PERMISSIONS.actions.update, PERMISSIONS.sections.admin, PERMISSIONS.resources.icons);
  const handleSave = () => {
    const errors = validate({ changedIcons, addedIcons });
    if (Object.keys(errors).length === 0) {
      setErrorsList({});
      dispatch(
        updateIconsStart({
          changedIcons,
          deletedIcons,
          addedIcons,
        }),
      );
      setAddedIcons([]);
      setChangedIcons([]);
      setDeletedIcons([]);
    } else {
      setErrorsList(errors);
    }
  };

  const getIcons = () => {
    dispatch(getPublicIconsStart());
  };

  const handleIconAdd = newIcon => {
    setAddedIcons([...addedIcons, newIcon]);
    dispatch(addNewIcon(newIcon));
    if (!isIconFormTouched) {
      dispatch(toggleIconFormTouched(true));
    }
  };

  const handleIconDelete = deletedIcon => {
    if (!isString(deletedIcon.id)) {
      setDeletedIcons([...deletedIcons, deletedIcon]);
    }
    setAddedIcons(addedIcons.filter(icon => icon.id !== deletedIcon.id));
    setChangedIcons(changedIcons.filter(icon => icon.id !== deletedIcon.id));
    dispatch(deleteIcon(deletedIcon.id));
    if (!isIconFormTouched) {
      dispatch(toggleIconFormTouched(true));
    }
  };

  const handleIconChange = changedIcon => {
    if (isString(changedIcon.id)) {
      const newAddedIconsList = addedIcons.map(icon => (icon.id === changedIcon.id ? changedIcon : icon));
      setAddedIcons(newAddedIconsList);
    } else {
      const isAllreadyChanged = changedIcons.some(icon => icon.id === changedIcon.id);
      const newChangedIconsList = isAllreadyChanged
        ? changedIcons.map(icon => (icon.id === changedIcon.id ? changedIcon : icon))
        : [...changedIcons, changedIcon];
      setChangedIcons(newChangedIconsList);
      if (!isIconFormTouched) {
        dispatch(toggleIconFormTouched(true));
      }
    }
  };

  const handleCancelButton = () => {
    dispatch(toggleIconFormTouched(false));
    navigate(ADMIN_ICONS_PATH);
  };

  const contextBar = {
    left: () => <CancelHistoryButton role="link" clickHandler={handleCancelButton} />,
    middle: () => <ContextBarTitle title={ADMIN_ICONS_CONTENT.editListOfPoiIcons} />,
    right: () => (
      <>
        {(canUserDeleteIcon || canUserCreateIcon || canUserUpdateIcon) && (
          <SaveAdminButton
            showSpinner={inProgress && actionType === UPDATE_ICONS_START}
            disabled={![...changedIcons, ...deletedIcons, ...addedIcons].length}
            onClick={handleSave}
          />
        )}
      </>
    ),
  };

  const renderContent = () => {
    if (inProgress && actionType === GET_PUBLIC_ICONS_START)
      return (
        <SpinnerWrapper>
          <Spinner />
        </SpinnerWrapper>
      );

    return (
      <>
        <DefaultIconSection errors={errorsList} onChange={handleIconChange} />
        <OtherIconsSection errors={errorsList} onChange={handleIconChange} onAdd={handleIconAdd} onDelete={handleIconDelete} />
      </>
    );
  };

  useEffect(getIcons, []);
  useEffect(() => {
    delay(() => {
      if (addedIcons.length > 0) {
        window.scrollTo({ top: document.body.scrollHeight, behavior: "smooth" });
      }
    }, 100);
  }, [addedIcons.length]);

  return (
    <AdminTemplate contextBar={contextBar} whiteBg>
      <Grid container justifyContent="center">
        <Grid item sm={10}>
          <Grid container justifyContent="center">
            <Grid item md={8}>
              {renderContent()}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </AdminTemplate>
  );
};

export { EditIcons };
