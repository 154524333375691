import {
  FILE_S3_INIT_URL,
  LIBRARY_FILE_LIST_URL,
  LIBRARY_FILE_URL,
  LIBRARY_NODE_UPDATE_DELETE_URL,
  LIBRARY_POPUP_FILES_URL,
  LIBRARY_ROOT_PATH,
  SEARCH_NODES_URL,
} from "constants/api";
import { HttpClient } from "services/application/httpClient/httpClient";
import { generateFileOrWebUrlKey } from "utils/generateFileOrWebUrlKey";
import { encodeParameter, setUrl, setUrlParams } from "utils/url";

export class LibraryRepository {
  constructor() {
    /** @type {HttpClient} */
    this.httpClient = HttpClient;
  }

  async getListOfFiles(page, rowsPerPage, path, sortingOrder, sortingBy, search = null) {
    const url = setUrlParams(LIBRARY_FILE_LIST_URL, {
      page,
      count: rowsPerPage,
      path,
      order: sortingOrder,
      order_by: sortingBy,
      search,
    });

    const { data } = await this.httpClient.get(url);

    return data;
  }

  async getAllItemsFromDirectory(path) {
    const url = setUrl(LIBRARY_POPUP_FILES_URL, { path }, true);

    const { data } = await this.httpClient.get(url);

    return data;
  }

  async searchItems(query) {
    const url = setUrl(SEARCH_NODES_URL, { query });

    const { data } = await this.httpClient.get(url);

    return data;
  }

  async createFolder(name, path) {
    const folderPathFromRoot = decodeURIComponent(path || "");
    const body = {
      path: `${LIBRARY_ROOT_PATH}${folderPathFromRoot}/${name}`,
      new_only: true,
    };

    const { data } = await this.httpClient.post(LIBRARY_FILE_URL, body);

    return data;
  }

  async uploadFileToS3(file) {
    const { data } = await this.httpClient.post(FILE_S3_INIT_URL, {
      filename: file.name,
      content_type: file.type,
    });

    await this.httpClient.put(data.url, file, {
      headers: {
        "Content-Type": file.type,
      },
    });

    const s3preparedUrl = data.s3url.replace(/^.+\/(.+?)$/, (matched, filename, offset, str) => {
      let name = null;
      let ext = null;

      if (filename.includes(".")) {
        [, name, ext] = /(.+)\.([a-zA-Z0-9]+?)$/.exec(filename);
      } else {
        name = filename;
      }

      return str.replace(filename, encodeParameter(name)) + (ext ? `.${ext}` : "");
    });

    return {
      file_url: s3preparedUrl,
      preview_url: URL.createObjectURL(file),
      type: file.type,
    };
  }

  async addFile(folderPath, url, remote_url, file_name, options) {
    const folderPathFromRoot = decodeURIComponent(folderPath || "");
    const body = {
      ...options,
      path: `${LIBRARY_ROOT_PATH}${folderPathFromRoot}/${file_name}`,
      [generateFileOrWebUrlKey(url)]: url || remote_url,
    };

    const { data } = await this.httpClient.post(LIBRARY_FILE_URL, body);

    return data;
  }

  async removeItem(item) {
    const { id } = item;
    const url = setUrl(LIBRARY_NODE_UPDATE_DELETE_URL, { id });

    await this.httpClient.delete(url);
  }

  async updateItem({ icon_id, id, path, url, remote_url }) {
    const requestUrl = setUrl(LIBRARY_NODE_UPDATE_DELETE_URL, { id });
    const body = { path, url, remote_url };

    if (icon_id) {
      body.icon_id = icon_id;
    }

    const { data } = await this.httpClient.post(requestUrl, body);

    return data;
  }
}
