const filterOptions = (options, value) =>
  options &&
  options.filter(option => {
    const optionValue = option.value.toLowerCase();
    const optionLabel = option.label.toLowerCase();
    const inputValue = value && value.toLowerCase();

    return (optionValue.includes(inputValue) || optionLabel.includes(inputValue));
  });

export { filterOptions };
