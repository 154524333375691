import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import Grid from "@material-ui/core/Grid";
import AddIcon from "@material-ui/icons/Add";

import { Link } from "components/ui/Links/Link";
import { PanelTemplate } from "components/templates/Panel/PanelTemplate";
import { PrimaryButton } from "components/ui/Buttons";
import { EditAction, Table, TableBody, TableHeader, TablePagination } from "components/ui/Tables";

import { PANEL_INSPIRATIONS_CREATE_PATH, PANEL_INSPIRATIONS_EDIT_PATH } from "constants/routes";

import inspirationTableColumns from "feature/panel/Inspirations/List/inspirationTableColumns";
import { InspirationRowTemplate } from "feature/panel/Inspirations/List/InspirationRowTemplate";
import { SharedNavigation } from "feature/panel/_shared/Navigations/SharedNavigation";

import { NoResultsMessage } from "components/ui/Messages/NoResultsMessage";
import { LoadingScreen } from "components/ui/LoadingScreen/LoadingScreen";

import { useTableHandlers } from "hooks/useTableHandlers";

import { CREATE_CONTENT_LABELS, EMPTY_LIST_MESSAGES_BASE, NAMES_OF_RESOURCES_LISTS, TABLE_ACTIONS_TITLES } from "constants/content";

import { setUrl } from "utils/url";
import { useNavigate } from "react-router-dom";
import { TableActionsContainer } from "components/ui/Tables/TableRowActions";
import { usePermissionsService } from "hooks/usePermissionsService";
import { PERMISSIONS } from "constants/permissions";
import { useService } from "hooks/useService";
import { InspirationService } from "services/InspirationService";

const EditInspirationButton = ({ item }) => {
  const permissionsService = usePermissionsService();
  const { reference_code, operator_code } = item;
  const navigate = useNavigate();
  const url = setUrl(PANEL_INSPIRATIONS_EDIT_PATH, { userId: operator_code, passcode: reference_code }, true);
  const title = TABLE_ACTIONS_TITLES.editResource(TABLE_ACTIONS_TITLES.resourceTypes.inspiration);

  return (
    <TableActionsContainer>
      {permissionsService.can(PERMISSIONS.actions.update, PERMISSIONS.sections.operator, PERMISSIONS.resources.inspirations) && (
        <EditAction clickAction={() => navigate(url)} tooltip={title} />
      )}
    </TableActionsContainer>
  );
};

EditInspirationButton.propTypes = {
  item: PropTypes.shape({
    reference_code: PropTypes.string.isRequired,
    operator_code: PropTypes.string.isRequired,
  }).isRequired,
};

const InspirationsList = ({ hasPermission }) => {
  const permissionsService = usePermissionsService();
  const inspirationService = useService(InspirationService);

  const [inspirations, setInspirations] = useState([]);
  const [count, setCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const { 
    rowsPerPage,
    page,
    sortingOrder,
    sortingBy,
    handleChangeOrder,
    handleChangeRowsPerPage,
    handleChangePage,
  } = useTableHandlers();

  const fetchInspirations = async () => {
    setIsLoading(true);

    const { items, total_matches } = await inspirationService.getList(
      page,
      rowsPerPage,
      sortingOrder,
      sortingBy
    );

    setInspirations(items);
    setCount(total_matches);
    setIsLoading(false);
  }

  const canCreateInspiration = permissionsService.can(
    PERMISSIONS.actions.create,
    PERMISSIONS.sections.operator,
    PERMISSIONS.resources.inspirations,
  );

  const contextBar = {
    left: () => null,
    middle: SharedNavigation,
    right: () =>
      canCreateInspiration && (
        <Link to={PANEL_INSPIRATIONS_CREATE_PATH}>
          <PrimaryButton>
            <AddIcon />
            <span>{CREATE_CONTENT_LABELS.inspiration}</span>
          </PrimaryButton>
        </Link>
      ),
  };

  const mainContent = (() => {
    if (isLoading && !inspirations.length) return <LoadingScreen />;
    if (!inspirations.length && !canCreateInspiration) return null;
    if (!inspirations.length && canCreateInspiration)
      return (
        <NoResultsMessage height="60vh">
          {EMPTY_LIST_MESSAGES_BASE(CREATE_CONTENT_LABELS.inspiration, NAMES_OF_RESOURCES_LISTS.inspiration)}
        </NoResultsMessage>
      );
    return (
      <Table
        headers={inspirationTableColumns}
        list={inspirations}
        actions={EditInspirationButton}
        onChangeOrder={handleChangeOrder}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        count={count}
        isDataLoading={isLoading}
      >
        <TableHeader withActions />
        <TableBody rowComponent={InspirationRowTemplate} />
        <TablePagination count={count} page={page - 1} rowsPerPage={rowsPerPage} />
      </Table>
    );
  })();

  useEffect(() => {
    fetchInspirations();
  }, [page, rowsPerPage, sortingBy, sortingOrder]);

  return (
    <PanelTemplate hasPermission={hasPermission} contextBar={contextBar}>
      <Grid container justifyContent="center">
        <Grid item xs={12} md={10}>
          {mainContent}
        </Grid>
      </Grid>
    </PanelTemplate>
  );
};

InspirationsList.propTypes = {
  hasPermission: PropTypes.bool.isRequired,
}

export { InspirationsList };
