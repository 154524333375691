import * as actionTypes from "store/app/actionTypes";
import { createAction } from "utils/store";

export const clearErrorsFor = payload => ({
  type: actionTypes.CLEAR_ERRORS,
  payload: { module: payload },
});

export const clearActionFlags = payload => ({
  type: actionTypes.CLEAR_ACTION_FLAGS,
  payload: { module: payload },
});

export const clearNotification = createAction(actionTypes.CLEAR_NOTIFICATION);
export const setNotification = createAction(actionTypes.SET_NOTIFICATION);

export const pushSuccessNotification = createAction(actionTypes.PUSH_SUCCESS_NOTIFICATION);
export const pushErrorNotification = createAction(actionTypes.PUSH_ERROR_NOTIFICATION);

export const showSavingCover = createAction(actionTypes.SHOW_SAVING_COVER);
export const hideSavingCover = createAction(actionTypes.HIDE_SAVING_COVER);

export const clearAllStores = createAction(actionTypes.CLEAR_ALL_STORES);

export const changeOperatorInitToggle = createAction(actionTypes.CHANGE_OPERATOR_INIT_TOGGLE);

export const changeOperatorStart = createAction(actionTypes.CHANGE_OPERATOR_START);
export const changeOperatorSuccess = createAction(actionTypes.CHANGE_OPERATOR_SUCCESS);
export const changeOperatorFail = createAction(actionTypes.CHANGE_OPERATOR_FAIL);
export const clearChangeOperatorFlags = createAction(actionTypes.CLEAR_CHANGE_OPERATOR_FLAGS);

export const toggleTripRedirectChecked = createAction(actionTypes.TOGGLE_TRIP_REDIRECT_CHECKED);

export const tryRouteChangeStart = createAction(actionTypes.TRY_ROUTE_CHANGE_START);
export const tryRouteChangeFinish = createAction(actionTypes.TRY_ROUTE_CHANGE_FINISH);

export const getApiKeysStart = createAction(actionTypes.GET_API_KEYS_START);
export const getApiKeysSuccess = createAction(actionTypes.GET_API_KEYS_SUCCESS);
export const getApiKeysFail = createAction(actionTypes.GET_API_KEYS_FAIL);

export const createApiKeyStart = createAction(actionTypes.CREATE_API_KEY_START);
export const createApiKeySuccess = createAction(actionTypes.CREATE_API_KEY_SUCCESS);
export const createApiKeyFail = createAction(actionTypes.CREATE_API_KEY_FAIL);

export const deleteApiKeyStart = createAction(actionTypes.DELETE_API_KEY_START);
export const deleteApiKeySuccess = createAction(actionTypes.DELETE_API_KEY_SUCCESS);
export const deleteApiKeyFail = createAction(actionTypes.DELETE_API_KEY_FAIL);

export const deleteUserFromOperatorStart = createAction(actionTypes.DELETE_USER_FROM_OPERATOR_START);
export const deleteUserFromOperatorSuccess = createAction(actionTypes.DELETE_USER_FROM_OPERATOR_SUCCESS);
export const deleteUserFromOperatorFail = createAction(actionTypes.DELETE_USER_FROM_OPERATOR_FAIL);

export const setErrors = createAction(actionTypes.SET_ERRORS);
