import React, { useReducer, useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { PanelTemplate } from "components/templates/Panel/PanelTemplate";
import { ContextBarTitle } from "components/templates/Panel/ContextBarTitle";
import { BackHistoryButton, CancelHistoryButton, PrimaryButton } from "components/ui/Buttons";
import { LoadingScreen } from "components/ui/LoadingScreen/LoadingScreen";

import { useExtendedNavigate } from "hooks/useExtendedNavigate";
import { useTitle } from "hooks/useTitle";
import { usePayloadValidator } from "hooks/usePayloadValidator";

import { tripStateReducer } from "feature/panel/Trips/_shared/tripStateReducer";
import { ManageTripContext } from "feature/panel/Trips/_shared/ManageTripContext";
import { tripInitialState } from "feature/panel/Trips/_shared/tripInitialState";
import { createTripHandler } from "feature/panel/Trips/_shared/helpers";

import {
  createDefaultGpsNotifications,
  createDefaultTimedNotifications,
  createInspirationNotification,
  filterOutExistingNotifications,
} from "utils/notifications";

import { CREATE_OR_UPDATE_TRIP_START, CREATE_OR_UPDATE_TRIP_SUCCESS } from "store/trips/actionTypes";
import { changeOperatorStart, clearActionFlags, pushSuccessNotification, tryRouteChangeStart } from "store/app/actions";
import { getCurrentOperatorStart } from "store/operator/actions";

import {
  PANEL_TRIPS_ROOT_PATH,
  PANEL_TRIPS_CREATE_CONTENT_PATH,
  PANEL_TRIPS_CREATE_PATH,
  PANEL_TRIPS_EDIT_CONTENT_PATH,
  PANEL_TRIPS_EDIT_PATH,
} from "constants/routes";

import { usePrompt } from "components/ui/CustomPrompt/CustomPrompt";
import { useSavingCoverContext } from "components/ui/SavingCover/SavingCoverContext";

import { NotificationsService } from "services/NotificationsService";
import { getAllNotificationsStart } from "store/notifications/actions";

import { setUrl } from "utils/url";
import { preUpdateTripPayload } from "utils/dataConverters";
import { GLOBAL_CONTENT, NOTIFICATION_TYPES, PAGE_TITLE_LABEL, SITE_LEAVE_WARNING_MESSAGES, TRIP_WIZARD_CONTENT } from "constants/content";
import { setTripFinishedActionStatus, setTripsActionStatus, setTripsFormTouched } from "store/trips/actions";
import { ListIndexService } from "services/ListIndexService";
import { StorageService } from "services/StorageService";
import { TripService } from "services/TripService";
import { Trip } from "domain/Trip";
import { usePermissionsService } from "hooks/usePermissionsService";
import { PERMISSIONS } from "constants/permissions";
import { HistoryOutlined } from "@material-ui/icons";
import { setLock } from "feature/panel/_shared/helpers";
import { ConfirmationModal } from "components/ui/Modals/ConfirmationModal";
import { TripWizardNavigation } from "./TripWizardNavigation/TripWizardNavigation";
import { TripWizardActions } from "./TripWizardActions/TripWizardActions";
import { CopyingTripModal } from "../Index/CopyingTripModal/CopyingTripModal";
import { shouldRedirect } from "./helpers";
import { useLock } from "../../../../hooks/useLock";

const noMarginPages = ["storyboard"];

const TripWizard = () => {
  const listIndexService = new ListIndexService(new StorageService());

  /** @type {NotificationsService} */
  const notificationService = new NotificationsService();

  const { pathname } = useLocation();
  const navigate = useNavigate();
  const extendedNavigate = useExtendedNavigate();
  const dispatch = useDispatch();

  const params = useParams();
  const savingCover = useSavingCoverContext();

  const { reference_code, operator_code } = params;
  const content = params["*"]
    .replace("/edit", "/")
    .split("/")
    .pop();

  const isEditMode = !!params.operator_code && !!params.reference_code;

  const pageTitle = isEditMode
    ? PAGE_TITLE_LABEL.editTrip({ operatorCode: params.operator_code, referenceCode: params.reference_code })
    : PAGE_TITLE_LABEL.createTrip;

  useTitle(pageTitle);

  const [copyData, setCopyData] = useState({ modalOpen: false, body: null });
  const [tripLoaded, setTripLoaded] = useState(false);
  const [originalTrip, setOriginalTrip] = useState(false);
  const [forceUpdateOpen, setForceUpdateOpen] = useState(false);
  const [formState, dispatchLocal] = useReducer(tripStateReducer, tripInitialState);
  const [shouldDisableSaveButton, setSaveButtonState] = useState(false);
  const [showModal, setShowModal] = useState({
    visible: false,
  });

  const isNoMargin = noMarginPages.includes(pathname.split("/")[pathname.split("/")?.length - 1]);

  const permissionsService = usePermissionsService();

  const { checkValidation } = usePayloadValidator("trips");

  const { passcode, userId, vamoos_id, is_wiped, is_active } = formState;

  const { errors, inProgress, actionType, finished } = useSelector(state => state.trips);
  const { currentOperatorCode, operators } = useSelector(state => state.auth);
  const isTripFormTouched = useSelector(state => state.trips.isTripFormTouched === true);

  const defaultLanguageCode = useSelector(({ operator }) => operator.currentOperator.defaultLanguageCode);

  const shouldShowSpinnerWhenUpdate = inProgress && actionType === CREATE_OR_UPDATE_TRIP_START;
  const shouldGetTripAfterUpdate = finished && CREATE_OR_UPDATE_TRIP_SUCCESS === actionType;

  usePrompt(SITE_LEAVE_WARNING_MESSAGES.createTitle(SITE_LEAVE_WARNING_MESSAGES.contexts.vamoosEditor), isTripFormTouched, [
    PANEL_TRIPS_CREATE_PATH,
    PANEL_TRIPS_CREATE_CONTENT_PATH,
    PANEL_TRIPS_EDIT_PATH,
    PANEL_TRIPS_EDIT_CONTENT_PATH,
  ]);

  const canEditItinerary = permissionsService.can(
    PERMISSIONS.actions.update,
    PERMISSIONS.sections.vamoosList,
    isEditMode ? vamoos_id : null,
  );

  const canReadCurrentItinerary = isEditMode
    ? !tripLoaded ||
      !vamoos_id ||
      (tripLoaded && permissionsService.can(PERMISSIONS.actions.read, PERMISSIONS.sections.vamoosList, vamoos_id))
    : true;

  const canCreateItinerary = permissionsService.can(
    PERMISSIONS.actions.create,
    PERMISSIONS.sections.vamoosList,
    PERMISSIONS.resources.default,
  );

  const handleSaveTrip = async () => {
    const templates = await notificationService.getAllNotifications();
    const storyboard = formState.storyboard.map(item => {
      const isHash = item.headline?.charAt(0) === "#";
      item.meta = { ...item.meta, hide_day_info: isHash };
      item.headline = isHash ? item.headline.substring(1) : item.headline;
      item.content_type = "text/html";

      return item;
    });

    const formData = {
      ...formState,
      passcode: formState.passcode.trim(),
      userId: formState.userId.trim(),
      storyboard,
    };

    const payload = await preUpdateTripPayload(
      formData,
      templates.filter(({ type }) => ["timed", "gps"].includes(type)),
    );
    createTripHandler(payload, checkValidation, dispatch, isEditMode ? "update" : "create");
    setOriginalTrip(payload);
    setLock({ id: formState.vamoos_id, userId }, dispatch);
  };

  const setValueFor = (fieldName, value, formTouchedValue = true) => {
    dispatchLocal({
      type: "setValueFor",
      fieldName,
      value,
    });

    if (!isTripFormTouched) {
      dispatch(setTripsFormTouched(formTouchedValue));
    }
  };

  const createNewTimedNotifications = async notifications => {
    const templates = await notificationService.getAllNotifications();

    const timedNotificationsList = notifications.filter(notification => notification.type === "timed");
    const listOfNewTimedNotifications = filterOutExistingNotifications(
      templates.filter(({ type }) => type === "timed"),
      timedNotificationsList,
    );
    return createDefaultTimedNotifications(listOfNewTimedNotifications);
  };

  const createNewGpsNotifications = async notifications => {
    const templates = await notificationService.getAllNotifications();

    const gpsNotificationsList = notifications.filter(notification => notification.type === "gps");
    const listOfNewGpsNotifications = filterOutExistingNotifications(
      templates.filter(({ type }) => type === "gps"),
      gpsNotificationsList,
    );
    return createDefaultGpsNotifications(listOfNewGpsNotifications, formState.departure_date);
  };

  const getNotificationTemplates = () => {
    dispatch(getAllNotificationsStart());
  };

  const createDefaultNotifications = async notifications => {
    const timedNotifications = await createNewTimedNotifications(notifications);
    const gpsNotifications = await createNewGpsNotifications(notifications);

    const finalNotificationList = [...notifications, ...timedNotifications, ...gpsNotifications];

    if (!finalNotificationList.some(notification => notification.type === NOTIFICATION_TYPES.inspiration)) {
      finalNotificationList.push(createInspirationNotification(formState.inspiration ? formState.inspiration.vamoos_id : ""));
    }

    setValueFor("notifications", finalNotificationList, false);
  };

  const init = () => {
    dispatch(clearActionFlags("trips"));
    dispatch(getCurrentOperatorStart());
    listIndexService.clearOriginalIndexes();
    dispatch(setTripsFormTouched(false));
  };

  const clearFormTouched = () => {
    if (actionType === CREATE_OR_UPDATE_TRIP_SUCCESS) {
      dispatch(setTripsFormTouched(false));
    }
  };

  const tryRedirectAfterPasscodeChange = () => {
    if (shouldRedirect(actionType, passcode, reference_code, userId, operator_code) && !isTripFormTouched) {
      const url = setUrl(
        PANEL_TRIPS_EDIT_CONTENT_PATH,
        { operator_code: formState.userId, reference_code: formState.passcode, content: content === "create" ? "" : content },
        true,
      );
      navigate(url);
    }
  };

  const getDefaultTrip = async () => {
    await createDefaultNotifications([]);
    setTripLoaded(true);
  };

  const getCurrentOperator = () => {
    dispatch(getCurrentOperatorStart());
  };

  const getTripDetails = async () => {
    const tripService = new TripService();

    if (reference_code && operator_code) {
      try {
        const data = await tripService.getTrip(operator_code, reference_code);
        const payload = { ...Trip(data), currentPasscode: reference_code, language: data.language || defaultLanguageCode };

        dispatchLocal({ type: "setAllValues", payload });
        dispatch(setTripsFormTouched(false));
        dispatch(setTripFinishedActionStatus(false));
        dispatch(setTripsActionStatus(null));

        await createDefaultNotifications(payload.notifications);
        setOriginalTrip(payload);
        setTripLoaded(true);
        return payload;
      } catch (e) {
        dispatchLocal({ type: "setAllValues", payload: { ...tripInitialState, language: defaultLanguageCode } });
        setTripLoaded(true);
      }
    }
  };

  // useLock({ id: formState.vamoos_id, setShowModal, getUpdated: getTripDetails });

  const handleCancelButton = () => {
    dispatch(tryRouteChangeStart(PANEL_TRIPS_ROOT_PATH));
    extendedNavigate(PANEL_TRIPS_ROOT_PATH);
  };

  const handleForceUpdate = async notification_text => {
    const tripService = new TripService();

    try {
      await tripService.sendUpdate(formState.userId, formState.passcode, notification_text);
      dispatch(pushSuccessNotification(TRIP_WIZARD_CONTENT.notifications.forceUpdate));
    } catch (e) {
      dispatch(pushSuccessNotification(TRIP_WIZARD_CONTENT.notifications.forceUpdateError));
    }

    setForceUpdateOpen(false);
  };

  const handleEraseTripToggle = () => {
    createTripHandler({ ...originalTrip, is_active: !formState.is_active }, () => true, dispatch);
    setValueFor("is_active", !formState.is_active, false);
  };

  const triggerCopyTrip = () => {
    const { modalOpen } = copyData;
    const body = modalOpen
      ? copyData.body
      : {
          field1: originalTrip.field1,
          operator_code: originalTrip.userId,
          reference_code: originalTrip.passcode,
        };
    setCopyData({ body, modalOpen: !modalOpen });
  };

  const closeCopyTripModal = () => setCopyData({ ...copyData, modalOpen: false });

  const getTripAfterUpdate = () => {
    /**
     * @todo: condition related to "finished" variable is important until app uses global redux actions CLEAR_ACTION_FLAGS
     */

    if (tripLoaded && shouldGetTripAfterUpdate) {
      getTripDetails();
    }
  };

  useEffect(closeCopyTripModal, [params.reference_code, params.operator_code]);
  useEffect(init, []);
  useEffect(clearFormTouched, [actionType]);
  useEffect(tryRedirectAfterPasscodeChange, [actionType, isTripFormTouched]);
  useEffect(getTripAfterUpdate, [shouldGetTripAfterUpdate]);
  useEffect(getNotificationTemplates, []);
  useEffect(getCurrentOperator, []);

  useEffect(() => {
    const tripOperator = operators?.find(operator => {
      const usedCodes = operator.usedCodes.map(code => code.toLowerCase());

      return usedCodes.includes(operator_code?.toLowerCase());
    });

    if (!formState?.vamoos_id && tripOperator && tripOperator.code !== currentOperatorCode) {
      dispatch(changeOperatorStart({ code: tripOperator?.code, isSelectedOperatorActive: tripOperator?.isActive }));
    } else if (params.operator_code && params.reference_code) {
      getTripDetails();
    } else {
      getDefaultTrip();
    }
  }, [params.reference_code, params.operator_code, currentOperatorCode]);

  useEffect(() => {
    if (shouldShowSpinnerWhenUpdate) {
      savingCover.show();
    } else {
      savingCover.hide();
    }
  }, [shouldShowSpinnerWhenUpdate]);

  useEffect(() => {
    setValueFor("language", formState.language || defaultLanguageCode, false);
  }, [defaultLanguageCode, formState?.vamoos_id]);

  const contextbar = {
    left: () =>
      isTripFormTouched ? (
        <CancelHistoryButton role="link" clickHandler={handleCancelButton} />
      ) : (
        <BackHistoryButton role="link" clickHandler={handleCancelButton} />
      ),
    middle: () => <ContextBarTitle title={pageTitle} />,
    right: () => {
      if (is_wiped) {
        return null;
      }
      if (tripLoaded && !is_active) {
        return canEditItinerary ? (
          <PrimaryButton onClick={handleEraseTripToggle}>
            <HistoryOutlined />
            <span>{GLOBAL_CONTENT.restore}</span>
          </PrimaryButton>
        ) : null;
      }

      return (
        <TripWizardActions
          onSave={handleSaveTrip}
          showSpinner={shouldShowSpinnerWhenUpdate}
          disabled={!passcode || !!errors.passcode || !userId || !isTripFormTouched}
          isEditMode={isEditMode}
          vamoosId={formState?.vamoos_id}
          triggerCopyVamoos={triggerCopyTrip}
          onForceUpdate={handleForceUpdate}
          forceUpdateDisabled={isTripFormTouched}
          forceUpdateOpen={forceUpdateOpen}
          setForceUpdateOpen={setForceUpdateOpen}
        />
      );
    },
  };

  const context = {
    ...formState,
    setValueFor,
    editMode: isEditMode,
    errors,
    shouldDisableSaveButton,
    setSaveButtonState,
  };

  const onConfirm = () => {
    if (showModal.type === "new-lock") {
      navigate("/panel/trips");
    } else {
      getNotificationTemplates();
      getCurrentOperator();
      init();
      setLock({ id: formState.vamoos_id });
      setShowModal(null);
    }
  };

  const getTitle = () => {
    if (showModal?.type === "new-lock")
      return `Trip is currently being edited ${showModal.isCurrentUserBuNotSession ? "elsewhere" : ""} by ${showModal?.editor}`;
    return "Changes were made while you were away. The new data will now be loaded.";
  };

  return (
    <PanelTemplate
      hasPermission={isEditMode ? canReadCurrentItinerary : canCreateItinerary}
      contextBar={contextbar}
      hasStickyPanel={isNoMargin}
      navigation={
        <TripWizardNavigation
          isEditMode={isEditMode}
          onEraseConfirm={handleEraseTripToggle}
          isTripActive={formState.is_active}
          isTripDeleted={formState.is_wiped}
          vamoosId={formState.vamoos_id}
        />
      }
    >
      <ManageTripContext.Provider value={context}>
        <ConfirmationModal onConfirm={onConfirm} title={getTitle()} open={showModal?.visible} />
        {!isEditMode || (isEditMode && tripLoaded) ? <Outlet /> : <LoadingScreen />}
      </ManageTripContext.Provider>
      <ConfirmationModal onConfirm={onConfirm} title={getTitle()} open={showModal?.visible} />
      <CopyingTripModal
        open={copyData.modalOpen}
        onCancel={triggerCopyTrip}
        item={copyData.body || {}}
        hasUnsavedChanges={isTripFormTouched}
        onSave={handleSaveTrip}
        showSpinner={shouldShowSpinnerWhenUpdate}
      />
    </PanelTemplate>
  );
};

TripWizard.defaultProps = {
  hasPermission: true,
};

export { TripWizard };
