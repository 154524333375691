import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { Close, InsertDriveFile, Description, Videocam, Link as LinkIcon } from "@material-ui/icons";

import { TextLimiter } from "components/ui/TextLimiter";
import { fontSize } from "config/theme/fonts";
import { colors } from "config/theme/colors";
import { IMAGES_MIME_TYPES, DOCUMENTS_FILE_TYPES, VIDEO_FILE_TYPES } from "constants/defaults";
import { MINIATURE_CONTENT } from "constants/content";
import { StoryboardFile } from "domain/StoryboardFile";
import { useRefreshedFileUrl } from "hooks/useRefreshedFileUrl";

const MiniatureCard = styled.div`
  width: 178px;
  height: 80px;
  margin-bottom: 50px !important;
  margin-right: 24px;
`;

const MiniatureImage = styled.img`
  max-width: 100%;
`;

const ImageWrapper = styled.div`
  height: 80px;
  overflow: hidden;
  max-height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-top: 1px solid ${props => (props.active ? colors.brand : colors.grey40)};
  border-left: 1px solid ${props => (props.active ? colors.brand : colors.grey40)};
  border-right: 1px solid ${props => (props.active ? colors.brand : colors.grey40)};
`;

const CardTitleBox = styled.div`
  height: ${({ theme }) => theme.setSpacing(8)}px;
  background-color: ${props => (props.active ? colors.brand : colors.white)};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 8%;
  border-bottom-left-radius: ${({ theme }) => theme.setSpacing(2)}px;
  border-bottom-right-radius: ${({ theme }) => theme.setSpacing(2)}px;
  border: 1px solid ${props => (props.active ? colors.brand : colors.grey40)};
`;

const PictureName = styled.span`
  color: ${props => (props.active ? colors.white : colors.grey100)};
  font-size: ${fontSize.title4}px;
  user-select: none;
`;

const RemoveImageIcon = styled(({ active, ...rest }) => <Close {...rest} />)`
  height: ${fontSize.title4}px !important;
  width: ${fontSize.title4}px !important;
  color: ${({ active }) => (active ? colors.white : colors.grey100)};
  cursor: pointer;
`;

const PlaceholderWrapper = styled.div`
  background-color: ${colors.grey10};
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.5;
`;

const Miniature = ({ data, webSource, active, selectable, handleMiniatureDelete, handleMiniatureClick }) => {
  const { file, id, name, remote_url, meta } = data;

  const previewURL = useRefreshedFileUrl(meta?.preview_url);
  const assetUrl = useRefreshedFileUrl(file?.https_url)

  const renderMiniature = () => {
    if (remote_url) {
      return (
        <PlaceholderWrapper alt={MINIATURE_CONTENT.defaultLinkCaption}>
          <LinkIcon />
        </PlaceholderWrapper>
      );
    }
    const fileType = file.type || file.mime_type;
    const asset = webSource ? assetUrl : URL.createObjectURL(file);
  
    if (IMAGES_MIME_TYPES.includes(fileType)) {
      return <MiniatureImage src={asset} alt={MINIATURE_CONTENT.imageCaption(name || file.name)} />;
    }

    if (DOCUMENTS_FILE_TYPES.includes(fileType)) {
      return (
        <PlaceholderWrapper alt={MINIATURE_CONTENT.documentFileCaption}>
          <Description />
        </PlaceholderWrapper>
      );
    }

    if (VIDEO_FILE_TYPES.includes(fileType)) {
      return (
        <PlaceholderWrapper alt={MINIATURE_CONTENT.videoFileCaption}>
          <Videocam />
        </PlaceholderWrapper>
      );
    }

    if (StoryboardFile.FILE_TYPE === fileType) {
      if (meta?.preview_url) {
        return <MiniatureImage src={previewURL} alt={MINIATURE_CONTENT.storyboardFileCaption} />;
      }
      return (
        <PlaceholderWrapper alt={MINIATURE_CONTENT.storyboardFileCaption}>
          <InsertDriveFile />
        </PlaceholderWrapper>
      );
    }

    return (
      <PlaceholderWrapper alt={MINIATURE_CONTENT.otherFileCaption}>
        <InsertDriveFile />
      </PlaceholderWrapper>
    );
  };

  return (
    <MiniatureCard item xs={4} onClick={() => handleMiniatureClick(data)}>
      <ImageWrapper>{renderMiniature()}</ImageWrapper>
      <CardTitleBox active={active}>
        <PictureName active={active}>
          <TextLimiter text={name || file.name} maxChar={20} />
        </PictureName>
        {selectable ? null : <RemoveImageIcon active={active} onClick={() => handleMiniatureDelete(id)} />}
      </CardTitleBox>
    </MiniatureCard>
  );
};

Miniature.propTypes = {
  data: PropTypes.shape().isRequired,
  webSource: PropTypes.bool.isRequired,
  handleMiniatureDelete: PropTypes.func.isRequired,
  handleMiniatureClick: PropTypes.func,
  active: PropTypes.bool,
  selectable: PropTypes.bool,
};

Miniature.defaultProps = {
  active: false,
  selectable: true,
  handleMiniatureClick: () => {},
};

export { Miniature, MiniatureImage };
