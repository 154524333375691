import * as actionTypes from "store/customApps/actionTypes";
import { reducer, setState, globalReducerActions } from "utils/store";

const customAppsInitialState = {
  errors: {},
  actionType: null,
  inProgress: false,
  finished: false,
  customApps: [],
  count: 0
};

const ReducerActions = globalReducerActions("customApps", customAppsInitialState, {
  [actionTypes.GET_CUSTOM_APPS_START](state) {
    return setState(state, {
      actionType: actionTypes.GET_CUSTOM_APPS_START,
      inProgress: true,
    });
  },

  [actionTypes.GET_CUSTOM_APPS_SUCCESS](state, payload) {
    const { items, total_matches } = payload;

    return setState(state, {
      inProgress: false,
      customApps: items,
      count: total_matches,
      errors: {},
    });
  },

  [actionTypes.GET_CUSTOM_APPS_FAIL](state, payload) {
    return setState(state, {
      inProgress: false,
      count: 0,
      customApps: [],
      errors: payload.errors,
      searchMode: payload.searchMode,
    });
  },
});

const customAppsReducer = (state = customAppsInitialState, action) => reducer(state, action, ReducerActions);

export { customAppsReducer };
