import React from "react";
import styled from "styled-components";
import CircularProgress from "@material-ui/core/CircularProgress";

import { setColorVariant } from "utils/styles";

const SpinnerWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Spinner = styled(({ size, ...rest }) => <CircularProgress size={size} {...rest} />)`
  && {
    color: ${({ cv }) => setColorVariant(cv, "brand")};
  }
`;

export { Spinner, SpinnerWrapper };
