import React, { useEffect, useRef } from "react";
import { MenuItem, Tooltip } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import PropTypes from "prop-types";
import { debounce } from "lodash";

import { Input, Select } from "components/ui/Forms";

import SearchableSelect from "components/ui/Forms/Select/index";
import Checkbox from "components/ui/Inputs/Checkbox";

import {
  checkUserIdAvailabilityForCAStart,
  setValueForAppDefaultLanguage,
  setValueForAppLanguages,
  setValueForDefaultUserIdSetting,
  setValueForTitle,
} from "store/customAppForm/actions";

import { DEFAULT_DEBOUNCE_DELAY } from "constants/defaults";
import { CUSTOM_APPS, CUSTOM_APP_OPTIONS } from "constants/content";

import { usePermissionsService } from "hooks/usePermissionsService";
import { PERMISSIONS } from "constants/permissions";
import { HttpClient } from "services/application/httpClient/httpClient";
import { useQuery } from "@tanstack/react-query";

import { HintWrapper, SectionTitle, CustomCard } from "../sharedComponents";

const InputWrapper = styled.div`
  width: ${({ expandWidth }) => (expandWidth ? 49 : 30)}%;
  position: relative;
`;

const InputTooltip = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 73px;
  z-index: 10;
`;

const InfoFieldContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const GeneralSectionContent = styled.div`
  padding: 51px 91px 21px 84px;
`;

const AppInfoSection = ({ isHistoryVersion }) => {
  const permissionsService = usePermissionsService();
  const dispatch = useDispatch();

  const customAppForm = useSelector(state => state.customAppForm.customAppForm);
  const errors = useSelector(state => state.customAppForm.errors);
  const caStatus = useSelector(state => state.customAppForm?.customAppForm?.status);
  const defaultLanguageCode = useSelector(({ operator }) => operator.currentOperator.defaultLanguageCode);

  const canEdit = permissionsService.can(PERMISSIONS.actions.update, PERMISSIONS.sections.operator, PERMISSIONS.resources.operators);
  const canCreate = permissionsService.can(PERMISSIONS.actions.create, PERMISSIONS.sections.operator, PERMISSIONS.resources.operators);

  const editDisabled = !((caStatus === null && canCreate) || (caStatus === "draft" && canEdit));

  const checkAvailableUserIds = useRef(debounce(params => dispatch(checkUserIdAvailabilityForCAStart(params)), DEFAULT_DEBOUNCE_DELAY));

  const { data: languages } = useQuery({
    queryKey: ["customAppLanguages"],
    queryFn: () => {
      return HttpClient.get("/app_languages");
    },
    refetchOnMount: true,
  });

  const init = () => {
    if (customAppForm?.settings?.defaultUserId) {
      checkAvailableUserIds.current(customAppForm.settings.defaultUserId);
    }
  };

  const handleTitleChange = ({ target }) => {
    if (target?.value?.length <= 30) {
      dispatch(setValueForTitle(target.value));
    }
  };

  const handleChangePropertyUserID = ({ target }) => {
    dispatch(setValueForDefaultUserIdSetting(target.value));
  };

  const onAvailableLanguageChange = lang => {
    if (customAppForm.languages?.includes(lang)) {
      const filetered = customAppForm.languages?.filter(item => item !== lang);
      dispatch(setValueForAppLanguages(filetered));
      if (customAppForm.default_language === lang) dispatch(setValueForAppDefaultLanguage(filetered[0] || null));
    } else {
      dispatch(setValueForAppLanguages([...(customAppForm.languages || []), lang]));
      if (!customAppForm.default_language) dispatch(setValueForAppDefaultLanguage(lang));
    }
  };

  const onDefaultLanguageChange = langObj => {
    const lang = langObj.value;
    dispatch(setValueForAppDefaultLanguage(lang));
    if (!customAppForm.languages?.includes(lang)) dispatch(setValueForAppLanguages([...(customAppForm.languages || []), lang]));
  };

  useEffect(init, [customAppForm?.settings?.defaultUserId]);

  useEffect(() => {
    if (languages && !customAppForm?.default_language) {
      const lang = languages?.data?.find(item => item.value === (defaultLanguageCode || "en"));
      onDefaultLanguageChange(lang);
    }
  }, [languages]);

  return (
    <CustomCard noColor>
      <GeneralSectionContent>
        <SectionTitle>{CUSTOM_APPS.general.appInfo}</SectionTitle>
        <InfoFieldContainer style={{ marginBottom: 15 }}>
          <InputWrapper expandWidth={customAppForm.type === "trip"}>
            <Input
              name="custom-app-title"
              value={customAppForm.name}
              onChange={handleTitleChange}
              label={CUSTOM_APPS.general.titleLabel}
              helperText={errors.name || errors.bundleId || (customAppForm.name > 30 && "Max length 30 chars")}
              error={!!errors.name || !!errors.bundleId || customAppForm.name > 30}
              disabled={isHistoryVersion || editDisabled}
            />
            {/* <HintWrapper>{CUSTOM_APPS.general.titleHint}</HintWrapper> */}
          </InputWrapper>
          <InputWrapper expandWidth={customAppForm.type === "trip"}>
            <Tooltip title={CUSTOM_APP_OPTIONS.find(opt => opt.value === customAppForm?.type)?.label || ""}>
              <InputTooltip />
            </Tooltip>
            {customAppForm.type && (
              <Select name="custom-app-type" value={customAppForm.type} label={CUSTOM_APPS.general.type} component="div" disabled>
                {CUSTOM_APP_OPTIONS.map(({ value, label }) => (
                  <MenuItem key={value} value={value}>
                    {label}
                  </MenuItem>
                ))}
              </Select>
            )}
          </InputWrapper>
          {customAppForm.type === "stay" && (
            <InputWrapper>
              <Input
                name="userId-field"
                value={customAppForm.settings.defaultUserId}
                onChange={handleChangePropertyUserID}
                label={CUSTOM_APPS.general.userIdProp}
                error={!!errors?.settings?.defaultUserId || !!errors?.userId}
                helperText={errors?.settings?.defaultUserId || errors?.userId}
                disabled={isHistoryVersion || editDisabled}
              />
            </InputWrapper>
          )}
        </InfoFieldContainer>
        <SectionTitle style={{ marginTop: 35 }}>Default language</SectionTitle>
        <div style={{ width: 400 }}>
          <SearchableSelect
            label="Choose default language"
            onChange={onDefaultLanguageChange}
            value={languages?.data?.find(item => item.value === customAppForm?.default_language)}
            options={languages?.data}
            disableSelectOnBlur
          />
        </div>

        <SectionTitle style={{ marginTop: 35 }}>Available languages</SectionTitle>
        {languages?.data?.map(({ label, value }) => {
          return (
            <Checkbox
              label={label}
              onChange={() => onAvailableLanguageChange(value)}
              checked={customAppForm.languages?.includes(value)}
              style={{ marginBottom: 10 }}
            />
          );
        })}
      </GeneralSectionContent>
    </CustomCard>
  );
};

AppInfoSection.defaultProps = {
  isHistoryVersion: false,
};

AppInfoSection.propTypes = {
  isHistoryVersion: PropTypes.bool,
};

export { AppInfoSection };
