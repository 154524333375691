import React, { useContext } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import uuidv4 from "uuid";
import AddIcon from "@material-ui/icons/Add";

import { BodyText2 } from "components/ui/Typography/Typography";
import { ActionButton } from "components/ui/Buttons";

import { EntriesContext } from "feature/panel/Trips/_shared/Storyboard/Entries/EntriesContext";
import { EntryDocumentsList } from "feature/panel/Trips/_shared/Storyboard/Entries/EntryForm/EntryDocumentsList";
import { GLOBAL_CONTENT, TRIP_WIZARD_CONTENT } from "constants/content";

const StyledWrapper = styled.div`
  width: 100%;
  border-top: 1px solid ${({ theme }) => theme.colors.grey10};
  padding: ${({ theme }) => theme.setSpacing(6)}px 0;
  margin-top: ${({ theme }) => theme.setSpacing(6)}px;
`;

const DocumentsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.setSpacing(6)}px;
`;

const EntryDocuments = ({ item, errors, disabled }) => {
  const { updateDayData, defaultDocumentIcon } = useContext(EntriesContext);

  const { documents } = item;

  const handleAddFile = () => {
    const newItem = {
      id: uuidv4(),
      name: "",
      icon_id: defaultDocumentIcon
    };
    updateDayData({ ...item, documents: [...documents, newItem] });
  };

  const handleChangeDocuments = newList => {
    updateDayData({ ...item, documents: [...newList] });
  };

  return (
    <StyledWrapper>
      <DocumentsHeader>
        <BodyText2>
          {TRIP_WIZARD_CONTENT.storyboard.documents}
          :
        </BodyText2>
        {!disabled && (
          <ActionButton disabled={disabled} size="small" onClick={handleAddFile}>
            <AddIcon />
            {GLOBAL_CONTENT.add}
          </ActionButton>
        )}
      </DocumentsHeader>
      <EntryDocumentsList
        list={documents}
        dayId={item.id}
        onChange={handleChangeDocuments}
        defaultIcon={defaultDocumentIcon}
        errors={errors}
        disabled={disabled}
      />
    </StyledWrapper>
  );
};

EntryDocuments.propTypes = {
  item: PropTypes.shape().isRequired,
  errors: PropTypes.shape().isRequired,
  disabled: PropTypes.bool.isRequired
};

export { EntryDocuments };
