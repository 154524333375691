import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { ContextNavigationLink } from "components/ui/Links/Links";

import { PANEL_TRIPS_ROOT_PATH, PANEL_STAYS_PATH } from "constants/routes";

const StyledWrapper = styled.div`
  display: flex;
  height: 100%;
  width: auto;
  justify-content: center;
  align-items: center;
`;

const VamoosListNavigation = ({ location }) => {
  const { pathname } = location;
  return (
    <StyledWrapper>
      <ContextNavigationLink to={PANEL_TRIPS_ROOT_PATH} isActive={PANEL_TRIPS_ROOT_PATH === pathname}>
        vamoos trips
      </ContextNavigationLink>
      <ContextNavigationLink to={PANEL_STAYS_PATH} isActive={PANEL_STAYS_PATH === pathname}>
        vamoos Stays
      </ContextNavigationLink>
    </StyledWrapper>
  );
};

VamoosListNavigation.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
};

export { VamoosListNavigation, StyledWrapper };
