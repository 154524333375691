// @ts-check

import { USER_INFO_URL } from "constants/api";
import { UserMapper } from "mappers/UserMapper";
import { HttpClient } from "services/application/httpClient/httpClient";
import { TabSessionService } from "./TabSessionService";

export class AuthService {
  /**
   *
   * @param {import("services/application/httpClient/httpClient").HttpClient} httpClient
   * @param {import("mappers/UserMapper").UserMapper} userMapper
   * @param {import("services/TabSessionService").TabSessionService} tabSessionsService
   */
  constructor(
    httpClient = HttpClient, 
    userMapper = new UserMapper(), 
    tabSessionsService = new TabSessionService()
  ) {
    this.httpClient = httpClient;
    this.userMapper = userMapper;
    this.tabSessionsService = tabSessionsService;
  }

  async getCurrentUser() {
    try {
      const { data } = await this.httpClient.get(USER_INFO_URL);

      return this.userMapper.fromDtoToDomain(data);
    } catch (e) {
      return null;
    }
  }

  /**
   * @returns {Promise<string>}
   */
  async getToken() {
    return this.tabSessionsService.getToken();
  }

  /**
   * @returns {Promise<string>}
   */
  async getCurrentOperatorCode() {
    return this.tabSessionsService.getTabOperatorCode();
  }
}
