import React, { useEffect, useState, useContext, useRef } from "react";

import { Content } from "components/ui/Content/Content";
import { Input } from "components/ui/Forms";
import { SignUpContext } from "feature/views/SignUp/SignUpContext";
import { debounce } from "lodash";
import { DEFAULT_DEBOUNCE_DELAY } from "constants/defaults";

const SignUpFormStep1 = () => {
  const { user, handleChange, passwordConfirmation, setPasswordConfirmation, validationService, canGoNext } = useContext(SignUpContext);
  const { username, email, password } = user;

  const [ errors, setErrors ] = useState({});

  const onChange = (e) => {
    if(e.target.name === "passwordConfirmation") {
      setPasswordConfirmation(e.target.value);
    } else {
      handleChange(e);
    }
  }

  const validate = async (form, step) => {
    canGoNext(false);

    const validationErrors = await validationService.validate(form, step);

    if(form.username && form.email && form.password && Object.keys(validationErrors).length === 0) {
      canGoNext(true)
    } else {
      canGoNext(false);
    }

    setErrors(validationErrors);
  }

  const debouncedValidate = useRef(debounce((form, step) => validate(form, step), DEFAULT_DEBOUNCE_DELAY));

  useEffect(() => {
    debouncedValidate.current({
      ...user,
      passwordConfirmation
    }, 1);
  }, [username, email, password, passwordConfirmation])

  return (
    <>
      <input type="hidden" value="sample" />
      <Content>
        <Input
          label="Username"
          size="large"
          name="username"
          isRequired
          value={username}
          onChange={onChange}
          error={!!errors.username}
          helperText={errors.username}
          autoComplete="new-username"
        />
      </Content>
      <Content>
        <Input
          label="Email"
          size="large"
          name="email"
          isRequired
          value={email}
          onChange={onChange}
          error={!!errors.email}
          helperText={errors.email}
          autoComplete="new-email"
        />
      </Content>
      <Content>
        <Input
          label="Password"
          size="large"
          name="password"
          type="password"
          isRequired
          value={password}
          onChange={onChange}
          error={!!errors.password}
          helperText={errors.password}
          autoComplete="new-password"
        />
      </Content>
      <Content>
        <Input
          label="Password confirmation"
          name="passwordConfirmation"
          type="password"
          size="large"
          isRequired
          value={passwordConfirmation}
          onChange={onChange}
          error={!!errors.passwordConfirmation}
          helperText={errors.passwordConfirmation}
          autoComplete="new-password"
        />
      </Content>
    </>
  );
};

export { SignUpFormStep1 };
