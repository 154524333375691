import React from "react";
import styled from "styled-components";
import MuiChip from "@material-ui/core/Chip";
import { setColorVariant } from "utils/styles/colors";

const Chip = styled(({ cv, ...rest }) => <MuiChip variant="outlined" {...rest} />)`
  && {
    border: 1px solid ${({ cv }) => setColorVariant(cv || "grey50")};
    color: ${({ cv }) => setColorVariant(cv || "grey50")};

    .MuiChip-icon {
      color: ${({ theme }) => theme.colors.grey100};
    }
  }
`;

export { Chip };
