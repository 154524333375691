import styled from "styled-components";
import TextField from "@mui/material/TextField";
import { colors } from "config/theme/colors";
import { font } from "config/theme/fonts";

export const InputStyled = styled(TextField)`
  width: 100%;

  .MuiOutlinedInput-input {
    height: 48px;
    padding: 0 14px;
    &:-webkit-autofill {
      opacity: ${({ value }) => (value ? 1 : 0)};
    }
  }

  .MuiOutlinedInput-root {
    border-radius: 8px;
    .MuiOutlinedInput-notchedOutline {
      transition: all 0.2s ease-in-out;
    }
    &.Mui-focused {
      .MuiOutlinedInput-notchedOutline {
        border-color: ${({ error }) => (error ? colors.error : colors.brand)} !important;
      }
    }
    &:hover {
      .MuiOutlinedInput-notchedOutline {
        border-color: ${colors.brand};
      }
    }
  }
  .MuiInputBase-input {
    font-family: "Montserrat";
  }
  .MuiInputLabel-outlined {
    transform: translate(14px, ${({ value, isFocused }) => (value || isFocused ? "-6px" : "13px")})
      scale(${({ value, isFocused }) => (value || isFocused ? 0.75 : 1)});
    font-family: ${({ theme }) => theme.fonts.font};
    background-color: #fff;
    padding: 0 5px;
    &.Mui-focused {
      transform: translate(14px, -6px) scale(0.75);
      color: ${colors.brand};
    }
  }
`;

export const ErrorText = styled.div`
  font-size: 12px;
  color: ${colors.error};
  font-family: ${({ theme }) => theme.fonts.font};
  margin-top: 5px;
`;
