import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { Link } from "components/ui/Links/Links";
import { PANEL_NOTIFICATIONS_PATH } from "constants/routes";
import { Message } from "components/ui/Messages";
import { NO_TEMPLATE_TEXT } from "constants/content";

const StyledLink = styled(Link)`
  && {
    margin-left: ${({ theme }) => theme.setSpacing(1)}px;
    line-height: 24px;
  }
`;

export function NoTemplatesMessage({ notificationType }) {
  return (
    <Message type="info" text={NO_TEMPLATE_TEXT(notificationType)}>
      <StyledLink to={PANEL_NOTIFICATIONS_PATH}>Create one</StyledLink>
    </Message>
  );
}

NoTemplatesMessage.propTypes = {
  notificationType: PropTypes.oneOf(["timed", "GPS"]).isRequired,
};
