import { Paginated } from "domain/Paginated";
import { BaseMapper } from "./BaseMapper";

/**
 * @template T
 */
export class PaginatedMapper extends BaseMapper {

  constructor(domainMapper) {
    super();
    this.domainMapper = domainMapper;
  }

  fromDtoToDomain(dto) {
    /** @type {Paginated<T>} */
    const domain = new Paginated();

    domain.items = dto.items.map(item => this.domainMapper.fromDtoToDomain(item));
    domain.page = dto.page;
    domain.requestedCount = dto.requested_count;
    domain.totalMatches = dto.total_matches;

    return domain;
  }
}