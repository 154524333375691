// @ts-check

/**
 * @param {CustomApp} customApp custom app object
 * @returns {import("types/dto").CustomAppDto} Custom app dto object
 */

export class CustomAppRequestDto {
  /** @type {string} */
  _id;

  /** @type {string} */
  name;

  /** @type {string} */
  type;

  /** @type {string} */
  operator_id;

  /** @type {string|number} */
  sequence_number;

  /** @type {string} */
  status;

  /** @type {string} */
  bundle_id;

  /** @type {string|Date} */
  updated_at;

  /** @type {string|Date} */
  created_at;

  /** @type {CustomAppColorsDto} */
  colors;

  /** @type {CustomAppStringsDto} */
  strings;

  /** @type {CustomAppSettingsDto} */
  settings;

  /** @type {CustomAppFontDto} */
  fonts;

  /** @type {CustomAppImagesDto} */
  images;

  /** @type {CustomAppMenuDocumentDto[]} */
  menu_documents;
}
