import React from "react";
import PropTypes from "prop-types";
import { SaveOutlined } from "@material-ui/icons";

import { Spinner } from "components/ui/Spinner/Spinner";
import { PrimaryButton } from "components/ui/Buttons";
import { BUTTON_LABEL } from "constants/content";

const SaveAdminButton = ({ onClick, disabled, showSpinner }) => {
  return (
    <PrimaryButton onClick={onClick} disabled={disabled}>
      {!showSpinner ? (
        <>
          <SaveOutlined />
          <span>{BUTTON_LABEL.save}</span>
        </>
      ) : (
        <Spinner size={28} cv="white" />
      )}
    </PrimaryButton>
  );
};

SaveAdminButton.defaultProps = {
  disabled: false,
  showSpinner: false,
}

SaveAdminButton.propTypes = {
  disabled: PropTypes.bool, 
  onClick: PropTypes.func.isRequired,
  showSpinner: PropTypes.bool
}

export { SaveAdminButton }