import React, { createContext } from "react";

export const SortableListNextContext = createContext();

export const SortableListNextContextProvider = ({ children, value }) => {
  return (
    <SortableListNextContext.Provider value={value}>
      {children}
    </SortableListNextContext.Provider>
  )
}