import { NOTIFICATION_TYPES } from "constants/content"

export const LIST_COLUMNS = {
  [NOTIFICATION_TYPES.gps]: [
    { label: "Template", key: "id", show: true, size: "small", sortable: true },
    { label: "Notification text", key: "content", show: true, sortable: true },
    { label: "Notification URL", key: "url", show: true, sortable: true },
  ],
  [NOTIFICATION_TYPES.timed]: [
    { label: "Template", key: "id", show: true, size: "small", sortable: true },
    { label: "Notification text", key: "content", show: true, sortable: true },
    { label: "Notification URL", key: "url", show: true, sortable: true },
  ],
  [NOTIFICATION_TYPES.forced]: [
    { label: "Template", key: "id", show: true, size: "small", sortable: true },
    { label: "Notification text", key: "content", show: true, sortable: true },
  ],
}