import React from "react";
import styled from "styled-components";
import { Grid } from "@material-ui/core";
import { AccountTree, ReorderOutlined } from "@material-ui/icons";

import { ListRowSquareField } from "feature/panel/Stays/_shared/styledComponents";
import { BodyText2 } from "components/ui/Typography/Typography";

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ActionsHeader = styled(Header)`
  margin-bottom: ${({ theme }) => theme.setSpacing(10)}px;
`;

export const DirectoryWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 72px;
  border: 1px solid ${({ theme }) => theme.colors.grey10};
  ${({ isSorted }) => (!isSorted ? "border-top: none" : "")};
`;

export const ContentDivider = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const ReorderButton = styled(ListRowSquareField)`
  cursor: grab;
  border-right: 1px solid ${({ theme }) => theme.colors.grey10};
  transition: background-color 0.25s ease-in-out;

  :hover {
    background-color: ${({ theme }) => theme.colors.withOpacity(theme.colors.black, 0.08)};
  }
`;

export const StyledReorderIcon = styled(ReorderOutlined)`
  && {
    color: ${({ theme }) => theme.colors.grey50};
  }
`;

export const StyledAccountIcon = styled(AccountTree)`
  && {
    color: ${({ theme }) => theme.colors.grey50};
  }
`;

export const ContentText = styled(BodyText2)`
  && {
    color: ${({ theme }) => theme.colors.grey100};
    text-align: left;
  }
`;

export const MainContentWrapper = styled(({ expanded, ...props }) => <Grid {...props} />)`
  && {
    margin-bottom: ${({ theme }) => theme.setSpacing(10)}px;
  }
`;

export const HeaderContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: auto;
`;
