import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import SendOutlinedIcon from "@material-ui/icons/SendOutlined";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { usePermissionsService } from "hooks/usePermissionsService";
import { PERMISSIONS } from "constants/permissions";

import { TripService } from "services/TripService";

import { emailValidate } from "utils/validation";
import { setNotification } from "store/app/actions";
import { Message } from "components/ui/Messages";
import { FormSection, Input, Switch } from "components/ui/Forms";
import { SecondaryButton } from "components/ui/Buttons";
import { Table, TableHeader, TableBody, TablePagination, DeleteAction } from "components/ui/Tables";
import { useTableHandlers } from "hooks/useTableHandlers";
import { TableActionsContainer } from "components/ui/Tables/TableRowActions";
import { ERRORS, TRIP_WIZARD_CONTENT } from "constants/content";

import { TripShareAccessTableRow } from "./TripShareAccessTableRow";
import { useManageTripContext } from "../ManageTripContext";

const TripShareAccess = () => {
  /** @type {TripService} */
  const tripService = new TripService();

  /** @type {import("services/PermissionsService").PermissionsService} */
  const permissionsService = usePermissionsService();

  const dispatch = useDispatch();
  const { operator_code } = useParams();
  const [invitationFormEmail, setInvitationFormEmail] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [invitationFormWrite, setInvitationFormWrite] = useState(false);
  const [users, setUsers] = useState([]);
  const [totalMatches, setTotalMatches] = useState(0);

  const { passcode, editMode, vamoos_id } = useManageTripContext();
  const currentUserEmail = useSelector(({ auth }) => auth.user.email);

  const canEdit = permissionsService.can(PERMISSIONS.actions.update, PERMISSIONS.sections.vamoosList, vamoos_id);

  const { rowsPerPage, page, sortingOrder, sortingBy, handleChangeOrder, handleChangeRowsPerPage, handleChangePage } = useTableHandlers(
    "",
    () => {},
  );

  const headers = [
    { label: TRIP_WIZARD_CONTENT.permissions.email, key: "email", show: true, sortable: true },
    { label: TRIP_WIZARD_CONTENT.permissions.read, key: "read", show: true, sortable: true },
    { label: TRIP_WIZARD_CONTENT.permissions.write, key: "write", show: true, sortable: true },
  ];

  const fetchUsers = async () => {
    /**
     * @returns {Promise<import("domain/Paginated").Paginated<import("domain/TripPermission").TripPermission>>}
     */
    const usersList = await tripService.getTripUsers(operator_code, passcode, {
      rowsPerPage,
      page,
      order: sortingOrder,
      orderBy: sortingBy,
    });

    setTotalMatches(usersList.totalMatches);
    setUsers(
      usersList.items.map(permission => {
        return {
          id: permission.user.id,
          email: permission.user.email,
          read: permission?.vamoosPermissions?.includes("r"),
          write: permission?.vamoosPermissions?.includes("u"),
        };
      }),
    );
  };

  const inviteUser = async () => {
    if (isEmailValid && invitationFormEmail.length > 0) {
      try {
        if (currentUserEmail === invitationFormEmail) {
          await dispatch(setNotification({ type: "error", message: TRIP_WIZARD_CONTENT.permissions.notifications.inviteSelf }));
        } else {
          await tripService.addUserToTrip(invitationFormEmail, invitationFormWrite ? "write" : "read", operator_code, passcode);
          await fetchUsers();
          await dispatch(setNotification({ type: "success", message: TRIP_WIZARD_CONTENT.permissions.notifications.invitedUser }));
          setInvitationFormEmail("");
          setInvitationFormWrite(false);
        }
      } catch (e) {
        if (e.response.status === 403) {
          await dispatch(setNotification({ type: "error", message: TRIP_WIZARD_CONTENT.permissions.notifications.inviteExisting }));
        }
        await dispatch(setNotification({ type: "error", message: TRIP_WIZARD_CONTENT.permissions.notifications.fetchUsersError }));
      }
    }
  };

  const removeUser = async (user, operator, code) => {
    try {
      await tripService.removeUserFromTrip(user.id, operator, code);
      await fetchUsers();
      await dispatch(setNotification({ type: "success", message: TRIP_WIZARD_CONTENT.permissions.notifications.removedUser }));
    } catch (e) {
      await dispatch(setNotification({ type: "error", message: TRIP_WIZARD_CONTENT.permissions.notifications.fetchUsersError }));
    }
  };

  const bodyCellAction = ({ item }) => {
    const isActiveEmail = currentUserEmail === item?.email;

    return (
      <TableActionsContainer>
        {canEdit && (
          <DeleteAction
            clickAction={() => removeUser(item, operator_code, passcode)}
            tooltip={TRIP_WIZARD_CONTENT.permissions.removeUser}
            disabled={isActiveEmail}
          />
        )}
      </TableActionsContainer>
    );
  };

  useEffect(() => {
    if (editMode) {
      fetchUsers();
    }
  }, [rowsPerPage, page]);

  return (
    <Grid container justifyContent="center">
      <Grid item md={12} lg={10}>
        {editMode ? (
          <>
            <FormSection title={TRIP_WIZARD_CONTENT.permissions.inviteUser}>
              <Grid container spacing={10}>
                <Grid item xs={12} md={6}>
                  <Input
                    label={TRIP_WIZARD_CONTENT.permissions.email}
                    name="address"
                    value={invitationFormEmail}
                    onChange={({ target }) => setInvitationFormEmail(target.value)}
                    onBlur={() => {
                      if (invitationFormEmail.length > 0) {
                        setIsEmailValid(emailValidate(invitationFormEmail));
                      } else {
                        setIsEmailValid(true);
                      }
                    }}
                    error={!isEmailValid}
                    helperText={!isEmailValid ? ERRORS.invalidEmail : ""}
                    disabled={!canEdit}
                  />
                </Grid>
                <Grid item>
                  <Switch name="read" label={TRIP_WIZARD_CONTENT.permissions.read} labelPlacement="start" checked disabled />
                </Grid>
                <Grid item>
                  <Switch
                    name="read"
                    label={TRIP_WIZARD_CONTENT.permissions.write}
                    labelPlacement="start"
                    checked={invitationFormWrite}
                    onChange={() => setInvitationFormWrite(!invitationFormWrite)}
                    disabled={!canEdit}
                  />
                </Grid>
                <Grid item>
                  <SecondaryButton disabled={!canEdit || invitationFormEmail.length === 0} onClick={() => inviteUser()}>
                    <SendOutlinedIcon />
                    {TRIP_WIZARD_CONTENT.permissions.invite}
                  </SecondaryButton>
                </Grid>
              </Grid>
            </FormSection>
            {users.length > 0 && (
              <FormSection title={TRIP_WIZARD_CONTENT.permissions.invitedUsers}>
                <Table
                  headers={headers}
                  list={users}
                  actions={bodyCellAction}
                  onChangeOrder={handleChangeOrder}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                >
                  <TableHeader withActions />
                  <TableBody rowComponent={TripShareAccessTableRow} />
                  <TablePagination count={totalMatches} />
                </Table>
              </FormSection>
            )}
          </>
        ) : (
          <FormSection title={TRIP_WIZARD_CONTENT.permissions.permissions}>
            <Message type="warning" text={TRIP_WIZARD_CONTENT.permissions.message} />
          </FormSection>
        )}
      </Grid>
    </Grid>
  );
};

export { TripShareAccess };
