import React from "react";
import PropTypes from "prop-types";
import { useSelector, } from "react-redux";

import { FeatureRow } from "feature/panel/Stays/_shared/General/FeatureRow";

const FeatureRowList = ({ disabled }) => {
  const features = useSelector(state => state.stays.features.filter(feature => feature.isActive));

  return features.map(
    featureGroup => <FeatureRow key={featureGroup.id} featureGroup={featureGroup} disabled={disabled} />
  );
}

FeatureRowList.defaultProps = {
  disabled: false,
};

FeatureRowList.propTypes = {
  disabled: PropTypes.bool,
};

export { FeatureRowList };
