import * as actionTypes from "store/adminUserDetails/actionTypes";
import { createAction } from "utils/store";

export const updateAdminUserDetailsStart = createAction(actionTypes.UPDATE_ADMIN_USER_DETAILS_START);
export const updateAdminUserDetailsSuccess = createAction(actionTypes.UPDATE_ADMIN_USER_DETAILS_SUCCESS);
export const updateAdminUserDetailsFail = createAction(actionTypes.UPDATE_ADMIN_USER_DETAILS_FAIL);

export const getUserDetailsStart = createAction(actionTypes.GET_USER_DETAILS_START);
export const getUserDetailsSuccess = createAction(actionTypes.GET_USER_DETAILS_SUCCESS);
export const getUserDetailsFail = createAction(actionTypes.GET_USER_DETAILS_FAIL);

export const setValueForUserDetails = createAction(actionTypes.SET_VALUE_FOR_USER_DETAILS);
export const setErrorsForUserDetails = createAction(actionTypes.SET_ERRORS_FOR_USER_DETAILS);

export const resetPasswordAdminUserDetails = createAction(actionTypes.RESET_ADMIN_USER_DETAILS_PASSWORD);

export const getAdminUsernameUniquenessStart = createAction(actionTypes.CHECK_ADMIN_USERNAME_UNIQUENES_START);
export const getAdminUsernameUniquenessFail = createAction(actionTypes.CHECK_ADMIN_USERNAME_UNIQUENES_FAIL);

export const getAdminEmailUniquenessStart = createAction(actionTypes.CHECK_ADMIN_EMAIL_UNIQUENES_START);
export const getAdminEmailUniquenessFail = createAction(actionTypes.CHECK_ADMIN_EMAIL_UNIQUENES_FAIL);

export const clearUserDetailsErrors = createAction(actionTypes.CLEAR_USER_DETAILS_ERRORS);

export const setUserDetailsFormTouched = createAction(actionTypes.SET_USER_DETAILS_FORM_TOUCHED);
