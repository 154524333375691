import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

import ErrorOutlineOutlinedIcon from "@material-ui/icons/ErrorOutlineOutlined";

import { Link } from "components/ui/Links/Link";

const StyledListItem = styled(({ isActive, error, disabled, ...rest }) => <ListItem {...rest} />)`
  && {
    padding-left: ${({ theme }) => theme.setSpacing(7)}px;
    padding-right: ${({ theme }) => theme.setSpacing(5)}px;
    height: ${({ theme }) => theme.setSpacing(16)}px;
    display: flex;
    align-items: center;
    ${({ theme, error, isActive, disabled }) => {
      if (isActive) {
        let color = error ? "error" : "brand";
        color = disabled ? "grey10" : color;
        return `
          background: ${theme.colors.withOpacity(theme.colors[color], 0.1)};
          :hover {
            background: ${theme.colors.withOpacity(theme.colors[color], 0.25)}
          }
        `;
      }
      return null;
    }}

    .MuiTouchRipple-root {
      .MuiTouchRipple-rippleVisible {
        .MuiTouchRipple-child {
          color: ${({ theme, isActive, disabled }) => {
            if(disabled) {
              return theme.colors.withOpacity(theme.colors.grey10, 0.1);
            } 
            return isActive ? theme.colors.brand : theme.colors.grey50
          }};
        }
      }
    }
  }
`;

const StyledListItemIcon = styled(({ isActive, error, ...rest }) => <ListItemIcon {...rest} />)`
  && {
    min-width: ${({ theme }) => theme.setSpacing(10)}px;
    color: ${({ theme, error, isActive }) => {
      if (isActive && !error) return theme.colors.brand;
      if (error) return theme.colors.error;
      return theme.colors.grey50;
    }};
  }
`;

const StyledListItemText = styled(({ isActive, disabled, error, ...rest }) => <ListItemText {...rest} />)`
  && {
    .MuiTypography-root {
      color: ${({ theme, error, isActive, disabled }) => {
        if (disabled) return theme.colors.grey50;
        if (isActive && !error) return theme.colors.brand;
        if (error) return theme.colors.error;
        return theme.colors.grey100;
      }};
      font-weight: ${({ theme }) => theme.fonts.fontWeight.semiBold};
    }
    .MuiTypography-body1 {
      font-family: Montserrat !important;
      letter-spacing: 0;
    }
  }
`;

const ContextMenuButton = ({ icon, label, url, action, error, isActive, disabled, replace }) => {
  const ButtonContent = () => (
    <>
      {(icon || error) && (
        <StyledListItemIcon isActive={isActive} error={error}>
          {error ? <ErrorOutlineOutlinedIcon /> : icon}
        </StyledListItemIcon>
      )}
      <StyledListItemText primary={label} isActive={isActive} error={error} disabled={disabled} />
    </>
  );

  const LinkedButton = () => (
    <Link to={url} disabled={disabled} replace={replace}>
      <StyledListItem button isActive={isActive} error={error}>
        <ButtonContent />
      </StyledListItem>
    </Link>
  );

  const ActionButton = () => (
    <StyledListItem button onClick={action} isActive={isActive}>
      <ButtonContent />
    </StyledListItem>
  );

  const setReturnedButton = () => {
    if (disabled) {
      return (
        <StyledListItem button disabled>
          <ButtonContent />
        </StyledListItem>
      );
    }
    if (!!url === true && !!action === false) return <LinkedButton />;
    if (!!url === false && !!action === true) return <ActionButton />;
    return null;
  };

  return setReturnedButton();
};

ContextMenuButton.defaultProps = {
  icon: null,
  url: "",
  action: null,
  isActive: false,
  error: undefined,
};

ContextMenuButton.propTypes = {
  icon: PropTypes.element,
  label: PropTypes.string.isRequired,
  url: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  action: PropTypes.func,
  isActive: PropTypes.bool,
  error: PropTypes.bool,
};

export { ContextMenuButton };
