import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { BodyText2 } from "components/ui/Typography/Typography";
import { Checkbox } from "components/ui/Forms";

const IncludedRowArea = styled.div`
  margin-bottom: 12px;
  width: 100%;
  display: flex;
  align-items: center;
  height: 32px;
  justify-content: space-between;
`;

const IncludedRow = styled.div`
  display: flex;
  align-items: center;
`;

const CopyingTripModalListElement = ({ label, elementIdentifier, onChange, value, children }) => (
  <IncludedRowArea>
    <IncludedRow>
      <Checkbox checked={value} onChange={() => onChange(elementIdentifier)} />
      <BodyText2>{label}</BodyText2>
    </IncludedRow>
    {children}
  </IncludedRowArea>
);

CopyingTripModalListElement.defaultProps = {
  children: undefined,
};

CopyingTripModalListElement.propTypes = {
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.bool.isRequired,
  elementIdentifier: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.element]),
};

export { CopyingTripModalListElement, IncludedRowArea, IncludedRow };
