import React, { useContext, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import moment from "moment";
import Grid from "@material-ui/core/Grid";
import { Tooltip } from "@material-ui/core";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";

import { BodyText } from "components/ui/Typography/Typography";
import { ActionButton } from "components/ui/Buttons";
import { EntriesContext } from "feature/panel/Trips/_shared/Storyboard/Entries/EntriesContext";
import { useManageTripContext } from "feature/panel/Trips/_shared/ManageTripContext";
import { BackgroundPreview } from "feature/panel/Trips/_shared/Storyboard/Entries/EntryForm/BackgroundPreview";
import { EntryDetailsForm } from "feature/panel/Trips/_shared/Storyboard/Entries/EntryForm/EntryDetailsForm";
import { EntryDocuments } from "feature/panel/Trips/_shared/Storyboard/Entries/EntryForm/EntryDocuments";
import config from "config/app";
import { GLOBAL_CONTENT, TRIP_WIZARD_CONTENT } from "constants/content";

import { AddToLibraryIcon } from "components/ui/Icons/AddToLibraryIcon";
import { usePermissionsService } from "hooks/usePermissionsService";
import { PERMISSIONS } from "constants/permissions";
import { ConfirmationModal } from "components/ui/Modals/ConfirmationModal";
import { PanelTemplateContext } from "components/templates/Panel/PanelTemplateContext";

const StyledWrapper = styled.div`
  width: 100%;
  border-top: 1px solid ${({ theme }) => theme.colors.grey10};
  padding: ${({ theme }) => theme.setSpacing(6)}px 0;
  height: auto;
`;

const EntryActionsContainer = styled.div`
  margin: 0 0 0 auto;
  display: flex;
`;

const EntryActionsItem = styled.div`
  margin: 0 0 0 24px;
`;

const EntryForm = ({ item, index, onAddToLibrary, isNew, scrollConfirm, disabled }) => {
  const { position, meta, image } = item;
  const formContanerRef = useRef(null);
  const { removeDay, show_days } = useContext(EntriesContext);
  const { scrollToElement } = useContext(PanelTemplateContext);
  const { departure_date, locations, errors } = useManageTripContext();
  const { details } = errors;

  const [showPreventModal, setShowPreventModal] = useState(false);

  const permissionsService = usePermissionsService();

  const canAddToLibrary = permissionsService.can(PERMISSIONS.actions.create, PERMISSIONS.sections.operator, PERMISSIONS.resources.library);

  const date = moment(departure_date)
    .add(meta?.day_number - 1, "d")
    .format(config.storyboardDateFormat);

  const handleDeleteDay = () => removeDay(item);

  const handleScrollToNewElement = () => {
    if (isNew && formContanerRef.current?.offsetTop) {
      setTimeout(() => {
        scrollToElement(formContanerRef.current);
        scrollConfirm();
      }, 0);
    }
  };

  const handleAddToLibrary = itemToBeSaved => {
    if (itemToBeSaved.image && !itemToBeSaved.image.file_id) {
      setShowPreventModal(true);
    } else {
      onAddToLibrary(itemToBeSaved);
    }
  };

  useEffect(handleScrollToNewElement, [isNew]);

  return (
    <div ref={formContanerRef}>
      <StyledWrapper>
        <Grid container spacing={10}>
          <Grid item xs={12} container alignItems="center">
            <BodyText>
              <span>
                <strong>{show_days ? `${TRIP_WIZARD_CONTENT.storyboard.day}` : "#"}</strong>
              </span>
              <span>
                <strong>{show_days ? `${meta?.day_number ? ` ${meta?.day_number}` : ""}` : position}</strong>
                {show_days && (
                  <>
                    <strong>:</strong>
                    <strong> </strong>
                    <span>{show_days && meta?.day_number && date}</span>
                  </>
                )}
              </span>
            </BodyText>
            <EntryActionsContainer>
              {canAddToLibrary && (
                <>
                  <Tooltip placement="bottom" title={TRIP_WIZARD_CONTENT.storyboard.addToLibrary}>
                    <EntryActionsItem>
                      <ActionButton size="small" onClick={() => handleAddToLibrary(item)}>
                        <AddToLibraryIcon />
                      </ActionButton>
                    </EntryActionsItem>
                  </Tooltip>
                  {showPreventModal && (
                    <ConfirmationModal
                      open={showPreventModal}
                      title={TRIP_WIZARD_CONTENT.storyboard.youHaveToSaveTrip}
                      cancelLabel={null}
                      confirmLabel={GLOBAL_CONTENT.back}
                      onConfirm={() => setShowPreventModal(false)}
                    />
                  )}
                </>
              )}
              {!disabled && (
                <EntryActionsItem>
                  <ActionButton size="small" onClick={handleDeleteDay}>
                    <DeleteOutlineIcon />
                    {GLOBAL_CONTENT.delete}
                  </ActionButton>
                </EntryActionsItem>
              )}
            </EntryActionsContainer>
          </Grid>
          <Grid item lg={2} md={4} sm={6} xs={12}>
            <BackgroundPreview image={image} />
          </Grid>
          <Grid item lg={8} sm={12}>
            <EntryDetailsForm disabled={disabled} item={item} index={index} locations={locations} />
            <EntryDocuments disabled={disabled} item={item} errors={details && details[index] ? details[index] : {}} />
          </Grid>
        </Grid>
      </StyledWrapper>
    </div>
  );
};

EntryForm.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    position: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    image: PropTypes.shape(),
    headline: PropTypes.string,
    documents: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    content: PropTypes.string.isRequired,
    meta: PropTypes.shape(),
  }).isRequired,
  isNew: PropTypes.bool.isRequired,
  scrollConfirm: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  onAddToLibrary: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export { EntryForm, StyledWrapper };
