import { TripPermission } from "domain/TripPermission";
import { BaseMapper } from "./BaseMapper";
import { UserMapper } from "./UserMapper";

export class TripPermissionMapper extends BaseMapper {

  /**
   * 
   * @param {import("mappers/UserMapper").UserMapper=} userMapper 
   */
  constructor(
    userMapper = new UserMapper()
  ) {
    super();
    this.userMapper = userMapper;
  }

  fromDtoToDomain(dto) {
    const domain = new TripPermission();

    domain.id = dto.id;
    domain.userId = dto.user_id;
    domain.vamoosId = dto.vamoos_id;
    domain.operatorId = dto.operator_id;
    domain.user = this.userMapper.fromDtoToDomain(dto.user);
    domain.vamoosPermissions = dto.vamoos_permissions;
    domain.createdAt = dto.created_at;
    domain.updatedAt = dto.updated_at;

    return domain;
  }
}