import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { TableCell } from "@material-ui/core";

import { useTableContext } from "components/ui/Tables/TableContext";
import { ActionTableCell } from "components/ui/Tables/TableRow";
import { TextLimiter } from "components/ui/TextLimiter";
import { StyledMuiTableRow } from "components/ui/Tables/_shared/styledComponents";

import { LinkedTableRowCell } from "components/ui/Tables/LinkedTableRowCell";

import { setUrl } from "utils/url";
import { firstLetterUpperCase } from "utils/string";
import { DEFAULT_CELL_MAX_CHARACTERS } from "constants/defaults";

import config from "config/app";
import { TableActionsContainer } from "components/ui/Tables/TableRowActions";
import { usePermissionsService } from "hooks/usePermissionsService";

import { PERMISSIONS } from "constants/permissions";

import { CUSTOM_APP_STATUS_ACCEPTED, CUSTOM_APP_STATUS_APPROVED } from "../_shared/constants/customAppStatus";

const CustomAppsTableRow = ({ item }) => {
  const { url, urlParams, actions: Actions, headers } = useTableContext();

  const permissionsService = usePermissionsService();

  const canReadAdmin = permissionsService.can(PERMISSIONS.actions.read, PERMISSIONS.sections.admin)

  const renderCell = header => {
    const { key } = header;

    let content = null;
    switch (key) {
      case "updated_at": {
        content = () => moment(item.updatedAt).format(config.dateTimeFormat);
        break;
      }
      case "created_at": {
        content = () => moment(item.createdAt).format(config.dateTimeFormat);
        break;
      }
      case "status": {
        content = () => {
          if (item.status === CUSTOM_APP_STATUS_APPROVED) {
            return firstLetterUpperCase(CUSTOM_APP_STATUS_ACCEPTED || "");
          }
          
          return firstLetterUpperCase(item?.status || "");
        };
        break;
      }
      default: {
        content = () => <TextLimiter maxChar={DEFAULT_CELL_MAX_CHARACTERS} text={item[header.key]} />;
      }
    }

    return item.status === "submitted" && !canReadAdmin ? (
      <TableCell key={header.key}>{content()}</TableCell>
    ) : (
      <LinkedTableRowCell url={setUrl(url, { id: item[urlParams[0]] })} key={header.key}>
        {content()}
      </LinkedTableRowCell>
    );
  };

  const cellsToRender = headers.filter(header => header.show).map(header => renderCell(header));

  return (
    <StyledMuiTableRow item={item}>
      {cellsToRender}
      <ActionTableCell align="right">
        <TableActionsContainer>
          <Actions item={item} />
        </TableActionsContainer>
      </ActionTableCell>
    </StyledMuiTableRow>
  );
};

CustomAppsTableRow.propTypes = {
  item: PropTypes.shape().isRequired,
};

export { CustomAppsTableRow };
