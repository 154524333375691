import { INSPIRATIONS_GET_URL, INSPIRATION_URL } from "constants/api";
import { HttpClient } from "services/application/httpClient/httpClient";
import { setUrl, setUrlParams } from "utils/url";

export class InspirationRepository {
  constructor() {
    this.httpClient = HttpClient;
  }

  async getList(page, rowsPerPage, sortingOrder, sortingBy) {
    const url = setUrlParams(INSPIRATIONS_GET_URL, {
      page,
      count: rowsPerPage,
      order: sortingOrder,
      order_by: sortingBy
    });

    const { data } = await this.httpClient.get(url);

    return data;
  }

  async saveInspiration(payload) {
    const { reference_code, operator_code, ...inspirationPayload } = payload;
    const url = setUrl(INSPIRATION_URL, { operator_code, reference_code }, true);

    const { data } = await this.httpClient.post(url, { 
      ...inspirationPayload, 
      new_only: !inspirationPayload.vamoos_id 
    });

    return data;
  }
}