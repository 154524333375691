import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import styled, { css } from "styled-components";

import TableCell from "@material-ui/core/TableCell";

import { Chip } from "components/ui/Chips";
import { useTableContext } from "components/ui/Tables/TableContext";
import { ActionTableCell } from "components/ui/Tables/TableRow";
import { TextLimiter } from "components/ui/TextLimiter";
import { StyledMuiTableRow } from "components/ui/Tables/_shared/styledComponents";

import { LinkedTableRowCell } from "components/ui/Tables/LinkedTableRowCell";

import { setUrl, setParams } from "utils/url";
import { DEFAULT_CELL_MAX_CHARACTERS } from "constants/defaults";

import config from "config/app";
import { TableActionsContainer } from "components/ui/Tables/TableRowActions";
import { deviceType } from "utils/deviceDetect";

const StyledTableRow = styled(({ item, ...props }) => <StyledMuiTableRow {...props} />)`
  && {
    ${({ archived, theme }) =>
      archived === "true"
        ? css`
            background: ${theme.colors.withOpacity(theme.colors.errorLight, 0.1)};

            :hover {
              background: ${theme.colors.withOpacity(theme.colors.errorLight, 0.2)};
            }

            a {
              color: ${theme.colors.errorLight};
            }

            .MuiChip-root {
              border-color: ${theme.colors.errorLight};
              color: ${theme.colors.errorLight};
            }
          `
        : ""}
  }
`;

const ItineraryTableRow = ({ item }) => {
  const { url, urlParams, actions: Actions, headers } = useTableContext();

  const renderCell = header => {
    const { key } = header;

    let content = null;
    switch (key) {
      case "downloads": {
        return (
          <TableCell key={key}>
            <Chip label={item.downloads || 0} />
          </TableCell>
        );
      }
      case "updated_at":
      case "original_created_at": {
        content = () => moment(item[key]).format(config.dateTimeFormat);
        break;
      }
      case "address": {
        content = () => item.meta?.[key];
        break;
      }
      case "return_date":
      case "departure_date": {
        const momentData = moment(item[key], config.apiDateFormat);
        content = () => (momentData.isValid() ? momentData.format(config.dateFormat) : item[key]);
        break;
      }
      default: {
        content = () => <TextLimiter maxChar={DEFAULT_CELL_MAX_CHARACTERS} text={item[header.key]} />;
      }
    }

    return (
      <LinkedTableRowCell url={setUrl(url, setParams(item, urlParams))} key={header.key}>
        {content()}
      </LinkedTableRowCell>
    );
  };

  const device = deviceType();

  const data = headers[device] || headers[headers.defaultDevice] || headers.all;

  const cellsToRender = data?.filter(header => header.show).map(header => renderCell(header));

  const { is_active: isActive } = item;

  return (
    <StyledTableRow item={item} archived={(!isActive).toString()}>
      {cellsToRender}
      <ActionTableCell align="right">
        <TableActionsContainer>
          <Actions item={item} archived={!isActive} />
        </TableActionsContainer>
      </ActionTableCell>
    </StyledTableRow>
  );
};

ItineraryTableRow.propTypes = {
  item: PropTypes.shape().isRequired,
};

export { ItineraryTableRow, StyledTableRow };
