import React, { useContext } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { ErrorOutline } from "@material-ui/icons";

import { PromptContext } from "components/ui/CustomPrompt/CustomPrompt";
import { useListOfLanguages } from "hooks/useListOfLanguages";
import { colors } from "config/theme/colors";

import { LocalLanguageModal } from "./LocalLanguageModal";

const LanguageSelectorList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px 5px;
  ${({ center }) => (center ? "justify-content: center" : "")}
`;

export const LanguageSelectorPill = styled.button`
  padding: 8px 24px;
  border-radius: 10px;
  font-weight: 600;
  cursor: pointer;
  white-space: nowrap;
  height: 40px;
  display: flex;
  align-items: center;
  border: none;
  font-family: ${({ theme }) => theme.fonts.font};
  font-size: ${({ theme }) => theme.fonts.fontSize.body2}px;
  background: ${p => (p.isPillActive ? "#F4F4F4" : "#f5f5f5")};
  .MuiBadge-badge{
    top: -10px;
    right: -3px;
    font-size: 12px;
    max-width:  ${p => (p.isError ? "10px" : "50px")};
    background:  ${p => (p.isError ? "red" : colors.brand)};
    color: #fff;
    font-weight: ${p => (p.isError ? 700 : 400)};
  }

  ${({ isActive, theme }) =>
    !isActive &&
    `
    color: ${theme.colors.grey30};
  `}

  ${({ isPillActive, theme }) =>
    isPillActive &&
    `
    color: ${theme.colors.brand};
    background: ${theme.colors.descriptionBackground}
  `}

  ${({ error }) =>
    error &&
    `
    color: red;
  `}

  .MuiSvgIcon-root {
    margin: 0 8px 0 0;
  }
`;

const LanguageSelector = ({
  currentLanguage,
  onChange,
  languages,
  onLanguageSwitch,
  onEditToggle,
  errors,
  center,
  editableLanguages,
  canSwitchLanguage,
  multiple,
}) => {
  const allLanguages = useListOfLanguages();
  const defaultLanguageCode = useSelector(({ operator }) => operator.currentOperator.defaultLanguageCode);
  const languageCodes = useSelector(({ operator }) => operator.currentOperator.additionalLanguageCodes) || [];
  const { enabled, showPrompt } = useContext(PromptContext);
  const languageToDisplay = languages.length
    ? languages
    : allLanguages
        .filter(lang => languageCodes.includes(lang.code) || lang.code === defaultLanguageCode)
        .map(lang => ({
          ...lang,
          isMaster: lang.isMaster,
          isActive: lang.isActive,
          isDefault: lang.code === defaultLanguageCode,
        }));

  languageToDisplay.sort((a, b) => {
    if (a.isDefault && !b.isDefault) {
      return -1;
    }

    if (!a.isDefault && b.isDefault) {
      return 1;
    }

    return a.name.localeCompare(b.name);
  });

  const isPillActive = code => {
    if (multiple) {
      return currentLanguage?.includes(code);
    } else {
      return code === (currentLanguage?.code || currentLanguage);
    }
  };

  return (
    <LanguageSelectorList center={center}>
      {[...languageToDisplay].map(({ code, isActive, isDefault }) => (
        <LanguageSelectorPill
          key={code}
          onClick={async () => {
            if (enabled && canSwitchLanguage) {
              showPrompt({
                to: "",
                callback: async () => {
                  await onLanguageSwitch(languageToDisplay.find(lang => lang.code === code));
                  await onChange(languageToDisplay.find(lang => lang.code === code));
                },
              });
            } else {
              await onLanguageSwitch(languageToDisplay.find(lang => lang.code === code));

              if (canSwitchLanguage) {
                await onChange(languageToDisplay.find(lang => lang.code === code));
              }
            }
          }}
          isActive={languages.length > 1 ? isActive : true}
          isPillActive={isPillActive(code)}
          error={isDefault ? errors.includes("default") || errors.includes(code) : errors.includes(code)}
        >
          {(isDefault ? errors.includes("default") || errors.includes(code) : errors.includes(code)) && <ErrorOutline />}
          {languageToDisplay.find(lang => lang.code === code)?.name}
        </LanguageSelectorPill>
      ))}
      {languages.length > 0 && editableLanguages && <LocalLanguageModal languages={languages} onLanguagesEdit={onEditToggle} />}
    </LanguageSelectorList>
  );
};

LanguageSelector.defaultProps = {
  languages: [],
  onEditToggle: () => {},
  errors: [],
  onLanguageSwitch: () => {},
  center: false,
  editableLanguages: true,
  canSwitchLanguage: true,
};

LanguageSelector.propTypes = {
  currentLanguage: PropTypes.oneOfType([
    PropTypes.shape({
      code: PropTypes.string,
    }),
    PropTypes.array,
    PropTypes.string,
  ]).isRequired,
  onChange: PropTypes.func.isRequired,
  onLanguageSwitch: PropTypes.func,
  languages: PropTypes.arrayOf(PropTypes.shape()),
  onEditToggle: PropTypes.func,
  errors: PropTypes.arrayOf(PropTypes.string),
  center: PropTypes.bool,
  editableLanguages: PropTypes.bool,
  canSwitchLanguage: PropTypes.bool,
};

export { LanguageSelector };
