import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { PropTypes } from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import SmartphoneIcon from "@material-ui/icons/Smartphone";
import SaveOutlinedIcon from "@material-ui/icons/SaveOutlined";

import { PanelTemplate } from "components/templates/Panel/PanelTemplate";
import { ContextBarTitle } from "components/templates/Panel/ContextBarTitle";
import { BodyText } from "components/ui/Typography/Typography";
import { LoadingScreen } from "components/ui/LoadingScreen/LoadingScreen";
import { ActionButton, BackHistoryButton, CancelHistoryButton, PrimaryButton } from "components/ui/Buttons";

import { validateInspirationForm } from "feature/panel/Inspirations/_shared/validateInspirationForm";
import { ManageInspiration } from "feature/panel/Inspirations/_shared/ManageInspiration";
import { ManageInspirationContext } from "feature/panel/Inspirations/_shared/ManageInspirationContext";
import { isInspirationSaveActive } from "feature/panel/Inspirations/_shared/inspirationTransformations";

import initialInspiration from "feature/panel/Inspirations/_shared/InspirationInitialState";

import { sendInspirationForceUpdate } from "feature/panel/Trips/_shared/sendForceUpdate";

import { isObjectEmpty } from "utils/object";
import { setUrl } from "utils/url";
import { HttpClient } from "services/application/httpClient/httpClient";

import { PANEL_INSPIRATIONS_PATH, PANEL_INSPIRATIONS_EDIT_PATH } from "constants/routes";
import { INSPIRATION_URL } from "constants/api";
import { hideSavingCover, pushErrorNotification, pushSuccessNotification, showSavingCover, tryRouteChangeStart } from "store/app/actions";
import { GLOBAL_CONTENT, INSPIRATIONS, SITE_LEAVE_WARNING_MESSAGES } from "constants/content";

import { Inspiration } from "domain/Inspiration";
import { useDefaultReducer } from "hooks/useDefaultReducer";
import { usePermissionsService } from "hooks/usePermissionsService";
import { PERMISSIONS } from "constants/permissions";
import { useExtendedNavigate } from "hooks/useExtendedNavigate";
import { usePrompt } from "components/ui/CustomPrompt/CustomPrompt";
import { useService } from "hooks/useService";
import { InspirationService } from "services/InspirationService";
import notifications from "constants/notifications";
import { Logger } from "services/application/Logger";
import { InspirationPasscodeAlreadyUsedException } from "exceptions/InspirationPasscodeAlreadyUsedException";
import { ForceUpdateModal } from "feature/panel/_shared/ForceUpdateModal/ForceUpdateModal";

const EditInspiration = ({ hasPermission }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const navigateWithPrompt = useExtendedNavigate();
  const permissionsService = usePermissionsService();

  const inspirationService = useService(InspirationService);

  const [formErrors, setFormErrors] = useState({});
  const { state: editedInspiration, dispatch: dispatchInspiration, setValueFor } = useDefaultReducer(initialInspiration);
  const [originalInspiration, setOriginalInspiration] = useState({});

  const [isLoading, setIsLoading] = useState(true);
  const [isTouched, setIsTouched] = useState(false);
  const [isForceUpdateOpened, setIsForceUpdateOpened] = useState(false);
  const errors = useSelector(state => state.inspirations.errors);

  const canEdit = permissionsService.can(PERMISSIONS.actions.update, PERMISSIONS.sections.operator, PERMISSIONS.resources.inspirations);

  usePrompt(SITE_LEAVE_WARNING_MESSAGES.createTitle(SITE_LEAVE_WARNING_MESSAGES.contexts.inspirationEditor), isTouched);

  const { passcode, userId } = useParams();

  const findEditedInspiration = () => {
    setIsLoading(true);
    const url = setUrl(INSPIRATION_URL, { operator_code: userId, reference_code: passcode }, true);
    HttpClient.get(url)
      .then(({ data }) => {
        const inspiration = Inspiration(data);
        dispatchInspiration({
          type: "setAllValues",
          payload: inspiration,
        });
        setOriginalInspiration(inspiration);
        setIsTouched(false);
        setIsLoading(false);
      })
      .catch(() => {
        dispatchInspiration({
          type: "setAllValues",
          payload: {},
        });
      });
  };

  const updateOriginalInspiration = async () => {
    await setOriginalInspiration(editedInspiration);
    const { reference_code, operator_code } = editedInspiration;
    if (reference_code && operator_code && (passcode !== reference_code || userId !== operator_code)) {
      navigate(setUrl(PANEL_INSPIRATIONS_EDIT_PATH, { passcode: reference_code, userId: operator_code }, true));
    }
  };

  const setRequestErrors = () => {
    if (errors) {
      setFormErrors(errors);
    }
  };

  const handleInspirationSave = async () => {
    const staticErrors = validateInspirationForm(editedInspiration);
    const shouldTriggerAction = isObjectEmpty(staticErrors);

    setFormErrors(staticErrors);

    if (shouldTriggerAction) {
      try {
        dispatch(showSavingCover());
        await inspirationService.saveInspiration(editedInspiration);
        setIsTouched(false);
        dispatch(pushSuccessNotification(notifications.resource("inspiration").update.success));
        setFormErrors({});
        updateOriginalInspiration();
      } catch (e) {
        if (e instanceof InspirationPasscodeAlreadyUsedException) {
          setFormErrors({
            reference_code: e.message,
          });
        } else {
          dispatch(pushErrorNotification(notifications.resource("inspiration").update.fail));
        }
        Logger.debug(e);
      } finally {
        dispatch(hideSavingCover());
      }
    }
  };

  const handleInspirationForceUpdate = text => {
    const { operator_code, reference_code } = originalInspiration;
    sendInspirationForceUpdate({ operator_code, reference_code, notification_text: text || editedInspiration.notification_text, dispatch });

    setIsForceUpdateOpened(false);
  };

  const sectionTitle = (
    <>
      <span>{`${INSPIRATIONS.editInspiration} `}</span>
      <strong>{editedInspiration.name || ""}</strong>
    </>
  );

  const saveDisabled = !isInspirationSaveActive(editedInspiration) || !isTouched || isObjectEmpty(editedInspiration);

  const handleCancelButton = () => {
    dispatch(tryRouteChangeStart(PANEL_INSPIRATIONS_PATH));
    navigateWithPrompt(PANEL_INSPIRATIONS_PATH);
  };

  const onShowModal = () => setIsForceUpdateOpened(true);

  const contextBar = {
    left: () =>
      isTouched ? (
        <CancelHistoryButton role="link" clickHandler={handleCancelButton} />
      ) : (
        <BackHistoryButton role="link" clickHandler={handleCancelButton} />
      ),
    middle: () => <ContextBarTitle title={sectionTitle} />,
    right: () =>
      canEdit ? (
        <>
          <ActionButton mr={6} onClick={onShowModal} disabled={isObjectEmpty(editedInspiration)}>
            <SmartphoneIcon />
            <span>{GLOBAL_CONTENT.forceUpdate}</span>
          </ActionButton>
          <PrimaryButton onClick={handleInspirationSave} disabled={saveDisabled}>
            <SaveOutlinedIcon />
            <span>{GLOBAL_CONTENT.save}</span>
          </PrimaryButton>
        </>
      ) : null,
  };

  const content = (() => {
    if (isLoading) return <LoadingScreen />;
    if (isObjectEmpty(editedInspiration)) return <BodyText>{INSPIRATIONS.inspirationNotFound}</BodyText>;
    return <ManageInspiration errors={formErrors} canEdit={canEdit} />;
  })();

  const handleSetValueFor = (fieldName, value, shouldTouchTheForm = true) => {
    setValueFor(fieldName, value);
    if (!isTouched) {
      setIsTouched(shouldTouchTheForm);
    }
  };

  const context = {
    setValueFor: handleSetValueFor,
    ...editedInspiration,
    mode: "edit",
  };

  const onCloseModal = () => setIsForceUpdateOpened(false);

  useEffect(findEditedInspiration, [passcode, userId]);
  useEffect(setRequestErrors, [errors]);

  return (
    <PanelTemplate hasPermission={hasPermission} contextBar={contextBar} whiteBg>
      <ForceUpdateModal open={isForceUpdateOpened} onCancel={onCloseModal} onConfirm={handleInspirationForceUpdate} />
      <ManageInspirationContext.Provider value={context}>{content}</ManageInspirationContext.Provider>
    </PanelTemplate>
  );
};

EditInspiration.propTypes = {
  hasPermission: PropTypes.bool.isRequired,
};

export { EditInspiration };
