import { NOTIFICATION_ALL_URL, NOTIFICATION_CREATE_URL, NOTIFICATION_ELEMENT_URL, NOTIFICATION_LIST_URL } from "constants/api";
import { HttpClient } from "services/application/httpClient/httpClient";
import { setUrl, setUrlParams } from "utils/url";

export class NotificationRepository {
  constructor() {
     /**
     * @type {import("services/application/httpClient/httpClient").HttpClient}
     */
    this.httpClient = HttpClient;
  }

  async getNotifications(type, {
    rowsPerPage,
    page,
    sortBy = "",
    order = "desc"
}) {
    const requestParams = {
      count: rowsPerPage,
      page,
      order_by: sortBy?.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`),
      order,
    }

    const url = setUrlParams(setUrl(NOTIFICATION_LIST_URL, { type }), requestParams);
    const { data } = await this.httpClient.get(url);
    return data;
  }

  async getAllNotifications() {
    const { data } = await this.httpClient.get(NOTIFICATION_ALL_URL);

    return data;
  }

  async createNotification(notification) {
    const { data } = await this.httpClient.post(NOTIFICATION_CREATE_URL, notification);

    return data;
  }

  async updateNotification(notification) {
    const { id, type, content, url, localisation } = notification;
    const editedNotification = { type, content, url, localisation };
    const requestUrl = setUrl(NOTIFICATION_ELEMENT_URL, { id });
    const { data } = await this.httpClient.post(requestUrl, editedNotification);

    return data;
  }

  async removeNotification(notification) {
    const requestUrl = setUrl(NOTIFICATION_ELEMENT_URL, { id: notification.id });
    const { data } = await this.httpClient.delete(requestUrl);

    return data;
  }
}