import { Grid } from "@material-ui/core";
import React from "react";
import { useLocation } from "react-router-dom";

import { LoginScreenSection } from "./LoginScreenSection";

const LoginScreen = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const isHistoryVersion = queryParams.get("versionHistory");
  
  return (
    <Grid>
      <Grid>
        <LoginScreenSection isHistoryVersion={isHistoryVersion} />
      </Grid>
    </Grid>
  );
};

export { LoginScreen };
