import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import Button from "@material-ui/core/Button";
import {
  DeleteOutlined,
  GetAppOutlined,
  EditOutlined,
  FileCopyOutlined,
  HistoryOutlined,
  AutorenewOutlined,
  PlaylistAddOutlined,
  MessageOutlined,
  VpnKeyOutlined,
  PanoramaFishEye,
  RemoveRedEye,
} from "@material-ui/icons";
import Tooltip from "@material-ui/core/Tooltip";

import { Switch } from "components/ui/Forms";

const baseStyling = css`
  && {
    color: ${({ theme }) => theme.colors.grey50};
    width: ${({ theme }) => theme.setSpacing(18)}px;
    height: ${({ height, theme }) => (height && typeof height === "number" ? theme.setSpacing(height) : theme.setSpacing(18))}px;
    border-radius: 0;
  }
`;

const ActionWrapper = styled.span`
  border-left: ${({ theme, noBorder }) => (noBorder ? "none" : `1px solid ${theme.colors.grey10}`)};
  background-color: ${({ theme, noBackground }) => (noBackground ? "transparent" : theme.colors.withOpacity(theme.colors.grey10, 0.3))};

  &:first-child {
    border-left: none;
  }
`;

export const BaseButton = styled(({ ...rest }) => <Button {...rest} />)`
  ${baseStyling};
`;

export const BaseAction = ({ clickAction, icon, tooltip, disabled, noBackground, noBorder, ...rest }) => {
  const buttonWithTooltip = () => (
    <Tooltip title={tooltip}>
      <ActionWrapper noBackground={noBackground} noBorder={noBorder}>
        <BaseButton onClick={clickAction} disabled={disabled} {...rest}>
          {icon}
        </BaseButton>
      </ActionWrapper>
    </Tooltip>
  );

  const buttonWithoutTooltip = () => (
    <ActionWrapper noBackground={noBackground}>
      <BaseButton onClick={clickAction} disabled={disabled} {...rest}>
        {icon}
      </BaseButton>
    </ActionWrapper>
  );

  return <>{tooltip && !disabled ? buttonWithTooltip() : buttonWithoutTooltip()}</>;
};

BaseAction.defaultProps = {
  clickAction: () => {},
  tooltip: null,
  disabled: false,
  noBackground: false,
  noBorder: false,
};

BaseAction.propTypes = {
  clickAction: PropTypes.func,
  icon: PropTypes.element.isRequired,
  tooltip: PropTypes.string,
  disabled: PropTypes.bool,
  noBackground: PropTypes.bool,
  noBorder: PropTypes.bool,
};

export const SwitchWrapper = styled.div`
  ${baseStyling};
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme, noBackground }) => (noBackground ? "transparent" : theme.colors.withOpacity(theme.colors.grey10, 0.3))};
`;

export const SwitchAction = ({ clickAction, ...props }) => (
  <SwitchWrapper>
    <Switch onClick={clickAction} {...props} />
  </SwitchWrapper>
);

SwitchAction.propTypes = {
  clickAction: PropTypes.func.isRequired,
};

export const TableActionsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const ShowAction = props => <BaseAction icon={<FileCopyOutlined />} {...props} />;
export const MessageAction = props => <BaseAction icon={<MessageOutlined />} {...props} />;
export const EditAction = props => <BaseAction icon={<EditOutlined />} {...props} />;
export const DownloadAction = props => <BaseAction icon={<GetAppOutlined />} {...props} />;
export const DeleteAction = props => <BaseAction icon={<DeleteOutlined />} {...props} />;
export const RevertAction = props => <BaseAction icon={<HistoryOutlined />} {...props} />;
export const RefreshAction = props => <BaseAction icon={<AutorenewOutlined />} {...props} />;
export const AddAction = props => <BaseAction icon={<PlaylistAddOutlined />} {...props} />;
export const KeyAction = props => <BaseAction icon={<VpnKeyOutlined />} {...props} />;
export const ViewAction = props => <BaseAction icon={<RemoveRedEye />} {...props} />;
