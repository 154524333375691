import React from "react";
import PropTypes from "prop-types";
import { ThemeProvider } from "styled-components";

import theme from "config/theme";

const WithTheme = ({ children }) => {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

WithTheme.propTypes = {
  children: PropTypes.node.isRequired,
};

export { WithTheme };
