import React, { useState, useEffect, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import uuidv4 from "uuid";

import { Content } from "components/ui/Content/Content";
import { Input } from "components/ui/Forms";

import { LanguageContext } from "components/_shared/LanguageSelector/LanguageContext";

import { checkErrorExistFor, isValidCoordinatePart } from "utils/validation";
import { extractCoordinates } from "utils";
import { setStayWizardAddress, setStayWizardCoordinates } from "store/stayWizard/actions";
import { STAY_WIZARD_CONTENT } from "constants/content";
import MapWidget from "feature/panel/_shared/MapWidget";

import { LanguageLock } from "../LanguageLock";

const AddressAndCoordinates = ({ disabled }) => {
  const dispatch = useDispatch();
  const [coordinatesString, setCoordinatesString] = useState("");

  const { currentLanguage } = useContext(LanguageContext);

  const isStayDefaultLanguage = useSelector(({ stayWizard }) =>
    stayWizard.defaultVariant.language ? stayWizard.defaultVariant.language === currentLanguage.code : true,
  );

  const address = useSelector(({ stayWizard }) => stayWizard.form.address);
  const defaultVariantAddress = useSelector(({ stayWizard }) => stayWizard.defaultVariant.address);
  const formLatitude = useSelector(({ stayWizard }) => stayWizard.form.coordinates.latitude);
  const defaultVariantLatitude = useSelector(({ stayWizard }) => stayWizard.defaultVariant.coordinates.latitude);
  const formLongitude = useSelector(({ stayWizard }) => stayWizard.form.coordinates.longitude);
  const defaultVariantLongitude = useSelector(({ stayWizard }) => stayWizard.defaultVariant.coordinates.longitude);

  const errors = useSelector(({ stayWizard }) => stayWizard.errors);

  // TODO: Must be refactored to stayWizard state
  const coordsError =
    errors.general?.coordinates?.latitude && errors.general?.coordinates?.longitude
      ? `${errors.general?.coordinates?.latitude}, ${errors.general?.coordinates?.longitude}`
      : errors.general?.coordinates?.latitude || errors.general?.coordinates?.longitude || "";

  const shouldShowMarker =
    isValidCoordinatePart(formLongitude || defaultVariantLongitude) && isValidCoordinatePart(formLatitude || defaultVariantLatitude);

  const marker = shouldShowMarker
    ? [
        {
          location: address,
          latitude: formLatitude || defaultVariantLatitude,
          longitude: formLongitude || defaultVariantLongitude,
          id: uuidv4(),
        },
      ]
    : [];

  const handleCoordinatesValueChange = ({ target }) => {
    const { value } = target;
    const { latitude, longitude } = extractCoordinates(value);
    dispatch(setStayWizardCoordinates({ latitude, longitude }));
    setCoordinatesString(value);
  };

  const handleAddressChange = ({ target }) => {
    const { value } = target;
    dispatch(setStayWizardAddress(value));
  };

  const handleSelectLocation = ({ longitude, latitude, formatted_address }) => {
    dispatch(setStayWizardAddress(formatted_address));
    dispatch(
      setStayWizardCoordinates({
        latitude,
        longitude,
      }),
    );
    setCoordinatesString(`${latitude}, ${longitude}`);
  };

  useEffect(() => {
    if (isStayDefaultLanguage) {
      if ((formLatitude || formLatitude === 0) && (formLongitude || formLongitude === 0)) {
        setCoordinatesString(`${formLatitude}, ${formLongitude}`);
      }
      if (formLatitude && !formLongitude) {
        setCoordinatesString(`${formLatitude}`);
      }
      if (!formLatitude && !formLongitude) {
        setCoordinatesString("");
      }
    } else {
      setCoordinatesString(`${defaultVariantLatitude}, ${defaultVariantLongitude}`);
    }
  }, [formLatitude, formLongitude, defaultVariantLatitude, defaultVariantLongitude, isStayDefaultLanguage]);

  return (
    <Grid container spacing={10}>
      <Grid item xs={12} md={7}>
        <Content margin={10}>
          <Input
            label={STAY_WIZARD_CONTENT.addressAndCoordinates.address}
            name="address"
            value={isStayDefaultLanguage ? address : defaultVariantAddress}
            onChange={handleAddressChange}
            error={!!errors.general?.address}
            helperText={errors.general?.address || ""}
            disabled={disabled || !isStayDefaultLanguage}
            isRequired={isStayDefaultLanguage}
          />
          {!isStayDefaultLanguage && <LanguageLock wide />}
        </Content>
        <Content margin={10}>
          <Input
            label={STAY_WIZARD_CONTENT.addressAndCoordinates.coords}
            name="coordinates"
            value={coordinatesString}
            onChange={handleCoordinatesValueChange}
            error={
              errors.general?.coordinates
                ? checkErrorExistFor("latitude", errors.general?.coordinates) ||
                  checkErrorExistFor("longitude", errors.general?.coordinates)
                : false
            }
            helperText={coordsError || ""}
            disabled={disabled || !isStayDefaultLanguage}
            isRequired={isStayDefaultLanguage}
          />
          {!isStayDefaultLanguage && <LanguageLock wide />}
        </Content>
      </Grid>
      <Grid item xs={12} md={5}>
        <MapWidget
          mapContainerStyle={{ height: 300 }}
          options={{ disableDefaultUI: true }}
          searchPlaceholder="Search location"
          infoWindowsProps={{
            coordinates: {
              onButtonClick: handleSelectLocation,
            },
            rightClick: {
              disableAddButton: true,
            },
          }}
          customInfoWindowType="coordinates"
          searchByLocation
        />
      </Grid>
    </Grid>
  );
};

AddressAndCoordinates.defaultProps = {
  disabled: false,
};

AddressAndCoordinates.propTypes = {
  disabled: PropTypes.bool,
};

export { AddressAndCoordinates };
