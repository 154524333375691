// @ts-check

import moment from "moment";
import { createDefaultTime } from "feature/panel/Stays/_shared/createDefaultTime";

export const DEFAULT_ACTION_ICON_ID = 282;
export const DEFAULT_BOOK_NOW_ICON_ID = 338;

export class Stay {
  /** @type {number} */
  vamoosId = null;

  /** @type {number} */
  id = null;

  /** @type {import("domain/StayVariant").StayVariant[]} */
  variants = [];

  /** @type {string} */
  language = null;

  /** @type {boolean} */
  isWiped = false;

  /** @type {boolean} */
  isActive = true;

  /** @type {boolean} */
  isListed = false;

  /** @type {boolean} */
  newOnly = false;

  /** @type {string} */
  referenceCode = "DEFAULT";

  /** @type {string} */
  departureDate = new Date().toISOString();

  /** @type {string} */
  returnDate = new Date().toISOString();

  /** @type {string} */
  userId = "";

  /** @type {string} */
  propertyName = "";

  /** @type {string} */
  homeDescription = "";

  /** @type {string} */
  phone = "";

  /** @type {string} */
  email = "";

  /** @type {string} */
  website = "";

  /** @type {string} */
  twitter = "";

  /** @type {string} */
  facebook = "";

  /** @type {string} */
  instagram = "";

  /** @type {string} */
  checkInTime = "12:00";

  /** @type {string} */
  updated_at = "";

  /** @type {boolean} */
  requirePersonalDetails = false;

  /** @type {number} */
  brandingProfileId = null;

  /** @type {string} */
  address = "";

  /** @type {object} */
  coordinates = {
    latitude: null,
    longitude: null,
  };

  /** @type {object} */
  connect = {
    isEnabled: false,
    requestedStatus: false,
  };

  /** @type {object} */
  hotelInfo = {
    label: "",
    icon: "",
  };

  /** @type {object} */
  logo = null;

  /** @type {object} */
  background = null;

  /** @type {string} */
  shortDescription = "";

  /** @type {string} */
  longDescription = "";

  /** @type {string} */
  numberOfRooms = "";

  /** @type {object} */
  directories = {
    show: "always",
    items: [],
  };

  /** @type {object} */
  vouchers = {
    show: "always",
    items: [],
  };

  /** @type {object} */
  dailyActivities = {
    show: "always",
    items: [],
  };

  /** @type {any[]} */
  features = [];

  /** @type {object} */
  notifications = {
    timed: {
      items: [],
    },
    gps: {
      items: [],
    },
  };

  /** @type {any[]} */
  passcodes = [];

  /** @type {any[]} */
  pois = [];

  /** @type {object} */
  inspiration = {
    vamoosId: null,
    settings: {
      content: "",
      period: {
        deliveryAtDays: null,
        startAt: "13:00",
        deliveryAtRelativeTo: null,
      },
    },
  };

  /** @type {array} */
  gallery = [];

  /** @type {object} */
  actions = {
    action: {
      icon: DEFAULT_ACTION_ICON_ID,
      label: "",
      file: null,
    },
    bookNow: {
      icon: DEFAULT_BOOK_NOW_ICON_ID,
      label: "",
      file: null,
    },
  };

  /** @type {object} */
  messaging = {
    emails: [],
    dates: {
      before: 0,
      after: 0,
    },
  };

  /** @type {object} */
  dnd = {
    show: "off",
    email: "",
    time: moment(createDefaultTime({ hour: 11, minutes: 0, seconds: 0 })).toISOString(),
  };

  /**
   * @type {{
   *  include: boolean,
   *  emails: string
   * }}
   */
  alertEmails = {
    emails: "",
    include: false,
  };

  /** @type {number} */
  copiedFrom;

  constructor(userId = "", vamoosId = null) {
    this.userId = userId;
    this.vamoosId = vamoosId;
  }
}
