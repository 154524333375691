// @ts-check

import { isValidCoordinatePart } from "utils/validation";
import { PoiMapper } from "../mappers/PoiMapper";
import { PoiRepository } from "../repositories/PoiRepository";

export class PoiService {
  /** @type {PoiRepository} */
  poiRepository;

  /** @type {PoiMapper} */
  poiMapper;

  /**
   *
   * @param {PoiRepository} poiRepository
   * @param {PoiMapper} poiMapper
   */
  constructor(poiRepository = new PoiRepository(), poiMapper = new PoiMapper()) {
    this.poiMapper = poiMapper;
    this.poiRepository = poiRepository;
  }

  /**
   * 
   * @param {number} page 
   * @param {number} rowsPerPage 
   * @param {array} countries 
   * @param {string} name 
   * @param {string} order 
   * @param {string} orderBy 
   * @param {array} locations 
   * @param {boolean} searchMode 
   * @returns 
   */
  async getTablePois(page, rowsPerPage, countries, name, order, orderBy, locations, searchMode) {
    const data = await this.poiRepository.fetchPoiList(page, rowsPerPage, countries, name, order, orderBy, locations, searchMode);
    const dataPayload = {
      ...data,
      items: data.items.map(poi => this.poiMapper.fromDtoToDomain(poi)),
    };

    return dataPayload;
  }

  /**
   * 
   * @returns {Promise<any[]>}
   */
  async getAllPois() {
    const data = await this.poiRepository.fetchAllPois();
    const dataPayload = {
      ...data,
      items: data.items.map(poi => this.poiMapper.fromDtoToDomain(poi)),
    };

    return dataPayload;
  }

  /**
   * 
   * @param {object} poi 
   * @returns 
   */
  async addNewPoi(poi) {
    const payload = this.poiMapper.fromDomainToDto(poi);
    const data = await this.poiRepository.addNewPoi(payload);

    return this.poiMapper.fromDtoToDomain(data);
  }

  /**
   * 
   * @param {number} id 
   * @returns 
   */
  async getPoi(id) {
    const data = await this.poiRepository.getPoi(id);

    return this.poiMapper.fromDtoToDomain(data);
  }

  /**
   * 
   * @param {any[]} locations 
   * @returns 
   */
  async getPoisByLocations(locations) {
    const validLocations = locations.filter(
      location => location.latitude <= 90 && location.latitude > -90 
        && location.longitude <= 180 
        && location.longitude > -180
        && isValidCoordinatePart(location.longitude) 
        && isValidCoordinatePart(location.latitude)
    );

    return this.poiRepository.getPoisByLocations(validLocations);
  }


  /**
   * 
   * @param {object} poi 
   * @returns 
   */
  async updatePoi(poi) {
    const payload = this.poiMapper.fromDomainToDto(poi);
    const data = await this.poiRepository.updateExistingPoi(poi.id, payload);

    return this.poiMapper.fromDtoToDomain(data);
  }

  /**
   * 
   * @param {number} id 
   * @returns 
   */
  async deletePoi(id) {
    const { data } = await this.poiRepository.removeExistingPoi(id);

    return data;
  }
}
