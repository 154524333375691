import * as actionTypes from "store/adminOperatorDetails/actionTypes";
import { createAction } from "utils/store";

export const updateAdminOperatorsDetailsStart = createAction(actionTypes.UPDATE_ADMIN_OPERATOR_DETAILS_START);
export const updateAdminOperatorsDetailsSuccess = createAction(actionTypes.UPDATE_ADMIN_OPERATOR_DETAILS_SUCCESS);
export const updateAdminOperatorsDetailsFail = createAction(actionTypes.UPDATE_ADMIN_OPERATOR_DETAILS_FAIL);

export const getOperatorDetailsStart = createAction(actionTypes.GET_OPERATOR_DETAILS_START);
export const getOperatorDetailsSuccess = createAction(actionTypes.GET_OPERATOR_DETAILS_SUCCESS);
export const getOperatorDetailsFail = createAction(actionTypes.GET_OPERATOR_DETAILS_FAIL);

export const setValueForOperatorAction = createAction(actionTypes.SET_VALUE_FOR_OPERATOR_START);
export const setErrorsForOperatorDetails = createAction(actionTypes.SET_ERRORS_FOR_OPERATOR_DETAILS);

export const setOperatorDetailsFormTouched = createAction(actionTypes.SET_OPERATOR_DETAILS_FORM_TOUCHED);

export const getCustomAppForAdminOperatorStart = createAction(actionTypes.GET_CUSTOM_APP_LIST_FOR_ADMIN_OPERATOR_START);
export const getCustomAppForAdminOperatorSuccess = createAction(actionTypes.GET_CUSTOM_APP_LIST_FOR_ADMIN_OPERATOR_SUCCESS);
export const getCustomAppForAdminOperatorFail = createAction(actionTypes.GET_CUSTOM_APP_LIST_FOR_ADMIN_OPERATOR_FAIL);

export const acceptCustomAppStart = createAction(actionTypes.ACCEPT_CUSTOM_APP_START);
export const acceptCustomAppSuccess = createAction(actionTypes.ACCEPT_CUSTOM_APP_SUCCESS);
export const acceptCustomAppFail = createAction(actionTypes.ACCEPT_CUSTOM_APP_FAIL);

export const rejectCustomAppStart = createAction(actionTypes.REJECT_CUSTOM_APP_START);
export const rejectCustomAppSuccess = createAction(actionTypes.REJECT_CUSTOM_APP_SUCCESS);
export const rejectCustomAppFail = createAction(actionTypes.REJECT_CUSTOM_APP_FAIL);

export const revertToDraftCustomAppStart = createAction(actionTypes.REVERT_TO_DRAFT_CUSTOM_APP_START);
export const revertToDraftCustomAppSuccess = createAction(actionTypes.REVERT_TO_DRAFT_CUSTOM_APP_SUCCESS);
export const revertToDraftCustomAppFail = createAction(actionTypes.REVERT_TO_DRAFT_CUSTOM_APP_FAIL);

export const makeLiveCustomAppStart = createAction(actionTypes.MAKE_LIVE_CUSTOM_APP_START);
export const makeLiveCustomAppSuccess = createAction(actionTypes.MAKE_LIVE_CUSTOM_APP_SUCCESS);
export const makeLiveCustomAppFail = createAction(actionTypes.MAKE_LIVE_CUSTOM_APP_FAIL);

export const getCustomAppBundleIdVersionsStart = createAction(actionTypes.GET_CUSTOM_APP_BUNDLE_VERSIONS_START);
export const getCustomAppBundleIdVersionsSuccess = createAction(actionTypes.GET_CUSTOM_APP_BUNDLE_VERSIONS_SUCCESS);
export const getCustomAppBundleIdVersionsFail = createAction(actionTypes.GET_CUSTOM_APP_BUNDLE_VERSIONS_FAIL);
