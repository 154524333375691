import React from "react";
import styled from "styled-components";
import TableCell from "@material-ui/core/TableCell";

export const ActionTableCell = styled(({ noPadding, ...rest }) => <TableCell {...rest} />)`
  && {
    ${({ noPadding }) =>
      noPadding
        ? `
        padding: 0;
        :last-child {
          padding-right: 0;
        }`
        : ""}
  }
`;
