import React from "react";
import PropTypes from "prop-types";

import { SortableList } from "components/ui/Lists/SortableList";
import { DocumentsListItem } from "feature/panel/Trips/_shared/Documents/DocumentsListItem";
import { useManageTripContext } from "feature/panel/Trips/_shared/ManageTripContext";

import { setNewOrder } from "utils/sortable";
import { usePermissionsService } from "hooks/usePermissionsService";
import { PERMISSIONS } from "constants/permissions";

const DocumentsList = ({ list, name, onRemove, onChange, onFilesUpload }) => {
  const permissionsService = usePermissionsService();
  const { setValueFor, errors, is_wiped, editMode, vamoos_id } = useManageTripContext();

  const evalNames = {
    destinationDocuments: "destination",
    travelDocuments: "travel",
  };

  const canEdit = permissionsService.can(PERMISSIONS.actions.update, PERMISSIONS.sections.vamoosList, vamoos_id);

  const documentsErrors = errors.documents ? errors.documents[evalNames[name]] : {};

  const handleChangeOrder = ({ newIndex, oldIndex }) => {
    const newList = setNewOrder(newIndex, oldIndex, list);
    setValueFor(name, newList);
  };

  return (
    list &&
    list.length > 0 && (
      <SortableList lockAxis="y" onSortEnd={handleChangeOrder} disabled={!canEdit} lockToContainerEdges useDragHandle>
        {list.map((item, index) => (
          <DocumentsListItem
            key={item.id}
            item={item}
            index={index}
            name={name}
            onRemove={onRemove}
            onChange={onChange}
            useDragHandle
            errors={documentsErrors}
            onFilesUpload={onFilesUpload}
            disabled={is_wiped || (editMode && !canEdit)}
          />
        ))}
      </SortableList>
    )
  );
};

DocumentsList.defaultProps = {
  list: [],
};

DocumentsList.propTypes = {
  list: PropTypes.arrayOf(PropTypes.object),
  name: PropTypes.string.isRequired,
  onRemove: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onFilesUpload: PropTypes.func.isRequired,
};

export { DocumentsList };
