import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import { Input } from "components/ui/Forms";

import { IconsSelect } from "feature/panel/_shared/IconsSelect";
import { TRIP_WIZARD_CONTENT } from "constants/content";

const DocumentsForm = ({ title, defaultTitle, icon, defaultIcon, onChange, errors, disabled }) => {
  const [titleValue, setTitleValue] = useState("");
  const [iconValue, setIconValue] = useState("");

  const handleTitleFocusout = () => {
    setTitleValue(defaultTitle);
  };

  const setupTitle = () => {
    setTitleValue(title || defaultTitle);
    setIconValue(icon || defaultIcon);
  };

  const handleTitleChange = ({ target }) => {
    const { value } = target;

    setTitleValue(value);
    onChange("title", value);
  };

  const handleIconChange = ({ target }) => {
    const { value } = target;

    setIconValue(value);
    onChange("icon", value);
  };

  useEffect(setupTitle, [defaultTitle, defaultIcon, title, icon]);

  return (
    <>
      <Grid container justifyContent="center" spacing={10}>
        <Grid item xs={12} lg={12}>
          <Input
            label={TRIP_WIZARD_CONTENT.documents.title}
            name="Title"
            value={titleValue}
            onChange={handleTitleChange}
            onBlur={handleTitleFocusout}
            error={!!errors.title}
            helperText={errors.title}
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={12} lg={12}>
          <IconsSelect name="Icon" selected={Number(iconValue)} onSelect={handleIconChange} withNames disabled={disabled} />
        </Grid>
      </Grid>
    </>
  );
};

DocumentsForm.defaultProps = {
  title: "",
  icon: "",
  defaultTitle: "",
  defaultIcon: "",
  errors: {},
};

DocumentsForm.propTypes = {
  onChange: PropTypes.func.isRequired,
  title: PropTypes.string,
  defaultTitle: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  defaultIcon: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  errors: PropTypes.shape({
    title: PropTypes.string,
  }),
};

export { DocumentsForm };
