import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { ConfirmationModal } from "components/ui/Modals/ConfirmationModal";
import { Input, Textarea } from "components/ui/Forms";
import { Content } from "components/ui/Content";
import { FileField } from "feature/panel/Stays/_shared/DailyDirectoryVoucherShared/FileField";
import { DirectoryValidator } from "services/domain/DirectoryValidator";
import { isObjectEmpty } from "utils/object";
import ActionManageElement from "feature/panel/Stays/_shared/DailyDirectoryVoucherShared/ActionManageElement";
import { formatDisplayableLibraryPopupValues } from "utils/library";
import { DEFAULT_ACTION_ICON_ID } from "feature/panel/Stays/_shared/initStaysState";

import { VIDEO_FILE_TYPES } from "constants/defaults";
import { Message } from "components/ui/Messages/Message";
import { DIRECTORY_LABELS, ERRORS } from "constants/content";
import { DirectoriesTree } from "./DirectoriesTree";

const DirectoryDialog = ({
  open,
  onCancel,
  onConfirm,
  directory,
  setDirectoryValue,
  onParentIdChange,
  directoriesList,
  currentDirectoriesList,
  canEdit,
}) => {
  const [errors, setErrors] = useState({});
  const [isUnique, setIsUnique] = useState(true);
  const [oldName, setOldName] = useState(null);


  const { id, name, content, background, video, is_list, actions } = directory;

  const title = DIRECTORY_LABELS.popupTitle(id, "directory", is_list);
  const confirmLabel = id ? "Update" : "Save";

  const showDirectoriesTree = typeof id !== "undefined";

  const handleOnCancel = () => {
    onCancel();
    setIsUnique(true);
  };

  const handleBackgroundChange = files => {
    const [newFile] = formatDisplayableLibraryPopupValues(files);
    setDirectoryValue("background", newFile);
  };
  const handleBackgroundRemove = () => setDirectoryValue("background", {});

  const handleVideoChange = files => {
    const [newFile] = formatDisplayableLibraryPopupValues(files);
    setDirectoryValue("video", newFile);
  };
  const handleVideoRemove = () => setDirectoryValue("video", {});

  const handleModalConfirm = () => {
    const errorsList = DirectoryValidator(directory);
    if (isObjectEmpty(errorsList)) {
      onConfirm();
    }
    setErrors(errorsList);
  };

  const clearActionErrors = orderNumber =>
    errors.actions &&
    setErrors({
      ...errors,
      actions: errors.actions.filter(action => action.orderNumber !== orderNumber),
    });

  const handleActionExistence = orderNumber => {
    const actionExist = actions.some(action => action.orderNumber === orderNumber);
    const newAction = {
      name: "",
      icon_id: DEFAULT_ACTION_ICON_ID,
      orderNumber,
    };
    const newActionsList = actionExist ? actions.filter(action => action.orderNumber !== orderNumber) : [...actions, newAction];
    if (actionExist) {
      clearActionErrors(orderNumber);
    }
    setDirectoryValue("actions", newActionsList);
  };

  const handleActionChange = (orderNumber, newValue) => {
    const newActionsList = actions.map(action => (action.orderNumber === orderNumber ? newValue : action));
    setDirectoryValue("actions", newActionsList);
  };

  const handleParentChange = directoryId => {
    if (canEdit) {
      const value = directoryId === "root" ? null : directoryId;

      onParentIdChange(value);
    }
  };

  const getAncestors = (root, directories) => {
    return directories
      .filter(dir => dir.parent_id && dir.parent_id === root.id)
      .reduce(
        (ancestors, dir) => {
          return [...ancestors, ...getAncestors(dir, directories)];
        },
        [root],
      );
  };

  const isSelectedPathAncestorOfEditedFolder =
    showDirectoriesTree && getAncestors(directory, directoriesList).find(dir => dir.id === directory.parent_id);

  const invalidPathMessage = isSelectedPathAncestorOfEditedFolder && (
    <Content>
      <Message text={ERRORS.invalidFolderPath} type="error" />
    </Content>
  );

  const checkUniquenessForNameField = () => {
    if (isUnique) {
      const { name: errorName, ...restOfErrors } = errors;
      setErrors(restOfErrors);
    } else {
      setErrors({ ...errors, name: ERRORS.directoryAlreadyExists });
    }
  };

  const getSelectedDirChildrens = () => {
    return directoriesList.filter(
      dir => (dir.parent_id === directory.parent_id || (!dir.parent_id && !directory.parent_id)) && dir.is_list === directory.is_list,
    );
  };

  const isNameIncluded = (directories, givenName) => directories.some(dir => dir.name === givenName);

  const checkIsTitleUniqueWhenDirectoryIsMoved = () => {
    const selectedDirChildrens = getSelectedDirChildrens();
    if (name) {
      setIsUnique(id === directory.parent_id || !isNameIncluded(selectedDirChildrens, name));
    }
  };

  const checkIsTitleUnique = currentDirsList => {
    const selectedDirChildrens = getSelectedDirChildrens();
    const searchedId = currentDirsList.filter(item => item.name === name).map(i => i.id)[0];
    if (name) {
      setIsUnique((searchedId === id && name === oldName) || !isNameIncluded(selectedDirChildrens, name));
    }
  };

  const handleTitleFieldFocusOut = currentDirsList => {
    checkIsTitleUnique(currentDirsList);
  };

  const handleInputChange = ({ target }) => {
    setDirectoryValue(target.name, target.value);
  };

  const init = () => {
    if (open) {
      setIsUnique(true);
      setErrors({});
      setOldName(name);
    } else {
      document.body.style = null;
    }
  };

  useEffect(checkUniquenessForNameField, [isUnique]);
  useEffect(checkIsTitleUniqueWhenDirectoryIsMoved, [directory.parent_id]);
  useEffect(init, [open]);

  return open ? (
    <ConfirmationModal
      open={open}
      title={title}
      confirmLabel={confirmLabel}
      onCancel={handleOnCancel}
      onConfirm={() => handleModalConfirm()}
      fullWidth
      confirmDisabled={isSelectedPathAncestorOfEditedFolder || !isUnique || !canEdit}
    >
      <Content>
        <Input
          label={DIRECTORY_LABELS.title}
          value={name}
          onChange={handleInputChange}
          name="name"
          fullWidth
          error={!!errors.name}
          helperText={errors.name || ""}
          onBlur={() => handleTitleFieldFocusOut(currentDirectoriesList)}
          disabled={!canEdit}
          isRequired
        />
      </Content>
      <Content>
        <Textarea
          label={DIRECTORY_LABELS.description}
          value={content}
          onChange={handleInputChange}
          name="content"
          fullWidth
          rows={4}
          rowsMin={4}
          disabled={!canEdit}
        />
      </Content>
      <FileField
        label={DIRECTORY_LABELS.bgImage}
        file={background}
        onFileChange={handleBackgroundChange}
        onFileRemove={handleBackgroundRemove}
        error={errors.background}
        disabled={!canEdit}
      />
      <FileField
        label={DIRECTORY_LABELS.video}
        file={video}
        onFileChange={handleVideoChange}
        onFileRemove={handleVideoRemove}
        allowedFileTypes={VIDEO_FILE_TYPES}
        error={errors.video}
        disabled={!canEdit}
      />
      <ActionManageElement
        action={actions.find(({ orderNumber }) => orderNumber === 1)}
        toggleActionExistence={() => handleActionExistence(1)}
        handleActionChange={action => handleActionChange(1, action)}
        label={DIRECTORY_LABELS.buttonOne}
        errors={errors.actions}
        disabled={!canEdit}
      />
      <ActionManageElement
        action={actions.find(({ orderNumber }) => orderNumber === 2)}
        toggleActionExistence={() => handleActionExistence(2)}
        handleActionChange={action => handleActionChange(2, action)}
        label={DIRECTORY_LABELS.buttonTwo}
        errors={errors.actions}
        disabled={!canEdit}
      />
      {invalidPathMessage}
      {showDirectoriesTree && (
        <DirectoriesTree
          directories={directoriesList.filter(dir => dir.is_list)}
          handleItemClick={handleParentChange}
          selectedDirectory={directory}
        />
      )}
    </ConfirmationModal>
  ) : null;
};

DirectoryDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  directory: PropTypes.shape({
    parent_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string,
    content: PropTypes.string,
    background: PropTypes.shape(),
    video: PropTypes.shape(),
    type: PropTypes.string,
    is_list: PropTypes.bool,
    actions: PropTypes.arrayOf(PropTypes.shape()),
    weekdays: PropTypes.shape(),
  }).isRequired,
  directoriesList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  setDirectoryValue: PropTypes.func.isRequired,
  onParentIdChange: PropTypes.func.isRequired,
  canEdit: PropTypes.bool.isRequired,
  currentDirectoriesList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export { DirectoryDialog };
