import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { useDispatch } from "react-redux";

import { PanelTemplate } from "components/templates/Panel/PanelTemplate";
import { Navigation } from "feature/panel/Settings/Navigation";

import { getCurrentOperatorStart } from "store/operator/actions";

const Settings = () => {
  const dispatch = useDispatch();

  const getOperatorData = () => {
    dispatch(getCurrentOperatorStart());
  };

  useEffect(getOperatorData, []);

  const contextBar = {
    left: () => null,
    middle: Navigation,
    right: () => null,
  };

  return (
    <PanelTemplate contextBar={contextBar}>
      <Outlet />
    </PanelTemplate>
  );
};

export { Settings };
