import React from "react";
import PropTypes from "prop-types";
import Tooltip from "@material-ui/core/Tooltip";

const cutText = (text, maxChar) => {
  const dotsLength = 3;
  return text.length > maxChar && text.length > dotsLength ? `${text.substring(0, maxChar - dotsLength)}...` : text;
};

const cutTextInTheMiddle = (text, maxChar) => {
  if (typeof text === "string" && text.length > maxChar) {
    const textPartLength = Math.round(maxChar / 2);
    const startText = text.substring(0, textPartLength);
    const endText = text.substring(text.length - (textPartLength - 3), text.length);
    return `${startText}...${endText}`;
  }

  return text;
};

const TextLimiter = ({ text, maxChar, dotsPosition }) =>
  typeof text === "string" && (
    <Tooltip title={text}>
      <span>
        {dotsPosition === "end" && cutText(text, maxChar)}
        {dotsPosition === "middle" && cutTextInTheMiddle(text, maxChar)}
      </span>
    </Tooltip>
  );

TextLimiter.defaultProps = {
  dotsPosition: "end",
  text: null,
};

TextLimiter.propTypes = {
  text: PropTypes.string,
  maxChar: PropTypes.number.isRequired,
  dotsPosition: PropTypes.oneOf(["end", "middle"]),
};

export { TextLimiter, cutText, cutTextInTheMiddle };
