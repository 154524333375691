import { HttpClient } from "services/application/httpClient/httpClient";

import { put, takeLatest } from "redux-saga/effects";
import * as actionTypes from "store/nested/actionTypes";
import {
  getNestedItinerariesFail,
  getNestedItinerariesSuccess,
  getAllNestedItinerariesSuccess,
  getAllNestedItinerariesFail,
} from "store/nested/actions";
import { handleErrorMessages } from "utils/store";
import { setUrlParams } from "utils/url";
import { GET_NESTED_ITINERARIES_URL } from "constants/api";

import { StayOverride } from "domain/StayOverride";

function* getNested({ payload }) {
  const { url, searchMode } = payload;
  if (searchMode) HttpClient.cancelRequest();
  try {
    const response = yield HttpClient.get(url);
    if (response) {
      const { items, total_matches } = response.data;
      yield put(
        getNestedItinerariesSuccess({
          items: items.map(item => StayOverride(item)),
          total_matches,
          searchMode,
        }),
      );
    }
  } catch (e) {
    if (!HttpClient.isCancelError(e)) {
      yield put(getNestedItinerariesFail({ errors: handleErrorMessages(e), searchMode }));
    }
  }
}

function* getAllNested({ payload }) {
  const { searchMode, searchQuery } = payload;
  if (searchMode) HttpClient.cancelRequest();
  const url = setUrlParams(GET_NESTED_ITINERARIES_URL, { page: 1, count: 5000, q: searchQuery });

  try {
    const response = yield HttpClient.get(url);
    if (response) {
      const { items, total_matches } = response.data;
      yield put(
        getAllNestedItinerariesSuccess({
          items: items.map(item => StayOverride(item)),
          total_matches,
          searchMode,
        }),
      );
    }
  } catch (e) {
    if (!HttpClient.isCancelError(e)) {
      yield put(getAllNestedItinerariesFail({ errors: handleErrorMessages(e), searchMode }));
    }
  }
}

export function* nestedSaga() {
  yield takeLatest(actionTypes.GET_NESTED_ITINERARIES_START, getNested);
  yield takeLatest(actionTypes.GET_ALL_NESTED_ITINERARIES_START, getAllNested);
}
