// @ts-check

/**
 * @param {import("types/dto").FileWrapperResponseDto} fileWrapperDto - user object
 * @returns {FileWrapper} FileWrapper item
 */
export function FileWrapper(fileWrapperDto) {
  /**
   * @type {FileWrapper}
   */
  const domain = {};

  domain.id = fileWrapperDto.id;
  domain.operatorId = fileWrapperDto.operator_id;
  domain.createdBy = fileWrapperDto.created_by;
  domain.name = fileWrapperDto.name;
  domain.file = fileWrapperDto.file;
  domain.meta = fileWrapperDto.meta;
  domain.path = fileWrapperDto.path;
  domain.createdAt = fileWrapperDto.created_at;
  domain.updatedAt = fileWrapperDto.updated_at;
  domain.isFolder = fileWrapperDto.is_folder;

  if (fileWrapperDto?.icon_id) {
    domain.iconId = fileWrapperDto.icon_id;
  }

  if (fileWrapperDto?.remote_url) {
    domain.remoteUrl = fileWrapperDto.remote_url;
  }

  if (fileWrapperDto?.description) {
    domain.description = fileWrapperDto.description;
  }

  if (fileWrapperDto?.position) {
    domain.position = fileWrapperDto.position;
  }

  if (fileWrapperDto?.alias_for_id) {
    domain.aliasForId = fileWrapperDto.alias_for_id;
  }

  return domain;
}
