import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import styles from "config/theme";
import { checkIfMessageWasRead } from "feature/panel/_shared/Messaging/shared/checkIfMessageWasRead";
import { ThreadBar } from "feature/panel/_shared/Messaging/Components/ThreadBar";
import { SettingsBar } from "feature/panel/_shared/Messaging/Components/SettingsBar";
import { MESSAGING } from "constants/defaults";

const { VIEWPORT_BREAK_SIZE } = MESSAGING;

const ThreadsContainer = styled.div`
  position: absolute;
  overflow-y: auto;
  min-height: calc(100% - ${({ theme }) => theme.setSpacing(32)}px);
  height: calc(100% - ${({ theme }) => theme.setSpacing(32)}px);
  width: ${({ theme }) => theme.setSpacing(95)}px;

  @media (max-width: ${VIEWPORT_BREAK_SIZE}px) {
    max-width: ${({ theme }) => theme.setSpacing(80)}px;
  }

  border-right: 1px solid ${styles.colors.grey10};
`;

const ListContainer = styled.div`
  div:last-child {
    /* margin-bottom: ${({ theme }) => theme.setSpacing(16)}px; */
  }
`;

const ThreadsList = ({ onThreadElementClick, isSettingsMenuOpen, handleSettingsBarClick, threads }) => {
  const renderThreadsList = () =>
    threads.map(({ name, device, friendlyName, lastReadAt, date, time, messages, id, active }) => {
      return (
        messages.length > 0 && (
          <ThreadBar
            key={id}
            id={id}
            displayed={checkIfMessageWasRead(messages, lastReadAt)}
            active={active}
            onElementClick={onThreadElementClick}
            data={{ name, device, friendlyName, date, time, messages }}
          />
        )
      );
    });

  return (
    <ThreadsContainer>
      <ListContainer>{renderThreadsList()}</ListContainer>
      <SettingsBar active={isSettingsMenuOpen} handleSettingsBarClick={handleSettingsBarClick} />
    </ThreadsContainer>
  );
};

ThreadsList.propTypes = {
  onThreadElementClick: PropTypes.func.isRequired,
  isSettingsMenuOpen: PropTypes.bool.isRequired,
  handleSettingsBarClick: PropTypes.func.isRequired,
  threads: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export { ThreadsList };
