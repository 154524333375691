import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import { Message } from "components/ui/Messages/Message";

const ElementContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  && {
    .MuiTypography-body1 {
      font-family: Montserrat !important;
      letter-spacing: 0;
    }
  }
`;

const ContentArea = styled.div`
  width: 100%;
  min-height: ${({ hasError, theme }) => (hasError ? theme.setSpacing(15) : theme.setSpacing(20))}px;
  display: flex;
  justify-content: space-around;
  align-items: center;

  ${({ hasError, theme }) =>
    hasError
      ? `
  &:first-of-type {
  margin-top: ${theme.setSpacing(2)}px;
  }
  &:last-of-type {
    margin-bottom: ${theme.setSpacing(2)}px;
  }
  `
      : null}

  & > * {
    margin: 0 ${({ theme }) => theme.setSpacing(2)}px;
  }
`;

const SortableListElementContentWithError = ({ children, error }) => {
  return (
    <ElementContainer>
      <ContentArea hasError={!!error}>{children}</ContentArea>
      {error && (
        <ContentArea hasError>
          <Message type="error" text={error} />
        </ContentArea>
      )}
    </ElementContainer>
  );
};

SortableListElementContentWithError.defaultProps = {
  error: undefined,
};

SortableListElementContentWithError.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.element]).isRequired,
  error: PropTypes.string,
};

export { SortableListElementContentWithError, ElementContainer, ContentArea };
