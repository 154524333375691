import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import { TOGGLE_CONTROL_LABEL } from "constants/content";

const ToggleSwitchContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({ theme }) => theme.setSpacing(38)}px;
  height: ${({ theme }) => theme.setSpacing(7)}px;
  border-radius: ${({ theme }) => theme.setSpacing(2)}px;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme, active }) => (active ? theme.colors.brand : theme.colors.grey40)};
`;

const Label = styled.div`
  font-size: ${({ theme }) => theme.setSpacing(3.5)}px;
  margin-bottom: ${({ theme }) => theme.setSpacing(2)}px;
  font-weight: 500;
`;

const SwitchItem = styled.div`
  width: 50%;
  height: ${({ theme }) => theme.setSpacing(4)}px;
  font-size: ${({ theme }) => theme.setSpacing(3)}px;
  font-family: Montserrat;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  cursor: pointer;

  ${({ isActive, theme }) =>
    isActive
      ? `
    height:  ${theme.setSpacing(6)}px;
    border-radius:${theme.setSpacing(1.5)}px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12);
    background-color: ${theme.colors.white};
    color: ${theme.colors.black};
    padding: ${theme.setSpacing(1)}px;
    margin: 0 ${theme.setSpacing(0.5)}px;
  `
      : ""}
`;

const ToggleControl = ({ value, firstValueLabel, secondValueLabel, firstValue, secondValue, label, onChange, disabled }) => {
  const handleClick = currentValue => {
    if (!disabled && value !== currentValue) onChange({ target: { value: currentValue } });
  };

  return (
    <>
      {label && <Label>{label}</Label>}
      <ToggleSwitchContainer active={secondValue === value}>
        <SwitchItem isActive={firstValue === value} onClick={() => handleClick(firstValue)}>
          {firstValueLabel}
        </SwitchItem>
        <SwitchItem isActive={secondValue === value} onClick={() => handleClick(secondValue)}>
          {secondValueLabel}
        </SwitchItem>
      </ToggleSwitchContainer>
    </>
  );
};

ToggleControl.defaultProps = {
  value: false,
  disabled: false,
  firstValue: false,
  secondValue: true,
  firstValueLabel: TOGGLE_CONTROL_LABEL.off,
  secondValueLabel: TOGGLE_CONTROL_LABEL.on,
  label: null,
};

ToggleControl.propTypes = {
  value: PropTypes.bool,
  disabled: PropTypes.bool,
  firstValue: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.number]),
  secondValue: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.number]),
  firstValueLabel: PropTypes.string,
  secondValueLabel: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export { ToggleControl };
