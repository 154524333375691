import React, { useContext, useEffect } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { TableHead, TableRow, TableCell } from "@material-ui/core";
import ArrowRightAltOutlinedIcon from "@material-ui/icons/ArrowRightAltOutlined";

import { ActionTableCell } from "components/ui/Tables/_shared/ActionTableCell";
import { TableContext } from "components/ui/Tables/TableContext";
import { deviceType } from "utils/deviceDetect";
import { getTaleVarFromLS } from "./getTableVarFromLS";

const SortingArrow = styled(({ order, ...rest }) => <ArrowRightAltOutlinedIcon {...rest} />)`
  && {
    transform: rotate(${({ order }) => (order === "asc" ? `` : `-`)}90deg);
    transition: transform 0.15s ease;
    font-size: ${({ theme }) => theme.setSpacing(4)}px;
  }
`;

const SortingButton = styled.button`
  border: 0;
  color: ${({ theme }) => theme.colors.grey50};
  padding: 0;
  outline: none;
  background: none;
  font-family: "Montserrat";
  font-size: ${({ theme }) => theme.setSpacing(3)}px;
  white-space: nowrap;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

const FlexArea = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-content: center;
  justify-content: ${({ align }) => align || "flex-start"};
`;

const Label = styled.span`
  font-size: 12px;
  white-space: nowrap;
`;

const TableHeader = ({ withActions, actionsLabel: ActionsLabel, actionsProps, tableId }) => {
  const { headers, handleChangeOrder, sortingOrder, sortingBy } = useContext(TableContext);
  const noPadding = typeof ActionsLabel !== "string" && typeof ActionsLabel !== "undefined";

  useEffect(() => {
    const ordering = getTaleVarFromLS("ordering", tableId);

    if (ordering) handleChangeOrder(ordering.column, tableId, ordering.order);
  }, []);

  const device = deviceType();

  const data = Array.isArray(headers) ? headers : headers?.[device] || headers?.all;
  return (
    <>
      <TableHead>
        <TableRow>
          {data
            ?.filter(header => header.show)
            .map(({ label, key, show, sortable, align, ...props }) => (
              <TableCell key={label} {...props}>
                {sortable && (
                  <FlexArea align={align}>
                    <SortingButton type="button" href="#" onClick={() => handleChangeOrder(key, tableId)}>
                      {label}
                    </SortingButton>
                    {sortingBy === key && <SortingArrow order={sortingOrder} />}
                  </FlexArea>
                )}
                {!sortable && <Label>{label}</Label>}
              </TableCell>
            ))}
          {withActions && (
            <ActionTableCell noPadding={noPadding} align="right" {...actionsProps}>
              {ActionsLabel}
            </ActionTableCell>
          )}
        </TableRow>
      </TableHead>
    </>
  );
};
TableHeader.defaultProps = {
  withActions: false,
  actionsLabel: "",
  actionsProps: {},
};

TableHeader.propTypes = {
  withActions: PropTypes.bool,
  actionsLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.element]),
  actionsProps: PropTypes.shape(),
};

export { TableHeader, SortingArrow, FlexArea, SortingButton };
