import axios from "axios";
import config from "config/app";
import { REQUEST_ABORTED_MESSAGE } from "constants/content";

// eslint-disable-next-line import/no-cycle
import { AuthInterceptor, CustomAppInterceptor } from "./interceptors";

const apiClientInstance = axios.create({
  baseURL: config.api[process.env.NODE_ENV],
});

apiClientInstance.interceptors.request.use(AuthInterceptor);
apiClientInstance.interceptors.request.use(CustomAppInterceptor);

// HttpClient - facade for axios
class HttpClientFacade {
  /**
   * @param {*} httpClient - axios instance
   * @param {*} CancelToken - CancelToken object to allow cancel request
   * @param {*} isCancel - method checks request was cancelled
   */
  constructor(httpClient, CancelToken, isCancel) {
    /**
     * isRequestPending - internal flag indicate request is pending
     */
    this.isRequestPending = false;
    this.isRequestCancelled = isCancel;
    // Move interceptor outside the Facade class
    httpClient.interceptors.request.use(requestConfig => {
      if (CancelToken !== undefined) {
        /**
         * Setup Cancel token property
         */
        // eslint-disable-next-line no-param-reassign
        requestConfig.cancelToken = new CancelToken(c => {
          /**
           * Assign cancel request method to class field
           */
          this.cancelMethod = c;
        });
      }
      return requestConfig;
    });
    /**
     * Assign axios instance to class field
     */
    this.httpClient = httpClient;
  }

  /**
   * GET request method method
   * @param  {...any} params
   */
  get(...params) {
    this.isRequestPending = true;
    return this.httpClient.get(...params).then(response => {
      this.isRequestPending = false;
      return response;
    });
  }

  /**
   * POST request method
   * @param  {...any} params
   */
  post(...params) {
    this.isRequestPending = true;
    return this.httpClient.post(...params).then(response => {
      this.isRequestPending = false;
      return response;
    });
  }

  /**
   * PUT request method
   * @param  {...any} params
   */
  put(...params) {
    this.isRequestPending = true;
    return this.httpClient.put(...params).then(response => {
      this.isRequestPending = false;
      return response;
    });
  }

  /**
   * PATCH request method
   * @param  {...any} params
   */
  patch(...params) {
    this.isRequestPending = true;
    return this.httpClient.patch(...params).then(response => {
      this.isRequestPending = false;
      return response;
    });
  }

  /**
   * DELETE request method
   * @param  {...any} params
   */
  delete(...params) {
    this.isRequestPending = true;
    return this.httpClient.delete(...params).then(response => {
      this.isRequestPending = false;
      return response;
    });
  }

  /**
   * Method cancel pending request
   */
  cancelRequest() {
    if (this.cancelMethod !== undefined && this.isRequestPending) {
      this.cancelMethod(REQUEST_ABORTED_MESSAGE);
    }
  }

  /**
   * Method chceks if catched error is indicating if request was cancelled
   * @param {*} error
   */
  isCancelError(error) {
    return this.isRequestCancelled !== undefined && this.isRequestCancelled(error);
  }
}

const HttpClient = new HttpClientFacade(apiClientInstance, axios.CancelToken, axios.isCancel);

export { HttpClient, apiClientInstance };
