import React, { useState } from "react";
import PropTypes from "prop-types";
import { DeleteOutlined, FileCopyOutlined, SaveOutlined, SendOutlined } from "@material-ui/icons";

import { ActionButton, PrimaryButton } from "components/ui/Buttons";

import { GLOBAL_CONTENT } from "constants/content";

import {
  CUSTOM_APP_STATUS_ACCEPTED,
  CUSTOM_APP_STATUS_APPROVED,
  CUSTOM_APP_STATUS_ARCHIVED,
  CUSTOM_APP_STATUS_DRAFT,
  CUSTOM_APP_STATUS_LIVE,
  CUSTOM_APP_STATUS_REJECTED,
  CUSTOM_APP_STATUS_SUBMITTED,
} from "./constants/customAppStatus";

import { CustomAppsReviseConfirmModal } from "./CustomAppsReviseConfirmModal";
import { CustomAppsDeleteConfirmModal } from "./CustomAppsDeleteConfirmModal";

const CustomAppsFormActions = ({
  isFormTouched,
  appStatus,
  onSave,
  onDismiss,
  onRevise,
  onSubmit,
  dismissSpinner,
  saveSpinner,
  reviseSpinner,
  submitSpinner,
  isHistoryView,
  isSubmitButtonDisabled,
  isDismissButtonDisabled,
}) => {
  const [isReviseModalOpened, setIsReviseModalOpened] = useState(false);
  const [isDeleteModalOpened, setIsDeleteModalOpened] = useState(false);

  const openReviseModal = () => setIsReviseModalOpened(true);
  const closeReviseModal = () => setIsReviseModalOpened(false);

  const openDeleteModal = () => setIsDeleteModalOpened(true);
  const closeDeleteModal = () => setIsDeleteModalOpened(false);

  const handleConfirmRevise = () => {
    onRevise();
    closeReviseModal();
  };

  const handleConfirmDismiss = () => {
    onDismiss();
    closeDeleteModal();
  };

  return (
    <>
      {appStatus === CUSTOM_APP_STATUS_DRAFT && (
        <>
          <ActionButton
            ml={6}
            onClick={openDeleteModal}
            disabled={appStatus === CUSTOM_APP_STATUS_SUBMITTED || isDismissButtonDisabled}
            showSpinner={dismissSpinner}
          >
            <DeleteOutlined />
            <span>{GLOBAL_CONTENT.dismiss}</span>
          </ActionButton>
        </>
      )}
      {[CUSTOM_APP_STATUS_REJECTED, CUSTOM_APP_STATUS_ARCHIVED, CUSTOM_APP_STATUS_LIVE].includes(appStatus) && !isHistoryView && (
        <ActionButton ml={6} onClick={openReviseModal} showSpinner={reviseSpinner}>
          <FileCopyOutlined />
          <span>{GLOBAL_CONTENT.revise}</span>
        </ActionButton>
      )}
      {appStatus === CUSTOM_APP_STATUS_DRAFT && (
        <ActionButton ml={6} onClick={onSubmit} showSpinner={submitSpinner} disabled={isFormTouched || isSubmitButtonDisabled}>
          <SendOutlined />
          <span>{GLOBAL_CONTENT.submit}</span>
        </ActionButton>
      )}
      {appStatus === CUSTOM_APP_STATUS_DRAFT && (
        <>
          <PrimaryButton ml={6} disabled={!isFormTouched} onClick={onSave} showSpinner={saveSpinner}>
            <SaveOutlined />
            <span>{GLOBAL_CONTENT.save}</span>
          </PrimaryButton>
        </>
      )}
      {isReviseModalOpened && (
        <CustomAppsReviseConfirmModal open={isReviseModalOpened} onCancel={closeReviseModal} onConfirm={handleConfirmRevise} />
      )}
      {isDeleteModalOpened && (
        <CustomAppsDeleteConfirmModal open={isDeleteModalOpened} onCancel={closeDeleteModal} onConfirm={handleConfirmDismiss} />
      )}
    </>
  );
};

CustomAppsFormActions.defaultProps = {
  onSave: () => {},
  onDismiss: () => {},
  onSubmit: () => {},
  onRevise: () => {},
  dismissSpinner: false,
  saveSpinner: false,
  submitSpinner: false,
  reviseSpinner: false,
  isHistoryView: false,
  isSubmitButtonDisabled: false,
  isDismissButtonDisabled: false,
};

CustomAppsFormActions.propTypes = {
  isFormTouched: PropTypes.bool.isRequired,
  onSave: PropTypes.func,
  dismissSpinner: PropTypes.bool,
  saveSpinner: PropTypes.bool,
  submitSpinner: PropTypes.bool,
  reviseSpinner: PropTypes.bool,
  isHistoryView: PropTypes.bool,
  isSubmitButtonDisabled: PropTypes.bool,
  isDismissButtonDisabled: PropTypes.bool,
  onDismiss: PropTypes.func,
  onRevise: PropTypes.func,
  onSubmit: PropTypes.func,
  appStatus: PropTypes.oneOf([
    CUSTOM_APP_STATUS_ARCHIVED,
    CUSTOM_APP_STATUS_DRAFT,
    CUSTOM_APP_STATUS_LIVE,
    CUSTOM_APP_STATUS_REJECTED,
    CUSTOM_APP_STATUS_SUBMITTED,
  ]).isRequired,
};

export { CustomAppsFormActions };
