import styled from "styled-components";
import { colors } from "config/theme/colors";

export const Wrap = styled("div")`
  position: relative;
`;

export const Label = styled("div")`
  position: absolute;
  font-size: 16px;
  background: #fff;
  z-index: 3;
  padding: 0 5px;
  margin-left: 1px;
  transform: ${({ isFocused, hasValue }) => (isFocused || hasValue ? "translate(-15px, -9px) scale(.75)" : "translate(5px, 15px)")};
  color: ${({ isFocused, hasValue }) => (isFocused ? colors.brand : hasValue ? "rgba(0, 0, 0, 0.54)" : "#a4a7aa")};
  transition: all 0.2s ease-in-out;
  cursor: text;
`;
