import { tripsValidator } from "utils/validators/tripsValidator";

import { isFunction } from "contracts/types";

const types = {
  trips: tripsValidator,
};

export const validate = (payload, type) => {
  return isFunction(types[type])(payload);
};
