import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { debounce } from "lodash";
import Grid from "@material-ui/core/Grid";

import columns from "feature/panel/Stays/Index/TableOfStaysColumns.json";

import { PanelTemplate } from "components/templates/Panel/PanelTemplate";
import { NoResultsMessage } from "components/ui/Messages/NoResultsMessage";
import { LoadingScreen } from "components/ui/LoadingScreen/LoadingScreen";
import { Switch } from "components/ui/Forms";

import { VamoosListNavigation } from "feature/panel/_shared/Navigations/VamoosListNavigation";
import { SearchField } from "feature/panel/_shared/SearchField/SearchField";
import { Actions } from "feature/panel/Stays/Index/Actions/Actions";

import { TableOfStays } from "feature/panel/Stays/Index/TableOfStays/TableOfStays";

import { useTableHandlers } from "hooks/useTableHandlers";

import {
  CREATE_CONTENT_LABELS,
  EMPTY_LIST_MESSAGES_BASE,
  NAMES_OF_RESOURCES_LISTS,
  NO_RESULTS_FOUND_MESSAGE,
  TABLE_NAMES,
} from "constants/content";
import { DEFAULT_DEBOUNCE_DELAY, REMOVED_STAYS_COLUMNS } from "constants/defaults";

import { StorageService } from "services/StorageService";
import { LS_STAYS_SELECTED_COLUMNS } from "constants/localstorage";
import { PERMISSIONS } from "constants/permissions";
import { usePermissionsService } from "hooks/usePermissionsService";
import { getCurrentUserStart } from "store/auth/actions";
import { useService } from "hooks/useService";
import { StayService } from "services/domain/StayService";
import { Logger } from "services/application/Logger";

import { pushErrorNotification } from "store/app/actions";
import notifications from "constants/notifications";

import { ShowArchivedContainer } from "./ShowArchivedContainer/ShowArchivedContainer";
import { fetchStays } from "./helpers";

const StaysIndex = () => {
  const dispatch = useDispatch();

  const storageService = new StorageService();
  const permissionsService = usePermissionsService();

  const staysService = useService(StayService);

  const canCreate = permissionsService.can(PERMISSIONS.actions.create, PERMISSIONS.sections.vamoosList, PERMISSIONS.resources.default);

  const [showArchived, setShowArchived] = useState(false);

  const { currentOperatorCode } = useSelector(state => state.auth);

  const [listOfStays, setListOfStays] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [count, setCount] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchValue, setSearchValue] = useState("");

  const tableHandlers = useTableHandlers(null, null, { params: { type: TABLE_NAMES.stay } });

  const { handleChangePage, page, rowsPerPage, sortingOrder, sortingBy } = tableHandlers;

  const handleEncodedSearch = query => {
    setSearchQuery(query);
  };

  const debounceSearchQuery = useRef(debounce(query => handleEncodedSearch(query), DEFAULT_DEBOUNCE_DELAY));

  const onSearch = query => {
    setSearchValue(query);
    if (query.length < 3 && query !== "") return;
    return debounceSearchQuery.current(query);
  };

  const handleToggleShowArchived = () => {
    setShowArchived(!showArchived);
  };

  const checkTableColumnsIntegrity = () => {
    storageService.getPropertyByName(LS_STAYS_SELECTED_COLUMNS).then(response => {
      if (Array.isArray(response)) {
        const hasRemovedColumn = response.some(item => REMOVED_STAYS_COLUMNS.includes(item.key));
        if (hasRemovedColumn) {
          storageService.setPropertyByName(LS_STAYS_SELECTED_COLUMNS, columns);
        }
      }
    });
  };

  /**
   * To avoid wrong permissions when user has been invited to specific itinerary in meantime
   * @todo: Solution should be more generic
   */
  const refreshCurrentUserDetails = () => {
    dispatch(getCurrentUserStart());
  };

  const contextBar = {
    left: <SearchField onSearchChange={onSearch} value={searchValue} />,
    middle: VamoosListNavigation,
    right: Actions,
  };

  const getStays = () =>
    fetchStays({
      data: { page, rowsPerPage, sortingOrder, sortingBy, showArchived, searchQuery },
      setIsLoading,
      staysService,
    })
      .then(({ items, total_matches }) => {
        setListOfStays(items);
        setCount(total_matches);
      })
      .catch(e => {
        dispatch(pushErrorNotification(notifications.general[400]));
        Logger.debug(e);
      });

  useEffect(() => {
    getStays();
  }, [page, rowsPerPage, sortingOrder, sortingBy, currentOperatorCode, showArchived]);
  useEffect(() => {
    if (page !== 1) {
      handleChangePage(0);
    } else {
      getStays();
    }
  }, [searchQuery]);
  useEffect(refreshCurrentUserDetails, []);
  useEffect(checkTableColumnsIntegrity, []);

  const content = (() => {
    if (isLoading && !listOfStays.length) return <LoadingScreen />;
    if (!listOfStays.length && !canCreate) return null;
    if (!listOfStays.length && canCreate)
      return (
        <NoResultsMessage>
          {searchQuery ? NO_RESULTS_FOUND_MESSAGE : EMPTY_LIST_MESSAGES_BASE(CREATE_CONTENT_LABELS.stay, NAMES_OF_RESOURCES_LISTS.stay)}
        </NoResultsMessage>
      );
    return (
      <TableOfStays
        stays={listOfStays}
        tableHandlers={tableHandlers}
        count={count}
        page={page}
        fetchStays={getStays}
        isLoadingInProgress={isLoading}
      />
    );
  })();

  return (
    <PanelTemplate contextBar={contextBar}>
      <Grid container justifyContent="center">
        <Grid item xs={12} md={10}>
          <ShowArchivedContainer margin={2}>
            <Switch
              name="show-archived"
              label="Show deleted"
              labelPlacement="start"
              checked={showArchived}
              onChange={handleToggleShowArchived}
            />
          </ShowArchivedContainer>
          {content}
        </Grid>
      </Grid>
    </PanelTemplate>
  );
};

export { StaysIndex };
