import * as actionTypes from "store/notifications/actionTypes";
import { reducer, setState, globalReducerActions } from "utils/store";
import { NOTIFICATION_TYPES } from "constants/content";

const initialState = {
  actionType: null,
  notificationsTemplatesType: null,
  notificationsTemplates: [],
  timedNotificationsTemplates: [],
  gpsNotificationsTemplates: [],
  forcedNotificationsTemplates: [],
  requestSuccess: false,
  inProgress: false,
  notificationsCount: 0,
  errors: {},
};

const ReducerNotifications = globalReducerActions("notifications", initialState, {
  [actionTypes.SET_REQUEST_SUCCESS_STATUS](state, payload) {
    return setState(state, {
      requestSuccess: payload || false,
    });
  },

  [actionTypes.GET_ALL_NOTIFICATION_SUCCESS](state, payload) {
    return setState(state, {
      timedNotificationsTemplates: payload.filter(notification => notification.type === NOTIFICATION_TYPES.timed),
      gpsNotificationsTemplates: payload.filter(notification => notification.type === NOTIFICATION_TYPES.gps),
      forcedNotificationsTemplates: payload.filter(notification => notification.type === NOTIFICATION_TYPES.forced),
    });
  },

  [actionTypes.GET_ALL_NOTIFICATION_FAIL](state, payload) {
    return setState(state, {
      errors: payload,
      inProgress: false,
    });
  },

  [actionTypes.TOGGLE_IN_PROGRESS](state, payload) {
    return setState(state, {
      inProgress: payload || false,
    });
  },
});

export const notificationsReducer = (state = initialState, action) => reducer(state, action, ReducerNotifications);
