import { createContext, useContext } from "react";

export const defaultContextValues = {
  listOfFlights: [],
  travelPeople: [],
  travelDocumentsTitle: "",
  travelDocumentsIcon: 0,
  destinationDocumentsTitle: "",
  destinationDocumentsIcon: 0,
  locations: [],
  timedNotificationsList: [],
  notifications: [],
  creationStoryboard: { showImages: false },
  creationFrontPage: { show: false, headline: "", body: "" },
  creationLastPage: { show: false, headline: "", body: "" },
  errors: {},
  is_wpied: false,
  pois: [],
  setValueFor: () => {},
  meta: {},
};

export const ManageTripContext = createContext(defaultContextValues);

export const useManageTripContext = () => {
  const data = useContext(ManageTripContext);
  return { ...data };
};
