import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";

import { Input } from "components/ui/Forms";
import { PrimaryButton, SecondaryButton } from "components/ui/Buttons";
import { ERRORS, PASSWORD_RESET } from "constants/content";
import { FORGOT_PASSWORD_URL } from "constants/api";
import { Content } from "components/ui/Content";
import { emailValidate } from "utils/validation";
import { useDispatch } from "react-redux";
import { setNotification } from "store/app/actions";
import { Link } from "components/ui/Links/Links";
import { ROOT_PATH } from "constants/routes";
import { HttpClient } from "services/application/httpClient/httpClient";

const resetEmailRequest = email => HttpClient.post(FORGOT_PASSWORD_URL, { email });

const PasswordResetForm = () => {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const dispatch = useDispatch();

  const handleSubmit = async e => {
    e.preventDefault();
    const isEmailValid = emailValidate(email);
    setEmailError(isEmailValid ? "" : ERRORS.invalidEmail);
    if (isEmailValid) {
      try {
        await resetEmailRequest(email);
        dispatch(setNotification({ type: "success", message: PASSWORD_RESET.sendSuccess }));
        setEmail("");
      } catch {
        dispatch(setNotification({ type: "error", message: PASSWORD_RESET.noAssociationError }));
      }
    }
  };

  const handleEmailChange = ({ target: { value } }) => setEmail(value);

  return (
    <form onSubmit={handleSubmit}>
      <Content margin={10}>
        <Input
          type="email"
          name="email"
          label="Contact email*"
          size="large"
          value={email}
          required
          onChange={handleEmailChange}
          error={!!emailError}
          helperText={emailError || ""}
        />
      </Content>
      <Grid container justifyContent="space-between" spacing={5}>
        <Grid item xs={6}>
          <Link to={ROOT_PATH} noDecoration>
            <SecondaryButton fullWidth size="large">
              Back
            </SecondaryButton>
          </Link>
        </Grid>
        <Grid item xs={6}>
          <PrimaryButton fullWidth type="submit" size="large">
            Reset
          </PrimaryButton>
        </Grid>
      </Grid>
    </form>
  );
};

export { PasswordResetForm };
