import { takeEvery, put, select } from "redux-saga/effects";

import * as actionTypes from "store/operator/actionTypes";
import {
  getCurrentOperatorSuccess,
  getCurrentOperatorFail,
  updateCompanyDataSuccess,
  updateCompanyDataFail,
  getIconsListSuccess,
  getIconsListFail,
  getCurrentOperatorStart,
} from "store/operator/actions";
import { setNotification } from "store/app/actions";

import { CURRENT_OPERATOR_URL, GET_ICONS } from "constants/api";
import { CHANGES_SAVED_MESSAGE } from "constants/content";
import { formFilePayloadObject } from "utils/library";

import { HttpClient } from "services/application/httpClient/httpClient";
import { OperatorService } from "services/OperatorService";

function* getCurrentOperatorData() {
  try {
    const operatorService = new OperatorService();

    const currentOperator = yield operatorService.getCurrentOperator()

    if(currentOperator.meta.poisList === undefined) {
      currentOperator.meta.poisList = true;
    }

    yield put(getCurrentOperatorSuccess(currentOperator));
  } catch (e) {
    yield put(getCurrentOperatorFail(e));
  }
}

function* updateCompanyData({ payload }) {
  try {
    const fileFieldNames = ["logo", "creations_header", "creations_footer"];
    const { fieldName, value } = payload;
    const newValue = fileFieldNames.includes(fieldName) ? formFilePayloadObject(value) : value;
    const { name, logo, labels, meta, creations_header, creations_footer } = yield select(state => state.operator.currentOperator);
    const requestPayload = {
      name,
      meta,
      labels,
      logo: formFilePayloadObject(logo) || undefined,
      creations_header: formFilePayloadObject(creations_header),
      creations_footer: formFilePayloadObject(creations_footer),
      [fieldName]: newValue,
    };

    yield HttpClient.post(CURRENT_OPERATOR_URL, requestPayload);
    yield put(getCurrentOperatorStart());
    yield put(updateCompanyDataSuccess(payload));
    yield put(setNotification({ type: "success", message: CHANGES_SAVED_MESSAGE }));
  } catch (e) {
    yield put(updateCompanyDataFail(e));
  }
}

function* switchCompanyPoisList() {
  try {
    const operatorService = new OperatorService();

    const currentOperator = yield select(state => state.operator.currentOperator);
    currentOperator.poisList = !currentOperator.poisList;

    delete currentOperator.isActive;

    yield operatorService.updateCurrentOperator(currentOperator);

    yield put(getCurrentOperatorStart());
    yield put(setNotification({ type: "success", message: CHANGES_SAVED_MESSAGE }));
  } catch (e) {
    yield put(updateCompanyDataFail(e));
  }
}

function* changeCompanyTemperatureScale({ payload }) {
  try {
    const operatorService = new OperatorService();

    const currentOperator = yield select(state => state.operator.currentOperator);
    currentOperator.temperatureScale = payload;

    delete currentOperator.isActive;

    yield operatorService.updateCurrentOperator(currentOperator);

    yield put(getCurrentOperatorStart());
    yield put(setNotification({ type: "success", message: CHANGES_SAVED_MESSAGE }));
  } catch (e) {
    yield put(updateCompanyDataFail(e));
  }
}

function* changeCompanyStoryboardView({ payload }) {
  try {
    const operatorService = new OperatorService();

    const currentOperator = yield select(state => state.operator.currentOperator);
    currentOperator.storyBoardView.summary = payload.summary;
    currentOperator.storyBoardView.details = payload.details;

    delete currentOperator.isActive;

    yield operatorService.updateCurrentOperator(currentOperator);

    yield put(getCurrentOperatorStart());
    yield put(setNotification({ type: "success", message: CHANGES_SAVED_MESSAGE }));
  } catch (e) {
    yield put(updateCompanyDataFail(e));
  }
}

function* changeCompanyName({ payload }) {
  try {
    const operatorService = new OperatorService();

    const currentOperator = yield select(state => state.operator.currentOperator);
    currentOperator.name = payload;

    delete currentOperator.isActive;

    yield operatorService.updateCurrentOperator(currentOperator);

    yield put(getCurrentOperatorStart());
    yield put(setNotification({ type: "success", message: CHANGES_SAVED_MESSAGE }));
  } catch (e) {
    yield put(updateCompanyDataFail(e));
  }
}

function* changeCompanyContactPerson({ payload }) {
  try {
    const operatorService = new OperatorService();

    const currentOperator = yield select(state => state.operator.currentOperator);
    currentOperator.contactPerson = payload;

    delete currentOperator.isActive;

    yield operatorService.updateCurrentOperator(currentOperator);

    yield put(getCurrentOperatorStart());
    yield put(setNotification({ type: "success", message: CHANGES_SAVED_MESSAGE }));
  } catch (e) {
    yield put(updateCompanyDataFail(e));
  }
}

function* changeCompanyTelephone({ payload }) {
  try {
    const operatorService = new OperatorService();

    const currentOperator = yield select(state => state.operator.currentOperator);
    currentOperator.telephone = payload;

    delete currentOperator.isActive;

    yield operatorService.updateCurrentOperator(currentOperator);

    yield put(getCurrentOperatorStart());
    yield put(setNotification({ type: "success", message: CHANGES_SAVED_MESSAGE }));
  } catch (e) {
    yield put(updateCompanyDataFail(e));
  }
}

function* changeCompanyNumberOfBookings({ payload }) {
  try {
    const operatorService = new OperatorService();

    const currentOperator = yield select(state => state.operator.currentOperator);
    currentOperator.numberOfBookings = payload;

    delete currentOperator.isActive;

    yield operatorService.updateCurrentOperator(currentOperator);

    yield put(getCurrentOperatorStart());
    yield put(setNotification({ type: "success", message: CHANGES_SAVED_MESSAGE }));
  } catch (e) {
    yield put(updateCompanyDataFail(e));
  }
}

function* changeCompanyNumberOfPassengers({ payload }) {
  try {
    const operatorService = new OperatorService();

    const currentOperator = yield select(state => state.operator.currentOperator);
    currentOperator.numberOfPassengers = payload;

    delete currentOperator.isActive;

    yield operatorService.updateCurrentOperator(currentOperator);

    yield put(getCurrentOperatorStart());
    yield put(setNotification({ type: "success", message: CHANGES_SAVED_MESSAGE }));
  } catch (e) {
    yield put(updateCompanyDataFail(e));
  }
}

function* changeCompanyDescription({ payload }) {
  try {
    const operatorService = new OperatorService();

    const currentOperator = yield select(state => state.operator.currentOperator);
    currentOperator.description = payload;

    delete currentOperator.isActive;

    yield operatorService.updateCurrentOperator(currentOperator);

    yield put(getCurrentOperatorStart());
    yield put(setNotification({ type: "success", message: CHANGES_SAVED_MESSAGE }));
  } catch (e) {
    yield put(updateCompanyDataFail(e));
  }
}

function* changeCompanyWebsite({ payload }) {
  try {
    const operatorService = new OperatorService();

    const currentOperator = yield select(state => state.operator.currentOperator);
    currentOperator.website = payload;

    delete currentOperator.isActive;

    yield operatorService.updateCurrentOperator(currentOperator);

    yield put(getCurrentOperatorStart());
    yield put(setNotification({ type: "success", message: CHANGES_SAVED_MESSAGE }));
  } catch (e) {
    yield put(updateCompanyDataFail(e));
  }
}

function* changeCompanyLanguage({ payload }) {
  try {
    const operatorService = new OperatorService();

    const currentOperator = yield select(state => state.operator.currentOperator);
    currentOperator.defaultLanguageCode = payload;

    delete currentOperator.isActive;

    yield operatorService.updateCurrentOperator(currentOperator);

    yield put(getCurrentOperatorStart());
    yield put(setNotification({ type: "success", message: CHANGES_SAVED_MESSAGE }));
  } catch (e) {
    yield put(updateCompanyDataFail(e));
  }
}

function* changeCompanyAdditionalLanguages({ payload }) {
  try {
    const operatorService = new OperatorService();

    const currentOperator = yield select(state => state.operator.currentOperator);
    currentOperator.additionalLanguageCodes = payload;

    delete currentOperator.isActive;

    yield operatorService.updateCurrentOperator(currentOperator);

    yield put(getCurrentOperatorStart());
    yield put(setNotification({ type: "success", message: CHANGES_SAVED_MESSAGE }));
  } catch (e) {
    yield put(updateCompanyDataFail(e));
  }
}

function* getIconsList() {
  try {
    const { data } = yield HttpClient.get(GET_ICONS);
    const iconsListSortedAlphabetically = data.items.sort((a, b) => a.name.localeCompare(b.name));
    yield put(getIconsListSuccess(iconsListSortedAlphabetically));
  } catch (e) {
    if (!HttpClient.isCancelError(e)) {
      yield put(getIconsListFail(e));
    }
  }
}

export function* operatorSaga() {
  yield takeEvery(actionTypes.GET_CURRENT_OPERATOR_DATA_START, getCurrentOperatorData);
  yield takeEvery(actionTypes.UPDATE_COMPANY_DATA_START, updateCompanyData);
  yield takeEvery(actionTypes.SWITCH_COMPANY_POIS_LIST, switchCompanyPoisList);
  yield takeEvery(actionTypes.CHANGE_COMPANY_TEMPERATURE_SCALE, changeCompanyTemperatureScale);
  yield takeEvery(actionTypes.CHANGE_COMPANY_STORYBOARD_VIEW, changeCompanyStoryboardView);
  yield takeEvery(actionTypes.CHANGE_COMPANY_NAME, changeCompanyName);
  yield takeEvery(actionTypes.CHANGE_COMPANY_CONTACT_PERSON, changeCompanyContactPerson);
  yield takeEvery(actionTypes.CHANGE_COMPANY_TELEPHONE, changeCompanyTelephone);
  yield takeEvery(actionTypes.CHANGE_COMPANY_NUMBER_OF_BOOKINGS, changeCompanyNumberOfBookings);
  yield takeEvery(actionTypes.CHANGE_COMPANY_NUMBER_OF_PASSENGERS, changeCompanyNumberOfPassengers);
  yield takeEvery(actionTypes.CHANGE_COMPANY_DESCRIPTION, changeCompanyDescription);
  yield takeEvery(actionTypes.CHANGE_COMPANY_WEBSITE, changeCompanyWebsite);
  yield takeEvery(actionTypes.CHANGE_COMPANY_LANGUAGE, changeCompanyLanguage);
  yield takeEvery(actionTypes.CHANGE_COMPANY_ADDITIONAL_LANGUAGES, changeCompanyAdditionalLanguages);
  yield takeEvery(actionTypes.GET_ICONS_LIST_START, getIconsList);
}
