import React, { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import Grid from "@material-ui/core/Grid";

import { Switch } from "components/ui/Forms";
import { StyledWrapper, StyledLeadText } from "components/ui/Forms/FormSection";
import { DndSettings } from "feature/panel/Stays/_shared/DoNotDisturb/DndSettings";
import { DndRequestsList } from "feature/panel/Stays/_shared/DoNotDisturb/DndRequestsList";

import { stayWizardChangeDndEmail, stayWizardChangeDndTime, stayWizardToggleDnd } from "store/stayWizard/actions";
import { STAY_WIZARD_CONTENT } from "constants/content";
import { usePermissionsService } from "hooks/usePermissionsService";
import { PERMISSIONS } from "constants/permissions";
import { PANEL_STAYS_EDIT_PATH } from "constants/routes";
import { LanguageContext } from "components/_shared/LanguageSelector/LanguageContext";
import { setUrl } from "utils/url";
import { useNavigate } from "react-router-dom";

const Header = styled.header`
  margin-bottom: ${({ theme }) => theme.setSpacing(10)}px;
  display: flex;
  align-items: center;
`;

const SectionSwitch = styled(Switch)`
  && {
    margin-left: ${({ theme }) => theme.setSpacing(7)}px;
  }
`;

const DoNotDisturb = () => {
  const permissionsService = usePermissionsService();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { currentLanguage } = useContext(LanguageContext);

  const showDnd = useSelector(({ stayWizard }) => stayWizard.form.dnd.show);
  const contactEmail = useSelector(({ stayWizard }) => stayWizard.form.dnd.email);
  const cutOffTime = useSelector(({ stayWizard }) => stayWizard.form.dnd.time);

  const isWiped = useSelector(({ stayWizard }) => stayWizard.form.isWiped);
  const vamoosId = useSelector(({ stayWizard }) => stayWizard.form.vamoosId);
  const userId = useSelector(({ stayWizard }) => stayWizard.form.userId);
  const editMode = useSelector(({ stayWizard }) => stayWizard.form.vamoosId !== null);

  const canEdit = permissionsService.can(PERMISSIONS.actions.update, PERMISSIONS.sections.vamoosList, vamoosId);

  const isStayDefaultLanguage = useSelector(({ stayWizard }) =>
    stayWizard.defaultVariant.language ? stayWizard.defaultVariant.language === currentLanguage.code : true,
  );

  const handleActiveChange = () => {
    dispatch(stayWizardToggleDnd());
  };

  const handleEmailChange = value => {
    dispatch(stayWizardChangeDndEmail(value));
  };

  const handleTimeChange = value => {
    dispatch(stayWizardChangeDndTime(value));
  };

  useEffect(() => {
    if (!isStayDefaultLanguage) {
      const url = setUrl(PANEL_STAYS_EDIT_PATH, { operator_code: userId }, true);
      navigate(url);
    }
  }, [isStayDefaultLanguage]);

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} md={10}>
        <StyledWrapper>
          <Header>
            <StyledLeadText>{STAY_WIZARD_CONTENT.dnd.title}</StyledLeadText>
            <SectionSwitch
              disabled={isWiped || (editMode && !canEdit)}
              checked={showDnd === "during"}
              onChange={handleActiveChange}
              inputProps={{
                "data-testid": "during",
              }}
            />
          </Header>
          <DndSettings
            onEmailChange={handleEmailChange}
            onTimeChange={handleTimeChange}
            time={cutOffTime}
            email={contactEmail}
            disabled={isWiped || (editMode && !canEdit)}
          />
          <DndRequestsList />
        </StyledWrapper>
      </Grid>
    </Grid>
  );
};

export { DoNotDisturb, Header, SectionSwitch };
