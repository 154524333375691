import * as actionTypes from "store/adminUsers/actionTypes";

import { reducer, setState, globalReducerActions } from "utils/store";

const adminUsersInitialState = {
  errors: {},
  actionType: null,
  inProgress: false,
  finished: false,
  listOfUsers: [],
  usersCount: 0,
  resetIdsInProgress: [],
  operatorFilterItems: [],
};

const ReducerActions = globalReducerActions("adminUsers", adminUsersInitialState, {
  [actionTypes.GET_ADMIN_USERS_START](state) {
    return setState(state, {
      actionType: actionTypes.GET_ADMIN_USERS_START,
      inProgress: true,
      finished: false,
    });
  },

  [actionTypes.GET_ADMIN_USERS_SUCCESS](state, payload) {
    const { count, items } = payload;
    return setState(state, {
      actionType: actionTypes.GET_ADMIN_USERS_SUCCESS,
      inProgress: false,
      finished: true,
      listOfUsers: items,
      usersCount: count,
    });
  },

  [actionTypes.GET_ADMIN_USERS_FAIL](state, payload) {
    return setState(state, {
      actionType: actionTypes.GET_ADMIN_USERS_FAIL,
      inProgress: false,
      finished: false,
      errors: payload,
    });
  },

  [actionTypes.RESET_USER_PASSWORD_PUSH](state, payload) {
    const { id } = payload;
    return setState(state, {
      resetIdsInProgress: [...state.resetIdsInProgress, id],
    });
  },

  [actionTypes.RESET_USER_PASSWORD_FINISH](state, payload) {
    const { id } = payload;
    return setState(state, {
      resetIdsInProgress: state.resetIdsInProgress.filter(userId => userId !== id),
    });
  },

  [actionTypes.GET_ADMIN_USERS_OPERATORS_SUCCESS](state, payload) {
    const { operators } = payload;

    return setState(state, {
      operatorFilterItems: operators,
    });
  },
});

const adminUsersReducer = (state = adminUsersInitialState, action) => reducer(state, action, ReducerActions);

export { adminUsersReducer };
