import styled from "styled-components";
import ButtonGroup from "@material-ui/core/ButtonGroup";

import { GhostButton } from "components/ui/Buttons/GhostButton";

export const ContentDivider = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.setSpacing(10)}px;

  :last-of-type {
    margin-bottom: 24px;
  }

  & > * {
    :nth-child(1) {
      flex-grow: 1;
    }
    :nth-child(2) {
      min-width: 100px;
    }
    :nth-child(3) {
      min-width: 115px;
    }
    :nth-child(4) {
      min-width: 145px;
    }
  }
`;

export const SizePicker = styled(ButtonGroup)`
  && {
    padding: 2px;
    border-radius: ${({ theme }) => theme.setSpacing(2)}px;
    background: ${({ theme }) => theme.colors.grey40};
    ${({ disabled }) => disabled && "opacity: 0.2;"} :disabled {
      opacity: 0.2;
    }
    height: 28px;
  }
`;

export const PickerButton = styled(GhostButton)`
  &&& {
    border: none;
    height: 24px;
    color: ${({ theme, selected }) => theme.colors[selected ? "black" : "white"]};
    background: ${({ theme, selected }) => (selected ? theme.colors.white : "none")};
    padding: 0 ${({ theme }) => theme.setSpacing(4)}px;
    text-transform: none;
    font-weight: normal;
    border-radius: 7px;
    ${({ selected }) => selected && "box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12);"}
  }
`;

export const FlexArea = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
