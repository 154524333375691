import { useState, useEffect } from "react";
import { StorageService } from "services/StorageService";

const useTableDynamicHeaders = (localStorageKey, columns) => {
  const storageService = new StorageService();

  const [headers, setHeaders] = useState([]);

  const init = () => {
    let cleanup = true;
    storageService
      .getPropertyByName(localStorageKey)
      .then(result => {
        if (cleanup) {
          if (result) {
            if (Array.isArray(result)) setHeaders({ all: result });
            else setHeaders(result);
          } else setHeaders(columns);
        }
      })
      .catch(() => {
        if (cleanup) setHeaders([]);
      });

    return () => {
      cleanup = false;
    };
  };

  useEffect(init, []);
  return [headers, setHeaders];
};

export { useTableDynamicHeaders };
