import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import moment from "moment";

import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";

import { Content } from "components/ui/Content/Content";
import { BodyText2, CaptionText, LeadText } from "components/ui/Typography/Typography";
import { Select, Textarea, TimePicker } from "components/ui/Forms";
import { StyledWrapper } from "components/ui/Forms/FormSection";

import { getInspirationsStart } from "store/inspirations/actions";

import { GLOBAL_CONTENT, INSPIRATIONS, NO_INSPIRATIONS_MESSAGE, PANEL_TRIPS_CREATE_TEXTS } from "constants/content";
import { LoadingScreen } from "components/ui/LoadingScreen/LoadingScreen";
import { DaySelector } from "components/ui/Forms/DaySelector";
import { Message } from "components/ui/Messages";
import {
  DISPLAYABLE_STARTING_POINT_OPTIONS,
  calculateSelectedDayValue,
  calculateStartingPointChange,
  calculateStartingPointValue,
} from "utils/notifications";
import config from "config/app";
import {
  stayWizardSetInspiration,
  stayWizardSetInspirationNotificationContent,
  stayWizardSetInspirationNotificationPeriod,
} from "store/stayWizard/actions";
import { usePermissionsService } from "hooks/usePermissionsService";
import { PERMISSIONS } from "constants/permissions";
import { AccessDenied } from "components/templates/_shared/AccessDenied/AccessDenied";

const AtWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const NotificationSettingsWrapper = styled.div`
  label {
    font-size: 12px !important;
  }
`;

const StayInspirations = () => {
  const permissionsService = usePermissionsService();
  const dispatch = useDispatch();

  const inspirationVamoosId = useSelector(({ stayWizard }) => stayWizard.form.inspiration.vamoosId || "");
  const notificationContent = useSelector(({ stayWizard }) => stayWizard.form.inspiration.settings.content);
  const deliveryAtDays = useSelector(({ stayWizard }) => stayWizard.form.inspiration.settings.period.deliveryAtDays);
  const startAt = useSelector(({ stayWizard }) => stayWizard.form.inspiration.settings.period.startAt);
  const deliveryAtRelativeTo = useSelector(({ stayWizard }) => stayWizard.form.inspiration.settings.period.deliveryAtRelativeTo);
  const errors = useSelector(({ stayWizard }) => stayWizard.errors);

  const isWiped = useSelector(({ stayWizard }) => stayWizard.form.isWiped);
  const vamoosId = useSelector(({ stayWizard }) => stayWizard.form.vamoosId);
  const editMode = useSelector(({ stayWizard }) => stayWizard.form.vamoosId !== null);

  const canEdit = permissionsService.can(PERMISSIONS.actions.update, PERMISSIONS.sections.vamoosList, vamoosId);
  const canReadInspirations = permissionsService.can(
    PERMISSIONS.actions.read,
    PERMISSIONS.sections.operator,
    PERMISSIONS.resources.inspirations,
  );

  const { listOfInspirations, inProgress } = useSelector(state => state.inspirations);
  const startingPointValue = calculateStartingPointValue(deliveryAtRelativeTo, deliveryAtDays);

  const shouldShowNotificationDate = inspirationVamoosId && startingPointValue !== DISPLAYABLE_STARTING_POINT_OPTIONS.disabled.value;

  const shouldShowDaySelector =
    startingPointValue &&
    startingPointValue !== DISPLAYABLE_STARTING_POINT_OPTIONS.startDay.value &&
    startingPointValue !== DISPLAYABLE_STARTING_POINT_OPTIONS.endDay.value &&
    startingPointValue !== DISPLAYABLE_STARTING_POINT_OPTIONS.disabled.value;

  const getAllInspirations = () => {
    dispatch(getInspirationsStart());
  };

  const handleInspirationIdChange = ({ target: { value } }) => {
    dispatch(stayWizardSetInspiration(value));
  };

  const handleMessageChange = ({ target: { value } }) => {
    dispatch(stayWizardSetInspirationNotificationContent(value));
  };

  const handleDayChange = ({ target: { value } }) => {
    const day = calculateSelectedDayValue(value, startingPointValue);

    dispatch(
      stayWizardSetInspirationNotificationPeriod({
        deliveryAtDays: day,
        startAt,
        deliveryAtRelativeTo,
      }),
    );
  };

  const handleStartingPointChange = ({ target: { value } }) => {
    const newInspirationNotification = calculateStartingPointChange(
      {
        delivery_at_days: deliveryAtDays,
        delivery_at_relative_to: deliveryAtRelativeTo,
      },
      value,
    );

    dispatch(
      stayWizardSetInspirationNotificationPeriod({
        deliveryAtDays: newInspirationNotification.delivery_at_days,
        deliveryAtRelativeTo: newInspirationNotification.delivery_at_relative_to,
        startAt,
      }),
    );
  };

  const handleNotificationTimeUpdate = momentValue => {
    const time = momentValue && momentValue.isValid() ? momentValue.format(config.timeFormat) : null;

    dispatch(
      stayWizardSetInspirationNotificationPeriod({
        deliveryAtDays,
        deliveryAtRelativeTo,
        startAt: time,
      }),
    );
  };

  useEffect(getAllInspirations, []);

  const mainContent = (() => {
    if (inProgress) return <LoadingScreen />;
    if (!listOfInspirations || listOfInspirations.length === 0) return <Message type="info" text={NO_INSPIRATIONS_MESSAGE} />;

    return (
      <Grid container justifyContent="space-between" spacing={10}>
        <Grid item xs={12} md={12} lg={5}>
          <Content>
            <Select
              name="inspiration"
              value={inspirationVamoosId}
              label={INSPIRATIONS.chooseInspirations}
              onChange={handleInspirationIdChange}
              disabled={isWiped || (editMode && !canEdit)}
            >
              <MenuItem value="">
                <em>{GLOBAL_CONTENT.none}</em>
              </MenuItem>
              {listOfInspirations.map(inspirationsItem => (
                <MenuItem key={inspirationsItem.id} value={inspirationsItem.vamoos_id}>
                  {inspirationsItem.name}
                </MenuItem>
              ))}
            </Select>
          </Content>

          <Content noMargin>
            <Textarea
              name="inspiration_content"
              label={INSPIRATIONS.messageContent}
              rows={4}
              value={notificationContent}
              onChange={handleMessageChange}
              disabled={
                !inspirationVamoosId ||
                startingPointValue === DISPLAYABLE_STARTING_POINT_OPTIONS.disabled.value ||
                isWiped ||
                (editMode && !canEdit)
              }
              error={!!errors?.inspiration?.content}
              helperText={errors?.inspiration?.content}
              isRequired
            />
          </Content>
        </Grid>
        <Grid item md={12} lg={7}>
          <Grid item xs={12}>
            <NotificationSettingsWrapper>
              <Content>
                <BodyText2>{PANEL_TRIPS_CREATE_TEXTS.inspirationsForm.text1}</BodyText2>
              </Content>
              <Content>
                <Grid container spacing={6}>
                  {shouldShowDaySelector && (
                    <Grid item sm={12} md={6} lg={3}>
                      <DaySelector
                        label={INSPIRATIONS.numberOfDays}
                        value={deliveryAtDays}
                        placeholder="0"
                        onChange={handleDayChange}
                        disabled={!inspirationVamoosId || isWiped || (editMode && !canEdit)}
                      />
                    </Grid>
                  )}
                  <Grid item sm={12} md={6} lg={5}>
                    <Select
                      value={startingPointValue}
                      label={INSPIRATIONS.period}
                      onChange={handleStartingPointChange}
                      name="inspiration_start_day"
                      disabled={!inspirationVamoosId || isWiped || (editMode && !canEdit)}
                    >
                      {Object.values(DISPLAYABLE_STARTING_POINT_OPTIONS).map(({ value, label }) => (
                        <MenuItem value={value} key={value}>
                          {label}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                  {shouldShowNotificationDate && (
                    <>
                      <Grid item sm={4} md={2} lg={1}>
                        <AtWrapper>
                          <BodyText2>{GLOBAL_CONTENT.at}</BodyText2>
                        </AtWrapper>
                      </Grid>
                      <Grid item sm={8} md={10} lg={3}>
                        <TimePicker
                          fullWidth
                          label={INSPIRATIONS.deviceLocalTime}
                          name="inspiration_start_time"
                          value={moment(startAt, config.timeFormat)}
                          onChange={handleNotificationTimeUpdate}
                          disabled={!inspirationVamoosId || isWiped || (editMode && !canEdit)}
                        />
                      </Grid>
                    </>
                  )}
                  <Grid item xs={12}>
                    <CaptionText cv="grey50">{PANEL_TRIPS_CREATE_TEXTS.inspirationsForm.text2}</CaptionText>
                  </Grid>
                </Grid>
              </Content>
            </NotificationSettingsWrapper>
          </Grid>
        </Grid>
      </Grid>
    );
  })();

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} md={10}>
        {canReadInspirations ? (
          <StyledWrapper>
            <Content margin={9}>
              <Grid container alignItems="center">
                <LeadText>{INSPIRATIONS.title}</LeadText>
              </Grid>
            </Content>
            {mainContent}
          </StyledWrapper>
        ) : (
          <AccessDenied />
        )}
      </Grid>
    </Grid>
  );
};

export { StayInspirations, AtWrapper };
