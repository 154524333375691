import { tripInitialState } from "feature/panel/Trips/_shared/tripInitialState";

export const tripStateReducer = (state, action) => {
  switch (action.type) {
    case "setValueFor": {
      return {
        ...state,
        [action.fieldName]: action.value,
      };
    }
    case "setAllValues": {
      return {
        ...state,
        ...action.payload,
      };
    }
    case "resetAllValues": {
      return tripInitialState;
    }
    default:
      return state;
  }
};
