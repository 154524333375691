import React, { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { debounce } from "lodash";

import { InformationField } from "feature/panel/Settings/_shared/InformationField";
import { FieldChangeModal } from "feature/panel/Settings/_shared/FieldChangeModal";

import { USER_DETAILS_EMAIL_KEY, USER_DETAILS_USERNAME_KEY } from "feature/admin/Users/Details/_shared/constants";
import { userDetailsValidator } from "feature/admin/Users/Details/_shared/validatorForm";

import {
  clearUserDetailsErrors,
  getAdminEmailUniquenessStart,
  getAdminUsernameUniquenessStart,
  setValueForUserDetails,
} from "store/adminUserDetails/actions";

import {
  USER_DETAILS_NAME_LABEL,
  USER_DETAILS_EMAIL_LABEL,
  USER_DETAILS_LAST_LOGIN_LABEL,
  USER_DETAILS_PASSWORD_EXPIRATION_LABEL,
  USER_DETAILS_PHONENUMBER_LABEL,
  USER_DETAILS_USERNAME_LABEL,
} from "constants/content";
import { emailValidate } from "utils/validation";
import { DEFAULT_DEBOUNCE_DELAY } from "constants/defaults";

import config from "config/app";
import { usePermissionsService } from "hooks/usePermissionsService";
import { PERMISSIONS } from "constants/permissions";

const UserInfo = () => {
  const permissionsService = usePermissionsService();
  const [editModal, setEditModal] = useState({ open: false, body: null });
  const [errors, setErrors] = useState({});
  const { user, errors: reduxErrors } = useSelector(state => state.adminUserDetails);

  const dispatch = useDispatch();

  const debounceUniquenesRef = useRef(debounce(action => dispatch(action), DEFAULT_DEBOUNCE_DELAY));

  const clearErrors = () => {
    setErrors({});
    dispatch(clearUserDetailsErrors());
  };

  const getErrors = field => {
    return errors?.value || reduxErrors[field?.fieldName];
  };

  const validateForm = field => {
    const errorsList = userDetailsValidator(field.fieldName, field.value);
    setErrors(errorsList);

    return !(errorsList.value || reduxErrors.username || reduxErrors.email);
  };

  const handleValueChange = ({ target }) => {
    const action =
      editModal.body.fieldName === USER_DETAILS_USERNAME_KEY
        ? getAdminUsernameUniquenessStart(target.value)
        : getAdminEmailUniquenessStart(target.value);

    if (
      editModal.body.fieldName === USER_DETAILS_USERNAME_KEY ||
      (editModal.body.fieldName === USER_DETAILS_EMAIL_KEY && !!emailValidate(target.value))
    )
      debounceUniquenesRef.current(action);

    setEditModal({
      ...editModal,
      body: {
        ...editModal.body,
        value: target.value,
        isFormTouched: true,
      },
    });
  };

  const handleEditionToggle = field => {
    const { open } = editModal;
    const body = open ? editModal.body : { ...field, isFormTouched: false };
    setEditModal({ open: !open, body });
    clearErrors();
  };

  const handleEditionConfirm = field => {
    const isValid = validateForm(field);

    if (isValid) {
      dispatch(setValueForUserDetails({ fieldName: editModal?.body?.fieldName, value: editModal?.body?.value }));
      handleEditionToggle();
      clearErrors();
    }
  };

  const nameFieldValue = user?.firstname || user?.lastname ? `${user?.firstname || ""} ${user?.lastname || ""}` : null;

  const isEditPermitted = permissionsService.can(
    PERMISSIONS.actions.update,
    PERMISSIONS.sections.admin,
    PERMISSIONS.resources.users,
    user?.id || null,
  );

  const usernameEditHandler = () => {
    handleEditionToggle({ fieldName: USER_DETAILS_USERNAME_KEY, label: USER_DETAILS_USERNAME_LABEL, value: user?.username });
  };

  const emailEditHandler = () => {
    handleEditionToggle({
      fieldName: USER_DETAILS_EMAIL_KEY,
      label: USER_DETAILS_EMAIL_LABEL,
      value: user?.email,
    });
  };

  return (
    <>
      <InformationField
        title={USER_DETAILS_USERNAME_LABEL}
        content={user?.username || "-"}
        onEditClick={isEditPermitted ? usernameEditHandler : null}
      />
      <InformationField
        title={USER_DETAILS_EMAIL_LABEL}
        content={user?.email || "-"}
        onEditClick={isEditPermitted ? emailEditHandler : null}
      />
      <InformationField title={USER_DETAILS_NAME_LABEL} content={nameFieldValue || "-"} />
      <InformationField title={USER_DETAILS_LAST_LOGIN_LABEL} content={moment(user?.lastLoginAt).format(config.dateTimeFormat) || "-"} />
      <InformationField
        title={USER_DETAILS_PASSWORD_EXPIRATION_LABEL}
        content={moment(user?.passwordExpiresAt).format(config.dateFormat) || "-"}
      />
      <InformationField title={USER_DETAILS_PHONENUMBER_LABEL} content={user?.meta?.telephone || "-"} />
      <FieldChangeModal
        open={editModal.open}
        label={editModal.body?.label || ""}
        value={editModal.body?.value || ""}
        onValueEdit={handleValueChange}
        onConfirm={() => handleEditionConfirm(editModal.body)}
        onCancel={handleEditionToggle}
        inputProps={{ error: !!getErrors(editModal.body), helperText: getErrors(editModal.body) }}
      />
    </>
  );
};

export { UserInfo };
