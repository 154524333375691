import { setUrl } from "utils/url";
import { OPERATE_ON_MESSAGES_FOR_CONVERSATION_URL } from "constants/api";
import { HttpClient } from "services/application/httpClient/httpClient";

const sendNewMessageHandler = (conversationDetails, { requestData, errorMessage }) => {
  return new Promise((resolve, reject) => {
    const postMessageURL = setUrl(OPERATE_ON_MESSAGES_FOR_CONVERSATION_URL, conversationDetails);
    HttpClient.post(postMessageURL, requestData)
      .then(({ data }) => resolve(data))
      .catch(({ stack }) => {
        reject({ stack: `${errorMessage}. Reason: ${stack}` });
      });
  });
};

export { sendNewMessageHandler };
