export const directoryReducer = (state, action) => {
	switch (action.type) {
		case "setValueFor": {
			return {
				...state,
				[action.fieldName]: action.value,
			};
		}
		case "removeParentId": {
			const newDirectory = { ...state };
			
			if(newDirectory.parent_id) {
				delete newDirectory.parent_id;
			}

			return newDirectory;
		}
		case "setAllValues": {
			return action.payload
		}
		default:
			return state;
	}
};
