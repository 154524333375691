import React from "react";
import styled from "styled-components";

import { ReactComponent as General } from "assets/images/ca_navigations_icons/General.svg";
import { ReactComponent as Background } from "assets/images/ca_navigations_icons/background.svg";
import { ReactComponent as Element } from "assets/images/ca_navigations_icons/Element.svg";
import { ReactComponent as Menu } from "assets/images/ca_navigations_icons/Menu.svg";
import { ReactComponent as Texts } from "assets/images/ca_navigations_icons/txt.svg";
import { ReactComponent as AllScreen } from "assets/images/ca_navigations_icons/All.svg";
import { ReactComponent as History } from "assets/images/ca_navigations_icons/vhistory.svg";
import { ReactComponent as Info } from "assets/images/ca_navigations_icons/Info.svg";

const FillColorWrapper = styled.div`
  && {
    svg {
      ${({ isActive, theme }) =>
        isActive
          ? `
      fill: ${theme.colors.brand};
      path {
        fill: ${theme.colors.brand};
      }
      rect {
        fill: ${theme.colors.brand};
      } 
      `
          : ""}
    }
  }
`;
const StronkeColorWrapper = styled.div`
  && {
    svg {
      ${({ isActive, theme }) =>
        isActive
          ? ` 
      path {
        stroke: ${theme.colors.brand};
      }  
      `
          : ""}
    }
  }
`;
const ElementColorWrapper = styled.div`
  && {
    svg {
      path {
        fill: #ffffff00;
      }
      ${({ isActive, theme }) =>
        isActive
          ? ` 
      path {
        stroke: ${theme.colors.brand};
      }  
      `
          : ""}
    }
  }
`;

const TextIconPreview = styled.div`
  && {
    svg {
      path:first-of-type {
        fill: #ffffff00;
      }

      ${({ isActive, theme }) =>
        isActive
          ? ` 
      path {
        &:first-of-type {
          stroke: ${theme.colors.brand}; 
        }
        &:last-of-type {
          fill: ${theme.colors.brand};
        }
      }  
      `
          : ""}
    }
  }
`;

const PreviewIconWrapper = styled.div`
  && {
    svg {
      path:first-of-type {
        fill: #ffffff00;
      }
      ${({ isActive, theme }) =>
        isActive
          ? ` 
      path {
        &:first-of-type {
          stroke: ${theme.colors.brand};
          fill: ${theme.colors.brand}00;
        }
        &:nth-of-type(2) {
          fill: ${theme.colors.brand};
        }
        &:last-of-type {
          fill: ${theme.colors.brand};
        }
      }  
      `
          : ""}
    }
  }
`;

const GeneralIcon = ({ isActive }) => {
  return (
    <FillColorWrapper isActive={isActive}>
      <General />
    </FillColorWrapper>
  );
};

const BackgroundIcon = ({ isActive }) => {
  return (
    <StronkeColorWrapper isActive={isActive}>
      <Background />
    </StronkeColorWrapper>
  );
};

const ElementIcon = ({ isActive }) => {
  return (
    <ElementColorWrapper isActive={isActive}>
      <Element />
    </ElementColorWrapper>
  );
};

const MenuIcon = ({ isActive }) => {
  return (
    <FillColorWrapper isActive={isActive}>
      <Menu />
    </FillColorWrapper>
  );
};

const TextsIcon = ({ isActive }) => {
  return (
    <TextIconPreview isActive={isActive}>
      <Texts />
    </TextIconPreview>
  );
};

const AllScreenIcon = ({ isActive }) => {
  return (
    <PreviewIconWrapper isActive={isActive}>
      <AllScreen />
    </PreviewIconWrapper>
  );
};

const HistoryIcon = ({ isActive }) => {
  return (
    <StronkeColorWrapper isActive={isActive}>
      <History />
    </StronkeColorWrapper>
  );
};

const InfoIcon = ({ isActive }) => {
  return (
    <FillColorWrapper isActive={isActive}>
      <Info />
    </FillColorWrapper>
  );
};

export { GeneralIcon, BackgroundIcon, ElementIcon, MenuIcon, TextsIcon, AllScreenIcon, HistoryIcon, InfoIcon };
