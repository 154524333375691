import React from "react";
import styled from "styled-components";

import transparentBg from "assets/images/transparencybg.png";

export const PICKER_POSITION = {
  left: "left",
  right: "right",
  top: "top",
  bottom: "bottom",
};

const ColorPickerWrapper = styled.div`
  background: white;
  height: ${({ alphaChannel }) => (alphaChannel ? 460 : 410)}px;
  width: 300px;
  border-radius: 20px;
  z-index: 1100;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.37);
  position: absolute;

  ${({ position, alphaChannel }) => {
    if (position === PICKER_POSITION.left) {
      return `
        left: -325px;
        top: -140px;
      `;
    }
    if (position === PICKER_POSITION.top) {
      return `
        left: -130px;
        top: -${alphaChannel ? 480 : 430}px;
      `;
    }
    if (position === PICKER_POSITION.bottom) {
      return `
        left: -130px;
        top: 45px;
      `;
    }
    return `
        left: 60px;
        top: -140px;
      `;
  }}
`;

const Indicator = styled.div`
  position: absolute;
  z-index: 1101;
  width: 30px;
  height: 30px;
  overflow: hidden;
  box-shadow: 0 16px 10px -17px rgb(0 0 0 / 50%);
  ${({ position }) => {
    if (position === PICKER_POSITION.left) {
      return `
        left: -25px;
        top: 0px; 

        &:after {
          top: 2px;
          left: -15px;

        }
      `;
    }
    if (position === PICKER_POSITION.top) {
      return `
        left: 0;
        top: -20px;

        &:after {
          top: -15px;
          left: 2px;
        }
      `;
    }
    if (position === PICKER_POSITION.bottom) {
      return `
        left: 0;
        top: 16px;

        &:after {
          top: 20px;
          left: 2px;
        }
      `;
    }
    return `
      left: 30px;
      top: 0px;  
      
      &:after {
        top: 5px;
        left: 25px;
      }
      `;
  }}
  &:after {
    content: "";
    position: absolute;
    width: 25px;
    height: 25px;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    box-shadow: 0px 0px 4px 0px rgb(0 0 0 / 50%);
    background: ${({ theme }) => theme.colors.white};
  }
`;

const ArrowLeft = styled.div`
  width: 0;
  height: 0;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;

  border-right: 5px solid ${({ theme }) => theme.colors.black};
`;
const ArrowRight = styled.div`
  width: 0;
  height: 0;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;

  border-left: 5px solid ${({ theme }) => theme.colors.black};
`;

const Slider = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 1px;
  width: 30px;
  height: 10px;
  transform: translateX(-5px);
  cursor: pointer;
  margin-top: -5px;
`;
const AlphaChannelSlider = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 1px;
  width: 30px;
  height: 10px;
  transform: translateX(-8px);
  cursor: pointer;
  margin-top: -5px;
`;

const Pointer = styled.div`
  width: 14px;
  background: white;
  height: 14px;
  border-radius: 10px;
  transform: translate(0, 0);
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.37);
  margin-top: -7px;
  margin-left: -7px;
`;
const Label = styled.label`
  width: 100%;
  padding: 20px 25px 15px;
  font-family: "Montserrat";
  font-size: 18px;
  display: block;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey10};
`;
const Actions = styled.div`
  width: 100%;
  text-align: right;
  padding: 0 20px;
`;
const Container = styled.div`
  margin: 10px 0;
  padding: 0 0 10px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey10};
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-evenly;
`;

const SaturationContainer = styled.div`
  position: relative;
  width: 210px;
  height: 210px;
  cursor: pointer;
`;

const HueContainer = styled.div`
  position: relative;
  width: ${({ theme }) => theme.setSpacing(5)}px;
  height: 210px;
  cursor: pointer;
`;

const ColorPreview = styled.div`
  width: ${({ theme }) => theme.setSpacing(10)}px;
  background-image: url(${transparentBg});
  background-size: cover;
  height: ${({ theme }) => theme.setSpacing(10)}px;
  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.colors.grey10};
  position: relative;
  overflow: hidden;
  cursor: pointer;

  &:after {
    content: "";
    background-color: ${({ theme, color }) => color || theme.colors.white};
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
`;

const CustomInputColor = styled.div`
  width: ${({ theme }) => theme.setSpacing(30)}px;
  border: 1px solid ${({ theme }) => theme.colors.grey40};
  color: ${({ theme }) => theme.colors.grey40};
  border-radius: ${({ theme }) => theme.setSpacing(2)}px;
  padding: ${({ theme }) => theme.setSpacing(2)}px;
  text-align: center;
`;

const CustomSlider = () => {
  return (
    <Slider>
      <ArrowRight />
      <ArrowLeft />
    </Slider>
  );
};

const AlphaSlider = () => {
  return (
    <AlphaChannelSlider>
      <ArrowRight />
      <ArrowLeft />
    </AlphaChannelSlider>
  );
};

const CustomPointer = () => {
  return <Pointer />;
};

export {
  ColorPickerWrapper,
  CustomPointer,
  CustomSlider,
  AlphaSlider,
  CustomInputColor,
  ColorPreview,
  HueContainer,
  SaturationContainer,
  Container,
  Actions,
  Label,
  Indicator,
};
