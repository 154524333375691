import { Operator } from "domain/Operator";
import * as actionTypes from "store/operator/actionTypes";
import { reducer, setState, globalReducerActions } from "utils/store";

const initialState = {
  currentOperator: new Operator(),
  availableIcons: [],
  selectedButtonFields: {},
};

const ReducerOperator = globalReducerActions("notifications", initialState, {
  [actionTypes.GET_CURRENT_OPERATOR_DATA_SUCCESS](state, payload) {
    return setState(state, {
      currentOperator: payload
    });
  },
  [actionTypes.UPDATE_COMPANY_DATA_SUCCESS](state, payload) {
    return setState(state, {
      currentOperator: {
        ...state.currentOperator,
        [payload.fieldName]: payload.value,
      }
    });
  },
  [actionTypes.GET_ICONS_LIST_SUCCESS](state, payload) {
    return setState(state, {
      availableIcons: payload
    });
  },
});

export const operatorReducer = (state = initialState, action) => reducer(state, action, ReducerOperator);
