import React from "react";
import { Controller } from "react-hook-form";
import { Input } from "components/ui/Forms";
import { inputs } from "./inputs";
import { Grid } from "./style";

const ManualForm = ({ control, errors }) => {
  return (
    <Grid>
      {inputs.map(item => (
        <Controller
          control={control}
          name={item.name}
          key={item.key}
          rules={item.rules}
          render={({ field: { onChange, value } }) => {
            const { name, label } = item;
            const errorMessage = errors[name]?.message;

            return item.element ? (
              React.cloneElement(item.element, {
                name,
                label,
                value,
                onChange,
              })
            ) : (
              <Input
                {...item}
                size="large"
                value={value}
                onChange={e => onChange(e.target.value)}
                error={errorMessage}
                errorMessages={errorMessage}
              />
            );
          }}
        />
      ))}
    </Grid>
  );
};

export default ManualForm;
