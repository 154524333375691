// @ts-check

import { StayOverride } from "domain/StayOverride";
import { StayOverrideRequestDto } from "dto/StayOverrideRequestDto";
import { BaseMapper } from "./BaseMapper";

export class OverrideMapper extends BaseMapper {
  fromDomainToDto(domain) {
    const dto = new StayOverrideRequestDto();

    dto.short_description = domain.shortDescription;
    dto.long_description = domain.longDescription;

    return dto;
  }

  fromDtoToDomain(dto) {
    return StayOverride(dto);
  }
}