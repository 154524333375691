const DEFAULT_DEBOUNCE_DELAY = 300;
const DEFAULT_PAGE_SIZE = 5000;
const IMAGES_MIME_TYPES = ["image/jpg", "image/jpeg", "image/png", "image/gif"];
const IMAGES_MIME_TYPES_NO_GIF = ["image/jpg", "image/jpeg", "image/png"];
const GPX_MIME_TYPES = ["application/gpx+xml", "application/octet-stream", ".gpx"];
const DOCUMENTS_FILE_TYPES = ["application/pdf"];
const VIDEO_FILE_TYPES = ["video/mp4"];
const DEFAULT_WINDOW_HEIGHT = "100%";
const MAX_BRAND_HEADER_IMAGE_RESOLUTION = "2048x454";
const MAX_BRAND_FOOTER_IMAGE_RESOLUTION = "2048x325";
const MAX_WAYPOINTS_COUNT = 10000;
const DEFAULT_MAP_POSITION = { lat: 20, lng: 0 };
const DEFAULT_PASSCODE = "DEFAULT";
const DEFAULT_CELL_MAX_CHARACTERS = 30;
const LONG_CELL_MAX_CHARACTERS = 50;
const MINIMUM_USER_ID_LENGTH = 3;
const MINIMUM_PASSCODE_LENGTH = 3;
const MAXIMUM_USER_ID_LENGTH = 12;
const MAXIMUM_SHORT_DESCRIPTION_LENGTH = 250;
const MAXIMUM_HOME_DESCRIPTION_LENGTH = 125;
const MAXIMUM_ICON_NAME_LENGTH = 20;
const MAX_VIDEO_SIZE_MB = 50;
const MAX_IMG_SIZE_MB = 12;
const MESSAGING = {
  USER_TYPES: {
    USER_MESSAGE: "user-message",
    NOTIFICATION: "notification",
    EMAIL_RESPONSE: "email-response",
    PORTAL_RESPONSE: "portal-response",
  },
  RELOAD_MESSAGE_ERROR: "Unable to retrieve most recent messages, please re-open messaging window.",
  MESSAGES_RELOAD_REQUEST_INTERVAL: 30000,
  VIEWPORT_BREAK_SIZE: 1366,
};
const EXTENDED_SEARCH_INPUT_WIDTH = "320px";
const CREATION_AND_PUBLISHER_SECTION_TYPES = {
  cover: "cover",
  notesFront: "notes_front",
  notesEnd: "notes_end",
  map: "map",
  poiList: "poi_list",
  storyboard: "storyboard",
  directory: "directory",
  tableOfContents: "toc",
};
const REMOVED_STAYS_COLUMNS = ["client_reference", "start_date", "start_time"];

const ENV_TYPES = {
  dev: "development",
  prod: "production",
};

const CONSTANT_ROLES = {
  ADMIN: "admin",
  REGULAR_USER: "regular user",
  LIMITED_USER: "limited user",
  SELECTED_ACCESS: "selected access",
};

const ALLOWED_ROLES_NAMES_AND_ORDER = [
  CONSTANT_ROLES.ADMIN,
  CONSTANT_ROLES.REGULAR_USER,
  CONSTANT_ROLES.LIMITED_USER,
  CONSTANT_ROLES.SELECTED_ACCESS,
];

export {
  DEFAULT_DEBOUNCE_DELAY,
  DEFAULT_PAGE_SIZE,
  IMAGES_MIME_TYPES,
  GPX_MIME_TYPES,
  DOCUMENTS_FILE_TYPES,
  DEFAULT_WINDOW_HEIGHT,
  DEFAULT_MAP_POSITION,
  DEFAULT_CELL_MAX_CHARACTERS,
  LONG_CELL_MAX_CHARACTERS,
  MINIMUM_USER_ID_LENGTH,
  MAXIMUM_USER_ID_LENGTH,
  DEFAULT_PASSCODE,
  MESSAGING,
  CREATION_AND_PUBLISHER_SECTION_TYPES,
  EXTENDED_SEARCH_INPUT_WIDTH,
  VIDEO_FILE_TYPES,
  MAX_VIDEO_SIZE_MB,
  MAX_IMG_SIZE_MB,
  MAX_WAYPOINTS_COUNT,
  ENV_TYPES,
  REMOVED_STAYS_COLUMNS,
  MINIMUM_PASSCODE_LENGTH,
  MAXIMUM_SHORT_DESCRIPTION_LENGTH,
  MAXIMUM_HOME_DESCRIPTION_LENGTH,
  MAXIMUM_ICON_NAME_LENGTH,
  MAX_BRAND_HEADER_IMAGE_RESOLUTION,
  MAX_BRAND_FOOTER_IMAGE_RESOLUTION,
  ALLOWED_ROLES_NAMES_AND_ORDER,
  CONSTANT_ROLES,
  IMAGES_MIME_TYPES_NO_GIF,
};
