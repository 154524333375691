import React, { memo } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useSelector } from "react-redux";

import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import ListItemText from "@material-ui/core/ListItemText";

import { useAuthContext } from "hoc/AuthVerification";

import { Link as BaseLink } from "components/ui/Links/Link";
import { Spinner } from "components/ui/Spinner/Spinner";

import { PANEL_USER_SETTINGS } from "constants/routes";
import { ACCOUNT_SETTINGS } from "constants/content";

import { LOGOUT_START } from "store/auth/actionTypes";

const Link = styled(BaseLink)`
  && {
    display: flex;
    align-items: center;
    color: inherit;
  }
`;

const UserMenu = memo(({ open, anchor, onClose }) => {
  const { logout, currentOperatorCode } = useAuthContext();
  const { inProgress, actionType } = useSelector(state => state.auth);

  return (
    <Menu
      anchorEl={anchor.current}
      open={open}
      onClose={onClose}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      {currentOperatorCode && (
        <MenuItem>
          <Link to={PANEL_USER_SETTINGS}>
            <ListItemText primary={ACCOUNT_SETTINGS.title} />
          </Link>
        </MenuItem>
      )}
      <MenuItem onClick={() => logout(true)}>
        <ListItemText primary="Log out" />
        {inProgress && actionType === LOGOUT_START && <Spinner size={20} />}
      </MenuItem>
    </Menu>
  );
});

UserMenu.defaultProps = {
  open: false,
};

UserMenu.propTypes = {
  open: PropTypes.bool,
  anchor: PropTypes.oneOfType([PropTypes.element, PropTypes.object, PropTypes.node]).isRequired,
  onClose: PropTypes.func.isRequired,
};

export { UserMenu };
