import { getCurrentUserStart, logoutStart } from "store/auth/actions";
import { setNotification } from "store/app/actions";

import config from "config/app";

import notifications from "constants/notifications";
import { LOGOUT_URL } from "constants/api";
import { StorageService } from "services/StorageService";
import { TabSessionService } from "services/TabSessionService";
import store from "../../../store/store";

const CUSTOM_APP_API_ENDPONTS = ["/definition/", "/bundle", "/information", "/app_languages"];

const CustomAppInterceptor = async instanceConfig => {
  if (CUSTOM_APP_API_ENDPONTS.some(requestUrl => instanceConfig.url.startsWith(requestUrl))) {
    instanceConfig.baseURL = config.api.customApp[process.env.NODE_ENV];
  }

  return instanceConfig;
};

const AuthInterceptor = async instanceConfig => {
  const tabSessionSerivce = new TabSessionService(new StorageService());
  const token = await tabSessionSerivce.getToken();
  const operatorCode = await tabSessionSerivce.getOperatorCode();
  const copyOperatorCode = instanceConfig.headers["X-Operator-Code"];
  const newConfig = { ...instanceConfig };
  const { auth: { composerData } } = store.getState();

  newConfig.headers = {
    ...instanceConfig.headers,
    "X-Vamoos-User-Info": JSON.stringify({ "version_id": composerData.versionId, "platform": "portal", bundle_id: composerData.bundleId }),
    ...(token && { "X-User-Access-Token": token }),
  };

  if (!copyOperatorCode) newConfig.headers["X-Operator-Code"] = operatorCode;

  return newConfig;
};

const ResponseInterceptor = response => response;

const CreateResponseErrorInterceptor = dispatch => {
  const reduxDispatch = dispatch;
  const ResponseErrorInterceptor = error => {
    if (error.response) {
      const { status } = error.response;
      const { responseURL } = error.request;
      const isLogoutURL = responseURL.includes(LOGOUT_URL);
      const isDefinitionRequest = CUSTOM_APP_API_ENDPONTS.some(url => error?.response?.config?.url.startsWith(url));
      if (status === 401 && !isLogoutURL && !isDefinitionRequest) {
        document.cookie = "authToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";

        reduxDispatch(logoutStart(false));
      }

      if (status === 402) {
        reduxDispatch(getCurrentUserStart());
      }

      if (status === 500) {
        reduxDispatch(
          setNotification({
            type: "error",
            message: notifications.general[500],
          }),
        );
      }
    }

    return Promise.reject(error);
  };

  return ResponseErrorInterceptor;
};

export { AuthInterceptor, ResponseInterceptor, CreateResponseErrorInterceptor, CustomAppInterceptor };
