import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import TableCell from "@material-ui/core/TableCell";

import { useTableContext } from "components/ui/Tables/TableContext";
import { ActionTableCell } from "components/ui/Tables/TableRow";
import { TextLimiter } from "components/ui/TextLimiter";
import { StyledMuiTableRow } from "components/ui/Tables/_shared/styledComponents";
import { TableActionsContainer } from "components/ui/Tables/TableRowActions";

import { DEFAULT_CELL_MAX_CHARACTERS } from "constants/defaults";
import { SETTINGS_STAYS } from "constants/content";

const StyledTableRow = styled(({ item, ...props }) => <StyledMuiTableRow {...props} />)`
  && {
    ${({ archived, theme }) =>
      archived === "true"
        ? css`
            background: ${theme.colors.withOpacity(theme.colors.errorLight, 0.1)};

            :hover {
              background: ${theme.colors.withOpacity(theme.colors.errorLight, 0.2)};
            }

            a {
              color: ${theme.colors.errorLight};
            }

            .MuiChip-root {
              border-color: ${theme.colors.errorLight};
              color: ${theme.colors.errorLight};
            }
          `
        : ""}
  }
`;

const DescriptionContainer = styled.div`
  display: flex;
  align-items: center;
`;

const EditedLabel = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.grey50};
  margin: 0 4px 0 0;
`;

const StaysTableRow = ({ item }) => {
  const { actions: Actions, headers } = useTableContext();

  const renderCell = header => {
    const { key } = header;
    let content = null;

    switch (key) {
      case "shortDescription":
        content = (
          <DescriptionContainer>
            {item.shortDescription !== item.shortDescriptionOriginal && <EditedLabel>{SETTINGS_STAYS.table.custom}</EditedLabel>}
            <TextLimiter maxChar={DEFAULT_CELL_MAX_CHARACTERS} text={item[key]} />
          </DescriptionContainer>
        );
        break;
      case "longDescription":
        content = (
          <DescriptionContainer>
            {item.longDescription !== item.longDescriptionOriginal && <EditedLabel>{SETTINGS_STAYS.table.custom}</EditedLabel>}
            <TextLimiter maxChar={DEFAULT_CELL_MAX_CHARACTERS} text={item[key]} />
          </DescriptionContainer>
        );
        break;
      default:
        content = <TextLimiter maxChar={DEFAULT_CELL_MAX_CHARACTERS} text={item[key]} />;
    }

    return <TableCell key={`stay-${key}-${item.vamoosId}`}>{content}</TableCell>;
  };

  const cellsToRender = headers.filter(header => header.show).map(header => renderCell(header));

  return (
    <StyledMuiTableRow item={item}>
      {cellsToRender}
      <ActionTableCell align="right" archived="false">
        <TableActionsContainer>
          <Actions item={item} archived={false} />
        </TableActionsContainer>
      </ActionTableCell>
    </StyledMuiTableRow>
  );
};

StaysTableRow.propTypes = {
  item: PropTypes.shape().isRequired,
};

export { StaysTableRow, StyledTableRow };
