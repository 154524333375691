import React from "react";
import { DeleteAction, EditAction, TableActionsContainer } from "components/ui/Tables/TableRowActions";

const TableActions = ({ handleDelete, item, handleEdit }) => {
  const onDelete = () => handleDelete(item);
  const onEdit = () => handleEdit(item);

  return (
    <TableActionsContainer>
      <EditAction onClick={onEdit} />
      <DeleteAction onClick={onDelete} />
    </TableActionsContainer>
  );
};

export default TableActions;
