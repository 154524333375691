import { LS_OPERATOR_CODE, LS_TOKEN_KN, LS_USER_EMAIL, LS_AUTH_METHOD } from "constants/localstorage";
import { StorageService } from "./StorageService";

export class TabSessionService {
  constructor(storageService = new StorageService()) {
    this.storage = storageService;
  }

  async setGlobalOperatorCode(code) {
    return this.storage.setPropertyByName(LS_OPERATOR_CODE, code);
  }

  // eslint-disable-next-line class-methods-use-this
  async setLocalOperatorCode(code) {
    return new Promise((resolve, reject) => {
      if (code) {
        sessionStorage.setItem(LS_OPERATOR_CODE, code);
      }

      const operatorCode = sessionStorage.getItem(LS_OPERATOR_CODE);
      if (operatorCode) {
        resolve(operatorCode);
      } else {
        reject(null);
      }
    });
  }

  async setToken(token) {
    return this.storage.setPropertyByName(LS_TOKEN_KN, token);
  }

  async clearSessionInfo() {
    await this.storage.removePropertyByName(LS_TOKEN_KN);
    await this.storage.removePropertyByName(LS_OPERATOR_CODE);

    const isUserAuth = await this.getAuthMethod();
    if (isUserAuth) {
      await this.storage.removePropertyByName(LS_AUTH_METHOD);
      await this.storage.removePropertyByName(LS_USER_EMAIL);
    }
    sessionStorage.removeItem(LS_OPERATOR_CODE);
  }

  getCookie(cookieName) {
    const name = cookieName + "=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const cookieArray = decodedCookie.split(";");

    for (let i = 0; i < cookieArray.length; i++) {
      let cookie = cookieArray[i];
      while (cookie.charAt(0) === " ") {
        cookie = cookie.substring(1);
      }
      if (cookie.indexOf(name) === 0) {
        return cookie.substring(name.length, cookie.length);
      }
    }
    return "";
  }

  /**
   * @returns {Promise<string>}
   */
  async getToken() {
    if (localStorage.getItem("rememberMeVamoos")) return this.storage.getPropertyByName(LS_TOKEN_KN);
    else return this.getCookie(LS_TOKEN_KN);
  }

  async setActiveTabOperatorAsGlobal() {
    const globalOperator = await this.getGlobalOperatorCode();
    const currentOperator = await this.getOperatorCode();

    if (globalOperator !== currentOperator) {
      await this.setGlobalOperatorCode(currentOperator);
    }
  }

  async getOperatorCode() {
    const tabOperatorCodePromise = this.getTabOperatorCode();

    return Promise.all([this.storage.getPropertyByName(LS_OPERATOR_CODE), tabOperatorCodePromise]).then(
      ([operatorCode, operatorCodeAssignedToTab]) => {
        if (!operatorCodeAssignedToTab) {
          sessionStorage.setItem(LS_OPERATOR_CODE, operatorCode);
          return operatorCode;
        }
        return operatorCodeAssignedToTab;
      },
    );
  }

  /**
   * @returns {Promise<string>}
   */
  // eslint-disable-next-line class-methods-use-this
  async getTabOperatorCode() {
    return new Promise(resolve => {
      const code = sessionStorage.getItem(LS_OPERATOR_CODE);

      resolve(code);
    });
  }
  /**
   * @returns {Promise}
   */

  async getGlobalOperatorCode() {
    return this.storage.getPropertyByName(LS_OPERATOR_CODE);
  }

  async setUserEmail(email) {
    return this.storage.setPropertyByName(LS_USER_EMAIL, email);
  }

  async getUserEmail() {
    return this.storage.getPropertyByName(LS_USER_EMAIL);
  }

  async setAuthMethod(method) {
    return this.storage.setPropertyByName(LS_AUTH_METHOD, method);
  }

  async getAuthMethod() {
    return this.storage.getPropertyByName(LS_AUTH_METHOD);
  }

}
