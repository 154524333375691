import { takeEvery, put, takeLatest, select } from "redux-saga/effects";
import * as actionTypes from "store/adminOperatorDetails/actionTypes";

import { hideSavingCover, setNotification, showSavingCover } from "store/app/actions";
import {
  getOperatorDetailsSuccess,
  updateAdminOperatorsDetailsSuccess,
  updateAdminOperatorsDetailsFail,
  getOperatorDetailsFail,
  getCustomAppForAdminOperatorSuccess,
  getCustomAppForAdminOperatorFail,
  acceptCustomAppFail,
  acceptCustomAppSuccess,
  rejectCustomAppSuccess,
  rejectCustomAppFail,
  makeLiveCustomAppSuccess,
  makeLiveCustomAppStart,
  revertToDraftCustomAppSuccess,
  revertToDraftCustomAppFail,
  getCustomAppBundleIdVersionsSuccess,
  getCustomAppBundleIdVersionsFail,
} from "store/adminOperatorDetails/actions";

import {
  BUNDLE_LIST_CUSTOM_APP_URL,
  CUSTOM_APP_DEFINITION_REJECT_URL,
  CUSTOM_APP_DEFINITION_REVERT_URL,
  CUSTOM_APP_DEFINITION_LIVE_STATUS_URL,
  BUNDLE_VERSIONS_LIST_URL,
  CUSTOM_APP_DEFINITION_ACCEPT_URL,
} from "constants/api";
import { CUSTOM_APP_ADMIN } from "constants/content";
import notifications from "constants/notifications";

import { HttpClient } from "services/application/httpClient/httpClient";
import { setUrl, setUrlParams } from "utils/url";
import { handleErrorMessages } from "utils/store";
import { CustomAppMapper } from "mappers/CustomAppMapper";
import { OperatorMapper } from "mappers/OperatorMapper";
import { OperatorRepository } from "repositories/OperatorRepository";
import { OperatorService } from "services/OperatorService";

function* getOperatorDetails({ payload }) {
  const operatorService = new OperatorService(new OperatorRepository(), new OperatorMapper());
  try {
    const operator = yield operatorService.getAdminOperatorDetails(payload);
    if (operator) {
      yield put(getOperatorDetailsSuccess(operator));
    }
  } catch (e) {
    yield put(getOperatorDetailsFail({ errors: handleErrorMessages(e) }));
    yield put(setNotification({ type: "error", message: notifications.resource("operator").get.fail }));
  }
}

function* updateOperatorDetails({ payload }) {
  const operatorService = new OperatorService(new OperatorRepository(), new OperatorMapper());
  const { id, data } = payload;
  try {
    const response = yield operatorService.updateAdminOperator(id, data);
    yield put(updateAdminOperatorsDetailsSuccess(response));
    yield put(setNotification({ type: "success", message: notifications.resource("operator").update.success }));
  } catch (e) {
    yield put(updateAdminOperatorsDetailsFail({ errors: handleErrorMessages(e) }));
    yield put(setNotification({ type: "error", message: notifications.resource("operator").update.fail }));
  }
}

function* getOperatorCustomApp() {
  const customAppMapper = new CustomAppMapper();
  const operator = yield select(state => state.adminOperatorDetails.details);
  try {
    const url = setUrlParams(BUNDLE_LIST_CUSTOM_APP_URL, { operator_id: operator?.id, count: 5000 });
    const { data } = yield HttpClient.get(url);

    const items = data.entries.map(bundle =>
      customAppMapper.fromDtoToDomain({ ...bundle?.latest_definition, created_at: bundle.created_at, versionsList: [] }),
    );

    yield put(getCustomAppForAdminOperatorSuccess({ customApps: items, count: 0 }));
  } catch (e) {
    yield put(getCustomAppForAdminOperatorFail({ errors: handleErrorMessages(e) }));
  }
}

function* acceptCustomApp({ payload }) {
  const { id } = payload;
  yield put(showSavingCover());

  try {
    const url = setUrl(CUSTOM_APP_DEFINITION_ACCEPT_URL, { id });
    yield HttpClient.post(url);

    yield put(acceptCustomAppSuccess());
    yield put(
      setNotification({
        type: "success",
        message: CUSTOM_APP_ADMIN.notifications.acceptSuccess,
      }),
    );
    yield put(hideSavingCover());
  } catch (e) {
    const error = handleErrorMessages(e);
    put(acceptCustomAppFail(error));
    yield put(
      setNotification({
        type: "error",
        message: CUSTOM_APP_ADMIN.notifications.accessFail,
      }),
    );
  }
  yield put(hideSavingCover());
}

function* rejectCustomApp({ payload }) {
  const { id } = payload;
  yield put(showSavingCover());
  try {
    const url = setUrl(CUSTOM_APP_DEFINITION_REJECT_URL, { id });
    yield HttpClient.post(url);

    yield put(rejectCustomAppSuccess());
    yield put(
      setNotification({
        type: "success",
        message: CUSTOM_APP_ADMIN.notifications.rejectSuccess,
      }),
    );
    yield put(hideSavingCover());
  } catch (e) {
    const error = handleErrorMessages(e);
    yield put(rejectCustomAppFail(error));
    yield put(
      setNotification({
        type: "error",
        message: CUSTOM_APP_ADMIN.notifications.rejectFail,
      }),
    );
  }
  yield put(hideSavingCover());
}

function* revertToDraftCustomApp({ payload }) {
  const { id } = payload;
  yield put(showSavingCover());

  try {
    const url = setUrl(CUSTOM_APP_DEFINITION_REVERT_URL, { id });
    yield HttpClient.post(url);

    yield put(revertToDraftCustomAppSuccess());
    yield put(
      setNotification({
        type: "success",
        message: CUSTOM_APP_ADMIN.notifications.revertToDraftSuccess,
      }),
    );
    yield put(hideSavingCover());
  } catch (e) {
    const error = handleErrorMessages(e);

    yield put(
      setNotification({
        type: "error",
        message: CUSTOM_APP_ADMIN.notifications.revertToDraftFail,
      }),
    );
    yield put(hideSavingCover());
    yield put(revertToDraftCustomAppFail(error));
  }
}

function* liveCustomApp({ payload }) {
  const { id } = payload;
  yield put(showSavingCover());
  try {
    const url = setUrl(CUSTOM_APP_DEFINITION_LIVE_STATUS_URL, { id });
    yield HttpClient.post(url);

    yield put(makeLiveCustomAppSuccess());
    yield put(
      setNotification({
        type: "success",
        message: CUSTOM_APP_ADMIN.notifications.revertToLiveSuccess,
      }),
    );
    yield put(hideSavingCover());
  } catch (e) {
    const error = handleErrorMessages(e);
    yield put(makeLiveCustomAppStart(error));
    yield put(
      setNotification({
        type: "error",
        message: CUSTOM_APP_ADMIN.notifications.revertToLiveFail,
      }),
    );
    yield put(hideSavingCover());
  }
}

function* getCustomAppVersions({ payload }) {
  const customAppMapper = new CustomAppMapper();
  try {
    const { bundleId } = payload;
    const url = setUrlParams(setUrl(BUNDLE_VERSIONS_LIST_URL, { bundleId }), { count: 5000 });
    const { data } = yield HttpClient.get(url);
    const versionsList = data.entries.map(item => customAppMapper.fromDtoToDomain(item));

    yield put(getCustomAppBundleIdVersionsSuccess({ bundleId, versionsList }));
  } catch (e) {
    const error = handleErrorMessages(e);
    yield put(getCustomAppBundleIdVersionsFail({ error }));
    yield put(
      setNotification({
        type: "error",
        message: CUSTOM_APP_ADMIN.notifications.getCustomAppVersionFail,
      }),
    );
  }
}

export function* adminOperatorDetailsSaga() {
  yield takeEvery(actionTypes.GET_OPERATOR_DETAILS_START, getOperatorDetails);
  yield takeEvery(actionTypes.UPDATE_ADMIN_OPERATOR_DETAILS_START, updateOperatorDetails);
  yield takeLatest(actionTypes.GET_CUSTOM_APP_LIST_FOR_ADMIN_OPERATOR_START, getOperatorCustomApp);
  yield takeLatest(actionTypes.ACCEPT_CUSTOM_APP_SUCCESS, getOperatorCustomApp);
  yield takeLatest(actionTypes.REJECT_CUSTOM_APP_SUCCESS, getOperatorCustomApp);
  yield takeLatest(actionTypes.REVERT_TO_DRAFT_CUSTOM_APP_SUCCESS, getOperatorCustomApp);
  yield takeLatest(actionTypes.MAKE_LIVE_CUSTOM_APP_SUCCESS, getOperatorCustomApp);
  yield takeLatest(actionTypes.ACCEPT_CUSTOM_APP_START, acceptCustomApp);
  yield takeLatest(actionTypes.REJECT_CUSTOM_APP_START, rejectCustomApp);
  yield takeLatest(actionTypes.REVERT_TO_DRAFT_CUSTOM_APP_START, revertToDraftCustomApp);
  yield takeLatest(actionTypes.MAKE_LIVE_CUSTOM_APP_START, liveCustomApp);
  yield takeLatest(actionTypes.GET_CUSTOM_APP_BUNDLE_VERSIONS_START, getCustomAppVersions);
}
