import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { Miniature } from "./Miniature";

const MiniaturesWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  padding-top: ${({ theme }) => theme.setSpacing(4)}px;

  :focus {
    outline: none;
  }
`;

const FilesList = ({ files, selectable, webSource, handleFileDelete, handleMiniatureClick }) => {
  return (
    <MiniaturesWrapper>
      {files.map(file => {
        return (
          <Miniature
            data={file}
            key={file.id}
            active={file.active}
            webSource={webSource}
            selectable={selectable}
            handleMiniatureClick={handleMiniatureClick}
            handleMiniatureDelete={handleFileDelete}
          />
        );
      })}
    </MiniaturesWrapper>
  );
};

FilesList.propTypes = {
  files: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  handleFileDelete: PropTypes.func,
  handleMiniatureClick: PropTypes.func,
  webSource: PropTypes.bool,
  selectable: PropTypes.bool,
};

FilesList.defaultProps = {
  webSource: false,
  handleMiniatureClick: () => {},
  handleFileDelete: () => {},
  selectable: true,
};

export { FilesList, MiniaturesWrapper };
