import React, { useState, useEffect } from "react";
import EditIcon from "@material-ui/icons/Edit";
import { useForm } from "react-hook-form";
import Input from "components/ui/Inputs/TextInput";
import { SectionTitle } from "../../../sharedComponents";

const SectionTitleEditable = ({ title, onChange, disabled }) => {
  const { control, reset, watch } = useForm();
  const [editing, setEditing] = useState(false);
  const titleWatch = watch("title");

  const onEditSet = () => !disabled && setEditing(true);
  const onEditRemove = () => setEditing(false);

  useEffect(() => {
    reset({ title });
  }, [editing]);

  useEffect(() => {
    if (titleWatch) onChange(titleWatch);
  }, [titleWatch]);

  return (
    <div>
      {!editing && (
        <SectionTitle onClick={onEditSet} disabled={disabled}>
          {title} <EditIcon />
        </SectionTitle>
      )}

      {editing && <Input control={control} value={title} onBlur={onEditRemove} style={{ marginBottom: 47 }} name="title" autoFocus />}
    </div>
  );
};

export default SectionTitleEditable;
