import React, { useReducer, useEffect, useState, useContext } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";

import { ChipWithRedirect } from "components/ui/Chips/ChipWithRedirect";
import { Input, Select, Textarea } from "components/ui/Forms";
import { Content } from "components/ui/Content/Content";
import { AttachFilesButton } from "components/ui/Buttons/AttachFilesButton";
import { ConfirmationModal } from "components/ui/Modals/ConfirmationModal";
import { IconImage } from "components/ui/Icons";
import { LanguageContext } from "components/_shared/LanguageSelector/LanguageContext";
import { LanguageSelector } from "components/_shared/LanguageSelector/LanguageSelector";
import { Autocomplete } from "components/ui/Forms/Autocomplete";

import { FieldValueWrapper } from "feature/panel/Settings/_shared/styledComponents";

import { isObjectEmpty } from "utils/object";
import { formatDisplayableLibraryPopupValues } from "utils/library";
import config from "config/app";
import { ERRORS, GLOBAL_CONTENT, POIS_LABELS } from "constants/content";
import { GPX_MIME_TYPES } from "constants/defaults";

import { pushErrorNotification } from "store/app/actions";

import { useActiveIcon } from "hooks/useActiveIcon";
import { useListOfCountries } from "hooks/useListOfCountries";
import { useService } from "hooks/useService";

import { Poi } from "domain/Poi";

import { LocalisationService } from "services/LocalisationService";
import { PoiService } from "services/PoiService";
import { EventBus } from "services/application/EventBus";
import { Logger } from "services/application/Logger";
import { FileReaderService } from "services/FileReaderService";

import { PoiHasBeenCreated } from "events/PoiHasBeenCreated";
import { PoiHasBeenUpdated } from "events/PoiHasBeenUpdated";
import { TrackValidator } from "services/domain/TrackValidator";
import { PoiValidator } from "services/domain/PoiValidator";
import { ErrorText } from "components/ui/Typography/Typography";
import { StyledTextarea } from "feature/panel/Trips/_shared/Storyboard/Entries/EntryForm/EntryDetailsForm";

export function transformCoordinatesToObject(coordinates) {
  if (typeof coordinates !== "string") {
    return coordinates;
  }
  let lat = "";
  let lng = "";

  if (coordinates.includes(",") || coordinates.includes(", ") || coordinates.includes(" ,")) {
    const coords = coordinates.replace(/\s+/g, "");
    [lat, lng] = coords.split(",");
  } else if (coordinates.includes(" ")) {
    [lat, lng] = coordinates.split(" ");
  } else if (coordinates.length > 0 && coordinates.length < 3) {
    lat = coordinates;
  }

  return { lat: Number(lat), lng: Number(lng) };
}

const editStateReducer = (state, action) => {
  switch (action.type) {
    case "callHandler": {
      if (["name", "description", "file"].includes(action.name)) {
        if (action.language.isDefault) {
          return {
            ...state,
            [action.name]: action.value,
          };
        }
        return {
          ...state,
          localisation: {
            ...state.localisation,
            [action.language.code]: {
              ...state.localisation?.[action.language.code],
              [action.name]: action.value,
            },
          },
        };
      }

      return {
        ...state,
        [action.name]: action.value,
      };
    }
    case "setAllValues": {
      return {
        ...state,
        ...action.payload,
      };
    }
    case "clearAllFields": {
      return new Poi();
    }
    default:
      return state;
  }
};

const PoiModal = ({ isOpen, onClose, poi }) => {
  const mode = poi?.id ? "edit" : "add";

  const poiService = useService(PoiService);

  const { currentLanguage, additionalLanguages } = useContext(LanguageContext);
  const defaultLanguageCode = useSelector(({ operator }) => operator.currentOperator.defaultLanguageCode);
  const [localCurrentLanguage, setLocalCurrentLanguage] = useState(currentLanguage);

  const localisationService = new LocalisationService(localCurrentLanguage);

  const [isLoading, setIsLoading] = useState(false);

  const [poiForm, dispatch] = useReducer(editStateReducer, new Poi());

  const [errors, setErrors] = useState({});
  const [poiIconsList, setPoiIconsList] = useState([]);

  const [inProgress, setInProgress] = useState(false);

  const [gpxFile, setGpxFile] = useState(null);
  const [routeWaypointsLength, setRouteWaypointsLength] = useState(null);
  const [autocompletePois, setAutocompletePois] = useState([]);

  const { availableIcons } = useSelector(state => state.operator);

  const { id, country, poiRange, iconId, type, children } = poiForm;

  const name = localisationService.localise(poiForm, "name");
  const description = localisationService.localise(poiForm, "description");
  const file = localisationService.localise(poiForm, "file");

  const poiIconsAvailableFromBackend = availableIcons.filter(icon => icon.section === "poi");
  const { ranges } = config.modules.pois;

  const countries = useListOfCountries();

  const switchLanguage = lang => {
    setLocalCurrentLanguage({
      ...lang,
      isDefault: lang.code === defaultLanguageCode,
    });
  };

  const resolveCoordinateErrorMessage = () => {
    if (!errors.latitude || !errors.longitude) {
      return null;
    }

    return [errors.latitude, errors.longitude];
  };

  const setCoordinates = () => {
    return poiForm.coordinates;
  };

  const handleChange = ({ target }) => {
    const { name: fieldName, value } = target;
    dispatch({
      type: "callHandler",
      name: fieldName,
      value,
      language: localCurrentLanguage,
    });
  };

  const setFile = value => {
    handleChange({ target: { name: "file", value } });
  };
  const handleSave = async () => {
    let formErrors = {};

    try {
      if (poiForm.type === "poi") {
        const validator = new PoiValidator();

        formErrors = validator.validate({ ...poiForm });

        if (isObjectEmpty(formErrors)) {
          setInProgress(true);
          const { file: poiFile, ...poiWithoutFile } = poiForm;

          if (poiForm.id) {
            await poiService.updatePoi(poiFile === null ? poiWithoutFile : poiForm);
            EventBus.dispatch(new PoiHasBeenUpdated());
          } else {
            await poiService.addNewPoi(poiFile === null ? poiWithoutFile : poiForm);
            EventBus.dispatch(new PoiHasBeenCreated());
          }

          dispatch({ type: "clearAllfields" });
        }
      } else {
        const validator = new TrackValidator();

        formErrors = validator.validate(poiForm);

        if (isObjectEmpty(formErrors)) {
          setInProgress(true);

          const newTrack = { ...poiForm, iconId: type === "route" ? 101 : 1 };

          if (gpxFile) {
            const fileReaderService = new FileReaderService();

            const points = await fileReaderService.read(gpxFile).then(gpxContent => {
              const parser = new DOMParser();
              const doc = parser.parseFromString(gpxContent, "text/xml");

              return [...doc.querySelectorAll("trkpt, rtept")].map(point => ({
                latitude: point.getAttribute("lat"),
                longitude: point.getAttribute("lon"),
              }));
            });

            newTrack.meta = {
              waypoints: points,
            };
          }

          newTrack.children = [...children.map(({ value }) => ({ aliasForId: value }))];

          const { file: trackFile, ...trackWithoutFile } = newTrack;
          if (newTrack.id) {
            await poiService.updatePoi(trackFile === null ? trackWithoutFile : newTrack);
            EventBus.dispatch(new PoiHasBeenUpdated());
          } else {
            await poiService.addNewPoi(trackFile === null ? trackWithoutFile : newTrack);
            EventBus.dispatch(new PoiHasBeenCreated());
          }
        }
      }
    } catch (e) {
      Logger.debug(e);
      dispatch(pushErrorNotification(ERRORS.unknownError));
    } finally {
      setInProgress(false);
    }
    setErrors(formErrors);
  };

  const prepareCoordinates = (latitude, longitude) => {
    if (!latitude && !longitude) {
      return "";
    }
    if (latitude && !longitude) {
      return `${latitude}`;
    }
    if (!latitude && longitude) {
      return `, ${longitude}`;
    }
    return `${latitude}, ${longitude}`;
  };

  const setState = () => {
    if (isOpen && poi) {
      if (poi.id) {
        setIsLoading(true);
        poiService
          .getPoi(poi.id)
          .then(fetchedPoi => {
            dispatch({
              type: "setAllValues",
              payload: {
                ...new Poi(),
                ...fetchedPoi,
                poiRange: fetchedPoi.poiRange || fetchedPoi.range,
                children: fetchedPoi.children.map(({ aliasFor }) => ({
                  label: aliasFor.name,
                  key: aliasFor.id,
                  value: aliasFor.id,
                })),
                coordinates: prepareCoordinates(fetchedPoi.latitude, fetchedPoi.longitude),
              },
            });
            setErrors({});
            setIsLoading(false);
          })
          .catch(() => {
            setErrors({});
            setIsLoading(false);
          });
      } else {
        dispatch({
          type: "setAllValues",
          payload: {
            ...poi,
            coordinates: prepareCoordinates(poi.latitude, poi.longitude),
          },
        });
      }
    }
    return () => {
      dispatch({ type: "clearAllFields" });
      setGpxFile(null);
      setRouteWaypointsLength(null);
    };
  };

  const handleFileSelect = files => {
    const [newFile] = formatDisplayableLibraryPopupValues(files);

    setFile(newFile);
  };

  const validateGPXRouteFile = file => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = function(event) {
        const gpxContent = event.target.result;
        const parser = new DOMParser();
        const gpxXML = parser.parseFromString(gpxContent, "text/xml");

        // Extract waypoints from GPX XML
        // trkpt
        const waypoints = gpxXML.getElementsByTagName("rtept");
        if (!waypoints.length) {
          reject(new Error("No waypoints found. Check your file, either it has no waypoints or wrong format"));
        }
        if (waypoints.length >= 23) {
          reject(new Error("GPX file contains more than 23 waypoints"));
        } else {
          resolve({ waypointsLength: waypoints.length });
        }
      };

      reader.onerror = function(event) {
        reject(new Error("Error occurred while reading the file"));
      };

      reader.readAsText(file);
    });
  };

  const handleGpxFileSelect = files => {
    setErrors(prev => ({ ...prev, gpxError: null }));

    setGpxFile(files[0].file);
    if (type === "route")
      validateGPXRouteFile(files[0].file)
        .then(data => {
          setRouteWaypointsLength(data.waypointsLength);
          setGpxFile(files[0].file);
        })
        .catch(error => {
          setErrors(prev => ({ ...prev, gpxError: error.message }));
        });
  };

  const renderAttachFileButton = () => {
    return (
      <>
        <span>Image, PDF or URL to show for POI:</span>
        <AttachFilesButton name="pdf" onSelect={handleFileSelect} size="small" align="right" />
      </>
    );
  };

  const renderAttachGPXFileButton = () => {
    return (
      <>
        <span>GPX {type} log</span>
        <AttachFilesButton
          name="pdf"
          tabs={["files"]}
          upload={false}
          allowedFileTypes={GPX_MIME_TYPES}
          onSelect={handleGpxFileSelect}
          size="small"
          align="right"
        />
      </>
    );
  };

  const fileIcon = useActiveIcon({ ...file });
  const gpxFileIcon = useActiveIcon({ ...gpxFile });

  const renderChipPreview = () => <ChipWithRedirect onDelete={() => setFile(null)} item={file} icon={fileIcon} />;
  const renderChipGPXPreview = () => (
    <ChipWithRedirect
      onDelete={() => {
        setGpxFile(null);
        setRouteWaypointsLength(null);
      }}
      item={gpxFile}
      icon={gpxFileIcon}
    />
  );

  const handleCountryChange = (event, value) => {
    handleChange({ target: { name: "country", value } });
  };

  const handleCoordinationChange = ({ target }) => {
    const coords = transformCoordinatesToObject(target.value);

    dispatch({
      type: "callHandler",
      name: "latitude",
      value: Number(coords.lat),
    });
    dispatch({
      type: "callHandler",
      name: "longitude",
      value: Number(coords.lng),
    });
    dispatch({
      type: "callHandler",
      name: "coordinates",
      value: target.value,
    });
  };

  const onModalClose = target => {
    onClose(target);
  };

  const prepareAvailableIconsForPoi = () => {
    if (id) {
      const isIconDeleted = poiIconsAvailableFromBackend.findIndex(icon => icon.id === poiForm.icon.id) === -1;
      const poiIcons = isIconDeleted ? [...poiIconsAvailableFromBackend, poiForm.icon] : poiIconsAvailableFromBackend;
      setPoiIconsList(poiIcons);
    } else {
      setPoiIconsList(poiIconsAvailableFromBackend);
    }
  };

  const getHelperText = () => {
    if (!!errors?.default?.latitude && !errors?.default?.longitude) {
      return errors?.default?.latitude;
    }
    if (!errors?.default?.latitude && !!errors?.default?.longitude) {
      return errors?.default?.longitude;
    }

    return "";
  };

  useEffect(setState, [isOpen]);
  useEffect(prepareAvailableIconsForPoi, [id, poiIconsAvailableFromBackend.length]);

  useEffect(() => {
    if (isOpen) {
      setLocalCurrentLanguage(currentLanguage);
    }
  }, [isOpen]);

  // useEffect(() => {
  //   poiService
  //     .getAllPois()
  //     .then(({ items }) => {
  //       setAutocompletePois(
  //         items
  //           .filter(({ type: poiType }) => poiType === "poi")
  //           .map(({ id: poiId, name: poiName }) => ({
  //             label: poiName,
  //             value: poiId,
  //             key: poiId,
  //           })),
  //       );
  //     })
  //     .catch(e => {
  //       Logger.debug(e);
  //     });
  // }, []);

  const getTitle = () => {
    const titles = {
      add: {
        poi: POIS_LABELS.newPoi,
        route: POIS_LABELS.newRoute,
        track: POIS_LABELS.newTrack,
      },
      edit: {
        poi: POIS_LABELS.editPoi,
        route: POIS_LABELS.editRoute,
        track: POIS_LABELS.editTrack,
      },
    };
    if (mode === "add") {
      return titles.add[type];
    }
    if (mode === "edit") {
      return titles.edit[type];
    }

    return "";
  };

  useEffect(() => {
    setErrors({});
    dispatch({ type: "clearAllfields" });
    setGpxFile(null);
    setRouteWaypointsLength(null);
  }, [isOpen]);

  return isOpen ? (
    <ConfirmationModal
      fullWidth
      title={getTitle()}
      open={isOpen}
      maxWidth="sm"
      aria-labelledby="add-new-poi"
      data-testid="add-poi-modal"
      onConfirm={handleSave}
      onCancel={onModalClose}
      confirmLabel={mode === "add" ? GLOBAL_CONTENT.create : GLOBAL_CONTENT.update}
      confirmDisabled={errors.gpxError}
      showSpinner={inProgress}
      isLoading={isLoading}
    >
      {mode === "add" && (
        <Content>
          <Select name="type" label="Type" value={type} onChange={handleChange}>
            <MenuItem value="poi">Point of interest</MenuItem>
            <MenuItem value="track">Track</MenuItem>
            <MenuItem value="route">Route</MenuItem>
          </Select>
        </Content>
      )}
      {type === "poi" && (
        <Content>
          <Autocomplete
            autoComplete
            inputProps={{ autoComplete: "country" }}
            name="country"
            label="Country"
            options={countries.map(({ value }) => value)}
            value={country}
            onChange={handleCountryChange}
            error={errors.default?.country ? errors.default?.country : null}
            helperText={localCurrentLanguage.isDefault ? errors.default?.country : errors[localCurrentLanguage.code]?.country}
            isRequired
          />
        </Content>
      )}
      <Content>
        {poiRange && (
          <Select name="poiRange" label="Range (in km)" value={poiRange} onChange={handleChange}>
            {ranges.map(r => (
              <MenuItem key={r} value={r}>
                {r}
              </MenuItem>
            ))}
          </Select>
        )}
      </Content>
      {(type === "track" || type === "route") && (
        <>
          <Content>
            <Grid container justifyContent={gpxFile ? "flex-end" : "space-between"} alignItems="center">
              {!gpxFile ? renderAttachGPXFileButton() : renderChipGPXPreview()}
            </Grid>
            {routeWaypointsLength && (
              <ErrorText style={{ textAlign: "right", marginTop: 15, color: "#000" }}>{routeWaypointsLength} waypoints</ErrorText>
            )}
            {errors.gpxError && <ErrorText style={{ textAlign: "right", marginTop: 15 }}>{errors.gpxError}</ErrorText>}
          </Content>
          <Content>
            <Autocomplete
              multiple
              inputProps={{ autoComplete: "pois" }}
              name="pois"
              label={`POIs to always show while ${type} is displaying`}
              options={autocompletePois}
              value={children}
              getOptionLabel={option => option.label}
              getOptionSelected={(option, value) => option.value === value.value}
              filterSelectedOptions
              onChange={(event, value) => {
                dispatch({
                  type: "callHandler",
                  name: "children",
                  value,
                });
              }}
            />
          </Content>
        </>
      )}
      {type === "poi" && (
        <>
          <Content>
            <Input
              id="coordinates"
              name="coordinates"
              label="Coordinates: latitude, longitude"
              value={setCoordinates()}
              onChange={handleCoordinationChange}
              error={localCurrentLanguage.isDefault && (!!errors.default?.latitude || !!errors.default?.longitude)}
              helperText={localCurrentLanguage.isDefault && getHelperText()}
              errorMessages={resolveCoordinateErrorMessage()}
              isRequired
            />
          </Content>
          <Content margin={12}>
            <Select
              name="iconId"
              label="Icon"
              value={iconId}
              onChange={handleChange}
              error={localCurrentLanguage.isDefault ? !!errors.default?.iconId : !!errors[localCurrentLanguage.code]?.iconId}
              helperText={localCurrentLanguage.isDefault ? errors.default?.iconId : errors[localCurrentLanguage.code]?.iconId}
            >
              {poiIconsList.map(icon => (
                <MenuItem value={icon.id} key={icon.id}>
                  <FieldValueWrapper>
                    <IconImage src={icon.url} />
                    <span>{icon.localisation?.[localCurrentLanguage.code]?.name || icon.name}</span>
                  </FieldValueWrapper>
                </MenuItem>
              ))}
            </Select>
          </Content>
        </>
      )}
      {additionalLanguages.length > 0 && (
        <Content>
          <LanguageSelector currentLanguage={localCurrentLanguage} onChange={switchLanguage} errors={Object.keys(errors)} />
        </Content>
      )}
      <Content>
        <Input
          name="name"
          label="Label"
          value={name || ""}
          labelShrink={localCurrentLanguage.isDefault ? undefined : poiForm.name || undefined}
          placeholder={localCurrentLanguage.isDefault ? "" : poiForm.name}
          onChange={handleChange}
          error={localCurrentLanguage.isDefault ? !!errors.default?.name : !!errors[localCurrentLanguage.code]?.name}
          helperText={localCurrentLanguage.isDefault ? errors.default?.name : errors[localCurrentLanguage.code]?.name}
          isRequired
        />
      </Content>
      <Content>
        <StyledTextarea label="Description" name="description" value={description || ""} onChange={handleChange} />
        {/* <Textarea
          name="description"
          value={description || ""}
          labelShrink={localCurrentLanguage.isDefault ? undefined : poiForm.description || undefined}
          placeholder={localCurrentLanguage.isDefault ? "" : poiForm.description}
          label="Description"
          rows={2}
          onChange={handleChange}
        /> */}
      </Content>
      <Grid container justifyContent={file ? "flex-end" : "space-between"} alignItems="center">
        {!file ? renderAttachFileButton() : renderChipPreview()}
      </Grid>
    </ConfirmationModal>
  ) : null;
};

PoiModal.defaultProps = {
  poi: null,
};

PoiModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  poi: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    country: PropTypes.string,
    poiRange: PropTypes.number,
    coordinates: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    iconId: PropTypes.number,
    description: PropTypes.string,
    latitude: PropTypes.number,
    longitude: PropTypes.number,
    range: PropTypes.number,
  }),
};

export { PoiModal };
