import { TabSessionService } from "services/TabSessionService";

const isAuthorizedToMakeRequest = () => {
  const tabSessionSerivce = new TabSessionService();
  
  return new Promise((resolve, reject) => {
    Promise.all([tabSessionSerivce.getToken(), tabSessionSerivce.getOperatorCode()])
      .then(results => resolve(results.every(currentValue => currentValue !== null)))
      .catch(() => reject(false));
  });
};

export { isAuthorizedToMakeRequest };
