import React from "react";
import { useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { ListAltOutlined } from "@material-ui/icons";

import { ContextNavigationList } from "components/ui/Lists/ContextNavigationList";
import { checkSectionHasError } from "feature/panel/_shared/helpers";

import { CUSTOM_APP_NAVIGATION } from "constants/content";
import {
  PANEL_CUSTOM_APPS_EDIT_PATH_ELEMENTS,
  PANEL_CUSTOM_APPS_EDIT_PATH_TEXTS,
  PANEL_CUSTOM_APPS_EDIT_PATH_PREVIEW,
  PANEL_CUSTOM_APPS_EDIT_PATH_VERSIONS,
  PANEL_CUSTOM_APPS_EDIT_PATH_LOGO,
  PANEL_CUSTOM_APPS_EDIT_PATH_LOGIN,
  PANEL_CUSTOM_APPS_EDIT_PATH_MENU,
  PANEL_CUSTOM_APPS_EDIT_PATH_GENERAL,
  PANEL_CUSTOM_APPS_EDIT_PATH_INFORMATION,
} from "constants/routes";
import { setUrl, setUrlParams } from "utils/url";
import { isObjectEmpty } from "utils/object";

import {
  GeneralIcon,
  BackgroundIcon,
  ElementIcon,
  MenuIcon,
  TextsIcon,
  HistoryIcon,
  AllScreenIcon,
  InfoIcon,
} from "../_shared/navigationIcons";
import { GENERAL_CA_SECTION_ERROR_NAMES } from "../_shared/constants/customAppStatus";

const Navigation = () => {
  const { id } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const isHistoryVersion = queryParams.get("versionHistory") !== null;
  const errors = useSelector(({ customAppForm }) => customAppForm.errors);
  const textsErrors = useSelector(
    ({ customAppForm }) => customAppForm.errors?.menuDocuments?.filter(doc => doc && !isObjectEmpty(doc)).length,
  );

  const loginScreenError =
    errors?.images?.backgroundImage ||
    errors?.strings?.loginText1 ||
    errors?.strings?.loginText2 ||
    errors?.strings?.loginText3 ||
    errors?.strings?.loginText4 ||
    errors?.strings?.loginUserIdHint ||
    errors?.strings?.loginNextButton;

  const isActive = url => url === location?.pathname;
  const list = [
    {
      url: isHistoryVersion
        ? setUrlParams(setUrl(PANEL_CUSTOM_APPS_EDIT_PATH_GENERAL, { id }), { versionHistory: "true" })
        : setUrl(PANEL_CUSTOM_APPS_EDIT_PATH_GENERAL, { id }),
      label: CUSTOM_APP_NAVIGATION.general,
      icon: <GeneralIcon isActive={isActive(setUrl(PANEL_CUSTOM_APPS_EDIT_PATH_GENERAL, { id }))} />,
      isActive: isActive(setUrl(PANEL_CUSTOM_APPS_EDIT_PATH_GENERAL, { id })),
      hasError: checkSectionHasError(GENERAL_CA_SECTION_ERROR_NAMES, errors),
    },
    {
      url: isHistoryVersion
        ? setUrlParams(setUrl(PANEL_CUSTOM_APPS_EDIT_PATH_LOGO, { id }), { versionHistory: "true" })
        : setUrl(PANEL_CUSTOM_APPS_EDIT_PATH_LOGO, { id }),
      label: CUSTOM_APP_NAVIGATION.logoAndIcons,
      icon: <ListAltOutlined />,
      isActive: isActive(setUrl(PANEL_CUSTOM_APPS_EDIT_PATH_LOGO, { id })),
      hasError: errors?.images?.logo || errors?.images?.icon || errors?.images?.iconTransparent || errors?.colors?.iconBackgroundColor,
    },
    {
      url: isHistoryVersion
        ? setUrlParams(setUrl(PANEL_CUSTOM_APPS_EDIT_PATH_ELEMENTS, { id }), { versionHistory: "true" })
        : setUrl(PANEL_CUSTOM_APPS_EDIT_PATH_ELEMENTS, { id }),
      label: CUSTOM_APP_NAVIGATION.elements,
      icon: <ElementIcon isActive={isActive(setUrl(PANEL_CUSTOM_APPS_EDIT_PATH_ELEMENTS, { id }))} />,
      isActive: isActive(setUrl(PANEL_CUSTOM_APPS_EDIT_PATH_ELEMENTS, { id })),
    },
    {
      url: isHistoryVersion
        ? setUrlParams(setUrl(PANEL_CUSTOM_APPS_EDIT_PATH_LOGIN, { id }), { versionHistory: "true" })
        : setUrl(PANEL_CUSTOM_APPS_EDIT_PATH_LOGIN, { id }),
      label: CUSTOM_APP_NAVIGATION.loginScreen,
      icon: <BackgroundIcon isActive={isActive(setUrl(PANEL_CUSTOM_APPS_EDIT_PATH_LOGIN, { id }))} />,
      isActive: isActive(setUrl(PANEL_CUSTOM_APPS_EDIT_PATH_LOGIN, { id })),
      hasError: loginScreenError,
    },
    {
      url: isHistoryVersion
        ? setUrlParams(setUrl(PANEL_CUSTOM_APPS_EDIT_PATH_MENU, { id }), { versionHistory: "true" })
        : setUrl(PANEL_CUSTOM_APPS_EDIT_PATH_MENU, { id }),
      label: CUSTOM_APP_NAVIGATION.menu,
      icon: <MenuIcon isActive={isActive(setUrl(PANEL_CUSTOM_APPS_EDIT_PATH_MENU, { id }))} />,
      isActive: isActive(setUrl(PANEL_CUSTOM_APPS_EDIT_PATH_MENU, { id })),
    },
    {
      url: isHistoryVersion
        ? setUrlParams(setUrl(PANEL_CUSTOM_APPS_EDIT_PATH_TEXTS, { id }), { versionHistory: "true" })
        : setUrl(PANEL_CUSTOM_APPS_EDIT_PATH_TEXTS, { id }),
      label: CUSTOM_APP_NAVIGATION.texts,
      icon: <TextsIcon isActive={isActive(setUrl(PANEL_CUSTOM_APPS_EDIT_PATH_TEXTS, { id }))} />,
      isActive: isActive(setUrl(PANEL_CUSTOM_APPS_EDIT_PATH_TEXTS, { id })),
      hasError: textsErrors,
    },
    {
      url: isHistoryVersion
        ? setUrlParams(setUrl(PANEL_CUSTOM_APPS_EDIT_PATH_PREVIEW, { id }), { versionHistory: "true" })
        : setUrl(PANEL_CUSTOM_APPS_EDIT_PATH_PREVIEW, { id }),
      label: CUSTOM_APP_NAVIGATION.preview,
      icon: <AllScreenIcon isActive={isActive(setUrl(PANEL_CUSTOM_APPS_EDIT_PATH_PREVIEW, { id }))} />,
      isActive: isActive(setUrl(PANEL_CUSTOM_APPS_EDIT_PATH_PREVIEW, { id })),
    },
    {
      url: isHistoryVersion
        ? setUrlParams(setUrl(PANEL_CUSTOM_APPS_EDIT_PATH_INFORMATION, { id }), { versionHistory: "true" })
        : setUrl(PANEL_CUSTOM_APPS_EDIT_PATH_INFORMATION, { id }),
      label: CUSTOM_APP_NAVIGATION.information,
      icon: <InfoIcon isActive={isActive(setUrl(PANEL_CUSTOM_APPS_EDIT_PATH_INFORMATION, { id }))} />,
      isActive: isActive(setUrl(PANEL_CUSTOM_APPS_EDIT_PATH_INFORMATION, { id })),
      hasError: errors.information,
    },
  ];

  const versionHistoryNavItem = {
    url: setUrl(PANEL_CUSTOM_APPS_EDIT_PATH_VERSIONS, { id }),
    label: CUSTOM_APP_NAVIGATION.versionHistory,
    icon: <HistoryIcon isActive={isActive(setUrl(PANEL_CUSTOM_APPS_EDIT_PATH_VERSIONS, { id }))} />,
    isActive: isActive(setUrl(PANEL_CUSTOM_APPS_EDIT_PATH_VERSIONS, { id })),
  };

  const navigation = isHistoryVersion ? list : [...list, versionHistoryNavItem];

  return <ContextNavigationList list={navigation} />;
};

export { Navigation };
