import { createContext, useContext } from "react";

const TableContext = createContext({
  list: [],
  headers: [],
  actions: () => {},
});

const useTableContext = () => useContext(TableContext);

export { TableContext, useTableContext };
