import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import VpnKeyOutlinedIcon from "@material-ui/icons/VpnKeyOutlined";

import { SaveAdminButton } from "feature/admin/_shared/SaveAdminButton";

import { AdminTemplate } from "components/templates/Admin/AdminTemplate";
import { ConfirmationModal } from "components/ui/Modals/ConfirmationModal";
import { ContextBarTitle } from "components/templates/Panel/ContextBarTitle";
import { ActionButton, BackHistoryButton, CancelHistoryButton } from "components/ui/Buttons";
import { LoadingScreen } from "components/ui/LoadingScreen/LoadingScreen";
import { usePrompt } from "components/ui/CustomPrompt/CustomPrompt";

import {
  getUserDetailsStart,
  resetPasswordAdminUserDetails,
  setUserDetailsFormTouched,
  updateAdminUserDetailsStart,
} from "store/adminUserDetails/actions";
import {
  GET_USER_DETAILS_START,
  UPDATE_ADMIN_USER_DETAILS_START,
  UPDATE_ADMIN_USER_DETAILS_SUCCESS,
} from "store/adminUserDetails/actionTypes";
import { changeOperatorInitToggle, tryRouteChangeStart } from "store/app/actions";

import { useExtendedNavigate } from "hooks/useExtendedNavigate";
import { 
  ADMIN_USERS_DETAILS,
  BUTTON_LABEL,
  GLOBAL_CONTENT,
  OPERATOR_TYPE, 
  PAGE_TITLE_LABEL,
  SITE_LEAVE_WARNING_MESSAGES
} from "constants/content";
import { ADMIN_USERS_PATH } from "constants/routes";
import { emailValidate } from "utils/validation";

import { UserDetailsRequestDto } from "dto/UserDetailsRequestDto";

import { usePermissionsService } from "hooks/usePermissionsService";
import { PERMISSIONS } from "constants/permissions";

import { Navigation } from "./Navigation";
import { General } from "./General/General";

const UserDetails = () => {
  const permissionsService = usePermissionsService();

  const dispatch = useDispatch();
  const navigate = useExtendedNavigate();
  const location = useLocation();
  const [resetPasswordModal, setResetPasswordModal] = useState(false);

  const user = useSelector(state => state.adminUserDetails.user);
  const actionType = useSelector(state => state.adminUserDetails.actionType);
  const inProgress = useSelector(state => state.adminUserDetails.inProgress);
  const isFormTouched = useSelector(state => state.adminUserDetails.isFormTouched === true);
  const operators = useSelector(state => state.auth.operators);
  const currentOperatorCode = useSelector(state => state.auth.currentOperatorCode);

  usePrompt(SITE_LEAVE_WARNING_MESSAGES.createTitle(SITE_LEAVE_WARNING_MESSAGES.contexts.vamoosEditor), isFormTouched);

  const title = (
    <span>
      {PAGE_TITLE_LABEL.editUser}
      <strong>{user?.username}</strong>
    </span>
  );

  const { id } = useParams();

  const showSpinner = inProgress && actionType === UPDATE_ADMIN_USER_DETAILS_START;
  const isDataLoading = inProgress && actionType === GET_USER_DETAILS_START;

  const getUserData = userId => {
    const isAdmin = operators?.find(operator => operator.code === currentOperatorCode)?.type === OPERATOR_TYPE.admin;
    if (userId && isAdmin) {
      dispatch(getUserDetailsStart(userId));
    }
  };

  const clearFormTouched = () => {
    if (actionType === UPDATE_ADMIN_USER_DETAILS_SUCCESS) {
      dispatch(setUserDetailsFormTouched(false));
    }
  };

  const handleOnSave = userDomain => {
    const userRequestDto = userDomain && UserDetailsRequestDto(userDomain);
    const payload = { id: userDomain.id, userRequestDto };
    if (userRequestDto) dispatch(updateAdminUserDetailsStart(payload));
  };

  const handleResetPassword = () => setResetPasswordModal(true);
  const handleResetPasswordConfirm = email => {
    const isEmailValid = emailValidate(email);
    if (isEmailValid) {
      dispatch(resetPasswordAdminUserDetails({ email }));
      setResetPasswordModal(false);
    }
  };
  const handleResetPasswordCancel = () => setResetPasswordModal(false);
  const init = () => {
    return () => {
      dispatch(setUserDetailsFormTouched(false));
      dispatch(changeOperatorInitToggle(false));
    };
  };

  const handleBackAction = () => {
    if (location.state?.previousUrl) {
      dispatch(tryRouteChangeStart(location.state.previousUrl));
      navigate(location.state.previousUrl);
    } else {
      dispatch(tryRouteChangeStart(ADMIN_USERS_PATH));
      navigate(ADMIN_USERS_PATH);
    }
  };

  const isEditPermitted = permissionsService.can(
    PERMISSIONS.actions.update,
    PERMISSIONS.sections.admin,
    PERMISSIONS.resources.users,
    id || null,
  );

  const contextBar = {
    left: () =>
      isFormTouched ? (
        <CancelHistoryButton role="link" clickHandler={handleBackAction} />
      ) : (
        <BackHistoryButton role="link" clickHandler={handleBackAction} />
      ),
    middle: () => <ContextBarTitle title={title} />,
    right: () =>
      isEditPermitted ? (
        <>
          <ActionButton disabled={isFormTouched} mr={6} onClick={handleResetPassword}>
            <VpnKeyOutlinedIcon />
            <span>{BUTTON_LABEL.resetPassword}</span>
          </ActionButton>
          <SaveAdminButton onClick={() => handleOnSave(user)} showSpinner={showSpinner} disabled={!isFormTouched} />
        </>
      ) : null,
  };

  useEffect(init, []);
  useEffect(() => getUserData(id), []);
  useEffect(clearFormTouched, [actionType]);

  const content = isDataLoading ? <LoadingScreen /> : <General />;

  return (
    <AdminTemplate contextBar={contextBar} navigation={<Navigation />}>
      {content}
      {resetPasswordModal && (
        <ConfirmationModal
          open={resetPasswordModal}
          confirmLabel={GLOBAL_CONTENT.proceed}
          title={ADMIN_USERS_DETAILS.confirmReset}
          description={(
            <>
              {ADMIN_USERS_DETAILS.doYouConfirmReset}
              <strong>{user.username}</strong>
              ?
            </>
          )}
          onConfirm={() => handleResetPasswordConfirm(user?.email)}
          onCancel={handleResetPasswordCancel}
        />
      )}
    </AdminTemplate>
  );
};

export { UserDetails };
