import { createDateTimeParagraph } from "utils/createDateTimeParagraph";
import { generateSenderTypeString } from "utils/generateSenderTypeString";
import { assignSenderType } from "utils/assignSenderType";

const threadMessagesPayloadMapper = ({ entry_id, message, received_at, sent_at, type, data, name }) => ({
  id: entry_id,
  content: message,
  dateSent: sent_at,
  dateReceived: received_at,
  type: assignSenderType(type),
  label: `${data && data.sender_name ? data.sender_name : generateSenderTypeString(type, name)}  •  ${createDateTimeParagraph(
    received_at,
  )}`,
});

const messagesMapper = conversations => {
  return conversations?.map(
    ({ conversation, passcode, friendly_name, last_read, device_type, date_start, date_end, email, messages, name }) => ({
      id: conversation,
      email,
      passcode,
      name,
      device: device_type,
      friendlyName: friendly_name,
      startDate: date_start,
      lastReadAt: last_read,
      endDate: date_end,
      messages: messages.map(message => ({ ...message, name })).map(threadMessagesPayloadMapper),
    }),
  );
};

export { messagesMapper, threadMessagesPayloadMapper };
