import React from "react";
import { InformationField } from "feature/panel/Settings/_shared/InformationField";
import PropTypes from "prop-types";
import styled from "styled-components";
import ImageOutlinedIcon from "@material-ui/icons/ImageOutlined";

import { AttachFilesButton } from "components/ui/Buttons/AttachFilesButton";
import { BodyText2 } from "components/ui/Typography/Typography";
import { CREATION_FILE_REQUIREMENTS } from "constants/content";
import { Content } from "components/ui/Content";
import { ChipWithRedirect } from "components/ui/Chips/ChipWithRedirect";
import { IMAGES_MIME_TYPES } from "constants/defaults";
import { getFilePreviewUrl } from "utils/library";
import { DEFAULT_LIBRARY_TABS } from "components/ui/LibraryPopup/LibraryPopup";
import { useRefreshedFileUrl } from "hooks/useRefreshedFileUrl";
import { useActiveIcon } from "hooks/useActiveIcon";

const { extensions, size } = CREATION_FILE_REQUIREMENTS;

const BgArea = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 142px;
  margin-bottom: ${({ theme }) => theme.setSpacing(6)}px;
  border-radius: ${({ theme }) => theme.borderRadius}px;
  border: 1px solid ${({ theme }) => theme.colors.grey10};
  background-color: ${({ theme }) => theme.colors.withOpacity(theme.colors.grey10, 0.2)};

  ${({ image }) =>
    image &&
    `
    background-image: url('${image}');
    background-position: center;
    background-size: cover;
  `};
`;

const BgImage = styled.img`
  height: ${({ height }) => height || "142"}px;
  max-width: 100%;
  margin-bottom: ${({ theme }) => theme.setSpacing(6)}px;
  border-radius: ${({ theme }) => theme.borderRadius}px;
  border: 1px solid ${({ theme }) => theme.colors.grey10};
`;

const StyledImageOutlinedIcon = styled(ImageOutlinedIcon)`
  && {
    font-size: ${({ theme }) => theme.setSpacing(6)}px;
    color: ${({ theme }) => theme.colors.grey50};
  }
`;

const FileRequirementsInfo = styled.div`
  margin: ${({ theme }) => theme.setSpacing(5)}px 0;
`;

const UploadContainer = styled.div`
  width: 100%;
  max-height: 50px;
`;

const AssetField = ({ title, file, onEditClick, height, disabled, showDescription }) => {
  const url = useRefreshedFileUrl(getFilePreviewUrl(file));
  const activeIcon = useActiveIcon(file);
  const renderFileDeleter = () => (
    <ChipWithRedirect onDelete={() => onEditClick(null)} item={file} icon={activeIcon} editable={!disabled} />
  );

  const renderFileUploader = () => (
    <AttachFilesButton
      name={`${title}-image`}
      onSelect={onEditClick}
      size="small"
      align="right"
      allowedFileTypes={IMAGES_MIME_TYPES}
      tabs={Object.values(DEFAULT_LIBRARY_TABS).splice(0, 2)}
      disabled={disabled}
    />
  );

  return (
    <InformationField title={title}>
      <Content margin={3}>
        {url && file ? (
          <BgImage height={height} src={url} />
        ) : (
          <BgArea>
            <StyledImageOutlinedIcon />
          </BgArea>
        )}
        <UploadContainer>{file ? renderFileDeleter() : renderFileUploader()}</UploadContainer>
        {showDescription && (
          <>
            <FileRequirementsInfo>
              <BodyText2>{extensions}</BodyText2>
              <BodyText2>{size}</BodyText2>
            </FileRequirementsInfo>
          </>
        )}
 
      </Content>
    </InformationField>
  );
};

AssetField.defaultProps = {
  file: null,
  height: null,
  showDescription: true,
  disabled: false,
};

AssetField.propTypes = {
  title: PropTypes.string.isRequired,
  file: PropTypes.shape({
    file_name: PropTypes.string,
  }),
  onEditClick: PropTypes.func.isRequired,
  showDescription: PropTypes.bool,
  height: PropTypes.number,
  disabled: PropTypes.bool,
};

export { AssetField, BgArea, StyledImageOutlinedIcon, FileRequirementsInfo };
