import React, { useState } from "react";
import usePlacesAutocomplete, { getGeocode, getLatLng } from "use-places-autocomplete";

import PropTypes from "prop-types";
import styled from "styled-components";
import SearchIcon from "@material-ui/icons/Search";
import InputAdornment from "@material-ui/core/InputAdornment";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";

import { Switch } from "components/ui/Forms";
import { Autocomplete } from "components/ui/Forms/Autocomplete";
import { ActionsPanel, ActionsWrapper } from "feature/panel/_shared/MapWidget_old/styledComponents";
import { LOCATION_LABELS } from "constants/content";
import { MapSearchItem } from "../MapSearchItem/MapSearchItem";

const StyledSearchedWrapper = styled.div`
  width: auto;
  flex-grow: 1;
  max-width: ${({ fullWidth }) => (fullWidth ? "100%" : "40%")};
  margin: 0 ${({ theme }) => theme.setSpacing(6)}px;

  .MuiSvgIcon-root {
    color: ${({ theme }) => theme.colors.grey40};
    transition: ${({ theme }) => theme.transition};
  }

  &.Mui-focused {
    .MuiSvgIcon-root {
      color: ${({ theme }) => theme.colors.brandHover};
    }
  }
`;

const CloseSearchButton = styled(InputAdornment)`
  && {
    cursor: pointer;
  }
`;

const MapWidgetSearchBar = ({
  onSearch,
  searchLabel,
  onOptionClick,
  inputRef,
  switchControls,
  onSwitch,
  searchByLocation,
  enabledData,
  onClear,
  styleContainer,
}) => {
  const {
    suggestions: { data: searchResults },
    setValue,
  } = usePlacesAutocomplete({
    debounce: 300,
  });
  const [searchQuery, setSearchQuery] = useState("");

  const handleSearch = (event, value) => {
    setSearchQuery(value);
    if (searchByLocation) setValue(value);
    else onSearch(value);
  };

  const handleOptionClick = (event, value) => {
    onOptionClick(value);
  };

  const clearSearchInput = () => {
    handleSearch({ target: { value: "" } });
    setSearchQuery("");
    onClear();
  };

  const adornment = (
    <CloseSearchButton position="end" onClick={clearSearchInput}>
      {searchQuery ? <CloseOutlinedIcon /> : <SearchIcon />}
    </CloseSearchButton>
  );

  const checkControl = name => switchControls.includes(name);

  const fullWidth = switchControls.length === 0;

  const handleChange = ({ target }) => {
    const { name, checked } = target;
    onSwitch(name, checked);
  };

  const isChecked = name => enabledData?.includes(name);

  return (
    <ActionsPanel style={styleContainer}>
      <StyledSearchedWrapper fullWidth={fullWidth}>
        <Autocomplete
          freeSolo
          name="search-location"
          label={searchLabel}
          inputProps={{
            endAdornment: adornment,
          }}
          ref={inputRef}
          inputValue={searchQuery}
          onChange={handleOptionClick}
          onInputChange={handleSearch}
          options={searchResults.map(place => ({
            ...place,
            key: place.place_id,
            value: place,
            label: `${place.structured_formatting.main_text}, ${place.structured_formatting.secondary_text}`,
          }))}
          getOptionLabel={option => option.label}
          renderOption={option => (
            <MapSearchItem mainText={option.structured_formatting.main_text} secondaryText={option.structured_formatting.secondary_text} />
          )}
        />
      </StyledSearchedWrapper>
      <ActionsWrapper fullWidth={fullWidth}>
        {checkControl("stays") && (
          <Switch
            name="stays"
            checked={isChecked("stays")}
            label={LOCATION_LABELS.showStays}
            labelPlacement="start"
            onChange={handleChange}
          />
        )}
        {checkControl("pois") && (
          <Switch
            name="pois"
            checked={isChecked("pois")}
            label={LOCATION_LABELS.showPoiOnMap}
            labelPlacement="start"
            onChange={handleChange}
          />
        )}
      </ActionsWrapper>
    </ActionsPanel>
  );
};

MapWidgetSearchBar.defaultProps = {
  switchControls: [],
  onClear: () => {},
  onOptionClick: () => {},
  searchByLocation: false,
  inputRef: null,
  onSearch: null,
};

MapWidgetSearchBar.propTypes = {
  onSearch: PropTypes.func,
  onSwitch: PropTypes.func.isRequired,
  inputRef: PropTypes.shape(),
  switchControls: PropTypes.arrayOf(PropTypes.string),
  searchLabel: PropTypes.string.isRequired,
  onClear: PropTypes.func,
  onOptionClick: PropTypes.func,
  searchByLocation: PropTypes.bool,
};

export { MapWidgetSearchBar, StyledSearchedWrapper };
