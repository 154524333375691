const createThreadTitle = ({ name, device }) => {
  if (!name && typeof device === "string" && device.length) {
    return device;
  }
  if (!device && typeof name === "string" && name.length) {
    return name;
  }
  return `${name} ${device}`;
};

export { createThreadTitle };
