import { buttons } from "config/theme/buttons";
import { setColorVariant } from "utils/styles/colors";
import { callFn } from "utils";

export const setButtonFontSize = variant => `${buttons[variant] ? buttons[variant].fontSize : buttons.medium.fontSize}px`;

export const setButtonPadding = variant =>
  `${buttons[variant] ? buttons[variant].padding.vertical : buttons.medium.padding.vertical}px ${
    buttons[variant] ? buttons[variant].padding.horizontal : buttons.medium.padding.horizontal
  }px`;

export const setButtonHeight = variant => `${buttons[variant] ? buttons[variant].height : buttons.medium.height}px`;
export const setButtonLineHeight = variant => buttons.ghostButton[variant] || buttons.ghostButton.medium.lienHeight;

export const setButtonAttr = callFn({
  "font-size": setButtonFontSize,
  "line-height": setButtonLineHeight,
  padding: setButtonPadding,
  height: setButtonHeight,
  color: setColorVariant,
});
