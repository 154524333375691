import React from "react";
import PropTypes from "prop-types";

import { ConfirmationModal } from "components/ui/Modals/ConfirmationModal";
import { useSelector } from "react-redux";
import { CUSTOM_APPS, GLOBAL_CONTENT } from "constants/content";

const CustomAppsReviseConfirmModal = ({ open, onCancel, onConfirm }) => {
  const name = useSelector(state => state.customAppForm.customAppForm.name);
  const appVersion = useSelector(state => state.customAppForm.customAppForm.sequenceNumber);

  return (
    <ConfirmationModal
      title={GLOBAL_CONTENT.revise}
      open={open}
      onCancel={onCancel}
      onConfirm={onConfirm}
      confirmLabel={GLOBAL_CONTENT.confirm}
      fullWidth
      maxWidth="sm"
      description={CUSTOM_APPS.modals.createReviseModalDescription(name, appVersion)}
    />
  );
};

CustomAppsReviseConfirmModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export { CustomAppsReviseConfirmModal };
