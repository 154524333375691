import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const AccordionWrapper = styled.div`
  width: 100%;
  min-height: 72px;
`;

const HeaderWrapper = styled(({ isOpen, ...rest }) => <div {...rest} />)`
  border: 1px solid ${({ theme }) => theme.colors.grey10};
  display: flex;
  justify-content: space-around;
  ${({ isOpen, theme }) =>
    isOpen ? `border-radius: ${theme.borderRadius}px ${theme.borderRadius}px 0 0;` : `border-radius: ${theme.borderRadius}px;`}
`;

const HeaderContent = styled.div`
  flex-grow: 1;
`;

const Arrow = styled.div`
  border-left: 1px solid ${({ theme }) => theme.colors.grey10};
  width: 72px;
  height: 72px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const StyledExpandMoreIcon = styled(({ shouldRotate, ...rest }) => <ExpandMoreIcon {...rest} />)`
  && {
    color: ${({ theme }) => theme.colors.grey50};
    transition: all 0.25s;
    ${({ shouldRotate }) => (shouldRotate ? "transform: rotate(180deg)" : "transform: rotate(0deg)")}
  }
`;

const ChildrenWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.withOpacity(theme.colors.grey10, 0.3)};
  border-radius: ${({ theme }) => `0 0 ${theme.borderRadius}px ${theme.borderRadius}px;`};
`;

const Accordion = ({ children, header, expanded }) => {
  const [open, setOpen] = useState(expanded);

  return (
    <AccordionWrapper>
      <HeaderWrapper isOpen={open}>
        <HeaderContent>{header}</HeaderContent>
        <Arrow onClick={() => setOpen(!open)}>
          <StyledExpandMoreIcon shouldRotate={open} />
        </Arrow>
      </HeaderWrapper>
      {open && <ChildrenWrapper>{children}</ChildrenWrapper>}
    </AccordionWrapper>
  );
};

Accordion.defaultProps = {
  expanded: false,
};

Accordion.propTypes = {
  header: PropTypes.oneOfType([PropTypes.node, PropTypes.element, PropTypes.string]).isRequired,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.element, PropTypes.array]).isRequired,
  expanded: PropTypes.bool,
};

export { Accordion, AccordionWrapper, HeaderWrapper, HeaderContent, Arrow, StyledExpandMoreIcon, ChildrenWrapper };
