// @ts-check
export class StayRequestDto {
    type = "stay";
    
    source = "portal";

    /** @type {number} */
    vamoos_id;

    /** @type {boolean} */
    is_active;

    /** @type {boolean} */
    new_only;

    /** @type {string} */
    field1;

    /** @type {object} */
    logo;

    /** @type {object} */
    background;

    /** @type {any[]} */
    features;

    /** @type {any[]} */
    notifications;

    /** @type {any[]} */
    directories;

    /** @type {object} */
    documents;

    /** @type {any[]} */
    details;

    /** @type {any[]} */
    passcode_groups;

    /** @type {any[]} */
    pois;

    /** @type {object} */
    meta = {};

    /** @type {string} */
    departure_date;

    /** @type {string} */
    return_date;

    /** @type {any[]} */
    locations;

    /** @type {number} */
    inspiration_vamoos_id;

    /** @type {number} */
    branding_profile_id;

    /** @type {any[]?} */
    variants;
}