import { ERRORS, INSPIRATIONS, STAYS_FORM_LABELS, STAY_WIZARD_CONTENT } from "constants/content";
import { MAXIMUM_HOME_DESCRIPTION_LENGTH } from "constants/defaults";
import { isNumber, isObject, isString } from "contracts/types";
import { isValidUrl } from "feature/panel/Settings/CustomApps/_shared/TextsPage/helpers";
import { isValidEmail } from "helpers";
import { isObjectEmpty } from "utils/object";
import { emailValidate } from "utils/validation";
import { coordinatesValidator } from "utils/validators/sharedValidators";

const GeneralSectionValidator = (wizardState, isDefault) => {
  const errors = {};

  if (isDefault) {
    if (!wizardState.userId) {
      errors.userId = ERRORS.isRequired(STAYS_FORM_LABELS.fields.userId.replace("*", ""));
    }

    if (!wizardState.propertyName) {
      errors.propertyName = ERRORS.isRequired(STAYS_FORM_LABELS.fields.propertyName.replace("*", ""));
    }

    if (wizardState.address === "" || !wizardState.address) {
      errors.address = ERRORS.isRequired("Address");
    }

    if (!wizardState.background || !isObject(wizardState.background)) {
      errors.background = ERRORS.isRequired("Background");
    }

    if (wizardState.website && !isValidUrl(wizardState.website)) {
      errors.website = "Wrong URL format";
    }

    if (wizardState.email && !isValidEmail(wizardState.email)) {
      errors.email = "Wrong email format";
    }

    const coordsError = coordinatesValidator(wizardState.coordinates);

    if (!isObjectEmpty(coordsError)) {
      errors.coordinates = coordsError;
    }

    wizardState.alertEmails.emails
      .split(",")
      .map(email => email.trim())
      .forEach(email => {
        if (email && !emailValidate(email)) {
          if (errors.alertEmails) {
            errors.alertEmails.emails = ERRORS.someInvalidEmail;
          } else {
            errors.alertEmails = { emails: ERRORS.someInvalidEmail };
          }
        }
      });

    if (!wizardState.checkInTime) {
      errors.checkInTime = ERRORS.isRequired(STAYS_FORM_LABELS.fields.checkInTime);
    }
  }

  if (wizardState.homeDescription.length > MAXIMUM_HOME_DESCRIPTION_LENGTH) {
    errors.homeDescription = STAYS_FORM_LABELS.maximumNumberOfCharacters(MAXIMUM_HOME_DESCRIPTION_LENGTH);
  }

  return errors;
};

const PasscodesValidator = passcodeGroups => {
  const errors = {};

  passcodeGroups.forEach(passcodeGroup => {
    const { id, passcodes, name } = passcodeGroup;

    if (!name) {
      if (!errors[id]) {
        errors[id] = {};
      }
      errors[id].name = ERRORS.isRequired(STAY_WIZARD_CONTENT.passcodes.categoryName);
    } else if (name.length > 20) {
      if (!errors[id]) {
        errors[id] = {};
      }
      errors[id].name = ERRORS.isTooLong(20);
    }
    if (passcodes && passcodes.length > 0) {
      passcodes.forEach((psd, i) => {
        if (!psd.passcode) {
          if (!errors[id] || !errors[id].passcodes || !errors[id].passcodes[i]) {
            errors[id] = { passcodes: { [i]: {} } };
          }
          errors[id].passcodes[i].passcode = ERRORS.isRequired(STAY_WIZARD_CONTENT.passcodes.passcode);
        }

        if (psd.passcode.toLowerCase() === "nested") {
          if (!errors[id] || !errors[id].passcodes || !errors[id].passcodes[i]) {
            errors[id] = { passcodes: { [i]: {} } };
          }
          errors[id].passcodes[i].passcode = ERRORS.reservedPasscode(psd.passcode);
        }

        const isPasscodeNotUniquee = passcodes.some((passcode, passcodeIndex) => psd.passcode === passcode.passcode && passcodeIndex !== i);

        if (isPasscodeNotUniquee) {
          if (!errors[id] || !errors[id].passcodes) {
            errors[id] = { passcodes: { [i]: {} } };
          }
          if (!errors[id].passcodes[i]) {
            errors[id].passcodes[i] = {};
          }

          errors[id].passcodes[i].passcode = ERRORS.uniquePasscode;
        }
      });
    }
  });

  return errors;
};

const MessagingValidator = messaging => {
  const doesExistEmailWithDuringProperty = messaging.emails.some(email => email.during);

  return messaging.emails.reduce((acc, email) => {
    const emailError = {};

    if (!email.email) {
      emailError.email = ERRORS.isRequired("E-mail");
    }
    if (email.email && !emailValidate(email.email)) {
      emailError.email = ERRORS.invalidEmail;
    }
    if (!doesExistEmailWithDuringProperty) {
      acc.during = ERRORS.messagingWithoutDuringOptionSelected;
    }

    if (!isObjectEmpty(emailError)) {
      acc[email.id] = emailError;
    }

    return acc;
  }, {});
};

const GalleryValidator = gallery => {
  const errors = {};

  if (gallery && gallery.length > 0) {
    gallery.forEach(galleryItem => {
      const { image, id, headline } = galleryItem;

      if (!image) {
        if (!errors[id]) {
          errors[id] = {};
        }
        errors[id].image = ERRORS.missingAttachment;
      }

      if (headline.length > 128) {
        if (!errors[id]) {
          errors[id] = {};
        }
        errors[id].headline = ERRORS.isTooLong(128);
      }
    });
  }

  return errors;
};

const InspirationValidator = inspiration => {
  const errors = {};

  if (
    inspiration?.settings?.period?.deliveryAtDays !== undefined &&
    inspiration?.settings?.period?.deliveryAtDays !== null &&
    inspiration?.settings?.period?.deliveryAtRelativeTo !== undefined &&
    inspiration?.settings?.period?.deliveryAtRelativeTo !== null &&
    inspiration.settings.content === ""
  ) {
    errors.content = ERRORS.cantBeBlank(INSPIRATIONS.messageContent.replace("*", ""));
  }

  return errors;
};

const DndValidator = dnd => {
  const errors = {};

  if (dnd.email && !emailValidate(dnd.email)) {
    errors.email = ERRORS.invalidEmail;
  }

  return errors;
};

const GpsNotificationValidatior = notifications => {
  const errors = {};

  notifications
    .filter(notification => notification.type === "gps")
    .forEach(notification => {
      if (notification.is_active && !(notification.latitude !== undefined && notification.longitude !== undefined)) {
        errors[notification.template_id] = {
          coordinates: ERRORS.coordinatesOrLocationRequired,
        };
      }
      if (notification?.latitude && notification?.longitude) {
        if (
          isString(notification?.latitude) ||
          (isNumber(notification?.latitude) && (notification.latitude < -90 || notification.latitude > 90))
        ) {
          errors[notification.template_id] = {
            coordinates: ERRORS.latitude,
          };
        }
        if (
          isString(notification?.longitude) ||
          (isNumber(notification?.longitude) && (notification.longitude < -180 || notification.longitude > 180))
        ) {
          errors[notification.template_id] = {
            coordinates: ERRORS.longitude,
          };
        }
      }
    });

  return errors;
};

const TimedNotificationValidatior = notifications => {
  const errors = {};

  notifications
    .filter(notification => notification.type === "timed")
    .forEach(notification => {
      if (!notification.start_at || notification.start_at === "Invalid date") {
        errors[notification.id] = ERRORS.invalidTime;
      }
    });

  return errors;
};

const ActionsValidator = actions => {
  const errors = {};

  if (actions.action.label || actions.action.file) {
    if (!actions.action.label) {
      errors.action = { label: ERRORS.isRequired("Title") };
    }

    if (!actions.action.file) {
      if (errors.action) {
        errors.action.file = ERRORS.missingAttachment;
      } else {
        errors.action = { file: ERRORS.missingAttachment };
      }
    }
  }

  if (actions.bookNow.label || actions.bookNow.file) {
    if (!actions.bookNow.label) {
      errors.bookNow = { label: ERRORS.isRequired("Title") };
    }

    if (!actions.bookNow.file) {
      if (errors.bookNow) {
        errors.bookNow.file = ERRORS.missingAttachment;
      } else {
        errors.bookNow = { file: ERRORS.missingAttachment };
      }
    }
  }

  return errors;
};

/**
 *
 * @param {import("domain/Stay").Stay} stayWizardState
 * @param {boolean} isDefault
 * @returns
 */
export const StayValidator = (stayWizardState, isDefault = true) => {
  const errors = {};
  const dndErrors = DndValidator(stayWizardState.dnd, isDefault);
  const passcodesErrors = PasscodesValidator(stayWizardState.passcodes, isDefault);
  const galleryErrors = GalleryValidator(stayWizardState.gallery, isDefault);
  const inspirationErrors = InspirationValidator(stayWizardState.inspiration, isDefault);
  const gpsNotificationErrors = GpsNotificationValidatior(stayWizardState?.notifications?.gps?.items, isDefault);
  const timedNotificationErrors = TimedNotificationValidatior(stayWizardState?.notifications?.timed?.items, isDefault);
  const generalErrors = GeneralSectionValidator(stayWizardState, isDefault);
  const actionsErrors = ActionsValidator(stayWizardState.actions, isDefault);
  const messagingErrors = MessagingValidator(stayWizardState.messaging, isDefault);

  if (!isObjectEmpty(dndErrors)) {
    errors.dnd = dndErrors;
  }

  if (!isObjectEmpty(gpsNotificationErrors)) {
    errors.gpsNotifications = gpsNotificationErrors;
  }

  if (!isObjectEmpty(timedNotificationErrors)) {
    errors.timedNotifications = timedNotificationErrors;
  }

  if (!isObjectEmpty(passcodesErrors)) {
    errors.passcodes = passcodesErrors;
  }

  if (!isObjectEmpty(galleryErrors)) {
    errors.gallery = galleryErrors;
  }

  if (!isObjectEmpty(inspirationErrors)) {
    errors.inspiration = inspirationErrors;
  }

  if (!isObjectEmpty(generalErrors)) {
    errors.general = generalErrors;
  }

  if (!isObjectEmpty(actionsErrors)) {
    errors.actions = actionsErrors;
  }

  if (!isObjectEmpty(messagingErrors)) {
    errors.messaging = messagingErrors;
  }

  return errors;
};
