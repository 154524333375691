import React from "react";
import PropTypes from "prop-types";

import { EditAction } from "components/ui/Tables/TableRowActions";
import { Table, TableHeader, TableBody, TablePagination } from "components/ui/Tables";

import { PANEL_CUSTOM_APPS_EDIT_PATH_GENERAL } from "constants/routes";
import { CA_VERSIONS_HISTORY } from "constants/content";
import { usePermissionsService } from "hooks/usePermissionsService";
import { PERMISSIONS } from "constants/permissions";

import { VersionsHistoryTableRow } from "./VersionsHistoryTableRow";

const caVersionsTableColumns = [
  { label: CA_VERSIONS_HISTORY.tableLabels.title, key: "name", show: true, sortable: true },
  { label: CA_VERSIONS_HISTORY.tableLabels.version, key: "sequenceNumber", show: true, sortable: true },
  { label: CA_VERSIONS_HISTORY.tableLabels.status, key: "status", show: true, sortable: true },
  { label: CA_VERSIONS_HISTORY.tableLabels.created, key: "created_at", show: true, sortable: true },
  { label: CA_VERSIONS_HISTORY.tableLabels.lastChange, key: "updated_at", show: true, sortable: true },
];

const VersionsTable = ({ list, count, tableHandlers }) => {
  const permissionsService = usePermissionsService();

  const canEdit = permissionsService.can(PERMISSIONS.actions.update, PERMISSIONS.sections.operator, PERMISSIONS.resources.operators);

  const { handleChangeOrder, handleChangePage, handleChangeRowsPerPage, rowsPerPage, page } = tableHandlers;

  const openCustomAppVersion = ({ onClick, isActive, tooltip }) => {
    return canEdit ? <EditAction disabled={!isActive} clickAction={onClick} tooltip={tooltip} /> : null;
  };

  return (
    <Table
      url={PANEL_CUSTOM_APPS_EDIT_PATH_GENERAL}
      urlParams={["id"]}
      headers={caVersionsTableColumns}
      list={list}
      actions={openCustomAppVersion}
      onChangeOrder={handleChangeOrder}
      onChangePage={handleChangePage}
      onChangeRowsPerPage={handleChangeRowsPerPage}
    >
      <TableHeader withActions />
      <TableBody rowComponent={VersionsHistoryTableRow} />
      <TablePagination count={count} page={page - 1} rowsPerPage={rowsPerPage} />
    </Table>
  );
};

VersionsTable.defaultProps = {
  list: [],
  count: 0,
};

VersionsTable.propTypes = {
  list: PropTypes.arrayOf(PropTypes.shape),
  count: PropTypes.number,
  tableHandlers: PropTypes.shape({
    handleChangeOrder: PropTypes.func,
    handleChangePage: PropTypes.func,
    handleChangeRowsPerPage: PropTypes.func,
    rowsPerPage: PropTypes.number,
    page: PropTypes.number,
  }).isRequired,
};

export { VersionsTable };
