import React from "react";
import PropTypes from "prop-types";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";

import { Chip } from "components/ui/Chips";
import { TextLimiter } from "components/ui/TextLimiter";

import { getFilePreviewUrl, openPreviewInNewTab } from "utils/library";
import { UrlRefreshService } from "services/application/UrlRefreshService";

const ChipWithRedirect = ({ onDelete, icon, maxChar, dotsPosition, item, editable, disabled }) => {
  const handlePreviewOpen = async () => {
    const refreshedUrl = await UrlRefreshService.refresh(getFilePreviewUrl(item));
    openPreviewInNewTab(refreshedUrl);
  };

  const tooltipItem = () => (
    <TextLimiter text={item.web_url || item.webUrl || item.file_name || item.name} maxChar={maxChar} dotsPosition={dotsPosition} />
  );

  return (
    <div>
      <Chip icon={icon} onClick={handlePreviewOpen} onDelete={(editable && !disabled) ? onDelete : null} label={tooltipItem()} />
    </div>
  );
};

ChipWithRedirect.propTypes = {
  onDelete: PropTypes.func.isRequired,
  item: PropTypes.shape(),
  icon: PropTypes.shape(),
  maxChar: PropTypes.number,
  dotsPosition: PropTypes.string,
  editable: PropTypes.bool,
  disabled: PropTypes.bool,
};

ChipWithRedirect.defaultProps = {
  icon: <DescriptionOutlinedIcon />,
  maxChar: 25,
  dotsPosition: "middle",
  editable: true,
  disabled: false,
  item: null,
};

export { ChipWithRedirect };
