import React from "react";
import { PropTypes } from "prop-types";
import AddIcon from "@material-ui/icons/Add";
import { Button } from "feature/panel/_shared/MapWidget_old/styledComponents";
import { Row } from "../../Location/style";
import CoordinatesButton from "../CoordinatesButton/index";

const BottomRow = ({ latitude, longitude, disableAddButton, onClick, addButtonText }) => {
  return (
    <Row style={{ marginTop: 10 }}>
      <CoordinatesButton latitude={latitude} longitude={longitude} />
      {!disableAddButton && (
        <Button primary style={{ marginLeft: 30 }} onClick={onClick}>
          <AddIcon />
          <span>{addButtonText || "Add"}</span>
        </Button>
      )}
    </Row>
  );
};

BottomRow.defaultProps = {
  disableAddButton: false,
  addButtonText: "Add",
};

BottomRow.propTypes = {
  latitude: PropTypes.number.isRequired,
  longitude: PropTypes.number.isRequired,
  disableAddButton: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  addButtonText: PropTypes.string,
};

export default BottomRow;
