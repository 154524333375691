import React from "react";
import PropTypes from "prop-types";
import MuiTableBody from "@material-ui/core/TableBody";

import { DefaultTableRow } from "components/ui/Tables/DefaultTableRow";
import { useTableContext } from "components/ui/Tables/TableContext";

const TableBody = ({ rowComponent, shouldCheckSaveLock }) => {
  const { list } = useTableContext();
  const RenderedRowComponent = (item, index) =>
    rowComponent ? (
      React.createElement(rowComponent, { item, index, key: item.vamoosId || item.id, shouldCheckSaveLock })
    ) : (
      <DefaultTableRow key={item.vamoosId || item.id || item.email_address} item={item} />
    );

  return <MuiTableBody>{list.map((item, index) => RenderedRowComponent(item, index))}</MuiTableBody>;
};

TableBody.defaultProps = {
  rowComponent: undefined,
};

TableBody.propTypes = {
  rowComponent: PropTypes.oneOfType([PropTypes.element, PropTypes.func, PropTypes.node]),
};

export { TableBody };
