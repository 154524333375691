import React, { forwardRef, useContext } from "react";

import { ReactSortable } from "react-sortablejs";
import styled from "styled-components"

import { SortableListItem } from "./SortableListItem";
import { SortableListNextContext, SortableListNextContextProvider } from "./SortableListNextContext";

const SortableList = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 40px;
`;

const SortableListContainer = forwardRef(({ children, ...props }, ref) => {
  return <SortableList {...props} ref={ref}>{children}</SortableList>;
});

export const SortableListNext = ({
  items, 
  listId, 
  group, 
  guard, 
  onSort, 
  useDragHandle, 
  renderItem 
}) => {

  const upperContext = useContext(SortableListNextContext);

  const value = {
    items, 
    listId, 
    group, 
    guard, 
    onSort: onSort || upperContext?.onSort, 
    useDragHandle, 
    renderItem,
    isRoot: upperContext === undefined
  }

  return (
    <SortableListNextContextProvider value={value}>
      <SortableListNextBase />
    </SortableListNextContextProvider>
  )
}

const SortableListNextBase = () => {

  const context = useContext(SortableListNextContext);

  if(context === undefined) {
    return null;
  }

  const {
    items, 
    listId, 
    group, 
    guard, 
    onSort, 
    useDragHandle, 
    renderItem,
  } = context;

  const onMove = (evt) => {
    return guard ? guard({
      listId,
      dragged: {
        hasChildren: evt.dragged.dataset.sortableHasChildren === "true",
        group: evt.dragged.dataset.sortableGroup
      },
      related: {
        hasChildren: evt.related.dataset.sortableHasChildren === "true",
        group: evt.related.dataset.sortableGroup,
      }
    }) : true;
  }

  const onSortHandle = (evt) => {
    const sortedItem = items.find(item => `${evt.item.dataset.id}` === `${item.id}`);

    if(sortedItem) {
      onSort({
        from: Number.isNaN(Number(evt.from.id)) ? evt.from.id : Number(evt.from.id),
        to: Number.isNaN(Number(evt.to.id)) ? evt.to.id : Number(evt.to.id),
        oldIndex: evt.oldIndex,
        newIndex: evt.newIndex,
        item: sortedItem
      })
    }
  }

  return (
    <ReactSortable 
      onMove={onMove} 
      handle={useDragHandle ? ".sortable-handle" : null}
      group={group}
      list={items}
      setList={() => {}}
      onSort={onSortHandle}
      animation={200}
      multiDrag
      id={listId}
      tag={SortableListContainer}
    >
      {items.map((item, index) => (
        <SortableListItem
          useDragHandle={useDragHandle}
          index={index}
          key={item.id}
          item={item}
          renderItem={renderItem}
          data-sortable-group={group ? group.name || group : undefined}
          data-sortable-has-children={item.items && item.items.length > 0}
        />
      ))}
    </ReactSortable>
  )
}

SortableListNextBase.defaultProps = {
  onAdd: () => {},
  onRemove: () => {}
}

SortableListNextBase.propTypes = {
  
}

export {
  SortableListNextBase
}