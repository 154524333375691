import { useDispatch } from "react-redux";

import { CHANGES_SAVED_MESSAGE } from "constants/content";

import { useTableDynamicHeaders } from "hooks/useTableDynamicHeaders";

import { setNotification } from "store/app/actions";
import { StorageService } from "services/StorageService";
import { deviceType } from "../utils/deviceDetect";

const useColumnSelectorHandlers = (localStorageKey, columns, onUpdate) => {
  const storageService = new StorageService();

  const [headers, setHeaders] = useTableDynamicHeaders(localStorageKey, columns);

  const dispatch = useDispatch();

  const handleSave = (selectedColumns, sortedColumns) => {
    const newHeaders = {};
    const device = deviceType();
    sortedColumns.all.forEach(column => {
      let show = false;
      if (selectedColumns.includes(column.key)) {
        show = true;
        newHeaders[device] = [...(newHeaders[device] || []), { ...column, show }];
      }
      newHeaders.all = [...(newHeaders.all || []), { ...column, show }];
    });

    if (!newHeaders.defaultDevice) newHeaders.defaultDevice = device;
    storageService
      .setPropertyByName(localStorageKey, newHeaders)
      .then(result => {
        if (result) {
          setHeaders(newHeaders);
          onUpdate(false);
          dispatch(setNotification({ type: "success", message: CHANGES_SAVED_MESSAGE }));
        }
      })
      .catch(() => {
        setHeaders({});
      });
  };
  return {
    headers,
    setHeaders,
    handleSave,
  };
};

export { useColumnSelectorHandlers };
