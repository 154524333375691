import React from "react";
import PropTypes from "prop-types";
import TableCell from "@material-ui/core/TableCell";

import { TableRow } from "components/ui/Tables";

import { convertToDateTimeFormat } from "utils/dataConverters";

const FlightsListRow = ({ item }) => {
  const { carrier_fs_code, carrier, carrier_flight_number, departure_airport, departure_at_utc, arrival_airport, arrival_at_utc } = item;
  return (
    <TableRow item={item}>
      <TableCell>{`${carrier_fs_code} ${carrier_flight_number}`}</TableCell>
      <TableCell>{carrier.name}</TableCell>
      <TableCell>{departure_airport.iata_code || departure_airport.fs_code}</TableCell>
      <TableCell>{convertToDateTimeFormat(departure_at_utc, departure_airport.timezone)}</TableCell>
      <TableCell>{arrival_airport.iata_code || arrival_airport.fs_code}</TableCell>
      <TableCell>{convertToDateTimeFormat(arrival_at_utc, arrival_airport.timezone)}</TableCell>
    </TableRow>
  );
};

FlightsListRow.propTypes = {
  item: PropTypes.shape({
    carrier_fs_code: PropTypes.string.isRequired,
    carrier_flight_number: PropTypes.string,
    departure_airport: PropTypes.shape({
      name: PropTypes.string,
      iata_code: PropTypes.string,
      fs_code: PropTypes.string,
    }).isRequired,
    arrival_airport: PropTypes.shape({
      name: PropTypes.string,
      iata_code: PropTypes.string,
      fs_code: PropTypes.string,
    }).isRequired,
    carrier: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }).isRequired,
    departure_at_utc: PropTypes.string.isRequired,
    arrival_at_utc: PropTypes.string.isRequired,
  }).isRequired,
};

export { FlightsListRow };
