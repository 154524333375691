import React from "react";
import { Grid } from "@material-ui/core";
import { useSelector } from "react-redux";

import { AdminTemplate } from "components/templates/Admin/AdminTemplate";
import { SearchField } from "components/ui/SearchField";
import { NoResultsMessage } from "components/ui/Messages";
import { LoadingScreen } from "components/ui/LoadingScreen/LoadingScreen";

import { useTableHandlers } from "hooks/useTableHandlers";

import {
  ADMIN_CONNECT_LABELS,
  CREATE_CONTENT_LABELS,
  EMPTY_LIST_MESSAGES_BASE,
  NAMES_OF_RESOURCES_LISTS,
  NO_RESULTS_FOUND_MESSAGE,
} from "constants/content";
import { ADMIN_CONNECT_STAYS_URL } from "constants/api";
import { checkQueryParamExistence } from "utils/url";

import { getConnectStaysStart } from "store/adminConnect/actions";
import { GET_CONNECT_STAYS_START } from "store/adminConnect/actionTypes";

import { ManageAccountsSubMenu } from "feature/admin/_shared/ManageAccountsSubMenu";

import { TableOfConnectItineraries } from "./TableOfConnectItineraries/TableOfConnectItineraries";

const ConnectIndex = () => {
  const isLoadingScreen = useSelector(state => state.adminConnect.inProgress && state.adminConnect.actionType === GET_CONNECT_STAYS_START);
  const staysCount = useSelector(state => state.adminConnect.staysCount);
  const itinerariesList = useSelector(state => state.adminConnect.stays);

  const tableHandlers = useTableHandlers(ADMIN_CONNECT_STAYS_URL, getConnectStaysStart, {
    customSearchParam: "search",
    defaultOrderBy: "created_at",
    defaultOrder: "desc",
  });

  const handleSearch = query => {
    tableHandlers.handleSearch(query, true);
  };

  const contextBar = {
    left: <SearchField label={ADMIN_CONNECT_LABELS.searchLabel} onSearchChange={handleSearch} />,
    middle: ManageAccountsSubMenu,
    right: null,
  };

  const content = () => {
    if (isLoadingScreen && !itinerariesList.length) {
      return <LoadingScreen />;
    }
    if (!isLoadingScreen && !itinerariesList.length) {
      return (
        <NoResultsMessage>
          {checkQueryParamExistence(tableHandlers.url, "search")
            ? NO_RESULTS_FOUND_MESSAGE
            : EMPTY_LIST_MESSAGES_BASE(CREATE_CONTENT_LABELS.stay, NAMES_OF_RESOURCES_LISTS.stay)}
        </NoResultsMessage>
      );
    }
    if (itinerariesList.length) {
      return (
        <TableOfConnectItineraries
          itineraries={itinerariesList}
          tableHandlers={tableHandlers}
          count={staysCount}
          isDataLoading={isLoadingScreen}
        />
      );
    }
    return null;
  };

  return (
    <AdminTemplate contextBar={contextBar}>
      <Grid container justifyContent="center">
        <Grid item md={12} lg={11} xl={10}>
          {content()}
        </Grid>
      </Grid>
    </AdminTemplate>
  );
};

export { ConnectIndex };
