import React, { useContext, useReducer, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormHelperText, Grid, Menu, MenuItem } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import PropTypes from "prop-types";
import { ArtTrackOutlined } from "@material-ui/icons";
import styled from "styled-components";
import uuidv4 from "uuid";

import { SortableTable } from "components/ui/SortableTable/SortableTable";
import {
  stayWizardAddDirectoriesItem,
  stayWizardEditDirectoriesItem,
  stayWizardRemoveDirectoriesItem,
  stayWizardSortDirectories,
} from "store/stayWizard/actions";
import { BodyText2 } from "components/ui/Typography/Typography";
import { ConfirmationModal } from "components/ui/Modals/ConfirmationModal";
import { DeleteAction, EditAction } from "components/ui/Tables";
import { StickyWrapper } from "components/ui/Forms/FormSection";
import { NoResultsMessage } from "components/ui/Messages";
import { SecondaryButton } from "components/ui/Buttons";

import { initDirectory } from "utils/directories";
import {
  CREATE_CONTENT_LABELS,
  EMPTY_LIST_MESSAGES_BASE,
  EMPTY_VARIANT_SECTION,
  GLOBAL_CONTENT,
  NAMES_OF_RESOURCES_LISTS,
  STAY_WIZARD_CONTENT,
  TABLE_ACTIONS_TITLES,
} from "constants/content";
import { useSticky } from "hooks/useSticky";
import { useListOfLanguages } from "hooks/useListOfLanguages";

import { colors } from "config/theme/colors";

import { usePermissionsService } from "hooks/usePermissionsService";
import { PERMISSIONS } from "constants/permissions";
import { LanguageContext } from "components/_shared/LanguageSelector/LanguageContext";

import { AnchorLikeButton, ListRowSquareField } from "../styledComponents";
import { StyledAccountIcon } from "../DailyDirectoryVoucherShared/styledComponents";

import { CurrentDirectoryInfo } from "./CurrentDirectoryInfo";
import { AccessRestrictionSection } from "../DailyDirectoryVoucherShared/AccessRestrictionSection";
import { DirectoryBreadcrumbs } from "./DirectoryBreadcrumbs";
import { directoryReducer } from "./DirectoryReducer";
import { DirectoryDialog } from "./DirectoryDialog";

export const HeaderContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: auto;
`;

export const ActionsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.setSpacing(10)}px;
`;

export const DirectoriesHeaderContainer = styled(Grid)`
  padding: 0 150px 0 76px;
`;

const getBreadcrumbs = (directoryId, dirs) => {
  const currentDir = dirs.find(dir => {
    return dir.id === directoryId;
  });

  if (currentDir) {
    if (currentDir.parent_id) {
      return [...getBreadcrumbs(currentDir.parent_id, dirs), currentDir];
    }

    return [currentDir];
  }

  return [];
};

const DirectoryListRow = ({ item, canRead, canEdit, onPreviewOpen, onDirectoryClick }) => {
  return (
    <>
      <ListRowSquareField>{item?.is_list ? <StyledAccountIcon /> : <ArtTrackOutlined />}</ListRowSquareField>
      <AnchorLikeButton
        onClick={() => {
          if (canRead && !canEdit) {
            onPreviewOpen(item);
          } else {
            onDirectoryClick(item);
          }
        }}
        disabled={canRead && !canEdit ? false : !item?.is_list}
      >
        <BodyText2 cv={colors.grey100}>{item.name}</BodyText2>
      </AnchorLikeButton>
    </>
  );
};

DirectoryListRow.propTypes = {
  item: PropTypes.shape({
    is_list: PropTypes.bool,
    name: PropTypes.string,
  }).isRequired,
  canRead: PropTypes.bool.isRequired,
  canEdit: PropTypes.bool.isRequired,
  onPreviewOpen: PropTypes.func.isRequired,
  onDirectoryClick: PropTypes.func.isRequired,
};

const Directories = () => {
  const stickyPosition = "top";
  const stickyOffset = 128;
  const allLanguages = useListOfLanguages();

  const { currentLanguage } = useContext(LanguageContext);

  const defaultLanguageName = useSelector(({ stayWizard, operator }) => {
    if(stayWizard.defaultVariant.language) {
      return allLanguages.find(lang => lang.code === stayWizard.defaultVariant.language)?.name;
    }
    
    return allLanguages.find(lang => lang.code === operator.currentOperator.defaultLanguageCode)?.name; 
  })

  const permissionsService = usePermissionsService();
  const dispatch = useDispatch();
  const headerRef = useRef();
  const isSticky = useSticky(headerRef, stickyPosition, stickyOffset);

  const [activityType, setActivityType] = useState(null);
  const [openMenu, setOpenMenu] = useState(null);
  const [creationModalOpen, setCreationModalOpen] = useState(false);
  const [deleteModal, setDeleteModal] = useState({ open: false, body: {} });
  const [currentDirectoryTag, setCurrentDirectoryTag] = useState(null);
  const [directoryForm, dispatchLocal] = useReducer(directoryReducer, initDirectory("directory", true));
  const isWiped = useSelector(({ stayWizard }) => stayWizard.form.isWiped);
  const vamoosId = useSelector(({ stayWizard }) => stayWizard.form.vamoosId);
  const editMode = useSelector(({ stayWizard }) => stayWizard.form.vamoosId !== null);

  const canRead = permissionsService.can(PERMISSIONS.actions.read, PERMISSIONS.sections.vamoosList, vamoosId);
  const canCreate = permissionsService.can(PERMISSIONS.actions.create, PERMISSIONS.sections.vamoosList, vamoosId);
  const canEdit = permissionsService.can(PERMISSIONS.actions.update, PERMISSIONS.sections.vamoosList, vamoosId);

  const directories = useSelector(({ stayWizard }) => stayWizard.form.directories.items || []);
  const currentDirectory = useSelector(
    ({ stayWizard }) => stayWizard.form.directories.items.find(dir => currentDirectoryTag && dir.tag === currentDirectoryTag),
  );
  const currentDirectoryId = currentDirectory?.id;

  const isStayDefaultLanguage = useSelector(
    ({ stayWizard }) => stayWizard.defaultVariant.language ? stayWizard.defaultVariant.language === currentLanguage.code : true
  )

  const directoriesToShow = currentDirectoryId
    ? directories.filter(dir => dir.parent_id === currentDirectoryId)
    : directories.filter(dir => !dir.parent_id);

  const breadcrumbs = [{ name: STAY_WIZARD_CONTENT.directories.directory, tag: null }, ...getBreadcrumbs(currentDirectoryId, directories)];

  const handleMenuOpen = ({ currentTarget }) => setOpenMenu(currentTarget);
  const handleMenuClose = () => setOpenMenu(null);

  const openEditModal = (directory = null, is_list = true) => {
    setCreationModalOpen(true);
    setActivityType(directory === null ? "CREATE" : "EDIT");
    setOpenMenu(null);

    dispatchLocal({
      type: "setAllValues",
      payload: directory || initDirectory("directory", directory ? directory.is_list : is_list, currentDirectoryId),
    });
  };

  const handleChangeOrder = ({ newIndex, oldIndex }) => {
    const allDirectoriesListOldIndex = directories.findIndex(dir => dir.id === directoriesToShow[oldIndex].id);
    const allDirectoriesListNewIndex = directories.findIndex(dir => dir.id === directoriesToShow[newIndex].id);

    dispatch(
      stayWizardSortDirectories({
        newIndex: allDirectoriesListNewIndex,
        oldIndex: allDirectoriesListOldIndex,
        language: currentLanguage
      }),
    );
  };

  const handleModalConfirm = () => {
    if (directoryForm.id) {
      dispatch(stayWizardEditDirectoriesItem({ editedDirectory: directoryForm, language: currentLanguage }));
    } else if (currentDirectoryId) {
      dispatch(
        stayWizardAddDirectoriesItem({
          newDirectory: {
            ...directoryForm,
            id: uuidv4(),
            parent_id: currentDirectoryId,
          },
          language: currentLanguage
        }),
      );
    } else {
      dispatch(
        stayWizardAddDirectoriesItem({
          newDirectory: {
            ...directoryForm,
            id: uuidv4(),
          },
          language: currentLanguage
        }),
      );
    }
    setCreationModalOpen(false);
  };

  const handleModalCancel = () => {
    setCreationModalOpen(false);
  };

  const openDeleteModal = directory => {
    setDeleteModal({ body: directory, open: true });
  };

  const handleDeleteConfirm = () => {
    dispatch(stayWizardRemoveDirectoriesItem({ id: deleteModal.body.id, language: currentLanguage }));
    setDeleteModal({ body: null, open: false });
  };

  const handleDeleteCancel = () => {
    setDeleteModal({ body: null, open: false });
  };

  const onDirectoryClick = directory => {
    setCurrentDirectoryTag(directory.tag);
  };

  const deleteTitle = deleteModal?.body?.name ? STAY_WIZARD_CONTENT.directories.deleteModalTitle(deleteModal?.body?.name) : "";

  const handleDirectorySet = (fieldName, value) => {
    dispatchLocal({
      type: "setValueFor",
      fieldName,
      value,
    });
  };

  const handleParentIdChange = newParentId => {
    if (newParentId === null) {
      dispatchLocal({ type: "removeParentId" });
    } else {
      dispatchLocal({
        type: "setValueFor",
        fieldName: "parent_id",
        value: newParentId,
      });
    }
  };

  const renderHeader = () => (
    <HeaderContentWrapper>
      <DirectoryBreadcrumbs onBreadcrumbChange={setCurrentDirectoryTag} breadcrumbs={breadcrumbs} />
      {!isWiped && (!editMode || canEdit) && (
        <SecondaryButton onClick={handleMenuOpen} id="add-directory-button">
          <AddIcon />
          {GLOBAL_CONTENT.add}
        </SecondaryButton>
      )}
    </HeaderContentWrapper>
  );

  const pathToCurrentDirectory = "";

  const DirectoriesTableHeader = () => {
    return (
      <DirectoriesHeaderContainer alignItems="center" container>
        <Grid xs="8" item>
          <FormHelperText>{STAY_WIZARD_CONTENT.directories.title}</FormHelperText>
        </Grid>
      </DirectoriesHeaderContainer>
    );
  };

  const actions = ({ item }) => (
    <>
      {!isWiped && (!editMode || canEdit) && (
        <EditAction
          clickAction={() => openEditModal(item)}
          tooltip={TABLE_ACTIONS_TITLES.editResource(TABLE_ACTIONS_TITLES.resourceTypes.directory)}
        />
      )}
      {!isWiped && (!editMode || canEdit) && (
        <DeleteAction
          clickAction={() => openDeleteModal(item)}
          tooltip={TABLE_ACTIONS_TITLES.deleteResource(TABLE_ACTIONS_TITLES.resourceTypes.directory)}
        />
      )}
    </>
  );

  return (
    <>
      {currentDirectoryTag === null && <AccessRestrictionSection resource="directories" />}
      <Grid container justifyContent="center">
        <Grid item xs={12} md={10}>
          <ActionsHeader ref={headerRef}>
            {isSticky && (
              <StickyWrapper stickyVariant="normal" sticky={isSticky} offset={stickyOffset} position={stickyPosition}>
                <Grid container justifyContent="center">
                  <Grid xs={12} md={10} item>
                    {renderHeader()}
                  </Grid>
                </Grid>
              </StickyWrapper>
            )}
            {renderHeader()}
          </ActionsHeader>
          <Menu
            id="addDirectoryButton"
            anchorEl={openMenu}
            open={!!openMenu && openMenu.id === "add-directory-button"}
            onClose={handleMenuClose}
          >
            <MenuItem onClick={() => openEditModal(null, true)}>{STAY_WIZARD_CONTENT.directories.subDirectory}</MenuItem>
            <MenuItem onClick={() => openEditModal(null, false)}>{STAY_WIZARD_CONTENT.directories.details}</MenuItem>
          </Menu>
          {currentDirectory && <CurrentDirectoryInfo onEditToggle={item => openEditModal(item)} directory={currentDirectory} />}
          {directoriesToShow.length > 0 ? (
            <SortableTable
              disabled={!canEdit}
              onSortEnd={handleChangeOrder}
              headerComponent={DirectoriesTableHeader}
              rowListComponent={props => (
                <DirectoryListRow
                  {...props}
                  canRead={canRead}
                  canEdit={editMode ? canEdit : canCreate}
                  onPreviewOpen={openEditModal}
                  onDirectoryClick={onDirectoryClick}
                />
              )}
              list={directoriesToShow}
              actions={actions}
            />
          ) : (
            !(editMode && !canEdit) && (
              <>
                {!isStayDefaultLanguage && (
                  <NoResultsMessage height="100px">
                    {EMPTY_VARIANT_SECTION(currentLanguage.name, defaultLanguageName)}
                  </NoResultsMessage>
                )}
                <NoResultsMessage height="100px">
                  {EMPTY_LIST_MESSAGES_BASE(CREATE_CONTENT_LABELS.add, NAMES_OF_RESOURCES_LISTS.directory)}
                </NoResultsMessage>
              </>
            )
          )}
          <DirectoryDialog
            open={creationModalOpen}
            directory={directoryForm}
            onCancel={handleModalCancel}
            onConfirm={handleModalConfirm}
            setDirectoryValue={handleDirectorySet}
            currentDirectoryPath={pathToCurrentDirectory}
            currentDirectoriesList={directoriesToShow}
            directoriesList={directories}
            currentDirectoryId={currentDirectoryId}
            onParentIdChange={handleParentIdChange}
            canEdit={(activityType === "CREATE" && canCreate) || (activityType === "EDIT" && canEdit)}
          />
          {deleteModal.open && (
            <ConfirmationModal onCancel={handleDeleteCancel} open={deleteModal.open} onConfirm={handleDeleteConfirm} title={deleteTitle} />
          )}
        </Grid>
      </Grid>
    </>
  );
};

export { Directories };
