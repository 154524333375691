import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import ReorderIcon from "@material-ui/icons/Reorder";
import CloseIcon from "@material-ui/icons/Close";

import { SortableListItem, SortableHandler } from "components/ui/Lists";

const StyledWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-basis: auto;
`;

const ReorderButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({ theme }) => theme.setSpacing(10)}px;
  min-height: ${({ theme }) => theme.setSpacing(10)}px;
  height: auto;
  background-color: ${({ theme, error, noBg, brandedBg }) => {
    const color = brandedBg ? theme.colors.brand : theme.colors.grey10;
    return noBg ? "transparent" : theme.colors.withOpacity(error ? theme.colors.error : color, 0.2);
  }};
  border-radius: ${({ theme }) => theme.borderRadius}px;
  margin-bottom: 8px;
  cursor: pointer;
  box-shadow: ${({ theme }) => theme.shadows.map};

  ${({ isDragged }) => (isDragged ? "opacity: 0.5;" : null)}
  ${({ withoutShadow, theme }) => (withoutShadow ? null : `box-shadow: ${theme.shadows.map};`)}
`;

const StyledReorderIcon = styled(ReorderIcon)`
  && {
    color: ${({ theme }) => theme.colors.grey50};
  }
`;

const ContentArea = styled.div`
  border-radius: ${({ theme }) => theme.borderRadius}px;
  min-height: ${({ theme }) => theme.setSpacing(10)}px;
  background-color: ${({ theme, error, noBg, brandedBg }) => {
    const color = brandedBg ? theme.colors.brand : theme.colors.grey10;
    return noBg ? "transparent" : theme.colors.withOpacity(error ? theme.colors.error : color, 0.2);
  }};
  margin-bottom: ${({ theme }) => theme.setSpacing(2)}px;
  width: calc(100% - ${({ theme }) => theme.setSpacing(12)}px);
  display: flex;
  justify-content: ${({ justify }) => justify || "space-between"};
  align-items: ${({ align }) => align || "center"};
  box-shadow: ${({ theme }) => theme.shadows.map};
  padding: 10px ${({ theme }) => theme.setSpacing(3)}px;

  ${({ isDragged }) => (isDragged ? "opacity: 0.5;" : null)}
  ${({ withoutShadow, theme }) => (withoutShadow ? null : `box-shadow: ${theme.shadows.map};`)}

  > * {
    margin: 0 ${({ theme }) => theme.setSpacing(3)}px !important;
  }
`;

const RemoveButton = styled(CloseIcon)`
  display: block;
  color: ${({ theme }) => theme.colors.grey50};
  cursor: pointer;
`;

const SortableListElement = ({
  index,
  children,
  noBg,
  height,
  item,
  style,
  withoutShadow,
  destination,
  withError,
  brandedBg,
  disabled,
  ...rest
}) => {
  return (
    <SortableListItem index={index} item={item} style={style} destination={destination}>
      <StyledWrapper>
        {!disabled && (
          <SortableHandler>
            <ReorderButton noBg={noBg} height={height} withoutShadow={withoutShadow} error={withError} brandedBg={brandedBg}>
              <StyledReorderIcon />
            </ReorderButton>
          </SortableHandler>
        )}
        <ContentArea noBg={noBg} height={height} withoutShadow={withoutShadow} error={withError} brandedBg={brandedBg} {...rest}>
          {children}
        </ContentArea>
      </StyledWrapper>
    </SortableListItem>
  );
};

SortableListElement.defaultProps = {
  noBg: false,
  height: 20,
  item: {},
  style: {},
  withoutShadow: false,
  destination: "normal",
  withError: false,
  brandedBg: false,
};

SortableListElement.propTypes = {
  index: PropTypes.number.isRequired,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.object, PropTypes.func, PropTypes.string, PropTypes.number, PropTypes.array])
    .isRequired,
  noBg: PropTypes.bool,
  brandedBg: PropTypes.bool,
  height: PropTypes.number,
  item: PropTypes.shape(),
  style: PropTypes.shape(),
  withoutShadow: PropTypes.bool,
  destination: PropTypes.oneOf(["popup", "normal"]),
  withError: PropTypes.bool,
};

export { SortableListElement, RemoveButton };
