import { ListIndexService } from "services/ListIndexService";
import { StorageService } from "services/StorageService";

export const STAY_CONNECT_STATUS = {
  ON: "ON",
  OFF: "OFF",
  ON_REQUESTED: "ON_REQUESTED",
  OFF_REQUESTED: "OFF_REQUESTED",
};

export const updateStayOriginalIndexes = async state => {
  const listIndexService = new ListIndexService(new StorageService());
  listIndexService.clearOriginalIndexes();
  const stateMap = ["gallery", "passcodes"];
  await listIndexService.updateOriginalIndexesPosition(state, stateMap);
};

export const getConnectStatus = (is_listed, requested_listing_status) => {
  if (requested_listing_status === null) {
    return is_listed ? STAY_CONNECT_STATUS.ON : STAY_CONNECT_STATUS.OFF;
  }

  switch (requested_listing_status) {
    case true: {
      return STAY_CONNECT_STATUS.ON_REQUESTED;
    }
    case false: {
      return STAY_CONNECT_STATUS.OFF_REQUESTED;
    }
    default: {
      return null;
    }
  }
};

export const GENERAL_SECTION_ERROR_NAMES = [
  "operator_code",
  "background",
  "locations",
  "field1",
  "logo",
  "propertyName",
  "checkInTime",
  "alertEmails",
  "website",
  "meta.home_screen_description",
  "meta.phone",
  "meta.website",
  "meta.email",
  "meta.twitter",
  "meta.facebook",
  "meta.instagram",
  "meta.hotel_info_icon_id",
  "meta.hotel_info_label",
  "meta.short_description",
  "meta.long_description",
  "meta.number_of_rooms",
];

export const ACTIONS_SECTION_ERROR_NAMES = [
  "documents.travel",
  "documents.destination",
  "meta.destination_documents_label",
  "meta.travel_documents_label",
];

export const STAYS_SECTION_ERROR = [
  {
    label: "General",
    errorNames: GENERAL_SECTION_ERROR_NAMES,
  },
  {
    label: "Actions",
    errorNames: ACTIONS_SECTION_ERROR_NAMES,
  },
  {
    label: "POIs",
    errorNames: ["pois"],
  },
  {
    label: "Gallery",
    errorNames: ["gallery"],
  },
  {
    label: "Directory",
    errorNames: ["directories"],
  },
  {
    label: "Daily activities",
    errorNames: ["directories"],
  },
  {
    label: "Vouchers/menus",
    errorNames: ["directories"],
  },
  {
    label: "Passcodes",
    errorNames: ["passcodes"],
  },
  {
    label: "Notifications",
    errorNames: ["notifications", "gpsNotifications"],
  },
  {
    label: "Inspirations",
    errorNames: ["inspirations"],
  },
  {
    label: "Messaging",
    errorNames: ["messaging"],
  },
];
