export const sortColumns = (start, end, direction, columns) => {
  if (start > -1 && start < end && end <= columns.length) {
    const unchangedBeginning = columns.slice(0, start);
    const unchangedEnd = columns.slice(end + 1, columns.length);
    const betweenChange = columns.slice(start + 1, end);
    if (direction === "up") return [...unchangedBeginning, columns[end], columns[start], ...betweenChange, ...unchangedEnd];
    if (direction === "down") return [...unchangedBeginning, ...betweenChange, columns[end], columns[start], ...unchangedEnd];
    return columns;
  }
  return columns;
};

export const setBreakpoints = (newIndex, oldIndex) => {
  if (newIndex !== oldIndex) {
    return newIndex < oldIndex
      ? { start: newIndex, end: oldIndex, direction: "up" }
      : { start: oldIndex, end: newIndex, direction: "down" };
  }
  return { start: 0, end: 0, direction: "none" };
};

export const setNewOrder = (newIndex, oldIndex, columns) => {
  if (newIndex !== oldIndex) {
    const { start, end, direction } = setBreakpoints(newIndex, oldIndex);
    const newColumns = sortColumns(start, end, direction, columns);
    return columns.length === newColumns.length ? newColumns : columns;
  }
  return columns;
};
