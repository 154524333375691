import React from "react";
import PropTypes from "prop-types";
import { BodyText } from "components/ui/Typography/Typography";
import { Link } from "components/ui/Links/Links";
import { ArrowIcon } from "components/ui/Breadcrumbs/styledComponents";

const BreadcrumbsListItem = ({ label, url, isLast }) => (
  <>
    <Link to={url} noDecoration cv="grey50">
      <BodyText cv={isLast ? "grey100" : "grey50"}>{decodeURIComponent(label)}</BodyText>
    </Link>
    {!isLast && <ArrowIcon />}
  </>
);

BreadcrumbsListItem.propTypes = {
  label: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  isLast: PropTypes.bool.isRequired,
};

export { BreadcrumbsListItem };
