import React, { useContext, useEffect } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import { useTheme } from "@material-ui/core/styles";
import TableFooter from "@material-ui/core/TableFooter";
import MuiTablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";

import { TableContext } from "components/ui/Tables/TableContext";
import { deviceType } from "../../../utils/deviceDetect";
import { getTaleVarFromLS } from "./getTableVarFromLS";

const BorderlessTableRow = styled(TableRow)`
  && .MuiTableCell-footer {
    border: none;
  }
`;

const StyledWrapper = styled.div`
  flex-shrink: 0;
  margin-left: ${({ theme }) => theme.setSpacing(2)}px;
`;

const Pagination = styled(MuiTablePagination)`
  && {
    p,
    div {
      font-size: ${({ theme }) => theme.setSpacing(3)}px;
      color: ${({ theme }) => theme.colors.withOpacity(theme.colors.black, 0.54)};
    }

    .MuiTypography-body2 {
      font-family: ${({ theme }) => theme.fonts.font};
      letter-spacing: normal;
    }
  }
`;

function TablePaginationActions({ count, page, rowsPerPage, onPageChange }) {
  const theme = useTheme();

  const handleBackButtonClick = event => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = event => {
    onPageChange(event, page + 1);
  };

  return (
    <StyledWrapper>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {theme.direction === "rtl" ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton onClick={handleNextButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="next page">
        {theme.direction === "rtl" ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
    </StyledWrapper>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const TablePagination = ({ tableId, ...rest }) => {
  const { handleChangePage, handleChangeRowsPerPage, rowsPerPage, page, count, rowsPerPageOptions } = useContext(TableContext);

  const onResultPerPageChange = e => {
    handleChangeRowsPerPage(e.target.value, tableId);
  };

  useEffect(() => {
    const resultsPerPage = getTaleVarFromLS("resultsPerPage", tableId);

    if (resultsPerPage) {
      handleChangeRowsPerPage(resultsPerPage, tableId);
    }
  }, []);

  return (
    <TableFooter>
      <BorderlessTableRow>
        <Pagination
          rowsPerPageOptions={rowsPerPageOptions}
          colSpan={100}
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          SelectProps={{
            inputProps: { "aria-label": "rows per page" },
          }}
          onPageChange={handleChangePage}
          onRowsPerPageChange={onResultPerPageChange}
          ActionsComponent={TablePaginationActions}
          {...rest}
        />
      </BorderlessTableRow>
    </TableFooter>
  );
};

export { TablePagination, TablePaginationActions, StyledWrapper, Pagination };
