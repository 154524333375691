import React, { useContext, useEffect } from "react";

import { Messaging } from "feature/panel/_shared/Messaging/Messaging";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { DEFAULT_PASSCODE } from "constants/defaults";
import {
  stayWizardMessagingSetDatesAfter,
  stayWizardMessagingSetDatesBefore,
  stayWizardMessagingSetEmails,
} from "store/stayWizard/actions";
import { PERMISSIONS } from "constants/permissions";
import { usePermissionsService } from "hooks/usePermissionsService";
import { LanguageContext } from "components/_shared/LanguageSelector/LanguageContext";
import { setUrl } from "utils/url";
import { PANEL_STAYS_EDIT_PATH } from "constants/routes";

const MessagingWithStaysContext = () => {
  const { operator_code } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const permissionsService = usePermissionsService();

  const { currentLanguage } = useContext(LanguageContext);

  const isStayDefaultLanguage = useSelector(
    ({ stayWizard }) => stayWizard.defaultVariant.language ? stayWizard.defaultVariant.language === currentLanguage.code : true
  )

  const propertyName = useSelector(({ stayWizard }) => stayWizard.form.userId);
  const userId = useSelector(({ stayWizard }) => stayWizard.form.userId);
  const vamoosId = useSelector(({ stayWizard }) => stayWizard.form.vamoosId);
  const messagingEmails = useSelector(({ stayWizard }) => stayWizard.form.messaging.emails);
  const before = useSelector(({ stayWizard }) => stayWizard.form.messaging.dates.before);
  const after = useSelector(({ stayWizard }) => stayWizard.form.messaging.dates.after);

  const stayWizardErrors = useSelector(({ stayWizard }) => stayWizard.errors)

  const canEdit = permissionsService.can(PERMISSIONS.actions.update, PERMISSIONS.sections.vamoosList, vamoosId);

  const contextHandlerInterface = () => {
    return {
      field1: propertyName,
      editMode: operator_code !== undefined,
      canEdit,
      vamoos_id: vamoosId,
      errors: stayWizardErrors,
      meta: {
        messaging_emails_list: messagingEmails,
        messaging_email_during_days_after: after,
        messaging_email_during_days_before: before,
      },
      setValueFor: (key, value) => {
        if (key === "meta") {
          if (value.messaging_emails_list !== undefined || value.messaging_emails_list !== null) {
            dispatch(stayWizardMessagingSetEmails(value.messaging_emails_list));
          }

          if (value.messaging_email_during_days_before !== undefined || value.messaging_email_during_days_before !== null) {
            dispatch(stayWizardMessagingSetDatesBefore(value.messaging_email_during_days_before));
          }

          if (value.messaging_email_during_days_after !== undefined || value.messaging_email_during_days_after !== null) {
            dispatch(stayWizardMessagingSetDatesAfter(value.messaging_email_during_days_after));
          }
        }
      },
    };
  };

  useEffect(() => {
    if(!isStayDefaultLanguage) {
      const url = setUrl(PANEL_STAYS_EDIT_PATH, { operator_code: userId }, true);
      navigate(url);
    }
  }, [isStayDefaultLanguage]);

  return (
    <>
      {propertyName && (
        <Messaging
          conversationDetails={{ operator_code, reference_code: DEFAULT_PASSCODE }}
          contextHandler={contextHandlerInterface}
          configurationType="stay"
          field1={propertyName}
        />
      )}
    </>
  );
};

export { MessagingWithStaysContext };
