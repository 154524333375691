import React from "react";
import PropTypes from "prop-types";

import { Header } from "feature/panel/Stays/_shared/DailyDirectoryVoucherShared/styledComponents";
import { BodyText2, ErrorText } from "components/ui/Typography/Typography";
import { AttachFilesButton } from "components/ui/Buttons/AttachFilesButton";
import { Content } from "components/ui/Content";
import { isObjectEmpty } from "utils/object";
import { IMAGES_MIME_TYPES } from "constants/defaults";
import { ChipWithRedirect } from "components/ui/Chips/ChipWithRedirect";
import { DEFAULT_LIBRARY_TABS } from "components/ui/LibraryPopup/LibraryPopup";
import { useActiveIcon } from "hooks/useActiveIcon";

const FileField = ({ label, file, onFileChange, onFileRemove, error, allowedFileTypes, disabled }) => {
  const errorMessage = error && <ErrorText>{error}</ErrorText>;
  const activeIcon = useActiveIcon(file);

  return (
    <Content>
      <Header>
        <BodyText2>{`${label}:`}</BodyText2>
        {isObjectEmpty(file) ? (
          <AttachFilesButton
            onSelect={onFileChange}
            name={label}
            size="small"
            allowedFileTypes={allowedFileTypes}
            tabs={Object.values(DEFAULT_LIBRARY_TABS).splice(0, 2)}
            disabled={disabled}
          />
        ) : (
          <ChipWithRedirect onDelete={onFileRemove} item={file} icon={activeIcon} editable={!disabled} />
        )}
      </Header>
      {errorMessage}
    </Content>
  );
};

FileField.defaultProps = {
  error: null,
  file: {},
  allowedFileTypes: IMAGES_MIME_TYPES,
  disabled: false,
};

FileField.propTypes = {
  label: PropTypes.string.isRequired,
  file: PropTypes.shape({
    file_name: PropTypes.string,
  }),
  onFileChange: PropTypes.func.isRequired,
  onFileRemove: PropTypes.func.isRequired,
  error: PropTypes.string,
  disabled: PropTypes.bool,
  allowedFileTypes: PropTypes.arrayOf(PropTypes.string),
};

export { FileField };
