import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import AddIcon from "@material-ui/icons/Add";

import { Link } from "components/ui/Links/Link";
import { PrimaryButton } from "components/ui/Buttons";
import { PANEL_STAYS_CREATE_PATH } from "constants/routes";
import { PERMISSIONS } from "constants/permissions";
import { usePermissionsService } from "hooks/usePermissionsService";

const StyledWrapper = styled.div`
  height: ${({ theme }) => theme.setSpacing(16)}px;
  width: auto;
  display: flex;
  align-items: center;
`;

const Actions = () => {
  const { searchMode } = useSelector(state => state.stays);
  const permissionsService = usePermissionsService();

  const canCreate = permissionsService.can(PERMISSIONS.actions.create, PERMISSIONS.sections.vamoosList, PERMISSIONS.resources.default);

  return (
    <StyledWrapper>
      {!searchMode && canCreate && (
        <Link to={PANEL_STAYS_CREATE_PATH}>
          <PrimaryButton>
            <AddIcon />
            <span>create stay</span>
          </PrimaryButton>
        </Link>
      )}
    </StyledWrapper>
  );
};

export { Actions, StyledWrapper };
