import React, { useState, useEffect } from "react";
import CreatableSelect from "react-select/creatable";
import spacetime from "spacetime";
import soft from "timezone-soft";
import moment from "moment";
import { colors } from "config/theme/colors";
import allTimezones from "./timezones";
import { Wrap, Label } from "./style";
import SearchableSelect from "../Forms/Select/index";

moment.locale("en");

export { allTimezones };

const TimezoneSelect = ({
  value,
  onBlur,
  onChange,
  labelStyle = "original",
  timezones,
  startDate,
  label,
  onCreateOption,
  startTime,
  ...props
}) => {
  const [options, setOptions] = useState([]);
  const [isInputFocused, setIsFocused] = useState(false);

  if (!timezones) timezones = allTimezones;

  // const startTimeOrigin = moment(startTime).format("hh:mm:ss");

  const notExisting = value && !timezones[value];

  const getOptions = () => {
    const result = Object.entries(timezones)
      .reduce((selectOptions, zone) => {
        const now = spacetime(moment(startDate).format("MMMM DD YYYY hh:mm:ss"), zone[0]);

        if (!now) {
          selectOptions.push({
            value: zone[0],
          });

          return selectOptions;
        }

        const tz = now.timezone();
        const tzStrings = soft(zone[0]);

        let label = "";
        const abbr = now.isDST()
          ? // @ts-expect-error
            tzStrings[0].daylight?.abbr
          : // @ts-expect-error
            tzStrings[0].standard?.abbr;
        const altName = now.isDST() ? tzStrings[0].daylight?.name : tzStrings[0].standard?.name;

        const min = tz.current.offset * 60;
        const hr = `${(min / 60) ^ 0}:` + (min % 60 === 0 ? "00" : Math.abs(min % 60));
        const prefix = `(GMT${hr.includes("-") ? hr : `+${hr}`}) ${zone[1]}`;

        switch (labelStyle) {
          case "original":
            label = prefix;
            break;
          case "altName":
            label = `${prefix} ${altName?.length ? `(${altName})` : ""}`;
            break;
          case "abbrev":
            label = `${prefix} ${abbr?.length < 5 ? `(${abbr})` : ""}`;
            break;
          default:
            label = `${prefix}`;
        }

        selectOptions.push({
          value: tz.name,
          label,
          offset: tz.current.offset,
          abbrev: abbr,
          altName,
        });

        return selectOptions;
      }, [])
      .sort((a, b) => a.offset - b.offset);

    setOptions([...(notExisting ? [{ value, label: value }] : []), ...result]);
  };

  const handleChange = tz => {
    if (onChange) onChange(tz);
  };

  useEffect(() => {
    getOptions();
  }, [labelStyle, timezones, startDate]);

  useEffect(() => {
    if (value) {
      const newValue = options.find(item => item.value === value.value);
      if (newValue && onChange) onChange(newValue);
    }
  }, [options]);

  const findFuzzyTz = zone => {
    let currentTime = spacetime.now("GMT");
    try {
      currentTime = spacetime.now(zone);
    } catch (err) {
      return;
    }
    return options
      .filter(tz => tz.offset === currentTime.timezone().current.offset)
      .map(tz => {
        let score = 0;
        if (
          currentTime.timezones[tz.value.toLowerCase()] &&
          !!currentTime.timezones[tz.value.toLowerCase()].dst === currentTime.timezone().hasDst
        ) {
          if (tz.value.toLowerCase().indexOf(currentTime.tz.substring(currentTime.tz.indexOf("/") + 1)) !== -1) {
            score += 8;
          }
          if (tz.label.toLowerCase().indexOf(currentTime.tz.substring(currentTime.tz.indexOf("/") + 1)) !== -1) {
            score += 4;
          }
          if (tz.value.toLowerCase().indexOf(currentTime.tz.substring(0, currentTime.tz.indexOf("/")))) {
            score += 2;
          }
          score += 1;
        } else if (tz.value === "GMT") {
          score += 1;
        }
        return { tz, score };
      })
      .sort((a, b) => b.score - a.score)
      .map(({ tz }) => tz)[0];
  };
  const parseTimezone = zone => {
    if (!value) return null;
    if (typeof zone === "object" && zone.value && zone.label) return zone;
    if (typeof zone === "string") {
      return options.find(tz => tz.value === zone) || (zone.indexOf("/") !== -1 && findFuzzyTz(zone));
    } else if (zone.value && !zone.label) {
      return options.find(tz => tz.value === zone.value);
    }
  };

  const handleFocus = () => setIsFocused(true);
  const handleBlur = e => {
    if (onBlur) onBlur(e);
    setIsFocused(false);
  };

  return (
    <Wrap>
      <Label isFocused={isInputFocused} hasValue={value}>
        {label || "Time zone"}
      </Label>
      <CreatableSelect
        value={parseTimezone(value)}
        onChange={handleChange}
        options={options}
        onBlur={handleBlur}
        onFocus={handleFocus}
        onCreateOption={onCreateOption}
        placeholder=""
        styles={{
          control: (styles, { isFocused }) => ({
            ...styles,
            backgroundColor: "transparent",
            borderRadius: 8,
            borderColor: isFocused ? colors.brand : "#c4c4c4",
            height: 48 + "px",
            boxShadow: `0 0 0 1px ${isFocused ? colors.brand : "#fff"}`,
            padding: "0 0 0 7px",
            "&:hover": {
              borderColor: colors.brand,
              //   boxShadow: `0 0 0 1px ${colors.brand}`,
            },
          }),
          input: styles => ({ ...styles, borderRadius: 8, fontFamily: "Montserrat" }),
          menu: styles => ({ ...styles, zIndex: 3 }),
          dropdownIndicator: styles => ({
            ...styles,
            // padding: "8px 0",
            // color: INPUT_BORDER_COLOR,
            cursor: "pointer",
            width: 40,
            height: 40,
            justifyContent: "center",
            alignItems: "center",
            transition: "all .2s ease",
            borderRadius: 100,
            // "&:hover": {
            //   // color: INPUT_BORDER_COLOR,
            //   backgroundColor: "rgba(0, 0, 0, 0.04)",
            // },
          }),
          indicatorSeparator: styles => ({ ...styles, display: "none" }),
          placeholder: () => ({ display: "none" }),
          option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
            ...styles,
            backgroundColor: isSelected ? "#E0E0E0" : "#fff",
            color: "#000",
            "&:hover": {
              backgroundColor: isSelected ? "#E0E0E0" : "#F2F2F2",
            },
          }),
        }}
        {...props}
      />
    </Wrap>
  );
};

export default TimezoneSelect;
