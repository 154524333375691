import { useState, useEffect, useCallback } from "react";

import { StorageService } from "services/StorageService";
import { ListIndexService } from "services/ListIndexService";
import { LS_ITEMS_ORIGINAL_INDEXES } from "constants/localstorage";

const useItemOriginalIndex = (id, context, trigger) => {
  const storageService = new StorageService();
  const listIndexService = new ListIndexService(storageService);

  const [originalIndex, setOriginalIndex] = useState(0);

  const IndexHandler = useCallback(async () => {
    let cleanup = true;
    const oldIndexes = await storageService.getPropertyByName(LS_ITEMS_ORIGINAL_INDEXES);
    const indexes = oldIndexes || {};
    const itemIndex = indexes[context] ? indexes[context].indexOf(id) : -1;

    if (itemIndex > -1) {
      if (cleanup) {
        await setOriginalIndex(itemIndex);
      }
    } else {
      const newIndexes = await listIndexService.addToOriginalIndexes(id, context);
      const newIndexesArray = newIndexes && newIndexes[context] ? newIndexes[context] : [];
      const newValue = newIndexesArray.length > 0 && newIndexesArray.indexOf(id) > -1 ? newIndexesArray.indexOf(id) : 0;
      if (cleanup) {
        await setOriginalIndex(newValue);
      }
    }
    return () => {
      cleanup = false;
    };
  }, [context, id]);

  useEffect(() => {
    IndexHandler();
  }, [trigger, IndexHandler]);

  return originalIndex;
};

export { useItemOriginalIndex };
