export class StayPermission {
  /** @type {number} */
  id;

  /** @type {number} */
  operatorId;

  /** @type {import("domain/User")} */
  user;

  /** @type {number} */
  userId;

  /** @type {number} */
  vamoosId;

  /** @type {string} */
  vamoosPermissions;

  /** @type {string} */
  createdAt;

  /** @type {string} */
  updatedAt;
}