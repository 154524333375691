import { IconImage } from "components/ui/Icons";
import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import banIcon from "assets/images/ban.png";
import { useListOfLanguages } from "hooks/useListOfLanguages";
import { useSelector } from "react-redux";

const LanguageLockContainer = styled.span`
  background: red;
  border-radius: 8px;
  padding: 8px 16px;
  background-color: ${({ theme }) => theme.colors.withOpacity(theme.colors.grey10, 0.5)};
  display: flex;
  align-items: center;
  width: 280px;
  white-space: normal !important;

  ${({ wide }) =>
    wide &&
    `
    margin: 8px 0 0 0;
    width: 100%;
  `}
`;

const LanguageLockLabel = styled.span`
  margin: 0 0 0 12px;
  font-size: 14px;
  line-height: 18px;
`;

export const LanguageLock = ({ wide, message, style }) => {
  const allLanguages = useListOfLanguages();

  const defaultLanguageName = useSelector(({ stayWizard, operator }) => {
    if (stayWizard.defaultVariant.language) {
      return allLanguages.find(lang => lang.code === stayWizard.defaultVariant.language)?.name;
    }

    return allLanguages.find(lang => lang.code === operator.currentOperator.defaultLanguageCode)?.name;
  });

  return (
    <LanguageLockContainer wide={wide} style={style}>
      <IconImage src={banIcon} alt="Ban icon" />
      <LanguageLockLabel>{message || `This can only be changed in the main (${defaultLanguageName}) version`}</LanguageLockLabel>
    </LanguageLockContainer>
  );
};

LanguageLock.defaultProps = {
  wide: false,
  defaultLanguage: {},
};

LanguageLock.propTypes = {
  wide: PropTypes.bool,
  defaultLanguage: PropTypes.shape({}),
};
