import React from "react";
import AddIcon from "@material-ui/icons/Add";
import { useDispatch, useSelector } from "react-redux";

import { PanelTemplate } from "components/templates/Panel/PanelTemplate";
import { ContextBarTitle } from "components/templates/Panel/ContextBarTitle";
import { ACCOUNT_SETTINGS, OPERATOR_TYPE } from "constants/content";
import { PrimaryButton } from "components/ui/Buttons";

import { Settings } from "feature/panel/UserSettings/Settings";
import { TableOfApiKeys } from "feature/panel/UserSettings/TableOfApiKeys";
import { TableOfOperators } from "feature/panel/UserSettings/TableOfOperators";

import { createApiKeyStart } from "store/app/actions";
import { AdminTemplate } from "components/templates/Admin/AdminTemplate";

const UserSettings = () => {
  const currentOperatorCode = useSelector(state => state.auth?.currentOperatorCode);
  const isAdminOperator = useSelector(
    state => state.auth?.operators?.find(operator => operator.code === currentOperatorCode)?.type === OPERATOR_TYPE.admin,
  );
  const dispatch = useDispatch();
  const handleCreateApiKey = () => {
    dispatch(createApiKeyStart());
  };

  const contextBar = {
    left: () => null,
    middle: () => <ContextBarTitle title={ACCOUNT_SETTINGS.title} />,
    right: () => (
      <PrimaryButton onClick={handleCreateApiKey}>
        <AddIcon />
        <span>{ACCOUNT_SETTINGS.generateApiKey}</span>
      </PrimaryButton>
    ),
  };

  const WrapperTemplate = isAdminOperator ? AdminTemplate : PanelTemplate;

  return (
    <WrapperTemplate contextBar={contextBar}>
      <Settings />
      <TableOfApiKeys />
      <TableOfOperators />
    </WrapperTemplate>
  );
};

export { UserSettings };
