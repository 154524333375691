// @ts-check

/**
 * @param {import("types/dto").ItineraryNotificationResponseDto} notificationDto  feature stay object
 * @returns {ItineraryNotification} Feature stay item
 */

export function NotificationItineraty(notificationDto) {
  /**
   * @type {ItineraryNotification}
   */
  const domain = {};

  domain.content = notificationDto.content;
  domain.createdAt = notificationDto.created_at;
  domain.deliveryAt = notificationDto.delivery_at;
  domain.deliveryAtDays = notificationDto.delivery_at_days;
  domain.deliveryAtRelativeTo = notificationDto.delivery_at_relative_to;
  domain.details = notificationDto.details;
  domain.id = notificationDto.id;
  domain.inspirationId = notificationDto.inspiration_id;
  domain.inspirationVamoosId = notificationDto.inspiration_vamoos_id;
  domain.isActive = notificationDto.is_active;
  domain.itineraryId = notificationDto.itinerary_id;
  domain.latitude = notificationDto.latitude;
  domain.location = notificationDto.location;
  domain.locationId = notificationDto.location_id;
  domain.locationInternalId = notificationDto.location_internal_id;
  domain.longitude = notificationDto.longitude;
  domain.startAt = notificationDto.start_at;
  domain.endAt = notificationDto.end_at;
  domain.templateId = notificationDto.template_id;
  domain.type = notificationDto.type;
  domain.updatedAt = notificationDto.updated_at;
  domain.url = notificationDto.url;

  return domain;
}
