import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Grid from "@material-ui/core/Grid";
import ImageOutlinedIcon from "@material-ui/icons/ImageOutlined";

import { ChipWithRedirect } from "components/ui/Chips/ChipWithRedirect";
import { AttachFilesButton } from "components/ui/Buttons/AttachFilesButton";
import { Content } from "components/ui/Content";
import { BodyText2 } from "components/ui/Typography/Typography";
import { ImagePlaceholder, ImageLogo } from "components/ui/Images";
import { PANEL_TRIPS_CREATE_TEXTS } from "constants/content";
import { formatDisplayableLibraryPopupValues, getFilePreviewUrl } from "utils/library";
import { IMAGES_MIME_TYPES } from "constants/defaults";
import { DEFAULT_LIBRARY_TABS } from "components/ui/LibraryPopup/LibraryPopup";
import { LOGO_FIELD } from "feature/panel/_shared/defaultFieldNames";

const Flex = styled.div`
  display: flex;
`;

const ImageSection = styled.div`
  margin-right: ${({ theme }) => theme.setSpacing(6)}px;
`;

const ContentSection = styled.div``;

const CustomLogo = ({ logo, contextValueSetter, disabled }) => {
  const handleUploadCustomLogo = files => {
    const [newFile] = formatDisplayableLibraryPopupValues(files);
    // TODO: Need to be refactored when trips will use Redux instead of context
    contextValueSetter(LOGO_FIELD, newFile);
  };

  const handleDeleteCustomLogo = () => {
    // TODO: Need to be refactored when trips will use Redux instead of context
    contextValueSetter(LOGO_FIELD, null);
  };

  const renderFileDeleter = () => (
    <ChipWithRedirect disabled={disabled} icon={<ImageOutlinedIcon />} onDelete={handleDeleteCustomLogo} item={logo} />
  );

  const renderFileUploader = () => (
    <AttachFilesButton
      size="small"
      name="upload"
      onSelect={handleUploadCustomLogo}
      allowedFileTypes={IMAGES_MIME_TYPES}
      tabs={Object.values(DEFAULT_LIBRARY_TABS).splice(0, 2)}
      disabled={disabled}
    />
  );

  const logoSource = !Object.keys(logo).length ? null : logo;
  return (
    <Grid container>
      <Grid item xs={12}>
        <Flex>
          <ImageSection>
            {logoSource ? <ImageLogo src={getFilePreviewUrl(logoSource)} /> : <ImagePlaceholder width="175px" height="175px" />}
          </ImageSection>
          <ContentSection>
            <Content margin={4}>{logoSource ? renderFileDeleter() : renderFileUploader()}</Content>
            <BodyText2>
              {PANEL_TRIPS_CREATE_TEXTS.preview.line1}
              <br />
              {PANEL_TRIPS_CREATE_TEXTS.preview.line2}
              <br />
              {PANEL_TRIPS_CREATE_TEXTS.preview.line3}
            </BodyText2>
          </ContentSection>
        </Flex>
      </Grid>
    </Grid>
  );
};

CustomLogo.propTypes = {
  logo: PropTypes.shape().isRequired,
  contextValueSetter: PropTypes.func,
  disabled: PropTypes.bool,
};

CustomLogo.defaultProps = {
  contextValueSetter: Function,
  disabled: false,
};

export { CustomLogo };
