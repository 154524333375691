import * as actionTypes from "store/branding/actionTypes";
import { reducer, setState, globalReducerActions } from "utils/store";

const brandingInitState = {
  errors: {},
  finished: false,
  inProgress: false,
  actionType: null,
  isFormTouched: false,
  brandingList: [],
  brandingForm: {
    id: null,
    name: null,
    logo: null,
    creationsHeader: null,
    creationsFooter: null,
    isActive: true,
  },
};

const ReducerActions = globalReducerActions(
  "branding",
  { ...brandingInitState },
  {
    [actionTypes.GET_BRANDING_LIST_START](state) {
      return setState(state, {
        actionType: actionTypes.GET_BRANDING_LIST_START,
        inProgress: true,
        finished: false,
      });
    },
    [actionTypes.GET_BRANDING_LIST_SUCCESS](state, payload) {
      return setState(state, {
        inProgress: false,
        finished: true,
        brandingList: payload,
      });
    },
    [actionTypes.GET_SELECTED_BRANDING_DATA_START](state) {
      return setState(state, {
        actionType: actionTypes.GET_SELECTED_BRANDING_DATA_START,
        inProgress: true,
        finished: false,
      });
    },
    [actionTypes.GET_SELECTED_BRANDING_DATA_SUCCESS](state, payload) {
      return setState(state, {
        inProgress: false,
        finished: true,
        brandingForm: { ...payload },
      });
    },
    [actionTypes.CREATE_NEW_BRAND_START](state) {
      return setState(state, {
        actionType: actionTypes.CREATE_NEW_BRAND_START,
        inProgress: true,
        finished: false,
      });
    },
    [actionTypes.CREATE_NEW_BRAND_SUCCESS](state) {
      return setState(state, {
        inProgress: false,
        finished: true,
      });
    },
    [actionTypes.CREATE_NEW_BRAND_FAIL](state, payload) {
      return setState(state, {
        inProgress: false,
        finished: false,
        errors: { ...payload },
      });
    },
    [actionTypes.SET_VALUE_FOR_CREATION_FOOTER_START](state) {
      return setState(state, {
        actionType: actionTypes.SET_VALUE_FOR_CREATION_FOOTER_START,
        inProgress: true,
        finished: false,
        errors: {},
      });
    },
    [actionTypes.SET_VALUE_FOR_CREATION_HEADER_START](state) {
      return setState(state, {
        actionType: actionTypes.SET_VALUE_FOR_CREATION_HEADER_START,
        inProgress: true,
        finished: false,
        errors: {},
      });
    },
    [actionTypes.SET_VALUE_FOR_LOGO_START](state) {
      return setState(state, { actionType: actionTypes.SET_VALUE_FOR_LOGO_START, inProgress: true, finished: false, errors: {} });
    },
    [actionTypes.UPDATE_BRAND_ITEM_SUCCESS](state) {
      return setState(state, { inProgress: false, finished: true });
    },
    [actionTypes.UPDATE_BRAND_ITEM_FAIL](state, payload) {
      return setState(state, { inProgress: false, finished: false, errors: { ...payload } });
    },
    [actionTypes.CLEAR_BRANDING_FORM](state) {
      return setState(state, {
        brandingForm: { ...brandingInitState.brandingForm },
      });
    },
  },
);

export const brandingReducer = (state = brandingInitState, action) => reducer(state, action, ReducerActions);
