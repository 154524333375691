import { objectHasKeys } from "utils/object";
import { LIBRARY_ROOT_PATH } from "constants/api";
import { isObject, isString } from "contracts/types";
import { webUrlValidation } from "./validation";

const EXTENSION_REGEX = /\.\w+$/;

const validateFilePayloadObject = object => {
  const { web_url, file_url, library_node_id, file_id } = object;
  const url = webUrlValidation(web_url || file_url) || (isString(object) && webUrlValidation(object));

  return url || library_node_id || file_id;
};

export const findObjectChildrenBy = (id, array, key) => {
  let selectedElements = [];
  if (Array.isArray(array) && array.length > 0 && id && key) {
    array.forEach(item => {
      if (item[key] === id) {
        selectedElements.push(item);
        selectedElements = [...findObjectChildrenBy(item.id, array, key), ...selectedElements];
      }
    });
  }

  return selectedElements;
};

export const getLibraryPopupFiles = files => {
  const arrayToCompare = ["id", "name", "https_url"];
  return Array.isArray(files) && files.length > 0
    ? files
        .map(file =>
          objectHasKeys(file, arrayToCompare) ? { library_node_id: file.id, file_name: file.name, https_url: file.https_url } : null,
        )
        .filter(Boolean)
    : null;
};

export const getLibraryPopupWebUrl = data => (typeof data === "string" ? { web_url: data, file_name: data } : null);

export const getLibraryPopupData = selectedItem => {
  const { libraryFiles, webpageUrl } = selectedItem;
  return getLibraryPopupWebUrl(webpageUrl) || getLibraryPopupFiles(libraryFiles);
};

export const nameExtensionCut = name => name.replace(EXTENSION_REGEX, "");

export const formatDisplayableLibraryPopupValues = valuesList => {
  if (!Array.isArray(valuesList)) return [];

  return valuesList.map(({ library_node_id, file_id, file_url, web_url, file_name, https_url, preview_url, type, is_library_file }) => {
    const formattedResponse = { file_name: !type ? file_name : nameExtensionCut(file_name), is_library_file: !!is_library_file };
    if (library_node_id) {
      formattedResponse.library_node_id = library_node_id;
      formattedResponse.https_url = https_url;
    }
    if (file_url) {
      formattedResponse.file_url = file_url;
      formattedResponse.preview_url = preview_url;
    }
    if (web_url) {
      formattedResponse.web_url = web_url;
      formattedResponse.file_name = file_name;
    }
    if (file_id) {
      formattedResponse.file_id = file_id;
    }
    if (type) formattedResponse.type = type;

    return formattedResponse;
  });
};

export const formFilePayloadObject = (file, copyToOtherOperator = false, isPublic = false) => {
  if (!isObject(file)) return null;

  const {
    file_url,
    file_id,
    web_url,
    remote_url,
    https_url,
    fileUrl,
    name,
    file_name,
    icon_id,
    s3_url,
    alias_for_id,
    aliasForId,
    is_library_file,
    library_node_id,
  } = file;

  if (copyToOtherOperator && s3_url) return { file_url: s3_url, name: file_name };

  const formattedObject = {
    is_public: isPublic,
  };

  if (file_name || name) formattedObject.name = file_name || name;

  // in case of having a web link
  if (!file_id && !file_url && !fileUrl) {
    formattedObject.web_url = web_url || remote_url || https_url;
    delete formattedObject.is_public;
  }
  // in case of upload new file from the computer disk
  else if (file_url || fileUrl) formattedObject.file_url = file_url || fileUrl;
  // in case of having
  else if (alias_for_id || aliasForId) formattedObject.library_node_id = Number(alias_for_id || aliasForId);
  // In case of uploaded file from library
  else if (!!is_library_file && !copyToOtherOperator && library_node_id) formattedObject.library_node_id = library_node_id;
  // in case of having
  else formattedObject.file_id = file_id;

  if (icon_id) formattedObject.icon_id = icon_id;

  return validateFilePayloadObject(formattedObject) ? formattedObject : null;
};

export const getFilePreviewUrl = file => {
  return file ? file.https_url || file.web_url || file.preview_url || file.previewUrl || file.remote_url : "";
};

export const openPreviewInNewTab = target => {
  window.open(target, "_blank");
};

export const prepareLibraryPathParam = pathFromRoot => `${LIBRARY_ROOT_PATH}${pathFromRoot || ""}/`;

export const bytesToMegaBytes = bytes => (bytes / (1024 * 1024)).toFixed(2);
