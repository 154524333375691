import { CUSTOM_APPS } from "constants/content";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import PropTypes from "prop-types";

import { setValueForInputFieldBgColor, setValueForInputFieldFrameColor, setValueForInputFieldTextColor } from "store/customAppForm/actions";
import { usePermissionsService } from "hooks/usePermissionsService";
import { PERMISSIONS } from "constants/permissions";

import { CustomColorPicker } from "../ColorPicker/CustomColorPicker";
import { CardSection, CardSectionsContainer, ColorPickerWrapper, CustomCard, InputContentWrapper, SectionTitle } from "../sharedComponents";

const StyledCardSection = styled(CardSection)`
  margin: auto 0;
`;

const TextFieldBox = styled.div`
  border-radius: ${({ theme }) => theme.setSpacing(2)}px;
  width: 100%;
  height: 116px;
  background-color: ${({ bgColor }) => bgColor};
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
`;

const InputPreview = styled.div.attrs(({ bgColor, borderColor, textColor }) => ({
  style: {
    backgroundColor: bgColor || "#ffffff",
    color: textColor,
    border: `2px solid ${borderColor || "#ffffff"}`,
  },
}))`
  width: 80%;
  padding: 8px;
  border-radius: 8px;
`;

const PreviewWrapper = styled.div`
  max-width: 320px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 auto;
`;

const TextFieldsProperties = ({ isHistoryVersion }) => {
  const permissionsService = usePermissionsService();

  const dispatch = useDispatch();

  const inputFieldBackgroundColor = useSelector(state => state.customAppForm.customAppForm.colors.inputFieldBackgroundColor);
  const inputFieldFrameColor = useSelector(state => state.customAppForm.customAppForm.colors.inputFieldFrameColor);
  const inputTextColor = useSelector(state => state.customAppForm.customAppForm.colors.inputTextColor);
  const loginBackground = useSelector(state => state.customAppForm.customAppForm.colors.loginBackground);

  const caStatus = useSelector(state => state.customAppForm?.customAppForm?.status);

  const canEdit = permissionsService.can(PERMISSIONS.actions.update, PERMISSIONS.sections.operator, PERMISSIONS.resources.operators);
  const canCreate = permissionsService.can(PERMISSIONS.actions.create, PERMISSIONS.sections.operator, PERMISSIONS.resources.operators);
  const editDisabled = !((caStatus === null && canCreate) || (caStatus === "draft" && canEdit));

  const handleTextFieldBackgroundColor = value => dispatch(setValueForInputFieldBgColor(value));
  const handleTextFieldFrameColor = value => dispatch(setValueForInputFieldFrameColor(value));
  const handleTextFieldTextColor = value => dispatch(setValueForInputFieldTextColor(value));

  const colorPicker = (
    <>
      <ColorPickerWrapper>
        <CustomColorPicker
          color={inputFieldBackgroundColor}
          label={CUSTOM_APPS.elements.textFields.bgColorLabel}
          onColorChange={handleTextFieldBackgroundColor}
          disabled={isHistoryVersion || editDisabled}
          alphaChannel
        />
      </ColorPickerWrapper>
      <ColorPickerWrapper>
        <CustomColorPicker
          color={inputFieldFrameColor}
          label={CUSTOM_APPS.elements.textFields.borderColorLabel}
          onColorChange={handleTextFieldFrameColor}
          disabled={isHistoryVersion || editDisabled}
        />
      </ColorPickerWrapper>
      <ColorPickerWrapper>
        <CustomColorPicker
          color={inputTextColor}
          label={CUSTOM_APPS.elements.textFields.textColorLabel}
          onColorChange={handleTextFieldTextColor}
          disabled={isHistoryVersion || editDisabled}
        />
      </ColorPickerWrapper>
    </>
  );

  const preview = (
    <PreviewWrapper>
      <TextFieldBox bgColor={loginBackground}>
        <InputPreview bgColor={inputFieldBackgroundColor} borderColor={inputFieldFrameColor} textColor={inputTextColor}>
          {CUSTOM_APPS.elements.textFields.inputPresentationLabel}
        </InputPreview>
      </TextFieldBox>
    </PreviewWrapper>
  );

  return (
    <CustomCard>
      <CardSectionsContainer>
        <CardSection>
          <InputContentWrapper>
            <SectionTitle>{CUSTOM_APPS.elements.textFields.title}</SectionTitle>
            {colorPicker}
          </InputContentWrapper>
        </CardSection>
        <StyledCardSection>{preview}</StyledCardSection>
      </CardSectionsContainer>
    </CustomCard>
  );
};

TextFieldsProperties.defaultProps = {
  isHistoryVersion: false,
};

TextFieldsProperties.propTypes = {
  isHistoryVersion: PropTypes.bool,
};

export { TextFieldsProperties };
