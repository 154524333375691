import React, { useContext } from "react";
import PropTypes from "prop-types";
import { SaveOutlined, FileCopyOutlined, SmartphoneOutlined } from "@material-ui/icons";

import { Spinner } from "components/ui/Spinner/Spinner";
import { ActionButton, PrimaryButton } from "components/ui/Buttons";
import { ForceUpdateModal } from "feature/panel/_shared/ForceUpdateModal/ForceUpdateModal";
import { GLOBAL_CONTENT, STAY_WIZARD_CONTENT } from "constants/content";
import { usePermissionsService } from "hooks/usePermissionsService";
import { PERMISSIONS } from "constants/permissions";
import { usePopupHandlers } from "hooks/usePopupHandlers";
import { LanguageContext } from "components/_shared/LanguageSelector/LanguageContext";
import { useDispatch } from "react-redux";
import { StayService } from "services/domain/StayService";
import { useService } from "hooks/useService";
import { pushErrorNotification, pushSuccessNotification } from "store/app/actions";
import { Logger } from "services/application/Logger";

const StaysWizardActions = ({
  onSave,
  showSpinner,
  disabled,
  triggerCopyVamoos,
  forceUpdateDisabled,
  userId,
  vamoosId,
  isEditMode,
  masterLanguage,
  hideUpdate,
}) => {
  const dispatch = useDispatch();
  const permissionsService = usePermissionsService();

  /** @type {StayService} */
  const stayService = useService(StayService);

  const { currentLanguage } = useContext(LanguageContext);

  const canCreate = permissionsService.can(PERMISSIONS.actions.create, PERMISSIONS.sections.vamoosList);
  const canEdit = permissionsService.can(PERMISSIONS.actions.update, PERMISSIONS.sections.vamoosList, vamoosId);
  const canRead = permissionsService.can(PERMISSIONS.actions.read, PERMISSIONS.sections.vamoosList, vamoosId);

  const forceUpdateModal = usePopupHandlers();

  const handleForceUpdate = async (notificationText, isForceUpdate, isSendOnlyToActive, sendToCurrentLanguage) => {
    try {
      await stayService.sendUpdate(
        userId,
        notificationText,
        isForceUpdate,
        isSendOnlyToActive,
        sendToCurrentLanguage ? currentLanguage.code : null,
      );
      dispatch(pushSuccessNotification(STAY_WIZARD_CONTENT.notifications.forceUpdate));
    } catch (e) {
      Logger.debug(e);
      dispatch(pushErrorNotification(STAY_WIZARD_CONTENT.notifications.forceUpdateError));
    } finally {
      forceUpdateModal.close();
    }
  };

  return (
    <>
      {isEditMode && !hideUpdate && canRead && canEdit && (
        <ActionButton mr={6} onClick={forceUpdateModal.open}>
          <SmartphoneOutlined />
          <span>{GLOBAL_CONTENT.update}</span>
        </ActionButton>
      )}
      {isEditMode && canRead && canCreate && (
        <ActionButton mr={6} onClick={triggerCopyVamoos}>
          <FileCopyOutlined />
          <span>{GLOBAL_CONTENT.copy}</span>
        </ActionButton>
      )}
      {(isEditMode ? isEditMode && canRead && canEdit : canCreate) && (
        <PrimaryButton onClick={onSave} disabled={disabled}>
          {!showSpinner ? (
            <>
              <SaveOutlined />
              <span>{GLOBAL_CONTENT.save}</span>
            </>
          ) : (
            <Spinner size={28} cv="white" />
          )}
        </PrimaryButton>
      )}
      {forceUpdateModal.isOpen && (
        <ForceUpdateModal
          configurable
          open={forceUpdateModal.isOpen}
          forceUpdateDisabled={forceUpdateDisabled}
          onCancel={forceUpdateModal.close}
          onConfirm={handleForceUpdate}
          masterLanguage={masterLanguage}
        />
      )}
    </>
  );
};

StaysWizardActions.defaultProps = {
  showSpinner: false,
  disabled: false,
  vamoosId: null,
  masterLanguage: null,
  hideUpdate: false,
  userId: null,
};

StaysWizardActions.propTypes = {
  onSave: PropTypes.func.isRequired,
  triggerCopyVamoos: PropTypes.func.isRequired,
  showSpinner: PropTypes.bool,
  disabled: PropTypes.bool,
  forceUpdateDisabled: PropTypes.bool.isRequired,
  userId: PropTypes.string,
  vamoosId: PropTypes.number,
  isEditMode: PropTypes.bool.isRequired,
  masterLanguage: PropTypes.string,
  hideUpdate: PropTypes.bool,
};

export { StaysWizardActions };
