import moment from "moment";
import uuidv4 from "uuid";

import {
  combineMessagingEmailsList,
  convertToFileObject,
  formDirectoriesListToDisplay,
} from "utils/dataConverters";
import initStaysState from "feature/panel/Stays/_shared/initStaysState";
import { DISPLAY_RULE_TYPES } from "./Passcodes/constants";

export const stayResponseDataMapper = payload => {
  const {
    background,
    logo,
    notifications,
    pois,
    meta,
    passcode_groups,
    details,
    directories,
    documents,
    locations,
    inspiration,
    vamoos_id,
    ...restOfPayload
  } = payload;
  const {
    messaging_email_during_days_before,
    messaging_email_during_days_after,
    messaging_email_during,
    messaging_email_before_after,
  } = meta;

  const location = locations[0] ? locations[0].location : "";
  const payloadToReturn = {
    ...restOfPayload,
    locations,
    pois: pois.map(({ id, is_on }) => ({ id, is_on })),
    notifications: notifications.map(({ location_id, ...notification }) => ({
      ...notification,
      location_internal_id: location_id,
    })),
    background: background ? convertToFileObject(background) : background,
    logo: logo ? convertToFileObject(logo) : logo,
    directories: formDirectoriesListToDisplay(directories),
    documents: {
      travel:
        documents.travel && documents.travel.length
          ? documents.travel.map(document => ({ ...document, ...convertToFileObject(document) }))
          : [],
      destination:
        documents.destination && documents.destination.length
          ? documents.destination.map(document => ({ ...document, ...convertToFileObject(document) }))
          : [],
    },
    details: details.map(detail => ({
      ...detail,
      image: detail.image ? convertToFileObject(detail.image) : undefined,
    })),
    passcode_groups: passcode_groups.map(group => ({
      ...group,
      id: uuidv4(),
      display_rules: {
        ...group.display_rules,
        show_messaging: group.display_rules.show_messaging || DISPLAY_RULE_TYPES.always,
      },
      passcodes: group.passcodes.map(passcode => ({ ...passcode, id: uuidv4() })),
    })),
    meta: {
      ...initStaysState.meta,
      ...meta,
      address: meta.address && meta.address.length > 0 ? meta.address : location,
      stays_features: JSON.stringify(meta.stays_features),
      messaging_emails_list: combineMessagingEmailsList(messaging_email_during, messaging_email_before_after),
      messaging_email_during_days_before: messaging_email_during_days_before || "0",
      messaging_email_during_days_after: messaging_email_during_days_after || "0",
      require_personal_details: Boolean(Number(meta.require_personal_details)),
    },
    vamoos_id,
  };
  if (meta.dnd_cut_off_time) {
    const time = `${new Date().toISOString().substring(0, 10)} ${meta.dnd_cut_off_time}`;
    payloadToReturn.meta.dnd_cut_off_time = moment(time)
      .toDate()
      .toISOString();
  }
  if (inspiration && inspiration.vamoos_id) payloadToReturn.inspiration_vamoos_id = inspiration.vamoos_id;
  return payloadToReturn;
};
