import { FILE_REFRESH_PATH } from "constants/api";
import { HttpClient } from "services/application/httpClient/httpClient";

export class UrlRefreshService {  
  static refresh(url) {
    if (!url) {
      return Promise.resolve(url);
    }

    const expirationTimestamp = UrlRefreshService.getExpirationTimestampFromUrl(url);
    
    if (!expirationTimestamp) {
      return Promise.resolve(url);
    }

    const now = Number(
      new Date()
        .getTime()
        .toString()
        .slice(0, 10),
    );

    const isExpired = now - Number(expirationTimestamp) > 0;

    if (!isExpired) {
      return Promise.resolve(url);
    }

    return HttpClient.post(FILE_REFRESH_PATH, { urls: [url] }).then(({ data }) => Promise.resolve(data[0]));
  }

  static getExpirationTimestampFromUrl = url => {
    const { searchParams } = new URL(url);
    return searchParams.get("Expires");
  }
}