import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import AddIcon from "@material-ui/icons/Add";
import Grid from "@material-ui/core/Grid";

import { StickyHeaderFormSection } from "components/ui/Forms";
import { SecondaryButton } from "components/ui/Buttons";
import { NoResultsMessage } from "components/ui/Messages/NoResultsMessage";
import { SortableList } from "components/ui/Lists";

import { PasscodeCategory } from "feature/panel/Stays/_shared/Passcodes/PasscodeCategory";
import useActiveItemId from "hooks/useActiveItemId";

import { PANEL_STAYS_EDIT_PATH } from "constants/routes";
import { setUrl } from "utils/url";

import { getHash } from "utils";
import {
  CREATE_CONTENT_LABELS,
  EMPTY_LIST_MESSAGES_BASE,
  GLOBAL_CONTENT,
  NAMES_OF_RESOURCES_LISTS,
  PASSCODES_LABELS,
} from "constants/content";
import {
  stayWizardAddPasscodesItem,
  stayWizardEditPasscodesItem,
  stayWizardRemovePasscodesItem,
  stayWizardSortPasscodes,
} from "store/stayWizard/actions";
import { usePermissionsService } from "hooks/usePermissionsService";
import { PERMISSIONS } from "constants/permissions";
import { LanguageContext } from "components/_shared/LanguageSelector/LanguageContext";
import { LanguageLock } from "../LanguageLock";
import { DISPLAY_RULE_TYPES } from "./constants";

export const initPasscodeCategory = () => ({
  id: getHash(),
  name: "",
  passcodes: [],
  display_rules: {
    show_directory: DISPLAY_RULE_TYPES.always,
    show_messaging: DISPLAY_RULE_TYPES.always,
    show_daily: DISPLAY_RULE_TYPES.always,
    show_dnd: DISPLAY_RULE_TYPES.during,
    show_vouchers: DISPLAY_RULE_TYPES.always,
  },
  isNewItem: true,
});

const Passcodes = () => {
  const permissionsService = usePermissionsService();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { currentLanguage } = useContext(LanguageContext);

  const isStayDefaultLanguage = useSelector(({ stayWizard }) =>
    stayWizard.defaultVariant.language ? stayWizard.defaultVariant.language === currentLanguage.code : true,
  );

  const passcodes = useSelector(({ stayWizard }) =>
    isStayDefaultLanguage ? stayWizard.form.passcodes : stayWizard.defaultVariant.passcodes,
  );
  const [expandedPasscodeId, setExpandedPasscodeId] = useActiveItemId();

  const isWiped = useSelector(({ stayWizard }) => stayWizard.form.isWiped);
  const userId = useSelector(({ stayWizard }) => stayWizard.form.userId);
  const vamoosId = useSelector(({ stayWizard }) => stayWizard.form.vamoosId);
  const editMode = useSelector(({ stayWizard }) => stayWizard.form.vamoosId !== null);

  const canEdit = permissionsService.can(PERMISSIONS.actions.update, PERMISSIONS.sections.vamoosList, vamoosId);

  const handleSectionAdd = () => {
    const newPasscodeGroup = initPasscodeCategory();
    dispatch(stayWizardAddPasscodesItem(newPasscodeGroup));
    setExpandedPasscodeId(newPasscodeGroup.id);
  };

  const handlePasscodeUpdate = passcode => {
    dispatch(stayWizardEditPasscodesItem(passcode));
  };

  const handlePasscodeDelete = passcodeId => {
    dispatch(stayWizardRemovePasscodesItem(passcodeId));
  };

  const onSortEndHandler = ({ oldIndex, newIndex }) => {
    dispatch(stayWizardSortPasscodes({ oldIndex, newIndex }));
  };

  useEffect(() => {
    if (!isStayDefaultLanguage) {
      const url = setUrl(PANEL_STAYS_EDIT_PATH, { operator_code: userId }, true);
      navigate(url);
    }
  }, [isStayDefaultLanguage]);

  const AddPasscodeItemButton = () =>
    !isWiped &&
    isStayDefaultLanguage &&
    (!editMode || canEdit) && (
      <SecondaryButton onClick={handleSectionAdd}>
        <AddIcon />
        {GLOBAL_CONTENT.add}
      </SecondaryButton>
    );

  const emptyListMessage = (editMode ? (
    canEdit
  ) : (
    true
  )) ? (
    <NoResultsMessage height="calc(100vh - 400px)">
      {EMPTY_LIST_MESSAGES_BASE(CREATE_CONTENT_LABELS.add, NAMES_OF_RESOURCES_LISTS.passcodeGroup)}
    </NoResultsMessage>
  ) : null;

  const renderSortableList = () => (
    <SortableList lockAxis="y" onSortEnd={onSortEndHandler} lockToContainerEdges useDragHandle>
      {passcodes.map((group, index) => (
        <PasscodeCategory
          key={group.id}
          category={group}
          index={index}
          isActive={group.id === expandedPasscodeId}
          onToggleActive={setExpandedPasscodeId}
          onCategoryChange={handlePasscodeUpdate}
          onCategoryDelete={handlePasscodeDelete}
          disabled={isWiped || (editMode && !canEdit) || !isStayDefaultLanguage}
        />
      ))}
    </SortableList>
  );

  const content = passcodes.length ? renderSortableList() : emptyListMessage;

  return (
    <Grid container justifyContent="center">
      <Grid xs={12} md={10} item>
        <StickyHeaderFormSection
          title={
            <>
              {PASSCODES_LABELS.listTitle}
              {!isStayDefaultLanguage && <LanguageLock defaultLanguage={{}} />}
            </>
          }
          headerActions={AddPasscodeItemButton}
        >
          {content}
        </StickyHeaderFormSection>
      </Grid>
    </Grid>
  );
};

export { Passcodes };
