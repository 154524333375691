import React, { useState, useEffect, useCallback, useRef } from "react";
import PropTypes from "prop-types";
import lodash from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { ConfirmationModal } from "components/ui/Modals/ConfirmationModal";
import { BodyText2 } from "components/ui/Typography/Typography";
import { Content } from "components/ui/Content";
import { Input } from "components/ui/Forms";

import { CopyingTripModalList } from "feature/panel/Trips/Index/CopyingTripModal/CopyingTripModalList";
import { UserIdField } from "feature/panel/_shared/UserIdField/UserIdField";
import { OperatorField } from "feature/panel/_shared/OperatorField/OperatorField";
import { UnsavedChangesMessage } from "feature/panel/_shared/CopyItinerary/UnsavedChangesMessage";

import { useDefaultReducer } from "hooks/useDefaultReducer";

import { copyTripStart, checkIsPasscodeAvailableStart } from "store/trips/actions";
import { clearActionFlags, clearErrorsFor } from "store/app/actions";
import { CREATE_OR_UPDATE_TRIP_FAIL, COPY_TRIP_SUCCESS } from "store/trips/actionTypes";

import { PANEL_TRIPS_EDIT_PATH } from "constants/routes";

import { setUrl } from "utils/url";
import { DEFAULT_DEBOUNCE_DELAY } from "constants/defaults";
import { COPYING_TRIP_MODAL, COPY_TRIP, CREATE_A_COPY, GLOBAL_CONTENT } from "constants/content";

const defaultSections = {
  travelDocuments: "travelDocuments",
  destinationDocuments: "destinationDocuments",
  locations: "locations",
  storyboard: "storyboard",
  storyboardDocuments: "storyboardDocuments",
  travelPeople: "travelPeople",
  notifications: "notifications",
  flights: "flights",
  creations: "creations",
  inspiration: "inspiration",
};

const initialState = {
  [defaultSections.travelDocuments]: true,
  [defaultSections.destinationDocuments]: true,
  [defaultSections.locations]: true,
  [defaultSections.storyboard]: true,
  [defaultSections.storyboardDocuments]: true,
  [defaultSections.travelPeople]: false,
  [defaultSections.notifications]: true,
  [defaultSections.flights]: false,
  [defaultSections.creations]: false,
  [defaultSections.inspiration]: true,
};

const sections = [
  { label: COPYING_TRIP_MODAL.travelDocuments, key: defaultSections.travelDocuments },
  { label: COPYING_TRIP_MODAL.destinationDocuments, key: defaultSections.destinationDocuments },
  { label: COPYING_TRIP_MODAL.locations, key: defaultSections.locations },
  { label: COPYING_TRIP_MODAL.storyboard, key: defaultSections.storyboard },
  { label: COPYING_TRIP_MODAL.people, key: defaultSections.travelPeople },
  { label: COPYING_TRIP_MODAL.notifications, key: defaultSections.notifications },
  { label: COPYING_TRIP_MODAL.flights, key: defaultSections.flights },
  // { label: "Creations", key: defaultSections.creations },
  { label: COPYING_TRIP_MODAL.inspirations, key: defaultSections.inspiration },
];

const CopyingTripModal = ({ open, onCancel, item, hasUnsavedChanges, showSpinner, onSave }) => {
  const reduxDispatch = useDispatch();
  const navigate = useNavigate();

  const { field1, reference_code, operator_code } = item;

  const title = COPY_TRIP(field1 || reference_code);
  const { state, dispatch, setValueFor } = useDefaultReducer(initialState);

  const { finished, actionType, errors, inProgress } = useSelector(store => store.trips);
  const operators = useSelector(store => store.auth.operators);
  const currentOperatorCode = useSelector(store => store.auth.currentOperatorCode);

  const [passcode, setPasscode] = useState(null);
  const [userId, setUserId] = useState(operator_code);
  const [selectedOperator, setOperator] = useState(currentOperatorCode);

  const isOtherOperatorSelected = selectedOperator !== currentOperatorCode;

  const hasCopyProcessFailed = isOtherOperatorSelected && errors !== {} && actionType === CREATE_OR_UPDATE_TRIP_FAIL;

  const checkPasscodeValidation = useRef(
    lodash.debounce(
      (passcodeParam, userIdParam) => reduxDispatch(checkIsPasscodeAvailableStart({ passcode: passcodeParam, userId: userIdParam })),
      DEFAULT_DEBOUNCE_DELAY,
    ),
  );

  const handleChangePasscode = ({ target }) => {
    setPasscode(target.value);
    checkPasscodeValidation.current(target.value, userId);
  };

  const handleChangeUserId = ({ target }) => {
    setUserId(target.value);
  };

  const handleOperatorChange = ({ target }) => {
    setOperator(target.value);
  };

  const handleChange = key => {
    setValueFor(key, !state[key]);
  };

  const resetState = useCallback(() => {
    dispatch({
      type: "resetAllValues",
    });
  }, [dispatch]);

  const resolveStorbyboardSection = elementsToTransfer => {
    let newList = elementsToTransfer;
    const [sb, sbd] = ["storyboard", "storyboardDocuments"];
    const hasSb = newList.includes(sb);
    const hasSbd = newList.includes(sbd);
    if (hasSb && hasSbd) {
      newList = newList.filter(el => el !== sb);
    } else if (!hasSb && hasSbd) {
      newList = newList.filter(el => el !== sbd);
    }
    return newList;
  };

  const handleConfirm = () => {
    const elementsToTransfer = Object.entries(state).reduce((acc, [key, value]) => {
      if (value) {
        return [...acc, key];
      }
      return acc;
    }, []);
    reduxDispatch(
      copyTripStart({
        sections: resolveStorbyboardSection(elementsToTransfer),
        passcode: passcode.trim(),
        oldPasscode: reference_code,
        oldUserId: operator_code,
        userId: userId.trim(),
        copyToOtherOperator: isOtherOperatorSelected,
        operator: selectedOperator,
      }),
    );
  };

  const handleCancelOrClose = () => {
    if (!hasCopyProcessFailed) {
      resetState();
    }
    onCancel();
    setPasscode("");
  };

  const clearFlags = () => {
    if (!hasCopyProcessFailed) {
      reduxDispatch(clearErrorsFor("trips"));
    }
    reduxDispatch(clearActionFlags("trips"));
    setOperator(currentOperatorCode);
  };

  const redirectToCreatedTrip = () => {
    if (finished && actionType === COPY_TRIP_SUCCESS && open) {
      clearFlags();
      handleCancelOrClose();
      if (!isOtherOperatorSelected) {
        navigate(setUrl(PANEL_TRIPS_EDIT_PATH, { operator_code: userId, reference_code: passcode }));
      }
      setPasscode(null);
      dispatch({ type: "resetAllValues" });
    }
  };

  const setupUserIdField = () => {
    if (operator_code) {
      setUserId(operator_code);
    }
  };

  const displayOperatorSelect = () => (
    <>
      {operators && operators.length > 1 && (
        <Content>
          <OperatorField
            name="operatorCode"
            label={GLOBAL_CONTENT.operator}
            onChange={handleOperatorChange}
            vamoosId={item?.vamoosId || null}
          />
        </Content>
      )}
    </>
  );

  useEffect(redirectToCreatedTrip, [finished, actionType]);
  useEffect(setupUserIdField, [reference_code, operator_code]);
  useEffect(clearFlags, [open]);

  return open ? (
    <ConfirmationModal
      open={open}
      onCancel={handleCancelOrClose}
      onConfirm={handleConfirm}
      title={title}
      fullWidth
      maxWidth="sm"
      confirmLabel={CREATE_A_COPY}
      confirmDisabled={!passcode || !!errors.passcode}
      showSpinner={inProgress}
    >
      {hasUnsavedChanges ? (
        <UnsavedChangesMessage showSpinner={showSpinner} onSave={onSave} />
      ) : (
        <>
          {displayOperatorSelect()}
          <Content>
            <UserIdField
              name="userId"
              value={userId || operator_code || ""}
              label={`${GLOBAL_CONTENT.userId}*`}
              onChange={handleChangeUserId}
              selectedOperator={selectedOperator}
            />
          </Content>
          <Content>
            <Input
              name="passcode"
              value={passcode || ""}
              label={`${GLOBAL_CONTENT.passcode}*`}
              onChange={handleChangePasscode}
              error={!!errors.passcode}
              helperText={errors.passcode || ""}
            />
          </Content>
          <BodyText2>{GLOBAL_CONTENT.include}</BodyText2>
          <CopyingTripModalList sections={sections} state={state} onChange={handleChange} />
        </>
      )}
    </ConfirmationModal>
  ) : null;
};

CopyingTripModal.defaultProps = {
  hasUnsavedChanges: false,
  showSpinner: false,
  onSave: () => {},
};

CopyingTripModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  item: PropTypes.shape().isRequired,
  hasUnsavedChanges: PropTypes.bool,
  showSpinner: PropTypes.bool,
  onSave: PropTypes.func,
};

export { CopyingTripModal };
