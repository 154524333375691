import styled from "styled-components";
import React from "react";
import { useRefreshedFileUrl } from "hooks/useRefreshedFileUrl";

const Image = styled.img`
  max-width: 175px;
  max-height: 175px;
  border-radius: ${({ theme }) => theme.borderRadius}px;
`;

const ImageLogo = ({ src }) => {
  const url = useRefreshedFileUrl(src);

  return url && <Image src={url} />;
};

export { ImageLogo };
