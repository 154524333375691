import { takeEvery, put } from "redux-saga/effects";
import * as actionTypes from "store/adminUserDetails/actionTypes";

import { setNotification } from "store/app/actions";
import { checkEmailIsTaken, checkUsernameIsTaken } from "store/auth/saga";
import {
  getAdminEmailUniquenessFail,
  getAdminUsernameUniquenessFail,
  getUserDetailsFail,
  getUserDetailsSuccess,
  updateAdminUserDetailsFail,
  updateAdminUserDetailsSuccess,
} from "store/adminUserDetails/actions";

import { GET_ADMIN_USER_DETAILS_URL, FORGOT_PASSWORD_URL } from "constants/api";
import notifications from "constants/notifications";
import { HttpClient } from "services/application/httpClient/httpClient";
import { setUrl } from "utils/url";
import { handleErrorMessages } from "utils/store";
import { PASSWORD_RESET } from "constants/content";
import { UserMapper } from "mappers/UserMapper";

function* getUserDetails({ payload }) {
  try {
    const userMapper = new UserMapper();

    const url = setUrl(GET_ADMIN_USER_DETAILS_URL, { id: payload });
    const response = yield HttpClient.get(url);

    if (response?.data) {
      const user = userMapper.fromDtoToDomain(response?.data);

      yield put(getUserDetailsSuccess(user));
    }
  } catch (e) {
    yield put(getUserDetailsFail({ errors: handleErrorMessages(e) }));
    yield put(setNotification({ type: "error", message: notifications.resource("user").get.fail }));
  }
}

function* resetAdminUserPassword({ payload }) {
  const { email } = payload;
  try {
    const response = yield HttpClient.post(FORGOT_PASSWORD_URL, { email });
    if (response) yield put(setNotification({ type: "success", message: PASSWORD_RESET.sendSuccess }));
  } catch {
    yield put(setNotification({ type: "error", message: PASSWORD_RESET.noAssociationError }));
  }
}

function* updateUserDetails({ payload }) {
  const { id, userRequestDto } = payload;
  try {
    const url = setUrl(GET_ADMIN_USER_DETAILS_URL, { id });
    const response = yield HttpClient.post(url, userRequestDto);
    if (response?.data) {
      yield put(updateAdminUserDetailsSuccess());
      yield put(setNotification({ type: "success", message: notifications.resource("user").update.success }));
    }
  } catch (e) {
    yield put(updateAdminUserDetailsFail({ errors: handleErrorMessages(e) }));
    yield put(setNotification({ type: "error", message: notifications.resource("user").update.fail }));
  }
}

function* checkAdminEmailUniqueness({ payload }) {
  const isTaken = yield checkEmailIsTaken({ email: payload });

  if (isTaken) yield put(getAdminEmailUniquenessFail());
}

function* checkAdminUsernameUniqueness({ payload }) {
  const isTaken = yield checkUsernameIsTaken({ username: payload });

  if (isTaken) yield put(getAdminUsernameUniquenessFail());
}

export function* adminUserDetailsSaga() {
  yield takeEvery(actionTypes.GET_USER_DETAILS_START, getUserDetails);
  yield takeEvery(actionTypes.UPDATE_ADMIN_USER_DETAILS_START, updateUserDetails);
  yield takeEvery(actionTypes.RESET_ADMIN_USER_DETAILS_PASSWORD, resetAdminUserPassword);
  yield takeEvery(actionTypes.CHECK_ADMIN_EMAIL_UNIQUENES_START, checkAdminEmailUniqueness);
  yield takeEvery(actionTypes.CHECK_ADMIN_USERNAME_UNIQUENES_START, checkAdminUsernameUniqueness);
}
