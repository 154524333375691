import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import Grid from "@material-ui/core/Grid";

import { Input } from "components/ui/Forms";
import { Content } from "components/ui/Content";
import { Message } from "components/ui/Messages/Message";

import { ACCOUNT_SETTINGS, ERRORS, GLOBAL_CONTENT, USER_WITHOUT_OPERATOR_MESSAGE } from "constants/content";

import { Paper, ContentWrapper } from "feature/panel/Help/styledComponents";
import { InformationField } from "feature/panel/Settings/_shared/InformationField";
import { FieldChangeModal } from "feature/panel/Settings/_shared/FieldChangeModal";

import { editCurrentUserStart, getCurrentUserStart, resetUserActionType } from "store/auth/actions";
import { EDIT_CURRENT_USER_SUCCESS, GET_CURRENT_USER_START } from "store/auth/actionTypes";

import { isObjectEmpty } from "utils/object";
import { emailValidate } from "utils/validation";
import { firstLetterUpperCase } from "utils/string";
import { Spinner } from "../../../components/ui/Spinner/Spinner";
import { tryRouteChangeStart } from "../../../store/app/actions";
import { PANEL_TRIPS_ROOT_PATH } from "../../../constants/routes";

const validateAccountSettingsForm = ({ key, value }, oldPassword) => {
  const errorsList = {};
  if (key === ACCOUNT_SETTINGS.username.key && !value) errorsList.value = ERRORS.isRequired("Username");
  if (key === ACCOUNT_SETTINGS.password.key && !value) errorsList.value = ERRORS.isRequired("Password");
  if (key === ACCOUNT_SETTINGS.password.key && !oldPassword) errorsList.oldPasswordValue = ERRORS.isRequired("Old password");
  if (key === ACCOUNT_SETTINGS.email.key && !value) errorsList.value = ERRORS.isRequired("Email");
  if (key === ACCOUNT_SETTINGS.email.key && !emailValidate(value)) errorsList.value = ERRORS.invalidEmail;
  return errorsList;
};

const LoaderWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  background: #ffffffd4;
  z-index: 1;
  padding-top: 40px;
`;

const Loader = () => {
  return (
    <LoaderWrapper>
      <Spinner size={42} cv="brand" />
    </LoaderWrapper>
  );
};

export const Button = styled("button")`
  text-decoration: underline;
  cursor: pointer;
  background: transparent;
  border: 0;
  font-family: ${({ theme }) => theme.fonts.font};
  font-size: inherit;
  color: #237da9;
`;

const Settings = () => {
  const [editSettingModal, setEditSettingsModal] = useState({ open: false, body: { key: "", value: "" } });
  const [oldPasswordValue, setOldPasswordValue] = useState("");
  const [errors, setErrors] = useState({});
  const { user, actionType, operators } = useSelector(state => state.auth);
  const showLoader = useSelector(state => state.auth.inProgress && state.auth.actionType === GET_CURRENT_USER_START);
  const { open, body } = editSettingModal;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const toggleEditModal = field => {
    const newBody = open ? editSettingModal.body : field;
    setEditSettingsModal({ body: newBody, open: !open });
    setOldPasswordValue("");
    setErrors({});
  };
  const handleEditUsernameStart = () => toggleEditModal({ key: ACCOUNT_SETTINGS.username.key, value: user.username });
  const handleEditEmailStart = () => toggleEditModal({ key: ACCOUNT_SETTINGS.email.key, value: user.email });
  const handleEditPasswordStart = () => toggleEditModal({ key: ACCOUNT_SETTINGS.password.key, value: "" });

  const handleFieldEdit = ({ target: { value } }) =>
    setEditSettingsModal({
      open,
      body: { ...editSettingModal.body, value },
    });
  const handleOldPasswordEdit = ({ target: { value } }) => setOldPasswordValue(value);

  const validateForm = () => {
    const formErrors = validateAccountSettingsForm(body, oldPasswordValue);
    setErrors(formErrors);
    return isObjectEmpty(formErrors);
  };

  const handleEditionConfirm = () => {
    if (validateForm()) {
      const payload = { [body.key]: body.value };
      if (body.key === ACCOUNT_SETTINGS.password.key) payload[ACCOUNT_SETTINGS.oldPassword.key] = oldPasswordValue;
      dispatch(editCurrentUserStart(payload));
    }
  };
  const tryTogglingModalOff = () => {
    if (actionType === EDIT_CURRENT_USER_SUCCESS) {
      toggleEditModal();
      dispatch(resetUserActionType());
    }
  };

  const editPasswordInputs = open && body.key === ACCOUNT_SETTINGS.password.key && (
    <>
      <Content>
        <Input
          type="password"
          value={oldPasswordValue}
          label={ACCOUNT_SETTINGS.oldPassword.label}
          name={ACCOUNT_SETTINGS.oldPassword.key}
          onChange={handleOldPasswordEdit}
          error={!!errors.oldPasswordValue}
          helperText={errors.oldPasswordValue}
        />
      </Content>
      <Input
        type="password"
        value={body.value}
        label={ACCOUNT_SETTINGS.newPasswordLabel}
        name="new-password"
        onChange={handleFieldEdit}
        error={!!errors.value}
        helperText={errors.value}
      />
    </>
  );

  useEffect(tryTogglingModalOff, [actionType]);

  const renderTryAgainButton = () => {
    return (
      <Button type="button" onClick={() => dispatch(getCurrentUserStart("refetch"))}>
        <span>{GLOBAL_CONTENT.tryAgain}</span>
      </Button>
    );
  };
  const loader = showLoader ? <Loader /> : null;

  useEffect(() => {
    if (operators.length === 0) return;
    dispatch(tryRouteChangeStart(PANEL_TRIPS_ROOT_PATH));
    navigate(PANEL_TRIPS_ROOT_PATH);
  }, [operators]);

  return (
    <>
      {operators.length === 0 && (
        <Grid container justifyContent="center">
          <Grid item xs={12} md={6}>
            <Content>
              <Message type="info" text={USER_WITHOUT_OPERATOR_MESSAGE} buttonContent={renderTryAgainButton()} />
              {loader}
            </Content>
          </Grid>
        </Grid>
      )}
      <Paper>
        <ContentWrapper>
          <InformationField title={ACCOUNT_SETTINGS.username.label} content={user.username} onEditClick={handleEditUsernameStart} />
          <InformationField title={ACCOUNT_SETTINGS.email.label} content={user.email} onEditClick={handleEditEmailStart} />
          <InformationField title={ACCOUNT_SETTINGS.password.label} onEditClick={handleEditPasswordStart} />
        </ContentWrapper>
      </Paper>
      <FieldChangeModal
        open={open}
        label={firstLetterUpperCase(body.key || "")}
        value={body.value || ""}
        onValueEdit={handleFieldEdit}
        onConfirm={handleEditionConfirm}
        onCancel={toggleEditModal}
        inputProps={{ error: !!errors.value, helperText: errors.value }}
      >
        {editPasswordInputs}
      </FieldChangeModal>
    </>
  );
};

export { Settings };
