import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { ConfirmationModal } from "components/ui/Modals/ConfirmationModal";
import { Table, TableHeader, TableBody } from "components/ui/Tables";
import { DeleteAction, TableActionsContainer } from "components/ui/Tables/TableRowActions";
import { NoResultsMessage } from "components/ui/Messages/NoResultsMessage";

import listColumns from "feature/panel/Trips/_shared/Flights/listColumns";
import { useManageTripContext } from "feature/panel/Trips/_shared/ManageTripContext";
import { FlightDetailsModal } from "feature/panel/Trips/_shared/Flights/FlightDetailsModal";
import { FlightsListRow } from "feature/panel/Trips/_shared/Flights/FlightsListRow";

import {
  CREATE_CONTENT_LABELS,
  EMPTY_LIST_MESSAGES_BASE,
  NAMES_OF_RESOURCES_LISTS,
  PANEL_TRIPS_ACTION_MESSAGES,
  TABLE_ACTIONS_TITLES,
} from "constants/content";
import { usePermissionsService } from "hooks/usePermissionsService";
import { PERMISSIONS } from "constants/permissions";

const TableWrapper = styled.div`
  .MuiTable-root {
    .MuiTableBody-root {
      .MuiTableRow-root {
        cursor: pointer;
      }
    }
  }
`;

const flightSortingComparisonDataMapper = (column, object) => {
  const { carrier_fs_code, carrier_flight_number, carrier, departure_airport, arrival_airport, departure_at_utc, arrival_at_utc } =
    object || {};
  const mapper = {
    flight_number: `${carrier_fs_code}${carrier_flight_number}`,
    carrier: carrier ? carrier.name : "",
    departure_airport: departure_airport ? departure_airport.name : "",
    arrival_airport: arrival_airport ? arrival_airport.name : "",
    departure_at_utc,
    arrival_at_utc,
  };
  return mapper[column];
};

const flightSortingComparison = (a, b, column, order) => {
  const elA = String(flightSortingComparisonDataMapper(column, a)).toLowerCase();
  const elB = String(flightSortingComparisonDataMapper(column, b)).toLowerCase();
  const compresionTypes = {
    asc: elA > elB,
    desc: elA < elB,
  };
  return compresionTypes[order] ? 1 : -1;
};

const FlightsListSection = () => {
  const { setValueFor, listOfFlights, vamoos_id, editMode, is_wiped } = useManageTripContext();
  const [deleteModal, setDeleteModal] = useState({ open: false, body: {} });
  const [detailsModal, setDetailsModal] = useState({ open: false, body: {} });

  const permissionsService = usePermissionsService();

  const canEdit = permissionsService.can(PERMISSIONS.actions.update, PERMISSIONS.sections.vamoosList, vamoos_id);

  const handleChangeOrder = ({ column, order }) => {
    const sortedListOfFlights = listOfFlights.sort((a, b) => flightSortingComparison(a, b, column, order));

    setValueFor("listOfFlights", sortedListOfFlights);
  };

  const handleDeleteToggle = flight => {
    const { open } = deleteModal;
    const body = open ? deleteModal.body : flight;
    setDeleteModal({ body, open: !open });
  };

  const handleDeleteConfirm = () => {
    setValueFor(
      "listOfFlights",
      listOfFlights.filter(flight => flight.id !== deleteModal.body.id),
    );
    handleDeleteToggle();
  };

  const RowActions = ({ item: flight }) => (
    <TableActionsContainer>
      {!is_wiped && (editMode ? canEdit : true) && (
        <DeleteAction
          clickAction={event => {
            event.stopPropagation();
            handleDeleteToggle(flight);
          }}
          tooltip={TABLE_ACTIONS_TITLES.deleteResource(TABLE_ACTIONS_TITLES.resourceTypes.flight)}
        />
      )}
    </TableActionsContainer>
  );

  RowActions.propTypes = {
    item: PropTypes.shape().isRequired,
  };

  const handleFlightDetailsToggle = flight => {
    const { open } = detailsModal;
    const body = open ? detailsModal.body : flight;
    setDetailsModal({ body, open: !open });
  };

  const deleteConfirmationText = (() => {
    const { body } = deleteModal;
    const { carrier_fs_code, carrier_flight_number, departure_airport, arrival_airport } = body;
    const { deleteConfirmationBase } = PANEL_TRIPS_ACTION_MESSAGES;
    return carrier_fs_code && carrier_flight_number && departure_airport && arrival_airport
      ? `
        ${deleteConfirmationBase} flight ${carrier_fs_code} ${carrier_flight_number} 
        from ${departure_airport.city} to ${arrival_airport.city}?
      `
      : "";
  })();

  if (!listOfFlights.length && editMode && !canEdit) return null;
  if (!listOfFlights.length && editMode && canEdit)
    return <NoResultsMessage>{EMPTY_LIST_MESSAGES_BASE(CREATE_CONTENT_LABELS.flight, NAMES_OF_RESOURCES_LISTS.flight)}</NoResultsMessage>;

  listOfFlights.sort((a, b) => {
    const dateA = new Date(a.departure_at_utc);
    const dateB = new Date(b.departure_at_utc);
    return dateA - dateB;
  });

  return (
    <TableWrapper>
      <Table
        headers={listColumns}
        list={listOfFlights}
        actions={RowActions}
        onRowClick={handleFlightDetailsToggle}
        onChangeOrder={handleChangeOrder}
      >
        <TableHeader withActions />
        <TableBody rowComponent={FlightsListRow} />
      </Table>

      {deleteModal.open && (
        <ConfirmationModal
          open={deleteModal.open}
          title={deleteConfirmationText}
          confirmLabel="Delete"
          onCancel={handleDeleteToggle}
          onConfirm={handleDeleteConfirm}
        />
      )}
      <FlightDetailsModal open={detailsModal.open} flight={detailsModal.body} onClose={handleFlightDetailsToggle} />
    </TableWrapper>
  );
};

export { FlightsListSection, flightSortingComparisonDataMapper, flightSortingComparison };
