import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import { Table, TableHeader, TableBody, TablePagination } from "components/ui/Tables";
import { SwitchAction, TableActionsContainer } from "components/ui/Tables/TableRowActions";
import { PoisTableRow } from "feature/panel/Trips/_shared/Locations/PoisTab/PoisTableRow/PoisTableRow";
import headers from "feature/panel/Pois/Index/TableOfPois/TableOfPoisColumns.json";

import { LoadingScreen } from "components/ui/LoadingScreen/LoadingScreen";

import { Link } from "components/ui/Links/Links";
import { Message } from "components/ui/Messages/Message";
import { PANEL_POIS_PATH, PANEL_STAYS_POIS_PARAM } from "constants/routes";
import { setLocationsFilter } from "utils/dataConverters";

import { CREATE_ONE_LABEL, DEFINE_LOCATION_LABEL, NO_POIS_FOUND_FOR_LOCATIONS, NO_HOTEL_LOCATION } from "constants/content";
import { PERMISSIONS } from "constants/permissions";
import { usePermissionsService } from "hooks/usePermissionsService";
import { useTableHandlers } from "hooks/useTableHandlers";
import { useService } from "hooks/useService";
import { PoiService } from "services/PoiService";
import { Logger } from "services/application/Logger";

const StyledLink = styled(Link)`
  && {
    margin-left: ${({ theme }) => theme.setSpacing(1)}px;
    line-height: 24px;
  }
`;

const PoiColumns = headers.columns.map(column => ({ ...column, sortable: false }));

const PoisTab = ({ context }) => {
  const [pois, setPois] = useState([]);
  const [count, setCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const poiService = useService(PoiService);

  const permissionsService = usePermissionsService();
  const { pois: poisList, setValueFor, locations, vamoos_id } = useContext(context);

  const hasLocations = locations && !!locations.length;

  const canEdit = permissionsService.can(PERMISSIONS.actions.update, PERMISSIONS.sections.vamoosList, vamoos_id);

  const {
    page,
    rowsPerPage, 
    handleChangeRowsPerPage,
    handleChangePage,
  } = useTableHandlers();

  const handleToggle = item => {
    const matchingPoi = poisList.find(({ id }) => id === item.id);
    const newPoiObject = matchingPoi ? { ...matchingPoi, is_on: !matchingPoi.is_on } : { id: item.id, is_on: !item.isDefaultOn };
    const updateExistingPoi = () => poisList.map(poi => (poi.id === matchingPoi.id ? newPoiObject : poi));
    const addNewPoi = () => [...poisList, newPoiObject];
    const newPoisList = matchingPoi ? updateExistingPoi() : addNewPoi();
    setValueFor("pois", newPoisList);
  };
  const bodyCellAction = item => {
    const checked = (() => {
      const matchingPoi = poisList.find(({ id }) => id === item.item.id);
      return matchingPoi ? matchingPoi.is_on : item.item.isDefaultOn;
    })();
    return (
      <TableActionsContainer>
        <SwitchAction disabled={!canEdit} clickAction={() => handleToggle(item.item)} checked={checked} />
      </TableActionsContainer>
    );
  };

  const getPoisList = async () => {
    if(hasLocations) {
      try {
        setIsLoading(true);
        const { items, total_matches } = await poiService.getTablePois(
          page, 
          rowsPerPage, 
          null, 
          null, 
          null, 
          null, 
          setLocationsFilter(locations), 
          false
        );
        setPois(items);
        setCount(total_matches);
      } catch(e) {
        Logger.debug(e);
      } finally {
        setIsLoading(false);
      }
    } else {
      setPois([]);
    }
  };

  useEffect(() => {
    getPoisList();
  }, [page, rowsPerPage]);
  
  if (isLoading) return <LoadingScreen />;
  if (!hasLocations) {
    const generalSectionPath = window.location.pathname.replace(`/${PANEL_STAYS_POIS_PARAM}`, "");
    return (
      <Message type="info" text={NO_HOTEL_LOCATION}>
        <StyledLink to={generalSectionPath}>{DEFINE_LOCATION_LABEL}</StyledLink>
      </Message>
    );
  }

  if (!pois.length)
    return (
      <Message type="info" text={NO_POIS_FOUND_FOR_LOCATIONS}>
        <StyledLink to={PANEL_POIS_PATH}>{CREATE_ONE_LABEL}</StyledLink>
      </Message>
    );
  return (
    <Table 
      list={pois} 
      headers={PoiColumns} 
      actions={bodyCellAction} 
      isDataLoading={isLoading} 
      noPaper 
      withBorder
      onChangePage={handleChangePage}
      onChangeRowsPerPage={handleChangeRowsPerPage}
    >
      <TableHeader withActions />
      <TableBody rowComponent={PoisTableRow} />
      <TablePagination
        count={count}
        page={page - 1}
        rowsPerPage={rowsPerPage}
      />
    </Table>
  );
};

PoisTab.propTypes = {
  context: PropTypes.shape({}).isRequired,
};

export { PoisTab };
