import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";

import { SharedNavigation } from "feature/panel/_shared/Navigations/SharedNavigation";
import { Actions } from "feature/panel/Notifications/Actions/Actions";
import { NotificationTemplateFormModal } from "feature/panel/Notifications/NotificationTemplateFormModal/NotificationTemplateFormModal";
import { NotificationsList } from "feature/panel/Notifications/NotificationsList";
import { localisedNotificationFormValidator } from "feature/panel/Notifications/notificationFormValidator";

import { PanelTemplate } from "components/templates/Panel/PanelTemplate";
import { Content } from "components/ui/Content";
import { Tabs } from "components/ui/Tabs/Tabs";
import { Tab } from "components/ui/Tabs/Tab";

import { CHANGES_SAVED_MESSAGE, NAMES_OF_RESOURCES_LISTS, NOTIFICATION_TYPES } from "constants/content";
import { isObjectEmpty } from "utils/object";
import { formNotificationTemplatePayload } from "utils/dataConverters";
import { NotificationsService } from "services/NotificationsService";
import { setNotification } from "store/app/actions";
import { setDefaultApiErrorMessage } from "utils";

const tabs = [
  {
    type: NOTIFICATION_TYPES.timed,
    label: NAMES_OF_RESOURCES_LISTS.notifications.timed,
  },
  {
    type: NOTIFICATION_TYPES.gps,
    label: NAMES_OF_RESOURCES_LISTS.notifications.gps,
  },
  {
    type: NOTIFICATION_TYPES.forced,
    label: NAMES_OF_RESOURCES_LISTS.notifications.forced,
  },
];

const Notifications = ({ hasPermission }) => {
  const dispatch = useDispatch();

  /** @type {NotificationsService} */
  const notificationService = new NotificationsService();

  const [currentTab, setCurrentTab] = useState(0);
  const [notificationToModify, setNotificationToModify] = useState({});
  const [errors, setErrors] = useState({});
  const isEditMode = !!notificationToModify.id;

  const listRef = useRef();

  const handleModalClose = () => {
    setNotificationToModify({});
    setErrors({});
  };

  const handleOpenCreationModal = type => {
    setNotificationToModify({
      type,
      url: "",
      content: "",
    });
  };

  const handleUpdateNotificationValue = (name, value, language) => {
    const newValue = name === "url" ? value.toLowerCase() : value;

    if(language.isDefault) {
      setNotificationToModify({
        ...notificationToModify,
        [name]: newValue,
      });
    } else {
      setNotificationToModify({
        ...notificationToModify,
        localisation: {
          ...notificationToModify.localisation,
          [language.code]: {
            ...notificationToModify.localisation?.[language.code],
            [name]: newValue,
          }
        }
      });
    }
  };

  const handleCreateNotification = async () => {
    try {
      await notificationService.createNotification(formNotificationTemplatePayload(notificationToModify));
      handleModalClose();
      await dispatch(setNotification({ type: "success", message: "Notification added successfully" }));
      if(notificationToModify.type === tabs[currentTab]?.type) {
        listRef.current.refresh();
      }
    } catch(error) {
      await dispatch(setNotification({ type: "error", message: setDefaultApiErrorMessage(error) }));
    }
  };

  const handleEditNotification = async () => {
    try {
      await notificationService.updateNotification(formNotificationTemplatePayload(notificationToModify));
      handleModalClose();
      await dispatch(setNotification({ type: "success", message: CHANGES_SAVED_MESSAGE }));
      if(notificationToModify.type === tabs[currentTab]?.type) {
        listRef.current.refresh();
      }
    } catch(error) {
      await dispatch(setNotification({ type: "error", message: setDefaultApiErrorMessage(error) }));
    }
  };

  const handleOpenEditionModal = notification => {
    setNotificationToModify(notification);
  };

  const handleFormConfirm = () => {
    const errorsList = localisedNotificationFormValidator(notificationToModify);
    if (!isObjectEmpty(errorsList)) {
      setErrors(errorsList);
    } else if (isEditMode) {
      handleEditNotification();
    } else {
      handleCreateNotification();
    }
  };

  const contextBar = {
    left: () => null,
    middle: SharedNavigation,
    right: () => <Actions onModalPick={handleOpenCreationModal} />,
  };

  return (
    <PanelTemplate hasPermission={hasPermission} languageSelector contextBar={contextBar}>
      <Grid container justifyContent="center">
        <Grid item xs={12} md={10}>
          <Content margin={10}>
            <Tabs value={currentTab} onChange={(event, newValue) => setCurrentTab(newValue)}>
              {tabs.map(tab => (
                <Tab key={tab.type} label={tab.label} />
              ))}
            </Tabs>
          </Content>
          <NotificationsList 
            onEditClick={handleOpenEditionModal}
            activeType={tabs[currentTab].type}
            ref={listRef}
          />
          <NotificationTemplateFormModal
            open={!!notificationToModify.type}
            type={notificationToModify.type}
            onClose={handleModalClose}
            onNotificationAdd={handleFormConfirm}
            onNotificationChange={handleUpdateNotificationValue}
            notification={notificationToModify}
            confirmLabel={isEditMode ? "Update" : "Create"}
            errors={errors}
          />
        </Grid>
      </Grid>
    </PanelTemplate>
  );
};

Notifications.defaultProps = {
  hasPermission: true,
};

Notifications.propTypes = {
  hasPermission: PropTypes.bool,
};

export { Notifications };
