import * as actionTypes from "store/app/actionTypes";
import { globalReducerActions, reducer, setState } from "utils/store";

export const appInitialState = {
  errors: {},
  notification: {
    show: false,
    message: "",
    type: "default",
  },
  savingCover: false,
  operatorChangeInit: false,
  operatorChangeProcess: false,
  operatorChanged: false,
  redirectPath: null,
  tripRedirectChecked: false,
  apiKeys: [],
  apiKeysCount: 0,
  inProgress: false,
  finished: false,
};

const setErrors = (state, errors) =>
  setState(state, {
    errors,
  });

const setApiKeys = (state, { items, count }) =>
  setState(state, {
    apiKeys: items,
    apiKeysCount: count,
  });

const ReducerActions = globalReducerActions("app", appInitialState, {
  [actionTypes.SET_NOTIFICATION]: (state, payload) => {
    return setState(state, {
      notification: {
        message: payload.message,
        type: payload.type,
        show: true,
      },
    })
  },
  [actionTypes.PUSH_SUCCESS_NOTIFICATION]: (state, payload) => {
    return setState(state, {
      notification: {
        message: payload,
        type: "success",
        show: true,
      },
    })
  },
  [actionTypes.PUSH_ERROR_NOTIFICATION]: (state, payload) => {
    return setState(state, {
      notification: {
        message: payload,
        type: "error",
        show: true,
      },
    })
  },
  [actionTypes.CLEAR_NOTIFICATION]: state => {
    return setState(state, {
      notification: appInitialState.notification,
    })
  },

  [actionTypes.SHOW_SAVING_COVER]: (state) => {
    return setState(state, {
      savingCover: true,
    })
  },
  [actionTypes.HIDE_SAVING_COVER]: state => {
    return setState(state, {
      savingCover: false,
    })
  },
  [actionTypes.CHANGE_OPERATOR_START]: state => {
    return setState(state, {
      operatorChangeProcess: true,
      operatorChanged: false,
    })
  },
  [actionTypes.CHANGE_OPERATOR_SUCCESS]: state => {
    return setState(state, {
      operatorChangeProcess: false,
      operatorChanged: true,
      tripRedirectChecked: false,
      operatorChangeInit: false,
    })
  },
  [actionTypes.CHANGE_OPERATOR_FAIL]: (state, payload) => {
    return setState(state, {
      operatorChangeProcess: false,
      operatorChanged: false,
      operatorChangeInit: false,
      errors: payload,
    })
  },
  [actionTypes.CLEAR_CHANGE_OPERATOR_FLAGS]: state => {
    return setState(state, {
      operatorChangeProcess: false,
      operatorChanged: false,
      errors: {},
    })
  },
  [actionTypes.TRY_ROUTE_CHANGE_START]: (state, payload) => {
    return setState(state, {
      redirectPath: payload,
    })
  },
  [actionTypes.TRY_ROUTE_CHANGE_FINISH]: state => {
    return setState(state, {
      redirectPath: null,
    })
  },
  [actionTypes.CLEAR_CHANGE_OPERATOR_FLAGS]: state => {
    return setState(state, {
      operatorChangeProcess: false,
      operatorChanged: false,
      errors: {},
    })
  },
  [actionTypes.CLEAR_CHANGE_OPERATOR_FLAGS]: state => {
    return setState(state, {
      operatorChangeInit: false,
      operatorChangeProcess: false,
      operatorChanged: false,
      errors: {},
    })
  },
  [actionTypes.TOGGLE_TRIP_REDIRECT_CHECKED]: state => {
    return setState(state, {
      tripRedirectChecked: !state.tripRedirectChecked,
    })
  },
  [actionTypes.GET_API_KEYS_START]: state => {
    return setState(state, {
      inProgress: true,
      finished: false,
    })
  },
  [actionTypes.DELETE_USER_FROM_OPERATOR_START]: state => {
    return setState(state, {
      inProgress: true,
      finished: false,
    })
  },
  [actionTypes.CHANGE_OPERATOR_INIT_TOGGLE]: (state, payload) => {
    return setState(state, {
      operatorChangeInit: payload,
    })
  },
  [actionTypes.DELETE_USER_FROM_OPERATOR_SUCCESS]: state => ({ ...state, finished: true, inProgress: false }),
  [actionTypes.DELETE_USER_FROM_OPERATOR_FAIL]: (state, payload) => ({ ...setErrors(state, payload), finished: false, inProgress: false }),

  [actionTypes.GET_API_KEYS_SUCCESS]: (state, payload) => ({ ...setApiKeys(state, payload), finished: true, inProgress: false }),
  [actionTypes.GET_API_KEYS_FAIL]: (state, payload) => ({ ...setErrors(state, payload), finished: true, inProgress: false }),

  [actionTypes.CREATE_API_KEY_SUCCESS]: (state, payload) => setApiKeys(state, payload),
  [actionTypes.CREATE_API_KEY_FAIL]: (state, payload) => setErrors(state, payload),

  [actionTypes.DELETE_API_KEY_SUCCESS]: (state, payload) => setApiKeys(state, payload),
  [actionTypes.DELETE_API_KEY_FAIL]: (state, payload) => setErrors(state, payload),
});

export const appReducer = (state = appInitialState, action) => reducer(state, action, ReducerActions);
