import { NotificationRepository } from "repositories/NotificationRepository";

/**
 * @todo: Add mapper and domain objects
 */

export class NotificationsService {
  constructor(notificationRepository = new NotificationRepository()) {
    this.notificationRepository = notificationRepository;
  }

  /**
   * 
   * @param {string} operator 
   * @param {*} params 
   * @returns {Promise<import("domain/Paginated").Paginated<import("domain/StayPermission").StayPermission>>}
   */
  async getStayUsers(operator, params = {}) {
    // Map to paginated
    return this.notificationRepository.getNotifications(operator, params);
  }

  getAllNotifications() {
    return this.notificationRepository.getAllNotifications();
  }

  createNotification(notification) {
    return this.notificationRepository.createNotification(notification);
  }

  updateNotification(notification) {
    return this.notificationRepository.updateNotification(notification);
  }

  removeNotification(notification) {
    return this.notificationRepository.removeNotification(notification);
  }
}