import React, { useState } from "react";
import styled from "styled-components";
import { Editor } from "@tinymce/tinymce-react";
import config from "config/app";

import emailIcon from "assets/images/tiny-editor/email.svg";
import phoneIcon from "assets/images/tiny-editor/phone.svg";
import { GLOBAL_CONTENT, LOGIN_SCREEN_EDITOR_LABELS } from "constants/content";

const EditorWrapper = styled.div`
  & > .tox-tinymce {
    border-radius: ${({ theme }) => `${theme.setSpacing(2)}px`} !important;
    border-color: ${({ theme }) => theme.colors.grey40} !important;

    .tox-statusbar {
      display: none;
    }
  }

  && {
    .tox-tbtn--enabled {
      background: #ffffff;

      &:hover {
        background: #c8cbcf;
      }
    }
  }
`;

const Label = styled.div`
  font-size: ${({ theme }) => theme.setSpacing(3.5)}px;
  margin-bottom: ${({ theme }) => theme.setSpacing(2)}px;
  font-weight: 500;
`;

const HelperTextContent = styled.small`
  color: ${({ theme, error }) => (error ? theme.colors.error : theme.colors.grey40)};
`;

const emailPopupConfig = editor => ({
  title: LOGIN_SCREEN_EDITOR_LABELS.insertEmail,
  tooltip: LOGIN_SCREEN_EDITOR_LABELS.addEmail,
  body: {
    type: "panel",
    items: [
      {
        type: "input",
        name: "email",
        label: LOGIN_SCREEN_EDITOR_LABELS.email,
      },
    ],
  },
  buttons: [
    {
      type: "cancel",
      name: "closeButton",
      text: GLOBAL_CONTENT.cancel,
    },
    {
      type: "submit",
      name: "submitButton",
      text: LOGIN_SCREEN_EDITOR_LABELS.save,
      primary: true,
    },
  ],
  initialData: {
    email: "",
  },
  onChange: () => {},
  onSubmit: api => {
    const { email } = api.getData();
    const content = editor.getContent();

    editor.setContent(`${content} <a href="mailto:${email}>${email}</a>`);
    api.close();
  },
});

const phoneNumberPopupConfig = editor => ({
  title: LOGIN_SCREEN_EDITOR_LABELS.insertPhone,
  tooltip: LOGIN_SCREEN_EDITOR_LABELS.addPhone,
  body: {
    type: "panel",
    items: [
      {
        type: "input",
        name: "phone",
        label: LOGIN_SCREEN_EDITOR_LABELS.phone,
      },
    ],
  },
  buttons: [
    {
      type: "cancel",
      name: "closeButton",
      text: GLOBAL_CONTENT.cancel,
    },
    {
      type: "submit",
      name: "submitButton",
      text: LOGIN_SCREEN_EDITOR_LABELS.save,
      primary: true,
    },
  ],
  initialData: {
    email: "",
  },
  onSubmit: api => {
    const { phone } = api.getData();
    const content = editor.getContent();

    editor.setContent(`${content} <a href="tel:${phone}>${phone}</a>`);
    api.close();
  },
});

const TextInputEditor = ({ value, onChange, label, error, helperText, disabled, toolbar = "link addEmail addPhone", height, index }) => {
  const [isDirty, setIsDirty] = useState(false);
  const setup = editor => {
    editor.ui.registry.addButton("addEmail", {
      text: `<img src="${emailIcon}" />`,
      tooltip: LOGIN_SCREEN_EDITOR_LABELS.addEmail,
      onAction: () => {
        editor.windowManager.open(emailPopupConfig(editor));
      },
    });
    editor.ui.registry.addButton("addPhone", {
      text: `<img src="${phoneIcon}" />`,
      tooltip: LOGIN_SCREEN_EDITOR_LABELS.addPhone,
      onAction: () => {
        editor.windowManager.open(phoneNumberPopupConfig(editor));
      },
    });
  };

  const onContentChange = (text, editor) => {
    const withouthNbsp = text.replaceAll(/&(amp|quot|lt|gt);/g, "");

    if (isDirty) onChange(text);
  };
  return (
    <>
      <Label style={{ color: "#a4a7aa" }}>{label}</Label>
      <EditorWrapper>
        <Editor
          apiKey={config.vendors.TinyMCE.API_KEY}
          // tinymceScriptSrc={process.env.PUBLIC_URL + "/tinymce/tinymce.min.js"}
          value={value}
          key={index}
          // onActivate={() => {
          //   document.querySelectorAll("button[title='Font sizes'] .tox-tbtn__select-label").forEach(x => x.innerText = 'Medium');
          // }}
          init={{
            height,
            min_height: 150,
            autoresize_bottom_margin: 0,
            menubar: false,
            force_br_newlines: true,
            force_p_newlines: false,
            forced_root_block: "",
            link_assume_external_targets: "https",
            link_title: false,
            target_list: false,
            content_style:
              "@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&family=Raleway:wght@300;400;500;600;700&display=swap'); body { font-family: Montserrat; font-size: 1rem; color: #313840; }",
            default_link_target: "_blank",
            setup,
            fontsize_formats: "Small=0.8rem Medium=1rem Large=1.5rem",
            // encoding: "html",
            paste_preprocess: (_, args) => {
              // eslint-disable-next-line no-param-reassign
              args.content = args.content.replaceAll(/<(?!br\s*\/?)(?!\/p)[^>]*>/gi, "").replaceAll(/<[^>]*>/gi, "<br>");
              // args.content = args.content.replaceAll(/<[^>]*>/gi, "");
            },

            plugins: [
              "advlist autolink lists link image charmap print preview anchor nonbreaking autoresize",
              "searchreplace visualblocks", 
              "insertdatetime media table paste code help wordcount",
            ],
            contextmenu: false,

            toolbar,
          }}
          onEditorChange={onContentChange}
          onKeyDown={() => setIsDirty(true)}
          onMouseEnter={() => setIsDirty(true)}
          disabled={disabled}
        />
      </EditorWrapper>

      {helperText && <HelperTextContent error={error}>{helperText}</HelperTextContent>}
    </>
  );
};

export { TextInputEditor };
