import { useEffect, useState } from "react";
import { UrlRefreshService } from "services/application/UrlRefreshService";

export const useRefreshedFileUrl = url => {
  const [currentUrl, setCurrentUrl] = useState("");

  const updateUrl = () => {
    let cleanup = true;
    if (url) {
      UrlRefreshService.refresh(url).then(newUrl => {
        if (cleanup) {
          setCurrentUrl(newUrl);
        }
      });
    } else {
      setCurrentUrl("");
    }
    return () => {
      cleanup = false;
    };
  };

  useEffect(updateUrl, [url]);

  return currentUrl;
};
