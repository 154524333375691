import { isObject } from "contracts/types";

import { FLIGHTS_MAP_URL } from "constants/api";

import config from "config/app";

/**
 * @deprecated since encoding is a part of setUrl and there is encodeParameter function
 * @param {*} str 
 * @returns 
 */
export const encodeForUrl = str => {
  return encodeURIComponent(str)
    .replace(/!/g, "%21")
    .replace(/#/g, "%23")
    .replace(/\?/g, "%3F")
    .replace(/\(/g, "%28")
    .replace(/\)/g, "%29")
    .replace(/'/g, "%27")
    .replace(/"/g, "%22")
};

export const encodeParameter = str => {
  return encodeURIComponent(str)
    .replace(/!/g, "%21")
    .replace(/#/g, "%23")
    .replace(/\?/g, "%3F")
    .replace(/\(/g, "%28")
    .replace(/\)/g, "%29")
    .replace(/\*/g, "%2A")
};

export const decodeFromUrl = str => {
  try {
    if(str) {
      return decodeURIComponent(str)
    }
    return str;
  } catch(e) {
    return str;
  }
}

export const setUrl = (urlBlueprint, params, encode = false) => {
  let url = urlBlueprint;
  const prepareParam = param => `:${param}`;
  Object.keys(params).map(param => {
    if(url.includes(prepareParam(param)))  {
      return (url = url.replace(prepareParam(param), encode ? encodeParameter(params[param]) : params[param]))
    }
    return null;
  });
  return url;
};

export const checkIsActiveUrl = (pathname, pathToCheck, exact) => {
  if (pathname && pathToCheck) {
    const match = pathname.includes(pathToCheck);
    const exactMatch = match && (pathname.endsWith(pathToCheck) || pathname.endsWith(`${pathToCheck}/`));
    return exact ? exactMatch : match;
  }
  return false;
};

export const setParams = (item, paramsMap) =>
  paramsMap.reduce((acc, current) => {
    return item[current] ? { ...acc, [current]: encodeParameter(item[current]) } : acc;
  }, {});

export const setUrlParams = (url, params) => {
  if (isObject(params) && url) {
    return Object.keys(params).reduce((acc, param) => {
      const value = params[param];
      if ((value !== "" && typeof value === "string") || typeof value === "number") {
        return `${acc}&${param}=${encodeParameter(value)}`;
      }
      if(Array.isArray(value)) {
        return `${acc}&${value.map(valueItem => `${param}=${encodeParameter(valueItem)}`).join("&")}`
      }
      return acc;
    }, `${url}?`);
  }
  return url;
};

export const getFlightsMapUrl = (from, to) => {
  const server = config.api[process.env.NODE_ENV] || "development";
  return from && to
    ? `${server}${setUrl(FLIGHTS_MAP_URL, {
        "from-to": `${from},${to}`,
      })}`
    : "";
};

export const checkQueryParamExistence = (url, paramName) => {
  const queryParams = new URLSearchParams(url);
  return queryParams.get(paramName);
};

export const arrayMoveToPosition = (arr, oldIndex, newIndex) => {
  let oldIndexVariable = oldIndex;
  let newIndexVariable = newIndex;
  while (oldIndexVariable < 0) {
    oldIndexVariable += arr.length;
  }
  while (newIndexVariable < 0) {
    newIndexVariable += arr.length;
  }
  if (newIndexVariable >= arr.length) {
    let k = newIndexVariable - arr.length;
    // eslint-disable-next-line no-plusplus
    while (k-- + 1) {
      arr.push(undefined);
    }
  }
  arr.splice(newIndexVariable, 0, arr.splice(oldIndexVariable, 1)[0]);
  return arr;
}
