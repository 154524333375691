import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import AddIcon from "@material-ui/icons/Add";
import FormatLineSpacingIcon from "@material-ui/icons/FormatLineSpacing";
import EventBusyIcon from "@material-ui/icons/EventBusy";
import EventAvailableIcon from "@material-ui/icons/EventAvailable";
import { Tooltip } from "@material-ui/core";

import { SecondaryButton } from "components/ui/Buttons";
import { CREATE_CONTENT_LABELS, TABLE_ACTIONS_TITLES, TRIP_WIZARD_CONTENT } from "constants/content";
import { AddFromLibraryIcon } from "components/ui/Icons/AddFromLibraryIcon";

const { hideDates, showDates, reorder } = TABLE_ACTIONS_TITLES;

const EntriesRightMenuContainer = styled.div`
  display: flex;
`;

const EntriesRightMenuItemContainer = styled.div`
  display: flex;
  margin-right: ${({ theme }) => theme.setSpacing(6)}px;
`;

const LibraryButtonWrapper = styled.div`
  margin-right: 24px;
`;

const EntriesRightMenu = ({ onAddEntry, onAddToStoryboard, onToggleShowDayNumber, showDayNumber, onClickSorting, daysCount, disabled }) => {
  const toggleShow = () => onToggleShowDayNumber(!showDayNumber);

  return (
    !disabled && (
      <EntriesRightMenuContainer>
        <Tooltip placement="bottom" title={reorder}>
          <EntriesRightMenuItemContainer>
            <SecondaryButton cv="grey50" iconButton onClick={() => onClickSorting(true)} disabled={daysCount < 2}>
              <FormatLineSpacingIcon />
            </SecondaryButton>
          </EntriesRightMenuItemContainer>
        </Tooltip>

        <Tooltip placement="bottom" title={showDayNumber ? hideDates : showDates}>
          <EntriesRightMenuItemContainer>
            <SecondaryButton cv="grey50" iconButton onClick={toggleShow}>
              {showDayNumber ? <EventAvailableIcon /> : <EventBusyIcon />}
            </SecondaryButton>
          </EntriesRightMenuItemContainer>
        </Tooltip>

        <Tooltip placement="bottom" title={TRIP_WIZARD_CONTENT.storyboard.addToStoryboard}>
          <LibraryButtonWrapper>
            <SecondaryButton cv="grey50" onClick={onAddToStoryboard}>
              <AddFromLibraryIcon />
            </SecondaryButton>
          </LibraryButtonWrapper>
        </Tooltip>
        <SecondaryButton onClick={onAddEntry}>
          <AddIcon />
          {CREATE_CONTENT_LABELS.add}
        </SecondaryButton>
      </EntriesRightMenuContainer>
    )
  );
};

EntriesRightMenu.defaultProps = {
  disabled: false,
};

EntriesRightMenu.propTypes = {
  onAddEntry: PropTypes.func.isRequired,
  onAddToStoryboard: PropTypes.func.isRequired,
  onToggleShowDayNumber: PropTypes.func.isRequired,
  showDayNumber: PropTypes.bool.isRequired,
  onClickSorting: PropTypes.func.isRequired,
  daysCount: PropTypes.number.isRequired,
  disabled: PropTypes.bool,
};

export { EntriesRightMenu };
