import iso6391 from "iso-639-1";

const useListOfLanguages = () => {
  const codes = iso6391.getAllCodes();

  return codes.map(code => ({
    code,
    name: iso6391.getName(code)
  }))
};

export { useListOfLanguages };
