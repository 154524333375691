// @ts-check

import { isNumber } from "contracts/types";

/**
 * @param {ImageResponseDto} imageDto - The image object.
 * @param {number|string} iconId - icon id
 * @returns {VamoosImage} VamoosImage object
 */
export function VamoosImage(imageDto, iconId) {
  /**
   * @type {VamoosImage}
   */
  const domain = {};

  domain.name = imageDto.name || imageDto?.file_name;

  if (isNumber(iconId || imageDto.icon_id)) {
    domain.iconId = iconId || imageDto.icon_id;
  } else {
    domain.iconId = 0; // If icon id is string then it's new icon
  }

  if (imageDto.https_url || imageDto.preview_url) {
    domain.previewUrl = imageDto.https_url || imageDto.preview_url;
  }

  if (imageDto?.s3) {
    domain.s3Url = imageDto.s3;
  }

  if (imageDto?.s3_url) {
    domain.s3Url = imageDto?.s3_url;
  }

  if (imageDto.library_node_id) {
    domain.libraryNodeId = imageDto.library_node_id;
    return domain;
  }

  if (imageDto.file_id) {
    domain.fileId = imageDto.file_id;
    return domain;
  }

  if (imageDto.file_url) {
    domain.fileUrl = imageDto.file_url;
    return domain;
  }

  if (imageDto.web_url) {
    domain.webUrl = imageDto.web_url;
    return domain;
  }
}
