import React from "react";
import styled from "styled-components";

const ImageBaseProps = styled(({ width, height, ...rest }) => <div {...rest} />)`
  width: ${({ width }) => width || "50px"};
  min-width: ${({ width }) => width || "50px"};
  height: ${({ height }) => height || "50px"};
  min-height: ${({ height }) => height || "50px"};
  border-radius: ${({ theme }) => theme.borderRadius}px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export { ImageBaseProps };
