import React, { memo } from "react";
import styled from "styled-components";

import { Link } from "components/ui/Links/Link";

const MainNavigationButton = styled(memo(({ isActive, ...rest }) => <Link {...rest} />))`
  && {
    display: inline-block;
    color: ${({ theme, isActive }) => (isActive ? theme.colors.white : theme.colors.grey50)};
    text-transform: uppercase;
    background: transparent;
    transition: ${({ theme }) => theme.transition};
    font-weight: ${({ theme }) => theme.fonts.fontWeight.semiBold};
    padding: 0 ${({ theme }) => theme.setSpacing(6)}px;
    position: relative;
    overflow: hidden;
    height: ${({ theme }) => theme.setSpacing(16)}px;
    line-height: ${({ theme }) => theme.setSpacing(16)}px;
    letter-spacing: 1px;

    :hover {
      background: transparent;
      color: ${({ theme }) => theme.colors.brandHover};
    }

    ${({ isActive, theme }) =>
      isActive &&
      `
      :after {
        content: '';
        position: absolute;
        display: block;
        width: ${theme.setSpacing(4)}px;
        height: ${theme.setSpacing(4)}px;
        border-top-left-radius: 2px;
        background: ${theme.colors.white};
        bottom: -${theme.setSpacing(4)}px;
        left: 50%;
        transform: rotate(45deg) translateX(-50%);
      }
    `}
  }
`;

export { MainNavigationButton };
