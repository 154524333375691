import React from "react";
import { Grid } from "@material-ui/core";
import { useLocation } from "react-router-dom";

import { AppIconSection } from "./AppIconSection";
import { AppLogoSection } from "./AppLogoSection";

const LogoAndIcons = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const isHistoryVersion = queryParams.get("versionHistory");

  return (
    <Grid>
      <Grid>
        <AppIconSection isHistoryVersion={isHistoryVersion} />
      </Grid>
      <Grid>
        <AppLogoSection isHistoryVersion={isHistoryVersion} />
      </Grid>
    </Grid>
  );
};

export { LogoAndIcons };
