import React, { useState, useContext, useEffect } from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import { deepClone } from "utils/array";
import { SortingEntriesDialogList } from "feature/panel/Trips/_shared/Storyboard/Entries/SortingEntriesDialog/SortingEntriesDialogList";
import { EntriesContext } from "feature/panel/Trips/_shared/Storyboard/Entries/EntriesContext";
import { ConfirmationModal } from "components/ui/Modals/ConfirmationModal";
import { TRIP_WIZARD_CONTENT } from "constants/content";

const SortingEntriesDialog = ({ open, onClose }) => {
  const { days, setDays } = useContext(EntriesContext);

  const [sortedList, setSortedList] = useState([]);

  const handleSave = () => {
    days.forEach((element, index) => {
      if (sortedList[index]) {
        sortedList[index].position = element.position;
      }
    });

    setDays(sortedList);
    onClose();
  };

  const handleCancel = () => {
    setSortedList([...days]);
    onClose();
  };

  useEffect(() => setSortedList(deepClone(days)), [days]);

  return open ? (
    <ConfirmationModal
      fullWidth
      open={open}
      maxWidth="sm"
      onCancel={handleCancel}
      onConfirm={handleSave}
      title={TRIP_WIZARD_CONTENT.storyboard.reorder}
    >
      <Grid container>
        <Grid item xs={12}>
          <SortingEntriesDialogList onChangeOrder={setSortedList} list={sortedList} />
        </Grid>
      </Grid>
    </ConfirmationModal>
  ) : null;
};

SortingEntriesDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export { SortingEntriesDialog };
