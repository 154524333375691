export const dailyActivityReducer = (state, action) => {
	switch (action.type) {
		case "setValueFor": {
			return {
				...state,
				[action.fieldName]: action.value,
			};
		}
		case "setAllValues": {
			return action.payload
		}
		default:
			return state;
	}
};
