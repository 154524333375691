import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { InformationField } from "feature/panel/Settings/_shared/InformationField";
import { FieldChangeModal } from "feature/panel/Settings/_shared/FieldChangeModal";
import { usePermissionsService } from "hooks/usePermissionsService";
import { PERMISSIONS } from "constants/permissions";
import { CREATION_LINNKS_LABELS } from "constants/content";
import { Grid } from "@material-ui/core";

const CreationLabelsWrapper = styled(Grid)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey10};
`;

const CreationLabels = ({ onLinkChange, instantViewLabel, instantViewMapsLabel }) => {
  const permissionsService = usePermissionsService();
  const [editModal, setEditModal] = useState({ open: false, body: {} });

  const canEdit = permissionsService.can(PERMISSIONS.actions.update, PERMISSIONS.sections.operator, PERMISSIONS.resources.operators);

  const handleEditionToggle = fieldName => {
    const { open } = editModal;
    const body = open ? editModal.body : fieldName;
    setEditModal({ body, open: !open });
  };

  const handleValueChange = ({ target }) => {
    setEditModal({
      ...editModal,
      body: {
        ...editModal.body,
        value: target.value,
      },
    });
  };

  const handleEditionConfirm = () => {
    const { value, fieldName } = editModal.body;

    onLinkChange(value, fieldName);
    handleEditionToggle();
  };

  const rawDataFields = [
    {
      label: CREATION_LINNKS_LABELS.instantView,
      value: instantViewLabel,
      fieldName: "instantViewLabel",
    },
    {
      label: CREATION_LINNKS_LABELS.instantViewMaps,
      value: instantViewMapsLabel,
      fieldName: "instantViewMapsLabel",
    },
  ];

  return (
    <>
      <CreationLabelsWrapper>
        {rawDataFields.map(field => (
          <Grid item key={field.fieldName}>
            <InformationField title={field.label} content={field.value} onEditClick={canEdit ? () => handleEditionToggle(field) : null} />
          </Grid>
        ))}
      </CreationLabelsWrapper>
      <FieldChangeModal
        open={editModal.open}
        label={editModal.body.label || ""}
        value={editModal.body.value || ""}
        onValueEdit={handleValueChange}
        onConfirm={handleEditionConfirm}
        onCancel={handleEditionToggle}
      />
    </>
  );
};

CreationLabels.defaultProps = {
  instantViewLabel: null,
  instantViewMapsLabel: null,
};

CreationLabels.propTypes = {
  onLinkChange: PropTypes.func.isRequired,
  instantViewLabel: PropTypes.string,
  instantViewMapsLabel: PropTypes.string,
};

export { CreationLabels };
