import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import MenuIcon from "@material-ui/icons/Menu";

import { getFilePreviewUrl } from "utils/library";
import { useRefreshedFileUrl } from "hooks/useRefreshedFileUrl";

import { LOGIN_PAGE_SINGLE_PROPERTY_LABELS } from "../constants/customAppStatus";
import { PhonePresentation, bgDimmed } from "../PhonePresentation";
import { getLoginText, getLoginTextColor } from "../LoginScreen/helpers";

const LoginPagePreviewWrapper = styled.div`
  padding: 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  position: relative;
  z-index: 2;
  ${({ bgColor }) => (bgColor ? `background-color: ${bgColor}` : "")}
`;

const Logo = styled.img`
  max-height: 100px;
  max-width: 170px;
  width: auto;
  height: auto;
  margin: 0 0 20px 0;
`;

const LoginPagePreviewLabel = styled.div`
  text-align: center;
  font-size: ${({ theme, size }) => {
    if (size === "large") {
      return theme.fonts.fontSize.body;
    }
    if (size === "small") {
      return theme.fonts.fontSize.body3;
    }
    return theme.fonts.fontSize.body2;
  }}px;
  margin: ${({ noMargin }) => (noMargin ? "0 0" : "0 0 20px 0")};
  color: ${({ color }) => color || "#FFFFFF"};

  a {
    color: ${({ accentColor }) => accentColor};
  }
`;

const LoginPagePreviewInput = styled.div`
  border: 1px solid ${({ borderColor }) => borderColor || "#FFFFFF"};
  color: ${({ color }) => color || "#989898"};
  background-color: ${({ bgColor, theme }) => bgColor || `${theme.colors.black}70`};
  text-align: left;
  border-radius: 6px;
  width: 160px;
  padding: 6px;
  height: 32px;
  font-size: 13px;
  margin-top: -15px;
  position: relative;
  ${({ noMarginBottom }) => (noMarginBottom ? "" : "margin: 0 0 5px 0")};
  ${({ displayAsterix }) =>
    displayAsterix
      ? `
  &:after {
    content: "*";
    position: absolute;
    top: 0px;
    right: -10px;
    color: white;
  }
  `
      : ""}
`;

export const LoginPagePreviewButton = styled.div`
  width: 140px;
  padding: 8px;
  font-size: 12px;
  background: ${({ bgColor }) => bgColor || "#7b7b7b"};
  height: 32px;
  border-radius: 16px;
  border: none;
  color: ${({ color }) => color || "#FFFFFF"};
  text-align: center;
  font-size: 14px;
  line-height: 14px;
  margin: 0 0 20px 0;
`;

const ContentWrapper = styled.div`
  position: relative;
  z-index: 1;
  ${({ bgColor }) => (bgColor ? `background-color: ${bgColor}` : "")};
  ${({ borderColor }) => (borderColor ? `border: 8px solid ${borderColor};` : null)};
  ${({ bgImage, theme }) => (bgImage ? bgDimmed(theme, bgImage) : "")}
  height: 100%;
`;

const SkipButtonWrapper = styled(LoginPagePreviewLabel)`
  font-size: 14px;
`;

const StyledMenuIcon = styled(MenuIcon)`
  color: ${({ menuColor }) => menuColor || "#ffffff"};
  position: absolute;
  top: ${({ isHotel }) => (isHotel ? 20 : 12)}px;
  left: ${({ isHotel }) => (isHotel ? 20 : 12)}px;
`;

const LoginScreenPreview = ({ currentLanguage }) => {
  const [previewLoading, setPreviewLoading] = useState(true);
  const type = useSelector(state => state.customAppForm.customAppForm.type);
  const bgColor = useSelector(state => {
    const loginBg = state.customAppForm.customAppForm.colors.loginBackground;
    const defaultBg = state.customAppForm.customAppForm.colors.defaultBackground;
    const appType = state.customAppForm.customAppForm.type;

    return appType === "stay" ? defaultBg : loginBg;
  });

  const inputFieldBackgroundColor = useSelector(state => state.customAppForm.customAppForm.colors.inputFieldBackgroundColor);
  const inputFieldFrameColor = useSelector(state => state.customAppForm.customAppForm.colors.inputFieldFrameColor);
  const inputTextColor = useSelector(state => state.customAppForm.customAppForm.colors.inputTextColor);

  const language = currentLanguage || "en";

  const buttonBackgroundColorActive = useSelector(state => state.customAppForm.customAppForm.colors.buttonBackgroundColorActive);
  const buttonTextColorActive = useSelector(state => state.customAppForm.customAppForm.colors.buttonTextColorActive);
  const loginBorderColor = useSelector(state => state.customAppForm.customAppForm.colors.loginBorderColor);
  const backgroundImage = useSelector(state => state.customAppForm.customAppForm.images.backgroundImage);
  const loginNavigationColor = useSelector(state => state.customAppForm.customAppForm.colors.loginNavigationColor);
  const accentColor = useSelector(state => state.customAppForm.customAppForm.colors.accentColor);
  const logo = useSelector(state => state.customAppForm.customAppForm.images.logo);

  const loginText1 = useSelector(state => state.customAppForm.customAppForm.strings.loginText1.languages[language]);
  const loginText2 = useSelector(state => state.customAppForm.customAppForm.strings.loginText2.languages[language]);
  const loginText3 = useSelector(state => state.customAppForm.customAppForm.strings.loginText3.languages[language]);
  const loginText4 = useSelector(state => state.customAppForm.customAppForm.strings.loginText4.languages[language]);
  const loginText5 = useSelector(state => state.customAppForm.customAppForm.strings.loginText5.languages[language]);
  const placeholder = useSelector(state => state.customAppForm.customAppForm.strings.loginUserIdHint.languages?.[language]);
  const loginNextButton = useSelector(state => state.customAppForm.customAppForm.strings.loginNextButton.languages?.[language]);

  const strings = useSelector(state => state.customAppForm.customAppForm.strings);

  const bgImagePreview = useRefreshedFileUrl(getFilePreviewUrl(backgroundImage));
  const logoPreview = useRefreshedFileUrl(getFilePreviewUrl(logo));

  const logoImage = new Image();
  const bgImage = new Image();

  logoImage.src = logoPreview;
  bgImage.src = bgImagePreview;

  useEffect(() => {
    const handleLogoLoad = () => {
      setPreviewLoading(!(logoImage?.naturalHeight > 0 || bgImage?.naturalHeight > 0));
    };
    const handleBgLoad = () => {
      setPreviewLoading(!(logoImage?.naturalHeight > 0 || bgImage?.naturalHeight > 0));
    };

    logoImage.addEventListener("load", handleLogoLoad);
    bgImage.addEventListener("load", handleBgLoad);

    setPreviewLoading(!(logoImage?.naturalHeight > 0 || bgImage?.naturalHeight > 0));

    return () => {
      logoImage.removeEventListener("load", handleLogoLoad);
      bgImage.removeEventListener("load", handleBgLoad);
    };
  }, [logoImage?.naturalHeight, bgImage?.naturalHeight, logoPreview, bgImagePreview]);
  const hotelPreview = (
    <>
      <LoginPagePreviewLabel size="small">{LOGIN_PAGE_SINGLE_PROPERTY_LABELS.line1}</LoginPagePreviewLabel>
      <LoginPagePreviewInput displayAsterix>{LOGIN_PAGE_SINGLE_PROPERTY_LABELS.fullName}</LoginPagePreviewInput>
      <LoginPagePreviewInput displayAsterix>{LOGIN_PAGE_SINGLE_PROPERTY_LABELS.email}</LoginPagePreviewInput>
      <LoginPagePreviewButton color={buttonTextColorActive} bgColor={buttonBackgroundColorActive}>
        {LOGIN_PAGE_SINGLE_PROPERTY_LABELS.nextButtonLabel}
      </LoginPagePreviewButton>
      <SkipButtonWrapper>{LOGIN_PAGE_SINGLE_PROPERTY_LABELS.skipButtonLabel}</SkipButtonWrapper>
      <LoginPagePreviewLabel size="small">{LOGIN_PAGE_SINGLE_PROPERTY_LABELS.hint}</LoginPagePreviewLabel>
    </>
  );

  const loginLoginText1 = () => ({ __html: getLoginText(strings, { varName: "loginText1", language }) || "" });
  const loginLoginText2 = () => ({ __html: getLoginText(strings, { varName: "loginText2", language }) || "" });
  const loginLoginText3 = () => ({ __html: getLoginText(strings, { varName: "loginText3", language }) || "" });
  const loginLoginText4 = () => ({ __html: getLoginText(strings, { varName: "loginText4", language }) || "" });
  const loginLoginText5 = () => ({ __html: getLoginText(strings, { varName: "loginText5", language }) || "" });

  const tripPreview = (
    <>
      <LoginPagePreviewLabel
        color={getLoginTextColor("loginText1", strings)}
        size="large"
        accentColor={accentColor}
        dangerouslySetInnerHTML={loginLoginText1()}
      />
      <LoginPagePreviewLabel
        color={getLoginTextColor("loginText2", strings)}
        borderColor={loginBorderColor}
        accentColor={accentColor}
        dangerouslySetInnerHTML={loginLoginText2()}
      />
      <LoginPagePreviewLabel
        color={getLoginTextColor("loginText3", strings)}
        accentColor={accentColor}
        dangerouslySetInnerHTML={loginLoginText3()}
      />
      <LoginPagePreviewInput color={inputTextColor} borderColor={inputFieldFrameColor} bgColor={inputFieldBackgroundColor} noMarginBottom>
        {placeholder?.text}
      </LoginPagePreviewInput>
      <LoginPagePreviewLabel
        color={getLoginTextColor("loginText4", strings)}
        size="small"
        accentColor={accentColor}
        dangerouslySetInnerHTML={loginLoginText4()}
        noMargin
      />
      <LoginPagePreviewButton color={buttonTextColorActive} bgColor={buttonBackgroundColorActive}>
        {loginNextButton?.text}
      </LoginPagePreviewButton>
      <LoginPagePreviewLabel
        color={getLoginTextColor("loginText5", strings)}
        size="small"
        accentColor={accentColor}
        dangerouslySetInnerHTML={loginLoginText5()}
      />
    </>
  );

  return (
    <PhonePresentation loading={previewLoading}>
      <ContentWrapper
        borderColor={type === "trip" ? loginBorderColor : null}
        bgColor={type === "trip" ? null : bgColor}
        bgImage={type === "stay" && bgImage.src}
      >
        <LoginPagePreviewWrapper bgColor={type === "trip" ? bgColor : null}>
          <StyledMenuIcon menuColor={loginNavigationColor} isHotel={type === "stay"} />
          {logoPreview && <Logo alt="Example logo" src={logoImage.src} />}
          {type === "stay" ? hotelPreview : tripPreview}
        </LoginPagePreviewWrapper>
      </ContentWrapper>
    </PhonePresentation>
  );
};

export { LoginScreenPreview };
