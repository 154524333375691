import { useRef, useState } from "react";
import moment from "moment";
import { debounce } from "lodash";

import { DEFAULT_DEBOUNCE_DELAY } from "constants/defaults";
import { ERRORS } from "constants/content";
import config from "config/app";

const validateDateInput = value => moment(value, config.inputDateFormat).isValid() || moment(value, config.timeFormat).isValid();

export const useDatePickerPropsCreator = (type, props) => {
  const [inputFocused, setInputFocused] = useState(false);
  const [pickerButtonFocused, setPickerButtonFocused] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const { value, error, helperText } = props;
  const debouncedFocusToggle = useRef(
    debounce((focusSetter, isFocused) => {
      focusSetter(isFocused);
    }, DEFAULT_DEBOUNCE_DELAY),
  );

  const isDateValid = validateDateInput(value);
  const inputFormat = (() => {
    if (type === "dateTimeFormat") return config.dateTimeFormat;
    if (type !== "date") return config.timeFormat;
    if (!isDateValid || inputFocused || pickerButtonFocused) return config.inputDateFormat;
    return config.dateFormat;
  })();
  const invalidFormatError = (() => {
    if (inputFocused || isDateValid) return "";
    if (!isDateValid && inputFormat === config.inputDateFormat) return ERRORS.invalidDate;
    return ERRORS.invalidTime;
  })();

  const handleCloseDialog = (e, source) => {
    if (source !== "backdropClick") setIsOpen(false);
  };

  return {
    DialogProps: { onClose: handleCloseDialog },
    onOpen: () => setIsOpen(true),
    onClose: () => setIsOpen(false),
    onAccept: () => setIsOpen(false),
    open: isOpen,
    inputVariant: "outlined",
    onBlur: () => debouncedFocusToggle.current(setInputFocused, false),
    onFocus: () => debouncedFocusToggle.current(setInputFocused, true),
    error: error || !!invalidFormatError,
    helperText: helperText || invalidFormatError,
    format: inputFormat,
    autoFocus: inputFocused,
    InputAdornmentProps: {
      onFocus: () => debouncedFocusToggle.current(setPickerButtonFocused, true),
      onBlur: () => debouncedFocusToggle.current(setPickerButtonFocused, false),
    },
  };
};
