import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AddIcon from "@material-ui/icons/Add";
import Grid from "@material-ui/core/Grid";

import { LoadingArea } from "feature/panel/_shared/CreationsAndPublishers/styledComponents";

import { useManageTripContext } from "feature/panel/Trips/_shared/ManageTripContext";
import { CreationCreator } from "feature/panel/Trips/_shared/Creations/CreationCreator";
import { CreationsList } from "feature/panel/Trips/_shared/Creations/CreationsList";

import { FormSection, StickyHeaderFormSection } from "components/ui/Forms";
import { SecondaryButton } from "components/ui/Buttons";
import { LoadingScreen } from "components/ui/LoadingScreen/LoadingScreen";

import { closeCreationModal, createTripCreationStart, openCreationModal } from "store/trips/actions";

import { CREATRIONS_URL } from "constants/api";

import { useDefaultReducer } from "hooks/useDefaultReducer";

import { setUrl } from "utils/url";
import { CREATE_CONTENT_LABELS } from "constants/content";
import { CreationsService } from "services/CreationsService";
import { usePermissionsService } from "hooks/usePermissionsService";
import { PERMISSIONS } from "constants/permissions";
import { Message } from "components/ui/Messages";

export const initialCreation = {
  fileName: "",
  showDays: true,
  tableOfContents: false,
  storyboard: {
    show: false,
    imageSize: "adjusted",
  },
  locations: false,
  frontPage: {
    show: false,
    body: "",
    headline: "",
  },
  lastPage: {
    show: false,
    body: "",
    headline: "",
  },
};

const addtionalActions = (state, action) => ({
  setAllValues: {
    ...state,
    ...action.payload,
  },
});

const Creations = () => {
  const dispatch = useDispatch();
  const permissionsService = usePermissionsService();
  const [apiUrl, setApiUrl] = useState(null);
  const { state, dispatch: localDispatch, setValueFor } = useDefaultReducer(initialCreation, addtionalActions);

  const isCreationModalOpened = useSelector(({ trips }) => trips.creationModal.opened);

  const { userId, currentPasscode, passcode, field1, field2, field3, field4, editMode, is_wiped, vamoos_id } = useManageTripContext();

  const canEdit = permissionsService.can(PERMISSIONS.actions.update, PERMISSIONS.sections.vamoosList, vamoos_id);

  const handleToggleModal = payload => {
    if (isCreationModalOpened) {
      dispatch(closeCreationModal());
    } else {
      const isEditModeToggle = payload?.id;
      const creation = isEditModeToggle ? CreationsService.setupExistingCreation(payload) : { ...initialCreation, id: undefined };
      // id: undefined removes existing id for reducer when id existed before
      localDispatch({ type: "setAllValues", payload: creation });
      dispatch(openCreationModal());
    }
  };

  const handleModalConfirm = () => {
    const data = CreationsService.setupCreationPayload({
      ...state,
      locations: state.locations,
      field1,
      field2,
      field3,
      field4,
    });
    dispatch(createTripCreationStart({ url: apiUrl, data }));
    dispatch(closeCreationModal());
    localDispatch({ type: "resetAllValues" });
  };

  const AddCreationButton = () =>
    !is_wiped &&
    (editMode ? canEdit : true) && (
      <SecondaryButton onClick={handleToggleModal}>
        <AddIcon />
        {CREATE_CONTENT_LABELS.creation}
      </SecondaryButton>
    );

  const prepareUrl = () => {
    if (userId && currentPasscode && passcode) {
      const url = setUrl(CREATRIONS_URL, { operator_code: userId, reference_code: currentPasscode }, true);
      setApiUrl(url);
    }
  };

  useEffect(prepareUrl, [userId, passcode]);

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} md={10}>
        {editMode ? (
          <StickyHeaderFormSection title="List of Creations" headerActions={AddCreationButton}>
            {apiUrl ? (
              <CreationsList handleEditStart={creationToEdit => handleToggleModal(creationToEdit)} url={apiUrl} />
            ) : (
              <LoadingArea>
                <LoadingScreen />
              </LoadingArea>
            )}
            <CreationCreator
              open={isCreationModalOpened}
              onCancel={handleToggleModal}
              onConfirm={handleModalConfirm}
              creation={state}
              setCreationValue={setValueFor}
            />
          </StickyHeaderFormSection>
        ) : (
          <FormSection title="List of Creations">
            <Message type="warning" text="Creations section is only available for already existing itineraries" />
          </FormSection>
        )}
      </Grid>
    </Grid>
  );
};

export { Creations };
