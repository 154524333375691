// @ts-check

import { isString } from "contracts/types";
import { VamoosImage } from "./VamoosImage";

/**
 * @param {IconResponseDto} dto 
 * @returns {Icon} Icon domain object
 */
export function Icon(dto) {
  /**
   * @type {Icon}
   */
  const domain = {};

  domain.id = dto.id;
  domain.name = dto.name;
  domain.section = dto.section;
  domain.image = isString(dto.url) ? dto.url : VamoosImage(dto.url, dto.id);
  domain.isDefault = dto.is_default;
  domain.createdAt = dto.created_at;
  domain.updatedAt = dto.updated_at;

  return domain;
}