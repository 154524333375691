import { Logger } from "./Logger";

/* eslint-disable class-methods-use-this */
class EventBusService {
  on(eventClass, callback) {
    document.addEventListener(eventClass.name, () => callback(eventClass.noPopup));
  }

  dispatch(event, data = null) {
    Logger.debug(`Event ${event.name} has been triggered: `, event);
    document.dispatchEvent(new CustomEvent(event.name, { detail: data }));
  }

  remove(eventClass, callback) {
    document.removeEventListener(eventClass.name, callback);
  }
}

const EventBus = new EventBusService();

export { EventBus };
