import { HttpClient } from "services/application/httpClient/httpClient";
import { checkErrorExistFor } from "utils/validation";
import { getDeepestObjectFragment } from "utils/object";
import store from "store/store";

import { deleteLastLock, setLastLock } from "../../../store/locks/actions";

export const IDLE_TIME = 1000 * 60 * 5;

export const checkSectionHasError = (listOfFields, errors) =>
  listOfFields.some(field => {
    if (field.includes(".")) {
      const fieldElements = field.split(".");
      const errorObject = getDeepestObjectFragment(errors, fieldElements);
      const finalField = fieldElements.pop();
      if (checkErrorExistFor(finalField, errorObject)) return true;
    } else if (checkErrorExistFor(field, errors)) return true;
    return false;
  }) || undefined;

export const getSectionsWithErrorsNames = (errors, sectionsList) => {
  const sections = sectionsList.reduce((acc, section) => {
    if (checkSectionHasError(section.errorNames, errors)) acc.push(section.label);
    return acc;
  }, []);
  const isPlural = sections.length > 1;
  const errorSections = sections.join(", ");

  return [errorSections, isPlural];
};

export const setLock = async ({ id }, dispatch) => {
  const {
    auth: {
      user: { id: userId },
    },
  } = store.getState();

  const res = await HttpClient.put(`/lock/${id}`);
  if (dispatch) dispatch(setLastLock({ vamoos_id: id, timestamp: new Date(), userId }));

  return res.data;
};

export const deleteLock = async dispatch => {
  const {
    locks: {
      lastLock: { vamoos_id, userId: userWholocked },
    },
    auth: {
      user: { id: userId },
    },
  } = store.getState();

  if (userId) {
    if (userId === userWholocked) {
      await HttpClient.delete(`/lock/${vamoos_id}`);
      if (dispatch) dispatch(deleteLastLock());
    }
  } else {
    await HttpClient.delete(`/lock/${vamoos_id}`);
    if (dispatch) dispatch(deleteLastLock());
  }
};

export const isLocked = async data => {
  const {
    locks: {
      lastLock: { userId: userWholocked },
    },
  } = store.getState();
  const id = Array.isArray(data.queryKey) ? data.queryKey[1] : data;
  // const { userId, lockUser } = Array.isArray(data.queryKey) ? data.queryKey[2] : userData;

  try {
    const res = await HttpClient.get(`/lock/${id}`);

    const date = new Date(res.data.updated_at);
    const dateDiff = Date.now() - date.getTime();

    if (dateDiff > IDLE_TIME) {
      return { lock: false };
    }
    if (res.data?.lock_name) return { ...res.data, lock: true };
  } catch (e) {
    const wasEdited = userWholocked; // If there is data in redux store that lock exist, means that trip was edited by someone else

    if (e.response?.status === 404) return { lock: false, wasEdited };
  }
};

export const onActive = async (data, showModal) => {
  const { id, userId, dispatch, lockUser } = data;

  const res = await isLocked(id, { userId, lockUser });
  const isCurrentUserBuNotSession = res.is_current_user && !res.is_current_session;

  const modal = {
    visible: true,
    editor: res?.current_user,
    isCurrentUserBuNotSession,
  };

  if (res.lock && (!res.is_current_user || isCurrentUserBuNotSession)) {
    // if new lock show modal

    showModal({
      ...modal,
      type: "new-lock",
    });
    dispatch(setLastLock({ vamoos_id: id, userId: res.api_key_id }));
  } else if (res.lock && res.is_current_user) {
    await setLock({ id }, dispatch);
  } else {
    await setLock({ id }, dispatch);
    if (res.wasEdited)
      showModal({
        ...modal,
        type: "reload",
      });
  }
};
