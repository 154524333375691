import React, { createContext, useContext, useState } from "react";
import { useParams } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import { MobilePreview } from "feature/panel/_shared/MobilePreview/MobilePreview";
import { CustomLogo } from "feature/panel/_shared/CustomLogo/CustomLogo";

import { GeneralInfoForm } from "feature/panel/Stays/_shared/General/GeneralInfoForm";
import { HotelInfoButton } from "feature/panel/Stays/_shared/General/HotelInfoButton";
import { AddressAndCoordinates } from "feature/panel/Stays/_shared/General/AddressAndCoordinates";
import { VamoosListingInformation } from "feature/panel/Stays/_shared/General/VamoosListingInformation";

import { FormSection } from "components/ui/Forms/FormSection";
import { Message } from "components/ui/Messages/Message";
import { H5 } from "components/ui/Typography/Typography";

import { setStayWizardBackground, setStayWizardLogo } from "store/stayWizard/actions";

import { PANEL_TRIPS_ACTION_MESSAGES, STAYS_FORM_LABELS } from "constants/content";

import { usePermissionsService } from "hooks/usePermissionsService";
import { PERMISSIONS } from "constants/permissions";
import { LanguageContext } from "components/_shared/LanguageSelector/LanguageContext";

import { SwitchConnect } from "./SwitchConnect";

import { LanguageLock } from "../LanguageLock";

const Line = styled.div`
  text-align: center;
  width: 100%;
  height: auto;
  white-space: pre-wrap;
`;

const StayMobilePreviewBridgeContext = createContext();

const General = () => {
  const params = useParams();
  const isEditMode = params.operator_code !== undefined;
  const dispatch = useDispatch();
  const permissionsService = usePermissionsService();

  const { currentLanguage } = useContext(LanguageContext);

  const isStayDefaultLanguage = useSelector(({ stayWizard }) =>
    stayWizard.defaultVariant.language ? stayWizard.defaultVariant.language === currentLanguage.code : true,
  );

  const isMasterStayActive = useSelector(({ stayWizard }) => stayWizard.defaultVariant.isActive);

  const vamoosId = useSelector(({ stayWizard }) => stayWizard.form.vamoosId);
  const isWiped = useSelector(({ stayWizard }) => stayWizard.form.isWiped);
  const logo = useSelector(({ stayWizard }) => stayWizard.form.logo);
  const defaultLogo = useSelector(({ stayWizard }) => stayWizard.defaultVariant.logo);
  const background = useSelector(({ stayWizard }) => stayWizard.form.background);
  const defaultBackground = useSelector(({ stayWizard }) => stayWizard.defaultVariant.background);
  const homeDescription = useSelector(({ stayWizard }) => stayWizard.form.homeDescription || stayWizard.defaultVariant.homeDescription);

  const canEdit = permissionsService.can(PERMISSIONS.actions.update, PERMISSIONS.sections.vamoosList, vamoosId);

  const errors = useSelector(({ stayWizard }) => stayWizard.errors);

  const [showArchivedInfo, setShowArchivedInfo] = useState(true);
  const toggleMessageShow = () => setShowArchivedInfo(!showArchivedInfo);

  const archivedMessage = !isMasterStayActive && showArchivedInfo && !isWiped && canEdit && (
    <Message text={PANEL_TRIPS_ACTION_MESSAGES.archivedInformation} type="error" onDelete={toggleMessageShow} />
  );

  const setLogo = (fieldName, newFile) => dispatch(setStayWizardLogo(newFile));
  const setBackground = newBackground => dispatch(setStayWizardBackground(newBackground));

  const colorVariant = background || defaultBackground ? "white" : "grey50";

  const contextBridgeValue = {
    errors: errors.general,
  };

  return (
    <Grid container justifyContent="center">
      {archivedMessage}
      <Grid item md={12} lg={10}>
        <FormSection title={STAYS_FORM_LABELS.generalInformation} headerWidth={7}>
          <Grid container xs={12} md={7} item>
            {vamoosId && isStayDefaultLanguage && <SwitchConnect disabled={isWiped || (isEditMode && !canEdit)} />}
          </Grid>
          <Grid container justifyContent="space-between" spacing={10}>
            <Grid item xs={12} sm={12} md={7}>
              <GeneralInfoForm isEditMode={isEditMode} />
            </Grid>
            <Grid item xs={12} sm={12} md={5}>
              <StayMobilePreviewBridgeContext.Provider value={contextBridgeValue}>
                <MobilePreview
                  context={StayMobilePreviewBridgeContext}
                  type="stays"
                  disabled={isWiped || (isEditMode && !canEdit)}
                  background={background}
                  defaultBackground={(!isStayDefaultLanguage && defaultBackground) || null}
                  homeDescription={homeDescription}
                  onBackgroundChange={setBackground}
                >
                  <Line>
                    <H5 cv={colorVariant}>{homeDescription}</H5>
                  </Line>
                </MobilePreview>
              </StayMobilePreviewBridgeContext.Provider>
            </Grid>
          </Grid>
        </FormSection>

        <FormSection title={STAYS_FORM_LABELS.addressAndCoordinates} divider="top">
          <AddressAndCoordinates disabled={isWiped || (isEditMode && !canEdit)} />
        </FormSection>

        <FormSection title={STAYS_FORM_LABELS.propertyInfoButton} divider="top">
          <HotelInfoButton disabled={isWiped || (isEditMode && !canEdit)} />
        </FormSection>

        <FormSection
          title={
            <>
              {STAYS_FORM_LABELS.overlayLogo}
              {!isStayDefaultLanguage && <LanguageLock defaultLanguage={{}} />}
            </>
          }
          divider="top"
        >
          <CustomLogo
            logo={(isStayDefaultLanguage ? logo : defaultLogo) || {}}
            contextValueSetter={setLogo}
            disabled={isWiped || (isEditMode && !canEdit) || !isStayDefaultLanguage}
          />
        </FormSection>

        <FormSection title={STAYS_FORM_LABELS.vamoosListingInformation} divider="top">
          <VamoosListingInformation disabled={isWiped || (isEditMode && !canEdit)} />
        </FormSection>
      </Grid>
    </Grid>
  );
};

export { General };
