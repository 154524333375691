import React, { useRef } from "react";
import styled from "styled-components";
import FolderIcon from "@material-ui/icons/Folder";
import PropTypes from "prop-types";
import { debounce } from "lodash";

import { TitleText3 } from "components/ui/Typography/Typography";
import { colors } from "config/theme/colors";

const FolderBase = styled.div`
  display: flex;
  align-items: center;
  width: ${({ theme }) => theme.setSpacing(48)}px;
  height: ${({ theme }) => theme.setSpacing(10)}px;
  border-radius: ${({ theme }) => theme.setSpacing(2)}px;
  box-shadow: ${({ theme }) => theme.shadows.folder};
  background-color: ${colors.white};
  margin-right: ${({ theme }) => theme.setSpacing(2)}px;
  margin-bottom: ${({ theme }) => theme.setSpacing(2)}px;

  .MuiSvgIcon-root {
    margin-left: ${({ theme }) => theme.setSpacing(2)}px;
    color: ${colors.grey50};
  }
`;

const FolderTitle = styled(TitleText3)`
  && {
    margin-left: ${({ theme }) => theme.setSpacing(4)}px;
    margin-right: ${({ theme }) => theme.setSpacing(4)}px;
    user-select: none;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

const Folder = ({ folderName, handleFolderClick }) => {
  const handleClick = useRef(debounce(handleFolderClick, 200));

  return (
    <FolderBase id="folder" onClick={() => handleClick.current()}>
      <FolderIcon />
      <FolderTitle>{folderName}</FolderTitle>
    </FolderBase>
  )
}

Folder.propTypes = {
  folderName: PropTypes.string.isRequired,
  handleFolderClick: PropTypes.func.isRequired
}

export {
  Folder,
  FolderTitle
}