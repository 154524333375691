export const inputs = [
  {
    key: 0,
    label: "Username",
    name: "username",
  },
  {
    key: 1,
    label: "Password",
    name: "password",
    type: "password",
  },
];
