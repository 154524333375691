import React from "react";
import PropTypes from "prop-types";
import { InsertPhotoOutlined } from "@material-ui/icons";

import { formatDisplayableLibraryPopupValues, getFilePreviewUrl } from "utils/library";
import { AttachFilesButton } from "components/ui/Buttons/AttachFilesButton";
import { ChipWithRedirect } from "components/ui/Chips/ChipWithRedirect";
import { ImagePreview, ImagePlaceholder } from "components/ui/Images";

import { RemoveButton } from "feature/panel/_shared/Sortable/StyledComponents/shared";
import {
  StyledSortableListElement,
  StyledTextarea,
  ContentArea,
  DescArea,
} from "feature/panel/_shared/Sortable/StyledComponents/sortableListFormImagesListItem";
import { IMAGES_MIME_TYPES } from "constants/defaults";
import { DEFAULT_LIBRARY_TABS } from "components/ui/LibraryPopup/LibraryPopup";

const SortableListFormImagesListItem = ({ index, item, onUpdate, onDelete, disabled }) => {
  const { id, description, image } = item;
  const handleAttachFile = files => {
    const [newImage] = formatDisplayableLibraryPopupValues(files);
    onUpdate(id, { ...item, image: newImage });
  };

  const handelDeleteImageFile = () => {
    onUpdate(id, { ...item, image: null });
  };

  const handleChangeDesc = ({ target }) => onUpdate(id, { ...item, description: target.value });

  const handleRemoveImage = () => onDelete(id);

  const [width, height] = [134, 120];

  const renderFileDeleter = () => (
    <ChipWithRedirect onDelete={handelDeleteImageFile} item={image} icon={<InsertPhotoOutlined />} maxChar={15} editable={!disabled} />
  );

  const renderFileUploader = () => (
    <AttachFilesButton
      size="small"
      name={`attached-file-${index}`}
      onSelect={handleAttachFile}
      allowedFileTypes={IMAGES_MIME_TYPES}
      tabs={Object.values(DEFAULT_LIBRARY_TABS).splice(0, 2)}
      disabled={disabled}
    />
  );

  return (
    <StyledSortableListElement
      index={index}
      item={item}
      style={{ width: "calc(50% - 8px)", flexShrink: 0, flexGrow: 0, padding: 4 }}
      disabled={disabled}
      withShadow
    >
      <ContentArea>
        <DescArea alignCenter={!image}>
          <StyledTextarea
            label="Image description"
            name={`image-desc-${index}`}
            value={description}
            onChange={handleChangeDesc}
            rows={2}
            disabled={disabled}
          />
          {image && image.file_name ? renderFileDeleter() : renderFileUploader()}
        </DescArea>
        {image && image.file_name ? (
          <ImagePreview image={getFilePreviewUrl(image)} width={`${width}px`} height={`${height}px`} />
        ) : (
          <ImagePlaceholder width={`${width}px`} height={`${height}px`} iconSize={48} />
        )}
      </ContentArea>
      {!disabled && <RemoveButton onClick={handleRemoveImage} />}
    </StyledSortableListElement>
  );
};

SortableListFormImagesListItem.propTypes = {
  index: PropTypes.number.isRequired,
  item: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    image: PropTypes.shape({
      file_name: PropTypes.string,
    }),
    description: PropTypes.string,
  }).isRequired,
  onDelete: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export { SortableListFormImagesListItem };
