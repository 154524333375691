import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { Input } from "components/ui/Forms";
import { MAXIMUM_ICON_NAME_LENGTH } from "constants/defaults";
import { LanguageContext } from "components/_shared/LanguageSelector/LanguageContext";

const IconFieldArea = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: ${({ theme }) => theme.setSpacing(10)}px;
`;

const Icon = styled.img`
  max-width: ${({ theme }) => theme.setSpacing(12)}px;
`;

const IconWrapper = styled.div`
  margin-right: ${({ theme }) => theme.setSpacing(10)}px;
  width: ${({ theme }) => theme.setSpacing(12)}px;
  display: flex;
  justify-content: center;
`;

const LimitedInputArea = styled.div`
  width: 100%;
  max-width: ${({ theme }) => theme.setSpacing(100)}px;
  margin-right: ${({ theme }) => theme.setSpacing(10)}px;
`;

const InfoArea = styled.div`
  width: 100%;
  max-width: ${({ theme }) => theme.setSpacing(60)}px;
  height: ${({ theme }) => theme.setSpacing(12)}px;
  line-height: ${({ theme }) => theme.setSpacing(12)}px;
  background-color: ${({ theme }) => theme.colors.withOpacity(theme.colors.info, 0.2)};
  color: ${({ theme }) => theme.colors.info};
  padding: 0 ${({ theme }) => theme.setSpacing(5)}px;
  border-radius: ${({ theme }) => theme.borderRadius}px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const IconField = ({ icon, onChange }) => {
  const { currentLanguage } = useContext(LanguageContext);

  const [iconName, setIconName] = useState("");
  const handleChange = ({ target }) => {
    const { value } = target;
    setIconName(value.substr(0, MAXIMUM_ICON_NAME_LENGTH));

    if(currentLanguage.isDefault) {
      onChange({ ...icon, name: value });
    } else {
      onChange({ 
        ...icon, 
        localisation: {
          ...icon.localisation,
          [currentLanguage.code]: {
            name: value
          }
        }
      });
    }
  };

  const { name, usageCount, id, url, localisation } = icon;
  const inputName = `icon-label-${id}`;
  const initNameValueForEdition = () => {
    setIconName(currentLanguage.isDefault ? name : localisation?.[currentLanguage?.code]?.name || "")
  };
  const tryDefaultNameAssign = () => {
    if (!iconName && currentLanguage.isDefault) {
      setIconName(name);
    }
  };

  useEffect(initNameValueForEdition, [currentLanguage?.code]);

  return (
    <IconFieldArea>
      <IconWrapper>
        <Icon src={url} alt="" />
      </IconWrapper>
      <LimitedInputArea>
        <Input
          name={inputName}
          value={iconName}
          placeholder={currentLanguage.isDefault && iconName ? undefined : icon.name}
          onChange={handleChange}
          size="large"
          onBlur={tryDefaultNameAssign}
        />
      </LimitedInputArea>
      <InfoArea>
        <InfoOutlinedIcon />
        <span>{`Used in ${usageCount} POIs`}</span>
      </InfoArea>
    </IconFieldArea>
  );
};

IconField.propTypes = {
  icon: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    usageCount: PropTypes.number.isRequired,
    url: PropTypes.string.isRequired,
    localised_name: PropTypes.string,
    localisation: PropTypes.shape({
      name: PropTypes.string
    })
  }).isRequired,
  onChange: PropTypes.func.isRequired,
};

export { IconField, IconFieldArea, Icon, LimitedInputArea, InfoArea };
