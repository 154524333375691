import React, { useContext, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import lodash from "lodash";
import Grid from "@material-ui/core/Grid";
import { useParams } from "react-router-dom";
import moment from "moment";

import config from "config/app";

import { Content } from "components/ui/Content/Content";
import { Input, Textarea, Switch, TimePicker } from "components/ui/Forms";

import { STAYS_FORM_LABELS, USER_ID_EXPLANATION_MESSAGE } from "constants/content";
import {
  DEFAULT_DEBOUNCE_DELAY,
  MAXIMUM_HOME_DESCRIPTION_LENGTH,
  MAXIMUM_USER_ID_LENGTH,
  MINIMUM_USER_ID_LENGTH,
} from "constants/defaults";

import { checkIsUserIdAvailableStart } from "store/stays/actions";
import {
  setStayWizardPropertyName,
  setStayWizardUserId,
  setStayWizardHomeDescription,
  setStayWizardPhone,
  setStayWizardEmail,
  setStayWizardWebsite,
  setStayWizardTwitter,
  setStayWizardFacebook,
  setStayWizardInstagram,
  toggleStayWizardRequiredPersonalDetails,
  stayWizardSetCheckInTime,
  stayWizardAlertEmailsToggleInclude,
  stayWizardAlertEmailsSetEmails,
  setStayWizardBrandingProfileId,
} from "store/stayWizard/actions";
import { usePermissionsService } from "hooks/usePermissionsService";
import { PERMISSIONS } from "constants/permissions";
import { SelectBrandProfile } from "feature/panel/_shared/SelectBranding/SelectBrandProfile";
import { getBrandingListStart } from "store/branding/actions";
import { LanguageContext } from "components/_shared/LanguageSelector/LanguageContext";

import { LanguageLock } from "../LanguageLock";

const GeneralInfoForm = () => {
  const dispatch = useDispatch();
  const permissionsService = usePermissionsService();
  const { operator_code: operatorCode } = useParams();

  const { currentLanguage } = useContext(LanguageContext);

  const isStayDefaultLanguage = useSelector(({ stayWizard }) =>
    stayWizard.defaultVariant.language ? stayWizard.defaultVariant.language === currentLanguage.code : true,
  );

  const userId = useSelector(({ stayWizard }) => (isStayDefaultLanguage ? stayWizard.form.userId : stayWizard.defaultVariant.userId));
  const brandingProfileId = useSelector(({ stayWizard }) => stayWizard.form.brandingProfileId);
  const propertyName = useSelector(({ stayWizard }) => stayWizard.form.propertyName);
  const propertyNamePlaceholder = useSelector(({ stayWizard }) => stayWizard.form.propertyName || stayWizard.defaultVariant.propertyName);
  const homeDescription = useSelector(({ stayWizard }) => stayWizard.form.homeDescription);
  const homeDescriptionPlaceholder = useSelector(
    ({ stayWizard }) => stayWizard.form.homeDescription || stayWizard.defaultVariant.homeDescription,
  );
  const phone = useSelector(({ stayWizard }) => stayWizard.form.phone || "");
  const phonePlaceholder = useSelector(({ stayWizard }) => stayWizard.form.phone || stayWizard.defaultVariant.phone);
  const email = useSelector(({ stayWizard }) => stayWizard.form.email || "");
  const emailPlaceholder = useSelector(({ stayWizard }) => stayWizard.form.email || stayWizard.defaultVariant.email);
  const website = useSelector(({ stayWizard }) => stayWizard.form.website || "");
  const websitePlaceholder = useSelector(({ stayWizard }) => stayWizard.form.website || stayWizard.defaultVariant.website);
  const instagram = useSelector(({ stayWizard }) => stayWizard.form.instagram || "");
  const instagramPlaceholder = useSelector(({ stayWizard }) => stayWizard.form.instagram || stayWizard.defaultVariant.instagram);
  const facebook = useSelector(({ stayWizard }) => stayWizard.form.facebook || "");
  const facebookPlaceholder = useSelector(({ stayWizard }) => stayWizard.form.facebook || stayWizard.defaultVariant.facebook);
  const twitter = useSelector(({ stayWizard }) => stayWizard.form.twitter || "");
  const twitterPlaceholder = useSelector(({ stayWizard }) => stayWizard.form.twitter || stayWizard.defaultVariant.twitter);
  const checkInTime = useSelector(({ stayWizard }) =>
    isStayDefaultLanguage ? stayWizard.form.checkInTime : stayWizard.defaultVariant.checkInTime,
  );
  const requirePersonalDetails = useSelector(({ stayWizard }) =>
    isStayDefaultLanguage ? stayWizard.form.requirePersonalDetails : stayWizard.defaultVariant.requirePersonalDetails,
  );
  const alertEmailsInclude = useSelector(({ stayWizard }) =>
    isStayDefaultLanguage ? stayWizard.form.alertEmails.include : stayWizard.defaultVariant.alertEmails.include,
  );
  const alertEmailsList = useSelector(({ stayWizard }) =>
    isStayDefaultLanguage ? stayWizard.form.alertEmails.emails : stayWizard.defaultVariant.alertEmails.emails,
  );
  const isWiped = useSelector(({ stayWizard }) => stayWizard.form.isWiped);
  const vamoosId = useSelector(({ stayWizard }) => stayWizard.form.vamoosId);
  const editMode = useSelector(({ stayWizard }) => stayWizard.form.vamoosId !== null);

  const brandsProfiles = useSelector(({ branding }) => branding.brandingList);

  const canEdit = permissionsService.can(PERMISSIONS.actions.update, PERMISSIONS.sections.vamoosList, vamoosId);
  const canOperatorsRead = permissionsService.can(PERMISSIONS.actions.read, PERMISSIONS.sections.operator, PERMISSIONS.sections.operators);

  const showSelectBrand = !(brandsProfiles.length === 1 && brandsProfiles[0].isDefault);

  const { errors } = useSelector(state => state.stays); // TODO: Validation instead of error handling
  const stayWizardErrors = useSelector(({ stayWizard }) => stayWizard.errors);

  const homeDescriptionRef = useRef(null);
  const checkAvailableUserIds = useRef(lodash.debounce(params => dispatch(checkIsUserIdAvailableStart(params)), DEFAULT_DEBOUNCE_DELAY));

  const [selectedBrandId, setSelectedBrandId] = useState(brandingProfileId || null);
  const [brandingLoaded, setBrandingLoaded] = useState(false);

  const handleHomeDescriptionChange = ({ target }) => {
    const { value } = target;

    if (value.length <= MAXIMUM_HOME_DESCRIPTION_LENGTH || value.length < homeDescription.length) {
      dispatch(
        setStayWizardHomeDescription({
          homeDescription: value,
        }),
      );
    }
  };

  const handleHomeDescriptionPaste = event => {
    event.preventDefault();
    const { clipboardData } = event;
    const valueFromClipboard = clipboardData.getData("text/plain");
    const selection = window.getSelection().toString();

    let newText = homeDescription;

    const getText = text => {
      return text.length <= MAXIMUM_HOME_DESCRIPTION_LENGTH ? text : text.substring(0, MAXIMUM_HOME_DESCRIPTION_LENGTH);
    };

    if (selection && homeDescription.includes(selection)) {
      newText = homeDescription.replace(selection, valueFromClipboard);
    } else if (homeDescriptionRef.current.selectionStart < homeDescription.length) {
      const beginning = homeDescription.slice(0, homeDescriptionRef.current.selectionStart);
      const ending = homeDescription.slice(homeDescriptionRef.current.selectionStart);
      newText = `${beginning}${valueFromClipboard}${ending}`;
    } else {
      newText = homeDescription.length > 0 ? `${homeDescription} ${valueFromClipboard}` : valueFromClipboard;
    }

    dispatch(setStayWizardHomeDescription({ homeDescription: getText(newText) }));
  };

  const handleChangePropertyName = ({ target }) => {
    dispatch(
      setStayWizardPropertyName({
        propertyName: target.value,
        language: currentLanguage,
      }),
    );
  };

  const handleChangePhone = ({ target }) => {
    dispatch(
      setStayWizardPhone({
        phone: target.value,
        language: currentLanguage,
      }),
    );
  };

  const handleChangeEmail = ({ target }) => {
    dispatch(
      setStayWizardEmail({
        email: target.value,
        language: currentLanguage,
      }),
    );
  };

  const handleChangeWebsite = ({ target }) => {
    dispatch(
      setStayWizardWebsite({
        website: target.value,
        language: currentLanguage,
      }),
    );
  };

  const handleChangeTwitter = ({ target }) => {
    dispatch(
      setStayWizardTwitter({
        twitter: target.value,
        language: currentLanguage,
      }),
    );
  };

  const handleChangeFacebook = ({ target }) => {
    dispatch(
      setStayWizardFacebook({
        facebook: target.value,
        language: currentLanguage,
      }),
    );
  };

  const handleChangeInstagram = ({ target }) => {
    dispatch(
      setStayWizardInstagram({
        instagram: target.value,
        language: currentLanguage,
      }),
    );
  };

  const handleUserIdChange = event => {
    const {
      target: { value },
    } = event;
    const parsedUserId = value.replaceAll("-", "");

    if (operatorCode !== parsedUserId && parsedUserId.length >= MINIMUM_USER_ID_LENGTH) {
      checkAvailableUserIds.current(parsedUserId);
    }
    if (operatorCode === parsedUserId || parsedUserId.length <= MAXIMUM_USER_ID_LENGTH) {
      dispatch(setStayWizardUserId(parsedUserId));
    }
  };

  const handleChangeRequiredPersonalDetails = () => {
    dispatch(toggleStayWizardRequiredPersonalDetails());
  };

  const handleAlertEmailsToggle = () => {
    dispatch(stayWizardAlertEmailsToggleInclude());
  };

  const handleAlertEmailsListChange = ({ target }) => {
    dispatch(stayWizardAlertEmailsSetEmails(target.value));
  };

  const handleChangeCheckInTime = momentValue => {
    const time = momentValue && momentValue.isValid() ? momentValue.format(config.timeFormat) : null;

    dispatch(stayWizardSetCheckInTime(time));
  };

  const handleBrandProfileChange = ({ target }) => {
    if (brandingLoaded) {
      const brandProfileIdValue = target.value || null;
      const isDefaultBrand = brandsProfiles?.find(profile => profile.id === target.value)?.isDefault;
      setSelectedBrandId(brandProfileIdValue);
      dispatch(setStayWizardBrandingProfileId(isDefaultBrand ? null : target.value));
    }
  };

  const getBrandProfiles = () => {
    if (canOperatorsRead) {
      dispatch(getBrandingListStart());
    }
  };
  const initBrandingProfiles = () => {
    if (!brandingLoaded && brandsProfiles?.length) {
      const currentBrand = brandsProfiles.find(
        profile => (brandingProfileId === null && profile.isDefault) || profile.id === brandingProfileId,
      );
      setSelectedBrandId(currentBrand.id);
      setBrandingLoaded(true);
    }
  };

  useEffect(getBrandProfiles, []);
  useEffect(initBrandingProfiles, [brandsProfiles?.length, brandingProfileId]);

  const inputs = {
    userId: {
      label: STAYS_FORM_LABELS.fields.userId,
      type: "text",
      name: "userId",
      onChange: handleUserIdChange,
      value: userId,
      error: stayWizardErrors.general?.userId,
      helperText: errors?.operator_code || stayWizardErrors.general?.userId || USER_ID_EXPLANATION_MESSAGE,
      disabled: (editMode && !canEdit) || !isStayDefaultLanguage,
      isRequired: isStayDefaultLanguage,
    },
    hotelName: {
      label: STAYS_FORM_LABELS.fields.propertyName,
      type: "text",
      name: "propertyName",
      value: propertyName,
      labelShrink: isStayDefaultLanguage ? undefined : propertyNamePlaceholder || undefined,
      placeholder: isStayDefaultLanguage ? "" : propertyNamePlaceholder,
      error: stayWizardErrors.general?.propertyName,
      helperText: stayWizardErrors.general?.propertyName,
      onChange: handleChangePropertyName,
      disabled: isWiped || (editMode && !canEdit),
      isRequired: isStayDefaultLanguage,
    },
    homeScreenDesc: {
      label: STAYS_FORM_LABELS.fields.homeDescription,
      name: "homeDescription",
      value: homeDescription,
      labelShrink: isStayDefaultLanguage ? undefined : homeDescriptionPlaceholder || undefined,
      placeholder: isStayDefaultLanguage ? "" : homeDescriptionPlaceholder,
      onChange: handleHomeDescriptionChange,
      onPaste: handleHomeDescriptionPaste,
      inputRef: homeDescriptionRef,
      error: stayWizardErrors.general?.homeDescription,
      helperText: stayWizardErrors.general?.homeDescription,
      disabled: isWiped || (editMode && !canEdit),
    },
    contactPhone: {
      label: STAYS_FORM_LABELS.fields.contactPhoneNumber,
      type: "text",
      name: "phone",
      value: phone,
      labelShrink: isStayDefaultLanguage ? undefined : phonePlaceholder || undefined,
      placeholder: isStayDefaultLanguage ? "" : phonePlaceholder,
      onChange: handleChangePhone,
      error: stayWizardErrors.general?.phone,
      helperText: stayWizardErrors.general?.phone,
      disabled: isWiped || (editMode && !canEdit),
    },
    constactEmail: {
      label: STAYS_FORM_LABELS.fields.contactEmail,
      type: "text",
      name: "email",
      value: email,
      placeholder: isStayDefaultLanguage ? "" : emailPlaceholder,
      labelShrink: isStayDefaultLanguage ? undefined : emailPlaceholder || undefined,
      onChange: handleChangeEmail,
      error: stayWizardErrors.general?.email,
      helperText: stayWizardErrors.general?.email,
      disabled: isWiped || (editMode && !canEdit),
    },
    website: {
      label: STAYS_FORM_LABELS.fields.website,
      type: "url",
      name: "website",
      value: website,
      placeholder: isStayDefaultLanguage ? "" : websitePlaceholder,
      labelShrink: isStayDefaultLanguage ? undefined : websitePlaceholder || undefined,
      onChange: handleChangeWebsite,
      error: stayWizardErrors.general?.website,
      helperText: stayWizardErrors.general?.website,
      disabled: isWiped || (editMode && !canEdit),
    },
    twitter: {
      label: STAYS_FORM_LABELS.fields.twitter,
      type: "text",
      name: "twitter",
      value: twitter,
      placeholder: isStayDefaultLanguage ? "" : twitterPlaceholder,
      labelShrink: isStayDefaultLanguage ? undefined : twitterPlaceholder || undefined,
      onChange: handleChangeTwitter,
      error: stayWizardErrors.general?.twitter,
      helperText: stayWizardErrors.general?.twitter,
      disabled: isWiped || (editMode && !canEdit),
    },
    facebook: {
      label: STAYS_FORM_LABELS.fields.facebook,
      type: "text",
      name: "facebook",
      value: facebook,
      placeholder: isStayDefaultLanguage ? "" : facebookPlaceholder,
      labelShrink: isStayDefaultLanguage ? undefined : facebookPlaceholder || undefined,
      onChange: handleChangeFacebook,
      error: stayWizardErrors.general?.facebook,
      helperText: stayWizardErrors.general?.facebook,
      disabled: isWiped || (editMode && !canEdit),
    },
    instagram: {
      label: STAYS_FORM_LABELS.fields.instagram,
      type: "text",
      name: "instagram",
      value: instagram,
      placeholder: isStayDefaultLanguage ? "" : instagramPlaceholder,
      labelShrink: isStayDefaultLanguage ? undefined : instagramPlaceholder || undefined,
      onChange: handleChangeInstagram,
      error: stayWizardErrors.general?.instagram,
      helperText: stayWizardErrors.general?.instagram,
      disabled: isWiped || (editMode && !canEdit),
    },
    loginEmails: {
      label: "Login messages",
      type: "text",
      name: "loginEmails",
      value: alertEmailsList,
      onChange: handleAlertEmailsListChange,
      error: stayWizardErrors?.general?.alertEmails?.emails,
      helperText: stayWizardErrors?.general?.alertEmails?.emails || STAYS_FORM_LABELS.explanations.loginEmails,
      disabled: isWiped || (editMode && !canEdit) || !isStayDefaultLanguage,
    },
  };

  return (
    <Grid container>
      {canOperatorsRead && showSelectBrand && (
        <Content margin={10}>
          <SelectBrandProfile
            brandingProfileId={selectedBrandId}
            onBrandProfileChange={handleBrandProfileChange}
            disabled={isWiped || (editMode && !canEdit)}
          />
        </Content>
      )}
      <Content margin={10}>
        <Input {...inputs.userId} />
        {!isStayDefaultLanguage && <LanguageLock wide defaultLanguage={{}} />}
      </Content>
      <Content margin={10}>
        <Input {...inputs.hotelName} />
      </Content>
      <Content margin={10}>
        <Textarea {...inputs.homeScreenDesc} rows={3} />
      </Content>
      <Content margin={10}>
        <Input {...inputs.contactPhone} />
      </Content>
      <Content margin={10}>
        <Input {...inputs.constactEmail} />
      </Content>
      <Content margin={10}>
        <Input {...inputs.website} />
      </Content>
      <Content margin={10}>
        <Input {...inputs.twitter} />
      </Content>
      <Content margin={10}>
        <Input {...inputs.facebook} />
      </Content>
      <Content margin={10}>
        <Input {...inputs.instagram} />
      </Content>
      <Grid item xs={12}>
        <Content container item xs={12} md={6}>
          <Switch
            checked={requirePersonalDetails}
            label={
              <>
                {STAYS_FORM_LABELS.fields.mandateEmailLogin}
                {!isStayDefaultLanguage && <LanguageLock defaultLanguage={{}} />}
              </>
            }
            onChange={handleChangeRequiredPersonalDetails}
            disabled={isWiped || (editMode && !canEdit) || !isStayDefaultLanguage}
          />
        </Content>
      </Grid>
      <Content margin={10}>
        <TimePicker
          fullWidth
          label={STAYS_FORM_LABELS.fields.checkInTime24}
          name="checkInTime"
          value={moment(checkInTime, config.timeFormat)}
          onChange={handleChangeCheckInTime}
          disabled={isWiped || (editMode && !canEdit) || !isStayDefaultLanguage}
          error={stayWizardErrors.general?.checkInTime}
          helperText={stayWizardErrors.general?.checkInTime ? stayWizardErrors.general?.checkInTime : ""}
        />
        {!isStayDefaultLanguage && <LanguageLock wide defaultLanguage={{}} />}
      </Content>
      <Content margin={10}>
        <Input {...inputs.loginEmails} />
        {!isStayDefaultLanguage && <LanguageLock wide defaultLanguage={{}} />}
      </Content>
      <Grid item>
        <Content container item xs={12} md={6}>
          <Switch
            disabled={isWiped || (editMode && !canEdit) || !isStayDefaultLanguage}
            checked={alertEmailsInclude}
            label={
              <>
                {STAYS_FORM_LABELS.fields.includeAdmins}
                {!isStayDefaultLanguage && <LanguageLock defaultLanguage={{}} />}
              </>
            }
            onChange={handleAlertEmailsToggle}
          />
        </Content>
      </Grid>
    </Grid>
  );
};

GeneralInfoForm.defaultProps = {
  editMode: false,
};

GeneralInfoForm.propTypes = {
  editMode: PropTypes.bool,
};

export { GeneralInfoForm };
