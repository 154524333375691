// @ts-check

import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { debounce } from "lodash";

import { PanelTemplate } from "components/templates/Panel/PanelTemplate";
import { Breadcrumbs } from "components/ui/Breadcrumbs";
import { ContextBarTitle } from "components/templates/Panel/ContextBarTitle";

import { LibraryTable } from "feature/panel/Library/_shared/LibraryTable/LibraryTable";
import { Actions } from "feature/panel/Library/_shared/Actions";
import { SearchField } from "feature/panel/_shared/SearchField/SearchField";

import { useTableHandlers } from "hooks/useTableHandlers";

import { prepareLibraryPathParam } from "utils/library";

import { PANEL_LIBRARY_PATH, PANEL_LIBRARY_FOLDER_PATH } from "constants/routes";
import {
  FILE_CREATED_MESSAGE,
  FILES_CREATED_MESSAGE,
  LIBRARY_SEARCH_LABEL,
  ERRORS,
  NODE_DELETED_MESSAGE,
  CHANGES_SAVED_MESSAGE,
} from "constants/content";
import { EXTENDED_SEARCH_INPUT_WIDTH } from "constants/defaults";

import { pushErrorNotification, pushSuccessNotification } from "store/app/actions";
import { LibraryService } from "services/LibraryService";
import { Logger } from "services/application/Logger";
import { useService } from "hooks/useService";

const Library = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  const libraryService = useService(LibraryService);

  const [searchValue, setSearchValue] = useState("");
  const [showSearchResults, setShowSearchResults] = useState(false);

  const { rowsPerPage, page, sortingOrder, sortingBy, handleChangeOrder, handleChangeRowsPerPage, handleChangePage } = useTableHandlers();

  const [libraryItems, setLibraryItems] = useState([]);
  const [count, setCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const fetchLibraryItems = async () => {
    try {
      setIsLoading(true);

      const { items, total_matches } = await libraryService.getListOfFiles(
        page,
        rowsPerPage,
        decodeURIComponent(prepareLibraryPathParam(location.pathname.substring(PANEL_LIBRARY_PATH.length))),
        sortingOrder,
        sortingBy,
        searchValue,
      );

      setShowSearchResults(false);
      setCount(total_matches);
      setLibraryItems(items);
    } catch (e) {
      Logger.debug(e);
      if (e.response.status === 404 && window.location.pathname !== PANEL_LIBRARY_PATH) {
        window.location.assign(PANEL_LIBRARY_PATH);
      } else {
        dispatch(pushErrorNotification(e));
      }
    } finally {
      setIsLoading(false);
    }
  };

  const searchItems = async query => {
    try {
      setIsLoading(true);

      const { items, total_matches } = await libraryService.searchItems(query);

      setCount(total_matches);
      setLibraryItems(items);
      setShowSearchResults(true);
    } catch (e) {
      Logger.debug(e);
    } finally {
      setIsLoading(false);
    }
  };

  const debouncedSearch = useRef(debounce(query => searchItems(query), 300));

  const addFolder = async name => {
    try {
      const newFolder = await libraryService.createFolder(name, location.pathname.substring(PANEL_LIBRARY_PATH.length));

      dispatch(pushSuccessNotification("Folder has been successfully saved"));

      setLibraryItems([newFolder, ...libraryItems]);
      setCount(count + 1);
    } catch (e) {
      /**
       * @todo Needs to support existing folder error
       */
      Logger.debug(e);
      const { error } = e.response?.data;
      let message = error || ERRORS.unknownError;
      if (message === "Node with this name already exists") {
        message = ERRORS.resourceAlreadyExists;
      }
      dispatch(pushErrorNotification(message));
    }
  };

  const handleAddFiles = async files => {
    try {
      const newFiles = await Promise.all(
        files.map(file => {
          return libraryService.addFile(location.pathname.substring(PANEL_LIBRARY_PATH.length), file.url, file.remote_url, file.file_name);
        }),
      );

      const message = files.length > 1 ? FILES_CREATED_MESSAGE : FILE_CREATED_MESSAGE;

      dispatch(pushSuccessNotification(message));

      setLibraryItems([...newFiles, ...libraryItems]);
      setCount(count + newFiles.length);
    } catch (e) {
      dispatch(pushErrorNotification(e));
    }
  };

  const onRemoveItem = async item => {
    try {
      await libraryService.removeItem(item);
      fetchLibraryItems();
      dispatch(pushSuccessNotification(NODE_DELETED_MESSAGE(item.is_folder ? "Folder" : "File")));
    } catch (e) {
      Logger.debug(e);
      dispatch(pushErrorNotification(ERRORS.nodeDeleteFailed(item.name, item.is_folder)));
    }
  };

  const onEditItem = async (selectedItem, name, path, url) => {
    try {
      await libraryService.updateItem(selectedItem, name, path, url);
      fetchLibraryItems();
      dispatch(pushSuccessNotification(CHANGES_SAVED_MESSAGE));
    } catch ({ response }) {
      const { error } = response?.data;
      let message = error || ERRORS.unknownError;

      if (message === "Cannot rename to name of existing file") {
        message = ERRORS.resourceAlreadyExists;
      }

      dispatch(pushErrorNotification(message));
    }
  };

  useEffect(() => {
    if (searchValue) {
      debouncedSearch.current(searchValue);
    }
  }, [searchValue]);

  useEffect(() => {
    if (!searchValue) {
      fetchLibraryItems();
    }
  }, [page, rowsPerPage, searchValue, sortingOrder, sortingBy]);

  useEffect(() => {
    handleChangePage(0);
    fetchLibraryItems();
  }, [location.pathname]);

  const breadcrumbsList = location.pathname
    .substring(PANEL_LIBRARY_PATH.length)
    .split("/")
    .filter(path => path.length > 0);
  const noContentText = searchValue ? "No results found for searched phrase" : "This folder is empty at the moment.";
  const title = searchValue && showSearchResults ? "Search library results" : "Library";
  const contextBar = {
    left: (
      <SearchField onSearchChange={setSearchValue} value={searchValue} label={LIBRARY_SEARCH_LABEL} width={EXTENDED_SEARCH_INPUT_WIDTH} />
    ),
    middle: () =>
      searchValue && showSearchResults ? (
        <ContextBarTitle title={title} />
      ) : (
        <Breadcrumbs
          list={breadcrumbsList}
          rootEl={{ title, url: PANEL_LIBRARY_PATH }}
          currentEl={{ url: PANEL_LIBRARY_FOLDER_PATH }}
          location={location}
        />
      ),
    right: () =>
      !searchValue && (
        <Actions
          addFolder={addFolder}
          addFiles={handleAddFiles}
          path={decodeURIComponent(prepareLibraryPathParam(location.pathname.substring(PANEL_LIBRARY_PATH.length)))}
        />
      ),
  };

  return (
    <PanelTemplate contextBar={contextBar}>
      <LibraryTable
        noContentText={noContentText}
        location={location}
        list={libraryItems}
        isLoading={isLoading}
        tableHandlers={{
          handleChangePage,
          handleChangeRowsPerPage,
          handleChangeOrder,
          page,
          rowsPerPage,
        }}
        onRemoveItem={onRemoveItem}
        onEditItem={onEditItem}
        count={count}
      />
    </PanelTemplate>
  );
};

export { Library };
