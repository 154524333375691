// @ts-check

import React, { useState } from "react";
import Proptypes from "prop-types";
import CreateNewFolderOutlinedIcon from "@material-ui/icons/CreateNewFolderOutlined";
import NoteAddOutlinedIcon from "@material-ui/icons/NoteAddOutlined";

import { generateFileOrWebUrlKey } from "utils/generateFileOrWebUrlKey";
import { PrimaryButton } from "components/ui/Buttons";
import { FolderModal, FOLDER_MODAL_TYPE } from "feature/panel/Library/_shared/FolderModal/FolderModal";
import { DEFAULT_LIBRARY_TABS, LibraryPopup } from "components/ui/LibraryPopup/LibraryPopup";
import { formatDisplayableLibraryPopupValues } from "utils/library";
import { LIBRARY_CONTENT } from "constants/content";
import { usePermissionsService } from "hooks/usePermissionsService";
import { PERMISSIONS } from "constants/permissions";

const Actions = ({ addFolder, addFiles, path }) => {
  const permissionsService = usePermissionsService();

  const [showFolderModal, setShowFolderModal] = useState(false);
  const [libraryPopupOpen, setLibraryPopupOpen] = useState(false);

  const toggleShowFolderModal = () => setShowFolderModal(!showFolderModal);
  const handleUploadFile = files => {
    const newFiles = formatDisplayableLibraryPopupValues(files);
    const filesWithRequestPayloadStructure = newFiles.map(({ file_name, file_url, web_url }) => ({
      file_name,
      [generateFileOrWebUrlKey(file_url)]: file_url || web_url,
    }));
    addFiles(filesWithRequestPayloadStructure);
  };

  const handleLibraryPopupToggle = () => setLibraryPopupOpen(!libraryPopupOpen);

  return (
    permissionsService.can(PERMISSIONS.actions.create, PERMISSIONS.sections.operator, PERMISSIONS.resources.library) && (
      <>
        <PrimaryButton onClick={handleLibraryPopupToggle} mr={6}>
          <NoteAddOutlinedIcon />
          {LIBRARY_CONTENT.file}
        </PrimaryButton>
        <LibraryPopup
          open={libraryPopupOpen}
          multiple
          onCancel={handleLibraryPopupToggle}
          onSelect={handleUploadFile}
          tabs={[DEFAULT_LIBRARY_TABS.files, DEFAULT_LIBRARY_TABS.webUrl]}
          isLibrary
          libraryPath={path}
          upload
        />
        <PrimaryButton onClick={toggleShowFolderModal}>
          <CreateNewFolderOutlinedIcon />
          <span>{LIBRARY_CONTENT.folder}</span>
        </PrimaryButton>
        <FolderModal
          title={LIBRARY_CONTENT.newFolder}
          isOpen={showFolderModal}
          onSave={addFolder}
          onClose={toggleShowFolderModal}
          type={FOLDER_MODAL_TYPE.folder}
          isLibrary
        />
      </>
    )
  );
};

Actions.propTypes = {
  addFolder: Proptypes.func.isRequired,
  addFiles: Proptypes.func.isRequired,
  path: Proptypes.string.isRequired,
};

export { Actions };
