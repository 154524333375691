export class Brand {
  /** @type {number} */
  id;

  /** @type {string} */
  name;

  /** @type {import(./types.d.ts).BrandSection} */
  logo;

  /** @type {import(./types.d.ts).BrandSection} */
  creationsHeader;

  /** @type {import(./types.d.ts).BrandSection} */
  creationsFooter;

  /** @type {string} */
  createdAt;

  /** @type {string} */
  updatedAt;

  /** @type {boolean} */
  isActive;

  /** @type {string} */
  instantViewLabel = "View this itinerary online with interactive maps and weather";

  /** @type {string} */
  instantViewMapsLabel = "For further details, view the interactive map online"
}
