import React from "react";
import PropTypes from "prop-types";
import SendOutlinedIcon from "@material-ui/icons/SendOutlined";

import { GhostButton } from "components/ui/Buttons/GhostButton";

const SendButton = ({ active }) => (
  <GhostButton disabled={!active} size="small" type="submit">
    <SendOutlinedIcon />
    send
  </GhostButton>
);

SendButton.propTypes = {
  active: PropTypes.bool.isRequired,
};

export { SendButton };
