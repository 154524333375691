import { takeEvery, put } from "redux-saga/effects";
import { handleErrorMessages } from "utils/store";

import {
  getAdminOperatorsSuccess,
  getAdminOperatorsFail,
  operatorActiveStatusToggleSuccess,
  operatorActiveStatusToggleFail,
} from "store/adminOperators/actions";
import { setNotification } from "store/app/actions";
import * as actionTypes from "store/adminOperators/actionTypes";

import { ADMIN_OPERATOR_INDEX } from "constants/content";
import notifications from "constants/notifications";

import { HttpClient } from "services/application/httpClient/httpClient";

import { OperatorMapper } from "mappers/OperatorMapper";
import { OperatorService } from "services/OperatorService";
import { OperatorRepository } from "repositories/OperatorRepository";

function* getAdminOperators({ payload }) {
  const operatorService = new OperatorService(new OperatorRepository(), new OperatorMapper());
  const { url } = payload;

  const data = yield operatorService.getAdminOperatorList(url);
  try {
    if (data) {
      yield put(getAdminOperatorsSuccess(data));
    }
  } catch (e) {
    if (!HttpClient.isCancelError(e)) {
      yield put(getAdminOperatorsFail({ errors: handleErrorMessages(e) }));
      yield put(setNotification({ type: "error", message: notifications.resource("operators").get.fail }));
    }
  }
}

function* activityOperatorToggle({ payload }) {
  const operatorService = new OperatorService(new OperatorRepository(), new OperatorMapper());
  try {
    const { target, id } = payload;
    const data = yield operatorService.updateAdminOperator(id, target);
    
    if (data) {
      const message = target.isActive ? ADMIN_OPERATOR_INDEX.operatorActivated : ADMIN_OPERATOR_INDEX.operatorDeactivated;
      yield put(operatorActiveStatusToggleSuccess({ id, isActive: target.isActive }));
      yield put(setNotification({ type: "success", message }));
    }
  } catch (e) {
    yield put(operatorActiveStatusToggleFail({ errors: handleErrorMessages(e) }));
    yield put(setNotification({ type: "error", message: notifications.resource("operators").update.fail }));
  }
}

export function* adminOperatorsSaga() {
  yield takeEvery(actionTypes.GET_ADMIN_OPERATORS_START, getAdminOperators);
  yield takeEvery(actionTypes.OPERATOR_ACTIVE_STATUS_TOGGLE_START, activityOperatorToggle);
}
