export class BaseMapper {
  /**
   * 
   * @param {*} dto
   * @returns {*}
   */
  fromDtoToDomain(dto) {
    return { ...dto }
  }

  /**
   * 
   * @param {*} domain 
   * @returns {*}
   */
  fromDomainToDto(domain) {
    return { ...domain }
  }
}