import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Textarea, Input } from "components/ui/Forms/Inputs";
import { BodyText } from "components/ui/Typography/Typography";
import { ConfirmationModal } from "components/ui/Modals/ConfirmationModal";
import { NOTIFICATIONS_LABELS } from "constants/content";
import { LanguageSelector } from "components/_shared/LanguageSelector/LanguageSelector";
import { LanguageContext } from "components/_shared/LanguageSelector/LanguageContext";
import { LocalisationService } from "services/LocalisationService";
import { useSelector } from "react-redux";

const UrlWrapper = styled.div`
  margin-top: ${({ theme }) => theme.setSpacing(8)}px;
`;

const LanguageSelectorWrapper = styled.div`
  margin: 0 0 ${({ theme }) => theme.setSpacing(8)}px 0;
`

const IdField = styled(BodyText)`
  && {
    position: absolute;
    top: -${({ theme }) => theme.setSpacing(5)}px;
    right: ${({ theme }) => theme.setSpacing(6)}px;
    transform: translateY(-100%);
  }
`;

const Dialog = styled(ConfirmationModal)`
  && {
    .MuiDialogContent-root {
      position: relative;
      overflow: visible;
    }
  }
`;

const NotificationTemplateFormModal = ({ open, onClose, onNotificationAdd, notification, onNotificationChange, confirmLabel, errors }) => {
  const { currentLanguage, additionalLanguages } = useContext(LanguageContext);
  const defaultLanguageCode = useSelector(({ operator }) => operator.currentOperator.defaultLanguageCode);
  const [localCurrentLanguage, setLocalCurrentLanguage] = useState(currentLanguage);

  const localisationService = new LocalisationService(localCurrentLanguage);

  const { type, id } = notification;
  const content = localisationService.localise(notification, "content");
  const url = localisationService.localise(notification, "url");

  const handleValueChange = ({ target }) => {
    onNotificationChange(target.name, target.value, localCurrentLanguage);
  };

  const switchLanguage = (lang) => {
    setLocalCurrentLanguage({
      ...lang,
      isDefault: lang.code === defaultLanguageCode
    });
  }

  const idField = id && <IdField>{`ID: ${id}`}</IdField>;
  const urlInput = type !== "forced" && (
    <UrlWrapper>
      <Input
        label={NOTIFICATIONS_LABELS.notificationUrl}
        name="url"
        value={url || ""}
        labelShrink={localCurrentLanguage.isDefault ? undefined : (notification.url || undefined)}
        placeholder={localCurrentLanguage.isDefault ? "" : notification.url}
        onChange={handleValueChange}
        size="large"
        error={localCurrentLanguage.isDefault ? !!errors.default?.url : !!errors[localCurrentLanguage.code]?.url}
        helperText={localCurrentLanguage.isDefault ? errors.default?.url : errors[localCurrentLanguage.code]?.url}
      />
    </UrlWrapper>
  );

  const displayableTypeName = type === "gps" ? type.toUpperCase() : type;
  const modalTitle = `${id ? "Edit" : "New"} ${displayableTypeName} notification`;

  useEffect(() => {
    if(open) {
      setLocalCurrentLanguage(currentLanguage);
    }
  }, [open])

  return open ? (
    <Dialog open={open} onCancel={onClose} title={modalTitle} confirmLabel={confirmLabel} onConfirm={onNotificationAdd} fullWidth>
      {idField}
      {additionalLanguages.length > 0 && (
        <LanguageSelectorWrapper>
          <LanguageSelector currentLanguage={localCurrentLanguage} onChange={switchLanguage} errors={Object.keys(errors)} />
        </LanguageSelectorWrapper>
      )}
      <Textarea
        name="content"
        value={content || ""}
        labelShrink={localCurrentLanguage.isDefault ? undefined : (notification.content || undefined)}
        placeholder={localCurrentLanguage.isDefault ? "" : notification.content}
        onChange={handleValueChange}
        label={NOTIFICATIONS_LABELS.notificationText}
        rows={4}
        fullWidth
        autoFocus
        error={localCurrentLanguage.isDefault ? !!errors.default?.content : !!errors[localCurrentLanguage.code]?.content}
        helperText={localCurrentLanguage.isDefault ? errors.default?.content : errors[localCurrentLanguage.code]?.content}
        isRequired
      />
      {urlInput}
    </Dialog>
  ) : null;
};

NotificationTemplateFormModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onNotificationAdd: PropTypes.func.isRequired,
  notification: PropTypes.shape().isRequired,
  onNotificationChange: PropTypes.func.isRequired,
  confirmLabel: PropTypes.string.isRequired,
  errors: PropTypes.shape().isRequired,
};

export { NotificationTemplateFormModal, Dialog };
