import React, { useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { isLocked, deleteLock, onActive, IDLE_TIME } from "feature/panel/_shared/helpers";
import { useIdleTimer } from "react-idle-timer";
import { useDispatch, useSelector } from "react-redux";

export const useLock = ({ id, getUpdated, setShowModal }) => {
  const dispatch = useDispatch();
  const {
    lastLock: { timestamp, userId: lockUser },
  } = useSelector(state => state.locks);
  const {
    user: { id: userId },
  } = useSelector(state => state.auth);

  // useQuery(["lock", id], isLocked, {
  //   refetchOnWindowFocus: false,
  //   refetchOnMount: true,
  //   // enabled: id && userId ? true : false,
  //   onSuccess: async lockData => {
  //   },
  // });

  useIdleTimer({
    timeout: IDLE_TIME,
    onActive: async () => {
      const res = await getUpdated();
      await onActive({ id, dispatch, updated_at: res.updated_at, lockUser, timestamp, userId }, setShowModal);
    },
    debounce: 500,
  });

  useEffect(() => {
    async function handleActive() {
      if (id && userId) await onActive({ id, dispatch, lockUser, timestamp, userId }, setShowModal);
    }

    if (id && userId) handleActive();
  }, [id, userId]);

  useEffect(() => {
    return async () => {
      await deleteLock(dispatch);
    };
  }, []);
};
