import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import MenuItem from "@material-ui/core/MenuItem";

import { ConfirmationModal } from "components/ui/Modals/ConfirmationModal";
import { Checkbox, Input, Select, Textarea } from "components/ui/Forms";
import { Content } from "components/ui/Content";
import { FileField } from "feature/panel/Stays/_shared/DailyDirectoryVoucherShared/FileField";
import { DirectoryValidator } from "services/domain/DirectoryValidator";
import { isObjectEmpty } from "utils/object";
import ActionManageElement from "feature/panel/Stays/_shared/DailyDirectoryVoucherShared/ActionManageElement";
import { formatDisplayableLibraryPopupValues } from "utils/library";
import { DEFAULT_ACTION_ICON_ID } from "feature/panel/Stays/_shared/initStaysState";
import ListItemText from "@material-ui/core/ListItemText";
import { getWeekdaysObjectFromActiveList, getDisplayableDaysText, multipleDaysOptions, WEEKDAY_VALUES_ARRAY } from "utils/directories";
import { VIDEO_FILE_TYPES } from "constants/defaults";
import { DIRECTORY_LABELS, ERRORS } from "constants/content";

const SelectItem = styled(MenuItem)`
  &&& {
    background: none;
    :hover {
      background: none;
    }
  }
`;

const DailyActivityDialog = ({
  open,
  onCancel,
  onConfirm,
  directory,
  setDirectoryValue,
  dailyActivitiesList,
  currentDirectoriesList,
  canEdit,
}) => {
  const [errors, setErrors] = useState({});
  const [isUnique, setIsUnique] = useState(true);
  const [weekdaysMenuOpen, setWeekdaysMenuOpen] = useState(false);
  const [oldName, setOldName] = useState(null);

  const { id, name, content, background, video, type, is_list, actions, weekdays } = directory;

  const displayableDirectoryType = type === "daily" ? "daily activity" : type;
  const title = DIRECTORY_LABELS.popupTitle(id, displayableDirectoryType, is_list);
  const confirmLabel = id ? "Update" : "Save";

  const handleOnCancel = () => {
    onCancel();
    setIsUnique(true);
  };

  const handleBackgroundChange = files => {
    const [newFile] = formatDisplayableLibraryPopupValues(files);
    setDirectoryValue("background", newFile);
  };

  const handleBackgroundRemove = () => setDirectoryValue("background", {});

  const handleVideoChange = files => {
    const [newFile] = formatDisplayableLibraryPopupValues(files);
    setDirectoryValue("video", newFile);
  };

  const handleVideoRemove = () => setDirectoryValue("video", {});
  const handleModalConfirm = () => {
    const errorsList = DirectoryValidator(directory);
    if (isObjectEmpty(errorsList)) {
      onConfirm();
    }
    setErrors(errorsList);
  };

  const clearActionErrors = orderNumber =>
    errors.actions &&
    setErrors({
      ...errors,
      actions: errors.actions.filter(action => action.orderNumber !== orderNumber),
    });

  const handleActionExistence = orderNumber => {
    const actionExist = actions.some(action => action.orderNumber === orderNumber);
    const newAction = {
      name: "",
      icon_id: DEFAULT_ACTION_ICON_ID,
      orderNumber,
    };
    const newActionsList = actionExist ? actions.filter(action => action.orderNumber !== orderNumber) : [...actions, newAction];
    if (actionExist) clearActionErrors(orderNumber);
    setDirectoryValue("actions", newActionsList);
  };

  const handleActionChange = (orderNumber, newValue) => {
    const newActionsList = actions.map(action => (action.orderNumber === orderNumber ? newValue : action));
    setDirectoryValue("actions", newActionsList);
  };

  const directoryActiveWeekdaysArray =
    weekdays &&
    Object.entries(weekdays)
      // eslint-disable-next-line no-unused-vars
      .filter(([weekdayName, isActive]) => isActive)
      .map(([weekdayName]) => weekdayName);

  const toggleWeekdaysMenuOpen = () => setWeekdaysMenuOpen(!weekdaysMenuOpen);

  const handleDayChange = ({ target: { value } }) => {
    const multipleDaysOptionSelected = multipleDaysOptions.some(option => value.includes(option.name));
    const weekdaysObject = getWeekdaysObjectFromActiveList(value, directoryActiveWeekdaysArray);
    setDirectoryValue("weekdays", weekdaysObject);
    if (multipleDaysOptionSelected) {
      toggleWeekdaysMenuOpen();
    }
  };

  const weekdaysSelect = weekdays && (
    <Content>
      <Select
        name={DIRECTORY_LABELS.days}
        multiple
        value={directoryActiveWeekdaysArray}
        onChange={handleDayChange}
        renderValue={getDisplayableDaysText}
        label={DIRECTORY_LABELS.days}
        open={weekdaysMenuOpen}
        onClose={toggleWeekdaysMenuOpen}
        onOpen={toggleWeekdaysMenuOpen}
        disabled={!canEdit}
      >
        {multipleDaysOptions.map(({ name: option }) => (
          <SelectItem key={option} value={option}>
            <ListItemText primary={option} />
          </SelectItem>
        ))}
        {WEEKDAY_VALUES_ARRAY.map(weekday => (
          <SelectItem key={weekday} value={weekday}>
            <Checkbox checked={weekdays[weekday]} />
            <ListItemText primary={weekday} />
          </SelectItem>
        ))}
      </Select>
    </Content>
  );

  const checkUniquenessForNameField = () => {
    if (isUnique) {
      const { name: errorName, ...restOfErrors } = errors;
      setErrors(restOfErrors);
    } else {
      setErrors({ ...errors, name: ERRORS.directoryAlreadyExists });
    }
  };

  const isNameIncluded = (dailyActivities, givenName) => dailyActivities.some(dir => dir.name === givenName);

  const checkIsTitleUnique = currentDirsList => {
    const searchedId = currentDirsList.filter(item => item.name === name).map(i => i.id)[0];
    if (name) {
      setIsUnique((searchedId === id && name === oldName) || !isNameIncluded(dailyActivitiesList, name));
    }
  };

  const handleTitleFieldFocusOut = currentDirsList => {
    checkIsTitleUnique(currentDirsList);
  };

  const handleInputChange = ({ target }) => {
    setDirectoryValue(target.name, target.value);
  };

  const init = () => {
    if (open) {
      setIsUnique(true);
      setErrors({});
      setOldName(name);
    } else {
      document.body.style = null;
    }
  };

  useEffect(checkUniquenessForNameField, [isUnique]);
  useEffect(init, [open]);

  return open ? (
    <ConfirmationModal
      open={open}
      title={title}
      confirmLabel={confirmLabel}
      onCancel={handleOnCancel}
      onConfirm={() => handleModalConfirm()}
      fullWidth
      confirmDisabled={!isUnique || !canEdit}
    >
      <Content>
        <Input
          label={DIRECTORY_LABELS.title}
          value={name}
          onChange={handleInputChange}
          name="name"
          fullWidth
          error={!!errors.name}
          helperText={errors.name || ""}
          onBlur={() => handleTitleFieldFocusOut(currentDirectoriesList)}
          disabled={!canEdit}
          isRequired
        />
      </Content>
      {weekdaysSelect}
      <Content>
        <Textarea
          label={DIRECTORY_LABELS.description}
          value={content}
          onChange={handleInputChange}
          name="content"
          fullWidth
          rows={4}
          rowsMin={4}
          disabled={!canEdit}
        />
      </Content>
      <FileField
        label={DIRECTORY_LABELS.bgImage}
        file={background}
        onFileChange={handleBackgroundChange}
        onFileRemove={handleBackgroundRemove}
        error={errors.background}
        disabled={!canEdit}
      />
      <FileField
        label={DIRECTORY_LABELS.video}
        file={video}
        onFileChange={handleVideoChange}
        onFileRemove={handleVideoRemove}
        allowedFileTypes={VIDEO_FILE_TYPES}
        error={errors.video}
        disabled={!canEdit}
      />
      <ActionManageElement
        action={actions.find(({ orderNumber }) => orderNumber === 1)}
        toggleActionExistence={() => handleActionExistence(1)}
        handleActionChange={action => handleActionChange(1, action)}
        label={DIRECTORY_LABELS.buttonOne}
        errors={errors.actions}
        disabled={!canEdit}
      />
      <ActionManageElement
        action={actions.find(({ orderNumber }) => orderNumber === 2)}
        toggleActionExistence={() => handleActionExistence(2)}
        handleActionChange={action => handleActionChange(2, action)}
        label={DIRECTORY_LABELS.buttonTwo}
        errors={errors.actions}
        disabled={!canEdit}
      />
    </ConfirmationModal>
  ) : null;
};

DailyActivityDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  directory: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string,
    content: PropTypes.string,
    background: PropTypes.shape(),
    video: PropTypes.shape(),
    type: PropTypes.string,
    is_list: PropTypes.bool,
    actions: PropTypes.arrayOf(PropTypes.shape()),
    weekdays: PropTypes.shape(),
  }).isRequired,
  dailyActivitiesList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  setDirectoryValue: PropTypes.func.isRequired,
  currentDirectoriesList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  canEdit: PropTypes.bool.isRequired,
};

export { DailyActivityDialog };
