import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import { Checkbox } from "components/ui/Forms";
import { Content } from "components/ui/Content";
import { BodyText2 } from "components/ui/Typography/Typography";

import { convertToTimeFormat } from "utils/dataConverters";

const FieldWrapper = styled(FormControlLabel)`
  && {
    .MuiFormControlLabel-label {
      font-size: ${({ theme }) => theme.fonts.fontSize.body}px;
    }
  }
`;

const ResultWrapper = styled.div`
  padding: ${({ theme }) => `${theme.setSpacing(6)}px 0`};

  &:last-of-type {
    padding-bottom: 0;
  }

  && {
    .MuiTypography-body1 {
      font-family: Montserrat !important;
      letter-spacing: 0;
    }
  }
`;

const GreySection = styled.div`
  display: flex;
  padding: ${({ theme }) => `${theme.setSpacing(6)}px ${theme.setSpacing(10)}px`};
  background: ${({ theme }) => theme.colors.withOpacity(theme.colors.grey10, 0.3)};
  border-radius: ${({ theme }) => theme.borderRadius}px;
`;

const DataValue = styled(BodyText2)`
  && {
    font-weight: ${({ theme }) => theme.fonts.fontWeight.bold};
    margin-left: ${({ theme }) => theme.setSpacing(2)}px;
  }
`;
const DataPieceWrapper = styled.div`
  display: flex;
  margin-bottom: ${({ theme }) => theme.setSpacing(4)}px;

  &:last-of-type {
    margin-bottom: 0;
  }
`;

const TravelInfoWrapper = styled.div`
  margin-left: ${({ theme }) => theme.setSpacing(6)}px;
`;

const FlightNumberValue = styled.strong`
  margin-left: ${({ theme }) => theme.setSpacing(2)}px;
`;

const FlightSearchElement = ({ isSelected, flight, onSelect }) => {
  const { carrier, flight_number, departure_at_utc, arrival_at_utc, airports } = flight;
  const [departure_airport, arrival_airport] = airports;

  const handleFlightSelect = () => onSelect(flight);

  const checkboxLabel = (
    <span>
      Flight:
      <FlightNumberValue>{`${carrier} ${flight_number}`}</FlightNumberValue>
    </span>
  );

  return (
    <ResultWrapper>
      <Content margin={6}>
        <FieldWrapper label={checkboxLabel} control={<Checkbox checked={isSelected} onChange={handleFlightSelect} />} />
      </Content>
      <GreySection>
        <TravelInfoWrapper>
          <DataPieceWrapper>
            <BodyText2>Estimated departure time:</BodyText2>
            <DataValue>{convertToTimeFormat(departure_at_utc, departure_airport.time_zone)}</DataValue>
          </DataPieceWrapper>
          <DataPieceWrapper>
            <BodyText2>Departure airport:</BodyText2>
            <DataValue>{departure_airport.name}</DataValue>
          </DataPieceWrapper>
          <DataPieceWrapper>
            <BodyText2>Departure city:</BodyText2>
            <DataValue>{departure_airport.city}</DataValue>
          </DataPieceWrapper>
        </TravelInfoWrapper>
        <TravelInfoWrapper>
          <DataPieceWrapper>
            <BodyText2>Estimated arrival time:</BodyText2>
            <DataValue>{convertToTimeFormat(arrival_at_utc, arrival_airport.time_zone)}</DataValue>
          </DataPieceWrapper>
          <DataPieceWrapper>
            <BodyText2>Arrival airport:</BodyText2>
            <DataValue>{arrival_airport.name}</DataValue>
          </DataPieceWrapper>
          <DataPieceWrapper>
            <BodyText2>Arrival city:</BodyText2>
            <DataValue>{arrival_airport.city}</DataValue>
          </DataPieceWrapper>
        </TravelInfoWrapper>
      </GreySection>
    </ResultWrapper>
  );
};

FlightSearchElement.propTypes = {
  isSelected: PropTypes.bool.isRequired,
  flight: PropTypes.shape().isRequired,
  onSelect: PropTypes.func.isRequired,
};

export { FlightSearchElement };
