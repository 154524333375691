import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { BodyText2 } from "components/ui/Typography/Typography";

const DataWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  margin-right: ${({ theme }) => theme.setSpacing(10)}px;
  :first-of-type {
    margin-bottom: ${({ theme }) => theme.setSpacing(5)}px;
  }
  :last-of-type {
    margin-right: 0;
  }
`;

const Label = styled(BodyText2)`
  && {
    font-weight: 300;
    color: ${({ theme }) => theme.colors.grey50};
    margin-right: ${({ theme }) => theme.setSpacing(8)}px;
  }
`;

const InfoBarElement = ({ label, value }) => (
  <DataWrapper>
    <Label>{`${label}:`}</Label>
    <BodyText2>{value}</BodyText2>
  </DataWrapper>
);

InfoBarElement.defaultProps = {
  value: "-",
};

InfoBarElement.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
};

export { InfoBarElement, Label, DataWrapper };
