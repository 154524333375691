import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import { SortableListElement, RemoveButton } from "components/_shared/SortableListElement/SortableListElement";
import { SortableListElementContentWithError } from "components/_shared/SortableListElementContentWithError/SortableListElementContentWithError";

import { Checkbox, Input } from "components/ui/Forms";
import { ERRORS } from "constants/content";

import { emailValidate } from "utils/validation";
import { isEmpty } from "lodash";

const WideFormControl = styled.div`
  .MuiTypography-body1 {
    font-size: ${({ theme }) => theme.fonts.fontSize.title4}px;
    white-space: nowrap;
  }
`;

const MessagingEmailElement = ({ emailData, index, onEmailChange, onEmailDelete, errors, disabled }) => {
  const [inputTouched, setInputTouched] = useState(false);
  const [inputFocused, setInputFocused] = useState(false);

  const handleFocusInput = () => {
    setInputFocused(true);
    if (!inputTouched) setInputTouched(true);
  };

  const handleEmailChange = ({ target: { value } }) => onEmailChange({ ...emailData, email: value });

  const handleBeforeAfterToggle = () => onEmailChange({ ...emailData, before_after: !emailData.before_after });
  const handleDuringToggle = () => onEmailChange({ ...emailData, during: !emailData.during });

  const shouldShowError = (inputTouched && !inputFocused && !emailValidate(emailData.email)) || !isEmpty(errors);

  return (
    <SortableListElement index={index}>
      <SortableListElementContentWithError>
        <Input
          label="Email"
          type="email"
          value={emailData.email}
          onChange={handleEmailChange}
          name={`email-${emailData.id}`}
          error={shouldShowError}
          helperText={shouldShowError ? (errors && errors.email) || ERRORS.invalidEmail : ""}
          onFocus={handleFocusInput}
          size="small"
          onBlur={() => setInputFocused(false)}
          disabled={disabled}
        />
        <WideFormControl>
          <FormControlLabel
            label="Before / After"
            control={<Checkbox checked={emailData.before_after} onChange={handleBeforeAfterToggle} />}
            disabled={disabled}
          />
        </WideFormControl>
        <WideFormControl>
          <FormControlLabel
            label="During"
            control={<Checkbox checked={emailData.during} onChange={handleDuringToggle} />}
            disabled={disabled}
          />
        </WideFormControl>
        {!disabled && <RemoveButton onClick={onEmailDelete} />}
      </SortableListElementContentWithError>
    </SortableListElement>
  );
};

MessagingEmailElement.defaultProps = {
  errors: {},
};

MessagingEmailElement.propTypes = {
  emailData: PropTypes.shape({
    email: PropTypes.string,
    during: PropTypes.bool,
    before_after: PropTypes.bool,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
  index: PropTypes.number.isRequired,
  onEmailChange: PropTypes.func.isRequired,
  onEmailDelete: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  errors: PropTypes.shape({
    email: PropTypes.string,
  }),
};

export { MessagingEmailElement, WideFormControl };
