import React from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import { hideSavingCover, showSavingCover } from "store/app/actions";

import { SavingCover } from "./SavingCover";
import { SavingCoverContext } from "./SavingCoverContext";

const SavingCoverProvider = ({ children }) => {
  const isVisible = useSelector(({ app }) => app.savingCover);
  const dispatch = useDispatch();

  const contextValue = {
    isVisible,
    show: () => dispatch(showSavingCover()),
    hide: () => dispatch(hideSavingCover())
  }

  return (
    <SavingCoverContext.Provider value={contextValue}>
      {isVisible && <SavingCover />}
      {children}
    </SavingCoverContext.Provider>
  )
}

SavingCoverProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export {
  SavingCoverProvider
}