import React from "react";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import styled from "styled-components";

import { ContextNavigationLink } from "components/ui/Links/Links";

const SubMenuWrapper = styled.div`
  display: flex;
  height: 100%;
  width: auto;
  justify-content: center;
  align-items: center;
`;

const SubMenu = ({ menu }) => {
  const { pathname } = useLocation();
  return (
    <SubMenuWrapper>
      {menu.map(({ url, label }) => (
        <ContextNavigationLink key={`${label}-${url}`} to={url} isActive={pathname.includes(url)}>
          {label}
        </ContextNavigationLink>
      ))}
    </SubMenuWrapper>
  );
};

SubMenu.propTypes = {
  menu: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export { SubMenu, SubMenuWrapper };
