import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";

import { BodyText2 } from "components/ui/Typography/Typography";
import { Select } from "components/ui/Forms";

const RulesWrapper = styled.div`
  padding-bottom: ${({ theme }) => theme.setSpacing(6)}px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey10};
`;
const RulesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;

const RulesTitle = styled(BodyText2)`
  && {
    padding-bottom: ${({ theme }) => theme.setSpacing(3)}px;
  }
`;

const RuleField = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 366px;
  margin-right: ${({ theme }) => theme.setSpacing(10)}px;
  padding: ${({ theme }) => theme.setSpacing(1)}px 0;
`;

const InputContainer = styled.div`
  width: 247px;
`;

const RuleLabel = styled(BodyText2)`
  && {
    color: ${({ theme }) => theme.colors.grey50};
  }
`;

const RULE_TYPES = [
  { name: "Directory", fieldName: "show_directory" },
  { name: "Daily", fieldName: "show_daily" },
  { name: "Vouchers", fieldName: "show_vouchers" },
  // { name: "Messaging", fieldName: "show_messaging" },
  { name: "DND", fieldName: "show_dnd" },
];

const RULE_OPTIONS = [
  { label: "Always show", value: "always" },
  { label: "Show during stay", value: "during" },
  { label: "Off", value: "off" },
];

const ruleOptions = ruleType => (ruleType === "show_dnd" ? RULE_OPTIONS.filter(item => item.value !== "always") : RULE_OPTIONS);

const PasscodeRules = ({ rules, onRuleChange, disabled }) => (
  <RulesWrapper>
    <RulesTitle>Permissions</RulesTitle>
    <RulesContainer>
      {RULE_TYPES.map(({ name, fieldName }) => (
        <RuleField key={fieldName}>
          <RuleLabel>{name}</RuleLabel>
          <InputContainer>
            <Select
              name={`display_rules-for-${fieldName}`}
              value={rules[fieldName]}
              onChange={({ target }) => onRuleChange(fieldName, target.value)}
              disabled={disabled}
            >
              {ruleOptions(fieldName).map(({ label, value }) => (
                <MenuItem key={label} value={value}>
                  {label}
                </MenuItem>
              ))}
            </Select>
          </InputContainer>
        </RuleField>
      ))}
    </RulesContainer>
  </RulesWrapper>
);

PasscodeRules.propTypes = {
  rules: PropTypes.shape({}).isRequired,
  onRuleChange: PropTypes.func.isRequired,
};

export { PasscodeRules, RulesWrapper, RulesContainer, RulesTitle, RuleField, InputContainer, RuleLabel, RULE_TYPES, RULE_OPTIONS };
