import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import styled from "styled-components";

import { Input } from "components/ui/Forms";
import { Content } from "components/ui/Content";
import { ConfirmationModal } from "components/ui/Modals/ConfirmationModal";
import { ListTree } from "components/ui/Lists/ListTree";
import { Message } from "components/ui/Messages/Message";
import { AttachFilesButton } from "components/ui/Buttons";
import { DEFAULT_LIBRARY_TABS } from "components/ui/LibraryPopup/LibraryPopup";
import { ERRORS, LIBRARY_CONTENT } from "constants/content";
import { LIBRARY_ROOT_PATH } from "constants/api";
import { PANEL_ROOT_PATH } from "constants/routes";
import { formatDisplayableLibraryPopupValues } from "utils/library";
import { DOCUMENTS_FILE_TYPES, IMAGES_MIME_TYPES, VIDEO_FILE_TYPES } from "constants/defaults";
import { StoryboardFile } from "domain/StoryboardFile";
import { ChipWithRedirect } from "components/ui/Chips/ChipWithRedirect";
import { useActiveIcon } from "hooks/useActiveIcon";
import { LibraryService } from "services/LibraryService";
import { useService } from "hooks/useService";
import { Logger } from "services/application/Logger";

const FilePreviewWrapper = styled.div`
  width: 100%;
  margin: 20px 0;
  text-align: right;
`;

export const isNewPathInsideOriginal = (originalPath, newPath) => `${newPath}/`.includes(`${originalPath}/`);

export const FOLDER_MODAL_TYPE = {
  file: "file",
  folder: "folder",
  storyboard: "storyboardComponent",
};

const FolderModal = ({ title, isOpen, onClose, onSave, value, path, originalItem, mode, type, isLibrary }) => {
  const { pathname } = useLocation();

  const libraryService = useService(LibraryService);

  const [name, setName] = useState("");
  const [url, setUrl] = useState("");
  const [libraryFiles, setLibraryFiles] = useState([]);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [uploadFolder, setUploadFolder] = useState(pathname.substring(PANEL_ROOT_PATH.length));

  const fileIcon = useActiveIcon(uploadedFile?.file || uploadedFile);

  const isEditMode = mode === "edit";

  const resetName = () => {
    setName(isOpen ? value || "" : "");
  };

  const resetUrl = () => {
    setUrl(isOpen && type === FOLDER_MODAL_TYPE.file && isEditMode ? originalItem.remote_url || originalItem.file.s3_url || "" : "");
  };

  const setFolderPath = () => {
    setUploadFolder(pathname.substring(PANEL_ROOT_PATH.length));
  };

  const getLibraryItemsList = async () => {
    if (isOpen && !isLibrary) {
      try {
        const items = await libraryService.getAllItemsFromDirectory(
          uploadFolder.startsWith(LIBRARY_ROOT_PATH) ? uploadFolder : LIBRARY_ROOT_PATH,
        );

        setLibraryFiles(items.filter(libraryItem => !libraryItem?.is_folder));
      } catch (e) {
        Logger.debug(e);
      }
    }
  };

  useEffect(resetName, [isOpen]);
  useEffect(resetUrl, [isOpen]);
  useEffect(() => {
    getLibraryItemsList();
  }, [isOpen, uploadFolder]);
  useEffect(() => {
    const newFileUploaded = originalItem?.file
      ? {
          ...originalItem,
          file: {
            ...originalItem.file,
            name: originalItem?.file?.short_name || originalItem.name,
          },
        }
      : originalItem;
    setUploadedFile(newFileUploaded);

    return () => {
      setUploadedFile(null);
    };
  }, [isOpen]);

  useEffect(setFolderPath, [pathname]);

  const buttonTitleEnum = {
    edit: "update",
    add: "create",
  };

  const handleChange = ({ target }) => setName(target.value);

  const handleCloseDialog = () => {
    setName(null);
    setUrl("");
    onClose();
  };

  const handleSave = () => {
    onSave(name, uploadFolder, url);
    handleCloseDialog();
  };

  const isNameNotUniquee =
    name !== originalItem?.name &&
    name?.length > 0 &&
    libraryFiles.length > 0 &&
    libraryFiles.findIndex(node => node.name.toLowerCase() === name.toLocaleLowerCase()) !== -1;

  const libraryTree = (isEditMode || type === FOLDER_MODAL_TYPE.storyboard) && (
    <ListTree uploadFolder={uploadFolder} setUploadFolder={setUploadFolder} defaultPath={path} />
  );

  const isSelectedPathAncestorOfEditedFolder =
    isEditMode && type === FOLDER_MODAL_TYPE.folder && isNewPathInsideOriginal(path, uploadFolder);
  const isNameInvalid = isNameNotUniquee;
  const invalidPathMessage = isSelectedPathAncestorOfEditedFolder && (
    <Content>
      <Message text={ERRORS.invalidFolderPath} type="error" />
    </Content>
  );

  const libraryTabs = isEditMode && originalItem.remote_url ? [DEFAULT_LIBRARY_TABS.webUrl] : [DEFAULT_LIBRARY_TABS.files];
  const allowedFileTypes = (() => {
    if (!originalItem || !originalItem.file) return undefined;
    const availableFileTypes = [IMAGES_MIME_TYPES, DOCUMENTS_FILE_TYPES, VIDEO_FILE_TYPES];
    return availableFileTypes.find(category => category.includes(originalItem.file.mime_type));
  })();

  const handleRemoveAttachment = () => {
    setUploadedFile(null);
  };

  const handleChangeAttachment = files => {
    const [newFile] = formatDisplayableLibraryPopupValues(files);

    setUploadedFile(newFile);
    setUrl(newFile.file_url || newFile.web_url);
    if (!name) {
      setName(newFile.file_name);
    }
  };

  const isFileSectionAvailable =
    isEditMode &&
    type === FOLDER_MODAL_TYPE.file &&
    (uploadedFile?.mime_type || uploadedFile?.file?.mime_type) !== StoryboardFile.FILE_TYPE;

  const renderAttachmentDeleter = () => (
    <ChipWithRedirect onDelete={handleRemoveAttachment} item={uploadedFile?.file || uploadedFile} maxChar={15} icon={fileIcon} />
  );

  const renderAttachmentUploader = () => (
    <AttachFilesButton size="small" onSelect={handleChangeAttachment} allowedFileTypes={allowedFileTypes} tabs={libraryTabs} />
  );

  const renderFileSection = () =>
    isFileSectionAvailable ? (
      <FilePreviewWrapper>{uploadedFile ? renderAttachmentDeleter() : renderAttachmentUploader()}</FilePreviewWrapper>
    ) : null;

  return isOpen ? (
    <ConfirmationModal
      fullWidth
      title={title}
      open={isOpen}
      maxWidth="sm"
      aria-labelledby="add-new-folder"
      onConfirm={handleSave}
      onCancel={onClose}
      confirmLabel={buttonTitleEnum[mode]}
      confirmDisabled={isSelectedPathAncestorOfEditedFolder || !uploadedFile}
    >
      <Content margin={4}>
        <Input
          label={LIBRARY_CONTENT.name}
          name="folder-name"
          value={name || ""}
          onChange={handleChange}
          error={name.length > 0}
          autoFocus
        />
      </Content>
      {invalidPathMessage}
      {libraryTree}
      {renderFileSection()}
    </ConfirmationModal>
  ) : null;
};

FolderModal.defaultProps = {
  value: "",
  path: "",
  mode: "add",
  originalItem: {},
  isLibrary: false,
  type: FOLDER_MODAL_TYPE.file,
};

FolderModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  isLibrary: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  value: PropTypes.string,
  originalItem: PropTypes.shape({
    name: PropTypes.string,
    remote_url: PropTypes.string,
    mime_type: PropTypes.string,
    file: PropTypes.shape({
      s3_url: PropTypes.string,
      mime_type: PropTypes.string,
      short_name: PropTypes.string,
    }),
  }),
  path: PropTypes.string,
  mode: PropTypes.oneOf(["add", "edit"]),
  type: PropTypes.oneOf([FOLDER_MODAL_TYPE.folder, FOLDER_MODAL_TYPE.file, FOLDER_MODAL_TYPE.storyboard]),
};

export { FolderModal };
