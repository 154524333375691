// @ts-check
import { PaginatedMapper } from "mappers/PaginatedMapper";
import { StayMasterMapper } from "mappers/StayMasterMapper";
import { StayPermissionMapper } from "mappers/StayPermissionMapper";
import { StayRepository } from "repositories/StayRepository";
import { StayNotFoundException } from "exceptions/StayNotFoundException";
import { StayVariantNotFoundException } from "exceptions/StayVariantNotFoundException";
import { fillEmptyNotificationsWithTemplateData } from "utils/dataConverters";
import { NOTIFICATION_TYPES } from "constants/content";
import { Stay } from "domain/Stay";

import { Logger } from "services/application/Logger";
import { NotificationsService } from "services/NotificationsService";
import { StayUpdateRequestDto } from "dto/StayUpdateRequestDto";
import { StayOverride } from "domain/StayOverride";
import { StayVariantMapper } from "mappers/StayVariantMapper";
import { PoiService } from "services/PoiService";
import { EventBus } from "services/application/EventBus";
import { StayHasBeenCopied } from "events/StayHasBeenCopied";

class StayService {
  /**
   * @param {import("repositories/StayRepository").StayRepository=} stayRepository 
   * @param {import("mappers/StayMasterMapper").StayMasterMapper=} stayMasterMapper 
   * @param {import("services/NotificationsService").NotificationsService=} notificationsService 
   */
  constructor(
    stayRepository = new StayRepository(), 
    stayMasterMapper = new StayMasterMapper(),
    stayVariantMapper = new StayVariantMapper(),
    notificationsService = new NotificationsService(),
    poiService = new PoiService(),
  ) {
    /** @type {import("repositories/StayRepository").StayRepository} */
    this.stayRepository = stayRepository;
    
    /** @type {import("mappers/StayMasterMapper").StayMasterMapper} */
    this.stayMasterMapper = stayMasterMapper;

    /** @type {import("mappers/StayVariantMapper").StayVariantMapper} */
    this.stayVariantMapper = stayVariantMapper;

    /** @type {import("services/NotificationsService").NotificationsService} */
    this.notificationsService = notificationsService;

    /** @type {import("services/PoiService").PoiService} */
    this.poiService = poiService;
    
    /** @type {import("mappers/PaginatedMapper").PaginatedMapper<import("domain/StayPermission").StayPermission>} */
    this.paginatedMapper = new PaginatedMapper(new StayPermissionMapper());
  }

  async createStay(operatorCode, stay, operator) {
    return this.stayRepository.createStay(operatorCode, stay, operator);
  }

  async createStayVariant(operatorCode, stay, language, operator) {
    return this.stayRepository.createStayVariant(operatorCode, stay, language, operator);
  }

  async updateStay(operatorCode, stay, operator) {
    return this.stayRepository.updateStay(operatorCode, stay, operator);
  }

  async updateStayVariant(operatorCode, stay, language, operator) {
    return this.stayRepository.updateStayVariant(operatorCode, stay, language, operator);
  }

  async setStayFallbackLanguage(operatorCode, language, operator) {
    return this.stayRepository.setStayFallbackLanguage(operatorCode, language, operator);
  }

  async setStayVariantsStatus(operatorCode, languages, operator) {
    const masterStay = await this.getStayVariant(operatorCode);

    await languages.reduce(async (promise, lang) => {
      try {

        await promise;

        if(masterStay.variants.find(variant => variant.language === lang.code)) {
          const existingVariant = await this.getStayVariant(operatorCode, lang.code);
        
          if(lang.isActive) {
            existingVariant.isActive = lang.isActive;
    
            return this.saveStayVariant(operatorCode, existingVariant, operator, lang.code);
          }

          return this.deactivateVariant(operatorCode, lang.code, operator);
        }

        if(lang.isActive) {
          const newVariant = new Stay(masterStay.userId, masterStay.vamoosId);
          newVariant.isActive = lang.isActive;
    
          return this.saveStayVariant(operatorCode, newVariant, operator, lang.code);
        }

        return Promise.resolve();
      } catch(e) {
        Logger.debug(e);
        return e;
      }
    }, Promise.resolve());
  }

  async activateStay(operatorCode) {
    const master = await this.getStay(operatorCode);

    master.isActive = true;

    return this.saveStayMasterVariant(operatorCode, master, null, master.language);    
  }

  async deactivateStay(operatorCode, operator) {
    return this.stayRepository.deactivateStay(operatorCode, operator);
  }

  async deactivateVariant(operatorCode, language, operator) {
    return this.stayRepository.deactivateStayVariant(operatorCode, language, operator);
  }

  /**
   * @param {string} operatorCode
   * @returns {Promise<import("domain/Stay").Stay>}
   */
  async getStay(operatorCode) {
    try {
      const stayObj = await this.stayRepository.getStay(operatorCode);

      return this.stayMasterMapper.fromDtoToDomain(stayObj);
    } catch(e) {
      Logger.debug(e);

      return null;
    }
  }

  /**
   * @param {string} operatorCode
   * @returns {Promise<import("domain/Stay").Stay>}
   */
  async getStayVariant(operatorCode, languageCode = null) {
    try {
      const stayObj = await this.stayRepository.getStayVariant(operatorCode, languageCode);

      return this.stayMasterMapper.fromDtoToDomain(stayObj);
    } catch(e) {

      Logger.debug(e);

      if(e.response && e.response.data) {
        if(e.response.data.error === "Not found") {
          throw new StayNotFoundException();
        }

        if(e.response.data.error.includes("Variant not found")) {
          throw new StayVariantNotFoundException();
        }
      }

      return null;
    }
  }

  async getVariantsStatus(operatorCode, variants) {
    return this.stayRepository.getVariantsStatus(operatorCode, variants);
  }

  /**
   * @deprecated
   * @param {string} operator 
   * @returns {Promise<import("domain/Stay").Stay>}
   */
  async saveStay(operatorCode, stay, operator) {
    const templates = await this.notificationsService.getAllNotifications();
    const availablePois = await this.poiService.getPoisByLocations([stay.coordinates]);

    const dto = await this.stayMasterMapper.fromDomainToDto(this.prepareStayBeforeSave(stay, availablePois, templates))

    return this.stayRepository.saveStay(operatorCode, dto, operator);
  }

  /**
   * @param {string} operator 
   * @returns {Promise<import("domain/Stay").Stay>}
   */
   async saveStayVariant(operatorCode, stayVariant, operator, language) {
    const templates = await this.notificationsService.getAllNotifications();
    const availablePois = await this.poiService.getPoisByLocations([stayVariant.coordinates]);

    const dto = await this.stayVariantMapper.fromDomainToDto(this.prepareStayBeforeSave(stayVariant, availablePois, templates))

    return this.stayRepository.saveStayVariant(operatorCode, dto, operator, language);
  }

  /**
   * @param {string} operator 
   * @param {import("domain/Stay").Stay} stay
   * @returns {Promise<import("domain/Stay").Stay>}
   */
   async saveStayMasterVariant(operatorCode, stay, operator, language) {
    const templates = await this.notificationsService.getAllNotifications();
    const availablePois = await this.poiService.getPoisByLocations([stay.coordinates]);

    const dto = await this.stayMasterMapper.fromDomainToDto(this.prepareStayBeforeSave(stay, availablePois, templates));

    if(language) {
      return this.stayRepository.saveStayVariant(operatorCode, dto, operator, language);
    }

    return this.stayRepository.saveStay(operatorCode, dto, operator);
  }
  
  /**
   * 
   * @param {string} operator 
   * @param {*} params 
   * @returns {Promise<import("domain/Paginated").Paginated<import("domain/StayPermission").StayPermission>>}
   */
  async getStayUsers(operator, params = {}) {
    const paginatedUsersObj = await this.stayRepository.getStayUsers(operator, params);
    return this.paginatedMapper.fromDtoToDomain(paginatedUsersObj);
  }

  /**
   * 
   * @param {string} email 
   * @param {"read"|"write"} permission 
   * @param {string} operator 
   * @returns 
   */
  async addUserToStay(email, permission, operator) {
    return this.stayRepository.addUserToStay(email, permission, operator);
  }

  /**
   * 
   * @param {number} userId 
   * @param {string} operator 
   * @returns 
   */
  async removeUserFromStay(userId, operator) {
    return this.stayRepository.removeUserFromStay(userId, operator);
  }

  /**
   * 
   * @param {*} userId 
   * @param {*} notificationText 
   * @param {*} isForceUpdate 
   * @param {*} isSendOnlyToActive 
   * @param {*} language 
   * @returns 
   */
  async sendUpdate(userId, notificationText, isForceUpdate, isSendOnlyToActive, language) {
    const requestPayload = StayUpdateRequestDto({
      notificationText,
      isForceUpdate,
      isSendOnlyToActive,
      language,
    });

    return this.stayRepository.sendUpdate(userId, requestPayload);
  }

  /**
   * 
   * @param {*} oldUserId 
   * @param {*} newUserId 
   * @param {*} sections 
   * @param {*} targetOperator 
   */
  async copyStay(oldUserId, newUserId, sections, copyToOtherOperator = false, targetOperator = null) {
    const originalStay = await this.getStay(oldUserId);
    const clone = Object.assign(new Stay(), originalStay);

    delete clone.vamoosId;

    clone.copiedFrom = originalStay.id;

    if(!sections.includes("documents")) {
      clone.actions.action.icon = null;
      clone.actions.action.label = null;
      clone.actions.action.file = null;
      clone.actions.bookNow.icon = null;
      clone.actions.bookNow.label = null;
      clone.actions.bookNow.file = null;
    }

    if(!sections.includes("pois")) {
      clone.pois = [];
    }

    if(!sections.includes("details")) {
      clone.gallery = [];
    }
    
    if(!sections.includes("directory")) {
      clone.directories = { items: [], show: "always" };
    }

    if(!sections.includes("voucher")) {
      clone.vouchers = { items: [], show: "always" };
    }

    if(!sections.includes("daily")) {
      clone.dailyActivities = { items: [], show: "always" };
    }

    if(!sections.includes("passcode_groups") && !sections.includes("passcodes")) {
      clone.passcodes = [];
    }

    if(!sections.includes("notifications")) {
      clone.notifications = { timed: { items: [] }, gps: { items: [] } };
    }

    const templates = await this.notificationsService.getAllNotifications();
    const availablePois = await this.poiService.getPoisByLocations([clone.coordinates]);

    const dto = await this.stayMasterMapper.fromDomainToDto(
      this.prepareStayBeforeSave(clone, availablePois, templates), copyToOtherOperator
    )

    if(clone.language) {
      await this.stayRepository.saveStayVariant(newUserId, dto, targetOperator, clone.language);
    } else {
      await this.stayRepository.saveStay(newUserId, dto, targetOperator);
    }

    const newMasterStay = await this.getStay(newUserId);

    if(sections.includes("translations")) {
      await originalStay.variants.reduce(async (promise, variant) => {
        try {
  
          await promise;
  
          let variantDto = null;
          const stayVariant = await this.getStayVariant(oldUserId, variant.language);

          const variantClone = Object.assign(new Stay(), stayVariant);

          variantClone.vamoosId = newMasterStay.vamoosId;
      
          if(!sections.includes("details")) {
            variantClone.gallery = [];
          }
          
          if(!sections.includes("directory")) {
            variantClone.directories = { items: [], show: "always" };
          }
      
          if(!sections.includes("voucher")) {
            variantClone.vouchers = { items: [], show: "always" };
          }
      
          if(!sections.includes("daily")) {
            variantClone.dailyActivities = { items: [], show: "always" };
          }
      
          if(!sections.includes("notifications")) {
            variantClone.notifications = { timed: { items: [] }, gps: { items: [] } };
          }

          if(variantClone.language === newMasterStay.language) {

            variantClone.copiedFrom = originalStay.id;

            variantDto = await this.stayMasterMapper.fromDomainToDto(
              this.prepareStayBeforeSave(variantClone, availablePois, templates), copyToOtherOperator
            )
          } else {
            variantDto = await this.stayVariantMapper.fromDomainToDto(
              this.prepareStayBeforeSave(variantClone, availablePois, templates), copyToOtherOperator
            )
          }

          return await this.stayRepository.saveStayVariant(newUserId, variantDto, targetOperator, stayVariant.language)
        } catch(e) {
          Logger.debug(e);
          return e;
        }
      }, Promise.resolve());
    }

    EventBus.dispatch(new StayHasBeenCopied(), { id: newUserId, copyToOtherOperator });
    
  }

  async getAllPublisherDocuments(userId) {
    return this.stayRepository.getAllPublisherDocuments(userId);
  }

  async getStays(page, rowsPerPage, sortingOrder, sortingBy, showArchived, searchQuery) {
    return this.stayRepository.getStays(page, rowsPerPage, sortingOrder, sortingBy, showArchived, searchQuery);
  }

  async getPublicStays() {
    const { items } = await this.stayRepository.getPublicStays();

    return items.map(stay => StayOverride(stay));
  }

  /**
   * @private
   * @param {*} stay 
   * @param {*} availablePois 
   * @param {*} templates 
   * @returns 
   */
  // eslint-disable-next-line class-methods-use-this
  prepareStayBeforeSave(stay, availablePois, templates) {
    const gpsTemplates = templates.filter(notification => notification.type === NOTIFICATION_TYPES.gps).map(template => ({
      ...template,
      content: stay.language && template.localisation?.[stay.language]?.content 
        ? template.localisation?.[stay.language]?.content 
        : template.content,
      url: stay.language && template.localisation?.[stay.language]?.url 
        ? template.localisation?.[stay.language]?.url 
        : template.url
    }))
    const timedTemplates = templates.filter(notification => notification.type === NOTIFICATION_TYPES.timed).map(template => ({
      ...template,
      content: stay.language && template.localisation?.[stay.language]?.content 
        ? template.localisation?.[stay.language]?.content 
        : template.content,
      url: stay.language && template.localisation?.[stay.language]?.url 
        ? template.localisation?.[stay.language]?.url 
        : template.url
    }))

    // eslint-disable-next-line no-param-reassign
    stay.pois = availablePois.map(poi => {
      const matchingPoi = stay.pois.find(({ id }) => poi.id === id);
      return {
        id: poi.id,
        is_on: matchingPoi ? matchingPoi.is_on : poi.is_default_on,
      };
    });

    // eslint-disable-next-line no-param-reassign
    stay.notifications.timed.items = fillEmptyNotificationsWithTemplateData(stay.notifications.timed.items, timedTemplates)
    // eslint-disable-next-line no-param-reassign
    stay.notifications.gps.items = fillEmptyNotificationsWithTemplateData(stay.notifications.gps.items, gpsTemplates)

    return stay;
  }
}

export {
  StayService
}