import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import { Link } from "components/ui/Links/Links";

const StyledLink = styled(Link)`
  && {
    margin-left: ${({ theme }) => theme.setSpacing(4)}px;
    display: flex;
    align-items: center;
  }
`;

const StyledIcon = styled.div`
  && {
    color: ${({ theme }) => theme.colors.grey50};
    display: flex;
    align-items: center;
    svg {
      font-size: ${({ theme }) => theme.setSpacing(3.5)}px;
    }
  }
`;

const StyledText = styled.div`
  font-size: ${({ theme }) => theme.setSpacing(4)}px;
  color: ${({ theme }) => theme.colors.black};
  font-weight: ${({ theme }) => theme.fonts.fontWeight.semiBold};
  text-transform: uppercase;
  text-decoration: none;
  padding-left: ${({ theme }) => theme.setSpacing(3)}px;
  letter-spacing: 1px;
  display: flex;
  align-items: center;
`;

const StyledButton = styled.div`
  display: flex;
  align-items: center;
  padding: 8px;
  border: none;
  outline: none;
  background: none;
  cursor: pointer;
`;

const LeaveBaseButton = ({ url, icon, text, onClick }) => {
  return (
    <StyledLink onClick={onClick} to={url} noDecoration>
      {icon && <StyledIcon>{icon}</StyledIcon>}
      <StyledText>{text}</StyledText>
    </StyledLink>
  );
};

const LeavePageButton = ({ icon, text, clickHandler }) => {
  return (
    <StyledButton onClick={clickHandler}>
      {icon && <StyledIcon>{icon}</StyledIcon>}
      <StyledText>{text}</StyledText>
    </StyledButton>
  );
};

LeaveBaseButton.propTypes = {
  url: PropTypes.string.isRequired,
  icon: PropTypes.element.isRequired,
  text: PropTypes.string.isRequired,
};

LeavePageButton.propTypes = {
  clickHandler: PropTypes.func.isRequired,
  icon: PropTypes.element.isRequired,
  text: PropTypes.string.isRequired,
};

export { LeaveBaseButton, LeavePageButton };
