import React from "react";
import PropTypes from "prop-types";
import { Spinner } from "components/ui/Spinner/Spinner";
import { ActionButton, PrimaryButton } from "components/ui/Buttons";
import SaveOutlinedIcon from "@material-ui/icons/SaveOutlined";
import { PERMISSIONS } from "constants/permissions";
import { usePermissionsService } from "hooks/usePermissionsService";
import { FileCopyOutlined, SmartphoneOutlined } from "@material-ui/icons";
import { GLOBAL_CONTENT } from "constants/content";
import { ForceUpdateModal } from "feature/panel/_shared/ForceUpdateModal/ForceUpdateModal";

const TripWizardActions = ({
  onSave,
  showSpinner,
  disabled,
  forceUpdateDisabled,
  setForceUpdateOpen,
  forceUpdateOpen,
  triggerCopyVamoos,
  isEditMode,
  vamoosId,
  onForceUpdate,
}) => {
  const permissionsService = usePermissionsService();
  const canCreate = permissionsService.can(PERMISSIONS.actions.create, PERMISSIONS.sections.vamoosList);
  const canEdit = permissionsService.can(PERMISSIONS.actions.update, PERMISSIONS.sections.vamoosList, vamoosId);
  const canRead = permissionsService.can(PERMISSIONS.actions.read, PERMISSIONS.sections.vamoosList, vamoosId);

  const closeModal = () => setForceUpdateOpen(true);

  return (
    <>
      {isEditMode && canRead && canEdit && (
        <ActionButton mr={6} onClick={closeModal} disabled={forceUpdateDisabled}>
          <SmartphoneOutlined />
          <span>{GLOBAL_CONTENT.forceUpdate}</span>
        </ActionButton>
      )}
      {isEditMode && canRead && canCreate && (
        <ActionButton mr={6} onClick={triggerCopyVamoos}>
          <FileCopyOutlined />
          <span>{GLOBAL_CONTENT.copy}</span>
        </ActionButton>
      )}
      {(canCreate || (isEditMode && canRead && canEdit)) && (
        <PrimaryButton onClick={onSave} disabled={disabled}>
          {!showSpinner && (
            <>
              <SaveOutlinedIcon />
              <span>save</span>
            </>
          )}
          {showSpinner && <Spinner size={28} cv="white" />}
        </PrimaryButton>
      )}
      {forceUpdateOpen && <ForceUpdateModal open={forceUpdateOpen} onCancel={() => setForceUpdateOpen(false)} onConfirm={onForceUpdate} />}
    </>
  );
};

TripWizardActions.propTypes = {
  onSave: PropTypes.func.isRequired,
  showSpinner: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export { TripWizardActions };
