import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import styled from "styled-components";

import config from "config/app";

import { firstLetterUpperCase } from "utils/string";

const VersionsList = styled.ul`
  list-style-type: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
  margin: 0;
  margin-bottom: -2px;
  margin-top: 1px;
  width: 100%;
`;
const VersionsListItem = styled.li`
  width: calc(100% + 8px);
  display: flex;
  height: 60px;
  border-top: 1px solid ${({ theme }) => theme.colors.grey10};
  border-left: 1px solid ${({ theme }) => theme.colors.grey10};
  border-right: 1px solid ${({ theme }) => theme.colors.grey10};
  font-size: 13px;
  padding: 0 4px;

  div {
    flex-basis: 20%;
    min-width: 210px;
    max-width: 210px;
    margin: auto 5px;

    &:last-of-type {
      margin-right: 366px;
    }
  }
  &:last-of-type {
    border-bottom: 1px solid ${({ theme }) => theme.colors.grey10};
  }
`;

const BundleVersionsTable = ({ versionsList }) => {
  return (
    <VersionsList>
      {versionsList.map(item => (
        <VersionsListItem key={`version-item-${item.id}-${item.sequenceNumber}`}>
          <div>{item.name}</div>
          <div>{item.status === "approved" ? "Accepted" : firstLetterUpperCase(item.status)}</div>
          <div>{moment(item.createdAt).format(config.dateTimeFormat)}</div>
          <div>{moment(item.updatedAt).format(config.dateTimeFormat)}</div>
        </VersionsListItem>
      ))}
    </VersionsList>
  );
};

BundleVersionsTable.propTypes = {
  versionsList: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export { BundleVersionsTable };
