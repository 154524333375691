import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useSnackbar } from "notistack";
import { InfoOutlined } from "@material-ui/icons";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import PropTypes from "prop-types";
import styled from "styled-components";
import uuidv4 from "uuid";

import { clearNotification } from "store/app/actions";

import { LOGOUT_START } from "store/auth/actionTypes";
import { VAMOOS_CONNECT_INFO_MESSAGE } from "constants/content";

const MessageContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px;
  cursor: pointer;

  svg {
    margin-right: 10px;
  }
`;

const NotificationComponent = ({ notification, actionType, clear }) => {
  const [vammosConnectNotificationKey, setVammosConnectNotificationKey] = useState(null);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { type, message, show } = notification;

  const action = key => (
    <IconButton
      key={key}
      aria-label="close"
      color="inherit"
      onClick={() => {
        closeSnackbar(key);
      }}
    >
      <CloseIcon />
    </IconButton>
  );

  const redirectToConnectPage = event => {
    event.preventDefault();
    window.open(VAMOOS_CONNECT_INFO_MESSAGE.url);
  };

  const vamoosConnectNotificationContent = (
    <MessageContainer onClick={redirectToConnectPage}>
      <InfoOutlined />
      {message}
    </MessageContainer>
  );

  const options = {
    key: uuidv4(),
    variant: type,
    action,
  };

  const callSnackbar = () => {
    if (show) {
      if (type === "brand") {
        const key = uuidv4();
        setVammosConnectNotificationKey(key);
        enqueueSnackbar(vamoosConnectNotificationContent, {
          key,
          persist: true,
          preventDuplicate: true,
          variant: "brand",
          action,
        });
      } else {
        enqueueSnackbar(message, options);
      }
      clear();
    }
  };

  const closeSnackbarOnLogout = () => {
    if (LOGOUT_START === actionType && vammosConnectNotificationKey !== null) {
      closeSnackbar(vammosConnectNotificationKey);
      setVammosConnectNotificationKey(null);
    }
  };

  useEffect(callSnackbar, [notification]);
  useEffect(closeSnackbarOnLogout, [actionType]);

  return null;
};

NotificationComponent.defaultProps = {
  actionType: "",
};

NotificationComponent.propTypes = {
  notification: PropTypes.shape().isRequired,
  actionType: PropTypes.string,
  clear: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  notification: state.app.notification,
  actionType: state.auth.actionType,
});

const mapDispatchToProps = dispatch => ({
  clear: () => dispatch(clearNotification()),
});

const Notification = connect(mapStateToProps, mapDispatchToProps)(NotificationComponent);

export { Notification };
