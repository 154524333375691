import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import styled from "styled-components";
import SettingsApplicationsIcon from "@material-ui/icons/SettingsApplications";

import { MESSAGING } from "constants/defaults";
import { Spinner } from "components/ui/Spinner/Spinner";
import { BodyText, BodyText2 } from "components/ui/Typography/Typography";
import { ActionButton } from "components/ui/Buttons";

import { FieldChangeModal } from "feature/panel/Settings/_shared/FieldChangeModal";
import { InfoBar, ThreadsList, SendMessageBar, MessagesWindow, SettingsWindow } from "feature/panel/_shared/Messaging/Components";
import { checkIfMessageWasRead } from "feature/panel/_shared/Messaging/shared/checkIfMessageWasRead";

import { useMessagesList } from "hooks/useMessagesList";

import { setNotification } from "store/app/actions";

import { ERRORS, MESSAGING_LABELS } from "constants/content";
import { sendNewMessageHandler } from "utils/messaging/sendNewMessageHandler";
import { usePermissionsService } from "../../../../hooks/usePermissionsService";
import { PERMISSIONS } from "../../../../constants/permissions";

const { RELOAD_MESSAGE_ERROR, MESSAGES_RELOAD_REQUEST_INTERVAL, VIEWPORT_BREAK_SIZE } = MESSAGING;

const MessagingContainer = styled.div`
  min-height: calc(100% + ${({ theme }) => theme.setSpacing(20)}px);
  display: flex;
  margin: -${({ theme }) => theme.setSpacing(10)}px;
  overflow-y: hidden;
`;

const PlaceholderContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

const FullWidthContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 128px);
`;

const PaddedPlaceholder = styled.div`
  padding: ${({ theme }) => theme.setSpacing(6)}px;
`;

const SpinnerWithMargin = styled(Spinner)`
  && {
    margin: ${({ theme }) => theme.setSpacing(8)}px;
  }
`;

const PositioningContainer = styled.div`
  overflow-y: hidden;
  margin-left: ${({ theme }) => theme.setSpacing(95)}px;
  width: 100%;

  @media (max-width: ${VIEWPORT_BREAK_SIZE}px) {
    margin-left: ${({ theme }) => theme.setSpacing(80)}px;
  }
`;

const PlaceholderContentCenterContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const NegativeMarginContainer = styled(PlaceholderContentCenterContainer)`
  margin: -${({ theme }) => theme.setSpacing(10)}px;
  height: calc(100vh - ${({ theme }) => theme.setSpacing(32)}px);
  overflow-y: hidden;
`;

const SpacedText = styled(BodyText2)`
  && {
    margin-bottom: ${({ theme }) => theme.setSpacing(6)}px;
  }
`;

const Messaging = ({ conversationDetails, contextHandler, configurationType }) => {
  const dispatch = useDispatch();

  const [isSettingsMenuOpen, setIsSettingsMenuOpen] = useState(false);
  const [activeThreadId, setActiveThreadId] = useState(null);
  const [openEditPopup, setOpenEditPopup] = useState(false);
  const [conversationName, setConversationName] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [currentMessage, setCurrentMessage] = useState("");
  const { editMode, field1, field3, vamoos_id: vamoosId } = contextHandler();

  const permissionsService = usePermissionsService();
  const canEdit = permissionsService.can(PERMISSIONS.actions.update, PERMISSIONS.sections.vamoosList, vamoosId);
  const [isLoading, setIsLoading] = useState(false);

  const [
    conversations,
    finishedFetchingMessages,
    reloadMessages,
    updateMessageLastRead,
    updateThreadMessages,
    friendlyNameUpdate,
  ] = useMessagesList({
    details: conversationDetails,
    sendRequest: isSettingsMenuOpen || !editMode,
  });

  const mappedMessages = conversations.map(({ id, ...rest }) => ({
    id,
    active: activeThreadId === id,
    ...rest,
  }));

  const activeThread = mappedMessages.filter(({ id }) => id === activeThreadId);
  const currentMessages = activeThread.length && activeThread[0].messages;
  const updateMessages = () => {
    reloadMessages();
    const interval = setInterval(() => {
      reloadMessages();
    }, MESSAGES_RELOAD_REQUEST_INTERVAL);

    return () => {
      clearInterval(interval);
    };
  };

  useEffect(updateMessages, []);

  const handleEditConversationNameOpen = friendlyName => {
    if (!canEdit) return;
    setConversationName(friendlyName);
    setOpenEditPopup(true);
  };

  const handleFriendlyNameUpdateCancel = () => {
    setOpenEditPopup(false);
    setConversationName(null);
  };

  const handleNameChange = ({ target }) => {
    setConversationName(target.value);
  };

  const renderPlaceholderMessage = () => (
    <PaddedPlaceholder>
      <span>{MESSAGING_LABELS.paddedPlaceholder}</span>
    </PaddedPlaceholder>
  );

  const updateConversationLastReadById = conversationId => {
    sendNewMessageHandler(
      { ...conversationDetails, conversation: conversationId },
      {
        requestData: {
          last_read: Date.now(),
        },
        errorMessage: MESSAGING_LABELS.updateLastReadConversationError,
      },
    );
  };

  const handleSendMessage = () => {
    if (!currentMessage.trim().length) return;
    setIsLoading(true);
    sendNewMessageHandler(
      { ...conversationDetails, conversation: activeThread[0].id },
      {
        requestData: {
          message: currentMessage,
        },
        errorMessage: MESSAGING_LABELS.sendMessageError,
      },
    )
      .then(({ conversation }) => {
        updateThreadMessages(conversation);
        setCurrentMessage("");
        updateMessageLastRead(activeThreadId, new Date().getTime());
        updateConversationLastReadById(activeThreadId);
        setIsLoading(false);
      })
      .catch(() => {
        dispatch(
          setNotification({
            type: "error",
            message: RELOAD_MESSAGE_ERROR,
          }),
        );
        setIsLoading(false);
      });
  };

  const handleChangeActiveThread = elementId => {
    if (isSettingsMenuOpen) setIsSettingsMenuOpen(false);
    if (elementId === activeThreadId) setActiveThreadId(null);
    else setActiveThreadId(elementId);
    setCurrentMessage("");
    updateMessageLastRead(elementId, new Date().getTime());
    updateConversationLastReadById(elementId);
  };

  const handleFriendlyNameUpdate = () => {
    if (!conversationName && conversationName.length === 0) {
      setErrorMessage(ERRORS.isRequired("Title"));
    } else {
      friendlyNameUpdate(conversationName, activeThread[0].id);
      sendNewMessageHandler(
        { ...conversationDetails, conversation: activeThread[0].id },
        {
          requestData: {
            friendly_name: conversationName,
          },
          errorMessage: MESSAGING_LABELS.friendlyNameUpdateError,
        },
      )
        .then(() => {
          reloadMessages();
          setErrorMessage(null);
          setOpenEditPopup(false);
        })
        .catch(() => {
          dispatch(
            setNotification({
              type: "error",
              message: RELOAD_MESSAGE_ERROR,
            }),
          );
        });
    }
  };

  const handleSettingsMenuOpen = () => setIsSettingsMenuOpen(!isSettingsMenuOpen);
  const handleSettingsBarClick = () => {
    setActiveThreadId(null);
    handleSettingsMenuOpen();
  };

  const updateBarElementIfActive = () => {
    if (activeThread.length && checkIfMessageWasRead(activeThread[0].messages, activeThread[0].lastReadAt)) {
      updateConversationLastReadById(activeThread[0].id);
    }
  };

  const tryEnteringSettings = () => {
    if (!editMode) handleSettingsBarClick();
  };

  useEffect(tryEnteringSettings, []);

  const renderMessages = () => {
    return (
      <>
        <InfoBar
          details={{ destination: field1, firstName: field3, ...activeThread[0] }}
          handleEditFriendlyName={handleEditConversationNameOpen}
          configurationType={configurationType}
          canEdit={canEdit}
        />
        <MessagesWindow messages={currentMessages} />
        <SendMessageBar
          message={currentMessage}
          handleUpdateMessage={setCurrentMessage}
          isLoading={isLoading}
          handleMessageSend={() => handleSendMessage()}
        />
        <FieldChangeModal
          open={openEditPopup}
          label={MESSAGING_LABELS.title}
          value={conversationName}
          onValueEdit={handleNameChange}
          onConfirm={handleFriendlyNameUpdate}
          onCancel={handleFriendlyNameUpdateCancel}
          inputProps={{ error: !!errorMessage, helperText: errorMessage }}
        />
      </>
    );
  };

  const renderMessagesBox = () => <FullWidthContainer>{activeThreadId ? renderMessages() : renderPlaceholderMessage()}</FullWidthContainer>;
  const renderSettingsBox = (isMessagesPresent = false) => (
    <SettingsWindow
      messagesPresence={isMessagesPresent}
      configurationType={configurationType}
      closeHandler={handleSettingsMenuOpen}
      contextHandler={contextHandler}
    />
  );

  const renderMessagingContainer = () => {
    return conversations.length ? (
      <MessagingContainer>
        <ThreadsList
          threads={mappedMessages}
          isSettingsMenuOpen={isSettingsMenuOpen}
          handleSettingsBarClick={handleSettingsBarClick}
          onThreadElementClick={elementId => handleChangeActiveThread(elementId)}
        />
        <PositioningContainer>{isSettingsMenuOpen ? renderSettingsBox(true) : renderMessagesBox()}</PositioningContainer>
      </MessagingContainer>
    ) : (
      <NegativeMarginContainer>
        {isSettingsMenuOpen ? (
          renderSettingsBox()
        ) : (
          <>
            {editMode && <SpacedText>{MESSAGING_LABELS.noMessagesPresent}</SpacedText>}
            <ActionButton onClick={handleSettingsBarClick}>
              <SettingsApplicationsIcon />
              {MESSAGING_LABELS.settings}
            </ActionButton>
          </>
        )}
      </NegativeMarginContainer>
    );
  };

  const renderMessagingPlaceholder = () => (
    <PlaceholderContainer>
      <SpinnerWithMargin size={60} />
      <BodyText>{MESSAGING_LABELS.fetchingMessages}</BodyText>
    </PlaceholderContainer>
  );

  updateBarElementIfActive();
  return !editMode || finishedFetchingMessages ? renderMessagingContainer() : renderMessagingPlaceholder();
};

Messaging.propTypes = {
  conversationDetails: PropTypes.shape().isRequired,
  contextHandler: PropTypes.func.isRequired,
  configurationType: PropTypes.oneOf(["stay", "trip"]).isRequired,
};

export { Messaging };
