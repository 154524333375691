import React from "react";
import styled from "styled-components";
import Grid from "@material-ui/core/Grid";

const FullHeightColumn = styled(props => <Grid container {...props} />)`
  && {
    height: 100vh;
  }
`;

export { FullHeightColumn };
