import { useState, useEffect, useCallback } from "react";

import { isString } from "contracts/types";
import { useRefreshedFileUrl } from "hooks/useRefreshedFileUrl";
import { getFilePreviewUrl } from "utils/library";

/**
 * 
 * @param {string} icon 
 */
const getIconName = (icon) => {
  if(icon && isString(icon.image)) {
    return icon.image.split("/").pop();
  }
  return "";
}

/**
 * 
 * @param {Icon} initIcon 
 * @param {Function} onChange 
 */
const useManageIconHandlers = (initIcon, onChange) => {
  const [icon, setIcon] = useState(initIcon);
  const [name, setName] = useState("");
  const [attachment, setAttachment] = useState({
    ...initIcon,
    preview_url: icon?.image,
    file_name: getIconName(initIcon)
  });
  const [dataLoaded, setDataloaded] = useState(false);

  const handleChangeName = useCallback(
    ({ target }) => {
      setDataloaded(true);
      setName(target.value);
    },
    [setName, setDataloaded],
  );

  const handleChangeAttachment = useCallback(
    ([selectedAttachment]) => {
      setDataloaded(true);
      setAttachment(selectedAttachment);
    },
    [setAttachment, setDataloaded],
  );

  const handleRemoveAttachment = useCallback(() => {
    setAttachment(null);
  }, [setAttachment]);

  const prepareIcon = () => {
    if (initIcon) {
      setIcon(initIcon);
      setName(initIcon.name);
    }
  };

  const createNewIconObject = () => {
    if (dataLoaded) onChange({ ...icon, name, image: attachment || icon.image });
  };

  useEffect(prepareIcon, [initIcon]);
  useEffect(createNewIconObject, [name, attachment]);

  const previewUrl = useRefreshedFileUrl(getFilePreviewUrl(attachment || {}))

  return {
    handleChangeName,
    handleChangeAttachment,
    handleRemoveAttachment,
    name,
    attachment,
    icon,
    preview: previewUrl || (icon && icon.image),
  };
};

export { useManageIconHandlers };
