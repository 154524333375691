import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import moment from "moment";

import config from "config/app";

import { ConfirmationModal } from "components/ui/Modals/ConfirmationModal";
import { shoudlCloseModal } from "feature/panel/Trips/_shared/Flights/helpers";
import { tripsAddSelectedFlightsStart, tripsClearSelectedFlightsList } from "store/trips/actions";
import { useForm } from "react-hook-form";
import { HttpClient } from "services/application/httpClient/httpClient";
import { FlightsSearchForm } from "./FlightsSearchForm";
import { useManageTripContext } from "../ManageTripContext";
import { onFlightAdd } from "./FlightsSearchForm/helpers";

const StyledConfirmationModal = styled(({ withFlightList, ...rest }) => <ConfirmationModal {...rest} />)`
  && {
    .MuiDialog-paper,
    .MuiDialogContent-root {
      overflow-y: ${({ withFlightList }) => (withFlightList ? "auto" : "visible")};
    }
  }
`;

const FlightsSearchSection = ({ open, onCancel }) => {
  const defaultValues = {
    carrier_code: null,
    carrier_flight_number: "",
  };
  const form = useForm({
    defaultValues,
  });
  const [showManualForm, setShowManualForm] = useState(false);

  // const [airlineCode, setAirlineCode] = useState("");

  const [flightNumber, setFlightNumber] = useState("");
  const [flightDate, setFlightDate] = useState(null);
  const [selectedFlights, setSelectedFlights] = useState([]);
  const { listOfFoundFlights, listOfSelectedFlights, finished, actionType } = useSelector(state => state.trips);

  const { setValueFor, listOfFlights, departure_date } = useManageTripContext();

  const dispatch = useDispatch();

  const airlineCode = form.watch("carrier_code");

  const updateSelectedFlightsList = () => {
    if (listOfSelectedFlights.length) {
      const newFlightsList = listOfSelectedFlights.filter(selectedFlight => !listOfFlights.some(flight => flight.id === selectedFlight.id));
      if (newFlightsList.length > 0) {
        setValueFor("listOfFlights", [...listOfFlights, ...newFlightsList]);
      }
      dispatch(tripsClearSelectedFlightsList());
    }
  };

  const handleAddFlightsToList = data => {
    dispatch(
      tripsAddSelectedFlightsStart(
        data || {
          fs: airlineCode.value,
          number: flightNumber,
          date: flightDate.format(config.apiDateFormat),
          selectedFlights: selectedFlights || [],
        },
      ),
    );
  };

  const handleFlightsSelect = flighsData => {
    setSelectedFlights(flighsData.selectedFlights);
    // setAirlineCode(flighsData.airlineCode);
    setFlightNumber(flighsData.flightNumber);
    setFlightDate(moment(flighsData.flightDate?.toISOString()));
  };

  const handleClose = () => {
    onCancel();
    setShowManualForm(false);
    form.reset(defaultValues);
  };

  const closeModal = () => {
    if (shoudlCloseModal(finished, actionType)) {
      handleClose();
    }
  };

  useEffect(updateSelectedFlightsList, [listOfSelectedFlights]);
  useEffect(closeModal, [finished, actionType]);

  const onShowManualForm = () => setShowManualForm(true);

  const handleFlightAdd = async values => {
    const res = await onFlightAdd(values);
    handleAddFlightsToList(res);
  };

  const onConfirm = () => {
    if (showManualForm) form.handleSubmit(handleFlightAdd)();
    else handleAddFlightsToList();
  };

  const defaultFlightDate = new Date(flightDate || departure_date).getTime() < Date.now() ? new Date() : flightDate || departure_date;

  return open ? (
    <StyledConfirmationModal
      open={open}
      title="Add flight"
      confirmLabel="Add flight"
      confirmDisabled={!showManualForm && (listOfFoundFlights.length === 0 || selectedFlights.length === 0)}
      onCancel={handleClose}
      onConfirm={onConfirm}
      maxWidth="xl"
      withFlightList={listOfFoundFlights.length > 0}
    >
      <FlightsSearchForm
        defaultFlightDate={defaultFlightDate}
        onFlightsSelect={handleFlightsSelect}
        form={form}
        showManualForm={showManualForm}
        onShowManualForm={onShowManualForm}
      />
    </StyledConfirmationModal>
  ) : null;
};

FlightsSearchSection.propTypes = {
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export { FlightsSearchSection, StyledConfirmationModal };
