export class StoryboardFile {  

  static FILE_TYPE = "application/x-vamoos-storyboard";

  constructor(filename, storyboardEntry) {
    this.name = filename;
    this.type = StoryboardFile.FILE_TYPE;

    this.content = JSON.stringify({
      title: storyboardEntry.headline,
      location: storyboardEntry.location,
      content: storyboardEntry.content,
      image: storyboardEntry.image || null,
      documents: storyboardEntry.documents
    });
  }

  convertToNativeFile() {
    return new File([this.content], this.name, {
      type: StoryboardFile.FILE_TYPE,
    })
  }
}