import { setSpacing } from "config/theme/spacing";

const paddings = {
  buttons: {
    small: {
      vertical: setSpacing(1),
      horizontal: setSpacing(4)
    },
    medium: {
      vertical: setSpacing(2),
      horizontal: setSpacing(8)
    },
    large: {
      vertical: setSpacing(3),
      horizontal: setSpacing(12)
    }
  }
};

export { paddings };
