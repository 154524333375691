import * as actionTypes from "store/trips/actionTypes";

import { reducer, setState, globalReducerActions } from "utils/store";

const tripsInitialState = {
  errors: {},
  actionType: null,
  inProgress: false,
  finished: false,
  searchMode: false,
  listOfTrips: [],
  tripsCount: 0,
  listOfCreations: [],
  creationsCount: 0,
  airlineCodes: [],
  listOfFoundFlights: [],
  listOfSelectedFlights: [],
  recentLocations: [],
  publicStays: [],
  isTripFormTouched: false,
  creationModal: {
    opened: false,
  },
};

const ReducerActions = globalReducerActions("trips", tripsInitialState, {
  [actionTypes.TRIPS_GET_TRIPS_START](state) {
    return setState(state, {
      actionType: actionTypes.TRIPS_GET_TRIPS_START,
      inProgress: true,
      finished: false,
    });
  },

  [actionTypes.TRIPS_GET_TRIPS_SUCCESS](state, payload) {
    const { items, total_matches, searchMode } = payload;
    return setState(state, {
      actionType: actionTypes.TRIPS_GET_TRIPS_SUCCESS,
      inProgress: false,
      finished: true,
      listOfTrips: items,
      tripsCount: total_matches,
      errors: {},
      searchMode,
    });
  },

  [actionTypes.TRIPS_GET_TRIPS_FAIL](state, payload) {
    return setState(state, {
      actionType: null,
      inProgress: false,
      finished: false,
      listOfTrips: [],
      tripsCount: 0,
      errors: payload.errors,
      searchMode: payload.searchMode,
    });
  },

  [actionTypes.TRIPS_GET_TRIP_CREATIONS_START](state) {
    return setState(state, {
      actionType: actionTypes.TRIPS_GET_TRIP_CREATIONS_START,
      inProgress: true,
      finished: false,
    });
  },

  [actionTypes.TRIPS_GET_TRIP_CREATIONS_SUCCESS](state, payload) {
    const { count, items } = payload;
    return setState(state, {
      inProgress: false,
      finished: true,
      listOfCreations: items,
      creationsCount: count,
    });
  },

  [actionTypes.TRIPS_GET_TRIP_CREATIONS_FAIL](state, payload) {
    return setState(state, {
      inProgress: false,
      finished: false,
      errors: payload,
    });
  },

  [actionTypes.OPEN_CREATION_MODAL](state) {
    return setState(state, {
      creationModal: {
        opened: true,
      },
    });
  },

  [actionTypes.CLOSE_CREATION_MODAL](state) {
    return setState(state, {
      creationModal: {
        opened: false,
      },
    });
  },

  [actionTypes.CREATE_TRIP_CREATION_START](state) {
    return setState(state, {
      actionType: actionTypes.CREATE_TRIP_CREATION_START,
      inProgress: true,
      finished: false,
    });
  },

  [actionTypes.CREATE_TRIP_CREATION_SUCCESS](state, payload) {
    const { count, items } = payload;
    return setState(state, {
      inProgress: false,
      finished: true,
      listOfCreations: items,
      creationsCount: count,
    });
  },

  [actionTypes.CREATE_TRIP_CREATION_FAIL](state, payload) {
    return setState(state, {
      inProgress: false,
      finished: false,
      errors: payload,
    });
  },

  [actionTypes.DELETE_TRIP_CREATION_START](state) {
    return setState(state, {
      inProgress: true,
      finished: false,
      errors: {},
    });
  },

  [actionTypes.DELETE_TRIP_CREATION_SUCCESS](state, payload) {
    return setState(state, {
      inProgress: false,
      finished: true,
      listOfCreations: payload,
      creationsCount: state.creationsCount - 1,
    });
  },

  [actionTypes.DELETE_TRIP_CREATION_FAIL](state, payload) {
    return setState(state, {
      inProgress: false,
      finished: false,
      errors: payload,
    });
  },

  [actionTypes.CLEAR_TRIP_CREATION_JOB](state, payload) {
    const { id, status } = payload;
    const updatedListOfCreations = state.listOfCreations.map(creation =>
      creation.job && creation.job.id === id ? { ...creation, job: { ...creation.job, status } } : creation,
    );
    return setState(state, {
      listOfCreations: updatedListOfCreations,
    });
  },

  [actionTypes.TRIPS_GET_AIRLINE_CODES_SUCCESS](state, payload) {
    return setState(state, {
      airlineCodes: payload,
    });
  },

  [actionTypes.CREATE_OR_UPDATE_TRIP_START](state) {
    return setState(state, {
      actionType: actionTypes.CREATE_OR_UPDATE_TRIP_START,
      inProgress: true,
      finished: false,
      errors: {},
    });
  },

  [actionTypes.CREATE_OR_UPDATE_TRIP_SUCCESS](state, { data }) {
    return setState(state, {
      actionType: actionTypes.CREATE_OR_UPDATE_TRIP_SUCCESS,
      inProgress: false,
      finished: true,
      listOfTrips: state.listOfTrips.map(trip => (trip.vamoos_id === data.vamoos_id ? data : trip)),
      listOfSelectedFlights: [],
    });
  },

  [actionTypes.CREATE_OR_UPDATE_TRIP_FAIL](state, payload) {
    return setState(state, {
      actionType: actionTypes.CREATE_OR_UPDATE_TRIP_FAIL,
      inProgress: false,
      finished: false,
      errors: payload,
    });
  },

  [actionTypes.TRIPS_SEARCH_FLIGHTS_START](state) {
    return setState(state, {
      actionType: actionTypes.TRIPS_SEARCH_FLIGHTS_START,
      inProgress: true,
      finished: false,
      errors: {},
      listOfFoundFlights: [],
    });
  },

  [actionTypes.TRIPS_SEARCH_FLIGHTS_SUCCESS](state, payload) {
    return setState(state, {
      inProgress: false,
      finished: true,
      errors: {},
      listOfFoundFlights: payload,
    });
  },

  [actionTypes.TRIPS_SEARCH_FLIGHTS_FAIL](state, payload) {
    return setState(state, {
      listOfFoundFlights: [],
      inProgress: false,
      finished: false,
      errors: payload,
    });
  },

  [actionTypes.TRIPS_ADD_SELECTED_FLIGHTS_START](state) {
    return setState(state, {
      actionType: actionTypes.TRIPS_ADD_SELECTED_FLIGHTS_START,
      inProgress: true,
      finished: false,
      errors: {},
    });
  },

  [actionTypes.TRIPS_ADD_SELECTED_FLIGHTS_SUCCESS](state, payload) {
    return setState(state, {
      inProgress: false,
      finished: true,
      errors: {},
      listOfSelectedFlights: payload,
    });
  },

  [actionTypes.TRIPS_ADD_SELECTED_FLIGHTS_FAIL](state, payload) {
    return setState(state, {
      inProgress: false,
      finished: false,
      errors: payload,
    });
  },
  [actionTypes.CLEAR_LIST_OF_SELECTED_FLIGHTS](state) {
    return setState(state, {
      listOfSelectedFlights: [],
    });
  },
  [actionTypes.COPY_TRIP_START](state) {
    return setState(state, {
      actionType: actionTypes.COPY_TRIP_START,
      inProgress: true,
      finished: false,
    });
  },

  [actionTypes.COPY_TRIP_SUCCESS](state) {
    return setState(state, {
      actionType: actionTypes.COPY_TRIP_SUCCESS,
      inProgress: false,
      finished: true,
    });
  },

  [actionTypes.COPY_TRIP_FAIL](state, payload) {
    return setState(state, {
      inProgress: false,
      finished: false,
      errors: payload,
    });
  },

  [actionTypes.TRIPS_CLEAR_FOUND_FLIGHTS](state) {
    return setState(state, {
      inProgress: false,
      finished: false,
      errors: {},
      listOfFoundFlights: [],
    });
  },

  [actionTypes.REFRESH_TRIP_CREATION_SUCCESS](state, payload) {
    return setState(state, {
      inProgress: false,
      finished: true,
      errors: {},
      listOfCreations: payload,
    });
  },
  [actionTypes.CHECK_PASSCODE_AVAILABILITY_SUCCESS](state, payload) {
    // todo: Validation for copying should be separated
    return setState(state, {
      errors: {
        ...state.errors,
        passcode: payload,
      },
    });
  },
  [actionTypes.GET_PUBLIC_STAYS_START](state) {
    return setState(state, {
      inProgress: true,
    });
  },
  [actionTypes.GET_PUBLIC_STAYS_SUCCESS](state, payload) {
    return setState(state, {
      inProgress: false,
      publicStays: [...payload],
    });
  },
  [actionTypes.GET_PUBLIC_STAYS_FAIL](state, payload) {
    return setState(state, {
      inProgress: false,
      errors: { ...payload },
    });
  },
  [actionTypes.GET_RECENT_LOCATION_START](state) {
    return setState(state, {
      inProgress: true,
    });
  },
  [actionTypes.GET_RECENT_LOCATION_SUCCESS](state, payload) {
    return setState(state, {
      inProgress: false,
      recentLocations: [...payload],
    });
  },
  [actionTypes.GET_RECENT_LOCATION_FAIL](state, payload) {
    return setState(state, {
      inProgress: false,
      errors: { ...payload },
    });
  },
  [actionTypes.SET_TRIPS_FORM_TOUCHED](state, payload) {
    return setState(state, {
      isTripFormTouched: payload,
    });
  },
  [actionTypes.SET_TRIPS_FINISHED_STATUS](state, payload) {
    return setState(state, {
      finished: payload,
    });
  },
  [actionTypes.SET_TRIPS_ACTION_STATUS](state, payload) {
    return setState(state, {
      actionType: payload,
    });
  },
});

const tripsReducer = (state = tripsInitialState, action) => reducer(state, action, ReducerActions);

export { tripsReducer };
