import React from "react";
import styled from "styled-components";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";

import styles from "config/theme";
import { InfoBarGridElement } from "feature/panel/_shared/Messaging/Components/InfoBarGridElement";
import { H5 } from "components/ui/Typography/Typography";
import { SecondaryButton } from "components/ui/Buttons/SecondaryButton";
import { createThreadTitle } from "feature/panel/_shared/Messaging/shared/createThreadTitle";
import { InfoBarElement } from "feature/panel/_shared/Messaging/Components/InfoBarElement";
import { MESSAGING } from "constants/content";

const InfoBarContainer = styled.div`
  min-width: ${({ theme }) => theme.setSpacing(190)}px;
  background-color: ${styles.colors.withOpacity(styles.colors.grey10, 0.3)};
  padding: ${({ theme }) => theme.setSpacing(11)}px ${({ theme }) => theme.setSpacing(10)}px;
  display: flex;
  flex-direction: column;
`;

const StayBottomContent = styled(Grid)`
  display: flex;

  p {
    margin: 0;
  }
`;

const TripBottomContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
`;

const StyledSecondaryButton = styled(SecondaryButton)`
  &&& {
    margin-left: ${({ theme }) => theme.setSpacing(6)}px;
    padding: 0;
    width: ${({ theme }) => theme.setSpacing(10)}px;
    min-width: ${({ theme }) => theme.setSpacing(10)}px;
    height: ${({ theme }) => theme.setSpacing(10)}px;
    border: 1px solid ${styles.colors.grey50};
    color: ${styles.colors.grey50}; 
    padding-left: ${({ theme }) => theme.setSpacing(6)}px;
    padding-right: ${({ theme }) => theme.setSpacing(6)}px;

    svg {
      margin-right: 0;
    }
  }

  &&&:hover {
    box-shadow: ${styles.shadows.infoBar};
  }

  &&&:disabled {
    color: ${styles.colors.grey20};
    border: 1px solid ${styles.colors.grey20};
  }
`;

const TopContent = styled.div`
  margin-left: -${({ theme }) => theme.setSpacing(1.5)}px;
  margin-bottom: ${({ theme }) => theme.setSpacing(11)}px;
  display: flex;
  align-items: center;
`;

const InfoBar = ({ details, handleEditFriendlyName, configurationType, canEdit }) => {
  const { name, email, friendlyName, passcode, device, startDate, endDate, destination, firstName } = details;

  const displayedName = friendlyName || createThreadTitle({ name, device });

  const renderHeader = () => <H5 onClick={() => handleEditFriendlyName(displayedName)}>{displayedName}</H5>;

  const renderStayInfoBar = () => (
    <StayBottomContent container direction="row">
      <InfoBarGridElement
        container
        data={{ 
          topPlaceholder: MESSAGING.fullName, 
          topValue: name || "-", 
          bottomPlaceholder: MESSAGING.checkIn, 
          bottomValue: startDate 
        }}
      />
      <InfoBarGridElement
        container
        data={{ 
          topPlaceholder: MESSAGING.passcode, 
          topValue: passcode || "-", 
          bottomPlaceholder: MESSAGING.checkOut, 
          bottomValue: endDate 
        }}
      />
      <InfoBarGridElement
        container
        data={{ 
          topPlaceholder: passcode === "nested" ? MESSAGING.provider : MESSAGING.email, 
          topValue: email || "-", 
          bottomPlaceholder: MESSAGING.deviceType, 
          bottomValue: device 
        }}
      />
    </StayBottomContent>
  );

  const renderTripInfoBar = () => (
    <TripBottomContent>
      <InfoBarElement label="Trip" value={`${destination} ${firstName}`} />
      <InfoBarElement label="Device type" value={device} />
    </TripBottomContent>
  );

  return (
    <>
      <InfoBarContainer>
        <TopContent>
          {renderHeader()}
          <StyledSecondaryButton disabled={!canEdit} onClick={() => handleEditFriendlyName(displayedName)}>
            <EditOutlinedIcon />
          </StyledSecondaryButton>
        </TopContent>
        {configurationType === "stay" ? renderStayInfoBar() : renderTripInfoBar()}
      </InfoBarContainer>
    </>
  );
};

InfoBar.propTypes = {
  details: PropTypes.shape().isRequired,
  handleEditFriendlyName: PropTypes.func.isRequired,
  configurationType: PropTypes.oneOf(["stay", "trip"]).isRequired,
};

export { InfoBar, InfoBarContainer };
