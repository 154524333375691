import { LOCALISED_ITINERARY_URL } from "constants/api";
import { DEFAULT_PASSCODE } from "constants/defaults";
import { stayResponseDataMapper } from "feature/panel/Stays/_shared/payloadDataMapper";
import { HttpClient } from "services/application/httpClient/httpClient";
import { setUrl } from "utils/url";

export class StayVariantRepository {
  constructor() {
   /**
    * @type {import("services/application/httpClient/httpClient").HttpClient}
    */
   this.httpClient = HttpClient;
 }

 /**
   * @param {*} operator 
   * @param {string} languageCode
   * @returns 
   */
  async getStayVariant(operator, languageCode) {
    const url = setUrl(LOCALISED_ITINERARY_URL, { operator, code: DEFAULT_PASSCODE, languageCode }, true);
    const { data } = await this.httpClient.get(url);
    return stayResponseDataMapper(data);
  }
}