import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import { Accordion, AccordionSummary, AccordionDetails } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { firstLetterUpperCase } from "utils/string";

import { LIBRARY_FILES_TREE } from "constants/api";
import { TreeListDisplay } from "components/ui/TreeListDisplay";
import { HttpClient } from "services/application/httpClient/httpClient";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

const StyledExpansionPanel = styled(AccordionDetails)`
  .MuiTreeView-root {
    height: auto;
  }

  && .MuiTypography-body1 {
    font-family: Montserrat !important;
    letter-spacing: 0;
  }
`;

const StyledExpansionHeader = styled(AccordionSummary)`
  && .MuiTypography-body1 {
    font-family: Montserrat !important;
    letter-spacing: 0;
  }
`;

const requestLibraryTree = () => {
  return HttpClient.get(LIBRARY_FILES_TREE);
};

const ListTree = ({ uploadFolder, topMargin, bottomMargin, setUploadFolder, defaultPath, overriddenTree }) => {
  const classes = useStyles();
  const [libraryTree, setLibraryTree] = useState({});

  useEffect(() => {
    if (!overriddenTree) {
      requestLibraryTree()
        .then(({ data }) => {
          setLibraryTree(data.items);
        })
        .catch(() => {
          setLibraryTree({});
        });
    }
  }, [overriddenTree]);

  const folderNameParser = () => {
    const folderName = uploadFolder.split("/").pop();
    return folderName === "library" ? firstLetterUpperCase(folderName) : folderName;
  };

  return (
    <div style={{ marginTop: topMargin, marginBottom: bottomMargin }} className={classes.root}>
      <Accordion>
        <StyledExpansionHeader expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
          <Typography className={classes.heading}>Folder: </Typography>
          <Typography className={classes.secondaryHeading}>{folderNameParser()}</Typography>
        </StyledExpansionHeader>
        <StyledExpansionPanel>
          <TreeListDisplay libraryTree={overriddenTree || libraryTree} setUploadFolder={setUploadFolder} defaultPath={defaultPath} />
        </StyledExpansionPanel>
      </Accordion>
    </div>
  );
};

ListTree.propTypes = {
  uploadFolder: PropTypes.string.isRequired,
  topMargin: PropTypes.number,
  bottomMargin: PropTypes.number,
  setUploadFolder: PropTypes.func.isRequired,
  defaultPath: PropTypes.string,
  overriddenTree: PropTypes.shape(),
};

ListTree.defaultProps = {
  topMargin: 0,
  bottomMargin: 0,
  defaultPath: "",
  overriddenTree: null,
};

export { ListTree };
