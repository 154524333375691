import React, { memo } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import { TitleText3 } from "components/ui/Typography/Typography";

import { IconRow } from "./IconRow";

const StyledWrapper = styled.div`
  display: flex;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey10};
  &:last-of-type {
    border-bottom: none;
  }
`;

const TitleWrapper = styled.div`
  width: 160px;
  height: ${({ theme }) => theme.setSpacing(15)}px;
  padding-top: ${({ theme }) => theme.setSpacing(1)}px;
  display: flex;
  align-items: center;
`;

const IconsWrapper = styled.div`
  flex-grow: 1;
`;

const IconsSection = memo(({ name, icons }) => (
  <StyledWrapper>
    <TitleWrapper>
      <TitleText3 cv="grey50">{name}</TitleText3>
    </TitleWrapper>
    <IconsWrapper>
      {icons.map(icon => (
        <IconRow key={icon.id} icon={icon} />
      ))}
    </IconsWrapper>
  </StyledWrapper>
));

IconsSection.propTypes = {
  name: PropTypes.string.isRequired,
  icons: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export { IconsSection, StyledWrapper, TitleWrapper, IconsWrapper };
