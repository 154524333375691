import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { GET_ADMIN_OPERATORS_URL } from "constants/api";
import { CREATE_CONTENT_LABELS, EMPTY_LIST_MESSAGES_BASE, NAMES_OF_RESOURCES_LISTS, NO_RESULTS_FOUND_MESSAGE } from "constants/content";

import { LoadingScreen } from "components/ui/LoadingScreen/LoadingScreen";
import { NoResultsMessage } from "components/ui/Messages/NoResultsMessage";

import { useTableHandlers } from "hooks/useTableHandlers";

import { getAdminOperatorsStart } from "store/adminOperators/actions";
import { GET_ADMIN_OPERATORS_START } from "store/adminOperators/actionTypes";

import { SEARCH_PARAM_KEY, DEFAULT_ORDER_BY_PARAM, DESC_ORDER_KEY } from "feature/admin/Operators/_shared/constants";

import { checkQueryParamExistence } from "utils/url";

import { TableOfOperators } from "./TableOfOperators";

const TableContainer = ({ archived, queryString }) => {
  const { listOfOperators, inProgress, finished, operatorsCount, actionType } = useSelector(state => state.adminOperators);
  const tableHandlers = useTableHandlers(GET_ADMIN_OPERATORS_URL, getAdminOperatorsStart, {
    customSearchParam: SEARCH_PARAM_KEY,
    defaultOrderBy: DEFAULT_ORDER_BY_PARAM,
    defaultOrder: DESC_ORDER_KEY,
  });

  const { url, handleSearch, handleChangeArchived } = tableHandlers;

  useEffect(() => handleSearch(queryString), [queryString]);
  useEffect(() => handleChangeArchived(archived ? "on" : "off"), [archived]);

  const content = () => {
    if (inProgress && !finished && actionType === GET_ADMIN_OPERATORS_START) return <LoadingScreen />;
    if (!listOfOperators.length)
      return (
        <NoResultsMessage>
          {checkQueryParamExistence(url, SEARCH_PARAM_KEY)
            ? NO_RESULTS_FOUND_MESSAGE
            : EMPTY_LIST_MESSAGES_BASE(CREATE_CONTENT_LABELS.trip, NAMES_OF_RESOURCES_LISTS.trip)}
        </NoResultsMessage>
      );
    return (
      <TableOfOperators
        operators={listOfOperators}
        tableHandlers={tableHandlers}
        count={operatorsCount}
        isDataLoading={inProgress && actionType === GET_ADMIN_OPERATORS_START}
      />
    );
  };
  return <>{content()}</>;
};

TableContainer.defaultProps = {
  archived: false,
  queryString: "",
};

TableContainer.propTypes = {
  archived: PropTypes.bool,
  queryString: PropTypes.string,
};

export { TableContainer };
