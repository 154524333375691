import React, { useState, useRef, useEffect } from "react";
import { MarkerClusterer, InfoWindow, GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";
import { getGeocode, getLatLng } from "use-places-autocomplete";
import { LoadingScreen } from "components/ui/LoadingScreen/LoadingScreen";
import PropTypes from "prop-types";
import { CustomInfoWindow } from "feature/panel/Stays/_shared/General/CustomInfoWindow";
import { useEffectDependenciesOnly } from "hooks/useEffectDependenciesOnly";

import { MapWidgetSearchBar } from "../MapWidget_old/MapWidgetSearchBar/MapWidgetSearchBar";
import CustomMarker from "./marker";
import { containerStyle, viewSettings } from "./settings";
import { addType } from "./helpers";
import StaysMapInfoWindow from "./InfoWindows/Stay";
import CommonInfoWindow from "./InfoWindows/Common";
import SearchInfoWindow from "./InfoWindows/Location";
import RightClickWindow from "./InfoWindows/RightClickWindow/index";

const libraries = ["places"];

const infoWindows = {
  stays: <StaysMapInfoWindow />,
  common: <CommonInfoWindow />,
  search: <SearchInfoWindow />,
  coordinates: <CustomInfoWindow />,
  rightClick: <RightClickWindow />,
};

const infoWindow = (component, props) => React.cloneElement(component, props);

const MapWidget = ({
  mapContainerStyle,
  data,
  markerIcon,
  infoWindowsProps,
  onSearch,
  switchControls,
  defaultSwitchesEnabled,
  searchPlaceholder,
  searchByLocation,
  locations,
  hideSearch,
  onMarkerClick,
  options,
  customInfoWindowType,
  topLineStyle,
  disableRightClick,
  settings,
  loading,
}) => {
  const clustererRef = useRef();
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_KEY,
    libraries,
  });

  const [mapSettings, setMapSettings] = useState({ ...viewSettings, ...settings });
  const [markerInfo, setMarkerInfo] = useState(null);
  const [enabledData, setEnabledData] = useState(defaultSwitchesEnabled);
  const [markersCount, setMarkersCount] = useState({});
  const [readyData, setDataReady] = useState([]);
  const [clusterLoaded, setClusterLoaded] = useState(false);

  const infoWindowType = markerInfo?.item?.type;
  const getCoordinates = item => item?.coordinates || `${item.latitude}, ${item.longitude}`;
  // Combine data (pois, stays etc)
  const getData = () => {
    if (!data) return [];
    const countedItems = {};
    const result = [];
    // Go through enabledData array, example: ["stays", "pois"]
    enabledData.forEach(item => {
      // if there is a key in data object that equals item
      // push data from it to result
      const modifiedData = data[item] ? addType(data[item], item) : [];
      result.push(...modifiedData);
    });
    // Finally, we have an combined array with all enabled data
    const modifiedLocations = addType(locations, "location");
    return [...result, ...modifiedLocations];
  };

  useEffectDependenciesOnly(() => {
    clustererRef.current?.repaint();
  }, [data, locations]);
  useEffectDependenciesOnly(() => {
    setMarkersCount(null);
    if (data) setDataReady(getData());
  }, [data, enabledData]);

  // useEffectDependenciesOnly(() => {
  //   if (readyData) {
  //     const countedItems = {};
  //     readyData.forEach(item => {
  //       if (countedItems[getCoordinates(item)]) countedItems[getCoordinates(item)] += 1;
  //       else countedItems[getCoordinates(item)] = 1;
  //     });
  //     setMarkersCount(countedItems);
  //   }
  // }, [readyData]);

  if (loadError) return <div>Map cannot be loaded right now, sorry.</div>;

  if (!isLoaded) return <LoadingScreen />;

  const handleShowInfo = info => {
    setMarkerInfo(info);
    if (onMarkerClick) onMarkerClick(info);
  };
  const handleHideInfo = () => setMarkerInfo(null);
  const handleSwitch = (name, value) => {
    // if value === true, that means switch is checked
    // so we just add name of the switch to array
    if (value) {
      setEnabledData([...enabledData, name]);
    } else {
      // Else we remove name of the switch from array
      const filtered = enabledData.filter(item => item !== name);
      setEnabledData(filtered);
    }
  };

  const handleOptionClick = value => {
    getGeocode({ placeId: value.place_id }).then(results => {
      const { lat, lng } = getLatLng(results[0]);
      const { address_components, formatted_address } = results[0];
      const { main_text: name, secondary_text: location } = value.structured_formatting;
      setMarkerInfo({
        item: { name, location, longitude: lng, latitude: lat, type: customInfoWindowType || "search" },
        position: { lat, lng },
        address_components,
        formatted_address,
      });
      setMapSettings({ zoom: 9, center: { lat, lng } });
    });
  };

  const onRightClick = e => {
    if (disableRightClick) return;
    const lat = e.latLng.lat();
    const lng = e.latLng.lng();

    setMarkerInfo({
      item: { type: "rightClick" },
      position: { lat, lng },
    });
  };
  return (
    <div style={{ height: mapContainerStyle?.height ? mapContainerStyle.height + 88 : null, position: "relative" }}>
      {(loading || (!clusterLoaded && readyData?.length > 0)) && (
        <div
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            background: "rgba(255,255,255,0.5)",
            zIndex: 3,
            backdropFilter: "blur(2px)",
          }}
        >
          <LoadingScreen text="It may take few seconds..." />
        </div>
      )}

      {/* need to add 88px to height - height of top line */}
      {!hideSearch && (
        <MapWidgetSearchBar
          searchLabel={searchPlaceholder}
          onSearch={onSearch}
          switchControls={switchControls}
          onSwitch={handleSwitch}
          enabledData={enabledData}
          searchByLocation={searchByLocation}
          onOptionClick={handleOptionClick}
          styleContainer={topLineStyle?.container}
        />
      )}
      {isLoaded && (
        <GoogleMap
          mapContainerStyle={{ ...containerStyle, ...mapContainerStyle }}
          {...mapSettings}
          onRightClick={onRightClick}
          options={options}
        >
          {readyData?.length && (
            <MarkerClusterer
              maxZoom={20}
              averageCenter
              onLoad={clusterer => {
                clustererRef.current = clusterer;

                setClusterLoaded(true);
              }}
            >
              {clusterer => {
                // clusterer?.clearMarkers();

                return readyData.map(item => {
                  return (
                    <CustomMarker
                      key={item.vamoos_id || item.id}
                      markerIcon={markerIcon}
                      clusterer={clusterer}
                      // count={markersCount[getCoordinates(item)]}
                      onClick={handleShowInfo}
                      item={item}
                      noClustererRedraw
                    />
                  );
                });
              }}
            </MarkerClusterer>
          )}
          {/* {getData().map(item => {
          return (
            <CustomMarker
              key={item.vamoos_id || item.id}
              markerIcon={markerIcon}
              // clusterer={clusterer}
              onClick={handleShowInfo}
              item={item}
            />
          );
        })} */}

          {/* If markerInfo exists, show the info window */}
          {markerInfo && (
            <InfoWindow position={markerInfo.position} onCloseClick={handleHideInfo}>
              {infoWindow(infoWindows[infoWindowType] || infoWindows.common, {
                ...markerInfo,
                ...(infoWindowsProps?.[infoWindowType] || {}),
                onWindowInfoClose: handleHideInfo,
              })}
            </InfoWindow>
          )}
        </GoogleMap>
      )}
    </div>
  );
};

MapWidget.propTypes = {
  mapContainerStyle: PropTypes.object,
  data: PropTypes.exact({
    stays: PropTypes.array,
    pois: PropTypes.array,
    map: PropTypes.array,
  }),
  markerIcon: PropTypes.node,
  // Here you can pass additional props for infoWindow components
  infoWindowsProps: PropTypes.exact({
    stays: PropTypes.exact({
      isAdding: PropTypes.bool,
      onAddMarker: PropTypes.func,
      addButtonText: PropTypes.string,
    }),
    search: PropTypes.exact({
      addButtonText: PropTypes.string,
      onAddMarker: PropTypes.func,
      disableAddButton: PropTypes.bool,
      title: PropTypes.string,
    }),
    rightClick: PropTypes.shape({
      addButtonText: PropTypes.string,
      onAddMarker: PropTypes.func,
    }),
    coordinates: PropTypes.exact({
      onButtonClick: PropTypes.func,
    }),
  }),
  topLineStyle: PropTypes.exact({
    container: PropTypes.object,
  }),
  onSearch: PropTypes.func,
  clustererMaxZoom: PropTypes.number,
  switchControls: PropTypes.array,
  defaultSwitchesEnabled: PropTypes.array,
  searchPlaceholder: PropTypes.string,
  searchByLocation: PropTypes.bool,
  locations: PropTypes.array,
  hideSearch: PropTypes.bool,
  onMarkerClick: PropTypes.func,
  passItemOnMarkerAdd: PropTypes.bool,
  disableRightClick: PropTypes.bool,
  options: PropTypes.object,
  customInfoWindowType: PropTypes.oneOf(["coordinates"]),
};

MapWidget.defaultProps = {
  mapContainerStyle: {},
  data: {},
  markerIcon: null,
  infoWindowsProps: null,
  onSearch: null,
  clustererMaxZoom: 4,
  switchControls: [],
  defaultSwitchesEnabled: ["stays"],
  searchPlaceholder: "Search",
  searchByLocation: false,
  locations: [],
  hideSearch: PropTypes.false,
  onMarkerClick: null,
  passItemOnMarkerAdd: false,
  disableRightClick: false,
  options: {},
  customInfoWindowType: null,
  topLineStyle: null,
};

export default MapWidget;
