import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { ArrowBackIos } from "@material-ui/icons";
import PropTypes from "prop-types";

import { CUSTOM_APPS, GLOBAL_CONTENT } from "constants/content";
import { setValueForAccentColor } from "store/customAppForm/actions";

import sendIcon from "assets/images/send.svg";
import chatBgImg from "assets/images/caSmallBg.png";

import { usePermissionsService } from "hooks/usePermissionsService";
import { PERMISSIONS } from "constants/permissions";

import { CardSection, CardSectionsContainer, CustomCard, InputContentWrapper, SectionTitle } from "../../sharedComponents";
import { CustomColorPicker } from "../../ColorPicker/CustomColorPicker";
import {
  PreviewWrapper,
  PreviewIconTile,
  MobileSendButton,
  ButtonIconImg,
  BackButtonContainer,
  MobileSwitchIcon,
  MobileToggleControl,
  UserInfoContainer,
  UserInfoHeader,
  UserInfoContent,
  ChatPreview,
  ChatMessage,
  InputPreview,
  StyledMobileRadioButton,
  StyledPreviewCardSection,
} from "./styledComponents";

const AccentColorBox = ({ isHistoryVersion }) => {
  const permissionsService = usePermissionsService();
  const dispatch = useDispatch();

  
  const caStatus = useSelector(state => state.customAppForm?.customAppForm?.status);
  const accentColor = useSelector(state => state.customAppForm.customAppForm.colors.accentColor);
  
  const canEdit = permissionsService.can(PERMISSIONS.actions.update, PERMISSIONS.sections.operator, PERMISSIONS.resources.operators);
  const canCreate = permissionsService.can(PERMISSIONS.actions.create, PERMISSIONS.sections.operator, PERMISSIONS.resources.operators);
  
  const editDisabled = !((caStatus === null && canCreate) || (caStatus === "draft" && canEdit));
  
  const onColorUpdate = value => {
    dispatch(setValueForAccentColor(value));
  };

  const sendButtonPreview = (
    <MobileSendButton color={accentColor}>
      <ButtonIconImg src={sendIcon} />
    </MobileSendButton>
  );
  const backButtonPreview = (
    <BackButtonContainer accentColor={accentColor}>
      <ArrowBackIos />
      {GLOBAL_CONTENT.back}
    </BackButtonContainer>
  );
  const switchButtonPreview = (
    <>
      <MobileSwitchIcon accentColor={accentColor} />
    </>
  );

  const customSwitchButtonPreview = (
    <MobileToggleControl color={accentColor}>
      <div>{CUSTOM_APPS.elements.accentColor.km}</div>
      <div>{CUSTOM_APPS.elements.accentColor.miles}</div>
    </MobileToggleControl>
  );

  const textBoxPreview = (
    <UserInfoContainer>
      <UserInfoHeader>{CUSTOM_APPS.elements.accentColor.userInfoHeader}</UserInfoHeader>
      <UserInfoContent color={accentColor}>{CUSTOM_APPS.elements.accentColor.userInfoMessage}</UserInfoContent>
    </UserInfoContainer>
  );

  const chatItemPreview = (
    <ChatPreview bgImage={chatBgImg}>
      <ChatMessage color={accentColor}>
        {CUSTOM_APPS.elements.accentColor.sampleChatMsg}
        <span>{CUSTOM_APPS.elements.accentColor.sampleChatTime}</span>
      </ChatMessage>
    </ChatPreview>
  );

  const inputControlPreview = (
    <UserInfoContainer>
      <InputPreview color={accentColor}>
        <span>{CUSTOM_APPS.elements.accentColor.inputLabel}</span>
      </InputPreview>
    </UserInfoContainer>
  );

  return (
    <CustomCard>
      <CardSectionsContainer>
        <CardSection>
          <InputContentWrapper>
            <SectionTitle>{CUSTOM_APPS.elements.accentColor.label}</SectionTitle>
            <CustomColorPicker
              color={accentColor}
              onColorChange={onColorUpdate}
              label={CUSTOM_APPS.elements.accentColor.label}
              disabled={isHistoryVersion || editDisabled}
            />
          </InputContentWrapper>
        </CardSection>
        <StyledPreviewCardSection>
          <PreviewWrapper>
            <PreviewIconTile>{accentColor && backButtonPreview}</PreviewIconTile>
            <PreviewIconTile>{accentColor && switchButtonPreview}</PreviewIconTile>
            <PreviewIconTile>
              <StyledMobileRadioButton accentColor={accentColor} />
            </PreviewIconTile>
            <PreviewIconTile>{accentColor && customSwitchButtonPreview}</PreviewIconTile>
            <PreviewIconTile>{accentColor && sendButtonPreview}</PreviewIconTile>
            {accentColor && textBoxPreview}
            {accentColor && chatItemPreview}
            {accentColor && inputControlPreview}
          </PreviewWrapper>
        </StyledPreviewCardSection>
      </CardSectionsContainer>
    </CustomCard>
  );
};

AccentColorBox.defaultProps = {
  isHistoryVersion: false,
};

AccentColorBox.propTypes = {
  isHistoryVersion: PropTypes.bool,
};

export { AccentColorBox };
