import * as actionTypes from "store/stays/actionTypes";
import { reducer, setState, globalReducerActions } from "utils/store";

export const initialPublisher = {
  fileName: "",
  areDirectoriesEnabled: false,
  frontPage: {
    show: false,
    body: "",
    headline: "",
  },
  contactDetailsFrontPage: false,
  tableOfContents: false,
  directories: [],
  map1: { show: false, headline: "", show_pois: false, latitude: null, longitude: null, zoom: 10 },
  map2: { show: false, headline: "", show_pois: false, latitude: null, longitude: null, zoom: 10 },
  map3: { show: false, headline: "", show_pois: false, latitude: null, longitude: null, zoom: 10 },
  poiList: { show: false, orderBy: "type" },
  lastPage: {
    show: false,
    body: "",
    headline: "",
  },
  cover: {
    show: false,
    imagesize: "reduced",
    body: "",
  },
  contactDetailsLastPage: false,
};

const staysInitialState = {
  errors: {},
  actionType: null,
  inProgress: false,
  finished: false,
  searchMode: false,
  staysCount: 0,
  features: [],
  dnd: [],
  dndCount: 0,
  publishers: [],
  publishersCount: 0,
  isStayFormTouched: false,
  publisherModal: {
    form: initialPublisher,
    opened: false,
    errors: {
      emptySectionList: false,
    },
  },
};

const ReducerActions = globalReducerActions("stays", staysInitialState, {
  [actionTypes.GET_STAYS_FEATURES_START](state) {
    return setState(state, {
      actionType: actionTypes.GET_STAYS_FEATURES_START,
      inProgress: true,
      finished: false,
    });
  },

  [actionTypes.GET_STAYS_FEATURES_SUCCESS](state, payload) {
    return setState(state, {
      inProgress: false,
      finished: true,
      features: payload,
    });
  },

  [actionTypes.GET_STAYS_FEATURES_FAIL](state, payload) {
    return setState(state, {
      inProgress: false,
      finished: false,
      errors: payload,
    });
  },

  [actionTypes.GET_STAYS_DND_START](state) {
    return setState(state, {
      actionType: actionTypes.GET_STAYS_DND_START,
      inProgress: true,
      finished: false,
      errors: {},
    });
  },

  [actionTypes.GET_STAYS_DND_SUCCESS](state, payload) {
    const { items, count } = payload;
    return setState(state, {
      inProgress: false,
      finished: true,
      dnd: items,
      dndCount: count,
    });
  },

  [actionTypes.GET_STAYS_DND_FAIL](state, payload) {
    return setState(state, {
      inProgress: false,
      finished: false,
      dnd: [],
      dndCount: 0,
      errors: payload,
    });
  },

  [actionTypes.REQUEST_STAY_CONNECT_STATUS_START](state) {
    return setState(state, {
      actionType: actionTypes.REQUEST_STAY_CONNECT_STATUS_START,
      inProgress: true,
      finished: false,
      errors: {},
    });
  },

  [actionTypes.REQUEST_STAY_CONNECT_STATUS_SUCCESS](state) {
    return setState(state, {
      inProgress: false,
      finished: true,
    });
  },

  [actionTypes.REQUEST_STAY_CONNECT_STATUS_FAIL](state, payload) {
    return setState(state, {
      inProgress: false,
      finished: false,
      errors: payload,
    });
  },

  [actionTypes.SET_STAYS_DND_APPROVAL_START](state) {
    return setState(state, {
      actionType: actionTypes.SET_STAYS_DND_APPROVAL_START,
      inProgress: true,
      finished: false,
      errors: {},
    });
  },

  [actionTypes.SET_STAYS_DND_APPROVAL_SUCCESS](state, payload) {
    return setState(state, {
      inProgress: false,
      finished: true,
      dnd: payload,
    });
  },

  [actionTypes.SET_STAYS_DND_APPROVAL_FAIL](state, payload) {
    return setState(state, {
      inProgress: false,
      finished: false,
      errors: payload,
    });
  },

  [actionTypes.COPY_STAY_START](state) {
    return setState(state, {
      actionType: actionTypes.COPY_STAY_START,
      inProgress: true,
      finished: false,
    });
  },

  [actionTypes.CHECK_USER_ID_AVAILABILITY_SUCCESS](state, payload) {
    return setState(state, {
      errors: { ...state.errors, operator_code: payload },
    });
  },

  [actionTypes.SET_STAYS_DND_APPROVAL_FAIL](state, payload) {
    return setState(state, {
      inProgress: false,
      finished: false,
      errors: payload,
    });
  },

  [actionTypes.CREATE_STAYS_PUBLISHER_START](state) {
    return setState(state, {
      actionType: actionTypes.CREATE_STAYS_PUBLISHER_START,
      inProgress: true,
      finished: false,
    });
  },

  [actionTypes.CREATE_STAYS_PUBLISHER_SUCCESS](state, payload) {
    const { items, count } = payload;
    return setState(state, {
      inProgress: false,
      finished: true,
      publishers: items,
      publishersCount: count,
    });
  },

  [actionTypes.CREATE_STAYS_PUBLISHER_FAIL](state, payload) {
    return setState(state, {
      inProgress: false,
      finished: false,
      errors: payload,
    });
  },

  [actionTypes.GET_STAYS_PUBLISHERS_START](state) {
    return setState(state, {
      actionType: actionTypes.GET_STAYS_PUBLISHERS_START,
      inProgress: true,
      finished: false,
    });
  },

  [actionTypes.GET_STAYS_PUBLISHERS_SUCCESS](state, payload) {
    const { items, count } = payload;
    return setState(state, {
      inProgress: false,
      finished: true,
      publishers: items,
      publishersCount: count,
    });
  },

  [actionTypes.GET_STAYS_PUBLISHERS_FAIL](state, payload) {
    return setState(state, {
      inProgress: false,
      finished: false,
      errors: payload,
    });
  },

  [actionTypes.DELETE_STAYS_PUBLISHER_START](state) {
    return setState(state, {
      actionType: actionTypes.DELETE_STAYS_PUBLISHER_START,
      inProgress: true,
      finished: false,
    });
  },

  [actionTypes.DELETE_STAYS_PUBLISHER_SUCCESS](state, payload) {
    return setState(state, {
      inProgress: false,
      finished: true,
      publishers: payload,
      publishersCount: state.publishersCount - 1,
    });
  },

  [actionTypes.DELETE_STAYS_PUBLISHER_FAIL](state, payload) {
    return setState(state, {
      inProgress: false,
      finished: false,
      errors: payload,
    });
  },

  [actionTypes.CLEAR_STAYS_PUBLISHER_JOB](state, payload) {
    const { id, status } = payload;
    const updatedListOfPublishers = state.publishers.map(publisher =>
      publisher.job && publisher.job.id === id ? { ...publisher, job: { ...publisher.job, status } } : publisher,
    );
    return setState(state, {
      publishers: updatedListOfPublishers,
    });
  },

  [actionTypes.REFRESH_STAYS_PUBLISHER_SUCCESS](state, payload) {
    return setState(state, {
      inProgress: false,
      finished: true,
      errors: {},
      publishers: payload,
    });
  },
  [actionTypes.OPEN_PUBLISHER_MODAL](state, payload = null) {
    return setState(state, {
      publisherModal: {
        ...state.publisherModal,
        form: payload === null ? initialPublisher : payload,
        opened: true,
      },
    });
  },
  [actionTypes.CLOSE_PUBLISHER_MODAL](state) {
    return setState(state, {
      publisherModal: {
        ...state.publisherModal,
        form: initialPublisher,
        opened: false,
      },
    });
  },
  [actionTypes.SET_PUBLISHER_MODAL_VALUE](state, payload) {
    const { field, value } = payload;

    return setState(state, {
      publisherModal: {
        ...state.publisherModal,
        form: {
          ...state.publisherModal.form,
          [field]: value,
        },
      },
    });
  },
  [actionTypes.SET_PUBLISHER_MODAL_ERROR](state, payload) {
    const { error, value } = payload;

    return setState(state, {
      publisherModal: {
        ...state.publisherModal,
        errors: {
          [error]: value,
        },
      },
    });
  },
});

const staysReducer = (state = staysInitialState, action) => reducer(state, action, ReducerActions);

export { staysReducer };
