// @ts-check

export class OverridesService {
  /**
   * @param {import("repositories/OverrideRepository").OverrideRepository} overrideRepository 
   * @param {import("mappers/OverrideMapper").OverrideMapper} overrideMapper 
   */
  constructor(overrideRepository, overrideMapper) {
    this.overrideRepository = overrideRepository;
    this.overrideMapper = overrideMapper;
  }

  /**
   * @param {number} vamoosId 
   * @returns {Promise<any>}
   */
  async getOverride(vamoosId) {
    try {
      const response = await this.overrideRepository.getOverride(vamoosId);
      return this.overrideMapper.fromDtoToDomain(response.data);
    } catch(e) {
      return {};
    }
  }

  /**
   * 
   * @param {number} vamoosId 
   * @param {Object} stay 
   * @returns {Promise<any>}
   */
  async saveOverride(vamoosId, stay) {
    return this.overrideRepository.saveOverride(vamoosId, this.overrideMapper.fromDomainToDto(stay));
  }
}