import React from "react";
import { useSelector } from "react-redux";
import { ArrowBackIos } from "@material-ui/icons";

import { CUSTOM_APPS, GLOBAL_CONTENT } from "constants/content";

import { PhonePresentation, ContentWrapper, PhoneHeader, LeftSlot, MiddleSlot, RightSlot } from "../PhonePresentation";
import { PresentationContent, WysywigContent } from "../TextsPage/sharedComponents";
import { HomeButton } from "../sharedComponents";
import { createMarkupForPreview } from "../TextsPage/helpers";

const AboutPreview = ({ language, title }) => {
  const textsColors = useSelector(state => state.customAppForm.customAppForm.menu.documents.about.styles);
  const accentColor = useSelector(state => state.customAppForm.customAppForm.colors.accentColor);
  const aboutValue = useSelector(
    state => state.customAppForm.customAppForm?.menu?.documents?.about?.languages?.[language.current || language]?.html_content,
  );

  const headerConfig = {
    label: title,
    bgColor: "#ffffff",
    visible: true,
  };

  const homeButton = (
    <HomeButton color={accentColor}>
      <ArrowBackIos />
      {GLOBAL_CONTENT.home}
    </HomeButton>
  );

  const content = (
    <PresentationContent bodyTextColor={textsColors?.textColor} headerTextColor={textsColors?.headlineColor} linkColor={accentColor}>
      <WysywigContent dangerouslySetInnerHTML={createMarkupForPreview(aboutValue)} />
    </PresentationContent>
  );

  return (
    <PhonePresentation>
      <>
        <PhoneHeader bgColor={headerConfig.bgColor} fontColor={headerConfig.fontColor}>
          <LeftSlot>{homeButton}</LeftSlot>
          <MiddleSlot>{headerConfig.label}</MiddleSlot>
          <RightSlot />
        </PhoneHeader>
        <ContentWrapper bgColor={textsColors?.backgroundColor}>{content}</ContentWrapper>
      </>
    </PhonePresentation>
  );
};

export { AboutPreview };
