import React, { useContext } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Box, Grid } from "@material-ui/core";
import styled from "styled-components";

import { FeatureOption } from "feature/panel/Stays/_shared/General/FeatureOption";
import { ImagePreview } from "components/ui/Images";
import { useRefreshedFileUrl } from "hooks/useRefreshedFileUrl";
import { LanguageContext } from "components/_shared/LanguageSelector/LanguageContext";

const LabelText = styled.span`
  margin-left: 10px;
  flex-grow: 1;
`;

const FeaturesOptionsListWrapper = styled(Grid)`
  flex-grow: 1;
`;

const FeatureOptionLabel = ({ option }) => {

  const url = useRefreshedFileUrl(option.icon.previewUrl);

  return (
    <Box display="flex" alignItems="center">
      <ImagePreview image={url} bgType="contain" width="24px" height="24px" />
      <LabelText>{option.name}</LabelText>
    </Box>
  )
}

const FeatureOptionList = ({ list, onChange, disabled }) => {
  const { currentLanguage } = useContext(LanguageContext);

  const isStayDefaultLanguage = useSelector(
    ({ stayWizard }) => stayWizard.defaultVariant.language ? stayWizard.defaultVariant.language === currentLanguage.code : true
  )

  const features = useSelector(({ stayWizard }) => isStayDefaultLanguage ? stayWizard.form.features : stayWizard.defaultVariant.features);

  const isFeatureOptionSelected = option => features.some(feature => feature.id === option.id);

  return (
    <FeaturesOptionsListWrapper container>
      {
        list.map(option => {
          const label = <FeatureOptionLabel option={option} />;

          return (
            <FeatureOption
              key={option.name}
              value={isFeatureOptionSelected(option)}
              label={label}
              onChange={() => onChange(option)}
              disabled={disabled}
            />
          )
        })
      }
    </FeaturesOptionsListWrapper>
  )
};

FeatureOptionList.defaultProps = {
  disabled: false,
};

FeatureOptionList.propTypes = {
  list: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string
  })).isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export { FeatureOptionList };
