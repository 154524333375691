import uuidv4 from "uuid";
import { convertToFileObject } from "utils/dataConverters";

// todo: update when inspiration will be rewrited to redux
export function Inspiration(inspirationDto) {
  const domain = {};

  domain.contact_email = inspirationDto.contact_email;
  domain.contact_phone = inspirationDto.contact_phone;
  domain.created_at = inspirationDto.created_at;
  domain.id = inspirationDto.id;
  domain.meta = inspirationDto.meta;
  domain.name = inspirationDto.name;
  domain.notification_text = inspirationDto.notification_text;
  domain.operator_code = inspirationDto.operator_code;
  domain.operator_id = inspirationDto.operator_id;
  domain.reference_code = inspirationDto.reference_code;
  domain.updated_at = inspirationDto.updated_at;
  domain.vamoos_id = inspirationDto.vamoos_id;
  domain.data = {
    rows: inspirationDto.data.rows.map(row => ({
      id: uuidv4(),
      ...row,
      images: row.images.map(({ description, image }) => ({
        id: uuidv4(),
        description,
        image: { ...convertToFileObject(image), id: uuidv4() },
      })),
    })),
  };

  return domain;
}
