import React, { useContext, useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import SendOutlinedIcon from "@material-ui/icons/SendOutlined";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { setNotification } from "store/app/actions";
import { emailValidate } from "utils/validation";

import { StayService } from "services/domain/StayService";

import { usePermissionsService } from "hooks/usePermissionsService";
import { PERMISSIONS } from "constants/permissions";

import { Message } from "components/ui/Messages";
import { FormSection, Input, Switch } from "components/ui/Forms";
import { SecondaryButton } from "components/ui/Buttons";
import { Table, TableHeader, TableBody, TablePagination, DeleteAction } from "components/ui/Tables";
import { ERRORS, STAY_WIZARD_CONTENT } from "constants/content";
import { useTableHandlers } from "hooks/useTableHandlers";
import { TableActionsContainer } from "components/ui/Tables/TableRowActions";

import { PANEL_STAYS_EDIT_PATH } from "constants/routes";
import { setUrl } from "utils/url";
import { LanguageContext } from "components/_shared/LanguageSelector/LanguageContext";

import { StayShareAccessTableRow } from "./StayShareAccessTableRow";

const StayShareAccess = () => {
  /** @type {StayService} */
  const stayService = new StayService();

  /** @type {import("services/PermissionsService").PermissionsService} */
  const permissionsService = usePermissionsService();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { operator_code } = useParams();

  const { currentLanguage } = useContext(LanguageContext);

  const isStayDefaultLanguage = useSelector(({ stayWizard }) =>
    stayWizard.defaultVariant.language ? stayWizard.defaultVariant.language === currentLanguage.code : true,
  );

  const [invitationFormEmail, setInvitationFormEmail] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [invitationFormWrite, setInvitationFormWrite] = useState(false);
  const [users, setUsers] = useState([]);
  const [totalMatches, setTotalMatches] = useState(0);

  const userId = useSelector(({ stayWizard }) => stayWizard.form.userId);
  const vamoosId = useSelector(({ stayWizard }) => stayWizard.form.vamoosId);
  const editMode = useSelector(({ stayWizard }) => stayWizard.form.vamoosId !== null);
  const currentUserEmail = useSelector(({ auth }) => auth.user.email);

  const isUserInvitedToTheVamoos = useSelector(({ auth, stayWizard }) => {
    if (stayWizard.form.vamoosId) {
      const currentUserOperator = auth.user?.operators.find(operator => operator.code === auth?.currentOperatorCode);

      return currentUserOperator.permissions.vamoos_list[stayWizard.form.vamoosId];
    }
    return false;
  });

  const canEdit = permissionsService.can(PERMISSIONS.actions.update, PERMISSIONS.sections.vamoosList, vamoosId);

  const { rowsPerPage, page, sortingOrder, sortingBy, handleChangeOrder, handleChangeRowsPerPage, handleChangePage } = useTableHandlers(
    "",
    () => {},
  );

  const headers = [
    { label: STAY_WIZARD_CONTENT.permissions.email, key: "email", show: true, sortable: true },
    { label: STAY_WIZARD_CONTENT.permissions.read, key: "read", show: true, sortable: true },
    { label: STAY_WIZARD_CONTENT.permissions.write, key: "write", show: true, sortable: true },
  ];

  const fetchUsers = async () => {
    /**
     * @type {import("domain/Paginated").Paginated<import("domain/StayPermission").StayPermission>}
     */
    const usersList = await stayService.getStayUsers(operator_code, {
      rowsPerPage,
      page,
      order: sortingOrder,
      orderBy: sortingBy,
    });

    setTotalMatches(usersList.totalMatches);
    setUsers(
      usersList.items.map(permission => {
        return {
          id: permission.user.id,
          email: permission.user.email,
          read: permission.vamoosPermissions?.includes("r"),
          write: permission.vamoosPermissions?.includes("u"),
        };
      }),
    );
  };

  const inviteUser = async () => {
    if (isEmailValid && invitationFormEmail.length > 0) {
      try {
        if (currentUserEmail === invitationFormEmail) {
          await dispatch(setNotification({ type: "error", message: STAY_WIZARD_CONTENT.permissions.notifications.inviteSelf }));
        } else {
          await stayService.addUserToStay(invitationFormEmail, invitationFormWrite ? "write" : "read", operator_code);
          await fetchUsers();
          await dispatch(setNotification({ type: "success", message: STAY_WIZARD_CONTENT.permissions.notifications.invitedUser }));
          setInvitationFormEmail("");
          setInvitationFormWrite(false);
        }
      } catch (e) {
        if (e.response.status === 403) {
          await dispatch(setNotification({ type: "error", message: STAY_WIZARD_CONTENT.permissions.notifications.inviteExisting }));
        }
        await dispatch(setNotification({ type: "error", message: STAY_WIZARD_CONTENT.permissions.notifications.fetchUsersError }));
      }
    }
  };

  const removeUser = async (user, operator) => {
    try {
      await stayService.removeUserFromStay(user.id, operator);
      await fetchUsers();
      await dispatch(setNotification({ type: "success", message: STAY_WIZARD_CONTENT.permissions.notifications.removedUser }));
    } catch (e) {
      await dispatch(setNotification({ type: "error", message: STAY_WIZARD_CONTENT.permissions.notifications.fetchUsersError }));
    }
  };

  const bodyCellAction = ({ item }) => {
    const isActiveEmail = item.email === currentUserEmail;

    return (
      <TableActionsContainer>
        {canEdit && !isUserInvitedToTheVamoos && (
          <DeleteAction
            clickAction={() => removeUser(item, operator_code)}
            tooltip={STAY_WIZARD_CONTENT.permissions.removeUser}
            disabled={isActiveEmail}
          />
        )}
      </TableActionsContainer>
    );
  };

  useEffect(() => {
    if (editMode) {
      fetchUsers();
    }
  }, [rowsPerPage, page]);

  useEffect(() => {
    if (!isStayDefaultLanguage) {
      const url = setUrl(PANEL_STAYS_EDIT_PATH, { operator_code: userId }, true);
      navigate(url);
    }
  }, [isStayDefaultLanguage]);

  return (
    <Grid container justifyContent="center">
      <Grid item md={12} lg={10}>
        {editMode ? (
          <>
            <FormSection title={STAY_WIZARD_CONTENT.permissions.inviteUser}>
              <Grid container spacing={10}>
                <Grid item xs={12} md={6}>
                  <Input
                    label={STAY_WIZARD_CONTENT.permissions.email}
                    name="email"
                    value={invitationFormEmail}
                    onChange={({ target }) => {
                      setInvitationFormEmail(target.value);
                    }}
                    onBlur={() => {
                      if (invitationFormEmail.length > 0) {
                        setIsEmailValid(emailValidate(invitationFormEmail));
                      } else {
                        setIsEmailValid(true);
                      }
                    }}
                    error={!isEmailValid}
                    helperText={!isEmailValid ? ERRORS.invalidEmail : ""}
                    disabled={!canEdit}
                  />
                </Grid>
                <Grid item>
                  <Switch name="read" label={STAY_WIZARD_CONTENT.permissions.read} labelPlacement="start" checked disabled />
                </Grid>
                <Grid item>
                  <Switch
                    name="write"
                    label={STAY_WIZARD_CONTENT.permissions.write}
                    labelPlacement="start"
                    checked={invitationFormWrite}
                    onChange={() => setInvitationFormWrite(!invitationFormWrite)}
                    disabled={!canEdit}
                  />
                </Grid>
                <Grid item>
                  <SecondaryButton disabled={!canEdit || invitationFormEmail.length === 0} onClick={() => inviteUser()}>
                    <SendOutlinedIcon />
                    {STAY_WIZARD_CONTENT.permissions.invite}
                  </SecondaryButton>
                </Grid>
              </Grid>
            </FormSection>
            {users.length > 0 && (
              <FormSection title={STAY_WIZARD_CONTENT.permissions.invitedUsers}>
                <Table
                  headers={headers}
                  list={users}
                  actions={bodyCellAction}
                  onChangeOrder={handleChangeOrder}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                >
                  <TableHeader withActions />
                  <TableBody rowComponent={StayShareAccessTableRow} />
                  <TablePagination count={totalMatches} />
                </Table>
              </FormSection>
            )}
          </>
        ) : (
          <FormSection title={STAY_WIZARD_CONTENT.permissions.permissions}>
            <Message type="warning" text={STAY_WIZARD_CONTENT.permissions.message} />
          </FormSection>
        )}
      </Grid>
    </Grid>
  );
};

export { StayShareAccess };
