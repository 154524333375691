export const SEARCH_PARAM_KEY = "search";
export const DESC_ORDER_KEY = "desc";
export const SHOW_DEACTIVATED_NAME = "show-deactivated";
export const SHOW_DEACTIVATED_LABEL_PLACEMENT = "start";
export const DEFAULT_ORDER_BY_PARAM = "created_at";
export const SORTING_COLUMN_MAPPER = {
  code: "code",
  name: "name",
  createdAt: "created_at",
  last30days: "created_last30",
  total: "downloads_total",
};
