import moment from "moment";

import { createDefaultTime } from "feature/panel/Stays/_shared/createDefaultTime";

export const DEFAULT_ACTION_ICON_ID = 282;
export const DEFAULT_BOOK_NOW_ICON_ID = 338;

const todayDate = new Date().toISOString();
export default {
  type: "stay",
  field1: "",
  field2: "",
  field3: "",
  field4: "",
  departure_date: todayDate,
  return_date: todayDate,
  client_reference: "",
  operator_code: "",
  background: null,
  logo: null,
  details: [],
  notifications: [],
  locations: [],
  documents: {
    travel: [{ name: "", icon_id: 0 }],
    destination: [{ name: "", icon_id: 0 }],
  },
  passcode_groups: [],
  publishers: [],
  directories: [],
  is_wiped: false,
  features: [],
  meta: {
    show_directory: "always",
    show_vouchers: "always",
    show_daily_activities: "always",
    show_journal: false,
    show_poi_list: false,
    home_screen_description: "",
    short_description: "",
    long_description: "",
    number_of_rooms: "",
    address: "",
    latitude: "",
    longitude: "",
    phone: "",
    email: "",
    website: "",
    twitter: "",
    facebook: "",
    instagram: "",
    hotel_info_label: "",
    hotel_info_icon_id: null,
    show_messaging: "off",
    messaging_emails_list: [],
    messaging_email_during_days_before: "0",
    messaging_email_during_days_after: "0",
    dnd_contact_email: "",
    dnd_cut_off_time: moment(createDefaultTime({ hour: 11, minutes: 0, seconds: 0 })).toISOString(),
    dnd_warning_message: "",
    show_dnd: "off",
    require_personal_details: false,
  },
  pois: [],
  is_active: true,
  errors: {},
};
