import React, { useContext } from "react";
import PropTypes from "prop-types";
import TableCell from "@material-ui/core/TableCell";

import styled from "styled-components";

import { ChipWithRedirect } from "components/ui/Chips/ChipWithRedirect";
import { TableRow } from "components/ui/Tables";
import { IconImage } from "components/ui/Icons";

import { DEFAULT_CELL_MAX_CHARACTERS } from "constants/defaults";
import { TextLimiter } from "components/ui/TextLimiter";
import { useActiveIcon } from "hooks/useActiveIcon";
import { LanguageContext } from "components/_shared/LanguageSelector/LanguageContext";
import { LocalisationService } from "services/LocalisationService";

const IconCenterer = styled.div`
  display: flex;
  align-items: center;
`;

const LocalisedTableCell = styled(TableCell)`
  && {
    ${({ $localised }) =>
      !$localised &&
      `
      opacity: 0.4;
    `}
  }
`;

const PoisTableRow = ({ item }) => {
  const { icon, poiRange, latitude, longitude, country } = item;

  const { currentLanguage } = useContext(LanguageContext);

  const localisationService = new LocalisationService(currentLanguage);

  const isNameLocalised = localisationService.isPropertyLocalised(item, "name");
  const isDescriptionLocalised = localisationService.isPropertyLocalised(item, "description");
  const isFileLocalised = localisationService.isPropertyLocalised(item, "file");

  const name = isNameLocalised ? localisationService.localise(item, "name") : item.name;
  const description = isDescriptionLocalised ? localisationService.localise(item, "description") : item.description;
  const file = isFileLocalised ? localisationService.localise(item, "file") : item.file;

  const chipFileIcon = useActiveIcon(file);

  const renderFileDeleter = () => <ChipWithRedirect onDelete={() => {}} item={file} icon={chipFileIcon} editable={false} maxChar={15} />;

  return (
    <TableRow item={item}>
      <TableCell>
        <IconCenterer>
          <IconImage src={icon?.url} />
        </IconCenterer>
      </TableCell>
      <LocalisedTableCell $localised={isNameLocalised}>{name}</LocalisedTableCell>
      <TableCell>{country}</TableCell>
      <TableCell style={{ minWidth: 120 }}>{poiRange}</TableCell>
      <TableCell>{`${latitude}, ${longitude}`}</TableCell>
      <LocalisedTableCell $localised={isDescriptionLocalised}>
        <TextLimiter maxChar={DEFAULT_CELL_MAX_CHARACTERS} text={description} />
      </LocalisedTableCell>
      <LocalisedTableCell $localised={isFileLocalised}>{!file ? "-" : renderFileDeleter()}</LocalisedTableCell>
    </TableRow>
  );
};

PoisTableRow.propTypes = {
  item: PropTypes.shape().isRequired,
};

export { PoisTableRow };
