import React from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { PrimaryButton, ActionButton } from "components/ui/Buttons";
import { PANEL_POIS_ICON_MANAGER_PATH } from "constants/routes";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import AddOutlinedIcon from "@material-ui/icons/AddOutlined";
import { CREATE_CONTENT_LABELS } from "constants/content";
import { usePermissionsService } from "hooks/usePermissionsService";
import { PERMISSIONS } from "constants/permissions";

const Actions = ({ onClickCreate }) => {
  const permissionsService = usePermissionsService();
  const navigate = useNavigate();
  const redirect = () => navigate(PANEL_POIS_ICON_MANAGER_PATH);
  return (
    <>
      {permissionsService.can(PERMISSIONS.actions.update, PERMISSIONS.sections.operator, PERMISSIONS.resources.icons) && (
        <ActionButton mr={6} onClick={redirect}>
          <EditOutlinedIcon />
          <span>manage icons</span>
        </ActionButton>
      )}

      {permissionsService.can(PERMISSIONS.actions.create, PERMISSIONS.sections.operator, PERMISSIONS.resources.pois) && (
        <PrimaryButton onClick={onClickCreate}>
          <AddOutlinedIcon />
          <span>{CREATE_CONTENT_LABELS.poi}</span>
        </PrimaryButton>
      )}
    </>
  );
};

Actions.propTypes = {
  onClickCreate: PropTypes.func.isRequired,
};

export { Actions };
