import { extractCoordinates } from "utils";
import { latitudeValidate, longitudeValidate } from "utils/validation";
import { ERRORS } from "constants/content";

export const coordinatesValidator = coordinates => {
  const { latitude, longitude } = extractCoordinates(coordinates);

  if (!latitudeValidate(latitude))
    return {
      latitude,
      longitude,
      text: ERRORS.latitude,
      error: true,
    };
  if (!longitudeValidate(longitude))
    return {
      latitude,
      longitude,
      text: ERRORS.longitude,
      error: true,
    };
  return {
    latitude,
    longitude,
    text: `Latitude: ${latitude} Longitude: ${longitude || ""}`,
    error: false,
  };
};
