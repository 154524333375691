import React, { useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

const GoogleAuth = ({ handleSignIn, setSpinner }) => {

  const { composerData } = useSelector(state => state.auth);

  const handleGoogleSignIn = useCallback(async (response) => {
    setSpinner(true);
    const signInUserBody = {
      method: composerData?.auth.google.name,
      clientId: response.client_id,
      idToken: response.credential
    };

    await handleSignIn('google', signInUserBody);
  }, [composerData?.auth.google.name, handleSignIn]);

  useEffect(() => {
    if (!composerData) return;
    let isMounted = true;
    window.google.accounts.id.initialize({
      client_id: composerData?.auth.google.systemClientId,
      callback: handleGoogleSignIn,
    });

    if (isMounted) {
      window.google.accounts.id.renderButton(
        document.getElementById("googleButton"),
        { theme: "standard", size: "medium", logo_alignment: "center" }
      );
    }
    return () => {
      isMounted = false;
    };
  }, [composerData, handleGoogleSignIn]);

  return (
    <>
      <div id="googleButton" />
    </>
  )
}

GoogleAuth.propTypes = {
  handleSignIn: PropTypes.func.isRequired,
  setSpinner: PropTypes.func.isRequired,
};

export default GoogleAuth;
