import React, { useEffect } from "react";
import PropTypes from "prop-types";

import { ButtonsListCategory } from "feature/panel/MenusLabels/ButtonsListCategory/ButtonsListCategory";
import { AccessDenied } from "components/templates/_shared/AccessDenied/AccessDenied";
import { PanelTemplate } from "components/templates/Panel/PanelTemplate";
import { SharedNavigation } from "feature/panel/_shared/Navigations/SharedNavigation";
import { useDispatch } from "react-redux";
import { getCurrentOperatorStart } from "store/operator/actions";

const MenusLabels = ({ hasPermission }) => {
  const dispatch = useDispatch();

  const getOperatorData = () => {
    dispatch(getCurrentOperatorStart());
  };

  const contextBar = {
    left: () => null,
    middle: SharedNavigation,
    right: () => null,
  };

  useEffect(getOperatorData, []);

  return hasPermission ? (
    <PanelTemplate languageSelector hasPermission={hasPermission} contextBar={contextBar}>
      <ButtonsListCategory />
    </PanelTemplate>
  ) : (
    <AccessDenied />
  );
};

MenusLabels.propTypes = {
  hasPermission: PropTypes.bool.isRequired,
};

export { MenusLabels };
