import React, { useEffect, useState, useCallback } from "react";
import { useSelector } from "react-redux";
import Grid from "@material-ui/core/Grid";
import { useManageTripContext } from "feature/panel/Trips/_shared/ManageTripContext";
import { generateUniqueId } from "utils";
import { setLocationsFilter } from "utils/dataConverters";
import { usePermissionsService } from "hooks/usePermissionsService";
import { PERMISSIONS } from "constants/permissions";
import { useService } from "hooks/useService";
import { PoiService } from "services/PoiService";
import { Logger } from "services/application/Logger";
import { DEFAULT_PAGE_SIZE } from "constants/defaults";
import { EventBus } from "services/application/EventBus";
import { LocationCoordinatesHasBeenChanged } from "events/LocationCoordinatesHasBeenChanged";
import MapWidget from "feature/panel/_shared/MapWidget";
import { LocationsTabs } from "./LocationsTabs/LocationsTabs";

const Locations = () => {
  const [staticAllPois, setStaticAllPois] = useState([]);
  const [activePois, setActivePois] = useState([]);
  const { setValueFor, locations, editMode, pois, vamoos_id } = useManageTripContext();
  const permissionsService = usePermissionsService();
  const poiService = useService(PoiService);
  const nestedPublicStays = [...locations].filter(location => location.nested).map(location => location.nested.vamoos_id);
  const allPublicStays = useSelector(state => state.trips.publicStays.filter(stay => !nestedPublicStays.includes(stay.vamoos_id)));

  const [allPois, setAllPois] = useState([]);

  const noLocationsWithValidCoordinates = !setLocationsFilter(locations);

  const canEdit = permissionsService.can(PERMISSIONS.actions.update, PERMISSIONS.sections.vamoosList, vamoos_id);

  const activatedPois = useCallback(() => {
    if (noLocationsWithValidCoordinates) {
      return [];
    }
    if (editMode) {
      return allPois.filter(poi => (pois.some(({ id }) => id === poi.id) ? pois.find(({ id }) => id === poi.id).is_on : poi.isDefaultOn));
    }

    return allPois.filter(poi => (pois.some(({ id }) => id === poi.id) ? !poi.isDefaultOn : poi.isDefaultOn));
  }, [allPois, editMode, noLocationsWithValidCoordinates, pois]);

  const handleAddLocation = ({ file, ...rest }, closeInfoWindow) => {
    const id = generateUniqueId();
    setValueFor("locations", [...locations, { ...rest, internal_id: id, id, isNewLocation: true }]);
    if (closeInfoWindow) closeInfoWindow();
  };

  const getPoisList = async () => {
    try {
      const { items } = await poiService.getTablePois(1, DEFAULT_PAGE_SIZE, null, null, null, null, setLocationsFilter(locations), false);

      setAllPois(items);
    } catch (e) {
      Logger.debug(e);
    }
  };

  useEffect(() => {
    const poisToDisplay = activatedPois();
    setActivePois(poisToDisplay);
  }, [allPois]);

  useEffect(() => {
    if (!locations.length) setActivePois([]);
    if (locations.length && !locations?.find(item => item.latitude === null || item.longitude === null)) getPoisList();
  }, [locations.length]);

  useEffect(() => {
    EventBus.on(LocationCoordinatesHasBeenChanged, getPoisList);

    return () => {
      EventBus.remove(LocationCoordinatesHasBeenChanged, getPoisList);
    };
  }, []);

  const settings = locations.length > 0 ? { center: { lat: locations[0].latitude, lng: locations[0].longitude }, zoom: 4 } : {};
  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} md={10}>
        <MapWidget
          data={{
            stays: allPublicStays,
            pois: activePois,
          }}
          locations={locations}
          defaultSwitchesEnabled={["pois"]}
          settings={settings}
          switchControls={["pois", "stays"]}
          searchPlaceholder="Search location"
          infoWindowsProps={{
            stays: {
              isAdding: true,
              onAddMarker: handleAddLocation,
            },
            search: {
              addButtonText: "Add Location",
              onAddMarker: handleAddLocation,
              title: "Location",
            },
            rightClick: {
              addButtonText: "Add Location",
              onAddMarker: handleAddLocation,
            },
          }}
          searchByLocation
        />
      </Grid>
      <LocationsTabs />
    </Grid>
  );
};

export { Locations };
