import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ImageOutlined } from "@material-ui/icons";
import PropTypes, { string } from "prop-types";

import { CUSTOM_APPS, CUSTOM_APP_OPTIONS, GLOBAL_CONTENT } from "constants/content";
import { IMAGES_MIME_TYPES } from "constants/defaults";
import { VamoosImage } from "domain/VamoosImage";

import {
  setValueForLoginBorderColor,
  setValueForLoginBackgroundColor,
  setValueForLoginText1String,
  setValueForLoginText2String,
  setValueForLoginText3String,
  setValueForLoginText4String,
  setValueForLoginUserIdHintString,
  setValueForLoginNextButtonString,
  setValueForImageBackground,
  setValueForLoginText1Color,
  setValueForLoginText2Color,
  setValueForLoginText3Color,
  setValueForLoginText4Color,
  setValueForLoginText5Color,
  setValueForLoginText5String,
  setCustomAppCurrentLanguage,
  setValueForAppLoginText,
  setValueForAppLoginTextColorCommon,
} from "store/customAppForm/actions";

import { DEFAULT_LIBRARY_TABS } from "components/ui/LibraryPopup/LibraryPopup";
import { AttachFilesButton } from "components/ui/Buttons";
import { ChipWithRedirect } from "components/ui/Chips/ChipWithRedirect";
import { Input } from "components/ui/Forms";

import { usePermissionsService } from "hooks/usePermissionsService";
import { PERMISSIONS } from "constants/permissions";
import { LanguageSelector, LanguageSelectorPill } from "components/_shared/LanguageSelector/LanguageSelector";
import { useQuery } from "@tanstack/react-query";
import { HttpClient } from "services/application/httpClient/httpClient";

import {
  CardSection,
  CardSectionsContainer,
  ColorPickerWrapper,
  CustomCard,
  CustomInputContainer,
  PreviewCardSection,
  SectionTitle,
  SectionBorder,
  InputContentWrapper,
} from "../sharedComponents";

import { CustomColorPicker } from "../ColorPicker/CustomColorPicker";
import { LoginScreenPreview } from "../previews/LoginScreenPreview";
import { TextInputEditor } from "./TextInputEditor";
import { getLoginText, getLoginTextColor } from "./helpers";
import LanguageSelectorCutsomApps from "../components/LanguageSelector";
import { LanguageLock } from "../../../../Stays/_shared/LanguageLock";

const LoginScreenSection = ({ isHistoryVersion }) => {
  const { data: languages } = useQuery({
    queryKey: ["customAppLanguages"],
    queryFn: () => {
      return HttpClient.get("/app_languages");
    },
  });
  const permissionsService = usePermissionsService();
  const dispatch = useDispatch();

  const [currentLanguage, setCurrentLanguage] = useState(null);

  const errors = useSelector(state => state.customAppForm.errors);
  const type = useSelector(state => state.customAppForm.customAppForm.type);
  const bgColor = useSelector(state => state.customAppForm.customAppForm.colors.loginBackground);

  const loginBorderColor = useSelector(state => state.customAppForm.customAppForm.colors.loginBorderColor);
  const backgroundImage = useSelector(state => state.customAppForm.customAppForm.images.backgroundImage);

  const language = currentLanguage || "en";

  const strings = useSelector(state => state.customAppForm.customAppForm.strings);

  const loginUserIdHint = useSelector(state => state.customAppForm.customAppForm.strings.loginUserIdHint?.languages?.[language]);
  const loginNextButton = useSelector(state => state.customAppForm.customAppForm.strings.loginNextButton?.languages?.[language]);
  const defaultLanguage = useSelector(state => state.customAppForm.customAppForm.default_language);

  const isCurrentLanguageSameAsDefault = currentLanguage === defaultLanguage;

  const caStatus = useSelector(state => state.customAppForm?.customAppForm?.status);

  const canEdit = permissionsService.can(PERMISSIONS.actions.update, PERMISSIONS.sections.operator, PERMISSIONS.resources.operators);
  const canCreate = permissionsService.can(PERMISSIONS.actions.create, PERMISSIONS.sections.operator, PERMISSIONS.resources.operators);

  const editDisabled = !((caStatus === null && canCreate) || (caStatus === "draft" && canEdit));

  const onBgColorUpdate = value => {
    dispatch(setValueForLoginBackgroundColor(value));
  };

  const onBorderColorChange = value => {
    dispatch(setValueForLoginBorderColor(value));
  };

  const onTextUpdate = ({ value, fieldName }) => {
    dispatch(setValueForAppLoginText({ value, language, fieldName }));
  };

  // const onText1Update = ({ target }) => {
  //   dispatch(setValueForLoginText1String({ value: target.value, language }));
  // };

  const onColorUpdate = ({ value, fieldName }) => {
    dispatch(setValueForAppLoginTextColorCommon({ value, language, fieldName }));
  };

  const onText1ColorUpdate = value => {
    dispatch(setValueForLoginText1Color({ value, language }));
  };

  const onUserIdHintUpdate = ({ target }) => {
    dispatch(setValueForLoginUserIdHintString({ value: target.value, language }));
  };
  const onButtonNextChange = ({ target }) => {
    dispatch(setValueForLoginNextButtonString({ value: target.value, language }));
  };

  const handleChangeAttachment = event => {
    const image = VamoosImage(event[0]);

    dispatch(setValueForImageBackground(image));
  };
  const handleRemoveAttachment = () => {
    if (!(isHistoryVersion || editDisabled)) {
      dispatch(setValueForImageBackground(null));
    }
  };

  const renderAttachmentDeleter = () => (
    <ChipWithRedirect
      onDelete={handleRemoveAttachment}
      item={backgroundImage}
      maxChar={15}
      icon={<ImageOutlined />}
      disabled={isHistoryVersion || editDisabled || !isCurrentLanguageSameAsDefault}
    />
  );

  const renderAttachmentUploader = () => (
    <AttachFilesButton
      label={`${GLOBAL_CONTENT.attach}/${GLOBAL_CONTENT.link}`}
      size="small"
      onSelect={handleChangeAttachment}
      allowedFileTypes={IMAGES_MIME_TYPES}
      disabled={isHistoryVersion || editDisabled || !isCurrentLanguageSameAsDefault}
      tabs={[DEFAULT_LIBRARY_TABS.library, DEFAULT_LIBRARY_TABS.files]}
      error={errors?.images?.logo || null}
      returnEncoded={false}
    />
  );

  const typeHotelForm = (
    <>
      <ColorPickerWrapper>{backgroundImage ? renderAttachmentDeleter() : renderAttachmentUploader()}</ColorPickerWrapper>
      {!isCurrentLanguageSameAsDefault && <LanguageLock message="Color fields can be set only in default language" />}
    </>
  );

  const onLanguageChange = language => {
    setCurrentLanguage(language.code);
    dispatch(setCustomAppCurrentLanguage(language.code));
  };

  useEffect(() => {
    setCurrentLanguage(defaultLanguage);
    dispatch(setCustomAppCurrentLanguage(defaultLanguage));
  }, [defaultLanguage]);

  const typeSinglePropertyForm = (
    <>
      {!isCurrentLanguageSameAsDefault && (
        <LanguageLock message="Color fields can be set only in default language" style={{ marginTop: 15 }} />
      )}
      <ColorPickerWrapper>
        <CustomColorPicker
          color={bgColor}
          label={CUSTOM_APPS.loginPage.backgroundColor}
          onColorChange={onBgColorUpdate}
          disabled={isHistoryVersion || editDisabled || !isCurrentLanguageSameAsDefault}
          fullWidth
        />
      </ColorPickerWrapper>
      <ColorPickerWrapper>
        <CustomColorPicker
          color={loginBorderColor}
          label={CUSTOM_APPS.loginPage.borderColor}
          onColorChange={onBorderColorChange}
          disabled={isHistoryVersion || editDisabled || !isCurrentLanguageSameAsDefault}
          fullWidth
        />
      </ColorPickerWrapper>
      <CustomInputContainer>
        <TextInputEditor
          value={getLoginText(strings, { varName: "loginText1", language }) || ""}
          onChange={value => onTextUpdate({ value, fieldName: "loginText1" })}
          label={CUSTOM_APPS.loginPage.text1}
          error={errors?.strings?.loginText1?.text}
          helperText={errors?.strings?.loginText1?.text || ""}
          disabled={isHistoryVersion || editDisabled}
        />
      </CustomInputContainer>
      <ColorPickerWrapper>
        <CustomColorPicker
          color={getLoginTextColor("loginText1", strings) || ""}
          label={CUSTOM_APPS.loginPage.text1Color}
          onColorChange={value => onColorUpdate({ value, fieldName: "loginText1" })}
          disabled={isHistoryVersion || editDisabled || !isCurrentLanguageSameAsDefault}
          fullWidth
        />
      </ColorPickerWrapper>
      <CustomInputContainer>
        <TextInputEditor
          value={getLoginText(strings, { varName: "loginText2", language }) || ""}
          onChange={value => onTextUpdate({ value, fieldName: "loginText2" })}
          label={CUSTOM_APPS.loginPage.text2}
          error={errors?.strings?.loginText2?.text}
          helperText={errors?.strings?.loginText2?.text || ""}
          disabled={isHistoryVersion || editDisabled}
        />
      </CustomInputContainer>
      <ColorPickerWrapper>
        <CustomColorPicker
          color={getLoginTextColor("loginText2", strings) || ""}
          label={CUSTOM_APPS.loginPage.text2Color}
          onColorChange={value => onColorUpdate({ value, fieldName: "loginText2" })}
          disabled={isHistoryVersion || editDisabled || !isCurrentLanguageSameAsDefault}
          fullWidth
        />
      </ColorPickerWrapper>
      <CustomInputContainer>
        <TextInputEditor
          label={CUSTOM_APPS.loginPage.text3}
          value={getLoginText(strings, { varName: "loginText3", language }) || ""}
          onChange={value => onTextUpdate({ value, fieldName: "loginText3" })}
          error={errors?.strings?.loginText3?.text}
          helperText={errors?.strings?.loginText3?.text || ""}
          disabled={isHistoryVersion || editDisabled}
        />
      </CustomInputContainer>
      <ColorPickerWrapper>
        <CustomColorPicker
          color={getLoginTextColor("loginText3", strings) || ""}
          label={CUSTOM_APPS.loginPage.text3Color}
          onColorChange={value => onColorUpdate({ value, fieldName: "loginText3" })}
          disabled={isHistoryVersion || editDisabled || !isCurrentLanguageSameAsDefault}
          fullWidth
        />
      </ColorPickerWrapper>
      <CustomInputContainer>
        <TextInputEditor
          label={CUSTOM_APPS.loginPage.text4}
          value={getLoginText(strings, { varName: "loginText4", language }) || ""}
          onChange={value => onTextUpdate({ value, fieldName: "loginText4" })}
          error={errors?.strings?.loginText4?.text}
          helperText={errors?.strings?.loginText4?.text || ""}
          disabled={isHistoryVersion || editDisabled}
        />
      </CustomInputContainer>
      <ColorPickerWrapper>
        <CustomColorPicker
          color={getLoginTextColor("loginText4", strings) || ""}
          label={CUSTOM_APPS.loginPage.text4Color}
          onColorChange={value => onColorUpdate({ value, fieldName: "loginText4" })}
          disabled={isHistoryVersion || editDisabled || !isCurrentLanguageSameAsDefault}
          fullWidth
        />
      </ColorPickerWrapper>
      <CustomInputContainer>
        <TextInputEditor
          label={CUSTOM_APPS.loginPage.text5}
          value={getLoginText(strings, { varName: "loginText5", language }) || ""}
          onChange={value => onTextUpdate({ value, fieldName: "loginText5" })}
          error={errors?.strings?.loginText5?.text}
          helperText={errors?.strings?.loginText5?.text || ""}
          disabled={isHistoryVersion || editDisabled}
        />
      </CustomInputContainer>
      <ColorPickerWrapper>
        <CustomColorPicker
          color={getLoginTextColor("loginText5", strings) || ""}
          label={CUSTOM_APPS.loginPage.text5Color}
          onColorChange={value => onColorUpdate({ value, fieldName: "loginText5" })}
          disabled={isHistoryVersion || editDisabled || !isCurrentLanguageSameAsDefault}
          fullWidth
        />
      </ColorPickerWrapper>
    </>
  );

  const singlePropertyFormPlaceholderTexts =
    type !== CUSTOM_APP_OPTIONS[0].value ? (
      <>
        <SectionBorder />
        <CardSectionsContainer>
          <CardSection>
            <InputContentWrapper>
              <ColorPickerWrapper>
                <CustomInputContainer>
                  <Input
                    label="User ID placeholder text"
                    value={loginUserIdHint?.text || ""}
                    onChange={e => onTextUpdate({ value: e.target.value, fieldName: "loginUserIdHint" })}
                    error={errors?.strings?.loginUserIdHint}
                    helperText={errors?.strings?.loginUserIdHint || ""}
                    disabled={isHistoryVersion || editDisabled}
                  />
                </CustomInputContainer>
                <CustomInputContainer>
                  <Input
                    label="Next button text"
                    value={loginNextButton?.text || ""}
                    onChange={e => onTextUpdate({ value: e.target.value, fieldName: "loginNextButton" })}
                    error={errors?.strings?.loginNextButton}
                    helperText={errors?.strings?.loginNextButton || ""}
                    disabled={isHistoryVersion || editDisabled}
                  />
                </CustomInputContainer>
              </ColorPickerWrapper>
            </InputContentWrapper>
          </CardSection>
          <CardSection />
        </CardSectionsContainer>
      </>
    ) : null;

  return (
    <CustomCard>
      <CardSectionsContainer>
        <CardSection>
          <InputContentWrapper>
            <SectionTitle>
              {type === CUSTOM_APP_OPTIONS[0].value ? CUSTOM_APPS.loginPage.labelBg : CUSTOM_APPS.loginPage.label}
            </SectionTitle>

            <LanguageSelectorCutsomApps currentLanguage={currentLanguage} onChange={lang => setCurrentLanguage(lang)} />

            {type === CUSTOM_APP_OPTIONS[0].value && typeHotelForm}
            {type === CUSTOM_APP_OPTIONS[1].value && typeSinglePropertyForm}
          </InputContentWrapper>
        </CardSection>
        <PreviewCardSection>
          <LoginScreenPreview currentLanguage={currentLanguage} />
        </PreviewCardSection>
      </CardSectionsContainer>
      {singlePropertyFormPlaceholderTexts}
    </CustomCard>
  );
};

LoginScreenSection.defaultProps = {
  isHistoryVersion: false,
};

LoginScreenSection.propTypes = {
  isHistoryVersion: PropTypes.bool,
};

export { LoginScreenSection };
