import React from "react";
import { useLocation } from "react-router-dom";
import { ListAltOutlined } from "@material-ui/icons";
import { useSelector } from "react-redux";

import { isObjectEmpty } from "utils/object";
import { checkSectionHasError } from "feature/panel/_shared/helpers";

import { ContextNavigationList } from "components/ui/Lists/ContextNavigationList";

import { CUSTOM_APP_NAVIGATION } from "constants/content";
import {
  PANEL_SETTINGS_CUSTOM_APPS_CREATE_ELEMENTS,
  PANEL_SETTINGS_CUSTOM_APPS_CREATE_LOGIN,
  PANEL_SETTINGS_CUSTOM_APPS_CREATE_MENU,
  PANEL_SETTINGS_CUSTOM_APPS_CREATE_TEXTS,
  PANEL_SETTINGS_CUSTOM_APPS_CREATE_LOGO,
  PANEL_SETTINGS_CUSTOM_APPS_CREATE_PREVIEW,
  PANEL_SETTINGS_CUSTOM_APPS_CREATE_GENERAL,
  PANEL_SETTINGS_CUSTOM_APPS_CREATE_INFORMATION,
} from "constants/routes";

import { GENERAL_CA_SECTION_ERROR_NAMES } from "../_shared/constants/customAppStatus";
import { GeneralIcon, BackgroundIcon, ElementIcon, MenuIcon, TextsIcon, AllScreenIcon, InfoIcon } from "../_shared/navigationIcons";

const Navigation = () => {
  const location = useLocation();
  const errors = useSelector(({ customAppForm }) => customAppForm.errors);
  const textsErrors = useSelector(
    ({ customAppForm }) => customAppForm.errors?.menuDocuments?.filter(doc => doc && !isObjectEmpty(doc)).length,
  );

  const isActive = url => location?.pathname === url;
  const loginScreenError =
    errors?.images?.backgroundImage ||
    errors?.strings?.loginText1 ||
    errors?.strings?.loginText2 ||
    errors?.strings?.loginText3 ||
    errors?.strings?.loginText4 ||
    errors?.strings?.loginUserIdHint ||
    errors?.strings?.loginNextButton;

  const list = [
    {
      url: PANEL_SETTINGS_CUSTOM_APPS_CREATE_GENERAL,
      label: CUSTOM_APP_NAVIGATION.general,
      icon: <GeneralIcon isActive={isActive(PANEL_SETTINGS_CUSTOM_APPS_CREATE_GENERAL)} />,
      isActive: isActive(PANEL_SETTINGS_CUSTOM_APPS_CREATE_GENERAL),
      hasError: checkSectionHasError(GENERAL_CA_SECTION_ERROR_NAMES, errors),
    },
    {
      url: PANEL_SETTINGS_CUSTOM_APPS_CREATE_LOGO,
      label: CUSTOM_APP_NAVIGATION.logoAndIcons,
      icon: <ListAltOutlined />,
      isActive: isActive(PANEL_SETTINGS_CUSTOM_APPS_CREATE_LOGO),
      hasError: errors?.images?.logo || errors?.images?.icon,
    },
    {
      url: PANEL_SETTINGS_CUSTOM_APPS_CREATE_ELEMENTS,
      label: CUSTOM_APP_NAVIGATION.elements,
      icon: <ElementIcon isActive={isActive(PANEL_SETTINGS_CUSTOM_APPS_CREATE_ELEMENTS)} />,
      isActive: isActive(PANEL_SETTINGS_CUSTOM_APPS_CREATE_ELEMENTS),
    },
    {
      url: PANEL_SETTINGS_CUSTOM_APPS_CREATE_LOGIN,
      label: CUSTOM_APP_NAVIGATION.loginScreen,
      icon: <BackgroundIcon isActive={isActive(PANEL_SETTINGS_CUSTOM_APPS_CREATE_LOGIN)} />,
      isActive: isActive(PANEL_SETTINGS_CUSTOM_APPS_CREATE_LOGIN),
      hasError: loginScreenError,
    },
    {
      url: PANEL_SETTINGS_CUSTOM_APPS_CREATE_MENU,
      label: CUSTOM_APP_NAVIGATION.menu,
      icon: <MenuIcon isActive={isActive(PANEL_SETTINGS_CUSTOM_APPS_CREATE_MENU)} />,
      isActive: isActive(PANEL_SETTINGS_CUSTOM_APPS_CREATE_MENU),
    },
    {
      url: PANEL_SETTINGS_CUSTOM_APPS_CREATE_TEXTS,
      label: CUSTOM_APP_NAVIGATION.texts,
      icon: <TextsIcon isActive={isActive(PANEL_SETTINGS_CUSTOM_APPS_CREATE_TEXTS)} />,
      isActive: isActive(PANEL_SETTINGS_CUSTOM_APPS_CREATE_TEXTS),
      hasError: !!textsErrors,
    },
    {
      url: PANEL_SETTINGS_CUSTOM_APPS_CREATE_PREVIEW,
      label: CUSTOM_APP_NAVIGATION.preview,
      icon: <AllScreenIcon isActive={isActive(PANEL_SETTINGS_CUSTOM_APPS_CREATE_PREVIEW)} />,
      isActive: isActive(PANEL_SETTINGS_CUSTOM_APPS_CREATE_PREVIEW),
    },
    {
      url: PANEL_SETTINGS_CUSTOM_APPS_CREATE_INFORMATION,
      label: CUSTOM_APP_NAVIGATION.information,
      icon: <InfoIcon isActive={isActive(PANEL_SETTINGS_CUSTOM_APPS_CREATE_INFORMATION)} />,
      isActive: isActive(PANEL_SETTINGS_CUSTOM_APPS_CREATE_INFORMATION),
      hasError: errors?.information,
    },
  ];

  return <ContextNavigationList list={list} />;
};

export { Navigation };
