// @ts-check

import { useMemo } from "react"
import { Logger } from "services/application/Logger";

/**
 * @param {function(new:T)} Service
 * @template T 
 * @returns {T}
 */
export const useService = (Service) => {
  const serviceInstance = useMemo(() => {
    // Logger.debug("SERVICE INITIALIZATION ->", Service.name);
    return new Service();
  }, []);

  return serviceInstance;
}