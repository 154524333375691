import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import { GLOBAL_CONTENT, PUBLISHER_CONTENT } from "constants/content";
import { closePublisherModal, createOrUpdateStayValidationFail, setPublisherModalError } from "store/stays/actions";

import { PublisherCreatePageOne } from "feature/panel/Stays/_shared/Publisher/PublisherCreatePageOne";
import { PublisherCreatePageTwo } from "feature/panel/Stays/_shared/Publisher/PublisherCreatePageTwo";

import { ConfirmationModal } from "components/ui/Modals/ConfirmationModal";
import { BodyText2 } from "components/ui/Typography/Typography";
import { GhostButton } from "components/ui/Buttons";
import { StayValidator } from "services/domain/StayValidator";
import { isObjectEmpty } from "utils/object";
import { setStayWizardTouched, stayWizardSetErrors } from "store/stayWizard/actions";
import { LanguageContext } from "components/_shared/LanguageSelector/LanguageContext";
import { useService } from "hooks/useService";
import { StayService } from "services/domain/StayService";

import { updateStayOriginalIndexes } from "../helpers";

const StepCounter = styled(BodyText2)`
  && {
    position: absolute;
    top: ${({ theme }) => theme.setSpacing(4)}px;
    right: ${({ theme }) => theme.setSpacing(7)}px;
  }
`;

const ExitButton = styled(GhostButton)`
  && {
    position: absolute;
    bottom: ${({ theme }) => theme.setSpacing(2)}px;
    left: ${({ theme }) => theme.setSpacing(4)}px;
  }
`;

const PublisherCreateModal = ({ onConfirm }) => {
  const dispatch = useDispatch();
  const [step, setStep] = useState(1);

  const modalOpened = useSelector(({ stays }) => stays.publisherModal.opened);

  const stayService = useService(StayService);

  const { currentLanguage } = useContext(LanguageContext);

  const isStayDefaultLanguage = useSelector(({ stayWizard }) =>
    stayWizard.defaultVariant.language ? stayWizard.defaultVariant.language === currentLanguage.code : true,
  );

  const areChoosenAtLeastOneSection = useSelector(({ stays }) => {
    const {
      areDirectoriesEnabled,
      contactDetailsFrontPage,
      contactDetailsLastPage,
      frontPage,
      lastPage,
      map1,
      map2,
      map3,
      poiList,
      cover,
      tableOfContents,
    } = stays.publisherModal.form;

    return (
      cover.show ||
      areDirectoriesEnabled ||
      contactDetailsFrontPage ||
      contactDetailsLastPage ||
      frontPage.show ||
      lastPage.show ||
      map1.show ||
      map2.show ||
      map3.show ||
      poiList.show ||
      tableOfContents
    );
  });

  const id = useSelector(({ stays }) => stays.publisherModal.form.id);
  const fileName = useSelector(({ stays }) => stays.publisherModal.form.fileName);
  const areDirectoriesEnabled = useSelector(({ stays }) => stays.publisherModal.form.areDirectoriesEnabled);
  const cover = useSelector(({ stays }) => stays.publisherModal.form.cover);
  const wizardForm = useSelector(({ stayWizard }) => stayWizard.form);
  const isWizardTouched = useSelector(({ stayWizard }) => stayWizard.touched);

  const isValid = (cover.body ? cover.body.replace(/<.+?>/gm, "").replace(/&[a-z]+?;/, " ").length <= 200 : true) && fileName.length > 0;

  const resetSteps = () => {
    setStep(1);
  };

  const handleSaveStay = async () => {
    const wizardErrors = StayValidator(wizardForm, isStayDefaultLanguage);

    if (isObjectEmpty(wizardErrors)) {
      updateStayOriginalIndexes(wizardForm);

      wizardForm.newOnly = false;

      await stayService.saveStayVariant(wizardForm.userId, wizardForm, null, currentLanguage?.code);

      dispatch(setStayWizardTouched(false));
    } else {
      dispatch(stayWizardSetErrors(wizardErrors));
      dispatch(createOrUpdateStayValidationFail());
    }
  };

  const handleConfirm = () => {
    if (isWizardTouched) {
      handleSaveStay();
    } else if (!isWizardTouched && step === 1 && !areChoosenAtLeastOneSection) {
      dispatch(
        setPublisherModalError({
          error: "emptySectionList",
          value: true,
        }),
      );
    } else {
      dispatch(
        setPublisherModalError({
          error: "emptySectionList",
          value: false,
        }),
      );

      if ((areDirectoriesEnabled && step === 2) || !areDirectoriesEnabled) {
        onConfirm();
      } else {
        setStep(step + 1);
      }
    }
  };

  const closeModal = () => dispatch(closePublisherModal());

  const handleCancel = () => {
    if (step === 1) {
      closeModal();
    } else {
      setStep(step - 1);
    }
  };

  const title = PUBLISHER_CONTENT.modalTitle(!!id);
  const confirmButtonText = (areDirectoriesEnabled && step === 2) || !areDirectoriesEnabled ? GLOBAL_CONTENT.save : GLOBAL_CONTENT.proceed;
  const cancelButtonText = step === 1 ? GLOBAL_CONTENT.cancel : GLOBAL_CONTENT.back;
  const stepsCounter = `Step ${step} of 2`;
  const ActivePage = (() => {
    switch (step) {
      case 1:
        return PublisherCreatePageOne;
      default:
        return PublisherCreatePageTwo;
    }
  })();
  const instantExitButton = step !== 1 && (
    <ExitButton onClick={closeModal} cv="grey50">
      {GLOBAL_CONTENT.exit}
    </ExitButton>
  );

  useEffect(resetSteps, [modalOpened]);

  return modalOpened ? (
    <ConfirmationModal
      open={modalOpened}
      title={title}
      confirmLabel={confirmButtonText}
      cancelLabel={cancelButtonText}
      confirmDisabled={!isWizardTouched && !isValid}
      onCancel={handleCancel}
      onConfirm={handleConfirm}
      maxWidth="md"
      fullWidth
      disableEnforceFocus
    >
      {areDirectoriesEnabled && <StepCounter>{stepsCounter}</StepCounter>}
      <ActivePage />
      {instantExitButton}
    </ConfirmationModal>
  ) : null;
};

PublisherCreateModal.defaultProps = {
  publisher: null,
};

PublisherCreateModal.propTypes = {
  publisher: PropTypes.shape({
    fileName: PropTypes.string.isRequired,
    id: PropTypes.number,
  }),
  onConfirm: PropTypes.func.isRequired,
};

export { PublisherCreateModal, StepCounter };
