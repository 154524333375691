import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Editor } from "@tinymce/tinymce-react";
import config from "config/app";

const EditorWrapper = styled.div`
  & > .tox-tinymce {
    border-radius: ${({ theme }) => `${theme.setSpacing(2)}px`} !important;
    border-color: ${({ theme, hasError }) => (hasError ? theme.colors.error : theme.colors.grey40)} !important;
  }
  & .tox-tbtn + .tox-tbtn {
    margin-left: 3px !important;
  }
`;

const TextEditor = ({
  value,
  onChange,
  plugins,
  toolbar,
  menubar,
  configuration,
  maxLength,
  hasError,
  height,
  fontFormats,
  contentStyle,
}) => (
  <EditorWrapper hasError={hasError}>
    <Editor
      apiKey={config.vendors.TinyMCE.API_KEY}
      value={value}
      init={{
        height,
        menubar,
        font_formats: fontFormats,
        content_style: contentStyle,
        force_br_newlines: true,
        force_p_newlines: false,
        forced_root_block: "",
        plugins,
        toolbar,
        setup: editor => {
          const allowedKeys = [8, 37, 38, 39, 40, 46];

          if (maxLength) {
            editor.on("keydown", e => {
              if (editor.getContent({ format: "text" }).length >= maxLength && editor.selection.isCollapsed() && !e.ctrlKey) {
                if (allowedKeys.indexOf(e.keyCode) !== -1) {
                  return true;
                }
                e.preventDefault();
                e.stopPropagation();
                return false;
              }
              return true;
            });
          }
        },
        ...configuration,
      }}
      onEditorChange={val => onChange(val)}
    />
  </EditorWrapper>
);

TextEditor.defaultProps = {
  plugins: [
    "advlist autolink lists link image charmap print preview anchor nonbreaking",
    "searchreplace visualblocks",
    "insertdatetime media table paste code help wordcount",
  ],
  toolbar: `formatselect | fontsizeselect | bold italic underline strikethrough superscript subscript forecolor backcolor |
    link | alignleft aligncenter alignright alignjustify |
    numlist bullist outdent indent | removeformat`,
  menubar: "file edit view insert format table help",
  configuration: {},
  height: 230,
  hasError: false,
  maxLength: null,
};

TextEditor.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  plugins: PropTypes.arrayOf(PropTypes.string),
  toolbar: PropTypes.string,
  menubar: PropTypes.string,
  configuration: PropTypes.shape({}),
  height: PropTypes.number,
  hasError: PropTypes.bool,
  maxLength: PropTypes.number,
};

export { TextEditor };
