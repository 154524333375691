import React from "react";
import PropTypes from "prop-types";
import { Provider } from "react-redux";
import store from "store/store";

const WithRedux = ({ children }) => {
  return <Provider store={store}>{children}</Provider>;
};

WithRedux.propTypes = {
  children: PropTypes.node.isRequired,
};

export { WithRedux };
