export const GET_BRANDING_LIST_START = "GET_BRANDING_LIST_START";
export const GET_BRANDING_LIST_SUCCESS = "GET_BRANDING_LIST_SUCCESS";
export const GET_BRANDING_LIST_FAIL = "GET_BRANDING_LIST_FAIL";

export const GET_DEFAULT_BRANDING_DATA_START = "GET_DEFAULT_BRANDING_DATA_START";
export const GET_SELECTED_BRANDING_DATA_START = "GET_SELECTED_BRANDING_DATA_START";
export const GET_SELECTED_BRANDING_DATA_SUCCESS = "GET_SELECTED_BRANDING_DATA_SUCCESS";
export const GET_SELECTED_BRANDING_DATA_FAIL = "GET_SELECTED_BRANDING_DATA_FAIL";

export const CREATE_NEW_BRAND_START = "CREATE_NEW_BRAND_START";
export const CREATE_NEW_BRAND_SUCCESS = "CREATE_NEW_BRAND_SUCCESS";
export const CREATE_NEW_BRAND_FAIL = "CREATE_NEW_BRAND_FAIL";

export const SET_VALUE_FOR_CREATION_HEADER_START = "SET_VALUE_FOR_CREATION_HEADER_START";
export const SET_VALUE_FOR_CREATION_FOOTER_START = "SET_VALUE_FOR_CREATION_FOOTER_START";
export const SET_VALUE_FOR_LOGO_START = "SET_VALUE_FOR_LOGO";

export const SET_VALUE_FOR_DEFAULT_CREATION_HEADER_START = "SET_VALUE_FOR_DEFAULT_CREATION_HEADER_START";
export const SET_VALUE_FOR_DEFAULT_CREATION_FOOTER_START = "SET_VALUE_FOR_DEFAULT_CREATION_FOOTER_START";
export const SET_VALUE_FOR_DEFAULT_LOGO_START = "SET_VALUE_FOR_DEFAULT_LOGO_START";

export const SET_VALUE_FOR_CREATION_LINK_START = "SET_VALUE_FOR_CREATION_LINK_START";
export const SET_VALUE_FOR_DEFAULT_CREATION_LINK_START = "SET_VALUE_FOR_DEFAULT_CREATION_LINK_START";

export const UPDATE_BRAND_ITEM_SUCCESS = "UPDATE_BRAND_ITEM_SUCCESS";
export const UPDATE_BRAND_ITEM_FAIL = "UPDATE_BRAND_ITEM_FAIL";
export const CLEAR_BRANDING_FORM = "CLEAR_BRANDING_FORM";

export const SET_VALUE_FOR_IS_ACTIVE = "SET_VALUE_FOR_IS_ACTIVE";
