import uuidv4 from "uuid";

import * as actionTypes from "store/adminFeatures/actionTypes";

import { reducer, setState, globalReducerActions } from "utils/store";
import { arrayMoveToPosition } from "utils/url";

const adminFeatureStaysInitialState = {
  errors: {},
  actionType: null,
  inProgress: false,
  finished: false,
  isFormTouched: false,
  features: [],
};

const ReducerActions = globalReducerActions("adminFeatureStays", adminFeatureStaysInitialState, {
  [actionTypes.GET_FEATURES_STAYS_START](state) {
    return setState(state, {
      actionType: actionTypes.GET_FEATURES_STAYS_START,
      inProgress: true,
      finished: false,
      isFormTouched: false,
      features: [],
    });
  },

  [actionTypes.GET_FEATURES_STAYS_SUCCESS](state, payload) {
    return setState(state, {
      inProgress: false,
      finished: true,
      isFormTouched: false,
      features: payload,
    });
  },

  [actionTypes.GET_FEATURES_STAYS_FAIL](state, payload) {
    return setState(state, {
      inProgress: false,
      finished: false,
      isFormTouched: false,
      errors: payload,
    });
  },

  [actionTypes.TOGGLE_FEATURE_ACTIVE_STATUS_START](state) {
    return setState(state, {
      inProgress: true,
      actionType: actionTypes.TOGGLE_FEATURE_ACTIVE_STATUS_START,
      finished: false,
    });
  },

  [actionTypes.TOGGLE_FEATURE_ACTIVE_STATUS_SUCCESS](state, payload) {
    const updatedFeatures = state.features;
    updatedFeatures[payload.id] = { ...payload, features: Object.values(payload.features) };

    return setState(state, {
      actionType: actionTypes.TOGGLE_FEATURE_ACTIVE_STATUS_SUCCESS,
      finished: true,
      inProgress: false,
      features: {
        ...updatedFeatures,
      },
    });
  },

  [actionTypes.TOGGLE_FEATURE_ACTIVE_STATUS_FAIL](state, payload) {
    return setState(state, {
      actionType: actionTypes.TOGGLE_FEATURE_ACTIVE_STATUS_FAIL,
      finished: true,
      inProgress: false,
      errors: { ...payload },
    });
  },

  [actionTypes.SET_VALUE_FOR_STAY](state, payload) {
    const { id, value, fieldName } = payload;
    const newFeatures = { ...state.features };
    newFeatures[id][fieldName] = value;
    newFeatures[id].isTouched = true;

    return setState(state, {
      features: newFeatures,
      isFormTouched: true,
    });
  },

  [actionTypes.SET_VALUE_FOR_STAY_FEATURE](state, payload) {
    const { featureGroupId, id } = payload;
    const newFeatures = {
      ...state.features,
    };

    newFeatures[featureGroupId].features = newFeatures[featureGroupId].features.map(feature => {
      if (feature.id === id) {
        return payload;
      }
      return feature;
    });

    newFeatures[featureGroupId].isTouched = true;

    return setState(state, {
      features: newFeatures,
      isFormTouched: true,
    });
  },
  [actionTypes.ADD_NEW_STAY_FEATURE_START](state) {
    const id = uuidv4();

    const features = {
      ...state.features,
      [id]: {
        id,
        isActive: true,
        name: "",
        position: Object.keys(state.features).length + 1,
        options: [],
        features: [],
        isTouched: true,
        isNewItem: true,
      },
    };
    return setState(state, {
      features,
    });
  },
  [actionTypes.ADD_NEW_FEATURE_ITEM_START](state, payload) {
    const id = uuidv4();
    const features = { ...state.features };

    features[payload].features.push({
      id,
      featureGroupId: payload,
      isActive: true,
      name: "",
    });
    features[payload].isTouched = true;
    return setState(state, {
      features,
      isFormTouched: true,
    });
  },
  [actionTypes.DELETE_STAY_FEATURE_START](state, payload) {
    const { id } = payload;
    const features = { ...state.features };

    features[id].isActive = false;
    features[id].isTouched = true;

    return setState(state, {
      features,
      isFormTouched: true,
    });
  },
  [actionTypes.DELETE_FEATURE_ITEM_START](state, payload) {
    const { featureGroupId, id } = payload;
    const newFeatures = { ...state.features };

    newFeatures[featureGroupId].features = newFeatures[featureGroupId].features.filter(feature => feature.id !== id);

    newFeatures[featureGroupId].isTouched = true;
    return setState(state, {
      features: newFeatures,
      isFormTouched: true,
    });
  },
  [actionTypes.SAVE_FEATURE_STAYS_START](state) {
    return setState(state, {
      inProgress: true,
      actionType: actionTypes.SAVE_FEATURE_STAYS_START,
      finished: false,
    });
  },
  [actionTypes.SAVE_FEATURE_STAYS_SUCCESS](state) {
    const features = {};
    Object.keys(state.features).forEach(key => {
      features[key] = {
        ...state.features[key],
        isTouched: false,
      };
    });
    return setState(state, {
      inProgress: false,
      finished: true,
      features,
      isFormTouched: false,
      errors: {},
    });
  },
  [actionTypes.SAVE_FEATURE_STAYS_FAIL](state, payload) {
    return setState(state, {
      inProgress: false,
      finished: false,
      errors: { ...payload },
    });
  },
  [actionTypes.SET_ORDER_FOR_STAY_FEATURES](state, payload) {
    const { oldIndex, newIndex } = payload;
    const oldFeatures = state.features;
    const features = {};
    const featuresArray = Object.values(oldFeatures).sort((a, b) => a.position - b.position);

    arrayMoveToPosition(featuresArray, oldIndex, newIndex)
      .map((feature, index) => ({
        ...feature,
        position: index + 1,
        isTouched: true, // At this moment all features are touched even if index hasn't change
      }))
      .forEach(feature => (features[feature.id] = feature));

    return setState(state, {
      features,
      isFormTouched: true,
    });
  },
  [actionTypes.SET_ORDER_FOR_FEATURES_ITEM](state, payload) {
    const { newIndex, oldIndex, featureGroupId } = payload;
    const features = { ...state.features };
    const featuresList = Object.values(state.features[featureGroupId].features).sort((a, b) => a.position - b.position);

    const categoryFeatures = arrayMoveToPosition(featuresList, oldIndex, newIndex).map((feature, index) => ({
      ...feature,
      position: index + 1,
    }));

    features[featureGroupId].isTouched = true;
    features[featureGroupId].features = categoryFeatures;

    return setState(state, { features, isFormTouched: true });
  },
  [actionTypes.SET_STAYS_FEATURES_FORM_TOUCHED](state, payload) {
    return setState(state, {
      isFormTouched: payload,
    });
  },
});

const adminFeatureStaysReducer = (state = adminFeatureStaysInitialState, action) => reducer(state, action, ReducerActions);

export { adminFeatureStaysReducer };
