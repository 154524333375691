import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import SaveOutlinedIcon from "@material-ui/icons/SaveOutlined";

import { PanelTemplate } from "components/templates/Panel/PanelTemplate";
import { ContextBarTitle } from "components/templates/Panel/ContextBarTitle";
import { BackHistoryButton, CancelHistoryButton, PrimaryButton } from "components/ui/Buttons";

import { PANEL_INSPIRATIONS_EDIT_PATH, PANEL_INSPIRATIONS_PATH } from "constants/routes";

import initialInspiration from "feature/panel/Inspirations/_shared/InspirationInitialState";
import { validateInspirationForm } from "feature/panel/Inspirations/_shared/validateInspirationForm";
import { ManageInspiration } from "feature/panel/Inspirations/_shared/ManageInspiration";
import { ManageInspirationContext } from "feature/panel/Inspirations/_shared/ManageInspirationContext";
import { isInspirationSaveActive } from "feature/panel/Inspirations/_shared/inspirationTransformations";

import { useDefaultReducer } from "hooks/useDefaultReducer";

import { setUrl } from "utils/url";
import { isObjectEmpty } from "utils/object";
import { GLOBAL_CONTENT, INSPIRATIONS, SITE_LEAVE_WARNING_MESSAGES } from "constants/content";
import { hideSavingCover, pushErrorNotification, pushSuccessNotification, showSavingCover, tryRouteChangeStart } from "store/app/actions";
import { useExtendedNavigate } from "hooks/useExtendedNavigate";
import { usePrompt } from "components/ui/CustomPrompt/CustomPrompt";
import { useNavigate } from "react-router-dom";
import { useService } from "hooks/useService";
import { InspirationService } from "services/InspirationService";
import { Logger } from "services/application/Logger";
import notifications from "constants/notifications";
import { InspirationPasscodeAlreadyUsedException } from "exceptions/InspirationPasscodeAlreadyUsedException";

const CreateInspiration = ({ hasPermission }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const navigateWithPrompt = useExtendedNavigate();

  const inspirationService = useService(InspirationService);

  const { state: inspiration, dispatch: dispatchInspiration } = useDefaultReducer(initialInspiration);
  const [formErrors, setFormErrors] = useState({});
  const errors = useSelector(state => state.inspirations.errors);
  const [isTouched, setIsTouched] = useState(false);

  usePrompt(SITE_LEAVE_WARNING_MESSAGES.createTitle(SITE_LEAVE_WARNING_MESSAGES.contexts.inspirationEditor), isTouched);

  const redirectToEditMode = (passcode, userId) => {
    const url = setUrl(PANEL_INSPIRATIONS_EDIT_PATH, { passcode, userId }, true);
    navigate(url);
  };

  const handleInspirationSave = async () => {
    const errorsFromValidation = validateInspirationForm(inspiration);
    const shouldTriggerAction = isObjectEmpty(errorsFromValidation);

    setFormErrors(errorsFromValidation);

    if (shouldTriggerAction) {
      try {
        dispatch(showSavingCover())
        await inspirationService.saveInspiration(inspiration);
        dispatch(pushSuccessNotification(notifications.resource("inspiration").create.success));
        redirectToEditMode(inspiration.reference_code, inspiration.operator_code);
        setFormErrors({});
      } catch(e) {
        if(e instanceof InspirationPasscodeAlreadyUsedException) {
          setFormErrors({
            reference_code: e.message
          })
        } else {
          dispatch(pushErrorNotification(notifications.resource("inspiration").create.fail))
        }
        Logger.debug(e);
      } finally {
        dispatch(hideSavingCover())
      }
    }
  };

  const saveDisabled = !isInspirationSaveActive(inspiration);
  const handleCancelButton = () => {
    dispatch(tryRouteChangeStart(PANEL_INSPIRATIONS_PATH));
    navigateWithPrompt(PANEL_INSPIRATIONS_PATH);
  };

  const contextBar = {
    left: () =>
      isTouched ? (
        <CancelHistoryButton role="link" clickHandler={handleCancelButton} />
      ) : (
        <BackHistoryButton role="link" clickHandler={handleCancelButton} />
      ),
    middle: () => <ContextBarTitle title={INSPIRATIONS.createNew} />,
    right: () => (
      <PrimaryButton onClick={handleInspirationSave} disabled={saveDisabled}>
        <SaveOutlinedIcon />
        <span>{GLOBAL_CONTENT.save}</span>
      </PrimaryButton>
    ),
  };

  const setValueFor = (fieldName, value) => {
    dispatchInspiration({
      type: "setValueFor",
      fieldName,
      value,
    });

    if (!isTouched) {
      setIsTouched(true);
    }
  };

  const context = {
    ...inspiration,
    setValueFor,
    mode: "create",
  };

  const changeInspitationFormStatus = () => {
    setIsTouched(false);
  };

  useEffect(changeInspitationFormStatus, []);
  useEffect(() => {
    if (errors) {
      setFormErrors(errors);
    }
  }, [errors, setFormErrors]);

  return (
    <PanelTemplate hasPermission={hasPermission} contextBar={contextBar} whiteBg>
      <ManageInspirationContext.Provider value={context}>
        <ManageInspiration errors={formErrors} canEdit={hasPermission} />
      </ManageInspirationContext.Provider>
    </PanelTemplate>
  );
};

CreateInspiration.propTypes = {
  hasPermission: PropTypes.bool.isRequired
};

export { CreateInspiration };
