import {
  GET_PUBLIC_ICONS_START,
  GET_PUBLIC_ICONS_SUCCESS,
  GET_PUBLIC_ICONS_FAIL,
  GET_OPERATOR_ICONS_START,
  GET_OPERATOR_ICONS_SUCCESS,
  GET_OPERATOR_ICONS_FAIL,
  UPDATE_ICONS_START,
  UPDATE_ICONS_SUCCESS,
  UPDATE_ICONS_FAIL,
  ADD_NEW_ICON,
  DELETE_ICON,
  TOGGLE_ICON_FORM_TOUCHED,
} from "store/icons/actionTypes";
import { createAction } from "utils/store";

export const getPublicIconsStart = createAction(GET_PUBLIC_ICONS_START);
export const getPublicIconsSuccess = createAction(GET_PUBLIC_ICONS_SUCCESS);
export const getPublicIconsFail = createAction(GET_PUBLIC_ICONS_FAIL);

export const getOperatorIconsStart = createAction(GET_OPERATOR_ICONS_START);
export const getOperatorIconsSuccess = createAction(GET_OPERATOR_ICONS_SUCCESS);
export const getOperatorIconsFail = createAction(GET_OPERATOR_ICONS_FAIL);

export const addNewIcon = createAction(ADD_NEW_ICON);
export const deleteIcon = createAction(DELETE_ICON);

export const updateIconsStart = createAction(UPDATE_ICONS_START);
export const updateIconsSuccess = createAction(UPDATE_ICONS_SUCCESS);
export const updateIconsFail = createAction(UPDATE_ICONS_FAIL);

export const toggleIconFormTouched = createAction(TOGGLE_ICON_FORM_TOUCHED);
