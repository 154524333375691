import * as actionTypes from "store/roles/actionTypes";

import { reducer, setState, globalReducerActions } from "utils/store";

const rolesInitialState = {
  errors: {},
  actionType: null,
  inProgress: false,
  finished: false,
  listOfRoles: [],
  usersCount: 0,
};

const ReducerActions = globalReducerActions("roles", rolesInitialState, {
  [actionTypes.GET_ROLES_START](state) {
    return setState(state, {
      actionType: actionTypes.GET_ROLES_START,
      inProgress: true,
    });
  },

  [actionTypes.GET_ROLES_SUCCESS](state, payload) {
    const { count, items } = payload;
    return setState(state, {
      actionType: actionTypes.GET_ROLES_SUCCESS,
      inProgress: false,
      finished: true,
      listOfRoles: items,
      usersCount: count,
    });
  },

  [actionTypes.GET_ROLES_FAIL](state, payload) {
    return setState(state, {
      actionType: actionTypes.GET_ROLES_FAIL,
      inProgress: false,
      finished: false,
      errors: payload,
    });
  },
});

const rolesReducer = (state = rolesInitialState, action) => reducer(state, action, ReducerActions);

export { rolesReducer };
