import React from "react";
import moment from "moment";
import TableCell from "@material-ui/core/TableCell";
import PropTypes from "prop-types";

import { TableRow } from "components/ui/Tables";
import config from "config/app";

const DndRequestRow = ({ item }) => {
  const { profile, requested_at, ends_at } = item;
  return (
    <TableRow item={item}>
      <TableCell>{profile.room_number}</TableCell>
      <TableCell>{profile.booking_name}</TableCell>
      <TableCell>{moment(requested_at).format(config.dateFormat)}</TableCell>
      <TableCell>{moment(requested_at).format(config.timeFormat)}</TableCell>
      <TableCell>{moment(ends_at).format(config.timeFormat)}</TableCell>
    </TableRow>
  );
};

DndRequestRow.propTypes = {
  item: PropTypes.shape().isRequired,
};

export { DndRequestRow };
