import { ADMIN_CONNECT_TABLE_LABELS } from "constants/content";

export const connectColumns = [
  { label: ADMIN_CONNECT_TABLE_LABELS.staysId, key: "operatorCode", show: true, sortable: true },
  { label: ADMIN_CONNECT_TABLE_LABELS.propertyName, key: "field1", show: true, sortable: true },
  { label: ADMIN_CONNECT_TABLE_LABELS.companyName, key: "operatorName", show: true, sortable: true },
  { label: ADMIN_CONNECT_TABLE_LABELS.created, key: "createdAt", show: true, sortable: true },
  { label: ADMIN_CONNECT_TABLE_LABELS.last30Days, key: "downloadsLast30", show: true, sortable: false },
  { label: ADMIN_CONNECT_TABLE_LABELS.totalDownloads, key: "downloads", show: true, sortable: true },
];
