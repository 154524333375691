// @ts-check

import { OVERRIDE_VAMOOS_ID_ITINERARY_URL } from "constants/api";
import { HttpClient } from "services/application/httpClient/httpClient";
import { setUrl } from "utils/url";

export class OverrideRepository {

  /**
   * @private
   * @type {HttpClient}
   */
  httpClient;

  constructor() {
    this.httpClient = HttpClient;
  }

  /**
   * 
   * @param {number} vamoosId 
   * @returns {Promise<any>}
   */
  getOverride(vamoosId) {
    const url = setUrl(OVERRIDE_VAMOOS_ID_ITINERARY_URL, { vamoos_id: vamoosId });

    return this.httpClient.get(url);
  }

  /**
   * 
   * @param {number} vamoosId 
   * @param {Object} override 
   * @returns {Promise<any>}
   */
  saveOverride(vamoosId, override) {
    const url = setUrl(OVERRIDE_VAMOOS_ID_ITINERARY_URL, { vamoos_id: vamoosId });
  
    return this.httpClient.post(url, override);
  }
}