import React from "react";
import { useSelector } from "react-redux";
import Grid from "@material-ui/core/Grid";
import { useLocation } from "react-router-dom";

import { InformationField } from "feature/panel/Settings/_shared/InformationField";
import { FormSection } from "components/ui/Forms";

import { StyledLink } from "feature/admin/_shared/StyledComponents";

import { ADMIN_USER_DETAILS_PATH } from "constants/routes";
import { ADMIN_OPERATOR_DETAILS_CONTENT } from "constants/content";

import { setUrl } from "utils/url";

import { GeneralInfoForm } from "./GeneralInfoForm";
import { OperatorInfo } from "./OperatorInfo";

const General = () => {
  const location = useLocation();
  const details = useSelector(state => state.adminOperatorDetails.details);

  const getUrl = id => setUrl(ADMIN_USER_DETAILS_PATH, { id });

  const adminsParsedData = details?.admins?.reduce((acc, { username, id }) => {
    acc.push({ username, id });

    return acc;
  }, []);

  const adminListItems = adminsParsedData?.map((item, index) => (
    <span key={`adminList-${item.id}`}>
      <StyledLink
        to={{
          pathname: getUrl(item.id),
          state: {
            previousUrl: location.pathname,
          },
        }}
        cv="grey100"
        linkfor="table"
      >
        {item.username}
      </StyledLink>
      {index === adminsParsedData.length - 1 ? "" : ", "}
    </span>
  ));

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} md={10}>
        <FormSection title={ADMIN_OPERATOR_DETAILS_CONTENT.generalInfo}>
          <GeneralInfoForm />
        </FormSection>
        <FormSection title={ADMIN_OPERATOR_DETAILS_CONTENT.adminInfo}>
          <InformationField title={ADMIN_OPERATOR_DETAILS_CONTENT.adminsList}>
            <div>{adminListItems}</div>
          </InformationField>
        </FormSection>
        <FormSection title={ADMIN_OPERATOR_DETAILS_CONTENT.operatorInfo}>
          <OperatorInfo />
        </FormSection>
      </Grid>
    </Grid>
  );
};

export { General };
