import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { setButtonAttr } from "utils/styles/buttons";
import { Link as RouterLink } from "components/ui/Links/Link";
import { Link as MuiLink } from "@material-ui/core";

const AdapterLink = React.forwardRef((props, ref) => <RouterLink innerRef={ref} {...props} />);

const Component = ({ external, to, ...rest }) => {
  const p = { ...rest };
  if (rest.target && (rest.target === "_blank" || rest.target === "blank")) p.rel = "noopener noreferrer";
  return external ? <MuiLink href={to} {...p} /> : <MuiLink component={AdapterLink} to={to} {...p} />;
};

const StyledComponent = styled(({ linkFor, noDecoration, ...rest }) => <Component {...rest} />)`
  && {
    color: ${({ theme, cv }) => theme.colors[cv] || theme.colors.brand};
    font-size: ${({ size }) => (size ? setButtonAttr("font-size", size) : "inherit")};
    text-decoration: ${({ noDecoration }) => (noDecoration ? "none" : "underline")};
    cursor: pointer;
    :hover {
      color: ${({ theme }) => theme.colors.branHover} !important;
      text-decoration: ${({ noDecoration }) => (noDecoration ? "none" : "underline")};
    }
    ${({ linkFor, theme }) => {
      if (linkFor && linkFor === "table") {
        return `
          font-weight: ${theme.fonts.fontWeight.normal}
          text-decoration: none;
          `;
      }
      return `font-weight: ${theme.fonts.fontWeight.bold}`;
    }}
  }
`;

const Link = props => {
  Component.defaultProps = {
    external: false,
  };

  Component.propTypes = {
    external: PropTypes.bool,
    to: PropTypes.string.isRequired,
  };

  return <StyledComponent {...props} />;
};

const ContextNavigationLink = styled(({ isActive, ...rest }) => <RouterLink {...rest} />)`
  && {
    display: block;
    font-weight: ${({ theme }) => theme.fonts.fontWeight.semiBold};
    margin: 0 ${({ theme }) => theme.setSpacing(6)}px;
    font-size: ${({ theme }) => theme.setSpacing(4)}px;
    color: ${({ theme, isActive }) => (isActive ? theme.colors.brand : theme.colors.grey100)};
    height: 64px;
    line-height: 64px;
    text-transform: uppercase;
    background: transparent;
    white-space: nowrap;
    transition: ${({ theme }) => theme.transition};
    ${({ theme, isActive }) => (isActive ? `border-bottom: 2px solid ${theme.colors.brand};` : null)};

    :hover {
      background: transparent;
      color: ${({ theme, isActive }) => (isActive ? theme.colors.brandHover : theme.colors.grey70)};
      text-decoration: none;
    }
  }
`;

const LightLink = styled(({ isActive, ...rest }) => <RouterLink {...rest} />)`
  && {
    color: #000000;
    font-weight: normal;
    text-decoration: none;

    :hover {
      text-decoration: underline;
    }
  }
`;

export { Link, LightLink, ContextNavigationLink };
