import React from "react";
import styled from "styled-components";

import { ReactComponent as Verified } from "assets/images/verified-icon.svg";
import { Collapse } from "@material-ui/core";

export const BundleListItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-width: 1200px;
  max-width: 1240px;
  min-height: 40px;
  border-radius: 8px;
  margin-bottom: 8px;
  margin-left: 2px;
  margin-right: 8px;
  overflow: hidden;

  ${({ theme, isOpened }) =>
    isOpened
      ? `
      border: 1px solid rgb(232, 232, 233);
      background-color: ${theme.colors.white};
  `
      : `
  background-color: rgba(232, 232, 233, 0.2);
  `}
`;

export const CustomAppWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  overflow-x: auto;
  max-width: 1250px;
  margin: 0 auto;
`;

export const InfoCell = styled.div`
  margin: auto 5px;
  flex-grow: 1;
  flex-basis: 20%;
  min-width: 210px;
  max-width: 210px;
  align-items: center;
  padding: 0 0 0 2px;
`;

export const ButtonArea = styled(({ hasError, isActive, ...rest }) => <div {...rest} />)`
  width: 72px;
  height: 72px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: -2px;
  overflow: hidden;
  ${({ noBackground, theme, hasError }) =>
    !noBackground
      ? `
      border-left: 1px solid ${hasError ? theme.colors.withOpacity(theme.colors.error, 0.2) : theme.colors.grey10};
    `
      : `
      span {
        background-color: rgba(232,232,233,0) !important;
        color: rgb(49, 56, 64);
      }
      && {
        .MuiButtonBase-root:hover {
          background: none;
        }
      }
    `}
  && {
    .Mui-disabled {
      color: ${({ theme }) => theme.colors.black}15;
    }
  }
`;

export const StyledVerifiedIcon = styled(Verified)`
  &&& {
    fill: ${({ theme, disabled }) => (disabled ? `${theme.colors.black}15` : theme.colors.grey50)} !important;
    width: 24px;
    height: 24px;
  }
`;

export const ItemContent = styled.div`
  width: 100%;
  display: flex;
`;
export const BundleListHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  min-width: 1200px;
  max-width: 1240px;
  min-height: 40px;
  border-radius: 8px;
  overflow: hidden;
  background-color: rgba(232, 232, 233, 0.2);
  box-sizing: border-box;
  margin: 2px 8px 8px 2px;

  div {
    height: 40px;
    line-height: 40px;
    min-width: 210px;
    max-width: 210px;
    padding: 0 0 0 2px;
  }
  div:last-of-type {
    margin-right: 366px;
  }
`;

export const StyledCollapse = styled(Collapse)`
  width: 100% !important;

  && {
    background-color: rgba(232, 232, 233, 0.2) !important;
  }
`;
