import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useService } from "hooks/useService";
import { StayService } from "services/domain/StayService";

import { debounce } from "lodash";
import { DEFAULT_DEBOUNCE_DELAY } from "constants/defaults";
import MapWidget from "feature/panel/_shared/MapWidget";

import { StayListItem } from "./StayListItem";
import { PublicStaysControl } from "./StaysControl";
import { useEffectDependenciesOnly } from "../../../../../hooks/useEffectDependenciesOnly";

const InnerContainer = styled.div`
  width: 100%;
`;

const filteredStays = (stays = [], searchQuery = "", searchedCountries = []) => {
  const filteredList =
    searchedCountries.length > 0 ? stays.filter(stay => searchedCountries.includes(stay.countryIso.toLowerCase())) : stays;
  if (searchQuery === "") {
    return filteredList;
  }

  return filteredList.filter(stay => {
    const queryName = stay.name.toLowerCase();

    return queryName.includes(searchQuery.toLowerCase());
  });
};

const StaySection = ({ onStaySelect }) => {
  const stayService = useService(StayService);

  const handleMapSearch = useRef();
  const mapWidgetRef = useRef();
  const [selectedStay, setSelectedStay] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedCountrySearch, setSelectedCountrySearch] = useState([]);

  const [publicStaysList, setPublicStaysList] = useState([]);
  const [staticStaysList, setStaticStaysList] = useState([]);

  const debouncedSearch = useRef(debounce(search => setSearchQuery(search), DEFAULT_DEBOUNCE_DELAY));

  const fetchAndSetPublicStays = async shouldSetStatic => {
    const publicStays = await stayService.getPublicStays();

    const result = filteredStays(publicStays, searchQuery, selectedCountrySearch);

    setPublicStaysList(result);
    if (shouldSetStatic) setStaticStaysList(result);
  };

  const handleSelectStayFromMap = (searchedLocation, _, handleCloseInfoWindow) => {
    onStaySelect(searchedLocation);
    handleCloseInfoWindow();
  };

  const handleSearchChange = query => {
    debouncedSearch.current(query);
  };

  const handleCountrySearchChange = countries => {
    const countriesIso = countries?.map(country => country.toLowerCase());

    setSelectedCountrySearch(countriesIso);
  };

  const handleMarkerClick = choosenStay => {
    setSelectedStay(choosenStay);
  };

  const handleSelectStays = stay => {
    onStaySelect(stay);
  };

  const handleStayClear = () => {
    setSelectedStay(null);
    mapWidgetRef.current.closeInfoWindow();
  };

  useEffect(() => {
    fetchAndSetPublicStays(true);
  }, []);

  useEffectDependenciesOnly(() => {
    if (searchQuery || selectedCountrySearch.length) {
      fetchAndSetPublicStays();
    } else {
      setPublicStaysList(staticStaysList);
    }
  }, [searchQuery, selectedCountrySearch]);

  return (
    <InnerContainer>
      <PublicStaysControl onSearchChange={handleSearchChange} onCountryChange={handleCountrySearchChange} />
      <MapWidget
        data={{
          stays: publicStaysList,
        }}
        onSearch={handleMapSearch.current}
        switchControls={["placeholder"]}
        onMarkerClick={handleMarkerClick}
        infoWindowsProps={{
          stays: {
            isAdding: true,
            passItemOnMarkerAdd: true,
            onAddMarker: handleSelectStayFromMap,
          },
        }}
        hideSearch
        disableRightClick
      />
      <StayListItem stay={selectedStay} onStaySelect={handleSelectStays} onStayClear={handleStayClear} />
    </InnerContainer>
  );
};

export { StaySection };
