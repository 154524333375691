import styled from "styled-components";
import theme from "config/theme";

export const AuthButtons = styled.div`
  display: flex;
  gap: ${theme.setSpacing(3)}px;
  justify-content: center;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const AppleSignInButton = styled("button")`
  display: flex;
  align-items: center;
  width: 175px;
  height: 32px;
  border-radius: 4px;
  background-color: #fff!important;
  color: #000000!important;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  cursor: pointer;
  border: 1px solid #d1d3d5;
  letter-spacing: 0.25px;
  justify-content: center;

  @media (max-width: 768px) {
    width: 100%;
  }

  &:hover {
    background-color: #f8faff!important;
}

  &:active {
    background-color: #ecf3fe!important;
  }
`;

export const AppleSignInButtonIcon = styled.img`
  width: 30px;
  height: 30px;
  margin-left: -22px;

  @media (max-width: 320px) {
    margin-left: 0;
  }
`;

export const AppleSignInButtonText = styled.span`
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const SpinnerWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
