import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { Chip } from "@material-ui/core";
import FlightIcon from "@material-ui/icons/Flight";
import { ArrowBackIos } from "@material-ui/icons";

import { FLIGHTS_PREVIEW_LABELS, GLOBAL_CONTENT } from "constants/content";

import { PhonePresentation, ContentWrapper, PhoneHeader, LeftSlot, MiddleSlot, RightSlot } from "../PhonePresentation";

const UpdateNotify = styled.div`
  font-size: ${({ theme }) => theme.setSpacing(2.5)}px;
  margin: ${({ theme }) => theme.setSpacing(1.5)}px auto;
`;
const FlightListContainer = styled.div`
  text-align: left;
  margin: 5px;
`;
const FlightItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: 13px;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme, bgColor }) => bgColor || theme.colors.brand};
  padding: ${({ theme }) => theme.setSpacing(1)}px;

  div {
    display: flex;
    flex-direction: column;
    margin: 5px auto;

    strong {
      margin: 5px auto;
      font-weight: 500;
    }

    &:last-of-type {
      align-items: flex-end;
    }

    &:first-of-type {
      align-items: flex-start;
    }
  }
`;

const FlightsDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 13px;
`;

const StyledFlightIcon = styled(FlightIcon)`
  transform: rotate(90deg);
  margin-bottom: ${({ theme }) => theme.setSpacing(1)}px;
`;

const HomeButton = styled.div`
  && {
    font-size: 10px;
    color: ${({ color }) => color};

    .MuiSvgIcon-root {
      font-size: 12px;
      margin-bottom: -2px;
      margin-right: -3px;
    }
  }
`;

const StyledChip = styled(Chip)`
  && {
    background-color: ${({ theme }) => theme.colors.white} !important;
  }
`;

const FlightsPreview = () => {
  const bgColor = useSelector(state => state.customAppForm.customAppForm.colors.defaultBackground);
  const accentColor = useSelector(state => state.customAppForm.customAppForm.colors.accentColor);
  const homeButton = (
    <HomeButton color={accentColor}>
      <ArrowBackIos />
      {GLOBAL_CONTENT.home}
    </HomeButton>
  );

  const preview = {
    label: FLIGHTS_PREVIEW_LABELS.label,
    bgColor: "#ffffff",
    visible: true,
    leftSlot: homeButton,
  };
  const flightNumberLabel = <strong>{FLIGHTS_PREVIEW_LABELS.flightNumber}</strong>;
  const content = (
    <FlightListContainer>
      <FlightItem bgColor={accentColor}>
        <div>
          <small>{FLIGHTS_PREVIEW_LABELS.source}</small>
          <strong>{FLIGHTS_PREVIEW_LABELS.departureDate}</strong>
          <small>{FLIGHTS_PREVIEW_LABELS.departureTime}</small>
        </div>
        <FlightsDetails>
          <StyledFlightIcon />
          <StyledChip size="small" label={flightNumberLabel} />
        </FlightsDetails>
        <div>
          <small>{FLIGHTS_PREVIEW_LABELS.destination}</small>
          <strong>{FLIGHTS_PREVIEW_LABELS.arrivalDate}</strong>
          <small>{FLIGHTS_PREVIEW_LABELS.arrivalTime}</small>
        </div>
      </FlightItem>
      <UpdateNotify>{FLIGHTS_PREVIEW_LABELS.lastUpdate}</UpdateNotify>
    </FlightListContainer>
  );

  return (
    <PhonePresentation>
      <>
        <PhoneHeader bgColor={preview.bgColor} fontColor={preview.fontColor}>
          <LeftSlot>{preview.leftSlot}</LeftSlot>
          <MiddleSlot>{preview.label}</MiddleSlot>
          <RightSlot />
        </PhoneHeader>
        <ContentWrapper bgColor={bgColor}>{content}</ContentWrapper>
      </>
    </PhonePresentation>
  );
};

export { FlightsPreview };
