import React, { useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import styles from "config/theme";
import { SendButton } from "feature/panel/_shared/Messaging/Components/SendButton";
import { usePermissionsService } from "hooks/usePermissionsService";
import { PERMISSIONS } from "constants/permissions";
import { useSelector } from "react-redux";
import { useManageTripContext } from "feature/panel/Trips/_shared/ManageTripContext";

const SendMessageContainer = styled.form`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: ${({ theme }) => theme.setSpacing(18.5)}px;
  width: 100%;
  border-top: 1px solid grey;
  padding: ${({ theme }) => `0 ${theme.setSpacing(6)}px`};
  border-top: 1px solid ${styles.colors.grey10};
  background-color: ${styles.colors.white};
  transition: border 0.3s ease;
  ${({ isFocused, theme }) => (isFocused ? `border: 1px solid ${theme.colors.brand};` : "")};
`;

const StyledTextInput = styled.textarea`
  width: 90%;
  padding-top: ${({ theme }) => theme.setSpacing(4)}px;
  border: none;
  color: ${({ theme }) => theme.colors.grey100};
  font-size: ${({ theme }) => theme.fonts.fontSize.body2}px;
  font-family: "Montserrat";
  resize: none;

  :focus {
    border: none;
    outline: none;
  }
`;

const SendMessageBar = ({ message, handleUpdateMessage, handleMessageSend, isLoading }) => {
  const permissionsService = usePermissionsService();

  const [messageInputFocused, setMessageInputFocused] = useState(false);
  const vamoosId = useSelector(({ stayWizard }) => stayWizard.form.vamoosId);
  const { vamoos_id } = useManageTripContext();

  const canUpdate = permissionsService.can(PERMISSIONS.actions.update, PERMISSIONS.sections.vamoosList, vamoosId || vamoos_id);

  const handleEnterDown = ({ key }) => {
    if (key === "Enter" && canUpdate) {
      handleMessageSend();
    }
  };

  const renderInput = () => (
    <StyledTextInput
      onFocus={() => setMessageInputFocused(true)}
      onBlur={() => setMessageInputFocused(false)}
      onKeyPress={handleEnterDown}
      onChange={({ target }) => handleUpdateMessage(target.value)}
      placeholder="Put your text for new message here..."
      value={message}
    />
  );

  const handleMessageSubmit = e => {
    e.preventDefault();
    handleMessageSend();
  };

  return (
    <SendMessageContainer isFocused={messageInputFocused} onSubmit={handleMessageSubmit}>
      {renderInput()}
      <SendButton active={!!message.length && canUpdate && !isLoading} />
    </SendMessageContainer>
  );
};

SendMessageBar.propTypes = {
  message: PropTypes.string.isRequired,
  handleUpdateMessage: PropTypes.func.isRequired,
  handleMessageSend: PropTypes.func.isRequired,
};

export { SendMessageBar, StyledTextInput };
