// @ts-check

/**
 * @param {UserRoleResponseDto} roleDto - user object
 * @returns {UserRole} Role item
 */
export function UserRole(roleDto) {
  /**
   * @type {UserRole}
   */
  const domain = {};

  domain.id = roleDto.id;
  domain.canAdmin = roleDto.can_admin;
  domain.canCreate = roleDto.can_create;
  domain.canReadAll = roleDto.can_read_all;
  domain.name = roleDto.name;
  domain.permissions = roleDto.permissions;

  return domain;
}
