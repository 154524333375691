import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import PropTypes from "prop-types";

import FormControlLabel from "@material-ui/core/FormControlLabel";

import { CUSTOM_APPS } from "constants/content";
import {
  setIsFormTouched,
  setValueForButtonBgActiveColor,
  setValueForButtonBgInactiveColor,
  setValueForButtonTextActiveColor,
  setValueForButtonTextInactiveColor,
} from "store/customAppForm/actions";
import { Checkbox } from "components/ui/Forms";

import chatBgImg from "assets/images/caSmallBg.png";
import { usePermissionsService } from "hooks/usePermissionsService";
import { PERMISSIONS } from "constants/permissions";

import {
  CardSection,
  CardSectionsContainer,
  ColorPickerWrapper,
  CustomCard,
  InputContentWrapper,
  PreviewCardSection,
  SectionTitle,
} from "../sharedComponents";
import { CustomColorPicker } from "../ColorPicker/CustomColorPicker";

const StyledPreviewCardSection = styled(PreviewCardSection)`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
  min-height: 200px;
`;

const PreviewWrapper = styled.div`
  max-width: 320px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
`;

const ButtonsContainer = styled.div.attrs(props => ({
  style: {
    backgroundColor: props.bgColor ? props.bgColor : props.theme.colors.white,
  },
}))`
  width: 100%;
  height: ${({ theme }) => theme.setSpacing(24)}px;
  margin: ${({ theme }) => theme.setSpacing(5)}px 0;
  border-radius: ${({ theme }) => theme.setSpacing(2)}px;
  background-size: cover;
  display: flex;
  flex-direction: row;
  justify-content: ${({ contentAlign }) => contentAlign || "space-around"};
  align-items: center;
  ${({ bgImage }) =>
    bgImage
      ? `
      background-image: url(${bgImage});
  `
      : ""}
`;

const ButtonPreview = styled.div.attrs(({ textColor, buttonColor }) => ({
  style: {
    color: textColor,
    backgroundColor: buttonColor,
  },
}))`
  width: ${({ theme }) => theme.setSpacing(26)}px;
  height: ${({ theme }) => theme.setSpacing(7)}px;
  line-height: ${({ theme }) => theme.setSpacing(7)}px;
  border-radius: ${({ theme }) => theme.setSpacing(3.5)}px;
  text-align: center;
  vertical-align: middle;
`;

const StyledFormControlLabel = styled(FormControlLabel)`
  && {
    .MuiFormControlLabel-label {
      font-size: ${({ theme }) => theme.setSpacing(3)}px;
    }
  }
`;

const ButtonColorsSetupCard = ({ isHistoryVersion }) => {
  const permissionsService = usePermissionsService();
  const dispatch = useDispatch();

  // eslint-disable-next-line no-underscore-dangle
  const appType = useSelector(state => state.customAppForm.customAppForm.type);
  const isFormTouched = useSelector(state => state.customAppForm.isFormTouched);
  const customAppId = useSelector(state => state.customAppForm.customAppForm.id);
  const accentColor = useSelector(state => state.customAppForm.customAppForm.colors.accentColor);
  const buttonTextColorActive = useSelector(state => state.customAppForm.customAppForm.colors.buttonTextColorActive);
  const buttonBackgroundColorActive = useSelector(state => state.customAppForm.customAppForm.colors.buttonBackgroundColorActive);
  const buttonTextColorInactive = useSelector(state => state.customAppForm.customAppForm.colors.buttonTextColorInactive);
  const buttonBackgroundColorInactive = useSelector(state => state.customAppForm.customAppForm.colors.buttonBackgroundColorInactive);
  const caStatus = useSelector(state => state.customAppForm?.customAppForm?.status);
  const bgColor = useSelector(state => {
    const loginBg = state.customAppForm.customAppForm.colors.loginBackground;
    const defaultBg = state.customAppForm.customAppForm.colors.defaultBackground;
    const applicationType = state.customAppForm.customAppForm.type;

    return applicationType === "stay" ? defaultBg : loginBg;
  });

  const canEdit = permissionsService.can(PERMISSIONS.actions.update, PERMISSIONS.sections.operator, PERMISSIONS.resources.operators);
  const canCreate = permissionsService.can(PERMISSIONS.actions.create, PERMISSIONS.sections.operator, PERMISSIONS.resources.operators);

  const editDisabled = !((caStatus === null && canCreate) || (caStatus === "draft" && canEdit));

  const [defaultAccentColor, setDefaultAccentColor] = useState(false);

  const onActiveBgColorChange = value => {
    const color = defaultAccentColor && accentColor !== buttonBackgroundColorActive ? accentColor : value;

    dispatch(setValueForButtonBgActiveColor(color));
  };

  const onActiveTextColorChange = value => {
    dispatch(setValueForButtonTextActiveColor(value));
  };

  const onInactiveBgColorChange = value => {
    dispatch(setValueForButtonBgInactiveColor(value));
  };

  const onInactiveTextColorChange = value => {
    dispatch(setValueForButtonTextInactiveColor(value));
  };

  const init = () => {
    let cleanup = false;
    if (!cleanup && customAppId && accentColor !== buttonBackgroundColorActive) {
      setDefaultAccentColor(false);
      cleanup = true;
    }
    if (!cleanup && accentColor === buttonBackgroundColorActive) {
      setDefaultAccentColor(true);
    }

    return () => {
      cleanup = false;
    };
  };

  const useAccentColorChange = () => {
    setDefaultAccentColor(!defaultAccentColor);
  };

  useEffect(init, []);
  useEffect(() => {
    if (defaultAccentColor && !isFormTouched) {
      onActiveBgColorChange(accentColor);
      dispatch(setIsFormTouched(false));
    }
    if (defaultAccentColor && isFormTouched) {
      onActiveBgColorChange(accentColor);
    }
  }, [defaultAccentColor, accentColor, appType]);

  const buttonColorSetup = (
    <>
      <ColorPickerWrapper>
        <CustomColorPicker
          color={buttonBackgroundColorActive}
          label={CUSTOM_APPS.elements.buttons.activeBgLabel}
          onColorChange={onActiveBgColorChange}
          disabled={defaultAccentColor || isHistoryVersion || editDisabled}
        />
        <StyledFormControlLabel
          label={CUSTOM_APPS.elements.buttons.useAccentColorLabel}
          control={<Checkbox checked={defaultAccentColor} onChange={useAccentColorChange} />}
          disabled={isHistoryVersion || editDisabled}
        />
      </ColorPickerWrapper>
      <ColorPickerWrapper>
        <CustomColorPicker
          color={buttonTextColorActive}
          label={CUSTOM_APPS.elements.buttons.activeTextLabel}
          onColorChange={onActiveTextColorChange}
          disabled={isHistoryVersion || editDisabled}
        />
      </ColorPickerWrapper>
      <ColorPickerWrapper>
        <CustomColorPicker
          color={buttonBackgroundColorInactive}
          label={CUSTOM_APPS.elements.buttons.inactiveBgLabel}
          onColorChange={onInactiveBgColorChange}
          disabled={isHistoryVersion || editDisabled}
        />
      </ColorPickerWrapper>
      <ColorPickerWrapper>
        <CustomColorPicker
          color={buttonTextColorInactive}
          label={CUSTOM_APPS.elements.buttons.inactiveTextLabel}
          onColorChange={onInactiveTextColorChange}
          disabled={isHistoryVersion || editDisabled}
        />
      </ColorPickerWrapper>
    </>
  );

  const buttonsPreview = (
    <PreviewWrapper>
      <span>Active</span>
      <span>Inactive</span>
      <ButtonsContainer bgColor={bgColor}>
        <ButtonPreview textColor={buttonTextColorActive} buttonColor={buttonBackgroundColorActive}>
          Text
        </ButtonPreview>
        <ButtonPreview textColor={buttonTextColorInactive} buttonColor={buttonBackgroundColorInactive}>
          Text
        </ButtonPreview>
      </ButtonsContainer>
      <ButtonsContainer bgImage={chatBgImg} bgColor={bgColor}>
        <ButtonPreview textColor={buttonTextColorActive} buttonColor={buttonBackgroundColorActive}>
          Text
        </ButtonPreview>
        <ButtonPreview textColor={buttonTextColorInactive} buttonColor={buttonBackgroundColorInactive}>
          Text
        </ButtonPreview>
      </ButtonsContainer>
      <ButtonsContainer>
        <ButtonPreview textColor={buttonTextColorActive} buttonColor={buttonBackgroundColorActive}>
          Text
        </ButtonPreview>
        <ButtonPreview textColor={buttonTextColorInactive} buttonColor={buttonBackgroundColorInactive}>
          Text
        </ButtonPreview>
      </ButtonsContainer>
    </PreviewWrapper>
  );

  return (
    <CustomCard>
      <CardSectionsContainer>
        <CardSection>
          <InputContentWrapper>
            <SectionTitle>{CUSTOM_APPS.elements.buttons.title}</SectionTitle>
            {buttonColorSetup}
          </InputContentWrapper>
        </CardSection>
        <StyledPreviewCardSection>{buttonsPreview}</StyledPreviewCardSection>
      </CardSectionsContainer>
    </CustomCard>
  );
};

ButtonColorsSetupCard.defaultProps = {
  isHistoryVersion: false,
};

ButtonColorsSetupCard.propTypes = {
  isHistoryVersion: PropTypes.bool,
};

export { ButtonColorsSetupCard };
