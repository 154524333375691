import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { debounce } from "lodash";

import { SortableList } from "components/ui/Lists";
import { SortableBarElement } from "feature/panel/_shared/Sortable/SortableBarElement";

import { deleteStayFeatureStart, setOrderForStayFeature, setValueForStay } from "store/adminFeatures/actions";

import { ADMIN_FEATURES_CONTENT } from "constants/content";

import { FeaturesList } from "./FeaturesList";
import { DeleteConfirmation } from "./DeleteConfirmation";

const FeaturesCategoriesList = ({ errors, isEditPermitted, isCreatePermitted }) => {
  const features = useSelector(state => state.adminFeatureStays.features);
  const [deleteConfirmModal, setDeleteConfirmModal] = useState({ open: false, feature: null, count: null });

  const [expandedSectionId, setExpandedSectionId] = useState(null);
  const dispatch = useDispatch();
  const featuresKeys = features
    ? Object.keys(features)
        .filter(key => features[key].isActive)
        .sort((a, b) => features[a].position - features[b].position)
    : [];
  const onSortEndHandler = ({ oldIndex, newIndex }) => {
    const key = featuresKeys[oldIndex];
    dispatch(setOrderForStayFeature({ oldIndex, newIndex, key }));
  };

  const featureNameRefresh = useRef(
    debounce((id, key, value) => {
      dispatch(setValueForStay({ id, value, fieldName: "name", position: key }));
    }, 20),
  );

  const handleDeleteSection = feature => {
    const count = feature.features.reduce((acc, featureItem) => {
      acc += featureItem.count;

      return acc;
    }, 0);
    if (count > 0) {
      setDeleteConfirmModal({ open: true, feature, count });
    } else {
      dispatch(deleteStayFeatureStart({ id: feature.id }));
    }
  };

  const handleDeleteSectionConfirm = () => {
    dispatch(deleteStayFeatureStart({ id: deleteConfirmModal.feature.id }));
    setDeleteConfirmModal({ open: false, feature: null });
  };
  const handleDeleteSectionCancel = () => setDeleteConfirmModal({ open: false, count: null });

  const handleExpandToggle = id => {
    if (expandedSectionId === id) {
      setExpandedSectionId(null);
    } else {
      setExpandedSectionId(id);
    }
  };

  const isNewItemDisable = !(!isEditPermitted || isCreatePermitted);

  return (
    <>
      <SortableList lockAxis="y" onSortEnd={onSortEndHandler} lockToContainerEdges useDragHandle>
        {featuresKeys?.map((key, index) => {
          const errorFieldName = errors[key] && errors[key]?.name ? "name" : "featuresRequired";
          return (
            <SortableBarElement
              key={features[key].id}
              titleLabel={ADMIN_FEATURES_CONTENT.categoryName}
              onTitleChange={e => featureNameRefresh.current(features[key].id, key, e.target.value)}
              onItemDelete={() => handleDeleteSection(features[key])}
              onExpandToggle={() => handleExpandToggle(features[key].id)}
              title={features[key].name || ""}
              index={index}
              isActive={expandedSectionId === features[key].id}
              item={features[key]}
              errors={errors}
              // eslint-disable-next-line
              disabled={!!features[key].isNewItem ? isNewItemDisable : !isEditPermitted}
              errorFieldName={errorFieldName}
              childrenErrors={errors[key]?.features || errors[key]?.featuresRequired}
            >
              <FeaturesList
                features={features[key].features}
                featureGroupId={features[key].id}
                errors={errors[key]?.features}
                // eslint-disable-next-line
                disabled={!!features[key].isNewItem ? isNewItemDisable : !isEditPermitted}
              />
            </SortableBarElement>
          );
        })}
      </SortableList>
      {deleteConfirmModal.open && (
        <DeleteConfirmation
          open={deleteConfirmModal.open}
          count={deleteConfirmModal.count}
          onConfirmModal={handleDeleteSectionConfirm}
          onCancelModal={handleDeleteSectionCancel}
        />
      )}
    </>
  );
};

FeaturesCategoriesList.propTypes = {
  errors: PropTypes.objectOf(
    PropTypes.shape({
      name: PropTypes.string,
      features: PropTypes.shape({}),
      featuresRequired: PropTypes.bool,
    }),
  ).isRequired,
  isEditPermitted: PropTypes.bool.isRequired,
};

export { FeaturesCategoriesList };
