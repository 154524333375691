import React from "react";
import LinkIcon from "@material-ui/icons/Link";
import { Description, InsertPhoto, InsertDriveFile, Videocam } from "@material-ui/icons";

const ICON_TYPES = {
  fileIcon: <InsertDriveFile />,
  photo: <InsertPhoto />,
  pdf: <Description />,
  webUrl: <LinkIcon />,
  video: <Videocam />,
};

const useActiveIcon = file => {
  if (!file) return;

  const { type, web_url, file_name, mime_type, short_name } = file;
  const fileName = file_name || short_name;
  const fileType = type || mime_type;

  const pdfFileTypeRegex = /(application\/pdf)/;
  const videoFileTypeRegex = /(video\/mp4)/;
  const imageFileTypeRegex = /(image\/jpg|image\/jpeg|image\/png|image\/gif)/;

  const fileNamePdfRegex = /[\w,!,@,#,$,%,^,&,*,),(,=,\s-]+\.(pdf|PDF)/;
  const fileNameVideoRegex = /[\w,!,@,#,$,%,^,&,*,),(,=,\s-]+\.(mp4|MP4)/;
  const fileNameImageRegex = /[\w,!,@,#,$,%,^,&,*,),(,=,\s-]+\.(jpg|jpeg|png|gif|JPG|JPEG|PNG|GIF)/;

  if (!web_url) {
    if (pdfFileTypeRegex.test(fileType) || fileNamePdfRegex.test(fileName)) return ICON_TYPES.pdf;
    if (videoFileTypeRegex.test(fileType) || fileNameVideoRegex.test(fileName)) return ICON_TYPES.video;
    if (imageFileTypeRegex.test(fileType) || fileNameImageRegex.test(fileName)) return ICON_TYPES.photo;
  }
  if (!fileType) return ICON_TYPES.webUrl;

  return ICON_TYPES.fileIcon;
};

export { useActiveIcon };
