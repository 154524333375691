import React from "react";
import styled from "styled-components";
import { CaptionText } from "components/ui/Typography/Typography";
import { Link } from "components/ui/Links/Links";
import { VAMOOS_PHONES, VAMOOS_OFFICIAL_WEBSITE } from "constants/content";
import { colors } from "config/theme/colors";

const StyledWrapper = styled.section`
  width: 100%;
  height: ${({ theme }) => theme.setSpacing(15)}px;
  margin-bottom: ${({ theme }) => theme.setSpacing(10)}px;
`;

const FooterSection = () => (
  <StyledWrapper>
    <CaptionText>
      <span>
        If you have questions{" "}
        <a href="mailto:support@vamoos.com " style={{ color: colors.brand }}>
          email us at support@vamoos.com
        </a>{" "}
        or call us at{" "}
      </span>
      <strong>{VAMOOS_PHONES.uk} </strong>
      <span>or </span>
      <strong>{VAMOOS_PHONES.us} </strong>
      <br />
      <span>For more detail go to </span>
      <Link external to={VAMOOS_OFFICIAL_WEBSITE.url} target="_blank" cv="black">
        {VAMOOS_OFFICIAL_WEBSITE.label}
      </Link>
    </CaptionText>
  </StyledWrapper>
);

export { FooterSection, StyledWrapper };
