import React from "react";

import { ADMIN_FEATURES_PATH, ADMIN_ICONS_PATH } from "constants/routes";
import { SubMenu } from "components/templates/_shared/SubMenu/SubMenu";
import { ADMIN_MAIN_SUB_NAVIGATION_LABELS } from "constants/content";

const SettingsSubMenu = () => {
  const menu = [
    { label: ADMIN_MAIN_SUB_NAVIGATION_LABELS.features, url: ADMIN_FEATURES_PATH },
    { label: ADMIN_MAIN_SUB_NAVIGATION_LABELS.icons, url: ADMIN_ICONS_PATH },
  ];

  return <SubMenu menu={menu} />;
};

export { SettingsSubMenu };
