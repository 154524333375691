import v8n from "v8n";
import moment from "moment";

import { addToObject } from "utils/object";
import { MINIMUM_USER_ID_LENGTH } from "constants/defaults";
import { PANEL_STAYS_DAILY_PARAM, PANEL_STAYS_DIRECTORIES_PARAM, PANEL_STAYS_VOUCHERS_PARAM } from "constants/routes";
import { isString } from "contracts/types";
import { ERRORS } from "constants/content";
import { isObject } from "lodash";

export const MAX_INPUT_CHARACTERS = 80;
export const checkErrorExistFor = (key, errors = {}) => {
  return !!errors && errors[key] ? errors[key]?.length > 0 || isObject(errors[key]) : false;
};

export const addErrorToObject = (object, errorMessage) => addToObject(object)("error", errorMessage);
export const removeErrorFromObject = ({ error, ...object }) => object;

export const isTooLong = (length, value) =>
  v8n()
    .minLength(length)
    .test(value);

export const isTooShort = (length, value) =>
  v8n()
    .maxLength(length)
    .test(value);

export const fullNameValidate = value =>
  v8n()
    .maxLength(50)
    .test(value);

export const latitudeValidate = value =>
  v8n()
    .between(-90, 90)
    .test(Number(value));

export const longitudeValidate = value =>
  v8n()
    .between(-180, 180)
    .test(Number(value));

export const latitudeMinRangeValidation = value =>
  v8n()
    .greaterThan(-90)
    .test(Number(value));

export const latitudeMaxRangeValidation = value =>
  v8n()
    .lessThan(91)
    .test(Number(value));

export const longitudeMinRangeValidation = value =>
  v8n()
    .greaterThan(-180)
    .test(Number(value));

export const longitudeMaxRangeValidation = value =>
  v8n()
    .lessThan(181)
    .test(Number(value));

export const emailValidate = value => {
  const EMAIL_REGEX = /^[\w.+'-]+@[\w.-]+\.[A-Z]{2,25}$/i;
  return EMAIL_REGEX.test(value);
};

export const phoneValidate = value => {
  const PHONE_REGEX = /^\+?\d[\d-\s]*$/;
  return PHONE_REGEX.test(value);
};

export const saveStayDisabledValidate = (operator_code, operatorCodeError, isFormTouched) =>
  !isFormTouched || (isString(operator_code) && (operator_code.length < MINIMUM_USER_ID_LENGTH || operatorCodeError));

export const isValidCoordinatePart = part =>
  Boolean((typeof part === "number" && !Number.isNaN(part)) || (typeof part === "string" && part.length && !Number.isNaN(Number(part))));

export const isDirectoryType = type =>
  typeof type === "string" &&
  (type === PANEL_STAYS_DIRECTORIES_PARAM || type === PANEL_STAYS_DAILY_PARAM || type === PANEL_STAYS_VOUCHERS_PARAM);

export const shouldAutocompleteName = currentName => !currentName;

export const setFileNameAfterUpload = (file, currentName) => (shouldAutocompleteName(currentName) ? file.file_name : currentName);

export const isValidDate = date => moment(date) !== ERRORS.invalidDate;

export const validateWebUrl = url => {
  const { href } = new URL(url);
  return href;
};

export const webUrlValidation = url => {
  try {
    new URL(url);
  } catch (e) {
    return false;
  }

  return true;
};

export const isNotEmpty = value => (isString(value) ? value.length > 0 : false);
export const isEmpty = value => (isString(value) ? value.length === 0 : true);
export const isStringNumber = value => !/\D/.test(value);
