import React, { useContext } from "react";
import Grid from "@material-ui/core/Grid";
import AddIcon from "@material-ui/icons/Add";
import { useSelector, useDispatch } from "react-redux";
import uuidv4 from "uuid";

import { SecondaryButton } from "components/ui/Buttons";
import { SortableList } from "components/ui/Lists";
import { UploadImageForm } from "feature/panel/Stays/_shared/Gallery/UploadImageForm";
import { SortableBarElement } from "feature/panel/_shared/Sortable/SortableBarElement";

import { StickyHeaderFormSection } from "components/ui/Forms/StickyHeaderFormSection";
import { NoResultsMessage } from "components/ui/Messages/NoResultsMessage";
import { ImagePreview, ImagePlaceholder } from "components/ui/Images";

import { getFilePreviewUrl } from "utils/library";

import useActiveItemId from "hooks/useActiveItemId";

import {
  CREATE_CONTENT_LABELS,
  EMPTY_LIST_MESSAGES_BASE,
  EMPTY_VARIANT_SECTION,
  GLOBAL_CONTENT,
  NAMES_OF_RESOURCES_LISTS,
} from "constants/content";
import {
  stayWizardAddGalleryItem,
  stayWizardRemoveGalleryItem,
  stayWizardRemoveGalleryItemPicture,
  stayWizardSortGallery,
  stayWizardUpdateGalleryItemTitle,
  stayWizardUpdateGalleryPictures,
} from "store/stayWizard/actions";
import { usePermissionsService } from "hooks/usePermissionsService";
import { PERMISSIONS } from "constants/permissions";
import { LanguageContext } from "components/_shared/LanguageSelector/LanguageContext";
import { useListOfLanguages } from "hooks/useListOfLanguages";

const Gallery = () => {
  const dispatch = useDispatch();
  const permissionsService = usePermissionsService();
  const allLanguages = useListOfLanguages();

  const { currentLanguage } = useContext(LanguageContext);

  const defaultLanguageName = useSelector(({ stayWizard, operator }) => {
    if (stayWizard.defaultVariant.language) {
      return allLanguages.find(lang => lang.code === stayWizard.defaultVariant.language)?.name;
    }

    return allLanguages.find(lang => lang.code === operator.currentOperator.defaultLanguageCode)?.name;
  });

  const [expandedGalleryItem, setExpandedGalleryItem] = useActiveItemId();

  const gallery = useSelector(({ stayWizard }) => stayWizard.form.gallery || []);
  const isWiped = useSelector(({ stayWizard }) => stayWizard.form.isWiped);
  const vamoosId = useSelector(({ stayWizard }) => stayWizard.form.vamoosId);
  const editMode = useSelector(({ stayWizard }) => stayWizard.form.vamoosId !== null);

  const canEdit = permissionsService.can(PERMISSIONS.actions.update, PERMISSIONS.sections.vamoosList, vamoosId);

  const errors = useSelector(({ stayWizard }) => stayWizard.errors.variants?.[currentLanguage.code]?.gallery);

  const isStayDefaultLanguage = useSelector(({ stayWizard }) =>
    stayWizard.defaultVariant.language ? stayWizard.defaultVariant.language === currentLanguage.code : true,
  );

  const handleSectionAdd = () => {
    const id = uuidv4();
    setExpandedGalleryItem(id);

    dispatch(stayWizardAddGalleryItem({ id, language: currentLanguage }));
  };

  const AddGalleryItemButton = () =>
    !isWiped &&
    !(editMode && !canEdit) && (
      <SecondaryButton onClick={handleSectionAdd}>
        <AddIcon />
        {GLOBAL_CONTENT.add}
      </SecondaryButton>
    );

  const onSortEndHandler = ({ oldIndex, newIndex }) => {
    dispatch(
      stayWizardSortGallery({
        oldIndex,
        newIndex,
        language: currentLanguage,
      }),
    );
  };

  const handleUpdateTitle = (id, { target }) => {
    dispatch(
      stayWizardUpdateGalleryItemTitle({
        headline: target.value,
        id,
        language: currentLanguage,
      }),
    );
  };

  const handleDeleteGalleryItem = id => {
    dispatch(stayWizardRemoveGalleryItem({ id, language: currentLanguage }));
  };

  const handleUpdatePicture = (files, item) => {
    dispatch(
      stayWizardUpdateGalleryPictures({
        id: item.id,
        files,
        language: currentLanguage,
      }),
    );
  };

  const handleDeletePicture = ({ image, ...item }) => {
    dispatch(stayWizardRemoveGalleryItemPicture({ id: item.id, language: currentLanguage }));
  };

  const renderSortableList = () => (
    <SortableList lockAxis="y" onSortEnd={onSortEndHandler} lockToContainerEdges useDragHandle>
      {gallery.map((galleryItem, index) => {
        const showImage = galleryItem.image && getFilePreviewUrl(galleryItem.image);
        const imageElement = showImage ? <ImagePreview image={showImage} /> : <ImagePlaceholder hasError={errors?.[galleryItem.id]} />;

        return (
          <SortableBarElement
            key={galleryItem.id}
            titleLabel="Title"
            onTitleChange={e => handleUpdateTitle(galleryItem.id, e)}
            onItemDelete={() => handleDeleteGalleryItem(galleryItem.id)}
            onExpandToggle={() => setExpandedGalleryItem(galleryItem.id)}
            title={galleryItem.headline || ""}
            index={index}
            isActive={expandedGalleryItem === galleryItem.id}
            headerChildren={imageElement}
            errors={errors}
            childrenErrors={errors?.[galleryItem.id]?.image || {}}
            item={galleryItem}
            context="details"
            errorFieldName="headline"
            disabled={isWiped || (editMode && !canEdit)}
          >
            <UploadImageForm
              handleAddPicture={handleUpdatePicture}
              item={galleryItem}
              handleDeletePicture={handleDeletePicture}
              hasError={errors?.[galleryItem.id]}
              disabled={isWiped || (editMode && !canEdit)}
            />
          </SortableBarElement>
        );
      })}
    </SortableList>
  );

  const renderEmptyListMessage = () =>
    !(editMode && !canEdit) && (
      <NoResultsMessage>
        {!isStayDefaultLanguage && (
          <NoResultsMessage height="100px">{EMPTY_VARIANT_SECTION(currentLanguage.name, defaultLanguageName)}</NoResultsMessage>
        )}
        <NoResultsMessage height="100px">
          {EMPTY_LIST_MESSAGES_BASE(CREATE_CONTENT_LABELS.add, NAMES_OF_RESOURCES_LISTS.gallery)}
        </NoResultsMessage>
      </NoResultsMessage>
    );
  const renderGalleryListContent = () => (gallery.length ? renderSortableList() : renderEmptyListMessage());

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} md={10}>
        <StickyHeaderFormSection title="List of images" headerActions={AddGalleryItemButton}>
          {renderGalleryListContent()}
        </StickyHeaderFormSection>
      </Grid>
    </Grid>
  );
};

export { Gallery };
