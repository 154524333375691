import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Grid } from "@material-ui/core";

import { ConfirmationModal } from "components/ui/Modals/ConfirmationModal";
import { CaptionText } from "components/ui/Typography/Typography";

import { GLOBAL_CONTENT, STAYS_FEATURES_LABEL } from "constants/content";
import { setNewOrder } from "utils/sortable";
import { setStayWizardFeatures } from "store/stayWizard/actions";

import { ListOfTopFourFeatures } from "./ListOfTopFourFeatures";
import { TopFourContext } from "./TopFourContext";

const Counter = styled(CaptionText)`
  && {
    position: absolute;
    top: ${({ theme }) => theme.setSpacing(6)}px;
    right: ${({ theme }) => theme.setSpacing(6)}px;
  }
`;

const FeaturesWrapper = styled.div`
  min-width: 400px;
`;

const TopFourFeatures = ({ isOpen, onClose, disabled }) => {
  const dispatch = useDispatch();
  const features = useSelector(({ stayWizard }) => stayWizard.form.features)
  const [allStayFeatures, setAllStayFeatures] = useState([]);
  const [selectedStayFeatures, setSelectedStayFeatures] = useState([]);
  const selectedAmount = selectedStayFeatures.length;

  const handleClose = () => {
    onClose();
  };

  const toggleCheck = (key, value) => {
    if (value) {
      if (!selectedStayFeatures.some(selectedId => selectedId === key)) setSelectedStayFeatures([...selectedStayFeatures, key]);
    } else {
      setSelectedStayFeatures([...selectedStayFeatures.filter(selectedId => selectedId !== key)]);
    }
  };

  const isFeatureChecked = id => selectedStayFeatures.includes(id);
  const isFeatureDisabled = id => selectedStayFeatures.length >= 4 && !selectedStayFeatures.includes(id);

  const onOrderChange = ({ newIndex, oldIndex }) => {
    const newOrderedFeatures = setNewOrder(newIndex, oldIndex, allStayFeatures);
    setAllStayFeatures(newOrderedFeatures);
  };

  const handleSave = () => {
    const newFeatures = [...allStayFeatures].map(item => ({ 
      ...item, 
      isFeatured: selectedStayFeatures.includes(item.id) 
    }));

    dispatch(setStayWizardFeatures(newFeatures))

    onClose();
  };

  const context = {
    toggleCheck,
    isFeatureChecked,
    isFeatureDisabled,
    onOrderChange,
  };

  const init = () => {
    const featuresDomain = [...features].filter(item => item.isActive);
    const selectedItems = [...featuresDomain].filter(feature => feature.isFeatured).map(({ id }) => id);

    setAllStayFeatures(featuresDomain);
    setSelectedStayFeatures(selectedItems);
  };

  useEffect(init, [features]);

  const content = isOpen ? (
    <ConfirmationModal
      open={isOpen}
      title={STAYS_FEATURES_LABEL.topFourTitle}
      confirmLabel={GLOBAL_CONTENT.save}
      confirmDisabled={disabled}
      onConfirm={handleSave}
      onCancel={handleClose}
    >
      <Grid container>
        <Grid item xs={12}>
          <TopFourContext.Provider value={context}>
            <Counter>
              {selectedAmount}
              /4
            </Counter>
            <FeaturesWrapper>
              <ListOfTopFourFeatures disabled={disabled} features={allStayFeatures} />
            </FeaturesWrapper>
          </TopFourContext.Provider>
        </Grid>
      </Grid>
    </ConfirmationModal>
  ) : null;

  return <>{content}</>;
};

TopFourFeatures.defaultProps = {
  isOpen: false,
};

TopFourFeatures.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

export { TopFourFeatures };
