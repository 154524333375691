import React from "react";
import styled from "styled-components";
import { LocationOnOutlined } from "@material-ui/icons";
import PropTypes from "prop-types";

const MapSearchResultContainer = styled.div`
  display: flex;

  .MuiSvgIcon-root {
    color: ${({ theme }) => theme.colors.grey40};
    transition: ${({ theme }) => theme.transition};
  }
`;

const MapSearchResultMainText = styled.span`
  font-size: 16px;
`;

const MapSearchResultSecondaryText = styled.span`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.grey60};
`;

const MapSearchResultsItemInfo = styled.div`
  margin: 0 0 0 8px;
`;

const MapSearchItem = ({ mainText, secondaryText }) => {
  return (
    <MapSearchResultContainer>
      <LocationOnOutlined />
      <MapSearchResultsItemInfo>
        <MapSearchResultMainText>{`${mainText} `}</MapSearchResultMainText>
        <MapSearchResultSecondaryText>{secondaryText}</MapSearchResultSecondaryText>
      </MapSearchResultsItemInfo>
    </MapSearchResultContainer>
  )
};

MapSearchItem.defaultProps = {
  secondaryText: null
};

MapSearchItem.propTypes = {
  mainText: PropTypes.string.isRequired,
  secondaryText: PropTypes.string
};

export {
  MapSearchItem
}