
/**
 * @template T
 */
export class Paginated {
  /** @type {T[]} */
  items;
  
  /** @type {number} */
  page;

  /** @type {number} */
  requestedCount;

  /** @type {number} */
  totalMatches;
}