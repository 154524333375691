import React from "react";
import PropTypes from "prop-types";

import { ConfirmationModal } from "components/ui/Modals/ConfirmationModal";
import { Content } from "components/ui/Content/Content";
import { Input } from "components/ui/Forms";

const FieldChangeModal = ({ open, label, value, onValueEdit, onConfirm, onCancel, children, inputProps }) => {
  return open ? (
    <ConfirmationModal
      open={open}
      title={`Edit ${label}`}
      confirmLabel="save"
      onConfirm={onConfirm}
      onCancel={onCancel}
      fullWidth
      maxWidth="sm"
    >
      {children || (
        <Content margin={5}>
          <Input value={value} name={label} label={label} onChange={onValueEdit} {...inputProps} />
        </Content>
      )}
    </ConfirmationModal>
  ) : null;
};

FieldChangeModal.defaultProps = {
  children: null,
  value: "",
  onValueEdit: null,
  inputProps: {},
};

FieldChangeModal.propTypes = {
  open: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
  onValueEdit: PropTypes.func,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  children: PropTypes.node,
  inputProps: PropTypes.shape(),
};

export { FieldChangeModal };
