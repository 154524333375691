import React from "react";
import styled from "styled-components";
import MuiTableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";

export const StyledMuiTableRow = styled(({ item, ...rest }) => <MuiTableRow {...rest} />)`
  && {
    transition: ${({ theme }) => theme.transition};
    height: ${({ theme }) => theme.setSpacing(18)}px;
    
    :hover {
      background: ${({ theme }) => theme.colors.withOpacity(theme.colors.grey10, 0.3)};
    }

    .MuiTableCell-body {
      white-space: nowrap;
    }
  }
`;

export const ActionTableCell = styled(TableCell)`
  && {
    padding: 0;

    :last-child {
      padding-right: 0;
    }
  }
`;
