import React from "react";
import { Content } from "components/ui/Content/Content";
import { H1 } from "components/ui/Typography/Typography";
import SignInForm from "feature/views/Home/SignIn";

const FormSection = () => (
  <section>
    <Content element="header" margin={11}>
      <H1 cv="grey90">Log in</H1>
    </Content>
    <SignInForm />
  </section>
);

export { FormSection };
