// @ts-check

import { takeEvery, put, call, select } from "redux-saga/effects";

import notifications from "constants/notifications";
import {
  REGISTRATION_USER_WITH_OPERATOR_URL,
  REGISTRATION_USER_URL,
  LOGIN_URL,
  LOGOUT_URL,
  USER_INFO_URL,
  EMAIL_AVAILABILITY_CHECK_URL,
  USERNAME_AVAILABILITY_CHECK_URL,
  COMPANY_CODE_AVAILABILITY_CHECK_URL,
  AUTH_LOGOUT_URL,
} from "constants/api";
import { PANEL_ROOT_PATH } from "constants/routes";

import * as actionTypes from "store/auth/actionTypes";
import {
  registerUserWithOperatorSuccess,
  registerUserWithOperatorFail,
  registerUserSuccess,
  registerUserFail,
  loginFail,
  loginSuccess,
  logoutSuccess,
  logoutFail,
  getCurrentUserSuccess,
  getCurrentUserFail,
  setCurrentOperatorCode,
  editCurrentUserSuccess,
} from "store/auth/actions";
import { clearAllStores, clearErrorsFor, setNotification } from "store/app/actions";

import { HttpClient } from "services/application/httpClient/httpClient";

import { isString } from "contracts/types";

import { setDefaultApiErrorMessage } from "utils";
import { setResponseMessage, handleErrorMessages } from "utils/store";
import { setUrl } from "utils/url";
import { isObjectEmpty } from "utils/object";
import { CHANGES_SAVED_MESSAGE, ERRORS } from "constants/content";
import { AuthService } from "services/AuthService";
import { UserMapper } from "mappers/UserMapper";
import { StorageService } from "services/StorageService";
import { LS_LAST_VISITED_PAGE_KN, LS_TOKEN_KN } from "constants/localstorage";
import { TabSessionService } from "services/TabSessionService";
import { getCurrentOperatorStart } from "store/operator/actions";
import { deleteCookies } from "helpers";

const fetchData = async (urlBluePrint, queryParams) => {
  if (!queryParams || isObjectEmpty(queryParams)) return {};
  const url = setUrl(urlBluePrint, { ...queryParams });
  return HttpClient.get(url);
};

export const checkIfValueExist = urlBluePrint => async queryParams => {
  const { data } = await fetchData(urlBluePrint, queryParams);
  return data && data.exists;
};

export const checkFieldIsTaken = urlBluePrint => async queryParams => {
  const { data } = await fetchData(urlBluePrint, queryParams);
  const { used, operator } = data || {};
  if (used || operator) return true;
  return false;
};

export const checkEmailIsTaken = checkIfValueExist(EMAIL_AVAILABILITY_CHECK_URL);
export const checkUsernameIsTaken = checkIfValueExist(USERNAME_AVAILABILITY_CHECK_URL);
export const checkCompanyCodeIsTaken = checkFieldIsTaken(COMPANY_CODE_AVAILABILITY_CHECK_URL);

function* login({ payload }) {
  const tabSessionSerivce = new TabSessionService(new StorageService());
  yield put(clearErrorsFor("auth"));

  try {
    const userMapper = new UserMapper();

    const { rememberMe, ...loggingData } = payload;
    const response = yield HttpClient.post(LOGIN_URL, loggingData);
    const { user, key } = response.data;

    // const userDomain = userMapper.fromDtoToDomain(user);
    // const { operators } = userDomain;
    // if (rememberMe) {
    //   localStorage.setItem("rememberMeVamoos", "true");
    //   yield tabSessionSerivce.setToken(key);
    // } else {
    //   document.cookie = `${LS_TOKEN_KN}=${key}; path=/`;
    // }

    // const userData = { user, operators, token: key };
    // if (operators.length > 0) {
    //   yield tabSessionSerivce.setGlobalOperatorCode(operators[0].code);
    //   yield tabSessionSerivce.setLocalOperatorCode(operators[0].code);
    //   userData.currentOperatorCode = operators[0].code;
    // }
    // yield put(loginSuccess(userData));
    const userDomain = userMapper.fromDtoToDomain(user);
    const { operators } = userDomain;

    if (rememberMe) {
      localStorage.setItem("rememberMeVamoos", true);
      yield tabSessionSerivce.setToken(key);
    } else {
      document.cookie = `${LS_TOKEN_KN}=${key}; path=/`;
    }

    const userData = { user, operators, token: key };
    if (operators.length > 0) {
      yield tabSessionSerivce.setGlobalOperatorCode(operators[0].code);
      yield tabSessionSerivce.setLocalOperatorCode(operators[0].code);
      userData.currentOperatorCode = operators[0].code;
    }

    yield put(loginSuccess(userData));
  } catch (error) {
    const message = setResponseMessage(error.response);
    yield put(loginFail(message));
    yield put(
      setNotification({
        type: "error",
        message,
      }),
    );
  }
}

function* registerUserWithOperator({ payload }) {
  try {
    yield HttpClient.post(REGISTRATION_USER_WITH_OPERATOR_URL, payload);
    const { username, password } = payload.user;
    yield put(registerUserWithOperatorSuccess());
    yield call(login, { payload: { username, password } });
  } catch (e) {
    const error = handleErrorMessages(e);
    const errorMessage = isString(error) ? error : ERRORS.createAccountFail;
    yield put(
      setNotification({
        type: "error",
        message: errorMessage,
      }),
    );
    // @ts-ignore
    yield put(registerUserWithOperatorFail(error.user ? ERRORS.invalidPassword : error));
  }
}

function* registerUser({ payload }) {
  try {
    const response = yield HttpClient.post(REGISTRATION_USER_URL, payload);
    if (response) {
      const { username, password } = payload;
      yield put(registerUserSuccess());
      yield call(login, { payload: { username, password } });
    }
  } catch (error) {
    const response = handleErrorMessages(error);
    yield put(registerUserFail(response));
    const message = isString(response) ? response : notifications.resource("user").create.fail;
    yield put(
      setNotification({
        type: "error",
        message,
      }),
    );
  }
}

function* logoutSuccessProcess(shouldDisplayMessage, tabSessionService, storageService) {
  yield tabSessionService.clearSessionInfo();
  yield put(logoutSuccess());
  yield put(clearAllStores());
  localStorage.removeItem("rememberMeVamoos");
  localStorage.removeItem("state");
  localStorage.removeItem("nonce");
  sessionStorage.removeItem(LS_TOKEN_KN);
  if (shouldDisplayMessage) {
    storageService.setPropertyByName(LS_LAST_VISITED_PAGE_KN, PANEL_ROOT_PATH);
    yield put(
      setNotification({
        type: "success",
        message: notifications.logout,
      }),
    );
  }
}

const goggleRevoke = (userEmail, clientId) => {
  // to have client_id for the revoke method
  window.google.accounts.id.initialize({
    client_id: clientId
  });

  window.google.accounts.id.disableAutoSelect();
  window.google.accounts.id.revoke(userEmail, done => {
    console.log('done.error', done.error);
    console.log('done', done);
  });
}

function* logout({ payload }) {
  const storageService = new StorageService();
  const tabSessionService = new TabSessionService(storageService);
  try {
    const isSSOAuthenticated = yield tabSessionService.getAuthMethod();

    deleteCookies("authToken");

    if (isSSOAuthenticated) {
      yield HttpClient.post(AUTH_LOGOUT_URL);

      if (isSSOAuthenticated === "google") {
        const { composerData } = yield select(state => state.auth);
        const userEmail = yield tabSessionService.getUserEmail();
        yield call(goggleRevoke, userEmail, composerData.auth.google.systemClientId);
      }
    } else {
      yield HttpClient.post(LOGOUT_URL);
    }

    yield call(logoutSuccessProcess, payload, tabSessionService, storageService);
  } catch (e) {
    if (e.response && e.response.status < 500) {
      yield call(logoutSuccessProcess, payload, tabSessionService, storageService);
    } else {
      yield put(logoutFail());
    }
  }
}

function* getCurrentUser({ payload }) {
  try {
    const authService = new AuthService();

    const user = yield authService.getCurrentUser();
    const token = yield authService.getToken();
    let currentOperatorCode;
    if (!payload) {
      currentOperatorCode = yield authService.getCurrentOperatorCode();
    } else {
      const tabSessionSerivce = new TabSessionService(new StorageService());
      const userMapper = new UserMapper();

      const userDomain = userMapper.fromDtoToDomain(user);
      const { operators } = userDomain;

      if (operators.length > 0) {
        yield tabSessionSerivce.setGlobalOperatorCode(operators[0].code);
        yield tabSessionSerivce.setLocalOperatorCode(operators[0].code);
        currentOperatorCode = operators[0].code;
      }
    }

    yield put(setCurrentOperatorCode(currentOperatorCode));
    yield put(
      getCurrentUserSuccess({
        user,
        operators: user.operators,
        token,
      }),
    );
    yield put(getCurrentOperatorStart());
  } catch (e) {
    yield put(getCurrentUserFail(e));
  }
}

function* editCurrentUser({ payload }) {
  try {
    const { data } = yield HttpClient.post(USER_INFO_URL, payload);
    const { username, email } = data;
    yield put(editCurrentUserSuccess({ username, email }));
    if (payload.password) {
      yield put(
        setNotification({
          type: "success",
          message: "Password updated successfully",
        }),
      );
    } else {
      yield put(setNotification({ type: "success", message: CHANGES_SAVED_MESSAGE }));
    }
  } catch (error) {
    yield put(
      setNotification({
        type: "error",
        message: setDefaultApiErrorMessage(error),
      }),
    );
  }
}

export function* authSaga() {
  yield takeEvery(actionTypes.REGISTER_USER_WITH_OPERATOR_START, registerUserWithOperator);
  yield takeEvery(actionTypes.REGISTER_USER_START, registerUser);
  yield takeEvery(actionTypes.LOGIN_START, login);
  yield takeEvery(actionTypes.LOGOUT_START, logout);
  yield takeEvery(actionTypes.GET_CURRENT_USER_START, getCurrentUser);
  yield takeEvery(actionTypes.EDIT_CURRENT_USER_START, editCurrentUser);
}
