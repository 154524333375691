import { useState } from "react";

const usePopupHandlers = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [props, setProps] = useState(null);

  const open = (newProps = {}) => {
    setIsOpen(true);
    setProps(newProps)
  }

  const close = () => {
    setIsOpen(false);
    setProps({});
  }

  return {
    isOpen,
    open, 
    close,
    props: props || {},
    setProps,
  }
}

export {
  usePopupHandlers
}