import { combineReducers } from "redux";

import { appReducer } from "store/app/reducer";
import { authReducer } from "store/auth/reducer";
import { tripsReducer } from "store/trips/reducer";
import { notificationsReducer } from "store/notifications/reducer";
import { iconsReducer } from "store/icons/reducer";
import { inspirationsReducer } from "store/inspirations/reducer";
import { operatorReducer } from "store/operator/reducer";
import { staysReducer } from "store/stays/reducer";
import { adminOperatorsReducer } from "./adminOperators/reducer";
import { adminUsersReducer } from "./adminUsers/reducer";
import { rolesReducer } from "./roles/reducer";
import { adminOperatorDetailsReducer } from "./adminOperatorDetails/reducer";
import { adminUserDetailsReducer } from "./adminUserDetails/reducer";
import { adminFeatureStaysReducer } from "./adminFeatures/reducer";
import { nestedReducer } from "./nested/reducer";
import { adminConnectReducer } from "./adminConnect/reducer";
import { customAppsReducer } from "./customApps/reducer";
import { customAppFormReducer } from "./customAppForm/reducer";
import { stayWizardReducer } from "./stayWizard/reducer";
import { brandingReducer } from "./branding/reducer";
import { lockReducer } from "./locks/reducer";

export default combineReducers({
  app: appReducer,
  auth: authReducer,
  trips: tripsReducer,
  notifications: notificationsReducer,
  icons: iconsReducer,
  inspirations: inspirationsReducer,
  operator: operatorReducer,
  stays: staysReducer,
  stayWizard: stayWizardReducer,
  adminOperators: adminOperatorsReducer,
  adminUsers: adminUsersReducer,
  roles: rolesReducer,
  adminOperatorDetails: adminOperatorDetailsReducer,
  adminUserDetails: adminUserDetailsReducer,
  adminFeatureStays: adminFeatureStaysReducer,
  nested: nestedReducer,
  adminConnect: adminConnectReducer,
  customApps: customAppsReducer,
  customAppForm: customAppFormReducer,
  branding: brandingReducer,
  locks: lockReducer,
});
