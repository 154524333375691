import { ADMIN_TOGGLE_CONNECT_STAYS_URL } from "constants/api";
import { put, takeEvery, takeLatest } from "redux-saga/effects";

import { setNotification } from "store/app/actions";

import { handleErrorMessages } from "utils/store";
import { setUrl } from "utils/url";
import { ADMIN_CONNECT_LABELS } from "constants/content";
// eslint-disable-next-line import/no-cycle
import { HttpClient } from "services/application/httpClient/httpClient";

import { StayItinerary } from "domain/StayItinerary";

import { getConnectStaysFail, getConnectStaysSuccess, toggleConnectStayFail, toggleConnectStaySuccess } from "./actions";
import * as actionTypes from "./actionTypes";

function* getConnectStays({ payload }) {
  const { url, searchMode } = payload;
  if (searchMode) HttpClient.cancelRequest();

  try {
    const { data } = yield HttpClient.get(url);
    const listOfStays = data.items.map(stay => StayItinerary(stay));
    yield put(getConnectStaysSuccess({ listOfStays, totalMatches: data.total_matches, searchMode }));
  } catch (e) {
    if (!HttpClient.isCancelError(e)) {
      const errors = handleErrorMessages(e);

      yield put(getConnectStaysFail({ errors }));
      yield put(setNotification({ type: "error", message: "error" }));
    }
  }
}

function* toggleStayListedProp({ payload }) {
  const { id, isListed } = payload;
  try {
    const url = setUrl(ADMIN_TOGGLE_CONNECT_STAYS_URL, { id });
    const response = yield HttpClient.post(url, { is_listed: isListed });

    if (response.status === 200) {
      const message = isListed ? ADMIN_CONNECT_LABELS.authorisedForPublicListing : ADMIN_CONNECT_LABELS.unauthorisedForPublicListing;
      yield put(toggleConnectStaySuccess(payload));
      yield put(setNotification({ type: "success", message }));
    }
    if (response.status === 202) {
      yield put(setNotification({ type: "success", message: ADMIN_CONNECT_LABELS.enqueuedForPublicListing(response?.data?.status) }));
    }
  } catch (e) {
    const errors = handleErrorMessages(e);
    yield put(toggleConnectStayFail({ errors }));
    yield put(setNotification({ type: "error", message: ADMIN_CONNECT_LABELS.authorisedForPublicListingFailed }));
  }
}

export function* adminConnect() {
  yield takeEvery(actionTypes.GET_CONNECT_STAYS_START, getConnectStays);
  yield takeLatest(actionTypes.TOGGLE_CONNECT_STAY_START, toggleStayListedProp);
}
