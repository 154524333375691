import * as actionTypes from "store/stayWizard/actionTypes";
import { createAction } from "utils/store";

export const setStayWizardValue = createAction(actionTypes.SET_STAY_WIZARD_VALUE);

export const setStayWizardTouched = createAction(actionTypes.SET_STAY_WIZARD_TOUCHED);

export const stayWizardSetErrors = createAction(actionTypes.STAY_WIZARD_SET_ERRORS);

export const enableStayVariants = createAction(actionTypes.ENABLE_STAY_VARIANTS);
export const disableStayVariants = createAction(actionTypes.DISABLE_STAY_VARIANTS);
export const setDefaultStayVariant = createAction(actionTypes.SET_DEFAULT_STAY_VARIANT);

export const setStayWizardPropertyName = createAction(actionTypes.SET_STAY_WIZARD_PROPERTY_NAME);
export const setStayWizardUserId = createAction(actionTypes.SET_STAY_WIZARD_USER_ID);
export const initStayWizardUserId = createAction(actionTypes.INIT_STAY_WIZARD_USER_ID);
export const setStayWizardHomeDescription = createAction(actionTypes.SET_STAY_WIZARD_HOME_DESCRIPTION);
export const setStayWizardPhone = createAction(actionTypes.SET_STAY_WIZARD_PHONE);
export const setStayWizardEmail = createAction(actionTypes.SET_STAY_WIZARD_EMAIL);
export const setStayWizardWebsite = createAction(actionTypes.SET_STAY_WIZARD_WEBSITE);
export const setStayWizardFacebook = createAction(actionTypes.SET_STAY_WIZARD_FACEBOOK);
export const setStayWizardTwitter = createAction(actionTypes.SET_STAY_WIZARD_TWITTER);
export const setStayWizardInstagram = createAction(actionTypes.SET_STAY_WIZARD_INSTAGRAM);

export const setStayWizardBrandingProfileId = createAction(actionTypes.SET_STAY_WIZARD_BRANDING_PROFILE_ID);

export const stayWizardSetCheckInTime = createAction(actionTypes.STAY_WIZARD_SET_CHECK_IN_TIME);

export const toggleStayWizardRequiredPersonalDetails = createAction(actionTypes.TOGGLE_STAY_WIZARD_REQUIRED_PERSONAL_DETAILS);

export const setStayWizardAddress = createAction(actionTypes.SET_STAY_WIZARD_ADDRESS);
export const setStayWizardCoordinates = createAction(actionTypes.SET_STAY_WIZARD_COORDINATES);

export const setStayWizardInfoButtonLabel = createAction(actionTypes.SET_STAY_WIZARD_INFO_BUTTON_LABEL);
export const setStayWizardInfoButtonIcon = createAction(actionTypes.SET_STAY_WIZARD_INFO_BUTTON_ICON);

export const setStayWizardShortDescription = createAction(actionTypes.SET_STAY_WIZARD_SHORT_DESCRIPTION);
export const setStayWizardLongDescription = createAction(actionTypes.SET_STAY_WIZARD_LONG_DESCRIPTION);
export const setStayWizardNumberOfRooms = createAction(actionTypes.SET_STAY_WIZARD_NUMBER_OF_ROOMS);

export const setStayWizardLogo = createAction(actionTypes.SET_STAY_WIZARD_LOGO);
export const setStayWizardBackground = createAction(actionTypes.SET_STAY_WIZARD_BACKGROUND);

export const setStayWizardFeatures = createAction(actionTypes.SET_STAY_WIZARD_FEATURES);

export const setStayWizardActionTitle = createAction(actionTypes.SET_STAY_WIZARD_ACTION_TITLE);
export const setStayWizardActionIcon = createAction(actionTypes.SET_STAY_WIZARD_ACTION_ICON);
export const setStayWizardDefaultActionIcon = createAction(actionTypes.SET_STAY_WIZARD_DEFAULT_ACTION_ICON);
export const setStayWizardActionFile = createAction(actionTypes.SET_STAY_WIZARD_ACTION_FILE);
export const setStayWizardBookNowTitle = createAction(actionTypes.SET_STAY_WIZARD_BOOK_NOW_TITLE);
export const setStayWizardBookNowIcon = createAction(actionTypes.SET_STAY_WIZARD_BOOK_NOW_ICON);
export const setStayWizardDefaultBookNowIcon = createAction(actionTypes.SET_STAY_WIZARD_DEFAULT_BOOK_NOW_ICON);
export const setStayWizardBookNowFile = createAction(actionTypes.SET_STAY_WIZARD_BOOK_NOW_FILE);

export const stayWizardAddGalleryItem = createAction(actionTypes.STAY_WIZARD_ADD_GALLERY_ITEM);
export const stayWizardSortGallery = createAction(actionTypes.STAY_WIZARD_SORT_GALLERY);
export const stayWizardUpdateGalleryItemTitle = createAction(actionTypes.STAY_WIZARD_UPDATE_GALLERY_ITEM_TITLE);
export const stayWizardRemoveGalleryItem = createAction(actionTypes.STAY_WIZARD_REMOVE_GALLERY_ITEM);
export const stayWizardRemoveGalleryItemPicture = createAction(actionTypes.STAY_WIZARD_REMOVE_GALLERY_ITEM_PICTURE);

export const stayWizardUpdateGalleryPictures = createAction(actionTypes.STAY_WIZARD_UPDATE_GALLERY_PICTURES);

export const stayWizardSetDirectoriesRestriction = createAction(actionTypes.STAY_WIZARD_SET_DIRECTORIES_RESTRICTION);
export const stayWizardSortDirectories = createAction(actionTypes.STAY_WIZARD_SORT_DIRECTORIES);
export const stayWizardAddDirectoriesItem = createAction(actionTypes.STAY_WIZARD_ADD_DIRECTORIES_ITEM);
export const stayWizardRemoveDirectoriesItem = createAction(actionTypes.STAY_WIZARD_REMOVE_DIRECTORIES_ITEM);
export const stayWizardEditDirectoriesItem = createAction(actionTypes.STAY_WIZARD_EDIT_DIRECTORIES_ITEM);

export const stayWizardSetDailyActivitiesRestriction = createAction(actionTypes.STAY_WIZARD_SET_DAILY_ACTIVITIES_RESTRICTION);
export const stayWizardSortDailyActivities = createAction(actionTypes.STAY_WIZARD_SORT_DAILY_ACTIVITIES);
export const stayWizardAddDailyActivitiesItem = createAction(actionTypes.STAY_WIZARD_ADD_DAILY_ACTIVITIES_ITEM);
export const stayWizardRemoveDailyActivitiesItem = createAction(actionTypes.STAY_WIZARD_REMOVE_DAILY_ACTIVITIES_ITEM);
export const stayWizardEditDailyActivitiesItem = createAction(actionTypes.STAY_WIZARD_EDIT_DAILY_ACTIVITIES_ITEM);

export const stayWizardSetVouchersRestriction = createAction(actionTypes.STAY_WIZARD_SET_VOUCHERS_RESTRICTION);
export const stayWizardSortVouchers = createAction(actionTypes.STAY_WIZARD_SORT_VOUCHERS);
export const stayWizardAddVouchersItem = createAction(actionTypes.STAY_WIZARD_ADD_VOUCHERS_ITEM);
export const stayWizardRemoveVouchersItem = createAction(actionTypes.STAY_WIZARD_REMOVE_VOUCHERS_ITEM);
export const stayWizardEditVouchersItem = createAction(actionTypes.STAY_WIZARD_EDIT_VOUCHERS_ITEM);
export const stayWizardToggleStatusVouchersItem = createAction(actionTypes.STAY_WIZARD_TOGGLE_STATUS_VOUCHERS_ITEM);

export const stayWizardSortPasscodes = createAction(actionTypes.STAY_WIZARD_SORT_PASSCODES);
export const stayWizardAddPasscodesItem = createAction(actionTypes.STAY_WIZARD_ADD_PASSCODES_ITEM);
export const stayWizardRemovePasscodesItem = createAction(actionTypes.STAY_WIZARD_REMOVE_PASSCODES_ITEM);
export const stayWizardEditPasscodesItem = createAction(actionTypes.STAY_WIZARD_EDIT_PASSCODES_ITEM);

export const stayWizardSetInspiration = createAction(actionTypes.STAY_WIZARD_SET_INSPIRATION);
export const stayWizardSetInspirationNotificationContent = createAction(actionTypes.STAY_WIZARD_SET_INSPIRATION_NOTIFICATION_CONTENT);
export const stayWizardSetInspirationNotificationPeriod = createAction(actionTypes.STAY_WIZARD_SET_INSPIRATION_NOTIFICATION_PERIOD);

export const stayWizardEnablePoi = createAction(actionTypes.STAY_WIZARD_ENABLE_POI);
export const stayWizardDisablePoi = createAction(actionTypes.STAY_WIZARD_DISABLE_POI);

export const stayWizardRequestConnectOn = createAction(actionTypes.STAY_WIZARD_REQUEST_CONNECT_ON);
export const stayWizardRequestConnectOff = createAction(actionTypes.STAY_WIZARD_REQUEST_CONNECT_OFF);

export const stayWizardToggleDnd = createAction(actionTypes.STAY_WIZARD_TOGGLE_DND);
export const stayWizardChangeDndEmail = createAction(actionTypes.STAY_WIZARD_CHANGE_DND_EMAIL);
export const stayWizardChangeDndTime = createAction(actionTypes.STAY_WIZARD_CHANGE_DND_TIME);

export const stayWizardSetNotifications = createAction(actionTypes.STAY_WIZARD_SET_NOTIFICATIONS);
export const stayWizardInitNotifications = createAction(actionTypes.STAY_WIZARD_INIT_NOTIFICATIONS);

export const stayWizardMessagingSetEmails = createAction(actionTypes.STAY_WIZARD_MESSAGING_SET_EMAILS);
export const stayWizardMessagingSetDatesBefore = createAction(actionTypes.STAY_WIZARD_MESSAGING_SET_DATES_BEFORE);
export const stayWizardMessagingSetDatesAfter = createAction(actionTypes.STAY_WIZARD_MESSAGING_SET_DATES_AFTER);

export const stayWizardAlertEmailsSetEmails = createAction(actionTypes.STAY_WIZARD_ALERT_EMAILS_SET_EMAILS);
export const stayWizardAlertEmailsToggleInclude = createAction(actionTypes.STAY_WIZARD_ALERT_EMAILS_TOGGLE_INCLUDE);

export const fillStayWizard = createAction(actionTypes.FILL_STAY_WIZARD);
export const clearStayWizard = createAction(actionTypes.CLEAR_STAY_WIZARD);

export const fillDefaultVariantWizard = createAction(actionTypes.FILL_DEFAULT_VARIANT_WIZARD);