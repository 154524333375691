import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { Collapse } from "@material-ui/core";

import { Content } from "components/ui/Content";
import { Checkbox, Input } from "components/ui/Forms";
import { BodyText2, ErrorText } from "components/ui/Typography/Typography";
import { Message } from "components/ui/Messages/Message";
import { Switch } from "components/ui/Forms/Switch";
import { TextEditor } from "components/ui/Forms/TextEditor";

import { ERRORS, PUBLISHER_CONTENT } from "constants/content";
import { setLocationsFilter } from "utils/dataConverters";

import { PageEditorField } from "feature/panel/Trips/_shared/Creations/PageEditorField";
import { CPFieldWrapper } from "feature/panel/_shared/CreationsAndPublishers/CPFieldWrapper";
import { CPSideFieldWrapper } from "feature/panel/_shared/CreationsAndPublishers/CPSideFieldWrapper";
import { ImageSizePicker } from "feature/panel/Trips/_shared/Creations/ImageSizePicker";

import { FlexArea, PickerButton, SizePicker } from "feature/panel/Stays/_shared/Publisher/styledComponents";

import { UNSAVED_CHANGES_MESSAGE } from "constants/notifications";

import { MAX_INPUT_CHARACTERS } from "utils/validation";
import { setPublisherModalValue } from "store/stays/actions";

import { Logger } from "services/application/Logger";
import { DEFAULT_PAGE_SIZE } from "constants/defaults";
import { useService } from "hooks/useService";
import { PoiService } from "services/PoiService";

import { PublisherMapSection } from "./PublisherMapSection";

const PublisherPageOneMsgWrapper = styled.div`
  display: flex;
  width: 100%;
  min-height: 10vh;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  & > div {
    margin-bottom: ${({ theme }) => theme.setSpacing(6)}px;
  }
`;

const PublisherPageOnePoiSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.setSpacing(8)}px;
`;

const PublisherPageOnePoiLabel = styled.div`
  font-size: ${({ theme }) => theme.fonts.fontSize.caption}px;
  margin: 0 ${({ theme }) => theme.setSpacing(3)}px 0 auto;
`;

const GreySection = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => `${theme.setSpacing(6)}px ${theme.setSpacing(10)}px`};
  margin-top: ${({ theme }) => `${theme.setSpacing(5)}px`};
  background: ${({ theme }) => theme.colors.withOpacity(theme.colors.grey10, 0.3)};
`;

const OptionalEditorCaption = styled.div`
  font-size: 14px;
  line-height: 14px;
  margin: 0 0 12px 0;
`;

const EditorErrorText = styled(ErrorText)`
  margin-top: 12px !important;
  line-height: 1em;
`;

const ImageSizePickerWrapper = styled.div`
  margin: 25px 0;
`;

const defaultRowValues = { page_break_before: false, show_image: true, image_size: "small" };

const PublisherCreatePageOne = () => {
  const dispatch = useDispatch();

  const poiService = useService(PoiService);

  const stayPois = useSelector(({ stayWizard }) => stayWizard.form.pois);
  const coordinates = useSelector(({ stayWizard }) => stayWizard.form.coordinates);
  const directories = useSelector(({ stayWizard }) => stayWizard.form.directories.items.filter(dir => !dir.parent_id));
  const isWizardTouched = useSelector(({ stayWizard }) => stayWizard.touched);

  const [pois, setPois] = useState([]);

  const hasLocations = coordinates.latitude && coordinates.longitude;

  const isPoiSectionEnabled = pois.some(poi => {
    const stayPoiStatus = stayPois.find(stayPoi => stayPoi.id === poi.id);

    if (stayPoiStatus) {
      return stayPoiStatus.is_on;
    }

    return poi.is_default_on;
  });

  const getPoisList = async () => {
    if(hasLocations) {
      try {
        const { items } = await poiService.getTablePois(
          1, 
          DEFAULT_PAGE_SIZE, 
          null, 
          null, 
          null, 
          null, 
          setLocationsFilter([coordinates]), 
          false
        );
        setPois(items);
      } catch(e) {
        Logger.debug(e);
      }
    } else {
      setPois([]);
    }
  };

  useEffect(() => {
    getPoisList()
  }, []);

  const {
    poiList,
    fileName,
    frontPage,
    lastPage,
    contactDetailsFrontPage,
    areDirectoriesEnabled,
    tableOfContents,
    contactDetailsLastPage,
    cover,
  } = useSelector(({ stays }) => stays.publisherModal.form);

  const isCoverValid = cover.body ? cover.body.replace(/<.+?>/gm, "").replace(/&[a-z]+?;/, " ").length <= 200 : true;

  const emptySectionListError = useSelector(({ stays }) => stays.publisherModal.errors.emptySectionList);

  const onFileNameChange = ({ target }) => {
    if (target.value.length <= MAX_INPUT_CHARACTERS) {
      dispatch(setPublisherModalValue({ field: "fileName", value: target.value }));
    }
  };

  const handleFirstPageFieldUpdate = (frontPageField, value) => {
    dispatch(
      setPublisherModalValue({
        field: "frontPage",
        value: {
          ...frontPage,
          [frontPageField]: value,
        },
      }),
    );
  };

  const handleEnabledDirectoriesChange = () => {
    dispatch(
      setPublisherModalValue({
        field: "areDirectoriesEnabled",
        value: !areDirectoriesEnabled,
      }),
    );

    if (!areDirectoriesEnabled) {
      const newList = directories.map(dir => {
        return { section: dir.tag, ...defaultRowValues };
      });

      dispatch(
        setPublisherModalValue({
          field: "directories",
          value: newList,
        }),
      );
    } else {
      dispatch(
        setPublisherModalValue({
          field: "directories",
          value: [],
        }),
      );
    }
  };

  const handleEnabledCoverPageChange = () => {
    dispatch(
      setPublisherModalValue({
        field: "cover",
        value: {
          ...cover,
          show: !cover.show,
        },
      }),
    );
  };

  const handleBodyCoverPageChange = value => {
    dispatch(
      setPublisherModalValue({
        field: "cover",
        value: {
          ...cover,
          body: value,
        },
      }),
    );
  };

  const handleContactDetailsFrontPageChange = () => {
    dispatch(
      setPublisherModalValue({
        field: "contactDetailsFrontPage",
        value: !contactDetailsFrontPage,
      }),
    );
  };

  const handlePoiListFieldUpdate = (poiField, value) => {
    dispatch(
      setPublisherModalValue({
        field: "poiList",
        value: {
          ...poiList,
          [poiField]: value,
        },
      }),
    );
  };

  const handleLastPageFieldUpdate = (lastPageField, value) => {
    dispatch(
      setPublisherModalValue({
        field: "lastPage",
        value: {
          ...lastPage,
          [lastPageField]: value,
        },
      }),
    );
  };

  const handleContactDetailsLastPage = () => {
    dispatch(
      setPublisherModalValue({
        field: "contactDetailsLastPage",
        value: !contactDetailsLastPage,
      }),
    );
  };

  const handleCoverSizeChange = value => {
    dispatch(
      setPublisherModalValue({
        field: "cover",
        value: {
          ...cover,
          imagesize: value,
        },
      }),
    );
  };

  const handleTableOfContentsChange = () => {
    dispatch(
      setPublisherModalValue({
        field: "tableOfContents",
        value: !tableOfContents,
      }),
    );
  };

  return isWizardTouched ? (
    <PublisherPageOneMsgWrapper>
      <Message text={UNSAVED_CHANGES_MESSAGE} type="info" />
    </PublisherPageOneMsgWrapper>
  ) : (
    <>
      <Content margin={10}>
        <Input
          label={PUBLISHER_CONTENT.sections.fileName}
          type="text"
          value={fileName}
          onChange={onFileNameChange}
          name="creationName"
          isRequired
        />
      </Content>
      <Content>
        <BodyText2>{PUBLISHER_CONTENT.includeSections}</BodyText2>
        {emptySectionListError && <Message margin={{ top: "16px" }} text={PUBLISHER_CONTENT.errors.emptySectionList} type="error" />}
      </Content>
      <Content>
        <CPFieldWrapper
          label={PUBLISHER_CONTENT.sections.coverPage}
          control={<Checkbox checked={cover.show} onChange={handleEnabledCoverPageChange} />}
        />
        <Collapse in={cover.show}>
          <GreySection>
            <ImageSizePickerWrapper>
              <ImageSizePicker
                imageSize={cover.imagesize}
                onImageSizeChange={handleCoverSizeChange}
                firstOptionLabel={PUBLISHER_CONTENT.converImageSize.firstOptionLabel}
                firstOptionValue={PUBLISHER_CONTENT.converImageSize.firstOptionValue}
                secondOptionLabel={PUBLISHER_CONTENT.converImageSize.secondOptionLabel}
                secondOptionValue={PUBLISHER_CONTENT.converImageSize.secondOptionValue}
              />
            </ImageSizePickerWrapper>
            <OptionalEditorCaption>{PUBLISHER_CONTENT.sections.coverPageNotes}</OptionalEditorCaption>
            <TextEditor
              onChange={handleBodyCoverPageChange}
              value={cover.body}
              height={157}
              plugins={["wordcount", "paste", "autolink"]}
              toolbar="bold italic underline"
              menubar={false}
              maxLength={200}
              hasError={!isCoverValid}
              configuration={{
                paste_as_text: true,
              }}
            />
            {!isCoverValid && <EditorErrorText>{ERRORS.isTooLong(200)}</EditorErrorText>}
          </GreySection>
        </Collapse>
      </Content>
      <Content>
        <CPFieldWrapper
          label={PUBLISHER_CONTENT.sections.tableOfContents}
          control={<Checkbox checked={tableOfContents} onChange={handleTableOfContentsChange} />}
        />
      </Content>
      <Content>
        <FlexArea>
          <CPFieldWrapper
            label={PUBLISHER_CONTENT.sections.introductoryNotes}
            control={<Checkbox checked={frontPage.show} onChange={() => handleFirstPageFieldUpdate("show", !frontPage.show)} />}
          />
          {frontPage.show && (
            <CPSideFieldWrapper
              label={PUBLISHER_CONTENT.sections.includeContactDetails}
              control={<Switch checked={contactDetailsFrontPage} onChange={handleContactDetailsFrontPageChange} />}
            />
          )}
        </FlexArea>
        <PageEditorField open={frontPage.show} onChange={handleFirstPageFieldUpdate} text={frontPage.body} headline={frontPage.headline} />
      </Content>
      {directories.length > 0 && (
        <Content>
          <CPFieldWrapper
            label={PUBLISHER_CONTENT.sections.directory}
            control={<Checkbox checked={areDirectoriesEnabled} onChange={handleEnabledDirectoriesChange} />}
          />
          {areDirectoriesEnabled && (
            <Message margin={{ top: "16px" }} type="info" text={PUBLISHER_CONTENT.sections.youCanConfigureDirectoryInNextStep} />
          )}
        </Content>
      )}
      <PublisherMapSection
        label={PUBLISHER_CONTENT.sections.map1}
        name="map1"
        defaultLat={coordinates.latitude}
        defaultLng={coordinates.longitude}
      />
      <PublisherMapSection
        label={PUBLISHER_CONTENT.sections.map2}
        name="map2"
        defaultLat={coordinates.latitude}
        defaultLng={coordinates.longitude}
      />
      <PublisherMapSection
        label={PUBLISHER_CONTENT.sections.map3}
        name="map3"
        defaultLat={coordinates.latitude}
        defaultLng={coordinates.longitude}
      />
      {isPoiSectionEnabled && (
        <PublisherPageOnePoiSection>
          <CPFieldWrapper
            label={PUBLISHER_CONTENT.sections.poiList}
            control={<Checkbox checked={poiList.show} onChange={() => handlePoiListFieldUpdate("show", !poiList.show)} />}
          />
          <PublisherPageOnePoiLabel>{PUBLISHER_CONTENT.sections.orderPoiBy}</PublisherPageOnePoiLabel>
          <SizePicker disabled={!poiList.show} disableRipple>
            <PickerButton size="small" selected={poiList.orderBy === "type"} onClick={() => handlePoiListFieldUpdate("orderBy", "type")}>
              {PUBLISHER_CONTENT.sections.type}
            </PickerButton>
            <PickerButton
              size="small"
              selected={poiList.orderBy === "distance"}
              onClick={() => handlePoiListFieldUpdate("orderBy", "distance")}
            >
              {PUBLISHER_CONTENT.sections.distance}
            </PickerButton>
          </SizePicker>
        </PublisherPageOnePoiSection>
      )}
      <Content>
        <FlexArea>
          <CPFieldWrapper
            label={PUBLISHER_CONTENT.sections.lastPageNotes}
            control={<Checkbox checked={lastPage.show} onChange={() => handleLastPageFieldUpdate("show", !lastPage.show)} />}
          />
          {lastPage.show && (
            <CPSideFieldWrapper
              label={PUBLISHER_CONTENT.sections.includeContactDetails}
              control={<Switch checked={contactDetailsLastPage} onChange={handleContactDetailsLastPage} />}
            />
          )}
        </FlexArea>
        <PageEditorField open={lastPage.show} onChange={handleLastPageFieldUpdate} text={lastPage.body} headline={lastPage.headline} />
      </Content>
    </>
  );
};

PublisherCreatePageOne.propTypes = {
  publisher: PropTypes.shape({
    fileName: PropTypes.string,
    reducedImage: PropTypes.bool,
    headline: PropTypes.string,
    frontPage: PropTypes.shape({
      show: PropTypes.bool,
      headline: PropTypes.string,
      body: PropTypes.string,
    }),
    contactDetailsFrontPage: PropTypes.bool,
  }).isRequired,
};

export { PublisherCreatePageOne };
