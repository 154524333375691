import React, { useState } from "react";
import PropTypes from "prop-types";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import styled from "styled-components";

const StyledDocument = styled(Document)`
  & {
    overflow-y: auto;
    background: #e6e6e6;
    min-height: 320px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  canvas {
    margin: 10px auto 10px auto;
    display: block;
    width: 210mm !important;
    max-width: calc(100% - 80px);
    height: auto !important;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
  }

  .react-pdf__message {
    margin: auto;
    display: block;
    text-align: center;
  }
`;

const PdfView = ({ fileUrl }) => {
  const [numPages, setNumPages] = useState(null);

  const onDocumentLoadSuccess = ({ numPages: nextNumPages }) => {
    setNumPages(nextNumPages);
  };

  const content = Array.from(new Array(numPages), (el, index) => (
    <Page key={`page_${index + 1}`} pageNumber={index + 1} scale="3" renderTextLayer={false} renderAnnotationLayer={false} />
  ));

  return (
    <StyledDocument file={fileUrl} onLoadSuccess={onDocumentLoadSuccess}>
      {content}
    </StyledDocument>
  );
};

PdfView.propTypes = {
  fileUrl: PropTypes.string.isRequired,
};

export { PdfView };
