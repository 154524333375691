import { COMPANY_CODE_AVAILABILITY_CHECK_URL, EMAIL_AVAILABILITY_CHECK_URL, USERNAME_AVAILABILITY_CHECK_URL } from "constants/api";
import { ERRORS } from "constants/content";
import { HttpClient } from "services/application/httpClient/httpClient";
import { encodeParameter, setUrl } from "utils/url";
import { emailValidate, phoneValidate } from "utils/validation";

class RegisterFormStep1Validator {
  // eslint-disable-next-line class-methods-use-this
  async validate(user) {
    const errors = {};

    if(user.username) {

      const { data } =  await HttpClient.get(setUrl(USERNAME_AVAILABILITY_CHECK_URL, { username: encodeParameter(user.username) }))

      if(data.exists) {
        errors.username = ERRORS.resourceTaken("username")
      }
    }

    if(user.email) {
      if(emailValidate(user.email)) {
        const { data } =  await HttpClient.get(setUrl(EMAIL_AVAILABILITY_CHECK_URL, { email: user.email }))

        if(data.exists) {
          errors.email = ERRORS.resourceTaken("email")
        }

      } else {
        errors.email = ERRORS.invalidEmail
      }
    }

    if(user.password && user.password.length < 8) {
      errors.password = ERRORS.invalidPassword;
    }

    if(user.password !== user.passwordConfirmation) {
      errors.passwordConfirmation = ERRORS.invalidPassowrdConfirmation
    }

    return errors;
  }
}

class RegisterFormStep3Validator {
  // eslint-disable-next-line class-methods-use-this
  async validate(form) {
    const errors = {};

    if(form.code) {
      const { data } =  await HttpClient.get(setUrl(COMPANY_CODE_AVAILABILITY_CHECK_URL, { operator_code: form.code }))

      if(data.used || data.operator) {
        errors.code = ERRORS.resourceTaken("company code")
      }
    }

    if(form.telephone && !phoneValidate(form.telephone)) {
      errors.telephone = ERRORS.invalidPhone;
    }

    return errors;
  }
}

export class RegisterValidationService {
  // eslint-disable-next-line class-methods-use-this
  validate(form, step) {

    let validator = null;

    switch(step) {
      case 1:
        validator = new RegisterFormStep1Validator();
        break;

      case 3:
        validator = new RegisterFormStep3Validator();
        break;

      default:
        validator = null;
    }

    if(validator) {
      return validator.validate(form);
    }

    return {};
  }
}