import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Grid } from "@material-ui/core";
import { useLocation, useParams, Outlet } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { usePrompt } from "components/ui/CustomPrompt/CustomPrompt";
import { PanelTemplate } from "components/templates/Panel/PanelTemplate";
import { BackHistoryButton, CancelHistoryButton } from "components/ui/Buttons";
import { ContextBarTitle } from "components/templates/Panel/ContextBarTitle";

import { CUSTOM_APPS, SITE_LEAVE_WARNING_MESSAGES } from "constants/content";
import { PANEL_SETTINGS_CUSTOM_APPS } from "constants/routes";
import * as paths from "constants/routes";
import {
  DISMISS_CUSTOM_APP_START,
  REVISE_CUSTOM_APP_START,
  SUBMIT_CUSTOM_APP_DEFINITION_FOR_REVIEW_START,
  UPDATE_CUSTOM_APP_DEFINITION_START,
} from "store/customAppForm/actionTypes";
import { tryRouteChangeStart, tryRouteChangeFinish, setNotification } from "store/app/actions";
import {
  clearCustomAppForm,
  dismissCustomAppStart,
  getCustomAppByIdStart,
  getCustomAppVersionsListStart,
  reviseCustomAppStart,
  setValueForSequenceNumber,
  submitCustomAppDefinitionForReviewStart,
  updateCustomAppDefinitionStart,
  setIsFormTouched,
} from "store/customAppForm/actions";

import { useExtendedNavigate } from "hooks/useExtendedNavigate";
import { usePermissionsService } from "hooks/usePermissionsService";
import { PERMISSIONS } from "constants/permissions";

import { setUrl } from "utils/url";
import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "@tanstack/react-query";
import { HttpClient } from "services/application/httpClient/httpClient";
import { getPayload } from "../_shared/payload";

import { Navigation } from "./Navigation";
import { CustomAppsFormActions } from "../_shared/CustomAppsFormActions";

const defaultValues = {
  default_language: "en",
  languages: [],
  name_override: null,
  copyright: null,
  support_url: null,
  privacy_policy_url: null,
  keywords: [],
  android_image: null,
  android_assignment_letter: null,
  developer_account_name_android: null,
  short_description_android: null,
  full_description_android: null,
  own_developer_account: null,
  developer_account_name: null,
  short_description: null,
  full_description: null,
  review_contact: null,
  screenshots: {
    operator_code: null,
    passcode: null,
    list_of_screenshots: null,
  },
  ios_assignment_letter: null,
};

const CustomAppsEdit = ({ hasPermission }) => {
  const {
    control,
    handleSubmit,
    formState: { isDirty, errors, dirtyFields },
    watch,
    reset,
    getValues,
    setValue,
    setError,
    clearErrors,
  } = useForm({
    defaultValues,
  });

  const { id } = useParams();

  const navigate = useExtendedNavigate();
  const location = useLocation();
  const permissionsService = usePermissionsService();
  const dispatch = useDispatch();

  const customAppForm = useSelector(state => state.customAppForm.customAppForm);
  const isFormTouched = useSelector(state => state.customAppForm.isFormTouched);
  const actionType = useSelector(state => state.customAppForm?.actionType);
  const finished = useSelector(state => state.customAppForm.finished);
  const inProgress = useSelector(state => state.customAppForm.inProgress);
  const caVersionsList = useSelector(state => state.customAppForm.caVersionsList);
  const redirectPath = useSelector(state => state.app.redirectPath);
  const defaultLanguage = useSelector(state => state.customAppForm.customAppForm.default_language);

  const { bundleId } = customAppForm;

  const queryParams = new URLSearchParams(location.search);
  const isHistoryVersion = queryParams.get("versionHistory");
  const historyCaVersion = queryParams.get("version");

  const { data, isLoading } = useQuery({
    queryKey: [`bundle`],
    queryFn: () => {
      return HttpClient.get(`/bundle/${bundleId}`);
    },
    cacheTime: 0,
    enabled: bundleId ? true : false,
  });

  const lastInfoId = data?.data?.latest_information?._id;

  // const { data: info } = useQuery({
  //   queryKey: [`information`],
  //   queryFn: () => {
  //     return HttpClient.get(`/information/${lastInfoId}`);
  //   },
  //   // enabled: lastInfoId ? true : false,
  // });

  const latest_information = data?.data?.latest_information;
  const defaultAppName = data?.data?.latest_definition?.name;

  const { data: defaultInfo } = useQuery({
    queryKey: ["/information/default"],
    refetchOnMount: true,
    enabled: !latest_information && !isLoading ? true : false,
  });

  usePrompt(SITE_LEAVE_WARNING_MESSAGES.createTitle(SITE_LEAVE_WARNING_MESSAGES.contexts.vamoosEditor), isFormTouched, [
    paths.PANEL_CUSTOM_APPS_EDIT_PATH_GENERAL,
    paths.PANEL_CUSTOM_APPS_EDIT_PATH_LOGO,
    paths.PANEL_CUSTOM_APPS_EDIT_PATH_ELEMENTS,
    paths.PANEL_CUSTOM_APPS_EDIT_PATH_LOGIN,
    paths.PANEL_CUSTOM_APPS_EDIT_PATH_MENU,
    paths.PANEL_CUSTOM_APPS_EDIT_PATH_TEXTS,
    paths.PANEL_CUSTOM_APPS_EDIT_PATH_PREVIEW,
    paths.PANEL_CUSTOM_APPS_EDIT_PATH_VERSIONS,
    paths.PANEL_CUSTOM_APPS_EDIT_PATH_INFORMATION,
  ]);

  const canEditCustomApp = permissionsService.can(
    PERMISSIONS.actions.update,
    PERMISSIONS.sections.operator,
    PERMISSIONS.resources.operators,
  );

  const cancelButtonHandler = () => {
    dispatch(tryRouteChangeStart(PANEL_SETTINGS_CUSTOM_APPS));
    navigate(PANEL_SETTINGS_CUSTOM_APPS);
  };

  const backButtonHandler = () => {
    if (isHistoryVersion) {
      const latestVersion = caVersionsList.sort((a, b) => b.sequenceNumber - a.sequenceNumber)[0];

      const url = setUrl(paths.PANEL_CUSTOM_APPS_EDIT_PATH_VERSIONS, { id: latestVersion.id });
      navigate(url);
    } else {
      cancelButtonHandler();
    }
  };

  const saveSpinner = inProgress && actionType === UPDATE_CUSTOM_APP_DEFINITION_START;
  const submitSpinner = inProgress && actionType === SUBMIT_CUSTOM_APP_DEFINITION_FOR_REVIEW_START;
  const dismissSpinner = inProgress && actionType === DISMISS_CUSTOM_APP_START;
  const pageTitle = CUSTOM_APPS.createPageHeaders.editPageTitle(customAppForm.name, customAppForm.sequenceNumber);

  const getVerisonsList = () => {
    if (customAppForm.bundleId && !caVersionsList.length) {
      dispatch(getCustomAppVersionsListStart({ bundleId: customAppForm.bundleId }));
    }

    if (!customAppForm?.sequenceNumber && caVersionsList.length) {
      const latestVersion = caVersionsList[0].sequenceNumber;
      const currentVersion = isHistoryVersion && historyCaVersion ? historyCaVersion : latestVersion;

      dispatch(setValueForSequenceNumber(currentVersion));
    }
  };

  const moveToSetupPage = () => {
    if (finished && REVISE_CUSTOM_APP_START === actionType && redirectPath !== null) {
      navigate(redirectPath);
      dispatch(tryRouteChangeFinish());
    }
    if (finished && SUBMIT_CUSTOM_APP_DEFINITION_FOR_REVIEW_START === actionType) {
      navigate(PANEL_SETTINGS_CUSTOM_APPS);
      dispatch(tryRouteChangeFinish());
    }
    if (finished && DISMISS_CUSTOM_APP_START === actionType) {
      cancelButtonHandler();
    }
    if (finished && DISMISS_CUSTOM_APP_START !== actionType && REVISE_CUSTOM_APP_START !== actionType) {
      dispatch(clearCustomAppForm());
    }
  };

  const onSave = values => {
    dispatch(
      updateCustomAppDefinitionStart({
        ...customAppForm,
        info: getPayload({ values, dirtyFields, bundle_id: bundleId }),
        dirtyFields,
        infoId: latest_information?._id,
      }),
    );
  };

  const onSubmit = values => {
    dispatch(
      submitCustomAppDefinitionForReviewStart({
        id,
        info: getPayload({ values, dirtyFields, bundle_id: bundleId }),
        dirtyFields,
        infoId: latest_information?._id,
      }),
    );
  };

  const handleRevise = () => {
    dispatch(reviseCustomAppStart(customAppForm));
  };

  const handleDismiss = () => {
    dispatch(dismissCustomAppStart({ id }));
  };

  const leavePageButton =
    isHistoryVersion || !isFormTouched ? (
      <BackHistoryButton role="link" clickHandler={backButtonHandler} />
    ) : (
      <CancelHistoryButton role="link" clickHandler={cancelButtonHandler} />
    );

  const contextBar = {
    left: () => leavePageButton,
    middle: () => <ContextBarTitle title={pageTitle} />,
    right: () => (
      <>
        {canEditCustomApp && customAppForm.status && (
          <CustomAppsFormActions
            isFormTouched={isFormTouched}
            appStatus={customAppForm.status}
            onSave={handleSubmit(onSave)}
            onSubmit={handleSubmit(onSubmit)}
            onRevise={handleRevise}
            onDismiss={handleDismiss}
            saveSpinner={saveSpinner}
            submitSpinner={submitSpinner}
            dismissSpinner={dismissSpinner}
            isHistoryView={isHistoryVersion}
          />
        )}
      </>
    ),
  };

  const getCurrentCustomApp = () => {
    if (id) {
      dispatch(getCustomAppByIdStart(id));
    }
  };

  const init = () => {
    return () => {
      dispatch(clearCustomAppForm());
    };
  };

  useEffect(init, []);
  useEffect(() => {
    if (isHistoryVersion && historyCaVersion) {
      dispatch(setValueForSequenceNumber(historyCaVersion));
    }
    if (!isHistoryVersion && caVersionsList[0]?.sequenceNumber) {
      const latestVersion = caVersionsList[0]?.sequenceNumber;
      dispatch(setValueForSequenceNumber(latestVersion));
    }
  }, [isHistoryVersion, historyCaVersion, customAppForm.id]);
  useEffect(getCurrentCustomApp, [id]);
  useEffect(moveToSetupPage, [finished, actionType, redirectPath, customAppForm.id]);
  useEffect(getVerisonsList, [customAppForm.bundleId, caVersionsList.length]);
  useEffect(() => {
    dispatch(setIsFormTouched(isDirty));
  }, [isDirty]);

  useEffect(() => {
    const info = latest_information || defaultInfo;
    if (info) {
      const {
        android: { full_description, short_description },
      } = info;
      reset({
        ...info,
        name_override: info.name_override || {
          languages: { [defaultLanguage || "en"]: defaultAppName || "App store display name" },
        },
        keywords: info.keywords || [],
        short_description,
        full_description,
        assignment_letter: info.documents?.ios_assignment_letter,
      });
    }
  }, [latest_information, defaultInfo]);

  useEffect(() => {
    return () => reset(defaultValues);
  }, []);

  return (
    <PanelTemplate contextBar={contextBar} navigation={<Navigation />} hasPermission={hasPermission}>
      <Grid container justifyContent="center">
        <Grid item xs={12} md={10}>
          <Outlet context={{ control, watch, getValues, setValue, setError }} />
        </Grid>
      </Grid>
    </PanelTemplate>
  );
};

CustomAppsEdit.propTypes = {
  hasPermission: PropTypes.bool.isRequired,
};

export { CustomAppsEdit };
