import React from "react";
import { H6 } from "components/ui/Typography/Typography";
import { Content } from "components/ui/Content";
import { SecondaryButton } from "components/ui/Buttons";
import { OpenInNew } from "@material-ui/icons";
import { PageWrapper, Page, AnswerText } from "feature/panel/Help/styledComponents";

const VamoosApi = () => (
  <PageWrapper>
    <Page>
      <Content margin={10}>
        <H6>How to use Vamoos API?</H6>
      </Content>
      <Content margin={10} style={{ width: "640px" }}>
        <AnswerText>
          Please press the button below to launch our API site, where you will find instructions, help and tips & tricks on how to use the
          Vamoos API.
        </AnswerText>
      </Content>
      <a href="https://docs.vamoos.com/" target="_blank" rel="noopener noreferrer">
        <SecondaryButton>
          <OpenInNew />
          VAMOOS API
        </SecondaryButton>
      </a>
    </Page>
  </PageWrapper>
);

export { VamoosApi };
