import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { Grid } from "@material-ui/core";

import { Table, TableHeader, TableBody, TablePagination } from "components/ui/Tables";
import { SwitchAction, TableActionsContainer } from "components/ui/Tables/TableRowActions";
import { PoisTableRow } from "feature/panel/Stays/_shared/Pois/PoisTableRow/PoisTableRow";
import headers from "feature/panel/Pois/Index/TableOfPois/TableOfPoisColumns.json";
import { LoadingScreen } from "components/ui/LoadingScreen/LoadingScreen";
import { Link } from "components/ui/Links/Links";
import { FormSection } from "components/ui/Forms";
import { Message } from "components/ui/Messages/Message";

import { stayWizardDisablePoi, stayWizardEnablePoi } from "store/stayWizard/actions";

import { CREATE_ONE_LABEL, DEFINE_LOCATION_LABEL, NO_POIS_FOUND_FOR_LOCATIONS, NO_HOTEL_LOCATION, STAY_POIS } from "constants/content";
import { PANEL_POIS_PATH, PANEL_STAYS_POIS_PARAM } from "constants/routes";
import { setLocationsFilter } from "utils/dataConverters";
import { usePermissionsService } from "hooks/usePermissionsService";
import { PERMISSIONS } from "constants/permissions";
import { PoiService } from "services/PoiService";
import { useTableHandlers } from "hooks/useTableHandlers";
import { LanguageContext } from "components/_shared/LanguageSelector/LanguageContext";
import { LanguageLock } from "../LanguageLock";

const StyledLink = styled(Link)`
  && {
    margin-left: ${({ theme }) => theme.setSpacing(1)}px;
    line-height: 24px;
  }
`;

const MessageWrapper = styled.div`
  margin-top: ${({ theme }) => theme.setSpacing(10)}px;
`;

const PoiColumns = headers.columns.map(column => ({ ...column, sortable: false }));

const Pois = () => {
  const permissionsService = usePermissionsService();
  const poiService = new PoiService();

  const dispatch = useDispatch();

  const { currentLanguage } = useContext(LanguageContext);

  const isStayDefaultLanguage = useSelector(({ stayWizard }) =>
    stayWizard.defaultVariant.language ? stayWizard.defaultVariant.language === currentLanguage.code : true,
  );

  const [pois, setPois] = useState([]);
  const [totalMatches, setTotalMatches] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const { rowsPerPage, page, handleChangeRowsPerPage, handleChangePage } = useTableHandlers();

  const isWiped = useSelector(({ stayWizard }) => stayWizard.form.isWiped);
  const vamoosId = useSelector(({ stayWizard }) => stayWizard.form.vamoosId);
  const editMode = useSelector(({ stayWizard }) => stayWizard.form.vamoosId !== null);

  const canEdit = permissionsService.can(PERMISSIONS.actions.update, PERMISSIONS.sections.vamoosList, vamoosId);

  const stayPois = useSelector(({ stayWizard }) => (isStayDefaultLanguage ? stayWizard.form.pois : stayWizard.defaultVariant.pois));
  const coordinates = useSelector(({ stayWizard }) =>
    isStayDefaultLanguage ? stayWizard.form.coordinates : stayWizard.defaultVariant.coordinates,
  );

  const hasCoordinates = coordinates.latitude && coordinates.longitude;

  const fetchPois = async () => {
    setIsLoading(true);

    const { items, total_matches } = await poiService.getTablePois(
      page,
      rowsPerPage,
      [],
      "",
      null,
      null,
      setLocationsFilter([coordinates]),
    );

    setPois(items);
    setTotalMatches(total_matches);
    setIsLoading(false);
  };

  const handleToggle = item => {
    const stayPoiStatus = stayPois.find(({ id }) => id === item.id);

    if (stayPoiStatus) {
      if (stayPoiStatus.is_on) {
        dispatch(stayWizardDisablePoi(item.id));
      } else {
        dispatch(stayWizardEnablePoi(item.id));
      }
    } else if (item.isDefaultOn) {
      dispatch(stayWizardDisablePoi(item.id));
    } else {
      dispatch(stayWizardEnablePoi(item.id));
    }
  };

  const bodyCellAction = ({ item }) => {
    const checked = (() => {
      const matchingPoi = stayPois.find(({ id }) => id === item.id);
      return matchingPoi ? matchingPoi.is_on : item.isDefaultOn;
    })();

    return (
      <TableActionsContainer>
        <SwitchAction
          disabled={isWiped || (editMode && !canEdit) || !isStayDefaultLanguage}
          clickAction={() => handleToggle(item)}
          checked={checked}
        />
      </TableActionsContainer>
    );
  };

  useEffect(() => {
    if (hasCoordinates) {
      fetchPois();
    } else {
      setPois([]);
      setIsLoading(false);
    }
  }, [page, rowsPerPage, hasCoordinates]);

  if (isLoading) return <LoadingScreen />;
  if (!hasCoordinates) {
    const generalSectionPath = window.location.pathname.replace(`/${PANEL_STAYS_POIS_PARAM}`, "");
    return (
      <Message type="info" text={NO_HOTEL_LOCATION}>
        <StyledLink to={generalSectionPath}>{DEFINE_LOCATION_LABEL}</StyledLink>
      </Message>
    );
  }

  const createPoisMessage = pois.length > 0 && (
    <MessageWrapper>
      <Message type="info" text={STAY_POIS.toAddMessage} />
    </MessageWrapper>
  );

  if (!pois.length)
    return (
      <Message type="info" text={NO_POIS_FOUND_FOR_LOCATIONS}>
        <StyledLink to={PANEL_POIS_PATH}>{CREATE_ONE_LABEL}</StyledLink>
      </Message>
    );
  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} md={10}>
        <FormSection
          title={
            <>
              {STAY_POIS.listOfPois}
              {!isStayDefaultLanguage && <LanguageLock defaultLanguage={{}} />}
            </>
          }
        >
          <Table
            list={pois}
            headers={PoiColumns}
            actions={bodyCellAction}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            count={totalMatches}
            noPaper
            withBorder
          >
            <TableHeader withActions />
            <TableBody rowComponent={PoisTableRow} />
            <TablePagination count={totalMatches} rowsPerPage={rowsPerPage} page={page - 1} />
          </Table>
          {createPoisMessage}
        </FormSection>
      </Grid>
    </Grid>
  );
};

export { Pois };
