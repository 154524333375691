import { useSelector } from "react-redux";

import { PermissionsService } from "services/PermissionsService";
import { isObjectEmpty } from "utils/object";

const usePermissionsService = () => {
  const currentOperatorCode = useSelector(state => state.auth.currentOperatorCode);
  const user = useSelector(state => state.auth.user);
  
  if (user && !isObjectEmpty(user) && !!currentOperatorCode) {
    return new PermissionsService(user, currentOperatorCode);
  }

  return new PermissionsService({}, "");
};

export { usePermissionsService };
