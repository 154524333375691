export function StayUpdateRequestDto(domain) {
  const dto = {};

  dto.notification_text = domain.notificationText;
  dto.force = domain.isForceUpdate;
  dto.check_dates = domain.isSendOnlyToActive;
  
  if(domain.language) {
    dto.language = domain.language;
  }

  return dto;
}
