import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Grid } from "@material-ui/core";
import Alert from "@mui/joy/Alert";
import InfoIcon from "@mui/icons-material/Info";
import { colors } from "config/theme/colors";

import { PresentationCarousel } from "./PresentationCarousel";
import { LoginScreenPreview } from "../previews/LoginScreenPreview";
import { HomeScreenPreview } from "../previews/HomeScreenPreview";
import { MenuOpenedPreview } from "../previews/MenuOpenedPreview";
import { FAQPreview } from "../previews/FAQPreview";
import { AboutPreview } from "../previews/AboutPreview";
import { TermsPreview } from "../previews/TermsPreview";
import { FlightsPreview } from "../previews/FlightsPreview";
import { MessagingPreview } from "../previews/MessagingPreview";
import { WeatherPreview } from "../previews/WeatherPreview";
import { SettingsPreview } from "../previews/SettingsPreview";
import LanguageSelectorCutsomApps from "../components/LanguageSelector";

const AllScreens = () => {
  const [currentLanguage, setCurrentLanguage] = useState(null);
  const defaultLanguage = useSelector(state => state.customAppForm.customAppForm.default_language);
  const availableLanguages = useSelector(state => state.customAppForm.customAppForm.languages);

  const isEnglishExist = availableLanguages?.includes("en");

  const slidesList = [
    { preview: <LoginScreenPreview currentLanguage="en" />, label: "Login screen" },
    { preview: <HomeScreenPreview />, label: "Home screen" },
    { preview: <MenuOpenedPreview />, label: "Menu" },
    { preview: <MessagingPreview />, label: "Messaging" },
    { preview: <WeatherPreview />, label: "Weather" },
    { preview: <SettingsPreview />, label: "Settings" },
    { preview: <FAQPreview language="en" />, label: "FAQ" },
    { preview: <AboutPreview language="en" />, label: "About" },
    { preview: <TermsPreview language="en" />, label: "Terms & Conditions" },
    { preview: <FlightsPreview />, label: "Flights " },
  ];

  useEffect(() => {
    setCurrentLanguage(defaultLanguage);
  }, [defaultLanguage]);

  return (
    <div>
      {!isEnglishExist && (
        <Alert
          sx={{ alignItems: "flex-start", backgroundColor: "rgba(255,124,70,.2)", color: colors.brand, marginBottom: 1 }}
          startDecorator={<InfoIcon />}
          variant="soft"
        >
          We currently only have preview information for the English language app. All content will be displayed in your selected language
        </Alert>
      )}

      {/* <LanguageSelectorCutsomApps currentLanguage={currentLanguage} onChange={lang => setCurrentLanguage(lang)} isBlock noHide /> */}
      <PresentationCarousel slides={slidesList} />
    </div>
  );
};

export { AllScreens };
