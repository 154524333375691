import { takeEvery, put } from "redux-saga/effects";
import * as actionTypes from "store/notifications/actionTypes"; 
import {
  getAllNotificationsSuccess,
  getAllNotificationsFail,
  toggleInProgress,
} from "store/notifications/actions";

import { NotificationsService } from "services/NotificationsService";

function* getAllNotifications() {
  try {
    const notificationService = new NotificationsService();

    yield put(toggleInProgress(true));
    const data = yield notificationService.getAllNotifications();
    yield put(getAllNotificationsSuccess(data));
  } catch (e) {
    yield put(getAllNotificationsFail(e));
  } finally {
    yield put(toggleInProgress(false));
  }
}

export function* notificationsSaga() {
  yield takeEvery(actionTypes.GET_ALL_NOTIFICATION_START, getAllNotifications);
}
