import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { ContextNavigationLink } from "components/ui/Links/Links";

import { PANEL_NOTIFICATIONS_PATH, PANEL_POIS_PATH, PANEL_INSPIRATIONS_PATH, PANEL_SETTINGS_MENUS_LABELS } from "constants/routes";
import { usePermissionsService } from "hooks/usePermissionsService";
import { PERMISSIONS } from "constants/permissions";
import { SHARED_NAVIGATION } from "constants/content";

const StyledWrapper = styled.div`
  display: flex;
  height: 100%;
  width: auto;
  justify-content: center;
  align-items: center;
`;

const SharedNavigation = ({ location }) => {
  const permissionsService = usePermissionsService();
  const { pathname } = location;

  return (
    <StyledWrapper>
      {permissionsService.can(PERMISSIONS.actions.read, PERMISSIONS.sections.operator, PERMISSIONS.resources.notifications) && (
        <ContextNavigationLink to={PANEL_NOTIFICATIONS_PATH} isActive={PANEL_NOTIFICATIONS_PATH === pathname}>
          {SHARED_NAVIGATION.notifications}
        </ContextNavigationLink>
      )}
      <ContextNavigationLink to={PANEL_POIS_PATH} isActive={PANEL_POIS_PATH === pathname}>
        {SHARED_NAVIGATION.poi}
      </ContextNavigationLink>
      {permissionsService.can(PERMISSIONS.actions.read, PERMISSIONS.sections.operator, PERMISSIONS.resources.inspirations) && (
        <ContextNavigationLink to={PANEL_INSPIRATIONS_PATH} isActive={PANEL_INSPIRATIONS_PATH === pathname}>
          {SHARED_NAVIGATION.inspirations}
        </ContextNavigationLink>
      )}
      <ContextNavigationLink to={PANEL_SETTINGS_MENUS_LABELS} isActive={PANEL_SETTINGS_MENUS_LABELS === pathname}>
        {SHARED_NAVIGATION.menuLabels}
      </ContextNavigationLink>
    </StyledWrapper>
  );
};

SharedNavigation.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
};

export { SharedNavigation };
