import { layers } from "config/theme/layers";
import React from "react";
import styled from "styled-components";

import { LoadingScreen } from "../LoadingScreen/LoadingScreen";

const SavingCoverMainElement = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  width: 100vw;
  background: rgba(255, 255, 255, 0.3);
  z-index: ${layers.savingCover};
`;

const SavingCover = () => {
  return (
    <SavingCoverMainElement>
      <LoadingScreen />
    </SavingCoverMainElement>
  )
}

export {
  SavingCover
}