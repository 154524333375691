import React from "react";
import {H6} from "components/ui/Typography/Typography";
import {Content} from "components/ui/Content";
import {SecondaryButton} from "components/ui/Buttons";
import {OpenInNew} from "@material-ui/icons";
import {PageWrapper, Page, AnswerText} from "feature/panel/Help/styledComponents";
import { VAMOOS_USER_GUIDE } from "constants/content";

const UserGuide = () => (
  <PageWrapper>
    <Page>
      <Content margin={10}>
        <H6>
          How to use the Vamoos Partner Portal?
        </H6>
      </Content>
      <Content margin={10}>
        <AnswerText>
          Please press the button below to launch our User Guide site,
          where you will find instructions, help and tips & tricks on how to use the Vamoos Partner Portal.
        </AnswerText>
      </Content>
      <a href={VAMOOS_USER_GUIDE.url} target="_blank" rel="noopener noreferrer">
        <SecondaryButton>
          <OpenInNew />
          {VAMOOS_USER_GUIDE.label}
        </SecondaryButton>
      </a>
    </Page>
  </PageWrapper>
);

export { UserGuide };
