import React, { useContext } from "react";
import PropTypes from "prop-types";

import { NotificationItem } from "feature/panel/_shared/Notifications/GpsNotificationForm/NotificationItem";
import { NoTemplatesMessage } from "feature/panel/_shared/Notifications/NoTemplatesMessage";
import { NOTIFICATION_TYPES } from "constants/content";

const GpsNotificationForm = ({ context, disabled }) => {
  const { notifications } = useContext(context);
  const gpsNotificationsList = notifications.filter(notification => notification.type === NOTIFICATION_TYPES.gps);

  return gpsNotificationsList.length ? (
    gpsNotificationsList.map((notification, index) => (
      <NotificationItem disabled={disabled} key={notification.id} item={notification} index={index} context={context} />
    ))
  ) : (
    <NoTemplatesMessage notificationType="GPS" />
  );
};

GpsNotificationForm.defaultProps = {
  disabled: false,
}

GpsNotificationForm.propTypes = {
  context: PropTypes.shape({}).isRequired,
  disabled: PropTypes.bool,
};

export { GpsNotificationForm };
