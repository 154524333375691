const authConfig = {
  "auth": {
    "com.appexmobile.vamoos": {
      "google": {
        "name": "google",
        "label": "Log in with Google",
        "systemClientId": "100722660571-hfdjkfvipv89s57vmda78cqj03iimiq3.apps.googleusercontent.com",
        "authUrl": "https://accounts.google.com/o/oauth2/v2/auth?client_id=100722660571-hfdjkfvipv89s57vmda78cqj03iimiq3.apps.googleusercontent.com&scope=openid%20email%20profile&response_type=id_token&state={{state}}&redirect_uri={{redirect_uri}}&nonce={{nonce}}"
      },
      "apple": {
        "name": "apple",
        "label": "Log in with Apple",
        "systemClientId": "com.appexmobile.vamoos-portal",
        "authUrl": "https://appleid.apple.com/auth/authorize?client_id=com.appexmobile.vamoos-portal&scope=openid%20email%20name&response_type=code&response_mode=form_post&state={{state}}&redirect_uri={{redirect_uri}}"
      }
    },
    "com.appexmobile.vamoos.uat": {
      "google": {
        "name": "google",
        "label": "Log in with Google",
        "systemClientId": "100722660571-g83udfc3fn1u7k1jt2ujfjotfl5fu4ke.apps.googleusercontent.com",
        "authUrl": "https://accounts.google.com/o/oauth2/v2/auth?client_id=100722660571-g83udfc3fn1u7k1jt2ujfjotfl5fu4ke.apps.googleusercontent.com&scope=openid%20email%20profile&response_type=id_token&state={{state}}&redirect_uri={{redirect_uri}}&nonce={{nonce}}"
      },
      "apple": {
        "name": "apple",
        "label": "Log in with Apple",
        "systemClientId": "com.appexmobile.vamoos-portal.uat",
        "authUrl": "https://appleid.apple.com/auth/authorize?client_id=com.appexmobile.vamoos-portal.uat&scope=openid%20email%20name&response_type=code&response_mode=form_post&state={{state}}&redirect_uri={{redirect_uri}}"
      }
    },
    "com.appexmobile.vamoos.debug": {
      "google": {
        "name": "google",
        "label": "Log in with Google",
        "systemClientId": "100722660571-g7d7jgno5p7qjeichoejo4b6460i6qu2.apps.googleusercontent.com",
        "authUrl": "https://accounts.google.com/o/oauth2/v2/auth?client_id=100722660571-g7d7jgno5p7qjeichoejo4b6460i6qu2.apps.googleusercontent.com&scope=openid%20email%20profile&response_type=id_token&state={{state}}&redirect_uri={{redirect_uri}}&nonce={{nonce}}"
      },
      "apple": {
        "name": "apple",
        "label": "Log in with Apple",
        "systemClientId": "com.appexmobile.vamoos-portal.stg",
        "authUrl": "https://appleid.apple.com/auth/authorize?client_id=com.appexmobile.vamoos-portal.stg&scope=openid%20email%20name&response_type=code&response_mode=form_post&state={{state}}&redirect_uri={{redirect_uri}}"
      }
    }
  },
  "authenticationRequiredToLogIn": false
}


export const getComposerData = () => {
  const metaTag = document.querySelector('meta[build-version]');
  const versionId = metaTag.getAttribute('build-version') || "Unknown";

  const composerData = {
    bundleId: 'com.appexmobile.vamoos',
    authenticationRequiredToLogIn: authConfig.authenticationRequiredToLogIn,
    versionId
  };

  const env = process.env.REACT_APP_API_URL_DEVELOPMENT;
  if (env.includes('uat')) {
    composerData.auth = authConfig.auth['com.appexmobile.vamoos.uat'];
    composerData.bundleId += '.uat';
  } else if (env.includes('stg')) {
    composerData.auth = authConfig.auth['com.appexmobile.vamoos.debug'];
    composerData.bundleId += '.debug';
  } else {
    composerData.auth = authConfig.auth['com.appexmobile.vamoos'];
  }

  return composerData;
}
