import React from "react";
import styled, { css } from "styled-components";
import {
  KeyboardDatePicker as MuiDatePicker,
  KeyboardTimePicker as MuiTimePicker,
  DateTimePicker as MuiDateTimePicker,
} from "@material-ui/pickers";
import { WatchLaterOutlined, CalendarTodayOutlined } from "@material-ui/icons";
import theme from "config/theme";
import { setInputAttr } from "utils/styles";
import config from "config/app";
import { useDatePickerPropsCreator } from "hooks/useDatePickerPropsCreator";
import moment from "moment";
import "moment/min/locales";
import { getUserLocale } from "get-user-locale";

import Toolbar from "./Toolbar";
import { getDateFormat } from "../../../utils/getDateFormat";

const locale = navigator.languages?.[0] || "en";

moment.locale(locale);

const adjustTransformYValue = size => {
  switch (size) {
    case "small":
      return 11;
    case "large":
      return 16;
    default:
      return 13;
  }
};

const sharedPickersStyling = css`
  && {
    border-radius: ${theme.borderRadius}px !important;

    fieldset {
      border-radius: ${theme.borderRadius}px;
      height: ${({ size }) => setInputAttr("height", size) + 5}px;
    }

    input {
      height: ${({ size }) => setInputAttr("input-height", size)}px;
      line-height: ${({ size }) => setInputAttr("input-height", size)}px;
      font-size: ${({ size }) => setInputAttr("font-size", size)}px;
      padding: ${({ size }) => setInputAttr("padding", size)};
      min-width: 45px;
    }

    label {
      font-size: ${({ size }) => setInputAttr("font-size", size)}px;
      &.Mui-focused {
        color: ${theme.colors.brand};
      }
    }

    .MuiInputAdornment-positionEnd {
      button {
        padding: 0;
      }
    }

    &:hover,
    &:focus,
    &:active {
      fieldset {
        border-color: ${theme.colors.brand};
      }
    }
    .Mui-focused {
      fieldset {
        border-color: ${theme.colors.brand};
      }

      &:hover {
        fieldset {
          color: ${theme.colors.brandHover};
          border-color: ${theme.colors.brandHover};
        }
      }
    }

    .Mui-disabled {
      background-color: ${theme.colors.withOpacity(theme.colors.grey10, 0.2)};

      input {
        background-color: transparent;
      }
    }

    label.Mui-disabled {
      background: transparent;
    }

    .MuiFormHelperText-root {
      font-size: ${theme.setSpacing(3)}px;
      line-height: ${theme.setSpacing(4)}px;
      margin: ${theme.setSpacing(1)}px;
    }
    .MuiSvgIcon-root {
      font-size: ${({ size }) => setInputAttr("font-size", size)}px;
    }
    .MuiInputLabel-outlined {
      transform: translate(14px, ${({ size }) => adjustTransformYValue(size)}px) scale(1);

      &.MuiInputLabel-shrink {
        transform: translate(14px, -6px) scale(0.75);
      }
    }
  }
`;

const DatePickerInput = styled(({ size, ...rest }) => <MuiDatePicker {...rest} />)`
  ${sharedPickersStyling}
`;

const TimePickerInput = styled(({ size, ...rest }) => <MuiTimePicker {...rest} />)`
  ${sharedPickersStyling}
`;

const DateTimePickerInput = styled(({ size, ...rest }) => <MuiDateTimePicker {...rest} />)`
  ${sharedPickersStyling}
`;

const DatePicker = props => {
  const pickerProps = useDatePickerPropsCreator("date", props);

  const propsAll = {
    ...props,
    format: getDateFormat(),
    ToolbarComponent: Toolbar,
  };

  return (
    <DatePickerInput
      {...pickerProps}
      {...propsAll}
      keyboardIcon={<CalendarTodayOutlined />}
      InputProps={{ placeholder: config.inputDateFormat }}
      error={pickerProps.error}
      helperText={pickerProps.helperText}
      style={{ width: "100%" }}
    />
  );
};

const TimePicker = props => {
  const pickerProps = useDatePickerPropsCreator("time", props);
  return (
    <TimePickerInput
      {...pickerProps}
      {...props}
      keyboardIcon={<WatchLaterOutlined />}
      InputProps={{ placeholder: config.timePlaceholder }}
      ampm={false}
      error={pickerProps.error}
      helperText={pickerProps.helperText}
    />
  );
};

const DateTimePicker = props => {
  const pickerProps = useDatePickerPropsCreator("dateTimeFormat", props);

  return <DateTimePickerInput {...pickerProps} {...props} error={pickerProps.error} keyboardIcon={<WatchLaterOutlined />} />;
};

export { DatePicker, TimePicker, DateTimePicker };
