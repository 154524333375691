import React from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

import { setValueForMenuDocumentContent } from "store/customAppForm/actions";

import { usePermissionsService } from "hooks/usePermissionsService";
import { PERMISSIONS } from "constants/permissions";

import { CardSection, CardSectionsContainer, CustomCard, InputContentWrapper, PreviewCardSection, SectionTitle } from "../sharedComponents";

import { CustomAppEditor } from "./CustomAppEditor";
import { AboutPreview } from "../previews/AboutPreview";
import SectionTitleEditable from "./components/SectionTitle";

const AboutBox = ({ isHistoryVersion, language, defaultName }) => {
  const permissionsService = usePermissionsService();
  const dispatch = useDispatch();

  const caStatus = useSelector(state => state.customAppForm?.customAppForm?.status);

  const aboutValue = useSelector(
    state => state.customAppForm.customAppForm?.menu?.documents?.about?.languages?.[language.current]?.html_content,
  );

  const aboutName = useSelector(state => state.customAppForm.customAppForm?.menu?.documents?.about?.languages?.[language.current]?.title);

  const errors = useSelector(state => state.customAppForm.errors?.menu);

  const canEdit = permissionsService.can(PERMISSIONS.actions.update, PERMISSIONS.sections.operator, PERMISSIONS.resources.operators);
  const canCreate = permissionsService.can(PERMISSIONS.actions.create, PERMISSIONS.sections.operator, PERMISSIONS.resources.operators);

  const editDisabled = !((caStatus === null && canCreate) || (caStatus === "draft" && canEdit));

  const onEditorContentChange = value => {
    dispatch(
      setValueForMenuDocumentContent({ content: value, type: "about", language: language.current, defaultName: defaultName.current }),
    );
  };

  const onTitleChange = value => {
    dispatch(
      setValueForMenuDocumentContent({
        title: value || defaultName.current,
        type: "about",
        language: language.current,
      }),
    );
  };

  return (
    <CustomCard>
      <CardSectionsContainer>
        <CardSection>
          <InputContentWrapper>
            <SectionTitleEditable
              title={aboutName || defaultName.current}
              onChange={onTitleChange}
              disabled={isHistoryVersion || editDisabled}
            />
            <CustomAppEditor
              value={aboutValue || ""}
              onEditorContentChange={onEditorContentChange}
              error={!!errors}
              helperText={errors?.html_content}
              disabled={isHistoryVersion || editDisabled}
              forceChange
            />
          </InputContentWrapper>
        </CardSection>
        <PreviewCardSection>
          <AboutPreview language={language} title={aboutName || defaultName.current} />
        </PreviewCardSection>
      </CardSectionsContainer>
    </CustomCard>
  );
};

AboutBox.defaultProps = {
  isHistoryVersion: false,
};

AboutBox.propTypes = {
  isHistoryVersion: PropTypes.bool,
};

export { AboutBox };
