import React, { useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { debounce } from "lodash";

import { FormControlLabel } from "@material-ui/core";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";

import { ChipWithRedirect } from "components/ui/Chips/ChipWithRedirect";
import { Input, Checkbox } from "components/ui/Forms";
import { AttachFilesButton } from "components/ui/Buttons/AttachFilesButton";
import { FlexGrow } from "components/ui/Content";
import { UnsafeAutocomplete } from "components/ui/Forms/UnsafeAutocomplete";
import { SecondaryButton } from "components/ui/Buttons/SecondaryButton";

import { SortableListElement, RemoveButton } from "components/_shared/SortableListElement/SortableListElement";
import { SortableListElementContentWithError } from "components/_shared/SortableListElementContentWithError/SortableListElementContentWithError";

import { useItemOriginalIndex } from "hooks/useItemOriginalIndex";

import { formatDisplayableLibraryPopupValues } from "utils/library";
import { checkErrorExistFor } from "utils/validation";
import { isObjectEmpty } from "utils/object";

import { ERRORS, LOCATIONS_LABELS } from "constants/content";
import { DOCUMENTS_FILE_TYPES } from "constants/defaults";

import { CustomiseNestedStay } from "feature/panel/_shared/CustomiseNestedStay/CustomiseNestedStay";

import nestingMarker from "assets/images/nesting_marker.svg";
import { useActiveIcon } from "hooks/useActiveIcon";

import { EventBus } from "services/application/EventBus";
import { LocationCoordinatesHasBeenChanged } from "events/LocationCoordinatesHasBeenChanged";

import { useManageTripContext } from "../../ManageTripContext";

const FlexContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  & > * {
    margin: 0 ${({ theme }) => theme.setSpacing(1.5)}px !important;
  }
`;

const AutocompleteWrapper = styled.div`
  min-width: 20%;
`;

const StyledFormControlLabel = styled(FormControlLabel)`
  && {
    margin-right: 0;

    .MuiFormControlLabel-label {
      font-size: ${({ theme }) => theme.setSpacing(3)}px;
    }
  }
`;

const IndexItem = styled.div`
  && {
    min-width: 20px;
    margin-right: 0px !important;
    margin-left: 0px !important;
  }
`;

const StayMarker = styled.span`
  color: ${({ theme }) => theme.colors.white};
  text-stroke: 1px ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.white};
  font-size: ${({ theme }) => theme.setSpacing(3)}px;
  font-family: Montserrat;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  position: absolute;
  top: 8px;
  left: 0;
  right: 0;
  width: 100%;
  text-align: center;
`;

const NestedIconWrapper = styled.div`
  width: 37px;
  height: 37px;
  object-fit: contain;
  position: relative;
  margin-right: 0px !important;
  margin-left: 0px !important;
`;
const NestedIcon = styled.img`
  width: 37px;
  height: 37px;
`;

const NestedLocationMarker = () => (
  <NestedIconWrapper>
    <NestedIcon src={nestingMarker} alt="Marker" />
    <StayMarker>V</StayMarker>
  </NestedIconWrapper>
);

const LocationsTabListItem = ({ item, index, onChange, onRemove, errors, currentLocations, currentStays, canEdit, canEditOperator }) => {
  const [editNestedStay, setEditNestedStay] = useState({ open: false, body: null });
  const [filteredLocations, setFilteredLocations] = useState([]);
  const { is_wiped, editMode } = useManageTripContext();
  const { id, name, coordinates, location, on_weather, on_maps, file } = item;
  const autocompleteRef = useRef(null);
  const activeIcon = useActiveIcon(file);
  const originalIndex = useItemOriginalIndex(id, "locations", errors);
  const error = errors[originalIndex] || {};
  const hasLocationNestedStay = !!item?.nested;

  const handleRemoveLocation = () => onRemove(id);
  const handleOpenEditModal = () => {
    setEditNestedStay({ open: true, body: item });
  };
  const handleCloseEditModal = () => setEditNestedStay({ open: false, body: null });

  const handleChange = ({ target }) => {
    const { name: fieldName, value } = target;
    onChange(id, fieldName, value);
  };

  const handleChangeName = value => {
    onChange(id, "name", value);
  };

  const handleSelectLocation = optionId => {
    const options = [...currentStays, ...currentLocations];
    const selectedLocation = options.find(({ id, vamoos_id }) => (vamoos_id ? vamoos_id === optionId : id === optionId));

    if (selectedLocation?.vamoos_id) {
      const { name: nameItem, location: locationItem, latitude, longitude, operator_code, reference_code, vamoos_id } = selectedLocation;
      onChange(id, "all", {
        ...item,
        name: nameItem,
        location: locationItem || "",
        latitude,
        longitude,
        coordinates: `${latitude}, ${longitude}`,
        nested: {
          vamoos_id,
          type: "stay",
          operator_code,
          reference_code,
        },
      });
    }

    if (selectedLocation?.id) {
      const { name: nameItem, location: locationItem, latitude, longitude } = selectedLocation;
      onChange(id, "all", {
        ...item,
        name: nameItem,
        location: locationItem,
        latitude,
        longitude,
        coordinates: `${latitude}, ${longitude}`,
        nested: null,
      });
    }
  };

  const handleAttachFile = files => {
    const [newFile] = formatDisplayableLibraryPopupValues(files);
    onChange(id, "file", newFile);
  };

  const handleRemoveAttachment = () => {
    onChange(id, "file", undefined);
  };

  const coordinatesErrorMessage =
    error.latitude || error.longitude
      ? `
        ${error.latitude ? `${error.latitude}` : ""}${error.latitude && error.longitude ? ", " : ""}
        ${error.longitude ? `${error.longitude}` : ""}
      `
      : "";

  const renderFileDeleter = () => (
    <ChipWithRedirect onDelete={handleRemoveAttachment} item={file} icon={activeIcon} maxChar={15} editable={canEdit} />
  );

  const renderFileUploader = () => (
    <AttachFilesButton
      disabled={is_wiped || (editMode && !canEdit)}
      size="small"
      name="upload"
      onSelect={handleAttachFile}
      allowedFileTypes={DOCUMENTS_FILE_TYPES}
    />
  );

  const editButton = canEditOperator && (
    <SecondaryButton disabled={is_wiped || (editMode && !canEditOperator)} size="small" onClick={handleOpenEditModal}>
      <EditOutlinedIcon />
      <span>edit</span>
    </SecondaryButton>
  );

  const setMatchedLocations = (query, stays, locations) => {
    if (query) {
      const newLocations = locations.filter(locationItem => locationItem.name.toLowerCase().includes(query.toLowerCase()));
      const newStays = stays.filter(({ name: stayName }) => stayName.toLowerCase().includes(query.toLowerCase()));
      const options = [...newLocations, ...newStays].map(({ name, id, vamoos_id }) => ({
        key: id || vamoos_id,
        label: name,
        value: id || vamoos_id,
        isStay: !!vamoos_id,
      }));
      setFilteredLocations(options);
    } else setFilteredLocations([]);
  };

  const locationOrUserIdInput = hasLocationNestedStay ? (
    <>
      <Input
        name="userId"
        id={`user-id-nr${index}`}
        value={item?.nested?.operator_code}
        onChange={handleChange}
        label={LOCATIONS_LABELS.userID}
        size="small"
        disabled
      />
    </>
  ) : (
    <>
      <Input
        name="location"
        id={`location-nr${index}`}
        value={location}
        onChange={handleChange}
        label={LOCATIONS_LABELS.location}
        size="small"
        error={checkErrorExistFor("location", error)}
        helperText={error.location}
        isRequired
        disabled={is_wiped || (editMode && !canEdit)}
      />
    </>
  );

  const debouncedLocations = useRef(
    debounce((query, stays, locations) => {
      setMatchedLocations(query, stays, locations);
    }, 100),
  );
  const fileContent = file ? renderFileDeleter() : renderFileUploader();
  const rowActionButton = hasLocationNestedStay ? editButton : fileContent;

  const onCoordinatesBlur = () => {
    EventBus.dispatch(new LocationCoordinatesHasBeenChanged());
  };

  useEffect(() => {
    let cleanup = true;
    const { current } = autocompleteRef;

    if (current && item?.isNewLocation && cleanup) {
      autocompleteRef.current.focus();
      autocompleteRef.current.scrollIntoView({ behavior: "smooth" });
    }
    if (item?.isNewNestedLocation) {
      autocompleteRef.current.scrollIntoView({ behavior: "smooth" });
    }
    return () => {
      cleanup = false;
    };
  }, []);

  useEffect(() => {
    debouncedLocations.current(name, currentStays, currentLocations);
  }, [name]);

  return (
    <SortableListElement index={index} withError={!isObjectEmpty(error)} brandedBg={hasLocationNestedStay} disabled={!canEdit}>
      <SortableListElementContentWithError error={!isObjectEmpty(error) ? ERRORS.invalidData : undefined}>
        <FlexGrow grow={1}>
          <FlexContent id="flexcontent">
            <IndexItem>{index + 1}</IndexItem>
            {hasLocationNestedStay && <NestedLocationMarker />}
            <AutocompleteWrapper>
              <UnsafeAutocomplete
                options={filteredLocations}
                onOptionClick={handleSelectLocation}
                onChange={({ target }) => handleChangeName(target.value)}
                value={name}
                name={`name-nr${item.id}`}
                type="text"
                size="small"
                label={LOCATIONS_LABELS.name}
                fullWidth
                inputRef={autocompleteRef}
                disabled={hasLocationNestedStay || is_wiped || (editMode && !canEdit)}
                error={checkErrorExistFor("name", error)}
                helperText={error?.name || ""}
                isRequired
              />
            </AutocompleteWrapper>
            {locationOrUserIdInput}
            <Input
              name="coordinates"
              id={`coordinates-nr${index}`}
              value={coordinates}
              onChange={handleChange}
              labelShrink={coordinates.length > 0}
              label={LOCATIONS_LABELS.coordinates}
              size="small"
              onBlur={onCoordinatesBlur}
              error={checkErrorExistFor("latitude", error) || checkErrorExistFor("longitude", error)}
              helperText={coordinatesErrorMessage}
              disabled={hasLocationNestedStay || is_wiped || (editMode && !canEdit)}
              isRequired
            />

            <StyledFormControlLabel
              value={on_weather}
              onChange={() => onChange(id, "on_weather", !on_weather)}
              label={LOCATIONS_LABELS.weather}
              control={
                <Checkbox
                  name="weather"
                  disabled={is_wiped || (editMode && !canEdit)}
                  checked={on_weather}
                  inputProps={{ "aria-label": "weather" }}
                />
              }
            />

            <StyledFormControlLabel
              value={on_maps}
              onChange={() => onChange(id, "on_maps", !on_maps)}
              label={LOCATIONS_LABELS.maps}
              control={
                <Checkbox
                  name="maps"
                  disabled={is_wiped || (editMode && !canEdit)}
                  checked={on_maps}
                  inputProps={{ "aria-label": "maps" }}
                />
              }
            />
          </FlexContent>
        </FlexGrow>
        {rowActionButton}
        {!(is_wiped || (editMode && !canEdit)) && <RemoveButton onClick={handleRemoveLocation} />}
        <CustomiseNestedStay isOpen={editNestedStay.open} stay={editNestedStay.body} onClose={handleCloseEditModal} />
      </SortableListElementContentWithError>
    </SortableListElement>
  );
};

LocationsTabListItem.defaultProps = {
  canEdit: true,
  canEditOperator: true,
};

LocationsTabListItem.propTypes = {
  item: PropTypes.shape().isRequired,
  index: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  errors: PropTypes.arrayOf(PropTypes.object).isRequired,
  currentLocations: PropTypes.arrayOf(PropTypes.object).isRequired,
  currentStays: PropTypes.arrayOf(PropTypes.object).isRequired,
  canEdit: PropTypes.bool,
  canEditOperator: PropTypes.bool,
};

export { LocationsTabListItem, StyledFormControlLabel, AutocompleteWrapper };
