import { takeEvery, put, call } from "redux-saga/effects";

import { HttpClient } from "services/application/httpClient/httpClient";

import { STAYS_FEATURES_URL, UPDATE_ADMIN_FEATURES } from "constants/api";
import { handleErrorMessages } from "utils/store";
import { ADMIN_FEATURES_CONTENT } from "constants/content";

import { FeatureStay } from "domain/FeatureStay";
import { FeatureStayRequestDto } from "dto/FeatureStayRequestDto";

import { hideSavingCover, setNotification, showSavingCover } from "store/app/actions";

import * as actionTypes from "./actionTypes";
import {
  toggleFeatureActiveStatusSuccess,
  toggleFeatureActiveStatusFail,
  getFeaturesStaysSuccess,
  getFeaturesStaysFail,
  saveStayFeatureFail,
  saveStayFeatureSuccess,
} from "./actions";

async function updateFeatureStay(feature) {
  const featureDto = FeatureStayRequestDto(feature);
  await HttpClient.post(UPDATE_ADMIN_FEATURES, featureDto);
}

const setFeatureStaysList = (items = []) => {
  const itemsDomain = {};
  items.forEach(stayDto => {
    const stay = FeatureStay(stayDto);
    itemsDomain[stay.id] = stay;
  });

  return itemsDomain;
};

function* getFeatures() {
  try {
    const response = yield HttpClient.get(STAYS_FEATURES_URL);
    const { items } = response.data;

    if (items) {
      const itemsDomain = setFeatureStaysList(items);

      yield put(getFeaturesStaysSuccess(itemsDomain));
    }
  } catch (e) {
    yield put(getFeaturesStaysFail(handleErrorMessages(e)));
  }
}

function* toggleFeatureActiveStatus({ payload }) {
  try {
    const data = FeatureStayRequestDto(payload);
    const response = yield HttpClient.post(UPDATE_ADMIN_FEATURES, data);

    if (response?.data) {
      yield put(toggleFeatureActiveStatusSuccess(payload));
      yield put(setNotification({ type: "success", message: ADMIN_FEATURES_CONTENT.notifications.toggleStatusSuccess }));
    }
  } catch (e) {
    yield put(toggleFeatureActiveStatusFail(handleErrorMessages(e)));
    yield put(setNotification({ type: "error", message: ADMIN_FEATURES_CONTENT.notifications.toggleStatusFailed }));
  }
}

function* saveStayFeaturesChanges({ payload }) {
  try {
    yield put(showSavingCover());
    yield Promise.all(payload.map(stayFeature => updateFeatureStay(stayFeature)));
    yield call(getFeatures);
    yield put(saveStayFeatureSuccess());
    yield put(setNotification({ type: "success", message: ADMIN_FEATURES_CONTENT.notifications.updateSuccess }));
    yield put(hideSavingCover());
  } catch (e) {
    const errors = handleErrorMessages(e);
    yield put(saveStayFeatureFail(errors));
    yield put(setNotification({ type: "error", message: ADMIN_FEATURES_CONTENT.notifications.updateError }));
    yield put(hideSavingCover());
  }
}

export function* adminFeaturesSaga() {
  yield takeEvery(actionTypes.GET_FEATURES_STAYS_START, getFeatures);
  yield takeEvery(actionTypes.TOGGLE_FEATURE_ACTIVE_STATUS_START, toggleFeatureActiveStatus);
  yield takeEvery(actionTypes.SAVE_FEATURE_STAYS_START, saveStayFeaturesChanges);
}
