import { reducer, setState, globalReducerActions } from "utils/store";
import { DELETE_LAST_LOCK, SET_LAST_LOCK } from "./actionTypes";

const locksInitital = {
  lastLock: {},
};

const ReducerActions = globalReducerActions("locks", locksInitital, {
  [SET_LAST_LOCK](state, payload) {
    const { vamoos_id, timestamp, userId } = payload;

    return setState(state, {
      lastLock: {
        vamoos_id,
        timestamp,
        userId,
      },
    });
  },
  [DELETE_LAST_LOCK](state) {
    return setState(state, {
      lastLock: {},
    });
  },
});

const lockReducer = (state = locksInitital, action) => reducer(state, action, ReducerActions);

export { lockReducer };
