import React, { useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import MuiTable from "@material-ui/core/Table";

import { TableContext } from "components/ui/Tables/TableContext";

import config from "config/app";
import { saveResultPerPage } from "utils/saveTableSettings";
import { deviceType } from "../../../utils/deviceDetect";

const TableWrapper = styled.div`
  border-radius: ${({ theme }) => theme.setSpacing(2)}px;
  overflow: auto;
  position: relative;

  .MuiTableCell-head {
    font-size: ${({ theme }) => theme.fonts.fontSize.body3}px;
    color: ${({ theme }) => theme.colors.grey50};
  }
`;

const TableContent = ({
  children,
  list,
  count,
  url,
  urlParams,
  actions,
  headers,
  onRowClick,
  onChangeOrder,
  onChangePage,
  onChangeRowsPerPage,
  ...rest
}) => {
  const { tables } = config;

  const rowsPerPageOptions = tables.rowsPerPage;
  const [rowsPerPage, setRowsPerPage] = useState(tables.defaultNumberOfRows);
  const [page, setPage] = useState(0);

  const device = deviceType();

  const columnsData = Array.isArray(headers) ? headers : headers[device] || headers[headers.defaultDevice] || headers.all;
  const columns = columnsData?.filter(header => header.show).map(header => header.key);

  const [sortingBy, setSortingBy] = useState(null);

  const [sortingOrder, setSortingOrder] = useState("asc");

  const resolveSortingOrder = sortingOrder === "asc" ? "desc" : "asc";

  const handleChangeRowsPerPage = (value, tableId) => {
    const newValue = +value;
    setRowsPerPage(newValue);
    setPage(0);
    onChangeRowsPerPage(newValue);
    saveResultPerPage(newValue, tableId);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    onChangePage(newPage);
  };

  const handleChangeOrder = (columnKey, tableId, exisitingOrder) => {
    setSortingBy(columnKey);
    let order = "";
    if (exisitingOrder) {
      order = exisitingOrder;
    } else {
      order = sortingBy === columnKey ? resolveSortingOrder : "asc";
    }
    setSortingOrder(order);
    onChangeOrder({ column: columnKey, order }, tableId);
  };

  const context = {
    url,
    urlParams,
    actions,
    headers,
    columns,
    list,
    handleChangeRowsPerPage,
    handleChangePage,
    handleChangeOrder,
    rowsPerPage,
    page,
    count,
    rowsPerPageOptions,
    onRowClick,
    sortingBy,
    setSortingBy,
    sortingOrder,
    setSortingOrder,
  };

  return (
    <TableWrapper>
      <MuiTable {...rest}>
        <TableContext.Provider value={context}>{children}</TableContext.Provider>
      </MuiTable>
    </TableWrapper>
  );
};

TableContent.defaultProps = {
  url: null,
  urlParams: null,
  actions: null,
  count: 0,
  onRowClick: () => {},
  onChangeOrder: () => {},
  onChangePage: () => {},
  onChangeRowsPerPage: () => {},
};

TableContent.propTypes = {
  list: PropTypes.arrayOf(PropTypes.object).isRequired,
  headers: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.array, PropTypes.element]).isRequired,
  urlParams: PropTypes.arrayOf(PropTypes.string),
  actions: PropTypes.oneOfType([PropTypes.func, PropTypes.element]),
  count: PropTypes.number,
  url: PropTypes.string,
  onRowClick: PropTypes.func,
  onChangeOrder: PropTypes.func,
  onChangePage: PropTypes.func,
  onChangeRowsPerPage: PropTypes.func,
};

export { TableContent, TableWrapper };
