// @ts-check

import { FeatureStay } from "./FeatureStay";
import { FileWrapper } from "./FileWrapper";
import { LocationItnierary } from "./LocationItinerary";
import { NotificationItineraty } from "./NotificationItinerary";
import { PoisItnierary } from "./PoisItinerary";

/**
 * @param {import("types/dto").StayItineraryResponseDto} itineraryDto  feature stay object
 * @returns {StayItinerary} Feature stay item
 */

export function StayItinerary(itineraryDto) {
  /**
   * @type {StayItinerary}
   */
  const domain = {};

  domain.id = itineraryDto.id;
  domain.isActive = itineraryDto.is_active;
  domain.background = itineraryDto.background && FileWrapper(itineraryDto.background);
  domain.clientReference = itineraryDto.client_reference;
  domain.createdAt = itineraryDto.created_at;
  domain.departureDate = itineraryDto.departure_date;
  domain.details = itineraryDto.details; // todo: add mapper
  domain.directories = itineraryDto.directories; // todo: add mapper
  domain.documents = itineraryDto.documents; // todo: add mapper
  domain.downloads = itineraryDto.downloads;
  domain.downloadsLast30 = itineraryDto.downloads_last30;
  domain.features = itineraryDto?.features?.length ? itineraryDto?.features?.map(feature => FeatureStay(feature)) : [];
  domain.field1 = itineraryDto.field1;
  domain.field2 = itineraryDto.field2;
  domain.field3 = itineraryDto.field3;
  domain.field4 = itineraryDto.field4;
  domain.flights = itineraryDto.flights; // todo: add mapper
  domain.id = itineraryDto.id;
  domain.isActive = itineraryDto.is_active;
  domain.isCurrentVersion = itineraryDto.is_current_version;
  domain.isListed = itineraryDto.is_listed;
  domain.isPublic = itineraryDto.is_public;
  domain.isWiped = itineraryDto.is_wiped;
  domain.locations = itineraryDto?.locations?.length ? itineraryDto.locations.map(location => LocationItnierary(location)) : [];
  domain.meta = itineraryDto.meta;
  domain.notifications = itineraryDto?.notifications?.length
    ? itineraryDto.notifications.map(notification => NotificationItineraty(notification))
    : [];
  domain.operatorCode = itineraryDto.operator_code;
  domain.operatorId = itineraryDto.operator_id;
  domain.operatorName = itineraryDto.operator_name;
  domain.originalCreatedAt = itineraryDto.original_created_at;
  domain.passcodeGroups = itineraryDto.passcode_groups;
  domain.pois = itineraryDto?.pois?.length ? itineraryDto.pois.map(poi => PoisItnierary(poi)) : [];
  domain.referenceCode = itineraryDto.reference_code;
  domain.returnDate = itineraryDto.return_date;
  domain.source = itineraryDto.source;
  domain.travellers = itineraryDto.travellers; // todo: add mapper;
  domain.type = itineraryDto.type;
  domain.updatedAt = itineraryDto.updated_at;
  domain.vamoosId = itineraryDto.vamoos_id;
  domain.version = itineraryDto.version;
  domain.startTime = itineraryDto.start_time;
  domain.timezone = itineraryDto.timezone;
  domain.leadTraveller = itineraryDto.lead_traveller;
  domain.copyOfId = itineraryDto.copy_of_id;
  domain.inspiration = itineraryDto.inspiration;
  domain.logo = itineraryDto.logo && FileWrapper(itineraryDto.logo);
  domain.deactivatedAt = itineraryDto.deactivated_at;
  domain.deactivatedBy = itineraryDto.deactivated_by;

  return domain;
}
