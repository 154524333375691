import * as actionTypes from "store/adminOperators/actionTypes";
import { createAction } from "utils/store";

export const getAdminOperatorsStart = createAction(actionTypes.GET_ADMIN_OPERATORS_START);
export const getAdminOperatorsSuccess = createAction(actionTypes.GET_ADMIN_OPERATORS_SUCCESS);
export const getAdminOperatorsFail = createAction(actionTypes.GET_ADMIN_OPERATORS_FAIL);

export const operatorActiveStatusToggleStart = createAction(actionTypes.OPERATOR_ACTIVE_STATUS_TOGGLE_START);
export const operatorActiveStatusToggleSuccess = createAction(actionTypes.OPERATOR_ACTIVE_STATUS_TOGGLE_SUCCESS);
export const operatorActiveStatusToggleFail = createAction(actionTypes.OPERATOR_ACTIVE_STATUS_TOGGLE_FAIL);

export const setCurrentAdminOperatorSelect = createAction(actionTypes.SET_CURRENT_ADMIN_OPERATOR_CODE_SELECTED);
