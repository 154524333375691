import React from "react";
import { useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import ListAltOutlinedIcon from "@material-ui/icons/ListAltOutlined";
import AssessmentOutlinedIcon from "@material-ui/icons/AssessmentOutlined";
import styled from "styled-components";

import { ContextNavigationList } from "components/ui/Lists/ContextNavigationList";

import { ReactComponent as CustomApp } from "assets/images/custom-apps.svg";

import { OPERATOR_DETAILS_NAVIGATION } from "constants/content";
import {
  ADMIN_OPERATOR_DETAILS_GENERAL_PATH,
  ADMIN_OPERATOR_DETAILS_STATISTICS_PATH,
  ADMIN_OPERATOR_DETAILS_CUSTOM_APPS_PATH,
} from "constants/routes";

import { checkSectionHasError } from "feature/panel/_shared/helpers";
import { setUrl } from "utils/url";

import { GENERAL_SECTION_ERROR_NAMES } from "./constants";

const StyledCustomApp = styled(CustomApp)`
  && {
    ${({ theme, isActive }) =>
      isActive
        ? `
          rect {
            stroke: ${theme.colors.brand};
          }
          path {
            fill: ${theme.colors.brand};
          }
    `
        : ""}
  }
`;

const Navigation = () => {
  const { id } = useParams();

  const location = useLocation();

  const errors = useSelector(state => state.adminOperators.errors);

  const isActive = url => url === location.pathname;

  const generalPagePath = setUrl(ADMIN_OPERATOR_DETAILS_GENERAL_PATH, { id });
  const statisticsPagePath = setUrl(ADMIN_OPERATOR_DETAILS_STATISTICS_PATH, { id });
  const customAppsPagePath = setUrl(ADMIN_OPERATOR_DETAILS_CUSTOM_APPS_PATH, { id });

  const list = [
    {
      label: OPERATOR_DETAILS_NAVIGATION.general,
      icon: <ListAltOutlinedIcon />,
      isActive: isActive(generalPagePath),
      hasError: checkSectionHasError(GENERAL_SECTION_ERROR_NAMES, errors),
      replace: true,
      url: {
        pathname: generalPagePath,
        state: {
          previousUrl: location.state?.previousUrl,
        },
      },
    },
    {
      label: OPERATOR_DETAILS_NAVIGATION.stats,
      icon: <AssessmentOutlinedIcon />,
      isActive: isActive(statisticsPagePath),
      hasError: !!errors?.statistics,
      replace: true,
      url: {
        pathname: statisticsPagePath,
        state: {
          previousUrl: location.state?.previousUrl,
        },
      },
    },
    {
      label: OPERATOR_DETAILS_NAVIGATION.customApps,
      icon: <StyledCustomApp isActive={isActive(customAppsPagePath)} />,
      isActive: isActive(customAppsPagePath),
      hasError: !!errors?.statistics,
      replace: true,
      url: {
        pathname: customAppsPagePath,
        state: {
          previousUrl: location.state?.previousUrl,
        },
      },
    },
  ];
  return <ContextNavigationList list={list} />;
};

export { Navigation };
