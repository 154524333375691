import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";

import { Input } from "components/ui/Forms";

import { SortableListElement, RemoveButton } from "components/_shared/SortableListElement/SortableListElement";
import { SortableListElementContentWithError } from "components/_shared/SortableListElementContentWithError/SortableListElementContentWithError";

import { useItemOriginalIndex } from "hooks/useItemOriginalIndex";

import { checkErrorExistFor } from "utils/validation";
import { isObjectEmpty } from "utils/object";

import { ERRORS } from "constants/content";
import { StyledTextInput } from "feature/panel/_shared/Messaging/Components/SendMessageBar";
import { StyledTextarea } from "../Storyboard/Entries/EntryForm/EntryDetailsForm";

const PersonItem = ({ person, index, onPersonChange, onPersonDelete, errors, disabled }) => {
  const personRef = useRef(null);
  const { id, name } = person;
  const originalIndex = useItemOriginalIndex(id, "travelPeople", errors);
  const error = errors[originalIndex] || {};

  const handleChangePerson = ({ target }) => {
    const { value } = target;
    const newPerson = { ...person, name: value };
    onPersonChange(newPerson);
  };

  const handleDeletePerson = () => {
    onPersonDelete(id);
  };

  useEffect(() => {
    if (!personRef.current.value) {
      personRef.current.focus();
      personRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  return (
    <SortableListElement disabled={disabled} index={index} key={id} withError={!isObjectEmpty(error)}>
      <SortableListElementContentWithError error={!isObjectEmpty(error) ? ERRORS.invalidData : undefined}>
        {/* <Input
          label="Full name"
          type="text"
          value={name}
          onChange={handleChangePerson}
          name={`name-${id}`}
          size="small"
          inputRef={personRef}
          error={checkErrorExistFor("name", error)}
          helperText={error.name}
          disabled={disabled}
        /> */}
        <StyledTextarea label="Full name" value={name} nextInputRef={personRef} onChange={handleChangePerson} />
        {!disabled && <RemoveButton onClick={handleDeletePerson} />}
      </SortableListElementContentWithError>
    </SortableListElement>
  );
};

PersonItem.propTypes = {
  person: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string,
  }).isRequired,
  index: PropTypes.number.isRequired,
  onPersonChange: PropTypes.func.isRequired,
  onPersonDelete: PropTypes.func.isRequired,
  errors: PropTypes.shape().isRequired,
  disabled: PropTypes.bool.isRequired,
};

export { PersonItem };
