export const SET_STAY_WIZARD_VALUE = "SET_STAY_WIZARD_VALUE";

export const SET_STAY_WIZARD_TOUCHED = "SET_STAY_WIZARD_TOUCHED";

export const STAY_WIZARD_SET_ERRORS = "STAY_WIZARD_SET_ERRORS";

export const STAY_WIZARD_SAVE_START = "STAY_WIZARD_SAVE_START";
export const STAY_WIZARD_SAVE_FAIL = "STAY_WIZARD_SAVE_FAIL";

export const ENABLE_STAY_VARIANTS = "ENABLE_STAY_VARIANTS";
export const DISABLE_STAY_VARIANTS = "DISABLE_STAY_VARIANTS";
export const SET_DEFAULT_STAY_VARIANT = "SET_DEFAULT_STAY_VARIANT";

export const SET_STAY_WIZARD_PROPERTY_NAME = "SET_STAY_WIZARD_PROPERTY_NAME";
export const SET_STAY_WIZARD_USER_ID = "SET_STAY_WIZARD_USER_ID";
export const INIT_STAY_WIZARD_USER_ID = "INIT_STAY_WIZARD_USER_ID";
export const SET_STAY_WIZARD_HOME_DESCRIPTION = "SET_STAY_WIZARD_HOME_DESCRIPTION";
export const SET_STAY_WIZARD_PHONE = "SET_STAY_WIZARD_PHONE";
export const SET_STAY_WIZARD_EMAIL = "SET_STAY_WIZARD_EMAIL";
export const SET_STAY_WIZARD_WEBSITE = "SET_STAY_WIZARD_WEBSITE";
export const SET_STAY_WIZARD_TWITTER = "SET_STAY_WIZARD_TWITTER";
export const SET_STAY_WIZARD_FACEBOOK = "SET_STAY_WIZARD_FACEBOOK";
export const SET_STAY_WIZARD_INSTAGRAM = "SET_STAY_WIZARD_INSTAGRAM";
export const STAY_WIZARD_SET_CHECK_IN_TIME = "STAY_WIZARD_SET_CHECK_IN_TIME";

export const TOGGLE_STAY_WIZARD_REQUIRED_PERSONAL_DETAILS = "TOGGLE_STAY_WIZARD_REQUIRED_PERSONAL_DETAILS";

export const SET_STAY_WIZARD_ADDRESS = "SET_STAY_WIZARD_ADDRESS";
export const SET_STAY_WIZARD_COORDINATES = "SET_STAY_WIZARD_COORDINATES";

export const SET_STAY_WIZARD_INFO_BUTTON_LABEL = "SET_STAY_WIZARD_INFO_BUTTON_LABEL";
export const SET_STAY_WIZARD_INFO_BUTTON_ICON = "SET_STAY_WIZARD_INFO_BUTTON_ICON";

export const SET_STAY_WIZARD_SHORT_DESCRIPTION = "SET_STAY_WIZARD_SHORT_DESCRIPTION";
export const SET_STAY_WIZARD_LONG_DESCRIPTION = "SET_STAY_WIZARD_LONG_DESCRIPTION";
export const SET_STAY_WIZARD_NUMBER_OF_ROOMS = "SET_STAY_WIZARD_NUMBER_OF_ROOMS";

export const SET_STAY_WIZARD_BRANDING_PROFILE_ID = "SET_STAY_WIZARD_BRANDING_PROFILE_ID";

export const SET_STAY_WIZARD_LOGO = "SET_STAY_WIZARD_LOGO";
export const SET_STAY_WIZARD_BACKGROUND = "SET_STAY_WIZARD_BACKGROUND";

export const SET_STAY_WIZARD_FEATURES = "SET_STAY_WIZARD_FEATURES";

export const SET_STAY_WIZARD_ACTION_TITLE = "SET_STAY_WIZARD_ACTION_TITLE";
export const SET_STAY_WIZARD_ACTION_ICON = "SET_STAY_WIZARD_ACTION_ICON";
export const SET_STAY_WIZARD_DEFAULT_ACTION_ICON = "SET_STAY_WIZARD_DEFAULT_ACTION_ICON";
export const SET_STAY_WIZARD_ACTION_FILE = "SET_STAY_WIZARD_ACTION_FILE";
export const SET_STAY_WIZARD_BOOK_NOW_TITLE = "SET_STAY_WIZARD_BOOK_NOW_TITLE";
export const SET_STAY_WIZARD_BOOK_NOW_ICON = "SET_STAY_WIZARD_BOOK_NOW_ICON";
export const SET_STAY_WIZARD_DEFAULT_BOOK_NOW_ICON = "SET_STAY_WIZARD_DEFAULT_BOOK_NOW_ICON";
export const SET_STAY_WIZARD_BOOK_NOW_FILE = "SET_STAY_WIZARD_BOOK_NOW_FILE";

export const STAY_WIZARD_ADD_GALLERY_ITEM = "STAY_WIZARD_ADD_GALLERY_ITEM";
export const STAY_WIZARD_UPDATE_GALLERY_ITEM_TITLE = "STAY_WIZARD_UPDATE_GALLERY_ITEM_TITLE";
export const STAY_WIZARD_UPDATE_GALLERY_PICTURES = "STAY_WIZARD_UPDATE_GALLERY_PICTURES";
export const STAY_WIZARD_SORT_GALLERY = "STAY_WIZARD_SORT_GALLERY";
export const STAY_WIZARD_REMOVE_GALLERY_ITEM = "STAY_WIZARD_REMOVE_GALLERY_ITEM";
export const STAY_WIZARD_REMOVE_GALLERY_ITEM_PICTURE = "STAY_WIZARD_REMOVE_GALLERY_ITEM_PICTURE";

export const STAY_WIZARD_SET_DIRECTORIES_RESTRICTION = "STAY_WIZARD_SET_DIRECTORIES_RESTRICTION";
export const STAY_WIZARD_SORT_DIRECTORIES = "STAY_WIZARD_SORT_DIRECTORIES";
export const STAY_WIZARD_ADD_DIRECTORIES_ITEM = "STAY_WIZARD_ADD_DIRECTORIES_ITEM";
export const STAY_WIZARD_REMOVE_DIRECTORIES_ITEM = "STAY_WIZARD_REMOVE_DIRECTORIES_ITEM";
export const STAY_WIZARD_EDIT_DIRECTORIES_ITEM = "STAY_WIZARD_EDIT_DIRECTORIES_ITEM";

export const STAY_WIZARD_SET_DAILY_ACTIVITIES_RESTRICTION = "STAY_WIZARD_SET_DAILY_ACTIVITIES_RESTRICTION";
export const STAY_WIZARD_SORT_DAILY_ACTIVITIES = "STAY_WIZARD_SORT_DAILY_ACTIVITIES";
export const STAY_WIZARD_ADD_DAILY_ACTIVITIES_ITEM = "STAY_WIZARD_ADD_DAILY_ACTIVITIES_ITEM";
export const STAY_WIZARD_REMOVE_DAILY_ACTIVITIES_ITEM = "STAY_WIZARD_REMOVE_DAILY_ACTIVITIES_ITEM";
export const STAY_WIZARD_EDIT_DAILY_ACTIVITIES_ITEM = "STAY_WIZARD_EDIT_DAILY_ACTIVITIES_ITEM";

export const STAY_WIZARD_SET_VOUCHERS_RESTRICTION = "STAY_WIZARD_SET_VOUCHERS_RESTRICTION";
export const STAY_WIZARD_SORT_VOUCHERS = "STAY_WIZARD_SORT_VOUCHERS";
export const STAY_WIZARD_ADD_VOUCHERS_ITEM = "STAY_WIZARD_ADD_VOUCHERS_ITEM";
export const STAY_WIZARD_REMOVE_VOUCHERS_ITEM = "STAY_WIZARD_REMOVE_VOUCHERS_ITEM";
export const STAY_WIZARD_EDIT_VOUCHERS_ITEM = "STAY_WIZARD_EDIT_VOUCHERS_ITEM";
export const STAY_WIZARD_TOGGLE_STATUS_VOUCHERS_ITEM = "STAY_WIZARD_TOGGLE_STATUS_VOUCHERS_ITEM";

export const STAY_WIZARD_SORT_PASSCODES = "STAY_WIZARD_SORT_PASSCODES";
export const STAY_WIZARD_ADD_PASSCODES_ITEM = "STAY_WIZARD_ADD_PASSCODES_ITEM";
export const STAY_WIZARD_REMOVE_PASSCODES_ITEM = "STAY_WIZARD_REMOVE_PASSCODES_ITEM";
export const STAY_WIZARD_EDIT_PASSCODES_ITEM = "STAY_WIZARD_EDIT_PASSCODES_ITEM";

export const STAY_WIZARD_SET_INSPIRATION = "STAY_WIZARD_SET_INSPIRATION";
export const STAY_WIZARD_SET_INSPIRATION_NOTIFICATION_CONTENT = "STAY_WIZARD_SET_INSPIRATION_NOTIFICATION_CONTENT";
export const STAY_WIZARD_SET_INSPIRATION_NOTIFICATION_PERIOD = "STAY_WIZARD_SET_INSPIRATION_NOTIFICATION_PERIOD";

export const STAY_WIZARD_ENABLE_POI = "STAY_WIZARD_ENABLE_POI";
export const STAY_WIZARD_DISABLE_POI = "STAY_WIZARD_DISABLE_POI";

export const STAY_WIZARD_REQUEST_CONNECT_ON = "STAY_WIZARD_REQUEST_CONNECT_ON";
export const STAY_WIZARD_REQUEST_CONNECT_OFF = "STAY_WIZARD_REQUEST_CONNECT_OFF";

export const STAY_WIZARD_TOGGLE_DND = "STAY_WIZARD_TOGGLE_DND";
export const STAY_WIZARD_CHANGE_DND_EMAIL = "STAY_WIZARD_CHANGE_DND_EMAIL";
export const STAY_WIZARD_CHANGE_DND_TIME = "STAY_WIZARD_CHANGE_DND_TIME";

export const STAY_WIZARD_SET_NOTIFICATIONS = "STAY_WIZARD_SET_NOTIFICATIONS";
export const STAY_WIZARD_INIT_NOTIFICATIONS = "STAY_WIZARD_INIT_NOTIFICATIONS";

export const STAY_WIZARD_MESSAGING_SET_EMAILS = "STAY_WIZARD_MESSAGING_SET_EMAILS";
export const STAY_WIZARD_MESSAGING_SET_DATES_BEFORE = "STAY_WIZARD_MESSAGING_SET_DATES_BEFORE";
export const STAY_WIZARD_MESSAGING_SET_DATES_AFTER = "STAY_WIZARD_MESSAGING_SET_DATES_AFTER";

export const STAY_WIZARD_ALERT_EMAILS_SET_EMAILS = "STAY_WIZARD_ALERT_EMAILS_SET_EMAILS";
export const STAY_WIZARD_ALERT_EMAILS_TOGGLE_INCLUDE = "STAY_WIZARD_ALERT_EMAILS_TOGGLE_INCLUDE";

export const FILL_STAY_WIZARD = "FILL_STAY_WIZARD";

export const FILL_DEFAULT_VARIANT_WIZARD = "FILL_DEFAULT_VARIANT_WIZARD";

export const CLEAR_STAY_WIZARD = "CLEAR_STAY_WIZARD";