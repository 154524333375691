import React from "react";
import PropTypes from "prop-types";
import AttachmentOutlinedIcon from "@material-ui/icons/AttachmentOutlined";
import styled from "styled-components";

import { SecondaryButton } from "components/ui/Buttons/SecondaryButton";
import { WordBreakLabel } from "components/ui/Labels/WordBreakLabel";
import { LibraryPopup } from "components/ui/LibraryPopup/LibraryPopup";
import { CaptionText } from "components/ui/Typography/Typography";

import { DOCUMENTS_FILE_TYPES, IMAGES_MIME_TYPES } from "constants/defaults";
import { GLOBAL_CONTENT } from "constants/content";
import { ErrorText } from "../Inputs/TextInput/style";

const ErrorMessage = styled(CaptionText)`
  && {
    margin-top: 5px;
  }
`;

const AttachFilesButton = ({
  multiUpload,
  onSelect,
  allowedFileTypes,
  tabs,
  error,
  label,
  maxResolution,
  minResolution,
  onlyResolution,
  disabled,
  returnEncoded,
  upload,
  maxFileSize,
  hint,
  ...rest
}) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const handleOpenLibraryPopup = () => {
    setIsOpen(true);
  };

  const handleCloseLibraryPopup = () => {
    setIsOpen(false);
  };

  const isAnythingExceptImages = !allowedFileTypes.find(item => item.split("/")[0] !== "image");

  return (
    <div>
      <LibraryPopup
        open={isOpen}
        multiUpload={multiUpload}
        onCancel={handleCloseLibraryPopup}
        onSelect={onSelect}
        allowedFileTypes={allowedFileTypes}
        maxResolution={maxResolution}
        minResolution={minResolution}
        onlyResolution={onlyResolution}
        tabs={tabs}
        returnEncoded={returnEncoded}
        upload={upload}
        maxFileSize={maxFileSize}
      />
      <SecondaryButton
        component="span"
        cv={error ? "error" : "brand"}
        role="button"
        onClick={handleOpenLibraryPopup}
        disabled={disabled}
        {...rest}
      >
        <AttachmentOutlinedIcon />
        {label || (
          <WordBreakLabel
            preBreakText={isAnythingExceptImages ? GLOBAL_CONTENT.addImage : GLOBAL_CONTENT.attachLink}
            postBreakText={` / ${GLOBAL_CONTENT.link}`}
          />
        )}
      </SecondaryButton>
      {hint && <ErrorText style={{ color: "#bbb" }}>{hint}</ErrorText>}
      {error && <ErrorMessage cv="error">{error}</ErrorMessage>}
    </div>
  );
};

AttachFilesButton.defaultProps = {
  multiUpload: false,
  disabled: false,
  allowedFileTypes: IMAGES_MIME_TYPES.concat(DOCUMENTS_FILE_TYPES),
  tabs: ["library", "files", "webUrl"],
  error: null,
  label: null,
  maxResolution: null,
  returnEncoded: true,
  upload: true,
};

AttachFilesButton.propTypes = {
  multiUpload: PropTypes.bool,
  disabled: PropTypes.bool,
  onSelect: PropTypes.func.isRequired,
  tabs: PropTypes.arrayOf(PropTypes.oneOf(["library", "files", "webUrl"])),
  allowedFileTypes: PropTypes.arrayOf(PropTypes.string),
  error: PropTypes.string,
  label: PropTypes.string,
  maxResolution: PropTypes.string,
  returnEncoded: PropTypes.bool,
  upload: PropTypes.bool,
};

export { AttachFilesButton };
