import { createAction } from "utils/store";

import * as actionTypes from "./actionTypes";

export const getConnectStaysStart = createAction(actionTypes.GET_CONNECT_STAYS_START);
export const getConnectStaysSuccess = createAction(actionTypes.GET_CONNECT_STAYS_SUCCESS);
export const getConnectStaysFail = createAction(actionTypes.GET_CONNECT_STAYS_FAIL);

export const toggleConnectStayStart = createAction(actionTypes.TOGGLE_CONNECT_STAY_START);
export const toggleConnectStaySuccess = createAction(actionTypes.TOGGLE_CONNECT_STAY_SUCCESS);
export const toggleConnectStayFail = createAction(actionTypes.TOGGLE_CONNECT_STAY_FAIL);
