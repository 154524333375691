import { validateBackground, validateStoryboard, validateNotifications, validateMessaging } from "utils/validators/sharedValidators";
import { isObjectEmpty } from "utils/object";

export const itineraryValidator = payload => {
  const { details, background, notifications, meta, type } = payload;

  let result = {};
  result = validateBackground(background);

  if (details) {
    const storyboardResult = validateStoryboard(details, type, meta.show_days);
    if (!isObjectEmpty(storyboardResult)) result = { ...result, details: storyboardResult };
  }

  if (notifications) {
    const notificationsResult = validateNotifications(notifications);
    if (!isObjectEmpty(notificationsResult)) result = { ...result, notifications: notificationsResult };
  }

  if (meta && meta.messaging_emails_list && meta.messaging_emails_list.length > 0) {
    const messagingResult = validateMessaging(meta.messaging_emails_list);

    if (!isObjectEmpty(messagingResult)) result = { ...result, messaging: messagingResult };
  }

  return result;
};
