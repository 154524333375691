import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Grid from "@material-ui/core/Grid";
import { LeadText } from "components/ui/Typography/Typography";
import { IconField } from "feature/panel/Pois/IconManager/IconField";

const ContentSection = styled.div`
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey10};
  padding-bottom: ${({ theme }) => theme.setSpacing(10)}px;
  margin-bottom: ${({ theme }) => theme.setSpacing(10)}px;
`;

const DefaultIconSection = ({ data, onChange }) => (
  <ContentSection>
    <Grid container justifyContent="center">
      <Grid item xs={12} md={10}>
        <LeadText>Default Icon</LeadText>
        <IconField icon={data} onChange={onChange} />
      </Grid>
    </Grid>
  </ContentSection>
);

DefaultIconSection.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    usageCount: PropTypes.number.isRequired,
    url: PropTypes.string.isRequired,
    localised_name: PropTypes.string,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
};

export { DefaultIconSection, ContentSection };
