import React from "react";
import PropTypes from "prop-types";
import { Paper, ContentWrapper } from "feature/panel/Help/styledComponents";
import { BodyText2 } from "components/ui/Typography/Typography";
import { TermsCategory } from "feature/panel/Help/Terms/TermsCategory";
import { TERMS } from "constants/content";
import { Content } from "components/ui/Content";

const {
  categories,
  contactInfo
} = TERMS;

const Terms = ({ component: Component }) => (
  <Component>
    <ContentWrapper>
      <Content margin={10}>
        {categories.map(category => (
          <TermsCategory
            key={category.header}
            header={category.header}
            explanations={category.explanations}
            terms={category.terms}
          />
        ))}
      </Content>
      <BodyText2>{contactInfo}</BodyText2>
    </ContentWrapper>
  </Component>
  );

Terms.defaultProps = {
  component: Paper
};

Terms.propTypes = {
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.object])
};

export { Terms };
