import { useContext } from "react";
import { useNavigate, matchPath } from "react-router-dom";

import { PromptContext } from "components/ui/CustomPrompt/CustomPrompt";

const useExtendedNavigate = () => {
  const navigate = useNavigate();
  const { showPrompt, enabled, allowed } = useContext(PromptContext);

  return (to, options) => {
    if(enabled && !allowed.some(path => matchPath(path, to))) {
      showPrompt({
        to,
        ...options,
      });
    } else {
      navigate(to, options);
    }
  }
}

export {
  useExtendedNavigate,
}