import React, { useEffect } from "react";
import { useLocation, useParams, Outlet } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { SaveOutlined } from "@material-ui/icons";

import { PrimaryButton, BackHistoryButton, CancelHistoryButton } from "components/ui/Buttons";
import { AdminTemplate } from "components/templates/Admin/AdminTemplate";
import { ContextBarTitle } from "components/templates/Panel/ContextBarTitle";
import { Spinner } from "components/ui/Spinner/Spinner";

import { Navigation } from "feature/admin/Operators/Details/Navigation";
import {
  getOperatorDetailsStart,
  setOperatorDetailsFormTouched,
  updateAdminOperatorsDetailsStart,
} from "store/adminOperatorDetails/actions";
import { UPDATE_ADMIN_OPERATOR_DETAILS_SUCCESS } from "store/adminOperatorDetails/actionTypes";

import { GLOBAL_CONTENT, OPERATOR_TYPE, PAGE_TITLE_LABEL, SITE_LEAVE_WARNING_MESSAGES } from "constants/content";

import { tryRouteChangeStart } from "store/app/actions";
import {
  ADMIN_OPERATORS_PATH,
  ADMIN_OPERATOR_DETAILS_CUSTOM_APPS_PATH,
  ADMIN_OPERATOR_DETAILS_GENERAL_PATH,
  ADMIN_OPERATOR_DETAILS_STATISTICS_PATH,
} from "constants/routes";

import { usePermissionsService } from "hooks/usePermissionsService";
import { PERMISSIONS } from "constants/permissions";
import { usePrompt } from "components/ui/CustomPrompt/CustomPrompt";
import { useExtendedNavigate } from "hooks/useExtendedNavigate";

const OperatorsDetails = () => {
  const permissionsService = usePermissionsService();
  const navigate = useExtendedNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const isPageReadOnly = queryParams.get("readOnly") !== null;
  const isFormTouched = useSelector(state => state.adminOperatorDetails.isFormTouched === true);
  const actionType = useSelector(state => state.adminOperatorDetails.actionType);
  const details = useSelector(state => state.adminOperatorDetails.details);
  const operators = useSelector(state => state.auth.operators);
  const currentOperatorCode = useSelector(state => state.auth.currentOperatorCode);
  const title = (
    <span>
      {PAGE_TITLE_LABEL.editOperator}
      <strong>{details?.name}</strong>
    </span>
  );
  const showUserSpinner = false;

  const disabledSave = !isFormTouched;

  usePrompt(SITE_LEAVE_WARNING_MESSAGES.createTitle(SITE_LEAVE_WARNING_MESSAGES.contexts.vamoosEditor), isFormTouched, [
    ADMIN_OPERATOR_DETAILS_GENERAL_PATH,
    ADMIN_OPERATOR_DETAILS_STATISTICS_PATH,
    ADMIN_OPERATOR_DETAILS_CUSTOM_APPS_PATH
  ]);

  const { id } = useParams();

  const getOperatorData = () => {
    const isAdmin = operators?.find(operator => operator.code === currentOperatorCode)?.type === OPERATOR_TYPE.admin;

    if (id && isAdmin) {
      dispatch(getOperatorDetailsStart(id));
    }
  };

  const clearFormTouched = () => {
    if (actionType === UPDATE_ADMIN_OPERATOR_DETAILS_SUCCESS) {
      dispatch(setOperatorDetailsFormTouched(false));
    }
  };

  const handleOnSave = () => {
    dispatch(updateAdminOperatorsDetailsStart({ id, data: details }));
  };

  const isEditActionPermitted = permissionsService.can(
    PERMISSIONS.actions.update,
    PERMISSIONS.sections.admin,
    PERMISSIONS.resources.operators,
    id || null,
  );

  const saveButton =
    isPageReadOnly || !isEditActionPermitted ? null : (
      <PrimaryButton onClick={handleOnSave} disabled={disabledSave}>
        {!showUserSpinner ? (
          <>
            <SaveOutlined />
            <span>{GLOBAL_CONTENT.save}</span>
          </>
        ) : (
          <Spinner size={28} cv="white" />
        )}
      </PrimaryButton>
    );

  const handleBackClickAction = () => {
    if (location.state?.previousUrl) {
      dispatch(tryRouteChangeStart(location.state.previousUrl));
      navigate(location.state.previousUrl);
    } else {
      dispatch(tryRouteChangeStart(ADMIN_OPERATORS_PATH));
      navigate(ADMIN_OPERATORS_PATH);
    }
  };

  const contextBar = {
    left: () =>
      isFormTouched ? (
        <CancelHistoryButton role="link" clickHandler={handleBackClickAction} />
      ) : (
        <BackHistoryButton role="link" clickHandler={handleBackClickAction} />
      ),
    middle: () => <ContextBarTitle title={title} />,
    right: () => saveButton,
  };

  useEffect(getOperatorData, [currentOperatorCode]);
  useEffect(() => {
    return () => dispatch(setOperatorDetailsFormTouched(false));
  }, []);
  useEffect(clearFormTouched, [actionType]);

  return (
    <AdminTemplate contextBar={contextBar} navigation={<Navigation />}>
      <Outlet />
    </AdminTemplate>
  );
};

export { OperatorsDetails };
