import { EMAIL_DETAILS_TAKEN_MESSAGE, USERNAME_DETAILS_TAKEN_MESSAGE } from "constants/content";
import * as actionTypes from "store/adminUserDetails/actionTypes";

import { reducer, setState, globalReducerActions } from "utils/store";

const adminUserInitialState = {
  errors: {},
  actionType: null,
  inProgress: false,
  finished: false,
  isFormTouched: false,
  user: {},
};

const ReducerActions = globalReducerActions("adminUserDetails", adminUserInitialState, {
  [actionTypes.GET_USER_DETAILS_START](state) {
    return setState(state, {
      actionType: actionTypes.GET_USER_DETAILS_START,
      inProgress: true,
      finished: false,
      user: {},
    });
  },
  [actionTypes.GET_USER_DETAILS_SUCCESS](state, payload) {
    return setState(state, {
      actionType: actionTypes.GET_USER_DETAILS_SUCCESS,
      inProgress: false,
      finished: true,
      isFormTouched: false,
      user: { ...payload },
    });
  },
  [actionTypes.GET_USER_DETAILS_FAIL](state, payload) {
    return setState(state, {
      actionType: actionTypes.GET_USER_DETAILS_FAIL,
      inProgress: false,
      finished: false,
      errors: { ...payload },
    });
  },
  [actionTypes.SET_VALUE_FOR_USER_DETAILS](state, payload) {
    const { fieldName, value } = payload;

    return setState(state, {
      ...state,
      isFormTouched: true,
      user: {
        ...state.user,
        [fieldName]: value,
      },
    });
  },
  [actionTypes.SET_ERRORS_FOR_USER_DETAILS](state, payload) {
    return setState(state, { ...state, errors: { ...payload } });
  },
  [actionTypes.CHECK_ADMIN_EMAIL_UNIQUENES_FAIL](state) {
    return setState(state, {
      ...state,
      errors: {
        email: EMAIL_DETAILS_TAKEN_MESSAGE,
      },
    });
  },
  [actionTypes.CHECK_ADMIN_USERNAME_UNIQUENES_FAIL](state) {
    return setState(state, {
      ...state,
      errors: {
        username: USERNAME_DETAILS_TAKEN_MESSAGE,
      },
    });
  },
  [actionTypes.UPDATE_ADMIN_USER_DETAILS_START](state) {
    return setState(state, {
      actionType: actionTypes.UPDATE_ADMIN_USER_DETAILS_START,
      inProgress: true,
      finished: false,
    });
  },
  [actionTypes.UPDATE_ADMIN_USER_DETAILS_SUCCESS](state) {
    return setState(state, {
      actionType: actionTypes.UPDATE_ADMIN_USER_DETAILS_SUCCESS,
      inProgress: false,
      finished: true,
    });
  },
  [actionTypes.UPDATE_ADMIN_USER_DETAILS_FAIL](state, payload) {
    return setState(state, {
      actionType: actionTypes.UPDATE_ADMIN_USER_DETAILS_FAIL,
      inProgress: false,
      finished: false,
      errors: { ...payload },
    });
  },
  [actionTypes.SET_USER_DETAILS_FORM_TOUCHED](state, payload) {
    return setState(state, {
      isFormTouched: payload,
    });
  },
  [actionTypes.CLEAR_USER_DETAILS_ERRORS](state) {
    return setState(state, {
      errors: {},
    });
  },
});

const adminUserDetailsReducer = (state = adminUserInitialState, action) => reducer(state, action, ReducerActions);

export { adminUserDetailsReducer };
