import { useEffect } from "react";

import { HttpClient } from "services/application/httpClient/httpClient";

const useJobStatus = (list, dispatcher) => {
  const shouldExecuteDispatcher = status => ["completed", "failed"].includes(status);

  useEffect(() => {
    const reqiestIntervalTimer = 5000;
    const ids = list.filter(({ job }) => job && !shouldExecuteDispatcher(job.status)).map(({ job }) => job.id);

    const interval = setInterval(() => {
      ids.forEach(async id => {
        const { data } = await HttpClient.get(`/job/${id}`);
        if (shouldExecuteDispatcher(data.status)) {
          dispatcher(id, data.status);
        }
      });
    }, reqiestIntervalTimer);

    return () => {
      clearInterval(interval);
    };
  }, [list, dispatcher]);
  return null;
};

export { useJobStatus };
