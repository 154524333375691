import React from "react";
import styled from "styled-components";
import { SortableContainer } from "react-sortable-hoc";

const StyledWrapper = styled.ul`
  margin-block-start: 0;
  margin-block-end: 0;
  padding-inline-start: 0;
  position: relative;
`;

const SortableList = SortableContainer(({ children, ...rest }) => {
  return <StyledWrapper {...rest}>{children}</StyledWrapper>;
});

export { SortableList };
