import React from "react";
import { Dialog } from "@material-ui/core";
import PropTypes from "prop-types";
import styled from "styled-components";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import { H6 } from "components/ui/Typography/Typography";
import { Content } from "components/ui/Content";
import { DialogTransition } from "components/ui/Dialogs";

const DialogWrapper = styled.div`
  position: relative;
  padding: ${({ theme }) => theme.setSpacing(6)}px;
`;

const CloseButton = styled(CloseOutlinedIcon)`
  && {
    position: absolute;
    top: ${({ theme }) => theme.setSpacing(6)}px;
    right: ${({ theme }) => theme.setSpacing(6)}px;
    font-size: ${({ theme }) => theme.fonts.fontSize.body2}px;
    color: ${({ theme }) => theme.colors.grey50};
    cursor: pointer;
  }
`;
const ContentWrapper = styled.div`
  padding-right: ${({ theme }) => theme.setSpacing(4)}px;
`;

const InformationalModal = ({ children, onCancel, header, ...rest }) => (
  <Dialog TransitionComponent={DialogTransition} {...rest}>
    <DialogWrapper>
      <CloseButton fontSize="small" onClick={onCancel} />
      <ContentWrapper>
        <Content margin={7}>
          <H6>{header}</H6>
        </Content>
        {children}
      </ContentWrapper>
    </DialogWrapper>
  </Dialog>
);

InformationalModal.propTypes = {
  open: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  onCancel: PropTypes.func.isRequired,
  header: PropTypes.string.isRequired,
};

export { InformationalModal };
