/* eslint-disable no-restricted-globals */
import { isObjectEmpty } from "utils/object";
import { documentValidator } from "utils/validators/validators";
import { isTooLong, emailValidate } from "utils/validation";

import { isObject } from "contracts/types";

import { ERRORS, TRIP_WIZARD_CONTENT } from "constants/content";

import { isEmpty } from "lodash";

export const validateDocuments = documents => {
  const result = {};
  documents.forEach((doc, index) => {
    const docResult = documentValidator(doc);
    if (!isObjectEmpty(docResult)) result[index] = docResult;

    const isDocNamePresent = documents.some((docItem, docIndex) => doc?.name === docItem?.name && index !== docIndex);

    if (isDocNamePresent) result[index] = { name: ERRORS.isUnique("Title") };
  });
  return result;
};

export const coordinatesValidator = data => {
  const errors = {};

  if ((data.latitude === "" && data.longitude === "") || (data.latitude === null && data.longitude === null)) {
    errors.latitude = ERRORS.coordinatesRequired;
  }
  if (isNaN(Number(data.latitude)) || isNaN(Number(data.longitude))) {
    errors.latitude = ERRORS.coordinatesNotNumbers;
  }
  if (data.longitude === "" && !isNaN(Number(data.latitude))) {
    errors.longitude = ERRORS.longitudeMinRange;
  }
  if (data.latitude === "" && !isNaN(Number(data.longitude))) {
    errors.latitude = ERRORS.longitudeMinRange;
  }
  if (!isNaN(Number(data.latitude)) && !isNaN(Number(data.longitude)) && Number(data.latitude) > 90) {
    errors.latitude = ERRORS.latitudeMaxRange;
  }
  if (!isNaN(Number(data.latitude)) && !isNaN(Number(data.longitude)) && Number(data.latitude) <= -90) {
    errors.latitude = ERRORS.latitudeMinRange;
  }
  if (!isNaN(Number(data.latitude)) && !isNaN(Number(data.longitude)) && Number(data.longitude) > 180) {
    errors.longitude = ERRORS.longitudeMaxRange;
  }
  if (!isNaN(Number(data.latitude)) && !isNaN(Number(data.longitude)) && Number(data.longitude) <= -180) {
    errors.longitude = ERRORS.longitudeMinRange;
  }

  return errors;
};

export const validateAction = (actionName, action = [], actionLabelName, actionLabel) => {
  const result = {};
  if (actionLabel || action.length > 0) {
    if (!action || !action.length) {
      if (!result.documents) result.documents = {};
      result.documents[actionName] = ERRORS.missingAttachment;
    }

    if (!actionLabel) {
      if (!result.meta) result.meta = {};
      result.meta[actionLabelName] = ERRORS.isRequired("Title");
    }
  }
  return result;
};

export const validateBackground = background => {
  const result = {};
  if (!background || !isObject(background)) {
    result.background = ERRORS.isRequired("Background");
  }
  return result;
};

export const validateNotifications = notificationsList => {
  return notificationsList.reduce((acc, notification) => {
    if (notification.type === "gps" && (!notification.end_at || !notification.start_at)) {
      return { ...acc, [notification.id]: ERRORS.invalidDate };
    }
    if (notification.type === "timed" && (!notification.start_at || notification.start_at === "Invalid date")) {
      return { ...acc, [notification.id]: ERRORS.invalidTime };
    }
    return acc;
  }, {});
};

export const validateLocationItem = (location, addressFieldValidation = false) => {
  let locationResult = {};
  const { name, location: locationItem } = location;

  if (!name || name === "") {
    locationResult = { ...locationResult, name: ERRORS.cantBeBlank("Name") };
  }
  if (addressFieldValidation && !location) {
    locationResult = { ...locationResult, location: ERRORS.isRequired("Address") };
  } else if (!location?.vamoos_id && (!locationItem || locationItem === "") && !location.nested) {
    locationResult = { ...locationResult, location: ERRORS.cantBeBlank("Location") };
  }

  const coordinatesErrors = coordinatesValidator(location);

  return { ...locationResult, ...coordinatesErrors };
};

export const validateLocations = (locations, addressFieldValidation = false) => {
  const result = {};
  if (locations.length > 0) {
    locations.forEach((location, index) => {
      const locationItemError = validateLocationItem(location, addressFieldValidation);
      if (!isEmpty(locationItemError)) {
        result[index] = locationItemError;
      }
    });
  }

  return result;
};

export const validateStoryboard = (storyboard, type, showDays) => {
  const result = {};
  const maxLength = 128;

  storyboard.forEach((day, dayIndex) => {
    const { documents, headline } = day || {};
    if (type === "trip" && parseInt(showDays, 10) === 1) {
      if (!day?.meta?.day_number || day?.meta?.day_number < 1) {
        if (!result[dayIndex]) {
          result[dayIndex] = {};
        }
        result[dayIndex].dayNumber = ERRORS.isRequired(TRIP_WIZARD_CONTENT.storyboard.dayNumber);
      }
    }

    if (isTooLong(maxLength, headline)) {
      if (!result[dayIndex]) result[dayIndex] = {};
      result[dayIndex].headline = ERRORS.isTooLong(maxLength);
    }

    if (documents)
      documents.forEach((doc, docIndex) => {
        const docResult = documentValidator(doc);
        if (!isObjectEmpty(docResult)) {
          if (!result[dayIndex] || !result[dayIndex].documents) {
            result[dayIndex] = { ...result[dayIndex], documents: {} };
          }
          result[dayIndex].documents[docIndex] = docResult;
        }

        const isNameRedundant = documents.some((item, index) => item.name === doc.name && index !== docIndex);

        if (isNameRedundant) {
          if (!result[dayIndex] || !result[dayIndex].documents) {
            result[dayIndex] = { ...result[dayIndex], documents: {} };
          }
          if (!result[dayIndex].documents[docIndex]?.name) {
            result[dayIndex].documents[docIndex] = { ...result[dayIndex].documents[docIndex], name: ERRORS.isUnique("Title") };
          }
        }
      });
  });
  return result;
};

export const validateMessaging = emailList => {
  const atLeastOneEmailDuringOption = emailList.some(email => email.during);

  return emailList.reduce((acc, email) => {
    const emailError = {};

    if (!email.email) {
      emailError.email = ERRORS.isRequired("E-mail");
    }
    if (email.email && !emailValidate(email.email)) {
      emailError.email = ERRORS.invalidEmail;
    }
    if (!atLeastOneEmailDuringOption) {
      acc.during = ERRORS.messagingWithoutDuringOptionSelected;
    }
    if (!isEmpty(emailError)) {
      acc[email.id] = emailError;
    }
    
    return acc;
  }, {});
};
