import React from "react";
import PropTypes from "prop-types";

import config from "config/app";

import { FormSection } from "components/ui/Forms/FormSection";

const StickyHeaderFormSection = ({ children, sticky, ...rest }) => (
  <FormSection sticky={sticky || config.global.formSection.defaultStickyHeader} {...rest}>
    {children}
  </FormSection>
);

StickyHeaderFormSection.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.node]).isRequired,
};

export { StickyHeaderFormSection };
