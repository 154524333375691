import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";

import { LIST_COLUMNS } from "feature/panel/Notifications/listColumns";
import { ActionsContext } from "feature/panel/Notifications/ActionsContext";
import { NotificationsRowActions } from "feature/panel/Notifications/NotificationsRowActions";
import { NotificationRowTemplate } from "feature/panel/Notifications/NotificationRowTemplate";

import { Table, TableBody, TableHeader, TablePagination } from "components/ui/Tables";
import { ConfirmationModal } from "components/ui/Modals/ConfirmationModal";
import { LoadingScreen } from "components/ui/LoadingScreen/LoadingScreen";
import { NoResultsMessage } from "components/ui/Messages/NoResultsMessage";

import { useTableHandlers } from "hooks/useTableHandlers";

import {
  CHANGES_SAVED_MESSAGE,
  CREATE_CONTENT_LABELS,
  EMPTY_LIST_MESSAGES_BASE,
  NAMES_OF_RESOURCES_LISTS,
  TABLE_NAMES,
} from "constants/content";
import { usePermissionsService } from "hooks/usePermissionsService";
import { PERMISSIONS } from "constants/permissions";
import { NotificationsService } from "services/NotificationsService";
import { setNotification } from "store/app/actions";
import { setDefaultApiErrorMessage } from "utils";

const NotificationsList = forwardRef(({ onEditClick, activeType }, ref) => {
  const dispatch = useDispatch();

  /** @type {NotificationsService} */
  const notificationService = new NotificationsService();
  const permissionsService = usePermissionsService();

  const canCreateNotification = permissionsService.can(
    PERMISSIONS.actions.create,
    PERMISSIONS.sections.operator,
    PERMISSIONS.resources.notifications,
  );

  const { rowsPerPage, page, sortingOrder, sortingBy, handleChangeOrder, handleChangeRowsPerPage, handleChangePage } = useTableHandlers();

  const [deleteModal, setDeleteModal] = useState({ open: false, body: {} });
  const [notificationTemplates, setNotificationTemplates] = useState([]);
  const [totalMatches, setTotalMatches] = useState(0);
  const [inProgress, setInProgress] = useState(false);

  const handleDeleteOpen = notification => {
    const { open } = deleteModal;
    const body = open ? deleteModal.body : notification;
    setDeleteModal({ body, open: !open });
  };

  const fetchNotifications = async () => {
    setInProgress(true);
    const notificationsTemplatesList = await notificationService.getStayUsers(activeType, {
      rowsPerPage,
      page,
      order: sortingOrder,
      orderBy: sortingBy,
    });

    setTotalMatches(notificationsTemplatesList.total_matches);
    setNotificationTemplates(notificationsTemplatesList.items);
    setInProgress(false);

    return notificationsTemplatesList;
  };

  const handleDeleteConfirm = async () => {
    try {
      await notificationService.removeNotification(deleteModal.body);
      await fetchNotifications();
      await dispatch(setNotification({ type: "success", message: CHANGES_SAVED_MESSAGE }));
    } catch (error) {
      await dispatch(
        setNotification({
          type: "error",
          message: setDefaultApiErrorMessage(error),
        }),
      );
    }

    handleDeleteOpen();
  };

  const actions = {
    onEditClick,
    handleDeleteOpen,
  };

  useImperativeHandle(ref, () => ({
    refresh: () => fetchNotifications(),
  }));

  useEffect(() => {
    fetchNotifications(activeType);
  }, [activeType, rowsPerPage, page]);

  const mainContent = (() => {
    if (inProgress && !notificationTemplates.length) return <LoadingScreen />;
    if (!notificationTemplates.length && !canCreateNotification) return null;
    if (!notificationTemplates.length && canCreateNotification)
      return (
        <NoResultsMessage>
          {EMPTY_LIST_MESSAGES_BASE(CREATE_CONTENT_LABELS.notificationTemplate, NAMES_OF_RESOURCES_LISTS.notifications[activeType])}
        </NoResultsMessage>
      );
    return (
      <ActionsContext.Provider value={actions}>
        <Table
          headers={LIST_COLUMNS[activeType]}
          list={notificationTemplates}
          actions={NotificationsRowActions}
          onChangeOrder={handleChangeOrder}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          count={totalMatches}
          isDataLoading={inProgress}
        >
          <TableHeader withActions />
          <TableBody rowComponent={NotificationRowTemplate} />
          <TablePagination count={totalMatches} tableId={TABLE_NAMES.notifications} />
        </Table>
      </ActionsContext.Provider>
    );
  })();

  return (
    <>
      {mainContent}
      {deleteModal.open && (
        <ConfirmationModal
          onCancel={handleDeleteOpen}
          open={deleteModal.open}
          onConfirm={handleDeleteConfirm}
          title="Are you sure you want to delete notification?"
        />
      )}
    </>
  );
});

NotificationsList.propTypes = {
  onEditClick: PropTypes.func.isRequired,
  activeType: PropTypes.string.isRequired,
};

export { NotificationsList, ActionsContext };
