import cloudIcon from "assets/images/weather/_09d.png";

export const CUSTOM_APP_STATUS_LIVE = "live";
export const CUSTOM_APP_STATUS_REJECTED = "rejected";
export const CUSTOM_APP_STATUS_SUBMITTED = "submitted";
export const CUSTOM_APP_STATUS_DRAFT = "draft";
export const CUSTOM_APP_STATUS_ARCHIVED = "archived";
export const CUSTOM_APP_STATUS_APPROVED = "approved";
export const CUSTOM_APP_STATUS_ACCEPTED = "accepted";

export const ERROR_NAMES_LABELS = {
  shouldBeBoolean: "Should be boolean value",
  loginStatusBarError: 'Incorrect value, allowed values are "light", "dark" or "default"',
  loginBorders: 'Incorrect value, allowed values are "none", "tb", "lr" or "tblr"',
  propertyUserId: "Property User ID",
  uppercaseRequired: "Uppercase required",
  docName: "Document name",
  docContent: "Document content",
};

export const LOGIN_PAGE_SINGLE_PROPERTY_LABELS = {
  line1: "For full access to all information enter requested details below",
  fullName: "Full name",
  email: "Email address",
  nextButtonLabel: "NEXT",
  skipButtonLabel: "SKIP",
  hint: "If you already have a reservation, please specify the name the booking was made for",
};

export const WEATHER_CONTENT = {
  lastUpdated: "Last updated - Just now",
  label: "Forecast",
  location: "Location name",
  firstDay: "Monday 11th",
  secondDay: "Tuesday 12th",
  thirdDay: "Wednesday 13th",
  fourthDay: "Thursday 14th",
  fifthDay: "Friday 15th",
  days: {
    firstDay: {
      hours: [
        { icon: cloudIcon, hour: 10, temperature: -2 },
        { icon: cloudIcon, hour: 13, temperature: -1 },
        { icon: cloudIcon, hour: 16, temperature: -2 },
        { icon: cloudIcon, hour: 19, temperature: -2 },
        { icon: cloudIcon, hour: 22, temperature: -3 },
      ],
    },
    secondDay: {
      avgTemperature: -1,
      icon: cloudIcon,
    },
    thirdDay: {
      avgTemperature: -2,
      icon: cloudIcon,
    },
    fourthDay: {
      avgTemperature: -3,
      icon: cloudIcon,
    },
    fifthDay: {
      avgTemperature: -6,
      icon: cloudIcon,
    },
  },
};

export const GENERAL_CA_SECTION_ERROR_NAMES = ["settings.defaultUserId", "bundleId", "name", "type"];
