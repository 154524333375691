import React from "react";
import styled from "styled-components";
import { Grid } from "@material-ui/core";
import PropTypes from "prop-types";

export const SectionWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.withOpacity(theme.colors.grey10, 0.3)};
  ${({ withMargin, theme }) => (withMargin ? `margin: 0 ${theme.setSpacing(9)}px;` : null)};
  margin: -40px -40px 0 -40px;
  margin-left: -40px;
  margin-right: -40px;
  margin-top: -40px;
  margin-bottom: ${({ theme }) => theme.setSpacing(10)}px;
  padding: 40px;
  border-radius: ${({ theme, fullWidth }) => (!fullWidth ? theme.borderRadius : 0)}px;
`;

const PageHeader = ({ children }) => {
  return (
    <SectionWrapper fullWidth>
      <Grid container justifyContent="center">
        <Grid item xs={12} md={10}>
          {children}
        </Grid>
      </Grid>
    </SectionWrapper>
  )
}

PageHeader.propTypes = {
  children: PropTypes.node.isRequired
}

export {
  PageHeader
}