import { all, fork } from "redux-saga/effects";
import { authSaga } from "store/auth/saga";
import { tripsSaga } from "store/trips/saga";
import { iconsSaga } from "store/icons/saga";
import { notificationsSaga } from "store/notifications/saga";
import { inspirationsSaga } from "store/inspirations/saga";
import { operatorSaga } from "store/operator/saga";
import { appSaga } from "store/app/saga";
import { staysSaga } from "store/stays/saga";
import { adminOperatorsSaga } from "store/adminOperators/saga";
import { adminUsersSaga } from "store/adminUsers/saga";
import { adminFeaturesSaga } from "store/adminFeatures/saga";
import { rolesSaga } from "./roles/saga";
import { adminOperatorDetailsSaga } from "./adminOperatorDetails/saga";
import { adminUserDetailsSaga } from "./adminUserDetails/saga";
import { nestedSaga } from "./nested/saga";
import { adminConnect } from "./adminConnect/saga";
import { customAppsSaga } from "./customApps/saga";
import { customAppFormSaga } from "./customAppForm/saga";
import { brandingSaga } from "./branding/saga";

function* rootSaga() {
  yield all([
    fork(authSaga),
    fork(tripsSaga),
    fork(iconsSaga),
    fork(notificationsSaga),
    fork(inspirationsSaga),
    fork(operatorSaga),
    fork(appSaga),
    fork(staysSaga),
    fork(adminOperatorsSaga),
    fork(adminUsersSaga),
    fork(adminFeaturesSaga),
    fork(adminOperatorDetailsSaga),
    fork(adminUserDetailsSaga),
    fork(rolesSaga),
    fork(nestedSaga),
    fork(adminConnect),
    fork(customAppsSaga),
    fork(customAppFormSaga),
    fork(brandingSaga),
  ]);
}
export { rootSaga };
