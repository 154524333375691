import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { ErrorOutline, WarningOutlined, InfoOutlined, CheckCircleOutline, CloseOutlined } from "@material-ui/icons";

import { BodyText2 } from "components/ui/Typography/Typography";
import { GhostButton } from "components/ui/Buttons/GhostButton";

import { colors } from "config/theme/colors";

const setColorTheme = messageType => {
  switch (messageType) {
    case "error":
      return { background: colors.withOpacity(colors.errorLight, 0.2), color: colors.errorLight };
    case "warning":
      return { background: colors.withOpacity(colors.warning, 0.2), color: colors.warning };
    case "info":
      return { background: colors.withOpacity(colors.info, 0.2), color: colors.info };
    default:
      return { background: colors.withOpacity(colors.success, 0.2), color: colors.success };
  }
};

const MessageWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: ${({ theme }) => theme.borderRadius}px;
  padding: ${({ theme }) => `${theme.setSpacing(3)}px ${theme.setSpacing(6)}px`};
  background: ${({ type }) => setColorTheme(type).background};

  ${({ margin }) =>
    margin ? `margin: ${margin.top || "auto"} ${margin.right || "auto"} ${margin.bottom || "auto"} ${margin.left || "auto"};` : null}

  svg {
    color: ${({ type }) => setColorTheme(type).color};
    &&& {
      margin: 0;
    }
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Text = styled(BodyText2)`
  color: ${({ type }) => setColorTheme(type).color};
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: ${({ theme }) => theme.setSpacing(5)}px;
`;

const CancelButton = styled(GhostButton)`
  && {
    padding: 0;
  }
`;

const Message = ({ text, type, onDelete, icon, children, margin, buttonContent }) => {
  const iconElement =
    icon ||
    (() => {
      switch (type) {
        case "error":
          return <ErrorOutline />;
        case "warning":
          return <WarningOutlined />;
        case "info":
          return <InfoOutlined />;
        default:
          return <CheckCircleOutline />;
      }
    })();

  const deleteButton = onDelete && (
    <CancelButton onClick={onDelete}>
      <CloseOutlined fontSize="small" />
    </CancelButton>
  );

  return (
    <MessageWrapper type={type} margin={margin}>
      <ContentWrapper>
        <IconWrapper>{iconElement}</IconWrapper>
        <Text type={type}>{text} {buttonContent}</Text>
        {children}
      </ContentWrapper>
      {deleteButton}
    </MessageWrapper>
  );
};

Message.defaultProps = {
  type: "success",
  icon: null,
  onDelete: null,
  children: undefined,
  text: "",
  margin: undefined,
};

Message.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  type: PropTypes.oneOf(["error", "warning", "info", "success"]),
  onDelete: PropTypes.func,
  icon: PropTypes.node,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.element]),
  margin: PropTypes.shape({
    top: PropTypes.string,
    bottom: PropTypes.string,
    left: PropTypes.string,
    right: PropTypes.string,
  }),
  buttonContent: PropTypes.element.isRequired,
};

export { Message, ContentWrapper, Text, IconWrapper, CancelButton };
