import React, { useState, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { HttpClient } from "services/application/httpClient/httpClient";
import { LanguageSelectorPill } from "components/_shared/LanguageSelector/LanguageSelector";
import { useSelector } from "react-redux";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Badge from "@mui/joy/Badge";

const LanguageSelectorCutsomApps = ({ currentLanguage, onChange, isBlock, noHide, missedLanguages }) => {
  const [expanded, setExpanded] = useState(false);
  const { data: languages } = useQuery({
    queryKey: ["/app_languages"],
  });

  const selectedLanguages = useSelector(state => state.customAppForm.customAppForm.languages);
  const defaultLanguage = useSelector(state => state.customAppForm.customAppForm.default_language);

  const languagesToShow = languages
    ?.filter(item => selectedLanguages?.find(selected => selected === item.value))
    .sort((a, b) => (b.value === defaultLanguage) - (a.value === defaultLanguage));

  useEffect(() => {
    onChange(defaultLanguage);
  }, [defaultLanguage]);

  if (selectedLanguages?.length <= 1) return null;

  return (
    <div style={{ display: isBlock ? "block" : "flex", flexWrap: "wrap" }}>
      {languagesToShow?.map(
        (item, index) =>
          (noHide || index < 3 || expanded) && (
            <LanguageSelectorPill
              onClick={() => onChange(item.value)}
              isPillActive={currentLanguage === item.value}
              key={item.value}
              style={{ marginRight: 5, marginBottom: 5, padding: "8px 12px", width: isBlock ? "100%" : "auto" }}
              isError={missedLanguages?.includes(item.value)}
            >
              {item.value === defaultLanguage || missedLanguages?.includes(item.value) ? (
                <Badge
                  badgeContent={missedLanguages?.includes(item.value) ? "!" : "default"}
                  color="primary"
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                >
                  {item.label}
                </Badge>
              ) : (
                item.label
              )}
            </LanguageSelectorPill>
          ),
      )}

      {!noHide && languagesToShow?.length > 3 && (
        <LanguageSelectorPill
          onClick={() => setExpanded(!expanded)}
          style={{ width: 40, padding: 0, display: "flex", justifyContent: "center" }}
        >
          <KeyboardArrowDownIcon style={{ margin: 0, transform: `rotate(${expanded ? 180 : 0}deg)` }} />
        </LanguageSelectorPill>
      )}
    </div>
  );
};

export default LanguageSelectorCutsomApps;
