// @ts-check

const PERMISSIONS = {
  actions: {
    create: "create",
    read: "read",
    update: "update",
    delete: "delete",
  },
  sections: {
    operator: "operator",
    vamoosList: "vamoos_list",
    admin: "admin",
  },
  resources: {
    library: "library",
    notifications: "notifications",
    pois: "pois",
    inspirations: "inspirations",
    icons: "icons",
    operators: "operators",
    vamoosList: "vamoos_list",
    users: "users",
    stays: "stays",
    features: "features",
    flights: "flights",
    jobs: "jobs",
    creations: "creations",
    default: "default",
    locations: "locations"
  },
};

export { PERMISSIONS };
