// @ts-check

import { OperatorMapper } from "mappers/OperatorMapper";
import { PaginatedMapper } from "mappers/PaginatedMapper";
import { UserMapper } from "mappers/UserMapper";
import { OperatorRepository } from "repositories/OperatorRepository";

export class OperatorService {
  /**
   *
   * @param {import("repositories/OperatorRepository").OperatorRepository} operatorRepository
   * @param {import("mappers/UserMapper").UserMapper} operatorRepository
   * @param {import("mappers/OperatorMapper").OperatorMapper} operatorMapper
   */
  constructor(operatorRepository = new OperatorRepository(), userMapper = new UserMapper(), operatorMapper = new OperatorMapper()) {
    this.operatorRepository = operatorRepository;
    this.operatorMapper = operatorMapper;

    /**
     * @type {import("mappers/PaginatedMapper").PaginatedMapper<import("domain/User").User>}
     */
    this.paginatedUserMapper = new PaginatedMapper(userMapper);
  }

  /**
   *
   * @returns {Promise<import("domain/Paginated").Paginated<import("domain/User").User>>}
   */
  async getUsers() {
    const response = await this.operatorRepository.getOperatorUsers();
    return this.paginatedUserMapper.fromDtoToDomain(response);
  }

  /**
   *
   * @param {*} userData
   * @returns {Promise<string>}
   */
  async inviteUser(userData) {
    const { data } = await this.operatorRepository.inviteUser(userData);

    return data.status;
  }

  /**
   *
   * @param {number} id
   * @returns {Promise<string>}
   */
  async revokeInvitedUser(id) {
    const { data } = await this.operatorRepository.revokeInvitedUser(id);

    return data.status;
  }

  /**
   *
   * @param {*} userData
   * @returns {Promise<string>}
   */
  async updateUserRole(userData) {
    const { data } = await this.operatorRepository.updateUserRole(userData);

    return data.status;
  }

  /**
   *
   * @param {*} userData
   * @returns {Promise<string>}
   */
  async suspendUser(userData) {
    return this.operatorRepository.suspendUser(userData);
  }

  /**
   *
   * @returns {Promise<any>}
   */
  async getRoles() {
    const { data } = await this.operatorRepository.getOperatorRoles();
    return data;
  }

  /**
   *
   * @param {string} code
   * @returns {Promise}
   */
  async getCurrentOperator(code) {
    const { data } = await this.operatorRepository.getCurrentOperator(code);

    // Should be done by mapper
    if (data.meta) {
      if (data.meta.poisList === undefined) {
        data.meta.poisList = true;
      }
    } else {
      data.meta = {
        poisList: true,
      };
    }

    return this.operatorMapper.fromDtoToDomain(data);
  }

  async updateCurrentOperator(operator) {
    const payload = this.operatorMapper.fromDomainToDto(operator);

    return this.operatorRepository.updateCurrentOperator(payload);
  }

  /** ADMIN */
  /**
   *
   * @param {number} id
   * @param {object} data
   * @returns {Promise}
   */
  async updateAdminOperator(id, data) {
    const payload = this.operatorMapper.fromDomainToDto(data);

    return this.operatorRepository.updateAdminOperatorDetails(id, payload).then(response => response.data);
  }

  /**
   *
   * @param {number} id
   * @returns {Promise}
   */
  async getAdminOperatorDetails(id) {
    const { data } = await this.operatorRepository.getAdminOperatorDetails(id);

    return this.operatorMapper.fromDtoToDomain(data);
  }

  /**
   *
   * @param {string} url
   * @returns {Promise}
   */
  async getAdminOperatorList(url, searchMode = false) {
    const { data } = await this.operatorRepository.getAdminOperatorList(url, searchMode);
    const items = data.items.map(operator => this.operatorMapper.fromDtoToDomain(operator));
    const count = data.total_matches;

    return { items, count };
  }
}
