import React from "react";
import { Grid } from "@material-ui/core";
import { useLocation } from "react-router-dom";

import { AppInfoSection } from "./AppInfoSection";

const General = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const isHistoryVersion = queryParams.get("versionHistory");
  return (
    <Grid>
      <AppInfoSection isHistoryVersion={isHistoryVersion} />
    </Grid>
  );
};

export { General };
