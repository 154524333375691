import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { RemoveOutlined, AddOutlined } from "@material-ui/icons";

const ControlZoomButtons = styled.div`
	display: flex;
	flex-direction: column;
	margin-left: ${({ theme }) => theme.setSpacing(5)}px;
`;

const Button = styled.button`
	&&& {
		display: flex;
		align-items: center;
		justify-content: center;
		background: none;
		width: 40px;
		height: 40px;
		border: 1px solid ${({ theme }) => theme.colors.grey40};
		color: ${({ theme }) => theme.colors.grey40};
		border-radius: ${({ theme }) => theme.setSpacing(2)}px;
		cursor: pointer;
		
		:first-of-type {
		  margin-bottom: ${({ theme }) => theme.setSpacing(5)}px;
		}
		:disabled {
			opacity: 0.4;
			cursor: default;
		}
		svg {
			margin: 0;
		}
	}
`;

const ZoomControlButtons = ({ onPlusClick, onMinusClick, disabledPlus, disabledMinus }) => (
  <ControlZoomButtons>
    <Button onClick={onPlusClick} cv="grey40" disabled={disabledPlus}>
      <AddOutlined />
    </Button>
    <Button onClick={onMinusClick} cv="grey40" disabled={disabledMinus}>
      <RemoveOutlined />
    </Button>
  </ControlZoomButtons>
);

ZoomControlButtons.propTypes = {
	onPlusClick: PropTypes.func.isRequired,
	onMinusClick: PropTypes.func.isRequired,
	disabledPlus: PropTypes.bool.isRequired,
	disabledMinus: PropTypes.bool.isRequired
};

export { ZoomControlButtons, ControlZoomButtons, Button };
