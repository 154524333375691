import { useState } from "react";

export function changeActiveItemId(currentId, newId) {
	return currentId === newId ? null : newId;
}

function useActiveItemId() {
	const [activeItemId, setActiveItemId] = useState(null);
	const handleSetActiveItemId = (id) => {
		const newId = changeActiveItemId(activeItemId, id);
		setActiveItemId(newId);
	};

	return [activeItemId, handleSetActiveItemId]
}

export default useActiveItemId;
