import React, { useRef } from "react";
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import styled from "styled-components";

import { Content } from "components/ui/Content";
import { LeadText } from "components/ui/Typography/Typography";

import { useSticky } from "hooks/useSticky";

const dividerOptions = ["top", "left", "right", "bottom"];

const StickyBg = styled.div`
  position: sticky;
  height: 37px;
  z-index: -1;
  top: 10px;
  margin-bottom: -37px;
  background-color: #eaeaea;
`;

const StyledWrapper = styled.div`
  width: 100%;
  height: auto;
  padding: ${({ theme }) => theme.setSpacing(5)}px 0;
  ${({ divider, theme }) =>
    divider && dividerOptions.some(el => el === divider)
      ? `
    border-${divider}: 1px solid ${theme.colors.grey10};
    margin-${divider}: ${theme.setSpacing(10)}px;
  `
      : null};

  &:first-of-type {
    padding-top: 0;
  }
`;

const StyledLeadText = styled(LeadText)`
  && {
    line-height: ${({ theme }) => theme.setSpacing(8)}px;
    font-weight: ${({ theme }) => theme.fonts.fontWeight.default};
    color: ${({ theme }) => theme.colors.grey90};
    display: flex;
    align-items: center;
    gap: 16px;
    white-space: nowrap;
  }
`;

export const StickyWrapper = styled(({ offset, sticky, stickyVariant, position, ...rest }) => <div {...rest} />)`
  width: ${({ sticky, stickyVariant }) => (sticky && stickyVariant === "normal" ? "calc(100% - 240px)" : "100%")};
  display: flex;
  align-items: center;

  ${({ sticky, stickyVariant, offset, position, theme }) =>
    sticky
      ? `
        position: fixed;
        z-index: 3;
        background-color: ${theme.colors.white};
        left: ${stickyVariant === "expanded" ? "0px" : "240px"};
        height: 72px;
        padding: 0 ${theme.setSpacing(10)}px;
        border-bottom: 1px solid ${theme.colors.grey10};
        ${position === "top" ? `top: ${offset || 0}px` : `bottom: ${offset || 0}px`}
      `
      : null}
`;

const FormSection = ({
  children,
  title,
  editableTitle,
  headerActions: HeaderActions,
  divider,
  sticky,
  stickyVariant,
  stickyContainer: StickyContainer,
  headerWidth,
  styleContent,
}) => {
  const { position, offset } = sticky || {};

  const wrapperRef = useRef();

  const isSticky = useSticky(wrapperRef, position, offset);

  const Header = () => (
    <Grid
      container
      item
      xs={12}
      alignItems="center"
      justifyContent="space-between"
      style={{ backgroundColor: "#fff", padding: "5px", width: "150%" }}
    >
      <Grid container alignItems="center" justifyContent="space-between" xs={12} md={headerWidth} item>
        {title ? <StyledLeadText>{title}</StyledLeadText> : <>{editableTitle}</>}
        {HeaderActions && <HeaderActions />}
      </Grid>
    </Grid>
  );

  return (
    <StyledWrapper ref={wrapperRef} divider={divider}>
      <Content style={styleContent}>
        {styleContent?.position === "sticky" && <StickyBg />}

        {sticky && isSticky && (
          <StickyWrapper stickyVariant={stickyVariant} sticky={isSticky} offset={offset} position={position}>
            <Grid container alignItems="center" justifyContent="center">
              <Grid container xs={12} md={10} alignItems="center" justifyContent="center" item>
                {StickyContainer ? (
                  <StickyContainer>
                    <Header />
                  </StickyContainer>
                ) : (
                  <Header />
                )}
              </Grid>
            </Grid>
          </StickyWrapper>
        )}
        <Header />
      </Content>
      <Grid item xs={12}>
        {children}
      </Grid>
    </StyledWrapper>
  );
};

FormSection.defaultProps = {
  headerActions: null,
  divider: undefined,
  sticky: undefined,
  stickyVariant: "normal",
  stickyContainer: null,
  title: "",
  editableTitle: null,
  headerWidth: 12,
};

FormSection.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]).isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  editableTitle: PropTypes.element,
  headerActions: PropTypes.oneOfType([PropTypes.element, PropTypes.func, PropTypes.array]),
  divider: PropTypes.oneOf(["top", "left", "right", "bottom"]),
  sticky: PropTypes.shape({
    position: PropTypes.oneOf(["top", "bottom"]),
    offset: PropTypes.number,
  }),
  stickyVariant: PropTypes.oneOf(["expanded", "normal"]),
  stickyContainer: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
  headerWidth: PropTypes.number,
};

export { FormSection, StyledWrapper, StyledLeadText };
