const LOGO_FIELD = "logo";
const STAY_FIELDS = {
  daily: "daily",
  voucher: "voucher",
  directory: "directory",
};

const TRIP_FIELDS = {
  departure_date: "departure_date",
  return_date: "return_date",
};

export { LOGO_FIELD, STAY_FIELDS, TRIP_FIELDS };
