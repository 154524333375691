import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

import { GLOBAL_CONTENT, LOCATIONS_LABELS } from "constants/content";
import { SecondaryButton } from "components/ui/Buttons/SecondaryButton";
import { Clear } from "@material-ui/icons";
import { useService } from "hooks/useService";
import { StayService } from "services/domain/StayService";
import { Autocomplete } from "components/ui/Forms/Autocomplete";

const StaysListItem = styled.div`
  width: 100%;
  padding: 10px;
  display: flex;
  height: 100px;
  align-items: center;
  flex-direction: row;
  ${({ isSelected, theme }) => (isSelected ? `background-color: ${theme.colors.brand}20;` : "")}
`;

const StayListItemFieldWrapper = styled.div`
  margin: 0 15px;
  flex-grow: 2;
  max-width: 50%;

  &:last-of-type {
    min-width: 118px;
    text-align: right;
    flex-grow: 0;
  }
`;

const StayListItem = ({ stay, onStaySelect, onStayClear }) => {
  const stayService = useService(StayService);

  const autocompleteRef = useRef();
  const [fullOptionsList, setFullOptionsList] = useState([]);

  const handleSelectStay = selectedStay => {
    onStaySelect(selectedStay);
  };

  const handleSelectClear = () => {
    onStayClear(stay);
  };

  const getStays = async () => {
    const stays = await stayService.getPublicStays();

    setFullOptionsList(stays);
  };

  useEffect(() => {
    getStays();
  }, []);

  const clearButton = (
    <SecondaryButton onClick={handleSelectClear} size="small">
      <Clear />
      {GLOBAL_CONTENT.clear}
    </SecondaryButton>
  );
  return (
    <StaysListItem isSelected={!!stay?.vamoosId}>
      <StayListItemFieldWrapper>
        <Autocomplete
          options={fullOptionsList}
          value={stay}
          onChange={(event, value) => handleSelectStay(value)}
          name={`name-nr${stay?.vamoosId}`}
          type="text"
          label={LOCATIONS_LABELS.name}
          fullWidth
          disabled={!!stay?.vamoosId}
          disableOptionsOpenAfterInputClick
          getOptionLabel={option => option.name}
          getOptionSelected={(option, value) => option.vamoosId === value.vamoosId}
        />
      </StayListItemFieldWrapper>
      <StayListItemFieldWrapper>{!!stay?.vamoosId && clearButton}</StayListItemFieldWrapper>
    </StaysListItem>
  );
};

export { StayListItem };
