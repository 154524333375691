import * as actionTypes from "store/adminFeatures/actionTypes";
import { createAction } from "utils/store";

export const getFeaturesStaysStart = createAction(actionTypes.GET_FEATURES_STAYS_START);
export const getFeaturesStaysSuccess = createAction(actionTypes.GET_FEATURES_STAYS_SUCCESS);
export const getFeaturesStaysFail = createAction(actionTypes.GET_FEATURES_STAYS_FAIL);

export const toggleFeatureActiveStatusStart = createAction(actionTypes.TOGGLE_FEATURE_ACTIVE_STATUS_START);
export const toggleFeatureActiveStatusSuccess = createAction(actionTypes.TOGGLE_FEATURE_ACTIVE_STATUS_SUCCESS);
export const toggleFeatureActiveStatusFail = createAction(actionTypes.TOGGLE_FEATURE_ACTIVE_STATUS_FAIL);

export const setValueForStay = createAction(actionTypes.SET_VALUE_FOR_STAY);
export const setValueForStayFeature = createAction(actionTypes.SET_VALUE_FOR_STAY_FEATURE);

export const addNewStayFeatureStart = createAction(actionTypes.ADD_NEW_STAY_FEATURE_START);
export const addNewFeatureItemStart = createAction(actionTypes.ADD_NEW_FEATURE_ITEM_START);
export const deleteStayFeatureStart = createAction(actionTypes.DELETE_STAY_FEATURE_START);
export const deleteFeatureItemStart = createAction(actionTypes.DELETE_FEATURE_ITEM_START);

export const saveStayFeatureStart = createAction(actionTypes.SAVE_FEATURE_STAYS_START);
export const saveStayFeatureSuccess = createAction(actionTypes.SAVE_FEATURE_STAYS_SUCCESS);
export const saveStayFeatureFail = createAction(actionTypes.SAVE_FEATURE_STAYS_FAIL);

export const setOrderForStayFeature = createAction(actionTypes.SET_ORDER_FOR_STAY_FEATURES);
export const setOrderForFeatureItem = createAction(actionTypes.SET_ORDER_FOR_FEATURES_ITEM);

export const setStaysFeaturesFormTouched = createAction(actionTypes.SET_STAYS_FEATURES_FORM_TOUCHED);