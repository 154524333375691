import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useDispatch } from "react-redux";

import { Table, TableHeader, TableBody, TablePagination } from "components/ui/Tables";
import { ShowAction, EditAction, RevertAction, TableActionsContainer } from "components/ui/Tables/TableRowActions";
import { ColumnSelector } from "components/_shared/ColumnSelector/ColumnSelector";

import { CopyingTripModal } from "feature/panel/Trips/Index/CopyingTripModal/CopyingTripModal";
import columns from "feature/panel/Trips/Index/TableOfTripsColumns.json";

import { ItineraryTableRow } from "feature/panel/_shared/ItineraryTableRow/ItineraryTableRow";

import { LS_TRIPS_SELECTED_COLUMNS } from "constants/localstorage";
import { PANEL_TRIPS_EDIT_PATH } from "constants/routes";

import { toggleTripActiveStatusStart } from "store/trips/actions";
import { TABLE_ACTIONS_TITLES, TABLE_NAMES } from "constants/content";

import { useColumnSelectorHandlers } from "hooks/useColumnSelectorHandlers";
import { usePermissionsService } from "hooks/usePermissionsService";
import { PERMISSIONS } from "constants/permissions";

const RevertActionButton = styled(RevertAction)`
  && {
    background: ${({ theme }) => theme.colors.withOpacity(theme.colors.errorLight, 0.2)};
    color: ${({ theme }) => theme.colors.errorLight};
  }
`;

const TableOfTrips = ({ trips, tableHandlers, count, isLoadingInProgress }) => {
  const [openColumnSelectorPopup, setOpenColumnSelectorPopup] = useState(false);
  const [activeItem, setActiveItem] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const dispatchRedux = useDispatch();
  const permissionsService = usePermissionsService();

  const canCreateVamoos = permissionsService.can(
    PERMISSIONS.actions.create,
    PERMISSIONS.sections.vamoosList,
    PERMISSIONS.resources.default,
  );

  const { headers, handleSave } = useColumnSelectorHandlers(LS_TRIPS_SELECTED_COLUMNS, columns, setOpenColumnSelectorPopup);

  const handleToggleModal = item => {
    setActiveItem(item);
    setOpenModal(!openModal);
  };

  const { handleChangeOrder, handleChangeRowsPerPage, handleChangePage, rowsPerPage, page } = tableHandlers;
  const handleTripRestore = async ({ reference_code, operator_code }) => {
    dispatchRedux(toggleTripActiveStatusStart({ reference_code, operator_code, dispatcher: dispatchRedux }));
  };
  const bodyCellAction = ({ archived, item }) => {
    const canUpdateTrip = permissionsService.can(PERMISSIONS.actions.create, PERMISSIONS.sections.vamoosList, item.vamoos_id);
    const restoreButton = canUpdateTrip && archived && (
      <RevertActionButton
        clickAction={() => handleTripRestore(item)}
        tooltip={TABLE_ACTIONS_TITLES.restoreResource(TABLE_ACTIONS_TITLES.resourceTypes.trip)}
        disabled={!canUpdateTrip}
      />
    );

    const tripActions = canCreateVamoos ? (
      <ShowAction
        clickAction={() => handleToggleModal(item)}
        tooltip={TABLE_ACTIONS_TITLES.duplicateResource(TABLE_ACTIONS_TITLES.resourceTypes.trip)}
      />
    ) : null;

    if (item.is_wiped) return null;
    return archived ? restoreButton : tripActions;
  };

  bodyCellAction.defaultProps = { archived: false };
  bodyCellAction.propTypes = { archived: PropTypes.bool, item: PropTypes.shape().isRequired };

  const tableActions = (
    <TableActionsContainer>
      <EditAction
        noBackground
        clickAction={() => setOpenColumnSelectorPopup(true)}
        height={16}
        tooltip={TABLE_ACTIONS_TITLES.editColumns}
      />
    </TableActionsContainer>
  );

  return (
    <>
      <Table
        url={PANEL_TRIPS_EDIT_PATH}
        urlParams={["reference_code", "operator_code"]}
        headers={headers}
        list={trips}
        actions={bodyCellAction}
        onChangeOrder={handleChangeOrder}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        isDataLoading={isLoadingInProgress}
      >
        <TableHeader withActions actionsLabel={tableActions} tableId={TABLE_NAMES.trip} />
        <TableBody rowComponent={ItineraryTableRow} shouldCheckSaveLock />
        <TablePagination count={count} page={page - 1} rowsPerPage={rowsPerPage} tableId={TABLE_NAMES.trip} />
        <ColumnSelector
          open={openColumnSelectorPopup}
          onClose={() => setOpenColumnSelectorPopup(false)}
          onSave={handleSave}
          list={headers}
          limit={10}
        />
      </Table>
      <CopyingTripModal open={openModal} onCancel={handleToggleModal} item={activeItem || {}} />
    </>
  );
};
TableOfTrips.defaultProps = {
  trips: [],
  isLoadingInProgress: false,
};

TableOfTrips.propTypes = {
  trips: PropTypes.arrayOf(PropTypes.object),
  tableHandlers: PropTypes.shape({
    handleChangeOrder: PropTypes.func,
    handleChangeRowsPerPage: PropTypes.func,
    handleChangePage: PropTypes.func,
    page: PropTypes.number,
    rowsPerPage: PropTypes.number,
  }).isRequired,
  count: PropTypes.number.isRequired,
  isLoadingInProgress: PropTypes.bool,
};

export { TableOfTrips };
