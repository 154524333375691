import React, { useState } from "react";
import styled from "styled-components";
import { Grid } from "@material-ui/core";

import { useListOfCountries } from "hooks/useListOfCountries";
import { SearchField } from "components/ui/SearchField";
import { UnsafeAutocomplete } from "components/ui/Forms";
import { SETTINGS_STAYS } from "constants/content";
import { filterOptions } from "utils/filters/filterOptions";

const ControlsContainer = styled.div`
  margin: 15px 30px;
`;

const PublicStaysControl = ({ onSearchChange, onCountryChange }) => {
  const countries = useListOfCountries();

  const [selectedCountries, setSelectedCountries] = useState([]);
  const [countrySearchValue, setCountrySearchValue] = useState("");

  const filteredCountries = filterOptions(countries, countrySearchValue);

  const setValues = newCountries => {
    setSelectedCountries(newCountries);
    onCountryChange(newCountries.map(country => country.key));
    setCountrySearchValue("");
  };

  const handleCountryChange = selectedItem => {
    const isCountryAlreadySelected = selectedCountries.some(country => country.key === selectedItem.key);
    if (!isCountryAlreadySelected) {
      setValues([selectedItem, ...selectedCountries]);
    }
  };

  const handleRemoveFromSelectedList = ({ key }) => {
    setValues(selectedCountries.filter(country => country.key !== key));
  };

  const handleCountrySearchChange = ({ target }) => {
    const { value } = target;
    setCountrySearchValue(value);
  };

  return (
    <ControlsContainer>
      <Grid container spacing={5}>
        <Grid item xs={6}>
          <SearchField onSearchChange={onSearchChange} label={SETTINGS_STAYS.searchField} fullWidth />
        </Grid>
        <Grid item xs={4}>
          <UnsafeAutocomplete
            multiple
            autoComplete="country"
            inputProps={{ autoComplete: "country" }}
            name="country"
            label={SETTINGS_STAYS.countryField}
            onOptionClick={handleCountryChange}
            onChange={handleCountrySearchChange}
            options={filteredCountries}
            value={countrySearchValue}
            selectedList={selectedCountries}
            onDelete={handleRemoveFromSelectedList}
          />
        </Grid>
      </Grid>
    </ControlsContainer>
  );
};

export { PublicStaysControl };
