import { CREATION_AND_PUBLISHER_SECTION_TYPES } from "constants/defaults";
import { isString } from "contracts/types";
import { UrlRefreshService } from "services/application/UrlRefreshService";

export class CreationsService {
  /**
   * @param {object} data
   * @returns {object}
   */
  static setupCreationPayload(data) {
    const { id, field1, field2, field3, field4, fileName, showDays, storyboard, locations, frontPage, lastPage, tableOfContents } = data;
    const coverSection = this.createCoverSection(field1, field2, field3, field4);
    const sections = [coverSection];

    if (storyboard && storyboard.show) {
      sections.push(this.createStoryboardSection(storyboard.imageSize));
    }
    if (frontPage && frontPage.show) {
      sections.push(this.createFrontPageSection(frontPage));
    }
    if (lastPage && lastPage.show) {
      sections.push(this.createLastPageSection(lastPage));
    }

    if (locations) {
      sections.push(this.createMapSection());
    }

    if (tableOfContents) {
      sections.push(this.createTableOfContentSection());
    }

    const sortedSections = this.sortSections(sections);

    const payload = {
      name: fileName,
      show_dates: showDays,
      sections: sortedSections,
    };

    if (id) payload.id = id;

    return payload;
  }

  /**
   * @param {object} creation
   * @returns {object}
   */
  static setupExistingCreation(creation) {
    const {
      name,
      id,
      settings: { show_dates, sections },
    } = creation;

    const defaultOutput = {
      id,
      fileName: name,
      showDays: show_dates,
    };

    if (sections) {
      const tableOfContents = sections.find(section => section.type === CREATION_AND_PUBLISHER_SECTION_TYPES.tableOfContents);
      const storyboard = sections.find(section => section.type === CREATION_AND_PUBLISHER_SECTION_TYPES.storyboard);
      const locations = sections.find(section => section.type === CREATION_AND_PUBLISHER_SECTION_TYPES.map);
      const pois = sections.find(section => section.type === CREATION_AND_PUBLISHER_SECTION_TYPES.poiList);
      const notesForEnd = sections.find(section => section.type === CREATION_AND_PUBLISHER_SECTION_TYPES.notesEnd);
      const notesForFront = sections.find(section => section.type === CREATION_AND_PUBLISHER_SECTION_TYPES.notesFront);

      return {
        ...defaultOutput,
        tableOfContents: !!tableOfContents,
        storyboard: {
          show: !!storyboard,
          imageSize: storyboard ? storyboard.image_size : "adjusted",
        },
        locations: !!locations,
        poisList: !!pois,
        frontPage: {
          show: !!notesForFront,
          body: notesForFront ? notesForFront.content : "",
          headline: notesForFront ? notesForFront.headline : "",
        },
        lastPage: {
          show: !!notesForEnd,
          body: notesForEnd ? notesForEnd.content : "",
          headline: notesForEnd ? notesForEnd.headline : "",
        },
      };
    }

    return defaultOutput;
  }

  /**
   * @param {creation} creation
   * @returns {object}
   */
  static setupRefreshCreationPayload(creation) {
    const {
      itinerary_id,
      created_by,
      created_at,
      updated_at,
      number,
      time,
      settings,
      vamoos_id,
      job_id,
      library_node_id,
      outputs,
      job,
      ...rest
    } = creation;
    const { version, ...restOfSettings } = settings;
    return {
      ...rest,
      ...restOfSettings,
    };
  }

  /**
   * @param {string} name
   * @returns {string}
   */
  static setpPdfName(name) {
    return isString(name) && !name.endsWith(".pdf") ? `${name}.pdf` : name;
  }

  /**
   * @param {object} file
   * @param {string} name
   */
  static async invokeFileDownloading(file, name) {
    const externalUrl = await UrlRefreshService.refresh(file.https_url);
    const fetchedResource = await fetch(externalUrl);
    if (fetchedResource.blob) {
      const localBlob = await fetchedResource.blob();
      const finalDownloadUrl = window.URL.createObjectURL(localBlob);
      const pdfAnchor = document.createElement("a");
      pdfAnchor.download = this.setpPdfName(name);
      pdfAnchor.href = finalDownloadUrl;
      document.body.appendChild(pdfAnchor);
      pdfAnchor.click();
      window.URL.revokeObjectURL(finalDownloadUrl);
      document.body.removeChild(pdfAnchor);
    }
    // Creating anchor with download parameter causes download only when the url is in the same domain as the application.
    // This is why we first fetch the data from the url, make it a downloadable blob and create local url for it
  }

  /**
   * @param {string} field1
   * @param {string} field2
   * @param {string} field3
   * @param {string} field4
   * @returns {object}
   */
  static createCoverSection(field1, field2, field3, field4) {
    return {
      type: CREATION_AND_PUBLISHER_SECTION_TYPES.cover,
      imagesize: "reduced",
      headline: `${field1} ${field2} - ${field3} ${field4}`,
    };
  }

  /**
   * @param {object} lastPage
   * @returns {object}
   */
  static createLastPageSection(lastPage) {
    return { type: CREATION_AND_PUBLISHER_SECTION_TYPES.notesEnd, headline: lastPage.headline, content: lastPage.body };
  }

  /**
   * @param {object} frontPage
   * @returns {object}
   */
  static createFrontPageSection(frontPage) {
    return { type: CREATION_AND_PUBLISHER_SECTION_TYPES.notesFront, headline: frontPage.headline, content: frontPage.body };
  }

  /**
   * @param {string} imageSize
   * @returns {object}
   */
  static createStoryboardSection(imageSize) {
    return { type: CREATION_AND_PUBLISHER_SECTION_TYPES.storyboard, image_size: imageSize };
  }

  /**
   * @returns {object}
   */
  static createMapSection() {
    return { type: CREATION_AND_PUBLISHER_SECTION_TYPES.map };
  }

  /**
   * @returns {object}
   */
  static createTableOfContentSection() {
    return { type: CREATION_AND_PUBLISHER_SECTION_TYPES.tableOfContents };
  }
  
  /**
   * @param {object[]} sectionsList
   * @returns {object[]}
   */
  static sortSections(sectionsList) {
    const sectionsOrder = ["cover", "toc", "notes_front", "storyboard", "map", "notes_end"];
    return sectionsList.sort((a, b) => {
      const indexOfA = sectionsOrder.indexOf(a.type);
      const indexOfB = sectionsOrder.indexOf(b.type);

      const posA = indexOfA === -1 ? 100 : indexOfA;
      const posB = indexOfB === -1 ? 100 : indexOfB;

      if (posA > posB) {
        return 1;
      }
      if (posA < posB) {
        return -1;
      }
      return 0;
    });
  }
}
