import { takeEvery, put } from "redux-saga/effects";
import { handleErrorMessages } from "utils/store";

import * as actionTypes from "store/roles/actionTypes";
import { getRolesFail, getRolesSuccess } from "store/roles/actions";

import { HttpClient } from "services/application/httpClient/httpClient";
import { OPERATOR_ROLES_URL } from "constants/api";
import { UserRole } from "domain/UserRole";

function* getRoles() {
  try {
    const response = yield HttpClient.get(OPERATOR_ROLES_URL);

    if (response) {
      const { items, total_matches } = response.data;
      yield put(getRolesSuccess({ 
        items: items.map(user => UserRole(user)), 
        count: total_matches 
      }));
    }
  } catch (e) {
    yield put(getRolesFail({ errors: handleErrorMessages(e) }));
  }
}

export function* rolesSaga() {
  yield takeEvery(actionTypes.GET_ROLES_START, getRoles);
}
