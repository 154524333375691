import React, { memo } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { BodyText } from "components/ui/Typography/Typography";

const IconRowWrapper = styled.div`
  display: flex;
  padding: ${({ theme }) => theme.setSpacing(4)}px;
  align-items: center;
`;

const IconRowPreview = styled.img`
  display: block;
  max-width: ${({ theme }) => theme.setSpacing(6.5)}px;
  max-height: ${({ theme }) => theme.setSpacing(6.5)}px;
  margin-right: ${({ theme }) => theme.setSpacing(6)}px;
`;

const IconRow = memo(({ icon }) => {
  const { image, name } = icon;
  return (
    <IconRowWrapper>
      <IconRowPreview src={image} alt={name} />
      <BodyText>{name}</BodyText>
    </IconRowWrapper>
  );
});

IconRow.propTypes = {
  icon: PropTypes.shape({
    image: PropTypes.string.isRequired,
    name: PropTypes.string,
  }).isRequired,
};

export { IconRow, IconRowWrapper, IconRowPreview };
