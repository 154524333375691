import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { ContextNavigationLink } from "components/ui/Links/Links";

import {
  PANEL_HELP_USER_GUIDE,
  PANEL_HELP_FAQ,
  PANEL_HELP_TERMS,
  PANEL_HELP_CONTACT_US,
  PANEL_HELP_VAMOOS_API,
} from "constants/routes";

const StyledWrapper = styled.div`
  display: flex;
  height: 100%;
  width: auto;
  justify-content: center;
  align-items: center;
`;

const Navigation = ({ location }) => {
  const { pathname } = location;
  return (
    <StyledWrapper>
      <ContextNavigationLink to={PANEL_HELP_USER_GUIDE} isActive={PANEL_HELP_USER_GUIDE === pathname}>
        USER GUIDE
      </ContextNavigationLink>
      <ContextNavigationLink to={PANEL_HELP_FAQ} isActive={PANEL_HELP_FAQ === pathname}>
        FAQ
      </ContextNavigationLink>
      <ContextNavigationLink to={PANEL_HELP_TERMS} isActive={PANEL_HELP_TERMS === pathname}>
        TERMS
      </ContextNavigationLink>
      <ContextNavigationLink to={PANEL_HELP_CONTACT_US} isActive={PANEL_HELP_CONTACT_US === pathname}>
        CONTACT US
      </ContextNavigationLink>
      <ContextNavigationLink to={PANEL_HELP_VAMOOS_API} isActive={PANEL_HELP_VAMOOS_API === pathname}>
        VAMOOS API
      </ContextNavigationLink>
    </StyledWrapper>
  );
};

Navigation.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
};

export { Navigation, StyledWrapper };
