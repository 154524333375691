import FormControlLabel from "@material-ui/core/FormControlLabel";
import styled from "styled-components";

const CPSideFieldWrapper = styled(FormControlLabel)`
  && {
    .MuiFormControlLabel-label {
      font-size: ${({ theme }) => theme.fonts.fontSize.caption}px;
      font-family: ${({ theme }) => theme.fonts.font};
    }
  }
`;

export {
  CPSideFieldWrapper
}