import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import moment from "moment";
import lodash from "lodash";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";

import { Content } from "components/ui/Content/Content";
import { Input, Switch, Select } from "components/ui/Forms";
import { DatePicker, TimePicker } from "components/ui/Forms/DatePickers";
import { useManageTripContext } from "feature/panel/Trips/_shared/ManageTripContext";

import { TRIP_FIELDS } from "feature/panel/_shared/defaultFieldNames";
import { UserIdField } from "feature/panel/_shared/UserIdField/UserIdField";
import { SelectBrandProfile } from "feature/panel/_shared/SelectBranding/SelectBrandProfile";

import { checkErrorExistFor } from "utils/validation";
import { DEFAULT_DEBOUNCE_DELAY } from "constants/defaults";
import { PERMISSIONS } from "constants/permissions";

import { checkIsPasscodeAvailableStart, checkIsPasscodeAvailableSuccess } from "store/trips/actions";
import { getBrandingListStart } from "store/branding/actions";
import { usePermissionsService } from "hooks/usePermissionsService";
import { useListOfLanguages } from "hooks/useListOfLanguages";
import { colors } from "config/theme/colors";
import TimezoneSelect, { allTimezones } from "../../../../../components/ui/TimezonePicker/index";

export const isDateExpired = (startDateISO, endDateISO) => moment(startDateISO).isAfter(moment(endDateISO));

const GeneralInfoForm = () => {
  const permissionsService = usePermissionsService();
  const dispatch = useDispatch();

  const { operator_code, reference_code } = useParams();

  const {
    field1,
    field2,
    field3,
    field4,
    passcode,
    timezone,
    userId,
    client_reference,
    departure_date,
    return_date,
    brandingProfileId,
    start_time,
    is_wiped,
    setValueFor,
    show_journal,
    show_poi_list,
    vamoos_id,
    language,
  } = useManageTripContext();

  const [selectedBrandingProfile, setSelectedBrandingProfile] = useState(brandingProfileId);
  const isEnabledPoisListByDefault = useSelector(state => state.operator.currentOperator.meta.poisList);

  const defaultLanguageCode = useSelector(({ operator }) => operator.currentOperator.defaultLanguageCode);
  const additionalLanguages = useSelector(({ operator }) => operator.currentOperator.additionalLanguageCodes) || [];

  const languages = useListOfLanguages();

  const errors = useSelector(state => state.trips.errors);
  const brandsProfiles = useSelector(({ branding }) => branding?.brandingList || []);

  const editMode = !(vamoos_id === null || vamoos_id === undefined);

  const canEdit = permissionsService.can(PERMISSIONS.actions.update, PERMISSIONS.sections.vamoosList, vamoos_id);
  const canOperatorsRead = permissionsService.can(PERMISSIONS.actions.read, PERMISSIONS.sections.operator, PERMISSIONS.sections.operators);

  const showSelectBrand = !(brandsProfiles.length === 1 && brandsProfiles[0].isDefault);

  const checkPasscodeValidation = useRef(
    lodash.debounce((passcodeParam, userIdParam, vamoosId) => {
      if (editMode) {
        if (operator_code !== userIdParam || passcodeParam !== reference_code) {
          dispatch(checkIsPasscodeAvailableStart({ passcode: passcodeParam, userId: userIdParam, vamoosId }));
        } else {
          dispatch(checkIsPasscodeAvailableSuccess(null));
        }
      } else {
        dispatch(checkIsPasscodeAvailableStart({ passcode: passcodeParam, userId: userIdParam }));
      }
    }, DEFAULT_DEBOUNCE_DELAY),
  );

  const handleBrandProfileChange = ({ target }) => {
    const selectedBrand = brandsProfiles.find(brand => brand.id === target.value);

    if (selectedBrand.isDefault) {
      setValueFor("brandingProfileId", null, !!selectedBrandingProfile);
      setSelectedBrandingProfile(target.value);
    } else {
      setValueFor("brandingProfileId", target.value, !!selectedBrandingProfile);
      setSelectedBrandingProfile(target.value);
    }
  };

  const onPasscodeChange = ({ target }) => {
    setValueFor("passcode", target.value);

    checkPasscodeValidation.current(target.value, userId, vamoos_id);
  };

  const initGeneralFormValues = () => {
    if (show_poi_list === undefined) {
      // We cannot be sure if old trips use number, bool or string
      setValueFor("show_poi_list", [1, "1", true].includes(isEnabledPoisListByDefault), false);
    }
  };

  const tryRefreshingStartDate = () => {
    if (isDateExpired(departure_date, return_date)) {
      setValueFor(
        TRIP_FIELDS.departure_date,
        moment(return_date)
          .subtract(1, "day")
          .toISOString(),
      );
    }
  };

  const tryRefreshingEndDate = () => {
    if (isDateExpired(departure_date, return_date)) {
      setValueFor(
        TRIP_FIELDS.return_date,
        moment(departure_date)
          .add(1, "day")
          .toISOString(),
      );
    }
  };

  const getBrandProfiles = () => {
    if (canOperatorsRead) {
      dispatch(getBrandingListStart());
    }
  };

  useEffect(tryRefreshingStartDate, [return_date]);
  useEffect(tryRefreshingEndDate, [departure_date]);
  useEffect(initGeneralFormValues, [isEnabledPoisListByDefault, show_poi_list]);
  useEffect(getBrandProfiles, []);
  useEffect(() => {
    if (brandsProfiles?.length) {
      if (!brandingProfileId) {
        const currentBrand = brandsProfiles?.find(profile => profile?.isDefault);
        setSelectedBrandingProfile(currentBrand.id);
      } else {
        setSelectedBrandingProfile(brandingProfileId);
      }
    }
  }, [brandsProfiles?.length]);

  const updateDate = (fieldName, value) => {
    setValueFor(fieldName, value && value.isValid() ? value.toISOString() : null);
  };

  const sharedInputsProps = {
    size: "large",
  };

  const inputs = {
    userId: {
      ...sharedInputsProps,
      label: "User ID*",
      type: "text",
      name: "userId",
      value: userId,
      onChange: ({ target, shouldTouchTheForm }) => setValueFor("userId", target.value, !!shouldTouchTheForm),
      disabled: is_wiped || (editMode && !canEdit),
    },
    passcode: {
      ...sharedInputsProps,
      label: "Passcode*",
      type: "text",
      name: "passcode",
      value: passcode,
      onChange: onPasscodeChange,
      error: checkErrorExistFor("passcode", errors),
      helperText: errors.passcode,
      disabled: is_wiped || (editMode && !canEdit),
      inputProps: {
        "data-lpignore": true,
        autoComplete: "off",
      },
    },
    destination: {
      ...sharedInputsProps,
      label: "Destination / Event title*",
      type: "text",
      name: "field1",
      /* eslint-disable prefer-template */
      value: field1 + (field2 ? " " + field2 : ""),
      onChange: ({ target }) => {
        setValueFor("field2", "");
        setValueFor("field1", target.value);
      },
      error: checkErrorExistFor("field1", errors),
      helperText: errors.field1 || (
        <>
          <strong>Note!</strong> We have simplified Vamoos by merging the old &quot;Destination/Event title&quot; and &quot;Holiday/Event
          type&quot; fields into one field above
        </>
      ),
      disabled: is_wiped || (editMode && !canEdit),
    },

    location: {
      ...sharedInputsProps,
      label: "Name / Location*",
      type: "text",
      name: "field3",
      value: field3 + (field4 ? " " + field4 : ""),
      onChange: ({ target }) => {
        setValueFor("field4", "");
        setValueFor("field3", target.value);
      },
      error: checkErrorExistFor("field3", errors),
      helperText: errors.field1 || (
        <>
          <strong>Note!</strong> We have simplified Vamoos by merging the old &quot;First name/Location&quot; and &quot;Last name&quot;
          fields into one field above
        </>
      ),
      disabled: is_wiped || (editMode && !canEdit),
    },

    startDate: {
      ...sharedInputsProps,
      label: "Start date*",
      name: "departure_date",
      value: departure_date,
      onChange: value => updateDate("departure_date", value),
      fullWidth: true,
      error: checkErrorExistFor("departure_date", errors),
      helperText: errors.departure_date,
      disabled: is_wiped || (editMode && !canEdit),
      autoOk: true,
      format: "",
    },
    endDate: {
      ...sharedInputsProps,
      label: "End date*",
      name: "return_date",
      value: moment(return_date),
      onChange: value => updateDate("return_date", value),
      fullWidth: true,
      error: checkErrorExistFor("return_date", errors),
      helperText: errors.return_date,
      disabled: is_wiped || (editMode && !canEdit),
      autoOk: true,
    },
    startTime: {
      ...sharedInputsProps,
      label: "Start time (24h)*",
      name: "start_time",
      value: moment(start_time),
      onChange: value => updateDate("start_time", value),
      fullWidth: true,
      error: checkErrorExistFor("start_time", errors),
      helperText: errors.start_time,
      disabled: is_wiped || (editMode && !canEdit),
    },
    timeZone: {
      ...sharedInputsProps,
      label: "Time zone",
      type: "text",
      name: "timezone",
      value: timezone,
      onChange: ({ target }) => setValueFor("timezone", target.value),
      error: checkErrorExistFor("timezone", errors),
      helperText: errors.timezone,
      disabled: is_wiped || (editMode && !canEdit),
    },
    language: {
      ...sharedInputsProps,
      label: "Language",
      type: "text",
      name: "language",
      value: language || "",
      onChange: ({ target }) => setValueFor("language", target.value),
      error: checkErrorExistFor("language", errors),
      helperText: errors.language,
      disabled: is_wiped || (editMode && !canEdit),
    },
    clientRef: {
      ...sharedInputsProps,
      label: "Client Ref",
      type: "text",
      name: "client_reference",
      value: client_reference,
      onChange: ({ target }) => setValueFor("client_reference", target.value),
      error: checkErrorExistFor("client_reference", errors),
      helperText: errors.client_reference,
      disabled: is_wiped || (editMode && !canEdit),
    },
  };

  const onCreateOption = value => {
    setValueFor("timezone", value);
  };

  useEffect(() => {
    const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
    if (!timezone) setValueFor("timezone", timeZone, false);
  }, []);

  return (
    <Grid container>
      {canOperatorsRead && showSelectBrand && (
        <Content margin={10}>
          <SelectBrandProfile
            brandingProfileId={selectedBrandingProfile}
            onBrandProfileChange={handleBrandProfileChange}
            disabled={is_wiped || (editMode && !canEdit)}
          />
        </Content>
      )}
      {additionalLanguages.length > 0 && (
        <Content margin={10}>
          <Select {...inputs.language}>
            {languages
              .filter(lang => [defaultLanguageCode, language, ...additionalLanguages].some(code => code === lang.code))
              .map(lang => (
                <MenuItem
                  key={lang.code}
                  value={lang.code}
                  selected={lang.code === language}
                  disabled={![defaultLanguageCode, ...additionalLanguages].includes(lang.code)}
                >
                  {lang.name}
                </MenuItem>
              ))}
          </Select>
        </Content>
      )}
      <Content margin={10}>
        <UserIdField {...inputs.userId} />
      </Content>
      <Content margin={10}>
        <Input {...inputs.passcode} />
      </Content>
      <Content margin={10}>
        <Input {...inputs.destination} />
      </Content>
      <Content margin={10}>
        <Input {...inputs.location} />
      </Content>
      <Content margin={10}>
        <DatePicker {...inputs.startDate} />
      </Content>
      <Content margin={10}>
        <DatePicker {...inputs.endDate} />
      </Content>
      <Content margin={10}>
        <TimePicker {...inputs.startTime} />
      </Content>
      <Content margin={10}>
        <TimezoneSelect
          value={timezone}
          onChange={tz => setValueFor("timezone", tz.value)}
          onCreateOption={onCreateOption}
          timezones={allTimezones}
          startDate={departure_date}
          startTime={start_time}
        />
      </Content>
      <Content margin={10}>
        <Input {...inputs.clientRef} />
      </Content>
      <Grid item container spacing={4}>
        <Grid item xs={6} md={12} lg={6}>
          <Switch
            checked={show_journal}
            label="Enable Posts"
            labelPlacement="start"
            onChange={() => setValueFor("show_journal", !show_journal)}
            disabled={is_wiped || (editMode && !canEdit)}
          />
        </Grid>
        <Grid item xs={6} md={12} lg={6}>
          {show_poi_list !== undefined && (
            <Switch
              checked={show_poi_list}
              label="Enable The list"
              labelPlacement="start"
              onChange={() => setValueFor("show_poi_list", !show_poi_list)}
              disabled={is_wiped || (editMode && !canEdit)}
            />
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

GeneralInfoForm.defaultProps = {
  editMode: false,
};

GeneralInfoForm.propTypes = {
  editMode: PropTypes.bool,
};

export { GeneralInfoForm };
